import React from 'react'
import './CustomTable.scss'
import clsx from 'clsx'

interface CustomTableProps {
    caption: string,
    th: string[],
    data: any[][]
}

const CustomTable = (props: CustomTableProps) => {
    const {
        caption,
        th,
        data,
    } = props
    return (
        <div className='customTable'>
            <table>
                <caption>{caption}</caption>
                <tbody>
                    <tr>
                        {th?.map((element, index) => <th key={`customTable_th_${index}`}>{element}</th>)}
                    </tr>
                    {
                        data?.map((tr, index) => <tr key={`customTable_tr_${index}`}>
                            {tr?.map((td, i) =>
                                <td key={`customTable_td_${i}`}
                                    colSpan={td.colspan || 1}
                                    className={clsx(td.type, td.className)}
                                >{td.value}</td>)}
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default CustomTable
