export function get<T, K extends keyof T>(obj: T, path: string | Array<string | number>, defaultValue?: K): any {
    const keys = Array.isArray(path) ? path : path.split('.')
    let result: any = obj

    for (const key of keys) {
        if (result == null) {
            return defaultValue
        }
        result = result[key as keyof typeof result]
    }

    return result === undefined ? defaultValue : result
}
