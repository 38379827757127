import { fetchDate } from '../../util/currentTime'
import { moneyToString } from '../../util/moneyToString'
import { KpService } from '~/components/pages/KP/kp.types'
import { ServicesType } from '~/shared/templates/mainTemplates/additionalFunctions'
import { ServicesWithId } from '~/shared/templates/mainTemplates/additionalFunctions/collectServices/collectServices'
import { calculateWithDiscount } from '~/shared/templates/mainTemplates/docUtil'

const page1Limit = 28
const page2Limit = 35

const findNeededServices = (limit: number, services: ServicesWithId[]) => {
    if (services.length === 0) {
        return null
    }

    if (services[0].items.length > limit) {
        return null
    }
    return services[0]
}

const buildServiceTable = (service: ServicesType) => {
    let html = `<tr>
                            <th colspan='5' style='text-align:center; height: 25px; vertical-align:top !important;'>${service.title}</th>
                        </tr>
                        <tr class='services__header'>
                                <th colspan='1' class='center' style='width:20px !important; height: 20px; vertical-align:top !important;'>№</th>
                                <th colspan='2' class='center' style='width:54%; vertical-align:top !important;'>Наименование работ</th>
                                <th colspan='2' class='center' style='width:45%; vertical-align:top !important;'>Примечание</th>
                        </tr>`
    html += service.items
        .map((item, index) => {
            return `<tr>
                            <td colspan='1' style='width:20px !important; height:20px !important; text-align:center; vertical-align:top !important;'>${index + 1 + '.'}</td>
                            <td colspan='2' style='text-align:center;vertical-align:top !important;'>${item.name}</td>
                            <td colspan='2' style='text-align:center;vertical-align:top !important;'>${item.description || ''}</td>
                        </tr>`
        })
        .join('')

    return html
}

export const contract = async (values: any) => {
    const html = await fetch('/html/contract.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    const domHtml = parser.parseFromString(htmlText, 'text/html')

    const servicesPage1: string[] = []
    const servicesAdditionalPages: string[][] = []

    const discount = Number(values.kpDiscount)

    let services: ServicesWithId[] = [...values.commercialOffer.services.reduce((acc: ServicesType[], item: KpService) => {
        acc.push(...item.tasks)

        return acc
    }, []).map((item: ServicesType, index: number) => ({
        ...item,
        id: item.title + index,
    }))].filter((s: ServicesType) => s?.items?.length > 0)
    // let services: ServicesType[] = values?.services?.filter((s: ServicesType) => s?.items?.length > 0)

    if (!values.inspection?.reportDate) {
        let servicePageCounter = 0
        let servicePage1Counter = 0;
        [...services].forEach(() => {
            // Если на первой странице остался не истраченный лимит
            if (servicesAdditionalPages.length === 0 && servicePage1Counter < page1Limit) {
                const findServices = findNeededServices(page1Limit - servicePage1Counter, services)
                // Если нашлась группа услуг подходящая для добавления на первую страницу
                if (findServices) {
                    const html = buildServiceTable(findServices)
                    servicesPage1.push(html)
                    services = services.filter((s) => s.id !== findServices.id)
                    servicePage1Counter += findServices.items.length
                    return
                }
                servicesAdditionalPages.push([])
            }

            if (servicesAdditionalPages.length === 0) {
                servicesAdditionalPages.push([])
            }
            // Проверяем лимит для текущей дополнительной страницы
            const currentLimit = page2Limit - servicePageCounter
            const findServices = findNeededServices(currentLimit, services)

            // Если нашлась группа услуг для добавления на текущую страницу
            if (findServices) {
                const html = buildServiceTable(findServices)
                servicesAdditionalPages[servicesAdditionalPages.length - 1].push(html)
                services = services.filter((s) => s.id !== findServices.id)
                servicePageCounter += findServices.items.length
                return
            }

            // Если остались сервисы, но не нашлось места на текущей странице
            servicePageCounter = 0
            servicesAdditionalPages.push([])
            const newFindServices = findNeededServices(page2Limit, services)
            if (newFindServices) {
                const html = buildServiceTable(newFindServices)
                servicesAdditionalPages[servicesAdditionalPages.length - 1].push(html)
                services = services.filter((s) => s.id !== newFindServices.id)
                servicePageCounter += newFindServices.items.length
                return servicesAdditionalPages
            }
            throw new Error('To many services')
        })

        domHtml.querySelector('#services')!.innerHTML = servicesPage1.join('')
    } else {
        domHtml.querySelector('#servicesHead')!.innerHTML = `
                <th class='services__td_1' style='text-align:center; line-height: 20px; font-size: 14px;'>№</th>
                <th class='services__td_3' style='text-align:center; font-size: 14px;'>Наименование работ/услуг</th>
                <th class='services__td_2' style='text-align:center; font-size: 14px;'>Кол-во</th>
                <th class='services__td_2' style='text-align:center; font-size: 14px;'>Ед.</th>
                <th class='services__td_2' style='text-align:center; font-size: 14px;'>Цена за ед.</th>
                <th class='services__td_2' style='text-align:center; font-size: 14px;'>Сумма</th>
            `
        domHtml.querySelector('#services')!.innerHTML = values?.commercialOffer.services
            .filter((item: KpService) => item.isSelected)
            .map(
                (item: any, index: number) => {
                    const costWithDiscount = item.price ? +calculateWithDiscount(+item.price, +item.discount, +item.discount > 0) : 0
                    const unitCost = () => {
                        return costWithDiscount && Number(item.amount) ? (costWithDiscount / Number(item.amount)).toFixed(2) : '0.00'
                    }

                    let html = `<tr style='font-size: 14px; line-height: 20px'>
                            <td class='services__td_1'>${index + 1 + '.'}</td>
                            <td class='services__td_3'>${item.name}</td>
                            <td class='services__td_2' style='min-width: 40px !important; text-align: center'>${item.amount}</td>
                            <td class='services__td_2' style='min-width: 60px !important'>${item.measure}</td>
                            <td class='services__td_2' style='min-width: 60px !important'>${unitCost()} ₽</td>
                            <td class='services__td_2' style='min-width: 60px !important'>${Math.floor(costWithDiscount)} ₽</td>
                        </tr>
                `
                    if (values.commercialOffer.services.length) {
                        html += `
                        <tr>
                            <th colspan='2' style='text-align: right; padding-right: 5px; line-height: 20px; font-size: 14px'>План выполнения работ:</th>
                            <td colspan='4' id='servicesDesc' style='padding-left: 5px;padding-right: 5px; line-height: 20px; font-size: 14px;'>${item.workDescription.replace(/\n/g, '<br>')}</td>
                        </tr>
                        <tr>
                            <th colspan='2' style='text-align: right; padding-right: 5px; line-height: 20px; font-size: 14px'>График работ:</th>
                            <td colspan='4' style='padding-left: 5px;padding-right: 5px; line-height: 20px; font-size: 14px'><pre>${item.schedule}</pre></td>
                        </tr>
                        <tr>
                            <th colspan='2' style='text-align: right; padding-right: 5px; line-height: 20px; font-size: 14px'>Количество персонала:</th>
                            <td colspan='4' style='padding-left: 5px; padding-right: 5px; line-height: 20px; font-size: 14px'><pre>${item.personnelDescription}</pre></td>
                        </tr>
                    `
                    }

                    return html
                },
            )
            .join('')

        servicesAdditionalPages.push([])
        let servicePageCounter = 0
        const pageLimit = 25
        let newServices: ServicesWithId[] = [...services]
        services.forEach(() => {
            // Проверяем лимит для текущей дополнительной страницы
            let currentLimit = pageLimit - servicePageCounter
            const findServices = findNeededServices(currentLimit, newServices)

            // Если нашлась группа услуг для добавления на текущую страницу
            if (findServices) {
                const html = buildServiceTable(findServices)
                servicesAdditionalPages[servicesAdditionalPages.length - 1].push(html)
                newServices = newServices.filter((s) => s.id !== findServices.id)
                servicePageCounter += findServices.items.length
                return
            }

            // Если остались сервисы, но не нашлось места на текущей странице
            servicePageCounter = 0
            currentLimit = pageLimit - servicePageCounter
            servicesAdditionalPages.push([])

            const newFindServices = findNeededServices(currentLimit, newServices)
            if (newFindServices) {
                const html = buildServiceTable(newFindServices)
                servicesAdditionalPages[servicesAdditionalPages.length - 1].push(html)
                newServices = newServices.filter((s) => s.id !== newFindServices.id)
                servicePageCounter += newFindServices.items.length
                return servicesAdditionalPages
            }
            throw new Error('Too many services')
        })
    }

    const signing = `<div class='signing'>
                <div style='text-align: center'>ПОДПИСИ СТОРОН</div>
                <div class='signing__footer'>
                    <div class='signing__left'>
                        Заказчик:
                    </div>
                    <div class='signing__right'>
                        Исполнитель:
                    </div>
                </div>
                <div class='signing__header'><b>Генеральный директор<br>ООО 'H2O'</b></div>
                <div class='signing__footer'>
                    <div class='signing__left'>
                        ____________/_______________________________/
                    </div>
                    <div class='signing__right'>
                        __________________/Исаков А.Л./
                    </div>
                </div>
            </div>`

    // if (values.commercialOffer?.additionalServices) {
    //     domHtml.querySelector('#additionalSimpleServicePage')!.innerHTML = `
    //             <div class='pageWrapper pageWrapper_2'>
    //                 <div class='annex'>
    //                     <table class='servicesTable'>
    //                         <thead>
    //                         <tr>
    //                             <th colspan='14' style='text-align:center; font-size: 14px; width: 100%'>Дополнительные услуги</th>
    //                         </tr>
    //                         <tr class='services__header'>
    //                             <th colspan='1' style='text-align:center; line-height: 20px; font-size: 14px;'>№</th>
    //                             <th colspan='6' style='text-align:center; font-size: 14px;'>Наименование работ/услуг</th>
    //                             <th colspan='2' style='text-align:center; font-size: 14px;'>Кол-во</th>
    //                             <th colspan='1' style='text-align:center; font-size: 14px;'>Ед.</th>
    //                             <th colspan='2' style='text-align:center; font-size: 14px;'>Цена за ед.</th>
    //                             <th colspan='2' style='text-align:center; font-size: 14px;'>Сумма</th>
    //                         </tr>
    //                         </thead>
    //                         <tbody>
    //                         ${buildAdditionalServiceTable(values.commercialOffer?.additionalServices)}
    //                         </tbody>
    //                     </table>
    //                     ${signing}
    //                 </div>
    //             </div>
    //         `
    // }

    if (servicesAdditionalPages.length === 0) {
        domHtml.querySelector('#signing')!.innerHTML = signing
    } else {
        domHtml.querySelector('#additionalPages')!.innerHTML = servicesAdditionalPages
            .map((page, index) =>
                `<div class='pageWrapper'>
                <div class='annex'>
                        <table class='services'>
                        <caption style='vertical-align: top !important;'><b>Перечень работ</b></caption>
                            <tbody>${page.join('')}</tbody>
                        </table>
                        </div>${index === servicesAdditionalPages.length - 1 ? signing : ''}</div>`,
            )
            .join('')
    }

    let date = fetchDate()
    if (values.contractDate) {
        date = fetchDate(values.contractDate)
    }
    const dateString = date.format('LL')
    const day = dateString.split(' ')[0]

    domHtml.querySelectorAll('.contractNumber').forEach((element) => (element.innerHTML = values.contractNumber))
    domHtml.querySelectorAll('.contractDate').forEach((element) => (element.innerHTML = 'от «' + day + '» ' + dateString.substring(day.length)))
    domHtml.querySelector('#startDate')!.innerHTML = fetchDate(values?.cleaningDate?.startDate).format('L')
    domHtml.querySelector('#endDate')!.innerHTML = fetchDate(values?.cleaningDate?.endDate).format('L')
    domHtml.querySelector('#address')!.innerHTML = values.address
    domHtml.querySelector('#serviceType')!.innerHTML = values.serviceType + (values.commercialOffer?.additionalServices?.length > 0 ? ' + ' + values.commercialOffer?.additionalServices?.map((s: any) => s.name).join(', ') : '')
    domHtml.querySelector('#cost')!.innerHTML = Number(values.cost).toFixed(2).toLocaleString() + ' руб. (' + moneyToString(values.cost) + ')'

    if (values.prepaymentType === 'Без предоплаты') {
        domHtml.querySelector<HTMLElement>('#prepayment_tr')!.style.display = 'none'
    }
    if (values.prepaymentType === 'На объекте') {
        const prepayment = (+values.cost * (+values.prepayment / 100)).toFixed(2)
        domHtml.querySelector('#prepayment')!.innerHTML = 'Сумма предоплаты составляет ' + prepayment.toLocaleString() + ' руб.(' + moneyToString(prepayment) + ') и вносится Заказчиком перед началом работ.'
    }
    if (values.prepaymentType === 'Внесена') {
        const prepayment = (+values.cost * (+values.prepayment / 100)).toFixed(2)
        domHtml.querySelector('#prepayment')!.innerHTML = 'Сумма предоплаты составляет ' + prepayment.toLocaleString() + ' руб.(' + moneyToString(prepayment) + ') и внесена Заказчиком перед началом работ.'
    }
    if (values.prepaymentType === 'Бронирование даты' || values.prepaymentType === 'Выезд на осмотр') {
        domHtml.querySelector('#prepayment')!.innerHTML = 'Сумма предоплаты составляет ' + Number(values.prepayment).toFixed(2).toLocaleString() + ' руб.(' + moneyToString(values.prepayment) + ') и внесена Заказчиком перед началом работ.'
    }

    if (values.clientRequisites?.passportSeries) domHtml.querySelector('#passportSeries')!.innerHTML = values.clientRequisites.passportSeries
    if (values.clientRequisites?.passportNumber) domHtml.querySelector('#passportNumber')!.innerHTML = values.clientRequisites.passportNumber
    if (values.clientRequisites?.birthDate) domHtml.querySelector('#passportBirthday')!.innerHTML = fetchDate(values.clientRequisites.birthDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
    if (values.clientRequisites?.issuedBy) domHtml.querySelector('#issuedBy')!.innerHTML = values.clientRequisites.issuedBy
    if (values.clientRequisites?.issueDate) domHtml.querySelector('#issuedDate')!.innerHTML = fetchDate(values.clientRequisites.issueDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
    if (values.clientRequisites?.passportAddress) domHtml.querySelector('#residencePlace')!.innerHTML = values.clientRequisites.passportAddress
    if (values.clientRequisites?.departmentCode) domHtml.querySelector('#subdivisionCode')!.innerHTML = values.clientRequisites.departmentCode
    if (values.clientRequisites?.phone) domHtml.querySelector('#clientPhone')!.innerHTML = values.clientRequisites.phone
    if (values.clientRequisites?.name) domHtml.querySelectorAll('.passportFullname').forEach((element) => (element.innerHTML = `<b>${values.clientRequisites.name}</b>`))

    domHtml.querySelectorAll('.pageWrapper').forEach((page, index, array) => {
        page.innerHTML += `<span class='pagesSign'>Страница ${index + 1} из ${array.length}</span>`
    })

    return { style: domHtml.querySelector('style')!.innerHTML, body: domHtml.querySelector('body')!.innerHTML }
}


