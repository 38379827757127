import { RootState } from '../store'

export const selectKpPaymentType = (state: RootState) => state.kp.paymentType
export const selectKpHandleSubmit = (state: RootState) => state.kp.handleSubmit
export const selectKpHandleControl = (state: RootState) => state.kp.handleControl
export const selectKpDiscount = (state: RootState) => state.kp.kpDiscount
export const selectKpHandleSave = (state: RootState) => state.kp.handleSave
export const selectKpSetter = (state: RootState) => state.kp.handleKPSetter
export const selectKpGetter = (state: RootState) => state.kp.handleKPGetter
export const selectKpTrigger = (state: RootState) => state.kp.handleKPTrigger
