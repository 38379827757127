import React, { ReactNode, useEffect, useState } from 'react'
import { UseFormGetFieldState, UseFormGetValues, UseFormResetField, UseFormSetValue, useWatch } from 'react-hook-form'
import { InputParams } from '../../useFormConstructor.types'
import { createInputName } from '../additionalFunctions/createInputName'
import { User } from '~/API/userApi/user.types'
import { checkAdditionalRoles } from '~/hooks/useFormConstructor/lib/additionalFunctions/checkAdditionalRoles'

interface WithHideInputProps {
    input: InputParams,
    handleSetRowEmpty: (mode: 'inc' | 'dec') => void,
    resetField: UseFormResetField<any>
    getValues: UseFormGetValues<any>
    setValue: UseFormSetValue<any>
    getFieldState: UseFormGetFieldState<any>
    sectionName: string
    user: User | null
    children?: ReactNode
}

export const WithHideInput = (props: WithHideInputProps) => {
    const {
        input,
        children,
        handleSetRowEmpty,
        resetField,
        getValues,
        setValue,
        sectionName,
        user,
        getFieldState,
    } = props
    const {
        control,
        options,
        name,
        roles,
        userDependence,
        usingData,
    } = input
    const [isHidden, setHidden] = useState<boolean>(false)

    const watchingInputsValues = useWatch({
        control,
        name: options?.inputRules?.watchRuleInputs ? options.inputRules.watchRuleInputs : [],
    })
    const inputsValuesString = createInputName(
        options?.inputRules?.inputsValues ? options.inputRules.inputsValues : [],
        options?.inputRules?.mode === 'ARRAY_NOT' || options?.inputRules?.mode === 'ARRAY_MANY_NOT' ? options?.inputRules?.mode : undefined,
    )
    const booleanInputs = options?.inputRules?.booleanInputs || []

    useEffect(() => {
        if (isHidden) {
            getValues(usingData || `${sectionName}.${name}`) && resetField(usingData || `${sectionName}.${name}`) // && setValue(usingData || `${sectionName}.${name}`, null)
            handleSetRowEmpty('inc')
        } else {
            handleSetRowEmpty('dec')
        }
    }, [isHidden])


    useEffect(() => {
        const watchRuleInputsString = options?.inputRules?.mode !== 'ARRAY_MANY_NOT' ? createInputName(watchingInputsValues, options?.inputRules?.mode) : watchingInputsValues
        if (roles && user) { // скрытие инпутов с полем roles
            /** Проверка на то что если ORDER_MANAGER создатель заявки и в данной секции есть права у OPERATOR то они будут и у ORDER_MANAGER */
            setHidden(!checkAdditionalRoles(roles, user, getValues).includes(user.role))
        }
        if (userDependence) { // скрытие инпутов с полем userDependence
            setHidden(user?.id !== getValues(userDependence))
        }
        switch (options?.inputRules?.mode) {
            case 'boolean': // TODO make it uppercase
                if (typeof watchRuleInputsString === 'string') {
                    setHidden(!inputsValuesString.includes(watchRuleInputsString))
                }
                break
            case 'OR':
                setHidden(!watchingInputsValues.some((value, index) => {
                    if (booleanInputs[index]) {
                        return !!value
                    }
                    return inputsValuesString.includes(value)
                }))
                break
            case 'NOT':
                if (typeof watchRuleInputsString === 'string') {
                    setHidden(!!watchRuleInputsString && inputsValuesString.includes(watchRuleInputsString))
                }
                break

            /** ARRAY_NOT - сравнивает по индексно watchRuleInputs и inputsValues в одномерном массиве и возвращает true если они совпадают */
            case 'ARRAY_NOT':
                if (Array.isArray(watchRuleInputsString) && Array.isArray(inputsValuesString)) {
                    let res = false
                    for (let i = 0; i < watchRuleInputsString.length; i++) {
                        if (watchRuleInputsString[i] === inputsValuesString[i]) {
                            res = true
                            break
                        }
                    }
                    setHidden(res)
                }
                break

            /** ARRAY_MANY_NOT - сравнивает по индексно watchRuleInputs и inputsValues в многомерном массиве и возвращает true если они совпадают */
            /** НЕ РАБОТАЕТ */
            case 'ARRAY_MANY_NOT': {
                const inputsValues = options?.inputRules?.inputsValues
                if (Array.isArray(watchRuleInputsString) && Array.isArray(inputsValues)) {
                    let res = false
                    for (let i = 0; i < watchRuleInputsString.length; i++) {
                        for (let j = 0; j < inputsValues[i].length; j++) {
                            if (watchRuleInputsString[i] === inputsValues[i][j]) {
                                res = true
                                break
                            }
                        }
                    }
                    setHidden(res)
                }
                break
            }

            /** ARRAY_MANY - сравнивает по индексно watchRuleInputs и inputsValues в многомерном массиве и возвращает true если они не совпадают */
            case 'ARRAY_MANY': {
                const inputsValues = options?.inputRules?.inputsValues as string[][]
                if (Array.isArray(watchRuleInputsString) && Array.isArray(inputsValues)) {
                    setHidden(!watchRuleInputsString.every((rule, index) => inputsValues[index].some((value) => rule === value)))
                }
                break
            }
            case 'MANY':
                if (typeof watchRuleInputsString === 'string' && typeof inputsValuesString === 'string') {
                    setHidden(!watchRuleInputsString.includes(inputsValuesString) || !inputsValuesString)
                }

                break
            case 'ARRAY':
                if (typeof watchRuleInputsString === 'string') {
                    setHidden(!inputsValuesString.includes(watchRuleInputsString) || !watchRuleInputsString)
                }

                break
            default:
                if (typeof watchRuleInputsString === 'string') {
                    setHidden(!inputsValuesString.includes(watchRuleInputsString) || !watchRuleInputsString)
                }

                break
        }
    }, [watchingInputsValues])

    if (!options || !Array.isArray(options?.inputRules?.watchRuleInputs)) {
        return <>{children}</>
    }

    if (!isHidden) {
        return <>{children}</>
    }

    return null
}
