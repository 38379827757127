import { Dayjs } from 'dayjs'

import { fetchDate, fetchLocalDate } from '../../util/currentTime'
import { SalaryObject } from '~/API/salaryApi/salary.types'
import { SalaryWorkers } from '~/redux/slices/salarySlice/salarySlice.type'
import { formatRUB } from '~/shared/util/formatNumberWithIntlOptions'

export interface SalaryTableTemplate extends SalaryWorkers {
    salaryData: SalaryObject
    startDate: Date | Dayjs
    endDate: Date | Dayjs
}

const calculateRate = (serviceRating: string, qualityRating: string | number, accumRate: number) => {
    let sum = 0
    switch (serviceRating) {
        case '-':
            break
        default:
            sum = sum + Number(serviceRating)
            break
    }
    switch (qualityRating) {
        case '-':
            break
        default:
            sum = sum + Number(qualityRating)
            break
    }
    if (serviceRating !== '-' && qualityRating !== '-') {
        sum = sum / 2
    }
    return sum + accumRate
}

export const salaryTable = async (values: SalaryTableTemplate) => {
    const html = await fetch('/html/salaryTable.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    const domHtml = parser.parseFromString(htmlText, 'text/html')

    const startDate = fetchLocalDate(values.startDate)
    const endDate = fetchLocalDate(values.endDate)

    domHtml.querySelector('#startDate')!.innerHTML = startDate.format('DD.MM.YY')
    domHtml.querySelector('#endDate')!.innerHTML = endDate.format('DD.MM.YY')
    domHtml.querySelector('#salaryDate')!.innerHTML = values.jobPosition.includes('Бригадир') ? '' : fetchLocalDate().format('DD.MM.YY')
    domHtml.querySelector('#fullname')!.innerHTML = values.name
    let salaryAmount = 0
    values.cleanings.flatMap((cleaning) => cleaning)
        .filter((w) => startDate.isSameOrBefore(fetchLocalDate(w.cleaningDate)) && endDate.isSameOrAfter(fetchLocalDate(w.cleaningDate)))
        .sort((a, b) => fetchDate(a.cleaningDate).valueOf() - fetchDate(b.cleaningDate).valueOf())
        .forEach((cleaning, index) => {
            const salary = +cleaning.salaryAmount || 0
            const addSalary = +cleaning.addSalary || 0

            if (cleaning.salary?.type !== 'SINGLE') {
                salaryAmount += +cleaning.salaryAmount + (+cleaning.addSalary || 0) // todo make salaryAmount and addSalary number type
            }

            const tr = document.createElement('tr')
            const td1 = document.createElement('td')
            td1.innerHTML = (index + 1).toString()
            tr.appendChild(td1)

            const td2 = document.createElement('td')
            td2.innerHTML = fetchDate(cleaning.cleaningDate).format('DD.MM.YY')
            tr.appendChild(td2)

            const td3 = document.createElement('td')
            td3.innerHTML = cleaning.address
            tr.appendChild(td3)

            const td4 = document.createElement('td')
            if (values.jobPosition.includes('Бригадир')) {
                td4.innerHTML = formatRUB(salary + addSalary, false)
            } else {
                td4.innerHTML = formatRUB(salary, false)
            }
            if (cleaning.salary?.type === 'SINGLE') {
                td4.style.textDecoration = 'line-through'
            }
            tr.appendChild(td4)

            if (values.jobPosition.includes('Бригадир')) {
                const td5 = document.createElement('td')
                td5.innerHTML = cleaning.petrolExpanses ? cleaning.petrolExpanses.toString() : '-'
                tr.appendChild(td5)

                const td6 = document.createElement('td')
                td6.innerHTML = cleaning.comment || '-'
                tr.appendChild(td6)

                const td7 = document.createElement('td')
                td7.innerHTML = cleaning.qualityRating?.toString() || '-'
                tr.appendChild(td7)

                const td8 = document.createElement('td')
                td8.innerHTML = cleaning.serviceRating?.toString() || '-'
                tr.appendChild(td8)

                const td9 = document.createElement('td')
                td9.innerHTML = cleaning.clientComment?.toString() || '-'
                td9.style.fontSize = '11px'
                td9.style.padding = '0px'
                td9.style.lineHeight = '1'
                tr.appendChild(td9)
            } else {
                const td5 = document.createElement('td')
                td5.innerHTML = addSalary.toString() || '0'
                if (cleaning.salary?.type === 'SINGLE') {
                    td5.style.textDecoration = 'line-through'
                }
                tr.appendChild(td5)

                const td6 = document.createElement('td')
                td6.innerHTML = !cleaning.rate ? '-' : cleaning.rate?.toString()
                tr.appendChild(td6)

                const td7 = document.createElement('td')
                td7.innerHTML = cleaning.comment?.toString() || '-'
                tr.appendChild(td7)

                const td8 = document.createElement('td')
                td8.innerHTML = cleaning.qualityRating?.toString() || '-'
                tr.appendChild(td8)

                const td9 = document.createElement('td')
                td9.innerHTML = cleaning.serviceRating?.toString() || '-'
                tr.appendChild(td9)
            }

            domHtml.querySelector('#tableFooter')!.before(tr)
        })

    if (values.jobPosition.includes('Бригадир')) {
        const cleanings = values.cleanings.filter((w) => startDate.isSameOrBefore(fetchDate(w.cleaningDate)) && endDate.isSameOrAfter(fetchDate(w.cleaningDate)))
        let newValues = cleanings
            .reduce((accum, e, i) => {
                return ({
                    petrol: Number(e.petrolExpanses) + accum.petrol,
                    salary: Number(e.salaryAmount) + accum.salary,
                    addSalary: Number(e.addSalary) + accum.addSalary,
                    rate: calculateRate(e.serviceRating, e.qualityRating, Number(accum.rate)).toString(),
                    rateCount: (e.serviceRating !== '-' || e.qualityRating !== '-') ? accum.rateCount + 1 : accum.rateCount,
                })
            }, { petrol: 0, salary: 0, addSalary: 0, rate: '', rateCount: 0 })
        newValues = {
            ...newValues,
            rate: newValues.rateCount === 0 ? '-' : (Number(newValues.rate) / newValues.rateCount).toFixed(1), // toFixed return string
        }
        const tr = document.createElement('tr')
        tr.innerHTML = `<th colspan='3' style='text-align: right'>Итог:</th><td>${newValues.salary + newValues.addSalary}</td><td>${newValues.petrol}</td><th style='text-align: right'>Оценка:</th><td colspan='2'>${newValues.rate}</td><td></td>`
        domHtml.querySelector('#tableFooter')!.before(tr)

        domHtml.querySelector('#tableHeader')!.innerHTML = `<td>№</td>
        <td>Дата</td>
        <td>Адрес</td>
        <td>Ставка</td>
        <td>Бензин</td>
        <td>Комментарий</td>
        <td>Качество работ</td>
        <td>Клиент. сервис</td>
        <td>Обратная свзять от Клиента</td>`

        domHtml.querySelector('#tableHeaderGroup')!.innerHTML = `<th colspan='6'>Отчет по уборке</th>
                <th colspan='3'>Контроль качества</th>`
    }
    domHtml.querySelector('#salaryAmount')!.innerHTML = formatRUB(salaryAmount, false)


    return { style: domHtml.querySelector('style')!.innerHTML, body: domHtml.querySelector('body')!.innerHTML }
}
