import { ClientServiceResponse } from '~/API/clientApi/client.types'
import './ClientServiceItems.scss'
import {
    ClientServiceItemsSection,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientService/subComponents/ClientServiceItems/ClientServiceItemsSection/ClientServiceItemsSection'

interface ClientServiceItemsProps {
    className?: string
    data: ClientServiceResponse[]
}

export const ClientServiceItems = (props: ClientServiceItemsProps) => {
    const {
        className,
        data,
    } = props

    return (
        <div className='client-service-items'>
            <div className='client-service-items__container'>
                <span>Дата</span>
                <span>Вид услуги</span>
                <span>Доп услуги</span>
                <span>Стоимость</span>
                <span>Ссылка на сделку</span>
                <span></span>
            </div>
            {data.map((item, index) => {
                return (
                    <ClientServiceItemsSection
                        key={item.id}
                        item={item}
                    />
                )
            })}
        </div>
    )
}
