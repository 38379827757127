import { KpoFormData } from './kpo.types'

export const KPO_DEFAULT_VALUES: KpoFormData = {
    propertyInformation: {
        address: '',
        objectType: '',
        objectClass: '',
        serviceType: '',
        isPassNeeded: undefined,
        documentRequirements: '',
        personnelRequirements: '',
        counterparty: null,
        cleaningStartDate: '',
        paymentType: '',
        hasTender: undefined,
        hasTechnicalTask: undefined,
        objectSchedule: '',
        contactPerson: '',
        comment: '',
    },
    technicalIndicator: {
        hasPlans: undefined,
        innerArea: '',
        bathroomNumber: '',
        ceilingHeight: '',
        walls: '',
        furniture: '',
        personnelNumber: '',
        cluttering: '',
        pollution: '',
        outerArea: '',
        covering: '',
        hasGreenArea: undefined,
        facadeArea: '',
        roofArea: '',
        flooring: '',
        draining: '',
        personnelRoom: '',
        innerGlazing: '',
        hasOuterArea: false,
    },
    staffIndicator: {
        maintenancePersonnelNumber: '',
        schedule: '',
        wageFund: '',
        isRegistrationNeeded: '',
    },
    inventoryEquipment: {
        sanitaryConsumables: '',
        inventoryRequirements: '',
        internalEquipment: '',
        externalEquipment: '',
    },
    additionalWork: {
        isWashingFacades: undefined,
        isAlpinism: undefined,
        isRotaryCleaning: undefined,
        isDryCleaning: undefined,
        isCarpetService: undefined,
        isMechanizedCleaning: undefined,
        isSnowRemoval: undefined,
        isLandscaping: undefined,
        otherServices: '',
    },
    windows: {
        windowNumber: '',
        glazingType: [],
        frameType: [],
        sillType: [],
        hasJalousie: '',
        hasMosquitoNet: '',
        hasSecondLightWindows: '',
        pollutionType: '',
        alpinism: '',
        comment: '',
    },
    balconies: {
        hasBalcony: true,
        balconies: '',
        glazingType: '',
        frameType: [],
        needDismantling: '',
        pollutionType: '',
        comment: '',
    },
    rooms: {
        premises: '',
        walls: '',
        floors: '',
        ceilings: '',
        furniture: '',
        bedclothes: '',
        curtains: '',
        dryCleaning: '',
        comment: '',
    },
    kitchen: {
        kitchen: '',
        walls: '',
        floors: '',
        furniture: '',
        appliances: '',
        comment: '',
    },
    bathroom: {
        bathroom: '',
        walls: '',
        floors: '',
        bath: '',
        showerCabin: '',
        appliances: '',
        comment: '',
    },
    other: {
        hasOther: true,
        sauna: '',
        cluttering: '',
        pollution: '',
        equipment: '',
        services: '',
        comment: '',
    },
    calculation: {
        personnel: '',
        days: '',
        logistics: '',
        equipment: '',
    },
}

export const KPO_TEST_VALUES: KpoFormData = {
    propertyInformation: {
        address: 'г Санкт-Петербург, ул Гороховая',
        objectType: 'Квартира',
        objectClass: '2k.',
        serviceType: 'Поддерживающая уборка',
        isPassNeeded: true,
        documentRequirements: 'требования к документам на пропуск',
        personnelRequirements: 'РФ',
        counterparty: null,
        cleaningStartDate: 'Начнем завтра(наверное)',
        paymentType: 'Наличные',
        hasTender: true,
        hasTechnicalTask: true,
        objectSchedule: 'График работы объекта',
        contactPerson: 'Человек Человекович',
        comment: 'Комментарий',
    },
    technicalIndicator: {
        hasPlans: true,
        innerArea: '20',
        bathroomNumber: '1',
        ceilingHeight: '3',
        walls: 'обои',
        furniture: 'Стол, кровать',
        personnelNumber: '5',
        cluttering: 'Небольшая',
        pollution: 'Средняя',
        outerArea: '10',
        covering: 'Не усовершенствованное',
        hasGreenArea: true,
        hasMowing: true,
        facadeArea: '15',
        roofArea: '25',
        flooring: 'паркет',
        draining: 'Хороший слив',
        personnelRoom: 'есть комната для персонала',
        innerGlazing: 'стекло на входных зонах',
        hasOuterArea: true,
    },
    staffIndicator: {
        maintenancePersonnelNumber: 'мало или много',
        schedule: 'каждый день',
        wageFund: 'фот ',
        isRegistrationNeeded: 'Частично',
    },
    inventoryEquipment: {
        sanitaryConsumables: 'Предоставляются заказчиком',
        inventoryRequirements: 'Среднее',
        internalEquipment: 'Оборудование внутренняя территория',
        externalEquipment: 'Оборудование внешняя территория',
    },
    additionalWork: {
        isWashingFacades: true,
        isAlpinism: true,
        isRotaryCleaning: true,
        isDryCleaning: true,
        isCarpetService: true,
        isMechanizedCleaning: true,
        isSnowRemoval: true,
        isLandscaping: true,
        otherServices: 'Иные услуги',
    },
    windows: {
        windowNumber: '5',
        glazingType: ['стандарт'],
        frameType: ['пластик'],
        sillType: ['пластик'],
        hasJalousie: 'да',
        hasMosquitoNet: 'да',
        hasSecondLightWindows: 'да',
        pollutionType: 'среднее',
        alpinism: 'нет',
        comment: '-',
    },
    balconies: {
        hasBalcony: true,
        balconies: '2 балкона',
        glazingType: 'стандарт',
        frameType: ['пластик'],
        needDismantling: 'да',
        pollutionType: 'сильное',
        comment: '-',
    },
    rooms: {
        premises: 'Помещения',
        walls: 'Стены',
        floors: 'Полы',
        ceilings: 'Высота потолков',
        furniture: 'внутри и снаружи',
        bedclothes: 'постирать',
        curtains: 'развесить',
        dryCleaning: 'почистить диван',
        comment: '-',
    },
    kitchen: {
        kitchen: 'кухня',
        walls: 'Стены кухня',
        floors: 'Полы кухня',
        furniture: 'только снаружи',
        appliances: 'только снаружи',
        comment: '-',
    },
    bathroom: {
        bathroom: 'два санузла',
        walls: 'Стены',
        floors: 'Полы',
        bath: 'есть',
        showerCabin: 'нет',
        appliances: 'на месте',
        comment: '-',
    },
    other: {
        hasOther: true,
        sauna: 'есть сауна',
        cluttering: 'Средняя',
        pollution: 'Сильная',
        equipment: 'не нужно',
        services: 'не нужны',
        comment: '-',
    },
    calculation: {
        personnel: 'Количество персонала',
        days: 'Количество дней',
        logistics: 'Логистика / парковка',
        equipment: 'Доп. оборудование / аренда',
    },
}
