import { User } from '~/API/userApi/user.types'
import { rules } from '~/shared/order/rules'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'

export const CHIEF_REPORT_SECTIONS = {
    chiefExpansesReport: () => ({
        type: 'reportTable',
        name: 'chiefExpansesReport',
        isMaintenance: true,
        table1: {
            caption: 'Информация по объекту',
            th: ['Наименование', 'План'],
            name: 'object',
        },
        table2: {
            caption: 'Денежные данные',
            th: ['Наименование', 'План', 'Факт', 'Экономия / перерасход'],
            name: 'expanses',
        },
    }),
    chiefDecisionAction: (disabled?: boolean) => ({
        disabled,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите действие',
        items: [
            {
                value: 'ORDER_CLOSED',
                text: 'Закрыть заявку',
                icon: 'forward',
            },
            {
                value: 'WORKING_PLAN_APPROVING',
                text: 'Вернуть на запуск уборки',
                icon: 'back',
            },
        ],
        type: 'action',
        version: 'v2',
    }),
}

interface MAINTENANCE_FINAL_REPORT_PARAMS {
    user: User,
    currentStage: string
    archived: boolean
}

export const MAINTENANCE_FINAL_REPORT = (params: MAINTENANCE_FINAL_REPORT_PARAMS) => {
    const {
        user,
        currentStage,
        archived,
    } = params
    const newInputs = []

    if (rules[user?.role]?.sections.chiefReportView) {
        newInputs.push([CHIEF_REPORT_SECTIONS.chiefExpansesReport()])
    }

    if (rules[user?.role]?.sections.chiefReport) {
        const action = fetchFormAction({
            archived,
            section: 'CHIEF_REPORT',
            user,
            currentStage,
        })
        if (action) {
            newInputs.push([action])
        }
    }

    return {
        title: 'Результат запуска объекта',
        active: true,
        name: 'chiefReport',
        inputs: [...newInputs],
    }
}
