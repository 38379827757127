import { UseFormGetValues } from 'react-hook-form'
import { StageManager } from './StageManager'

interface fetchFileRulesParams {
    fileModel?: any,
    user?: any,
    currentStage?: string,
    inputName: string,
    getValues?: UseFormGetValues<any>
}

export const fetchFileRules = (params: fetchFileRulesParams) => {
    const {
        fileModel,
        user,
        currentStage,
        inputName,
        getValues,
    } = params
    const splitName = splitInputName(inputName)
    const availableStagesForInput: (string | null | undefined)[] = StageManager.getAvailableStagesForInput(splitName)
    const availableStages = availableStagesForInput.includes(currentStage)

    if (getValues &&
        getValues()?.documents?.foremanReport?.daysReport?.length === 1 &&
        inputName === 'documents.foremanReport.daysReport.0.prepaymentCheck' &&
        availableStages
    ) { // sorry for that todo make additional check function in new constructor
        return {
            edit: false,
            download: true,
            delete: true,
        }
    }

    if (!fileInputs[splitName as keyof typeof fileInputs]) {
        return {
            edit: false,
            download: true,
            delete: false,
        }
    }
    return fileInputs[splitName as keyof typeof fileInputs](availableStages)
}

// Вынести в inputs?
const fileInputs = {
    requisites: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    prepayment: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    customContract: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    customContractContractor: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    photosObject: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    objectPhotos: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    other: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    contract: () => ({
        edit: false,
        download: true,
        delete: false,
    }),
    act: () => ({
        edit: false,
        download: true,
        delete: false,
    }),
    nzFront: () => ({
        edit: false,
        download: true,
        delete: false,
    }),

    contractPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    addContractPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    addRegisterPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    additionalServicesPhotos: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    workBeginning: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    workProcess: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    workInterimProcess: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    workEnding: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    nzBack: () => ({
        edit: false,
        download: true,
        delete: false,
    }),
    nzFrontPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    paymentCheck: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    prepaymentContractor: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    prepaymentCheck: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    nzBackPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    qualityControlFiles: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    mistakesFiles: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    passportPhoto: () => ({
        edit: false,
        download: true,
        delete: true,
    }),
    photo: () => ({
        edit: false,
        download: true,
        delete: true,
    }),
    otherDocuments: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    prepaymentPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    ownContract: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    updPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    paymentPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    actPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    inspectionPayment: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    organizationProxyId: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    actualAmortization: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    contractorAccountingPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    contractorAccountingActPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    contractorAccountingPaymentPhoto: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
    memo: (check: boolean) => ({
        edit: false,
        download: true,
        delete: check,
    }),
}

const splitInputName = (name: string) => {
    const splitName = name.split('.')

    // todo: refactor
    if (splitName.includes('actualAmortization')) {
        return 'actualAmortization'
    }

    if (splitName.length > 0) {
        return splitName[splitName.length - 1]
    }
    return name
}
