import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { ToolGroupEnum } from '~/API/toolsApi/tools.types'
import { TabsTableContext } from '~/components/common/ReusingComponents/TabsTable/TabsTableContext'
import { toolsTypes } from '~/shared/util/types'

interface UseFileTitleOptions {
    sectionName: string
    rowIndex: number
}

export const useFileTitle = (options: UseFileTitleOptions): string => {
    const {
        sectionName,
        rowIndex,
    } = options

    const {
        baseName,
        activeTabIndex,
    } = useContext(TabsTableContext)

    const { getValues } = useFormContext()

    switch (true) {
        // tools report actualAmortization table
        case (sectionName === ToolGroupEnum.INVENTORY || sectionName === ToolGroupEnum.EQUIPMENT || sectionName === ToolGroupEnum.OVERALL || sectionName === ToolGroupEnum.COMPONENTS || sectionName === ToolGroupEnum.APPLIANCES): {
            const row = getValues(`${baseName}.${activeTabIndex}.items.${rowIndex}`)
            return `${toolsTypes[sectionName as ToolGroupEnum]} - ${row?.name}`
        }
        // personnel report actualTeam table
        case (baseName.includes('actualTeam')): {
            const row = getValues(`${baseName}.${rowIndex}`)
            return `СЛУЖЕБНАЯ ЗАПИСКА - ${row?.name}`
        }
        default:
            return 'Файл'
    }
}
