import { KpAdditionalService, KpInput, KpServiceForm } from './kp.types'
import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import { ValidationType } from '~/hooks/useFormConstructor/lib/additionalFunctions/validateInput'
import range from '~/shared/util/range'

export const DISABLED_STAGES = [
    'COMMERCIAL_OFFER_APPROVING',
    'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING',
    'DATE_BOOKING',
    'REPEAT_CLIENT_STARTING',
]

export const DISCOUNT_OPTIONS = range(0, 51, 5).map((i) => ({
    value: i.toString(),
    text: `${i}%`,
}))

const MEASURE_OPTIONS = [
    {
        value: 'шт.',
        text: 'шт.',
    },
    {
        value: 'м2',
        text: 'м2',
    },
    {
        value: 'м3',
        text: 'м3',
    },
    {
        value: 'услуга',
        text: 'услуга',
    },
]

export const KP_DEFAULT_VALUES: KpServiceForm = {
    services: [{
        name: '',
        measure: '',
        schedule: '',
        workDescription: '',
        personnelDescription: '',
        discount: '0',
        amount: 0,
        isSelected: true,
        tasks: [
            {
                type: 'Комната',
                title: 'Комната',
                items: [],
            },
        ],
    }],
    additionalServices: [{
        type: 'additionalServices',
        title: 'Дополнительные работы',
        items: [] as KpAdditionalService[],
    }],
    company: '',
}

export const KP_ACTION_OPTIONS = [
    {
        text: 'Удалить услугу',
        value: 'deleteService',
    },
    {
        text: 'Добавить услугу',
        value: 'addService',
    },
    {
        text: 'Дублировать услугу',
        value: 'copyService',
    },
]

export const KP_INPUTS: KpInput[][] = [
    [
        {
            'type': 'text',
            'disabled': false,
            'title': 'Наименование услуги',
            'name': 'name',
            'className': 'kpServiceSection__input50',
            'required': true,
        },
        {
            'type': 'text',
            'disabled': false,
            'title': 'Кол-во',
            'name': 'amount',
            'required': true,
            'className': 'input25',
            'mask': InputMask.NUMBER,
        },
        {
            'type': 'select',
            'disabled': false,
            'title': 'Ед. измерения',
            'name': 'measure',
            'required': true,
            'className': 'kpServiceSection__input25',
            'items': MEASURE_OPTIONS,
        },
    ],
    [
        {
            'type': 'text',
            'multiline': true,
            'disabled': false,
            'title': 'График работы',
            'name': 'schedule',
            'required': true,
        },
    ],
    [
        {
            'type': 'text',
            'disabled': false,
            'title': 'План выполнения работ (это не перечень работ!!!)',
            'name': 'workDescription',
            'required': true,
            'multiline': true,
        },
    ],
    [
        {
            'type': 'text',
            'disabled': false,
            'title': 'Кол-во персонала',
            'name': 'personnelDescription',
            'required': true,
            'multiline': true,
        },
    ],
    [
        {
            type: 'table',
            disabled: false,
            required: true,
            name: 'tasks',
            title: 'Перечень работ',
            withToolbar: true,
            withHideButton: true,
            withAddButton: true,
            validationType: ValidationType.ITEMS_NOT_NULL,
            headerSections: [
                {
                    'name': 'Комната',
                    'title': 'Комната',
                    'inputs': [
                        {
                            'name': 'name',
                            'title': 'Наименование',
                            'default': '',
                            'required': true,
                            'className': 'td50',
                        },
                        {
                            'name': 'description',
                            'title': 'Примечание',
                            'default': '',
                            'className': 'td50',
                        },
                    ],
                },
                {
                    'name': 'Кухня',
                    'title': 'Кухня',
                    'inputs': [
                        {
                            'name': 'name',
                            'title': 'Наименование',
                            'default': '',
                            'required': true,
                            'className': 'td50',
                        },
                        {
                            'name': 'description',
                            'title': 'Примечание',
                            'default': '',
                            'className': 'td50',
                        },
                    ],
                },
                {
                    'name': 'Окна',
                    'title': 'Окна',
                    'inputs': [
                        {
                            'name': 'name',
                            'title': 'Наименование',
                            'default': '',
                            'required': true,
                            'className': 'td50',
                        },
                        {
                            'name': 'description',
                            'title': 'Примечание',
                            'default': '',
                            'className': 'td50',
                        },
                    ],
                },
                {
                    'name': 'Балкон',
                    'title': 'Балкон',
                    'inputs': [
                        {
                            'name': 'name',
                            'title': 'Наименование',
                            'default': '',
                            'required': true,
                            'className': 'td50',
                        },
                        {
                            'name': 'description',
                            'title': 'Примечание',
                            'default': '',
                            'className': 'td50',
                        },
                    ],
                },
                {
                    'name': 'Санузел',
                    'title': 'Санузел',
                    'inputs': [
                        {
                            'name': 'name',
                            'title': 'Наименование',
                            'default': '',
                            'required': true,
                            'className': 'td50',
                        },
                        {
                            'name': 'description',
                            'title': 'Примечание',
                            'default': '',
                            'className': 'td50',
                        },
                    ],
                },
            ],
        },
    ],
]

export const ADDITIONAL_SERVICES_INPUTS = [
    {
        'name': 'name',
        'title': 'Наименование доп. услуги',
        'className': 'table__td_40',
        'required': true,
    },
    {
        'name': 'amount',
        'title': 'Кол-во',
        'defaultValue': '1',
        'className': 'table__td_10',
        'required': true,
        'mask': InputMask.NUMBER,
        'isSubscription': true,
    },
    {
        'name': 'measure',
        'title': 'Ед. измерен.',
        'type': 'select',
        'items': MEASURE_OPTIONS,
        'defaultValue': 'шт.',
        'className': 'table__td_10',
        'required': true,
    },
    {
        'name': 'unitPrice',
        'title': 'Цена за ед.',
        'className': 'table__td_10',
        'required': true,
        'defaultValue': '0',
        'mask': InputMask.NUMBER,
        'disabled': true,

    },
    {
        'name': 'price',
        'title': 'Стоимость',
        'className': 'table__td_10',
        'required': true,
        'defaultValue': '0',
        'mask': InputMask.NUMBER,
        'isSubscription': true,
    },
    {
        'name': 'discount',
        'title': 'Скидка',
        'className': 'table__td_10',
        'required': true,
        'type': 'select',
        'defaultValue': '0',
        'items': DISCOUNT_OPTIONS,
        'isSubscription': true,
    },
    {
        'name': 'priceWithDiscount',
        'title': 'Стоимость со скидкой',
        'className': 'table__td_10',
        'required': true,
        'defaultValue': '0',
        'disabled': true,
    },
]

export const KP_COST_SECTION_LABELS = {
    price: 'Общая стоимость',
    discount: 'Скидка',
    discountPrice: 'Стоимость со скидкой',
    discountAmount: 'Сумма скидки',
}

export const KP_TABLE_HEADER = [
    {
        title: 'Выбрать все услуги',
    },
    {
        title: 'Кол-во',
    },
    {
        title: 'Ед. измерен.',
    },
    {
        title: 'Цена за един.',
    },
    {
        title: 'Стоимость',
    },
    {
        title: 'Скидка',
    },
    {
        title: 'Стоимость\n' +
            'со скидкой',
    },
]


export const PAYMENT_TYPE_OPTIONS = [
    {
        value: 'р/с (НДС)',
        text: 'р/с (НДС)',
    },
    {
        value: 'р/с (без НДС)',
        text: 'р/с (без НДС)',
    },
    {
        value: 'Наличные',
        text: 'Наличные',
    },
]
