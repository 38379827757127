import { useEffect } from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import { LoginPage } from '../pages/Login'
import MobileWrapper from './subComponents/MobileWrapper/MobileWrapper'
import Wrapper from './subComponents/Wrapper/Wrapper'
import { AppLayout } from '~/components/app/layouts/AppLayout/AppLayout'
import { RequireAuth } from '~/components/app/subComponents/RequireAuth/RequireAuth'
import PopupLoader from '~/components/common/preloaders/PopupLoader/PopupLoader'
import useLocalStorage from '~/hooks/useLocalStorage'
import { useUpdateUser } from '~/hooks/useUpdateUser/useUpdateUser'
import { selectToken, selectUser } from '~/redux/selectors/appSlice.selectors'
import { DELETE_USER_INFO } from '~/redux/slices/appSlice/appSlice'
import { setFilterValues } from '~/redux/slices/FilterSlice/FilterSlice'
import { CHANGE_DIVISION } from '~/redux/slices/orderSlice/orderSlice'
import { rules } from '~/shared/order/rules'
import { isMobile } from '~/shared/util/deviceDetect'
import { getGlobalDivision } from '~/shared/util/localStorage'
import Test2 from '~/test/Test2'

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<AppLayout />}>
            <Route
                path='/test'
                element={<Test2 />}
            />
            <Route
                path='/login'
                element={<LoginPage />}
            />
            <Route
                index
                path='/*'
                element={
                    <RequireAuth>
                        {isMobile ? <MobileWrapper /> : <Wrapper />}
                    </RequireAuth>
                }
            />
        </Route>,
    ),
)

Modal.setAppElement('#root')

function App() {
    const dispatch = useDispatch()
    const token = useSelector(selectToken)
    const user = useSelector(selectUser)

    const updateUser = useUpdateUser()

    const [localUserId, setLocalUserId, removeLocalUserId] = useLocalStorage({ key: 'userId', initialValue: '' })

    useEffect(() => {
        if (token) {
            updateUser()
        } else {
            dispatch(DELETE_USER_INFO())
            removeLocalUserId()
        }
    }, [token])

    useEffect(() => {
        if (user) {
            !user.division.includes(getGlobalDivision()) && dispatch(CHANGE_DIVISION(user.division[0]))
            if (!localUserId) {
                dispatch(setFilterValues(rules[user.role].defaultFilter))
                setLocalUserId(user.id)
            }
        }
    }, [user])

    return (
        <RouterProvider
            router={router}
            fallbackElement={<PopupLoader />}
        />
    )
}

export default App
