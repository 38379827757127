import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import Modal from 'react-modal'
import CustomCheckbox from '../../../../common/checkbox/CustomCheckbox/CustomCheckbox'
import { CustomMultipleSelectorThemes } from '~/components/common/inputs/CustomMultipleSelector/CustomMultipleSelector'
import { InputParamsItem, InputTemplateType } from '~/hooks/useFormConstructor/useFormConstructor.types'
import './MobileCustomMultipleSelector.scss'

interface MobileCustomMultipleSelectorProps {
    placeholder?: string,
    name: string,
    items?: InputParamsItem<any>[] | Record<string, InputParamsItem<any>[]> | InputTemplateType,
    values: any[],
    onChange: (item: any) => void,
    title?: string,
    className?: string,
    disabled?: boolean,
    theme?: CustomMultipleSelectorThemes
}

const MobileCustomMultipleSelector = (props: MobileCustomMultipleSelectorProps) => {
    const {
        placeholder,
        name,
        items= [],
        values,
        onChange,
        title,
        className,
        disabled,
        theme = CustomMultipleSelectorThemes.COLOR,
    } = props
    const [isActive, setActive] = useState<boolean>(false)

    const handleCheckbox = useCallback((e: any) => {
        const newValues = [...values]
        if (e.target.checked) {
            newValues.push((items as InputParamsItem<any>[]).find((item) => item.value === e.target.name)?.value)
        } else {
            const pos = newValues.findIndex((value) => value === e.target.name)
            if (pos !== -1) {
                newValues.splice(pos, 1)
            }
        }
        onChange(newValues)
    }, [values, items, onChange])

    const handleClose = useCallback(() => {
        setActive(false)
    }, [])

    return (
        <div className={clsx('mobileCustomMultipleSelector', className, { disabled })}>
            {title && <span>{title}</span>}
            <div
                className={clsx('customMultipleSelector__selector', theme && `customMultipleSelector__selector--${theme}`)}
                onClick={() => !disabled && setActive((prev) => !prev)}
            >
                {!values || values.length === 0 ? (
                    <span>{placeholder}</span>
                ) : (
                    <span className='customMultipleSelector__value'>
                        {
                            values
                                .filter((value) => Object.values(items).find((item) => item.value === value))
                                .map((value) => Object.values(items).find((item) => item.value === value)?.text).join(', ')
                        }

                    </span>
                )}
                <ExpandMoreIcon className={isActive ? 'active' : ''} />
            </div>
            {isActive && (
                <Modal
                    isOpen={isActive}
                    onRequestClose={handleClose}
                    id='customSelectModal'
                    ariaHideApp={false}
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: '#78788566',
                            backdropFilter: 'blur(2.5px)',
                        },
                        content: {
                            position: 'absolute',
                            top: '40px',
                            left: '40px',
                            right: '40px',
                            bottom: '40px',
                            border: '1px solid #ccc',
                            background: '#fff',
                            overflow: 'auto',
                            WebkitOverflowScrolling: 'touch',
                            borderRadius: '4px',
                            outline: 'none',
                            padding: '20px',
                        },
                    }}
                >
                    <div className='mobileCustomMultipleSelector__popupItems'>
                        <div className='mobileCustomMultipleSelector__items'>
                            {Object.values(items).map((item) => (
                                <CustomCheckbox
                                    key={`${name}_${item.value}`}
                                    id={`${name}_${item.value}`}
                                    onChange={handleCheckbox}
                                    value={values && values.some((value) => value === item.value)}
                                    caption={item.text}
                                    name={item.value}
                                />
                            ))}
                        </div>
                        <button
                            className={'mobileCustomMultipleSelector__button'}
                            onClick={() => {
                                onChange([])
                            }}
                            type={'button'}
                            disabled={!values || values.length === 0}
                        >
                            Сбросить
                        </button>
                    </div>
                    <div
                        className='fixedWrapper'
                        onClick={() => {
                            !disabled && setActive(false)
                        }}
                    />
                </Modal>
            )}
        </div>
    )
}

export default MobileCustomMultipleSelector
