import React, { useRef, useState } from 'react'
import './popupOrganization.scss'
import '../../../popups.scss'
import { useDispatch } from 'react-redux'
import ArchiveSvg from '../../../../../../assets/svg/archive.svg'
import CloseIcon from '@mui/icons-material/Close'
import CustomInput from '../../../../inputs/CustomInput/CustomInput'
import { closeBankPopup } from '~/redux/slices/popupSlice/popupSlice'

const PopupOrganization = () => {
    const ref = useRef<HTMLDivElement | null>(null)
    const dispatch = useDispatch()
    const [value, setValue] = useState('')


    const handleClose = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            dispatch(closeBankPopup())
        }
    }

    return (
        <div className={'popupWrapper'} onClick={handleClose}>
            <div className='popup popupToArchive' ref={ref}>
                <div className='popupToArchive__header'>
                    <div className='popupToArchive__icon'>
                        <ArchiveSvg />
                    </div>
                    <div className='popupToArchive__header__body'>
                        <h4>Сохранение банковских данных</h4>
                    </div>
                </div>
                <div className='popupOrganization__body'>
                    <CustomInput title='Название банка' name='bankName' />
                    <CustomInput title='Идентификатор банка' name='bankId' />
                    <CustomInput title='Банковский счёт' name='bankAccount' />
                    <CustomInput title='Корреспондентский счёт' name='correspondentAccount' />
                </div>
                <button className={'button'} type='submit'>Сохранить</button>
                <button className={'button'}>Отмена</button>
                <CloseIcon className={'close'} onClick={() => {
                    dispatch(closeBankPopup())
                }} />
            </div>
        </div>
    )
}

export default PopupOrganization
