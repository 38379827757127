import {
    FetchUsersByRoleParams,
    FetchUsersByRolesParams,
    UpdateUser,
    UpdateUserLesson,
} from './user.types'
import { instance } from '~/API/lib/axios/axios'
import { fetchPath } from '~/API/lib/path/path'
import { User } from '~/API/userApi/user.types'
import { rtkApi } from '~/shared/api/rtkApi'
import { getGlobalDivision } from '~/shared/util/localStorage'

const path = fetchPath('user')

const userApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchUsersByRole: build.query<User[], FetchUsersByRoleParams>({
            query: ({
                role,
                withLocked = false,
                division,
            }) => ({
                method: 'GET',
                url: path + '/role/' + role,
                params: {
                    withLocked,
                    division,
                },
            }),
            providesTags: ['User'],
        }),
        fetchUserInfo: build.query<User, void>({
            query: () => ({
                method: 'GET',
                url: path + '/about-me',
            }),
            providesTags: ['User'],
        }),
        fetchAllUsers: build.query<User[], any | void>({
            query: () => ({
                method: 'GET',
                url: path + '/all',
            }),
            providesTags: ['User'],
        }),
        fetchUserById: build.query<User, string>({
            query: (userId) => ({
                method: 'GET',
                url: path + '/' + userId,
            }),
            providesTags: ['User'],
        }),
        fetchUsersByRoles: build.mutation<User[], FetchUsersByRolesParams>({
            query: ({
                roles,
                division = getGlobalDivision(),
                locked,
            }) => ({
                method: 'POST',
                url: path + '/roles',
                body: roles,
                params: {
                    division,
                    locked,
                },
            }),
        }),
        updateUser: build.mutation<User[], UpdateUser>({
            query: ({
                userId,
                role,
            }) => ({
                method: 'POST',
                url: path + '/' + userId,
                params: {
                    userId,
                    role,
                },
            }),
            invalidatesTags: ['User'],
        }),
        updateUserLesson: build.mutation<User[], UpdateUserLesson>({
            query: ({
                lessonName,
                status,
            }) => ({
                method: 'POST',
                url: path + '/lesson',
                params: {
                    lessonName,
                    status,
                },
            }),
            invalidatesTags: ['User'],
        }),
    }),
})

export const {
    useLazyFetchUsersByRoleQuery,
    useLazyFetchUserByIdQuery,
    useLazyFetchAllUsersQuery,
    useLazyFetchUserInfoQuery,
    useFetchUsersByRolesMutation,
    useUpdateUserMutation,
    useUpdateUserLessonMutation,
} = userApi

export const fetchUserById = async (userId: string) =>
    await instance({
        method: 'get',
        url: path + '/' + userId,
    })
        .then((response) => {
            return response.data as User
        })
        .catch((error) => {
        })
