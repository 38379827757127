import clsx from 'clsx'
import React from 'react'
import './ClientData.scss'
import { ClientDataItem } from './subComponents/ClientDataItem/ClientDataItem'
import { ClientResponse } from '~/API/clientApi/client.types'
import {
    ItemType,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientOrganization/ClientOrganization.types'

interface ClientDataProps {
    className?: string
    data: ClientResponse<ItemType>[] | ItemType[]
    handleSetData?: (value: any) => void
    currentItem: ClientResponse<ItemType> | ItemType
}

export const ClientData = (props: ClientDataProps) => {
    const {
        className,
        data,
        handleSetData,
        currentItem,
    } = props

    return (
        <>
            {data?.length ? (
                <ul className={clsx('client-data', className)}>
                    {data.map((option, index: number) => {
                        return (
                            <ClientDataItem
                                key={`${('additionalParams' in option) ? option.additionalParams.value : option.value}_${index}`}
                                option={option}
                                handleSetData={handleSetData}
                                currentItem={currentItem}
                            />
                        )
                    })}
                </ul>
            ) : (
                <span className='client-data__empty'>По вашему запросу ничего не найдено</span>
            )}
        </>
    )
}
