import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { useSelector } from 'react-redux'
import { useLazyFetchContractorByIdQuery } from '~/API/contractorApi/contractor'
import { useLazyFetchOrganizationByInnQuery } from '~/API/organizationApi/organization'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'
import { toLowerOOO } from '~/hooks/useUpdateOrganization/lib/helpers'
import { selectReplaceMethod } from '~/redux/slices/clientSlice/clientSlice.selectors'
import { getObjectValueByPath } from '~/shared/util/getObjectValueByPath'

interface UseUpdateOrganizationParams {
    onChange: (...args: any[]) => void
    additionalMethods?: UseFormReturn
    clientType: ClientInfoEnum
}

const fieldNames = [
    'bankName',
    'bankId',
    'bankAccount',
    'correspondentAccount',
    'inn',
    'kpp',
    'ogrn',
    'management.name',
    'management.post',
    'address',
    'postAddress',
    'unrestricted_value',
    'value',
    'isProxy',
    'proxyFullname',
    'proxyAuthorityNumber',
    'proxyAuthority',
]

export const useUpdateOrganization = (params: UseUpdateOrganizationParams) => {
    const {
        onChange,
        additionalMethods,
        clientType,
    } = params
    const [fetchOrganizationByInn] = useLazyFetchOrganizationByInnQuery()
    const [fetchContractorById] = useLazyFetchContractorByIdQuery()

    const replace = useSelector(selectReplaceMethod)

    const {
        setValue,
        clearErrors,
        reset,
        getValues,
    } = useFormContext()

    return async (values: any) => {
        let management
        switch (values.data.type) {
            case 'INDIVIDUAL':
                management = {
                    name: clsx(values.data.fio?.surname, values.data.fio?.name, values.data.fio?.patronymic),
                    post: 'Индивидуальный предприниматель',
                }
                break

            case 'LEGAL':
                management = {
                    name: values.data.management?.name,
                    post:
                        values.data.management?.post?.substring(0, 1) +
                        values.data.management?.post?.substring(1).toLowerCase(),
                }
                break
            default:
                management = {
                    name: '',
                    post: '',
                }
        }

        const newValues = {
            ...values.data,
            value: values?.value || values.data?.name.short_with_opf,
            unrestricted_value: values.unrestricted_value,
            name: toLowerOOO(values.data?.name.full_with_opf) || values.data?.name.short_with_opf,
            address: values.data?.address?.value,
            postAddress: values.data?.address?.unrestricted_value,
            ogrn_date: new Date(values.data?.ogrn_date),
            type: values.data.type,
            management,
        }

        let bankData: { bankName: string, bankId: string, bankAccount: string, correspondentAccount: string } = {
            bankName: '',
            bankId: '',
            bankAccount: '',
            correspondentAccount: '',
        }

        // По ИНН выборка по базе
        switch (clientType) {
            case ClientInfoEnum.ORGANIZATION: {
                await fetchOrganizationByInn(newValues.inn, true).unwrap()
                    .then((response: any) => {
                        if (onChange && response) {
                            bankData = {
                                bankName: response.bankName || '',
                                bankId: response.bankId || '',
                                bankAccount: response.bankAccount || '',
                                correspondentAccount: response.correspondentAccount || '',
                            }
                            onChange({
                                ...newValues,
                                bankData,
                            })
                            const setValueMap: Map<string, string> = new Map()
                            const clearErrorsArray: string[] = []
                            fieldNames.forEach((name) => {
                                if (getObjectValueByPath(response, name)) {
                                    setValueMap.set(`primaryInformation.organizationRequisites.${name}`, getObjectValueByPath(response, name))
                                    clearErrorsArray.push(`primaryInformation.organizationRequisites.${name}`)
                                }
                            })
                            for (const [key, value] of setValueMap.entries()) {
                                if (additionalMethods) { // если у нас есть инпут для организаций вне основной формы
                                    additionalMethods.setValue(key, value)
                                    // additionalMethods.reset({...additionalMethods.getValues(), [key]: value})
                                } else {
                                    setValue(key, value)
                                    // reset({...getValues(), [key]: value})
                                }
                            }
                            // reset({ ...getValues() })
                            clearErrors(clearErrorsArray)
                        }
                        onChange && !response && onChange({ ...newValues })
                    })
                    .catch(() => {
                        fieldNames.forEach((name) => {
                            if (getObjectValueByPath(newValues, name)) {
                                setValue(`primaryInformation.organizationRequisites.${name}`, getObjectValueByPath(newValues, name))
                                if (additionalMethods) {
                                    additionalMethods.setValue(`primaryInformation.organizationRequisites.${name}`, getObjectValueByPath(newValues, name))
                                }
                            } else {
                                setValue(`primaryInformation.organizationRequisites.${name}`, '')
                                if (additionalMethods) { // если у нас есть инпут для организаций вне основной формы
                                    additionalMethods.setValue(`primaryInformation.organizationRequisites.${name}`, '')
                                }
                            }
                        })
                        onChange({
                            ...newValues,
                        })
                    })
                break
            }
            case ClientInfoEnum.CONTRACTOR: {
                fieldNames.push('counterparty')
                fieldNames.push('contactInfo')
                await fetchContractorById(newValues.inn, true).unwrap()
                    .then((response: any) => {
                        if (onChange && response) {
                            bankData = {
                                bankName: response.bankName || '',
                                bankId: response.bankId || '',
                                bankAccount: response.bankAccount || '',
                                correspondentAccount: response.correspondentAccount || '',
                            }
                            onChange({
                                ...newValues,
                                bankData,
                            })
                            const setValueMap: Map<string, string | any[]> = new Map()
                            const clearErrorsArray: string[] = []
                            fieldNames.forEach((name) => {
                                // if (getObjectValueByPath(response, name)) {
                                if (name === 'counterparty') {
                                    setValueMap.set(`starting.contractor.${name}`, 'Юр. лицо')
                                } else {
                                    setValueMap.set(`starting.contractor.${name}`, getObjectValueByPath(response, name))
                                }
                                clearErrorsArray.push(`starting.contractor.${name}`)
                                // }
                            })
                            for (const [key, value] of setValueMap.entries()) {
                                if (key === 'starting.contractor.contactInfo' && replace) {
                                    replace(value || [{
                                        clientFullname: '',
                                        clientPost: '',
                                        clientPhone: '',
                                        clientEmail: '',
                                    }])
                                } else {
                                    if (additionalMethods) { // если у нас есть инпут для организаций вне основной формы
                                        additionalMethods.setValue(key, value)
                                    } else {
                                        setValue(key, value)
                                    }
                                }
                            }
                            clearErrors(clearErrorsArray)
                        }
                        onChange && !response && onChange({ ...newValues })
                    })
                    .catch(() => {
                        fieldNames.forEach((name) => {
                            if (name === 'contactInfo' && replace) {
                                replace([{ clientFullname: '', clientPost: '', clientPhone: '', clientEmail: '' }])
                            } else {
                                if (getObjectValueByPath(newValues, name)) {
                                    setValue(`starting.contractor.${name}`, getObjectValueByPath(newValues, name))
                                    if (additionalMethods) {
                                        additionalMethods.setValue(`starting.contractor.${name}`, getObjectValueByPath(newValues, name))
                                    }
                                } else {
                                    setValue(`starting.contractor.${name}`, '')
                                    if (additionalMethods) { // если у нас есть инпут для организаций вне основной формы
                                        additionalMethods.setValue(`starting.contractor.${name}`, '')
                                    }
                                }
                            }
                        })
                        setValue('starting.contractor.counterparty', 'Юр. лицо')
                        if (additionalMethods) { // если у нас есть инпут для организаций вне основной формы
                            additionalMethods.setValue('starting.contractor.counterparty', 'Юр. лицо')
                        }
                        onChange({
                            ...newValues,
                            counterparty: 'Юр. лицо',
                        })
                    })
                break
            }
        }

        return ({
            newValues,
            bankData,
        })
    }
}
