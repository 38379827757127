import { useSelector } from 'react-redux'
import { useLazyFetchOrderQuery, useUpdateBitrixDealMutation } from '~/API/orderApi/order'
import { useSaveOrganizationMutation } from '~/API/organizationApi/organization'
import { useRestoreFromArchive } from '~/hooks/useRestoreFromArchive/useRestoreFromArchive'
import { useUpdateOrder } from '~/hooks/useUpdateOrder/useUpdateOrder'
import { selectFormAction, selectUser } from '~/redux/selectors/appSlice.selectors'
import { selectActiveOrder } from '~/redux/selectors/orderSlice.selectors'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import { FormActionEnum } from '~/redux/slices/appSlice/appSlice.types'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { CHANGE_ORDER_LOADER, setStageName } from '~/redux/slices/orderSlice/orderSlice'
import { useAppDispatch } from '~/redux/store'
import { handleOrderSubmit } from '~/shared/order/orderDataService'

interface UseSubmitOrderParams {
    id: string
    setWarning?: React.Dispatch<React.SetStateAction<boolean>>
}

export const useSubmitOrder = (params: UseSubmitOrderParams) => {
    const {
        id,
        setWarning,
    } = params
    const dispatch = useAppDispatch()

    const restoreFromArchive = useRestoreFromArchive()
    const updateOrder = useUpdateOrder()

    const [fetchOrder] = useLazyFetchOrderQuery()
    const [updateBitrixDeal] = useUpdateBitrixDealMutation()
    const [saveOrganization] = useSaveOrganizationMutation()

    const formAction = useSelector(selectFormAction)
    const user = useSelector(selectUser)
    const activeOrder = useSelector(selectActiveOrder)

    return (isFormValid: boolean, formValues: any, stageName?: FormActionEnum) => {
        if (isFormValid && id) {
            if (stageName !== formValues.stageName) { // todo использовать оптимизацию и с санками
                dispatch(setStageName(formValues.stageName))
            }
            dispatch(CHANGE_ORDER_LOADER(true))
            handleOrderSubmit({
                order: formValues,
                formAction: stageName ? { action: stageName } : formAction,
                user,
                id,
                updateBitrixDeal,
                fetchOrder,
                paymentType: activeOrder?.accountingAndSelling?.commercialOffer?.paymentType || 'р/с (без НДС)',
            })
                .then(async (newOrder: any) => {
                    if (newOrder?.primaryInformation?.organizationRequisites) {
                        saveOrganization(newOrder?.primaryInformation?.organizationRequisites).unwrap()
                            .catch(() => dispatch(SHOW_NOTICE({
                                type: 'error',
                                message: 'Ошибка при сохранении организации',
                            })))
                    }
                    switch (newOrder) {
                        case 'FROM_ARCHIVE':
                            await restoreFromArchive(formValues.id, 'archiveOutReason')
                            dispatch(CHANGE_ORDER_LOADER(false))
                            return
                        case 'INFO_TO_FOREMAN_ERROR':
                            dispatch(SHOW_NOTICE({
                                type: 'error',
                                message: 'Менеджер по клинингу уже дал ответ по выезду на объект',
                            }))
                            dispatch(CHANGE_ORDER_LOADER(false))
                            return
                        case 'EDIT_WARNING_ERROR':
                            dispatch(CHANGE_ORDER_LOADER(false))
                            setWarning && setWarning(formAction.action as any)
                            return
                        case 'TRANSITION_STAGE_ERROR':
                            dispatch(SHOW_NOTICE({ type: 'error', message: 'Недопустимое действие для данной стадии' }))
                            dispatch(CHANGE_ORDER_LOADER(false))
                            return
                        default:
                            break
                    }
                    if (!newOrder) {
                        dispatch(SHOW_NOTICE({ type: 'error', message: 'Ошибка сохранения заявки' }))
                        dispatch(CHANGE_ORDER_LOADER(false))
                        return
                    }
                    await updateOrder({ ...newOrder, save: !!formAction.isSaveButton })
                })
                .catch((error: Error) => {
                    console.log(error)
                    dispatch(CHANGE_ORDER_LOADER(false))
                    dispatch(SHOW_NOTICE({ type: 'error', message: 'Ошибка выполнения этапа\n' + error }))
                })
                .finally(() => {
                    dispatch(changeFormAction({ action: 'NONE' }))
                })
        } else {
            dispatch(SHOW_NOTICE({ type: 'error', message: 'Заполнены не все поля' }))
        }
    }
}
