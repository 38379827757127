import { OrderInterface } from '~/API/orderApi/types/order.types'
import { User } from '~/API/userApi/user.types'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import { rules } from '~/shared/order/rules'
import { StageManager } from '~/shared/order/StageManager'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { returningDocuments } from '~/shared/templates/inputTemplates/returningDocuments'
import { additionalInputs } from '~/shared/templates/inputTemplates/v2/additionalInputs'

export const ACTUAL_PAYMENT_RESERVING_ACTIONS = {
    mainActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'DOCUMENTS_RETURNING',
                text: 'Отправить на возврат документов',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
}

interface ACTUAL_PAYMENT_RESERVING_PARAMS {
    order: OrderInterface
    reportDate: Date
    currentStage: StageType
    user: User
    archived: boolean
}

export const ACTUAL_PAYMENT_RESERVING = (params: ACTUAL_PAYMENT_RESERVING_PARAMS) => {
    const {
        order,
        reportDate,
        currentStage,
        user,
        archived,
    } = params
    const newInputs = []
    if (rules[user?.role]?.sections.returningDocuments) {
        const availableAction = StageManager.checkAvailableStageByRole('ACTUAL_PAYMENT_RESERVING', user.role)
        const disabled = currentStage !== 'ACTUAL_PAYMENT_RESERVING'

        if (reportDate) {
            newInputs.push([
                additionalInputs.division(true),
                additionalInputs.responsibleUserId(true, 'returningDocuments.division', 'responsibleManager'),
                additionalInputs.reportDate(true),
            ])
        } else {
            newInputs.push([
                additionalInputs.division(true),
            ])
        }
        newInputs.push([
            returningDocuments.actualPayment(disabled),
        ])

        if (availableAction && !disabled) {
            const action = fetchFormAction({
                order,
                archived,
                section: 'RETURNING_DOCUMENTS',
                user,
                currentStage,
            })
            if (action) {
                newInputs.push([action])
            }
        }
    }
    return {
        title: 'Возврат документов и постоплата',
        name: 'returningDocuments',
        inputs: [...newInputs],
    }
}
