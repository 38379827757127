import { useMemo } from 'react'
import { Control, useWatch } from 'react-hook-form'
import {
    CheckTypes,
    getSuccessStatus,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableHeader/subComponents/TabsTableCheckIcon/TabsTableCheckIcon'
import MobileTabs, { MobileTab } from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTabs/MobileTabs'

interface CheckIconsTabsProps {
    name: string;
    control: Control
    activeTabIndex: number
    tabs: MobileTab[]
    setActiveTabIndex: (index: number) => void
    checkIcons?: {
        inputName: string,
        checkType: CheckTypes,
    },
}

const CheckIconsTabs = (props: CheckIconsTabsProps) => {
    const {
        name,
        control,
        tabs,
        activeTabIndex,
        setActiveTabIndex,
        checkIcons,
    } = props

    const watchPages = useWatch({ control, name })

    const tabsWithSuccess = useMemo(() => tabs.map((tab, index) => ({
        ...tab,
        isSuccess: checkIcons && getSuccessStatus(watchPages[index].items, checkIcons.checkType, checkIcons.inputName),
    })), [watchPages, checkIcons, tabs])

    return (
        <MobileTabs
            activeTabIndex={activeTabIndex}
            tabs={tabsWithSuccess}
            setActiveTabIndex={setActiveTabIndex}
            hasCheckIcons={!!checkIcons}
        />
    )
}

export default CheckIconsTabs
