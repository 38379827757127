import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import TabsTableBody from './subComponents/TabsTableBody/TabsTableBody'
import TabsTableToolbar from './subComponents/TabsTableToolbar/TabsTableToolbar'
import Preloader from '~/components/common/preloaders/Preloader/Preloader'
import TabsTableTemplateButton
    from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTablePage/subComponents/TabsTableTemplateButton/TabsTableTemplateButton'
import { TabsTableContext } from '~/components/common/ReusingComponents/TabsTable/TabsTableContext'

import './TabsTablePage.scss'

interface TabsTablePageProps {
    name: string
    sectionName: string
    isActive: boolean
    inputs: any[]
    pageIndex: number
}

const TabsTablePage = (props: TabsTablePageProps) => {
    const {
        name,
        sectionName,
        isActive,
        inputs,
        pageIndex,
    } = props

    const {
        withToolbar,
        templates,
        onTemplateLoad,
        isStrictTemplateSetting,
        customTools,
        onRowChange,
        disabled,
        isLoading,
        baseName,
    } = useContext(TabsTableContext)

    const {
        control,
        trigger,
        formState: { isSubmitted },
    } = useFormContext()

    const {
        fields: rows,
        append: appendRow,
        update: updateRow,
        remove: removeRow,
    } = useFieldArray({ control, name })

    const [checkedRows, setCheckedRows] = useState(rows.map((_) => false))
    const [isCheckAll, setIsCheckAll] = useState(false)

    const handleCheckRow = (index: number) => {
        setCheckedRows((prev) => {
            const newChecks = [...prev]
            newChecks.splice(index, 1, !prev[index])
            return [...newChecks]
        })
    }

    const handleCheckAll = (value: boolean) => {
        setIsCheckAll(value)
        setCheckedRows((prev) => prev.map((_) => value))
    }

    const addNewRow = () => {
        appendRow(inputs.reduce((accum: any, input: any) => ({ ...accum, [input.name]: input.defaultValue || '' }), {}))
        setCheckedRows((prev) => [...prev, false])

        isSubmitted && trigger(baseName)
    }

    const deleteRows = () => {
        const indexes = checkedRows.reduce((acc, value, index) => value ? [...acc, index] : acc, [] as number[])
        removeRow(indexes)
        setIsCheckAll(false)
    }

    useEffect(() => {
        setCheckedRows(rows.map((_) => false))
        onRowChange && onRowChange()
    }, [rows])

    return (
        <div className={clsx('tabsTablePage', {
            'tabsTablePage--active': isActive,
        })}>
            <div className='relative'>
                {
                    isLoading ?
                        <div style={{ padding: '4rem 0' }}><Preloader className='tabsTablePage__preloader' />
                        </div> : (
                            <>
                                <div className='tabsTablePage__toolbar'>
                                    {withToolbar && (
                                        <TabsTableToolbar
                                            addNewRow={addNewRow}
                                            deleteRows={deleteRows}
                                            disabledDelete={checkedRows.every((value) => !value)}
                                        />
                                    )}
                                    <div className='tabsTablePage__toolbarContent'>
                                        {customTools}
                                        {!!templates?.length && (
                                            <TabsTableTemplateButton
                                                baseName={baseName}
                                                name={name}
                                                pageIndex={pageIndex}
                                                disabled={disabled}
                                                sectionName={sectionName}
                                                templates={templates}
                                                onTemplateLoad={onTemplateLoad}
                                                isStrictTemplateSetting={isStrictTemplateSetting}
                                            />
                                        )}
                                    </div>
                                </div>
                                <TabsTableBody
                                    name={name}
                                    inputs={inputs}
                                    rows={rows}
                                    updateRow={updateRow}
                                    withCheckboxes={withToolbar}
                                    isCheckAll={isCheckAll}
                                    handleCheckAll={handleCheckAll}
                                    handleCheckRow={handleCheckRow}
                                    checkedRows={checkedRows}
                                    sectionName={sectionName}
                                    pageIndex={pageIndex}
                                />
                            </>
                        )
                }
            </div>
        </div>
    )
}

export default TabsTablePage
