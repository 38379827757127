import './ProfileToolbar.scss'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Avatar from '@mui/material/Avatar'
// @ts-ignore
// import Ebisu from '../../../../../assets/gif/ebisu.gif'
// @ts-ignore
// import Kaspiyskiy from '../../../../../assets/jpg/gruz.jpg'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '~/API/authApi/auth'
import { useUpdateUserMutation } from '~/API/userApi/user'
import { Role } from '~/API/userApi/user.types'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
// import { fitnessData, FitnessDataType, fitnessNames } from './data/fitnessData'
import { FETCH_USER_INFO_REQUESTED, logout, UPDATE_USER_INFO } from '~/redux/slices/appSlice/appSlice'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { roleNames, RoleNamesType } from '~/shared/util/types'

interface ProfileToolbarProps {
    className?: string,
}

const ProfileToolbar = ({ className }: ProfileToolbarProps) => {
    const dispatch = useDispatch()
    const [updateUser] = useUpdateUserMutation()
    const [open, setOpen] = useState(false)
    const [popup, setPopup] = useState<{ x: number, y: number } | null>(null)
    const user = useSelector(selectUser)
    const quotes = [
        'На ней обувь от Jimmy Choo, остального на ней не хочу...',
        'И я понял, но только потом что не всегда слабее слабый пол...',
        'В голове бы моей мысли поумней, ребята, но я такой тупой, что гожусь в депутаты...',
        'Падаль падала к падали, падали, падали, падали листья...',
        'Она шепчет мне, что в этот раз все сделает сама. скрипит кровать, она захотела ее сломать...',
        'И если спросят, как пройти в переулок печали, мы пожмём плечами...',
        'И если потеряю, не выдержит печень... Нет, прежде, чем не выдержит сердце...',
        'Родной город, я тебя обожаю, но я от тебя уезжаю, я от тебя уезжаю...',
    ]

    const buildProfile = (id?: string) => {
        switch (id) {
            /* Eduard*/
            // case '60892ed67ca5a533fc2b7987':
            //     return (
            //         <>
            //             (上忍)
            //             {popup && (
            //                 <div className='profileToolbar__titlePopup'>
            //                     <h5>上忍 (Jōnin, Джонин)</h5>
            //                     <p>Звание получено за активное использование системы</p>
            //                     <img src={Ebisu} alt='jonin' />
            //                 </div>
            //             )}
            //         </>
            //     )
            // /*Kristina*/
            // case '608932de7ca5a533fc2b7988':
            //     return (
            //         <>
            //             👑
            //             {popup && (
            //                 <div className='profileToolbar__titlePopup'>
            //                     <h5>上忍 (Jōnin, Джонин)</h5>
            //                     <p>{quotes[Math.floor(Math.random() * quotes.length)]}</p>
            //                     <img src={Kaspiyskiy} alt='chunin' />
            //                 </div>
            //             )}
            //         </>
            //     )
            // /*Nastya*/
            // case '60c1c07562eee07bcff06756':
            //     const randomFitnessName = fitnessNames[Math.floor(Math.random() * fitnessNames.length)]
            //     const fitnessPerson = fitnessData[randomFitnessName as keyof FitnessDataType]
            //     return (
            //         <>
            //             💪
            //             {popup && (
            //                 <div className='profileToolbar__titlePopup' style={{ right: popup.x, top: popup.y }}>
            //                     <h5>上忍 (Jōnin, Джонин)</h5>
            //                     <img src={fitnessPerson.img} alt='chunin' />
            //                     <p>
            //                         {fitnessPerson.name} - {fitnessPerson.description}
            //                     </p>
            //                 </div>
            //             )}
            //         </>
            //     )
            default:
                return null
        }
    }

    const handleToggle = () => {
        setOpen((prev) => !prev)
    }

    const handleLogout = () => {
        setOpen(false)
        logoutUser().then(() => {
            dispatch(logout())
        })
    }

    const changeRole = (role: Role) => {
        if (user) {
            setOpen(false)
            dispatch(FETCH_USER_INFO_REQUESTED())
            updateUser({ userId: user.id, role }).unwrap()
                .then((response) => {
                    dispatch(UPDATE_USER_INFO())
                    dispatch(SHOW_NOTICE({ type: 'success', message: 'Роль успешно изменена' }))
                })
                .catch((e) => {
                    dispatch(SHOW_NOTICE({ type: 'error', message: e.message }))
                })
        }
    }

    return (
        <div className={clsx('profileToolbar', className)} onClick={handleToggle}>
            <Avatar className='profileToolbar__avatar' src={user?.photo || ''} alt={user?.fullname} />
            <div className='profileToolbar__body'>
                <div className='profileToolbar__info'>
                    <span className='profileToolbar__name'>
                        {user?.fullname}
                    </span>
                    <div>
                        <span className='profileToolbar__role'>
                            {roleNames[(user as any)?.roleName as keyof RoleNamesType]}
                        </span>
                        <span
                            className={'profileToolbar__title'}
                            onMouseOver={(e: React.MouseEvent<HTMLSpanElement>) => {
                                const rect = (e.target as any).getBoundingClientRect()
                                setPopup({ x: document.documentElement.scrollWidth - rect.right, y: rect.bottom + 15 })
                            }}
                            onMouseOut={() => setPopup(null)}
                        >
                            {buildProfile(user?.id)}
                        </span>
                    </div>
                </div>
                <ExpandMoreIcon className={'profileToolbar__expandMore'} />
            </div>
            {open && (
                <>
                    <div
                        className={'fixedWrapper'}
                    />
                    <div className='profileToolbar__popup'>
                        <ul>
                            {user?.id === '61e6e77ee36f4650db674852' && (
                                <>
                                    <li onClick={() => changeRole('OPERATOR')}>Менеджер продаж</li>
                                    <li onClick={() => changeRole('ORDER_MANAGER')}>Менеджер исполнения</li>
                                    <li onClick={() => changeRole('FOREMAN')}>Менеджер по клинингу</li>
                                    <li onClick={() => changeRole('TOOLS_MANAGER')}>
                                        Менеджер АХО(Не используется пока)
                                    </li>
                                    <li onClick={() => changeRole('CHIEF')}>Руководитель</li>
                                </>
                            )}
                            <li onClick={handleToggle}>Профиль</li>
                            <li onClick={handleLogout}>Выход</li>
                        </ul>
                    </div>
                </>
            )}
        </div>
    )
}

export default ProfileToolbar
