import { KpService } from '~/components/pages/KP/kp.types'
import {
    firstPageTemplate,
    lastPageTemplate,
    pageTemplate,
    PageTemplateOptions,
    singlePageTemplate,
} from '~/shared/templates/mainTemplates/additionalFunctions/collectCommercialOfferServices/collectCommercialOfferServices.templates'
import { calculateWithDiscount } from '~/shared/templates/mainTemplates/docUtil'
import { formatRUB } from '~/shared/util/formatNumberWithIntlOptions'

interface CollectCommercialOfferServicesParams {
    kpServices?: KpService[]
    maxHeight?: MaxServicesHeight | number
    style: string
    format: 'docx' | 'default'
}

export const collectCommercialOfferServices = (params: CollectCommercialOfferServicesParams) => {
    const {
        kpServices = [],
        maxHeight = 483,
        style,
        format = 'default',
    } = params

    if (kpServices) {
        let counter = 0
        const newServices = kpServices
            .map((item, index: number) => {
                if (item.isSelected) {
                    counter++
                    const costWithDiscount = item.price ? +calculateWithDiscount(+item.price, +item.discount, +item.discount > 0) : 0
                    const unitCost = () => {
                        return costWithDiscount && Number(item.amount) ? (costWithDiscount / Number(item.amount)).toFixed(2) : '0.00'
                    }

                    let html = `<tr style='font-size: ${kpServices?.length > 1 ? '11px' : '12px'}'>
                            <td colspan='1' class='servicesStyle'
                                style='${format === 'docx' ? 'height: 40px; text-align: center; border: 1px solid #54D3C2;' : ''}'
                            >${counter + '.'}</td>
                            <td colspan="${format === 'docx' ? '2' : '6'}" class='servicesStyle'
                                style='${format === 'docx' ? 'height: 40px; text-align: center; border: 1px solid #54D3C2;' : ''}'
                            >${item.name}</td>
                            <td colspan="${format === 'docx' ? '1' : '2'}" class='servicesStyle'
                                style='${format === 'docx' ? 'height: 40px; text-align: center; border: 1px solid #54D3C2;' : ''}'
                            >${item.amount}</td>
                            <td colspan="${format === 'docx' ? '1' : '2'}" class='servicesStyle'
                                style='${format === 'docx' ? 'height: 40px; text-align: center; border: 1px solid #54D3C2;' : ''}'
                            >${item.measure}</td>
                            <td colspan="${format === 'docx' ? '1' : '2'}" class='servicesStyle'
                                style='${format === 'docx' ? 'height: 40px; text-align: center; border: 1px solid #54D3C2;' : ''}'
                            >${formatRUB(Number(unitCost()), false)}</td>
                            <td colspan="${format === 'docx' ? '1' : '2'}" class='servicesStyle'
                                style='${format === 'docx' ? 'height: 40px; text-align: center; border: 1px solid #54D3C2;' : ''}'
                            >${formatRUB(Number(Math.floor(costWithDiscount)), false)}</td>
                        </tr>`
                    if (kpServices.length) {
                        html += `
                        <tr>
                            <th colspan='1' style='text-align: right; padding-right: 5px; height: 40px; hyphens: auto; 
                                ${format === 'docx' ? ' border: 1px solid #54D3C2;': ''}'>План выполнения работ:</th>
                            <td colspan="${format === 'docx' ? '6' : '14'}" style='padding-left: 5px;padding-right: 5px;
                                ${format === 'docx' ? ' border: 1px solid #54D3C2;': ''}'>
                                ${item.workDescription.replace(/\n/g, '<br>')}
                            </td>
                        </tr>
                        <tr>
                            <th colspan='1' style='text-align: right; padding-right: 5px; height: 40px;
                                ${format === 'docx' ? ' border: 1px solid #54D3C2;': ''}'>График работ:</th>
                            <td colspan="${format === 'docx' ? '6' : '14'}" style='padding-left: 5px;padding-right: 5px;
                                ${format === 'docx' ? ' border: 1px solid #54D3C2;': ''}'>
                                ${item.schedule.replace(/\n/g, '<br>')}
                            </td>
                        </tr>
                        <tr>
                            <th colspan='1' style=' text-align: right; padding-right: 5px; height: 40px;
                                ${format === 'docx' ? ' border: 1px solid #54D3C2;': ''}'>Количество персонала:</th>
                            <td colspan="${format === 'docx' ? '6' : '14'}" style='padding-left: 5px; padding-right: 5px;
                                ${format === 'docx' ? ' border: 1px solid #54D3C2;': ''}'>
                                <pre>${item.personnelDescription}</pre>
                            </td>
                        </tr>
                    `
                    }

                    return html
                }
                return []
            })

        if (newServices.length > 0) {
            return parseServices(newServices as string[], maxHeight, style)
        }
        return []
    }
    return []
}

export interface MaxServicesHeight {
    first: number
    second: number
}

export const parseServices = (services: string[], maxHeight: MaxServicesHeight | number, style: string) => {
    let currentHeight = 0
    let servicePageCounter = 0
    const servicesAdditionalPages: string[][] = []
    if (services.length) {
        for (let i = 0; i < services.length; i++) {
            const doc = document.createElement('table')
            doc.innerHTML += services[i] + `<style>${style}</style>`
            document.body.appendChild(doc)
            const itemHeight = doc.getBoundingClientRect().height
            document.body.removeChild(doc)

            const currentMaxHeight = typeof maxHeight !== 'number' ?
                servicePageCounter === 0 ? maxHeight.first : maxHeight.second :
                maxHeight

            if (itemHeight > currentMaxHeight) {
                throw new Error('Размер услуги превышает максимальный')
            }

            if (currentHeight + itemHeight > currentMaxHeight) {
                servicePageCounter++
                currentHeight = 0
            }

            if (!servicesAdditionalPages[servicePageCounter]) {
                servicesAdditionalPages[servicePageCounter] = []
            }

            servicesAdditionalPages[servicePageCounter].push(services[i])
            currentHeight += itemHeight
        }
    }

    return servicesAdditionalPages
}

export const makeAdditionalContractPages = (servicesAdditionalPages: string[][], options: PageTemplateOptions) => {
    return servicesAdditionalPages.map((item, index) => generateNewContractServicesPageWithBody(item.join(''), index === 0 ? 'FIRST' : 'CENTER', options)).join('')
}

type generateNewPageWithBodyPage = 'LAST' | 'FIRST' | 'CENTER' | 'SINGLE'

export const generateNewContractServicesPageWithBody = (body: string, page: generateNewPageWithBodyPage, options: PageTemplateOptions) => {
    const div = document.createElement('div')
    switch (page) {
        case 'FIRST': {
            div.innerHTML = firstPageTemplate(options).trim()
            break
        }
        case 'LAST': {
            div.innerHTML = lastPageTemplate(options).trim()
            break
        }
        case 'CENTER': {
            div.innerHTML = pageTemplate(options).trim()
            break
        }
        case 'SINGLE': {
            div.innerHTML = singlePageTemplate(options).trim()
            break
        }
    }
    div.querySelector('#services')!.innerHTML = body.trim()
    return div.innerHTML
}
