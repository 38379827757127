import clsx from 'clsx'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { clientGenderItems, clientMainInfo, clientProxyItems, clientTypeItems } from './ClientInfo.types'
import { useLazyFetchOrderQuery } from '~/API/orderApi/order'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import CustomFieldArray from '~/components/common/fields/CustomFieldArray/CustomFieldArray'
import CustomAddressInput from '~/components/common/inputs/CustomAddressInput/CustomAddressInput'
import CustomFileInputSingle from '~/components/common/inputs/CustomFileInputSingle/CustomFileInputSingle'
import CustomInput, { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import CustomOrganizationInput from '~/components/common/inputs/CustomOrganizationInput/CustomOrganizationInput'
import CustomSelect from '~/components/common/inputs/CustomSelect/CustomSelect'
import {
    checkClientPath,
    chooseTitle,
    handleReset,
} from '~/components/common/ReusingComponents/ClientInfo/ClientInfo.helpers'
import { useChooseIcon } from '~/components/common/ReusingComponents/ClientInfo/hooks/useChooseIcon/useChooseIcon'
import { useClientEffect } from '~/components/common/ReusingComponents/ClientInfo/hooks/useClientEffect/useClientEffect'
import { useSaveClient } from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'
import { useUpdateOrder } from '~/components/common/ReusingComponents/ClientInfo/hooks/useUpdateOrder/useUpdateOrder'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import CustomDate from '~/components/common/ReusingComponents/CustomDate/CustomDate'
import { CustomPopup } from '~/components/common/ReusingComponents/CustomPopup/CustomPopup'
import { InputParams } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { selectClientInfoEvent } from '~/redux/selectors/orderSlice.selectors'
import './ClientInfo.scss'
import { setActiveOrder, setEventEmitter } from '~/redux/slices/orderSlice/orderSlice'
import { useAppDispatch } from '~/redux/store'
import { isMobile } from '~/shared/util/deviceDetect'

interface ClientInfoProps {
    className?: string
    disabled: boolean
    clientType: ClientInfoEnum
    title?: string
}

export const ClientInfo = memo(function ClientInfo(props: ClientInfoProps) {
    const {
        className,
        disabled,
        clientType = ClientInfoEnum.ORGANIZATION,
        title,
    } = props

    const dispatch = useAppDispatch()
    const contractorError = useSelector(selectClientInfoEvent)

    const [fetchOrder] = useLazyFetchOrderQuery()

    const saveClient = useSaveClient({ clientType })
    const updateOrder = useUpdateOrder({ clientType })
    const icon = useChooseIcon({ clientType })

    const { id } = useParams()

    const methods = useForm()
    const contextMethods = useFormContext<OrderInterface>()

    const {
        getValues,
        control,
        setValue,
        reset,
    } = methods

    const path = checkClientPath(clientType)

    const [currentClientType, isProxy] = useWatch({
        control,
        name: [path.currentClientType, path.isProxy],
    })

    const [isPopup, setPopup] = useState(false)
    const [isLoader, setLoader] = useState(false)

    const handleClosePopup = useCallback(() => {
        setPopup(false)
        dispatch(setEventEmitter({ code: 'CLIENT_INFO_CONTRACTOR_VALIDATION_FAILED', condition: false }))
    }, [dispatch])

    const handleOnSubmit = async () => {
        setLoader(true)
        saveClient(getValues, currentClientType)

        try {
            const activeOrder = await fetchOrder(id!).unwrap()
            const newOrder = await updateOrder(activeOrder, getValues)
            dispatch(setActiveOrder(newOrder))

            handleReset({
                clientType,
                getValues,
                handleClosePopup,
                contextMethods,
            })

            setLoader(false)
        } catch (e) {
            setLoader(false)
        }
    }

    const popupBody = useMemo(() => {
        switch (clientType) {
            case ClientInfoEnum.ORGANIZATION: {
                const clientInfoInputs = [(
                    <FormProvider
                        key={'FormProvider' + ClientInfoEnum.ORGANIZATION}
                        {...methods}
                    >
                        <CustomFieldArray
                            items={clientMainInfo as unknown as InputParams[]}
                            control={control}
                            setValue={setValue}
                            name='primaryInformation.contactInfo'
                            disabled={disabled}
                            className='client-info__info'
                        />
                    </FormProvider>
                )]
                const clientCounterpartyInput = (
                    <Controller
                        key='counterparty.popup'
                        name='primaryInformation.counterparty'
                        control={control}
                        rules={{ required: true }}
                        defaultValue={''}
                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                            <CustomSelect
                                title='Контрагент'
                                items={clientTypeItems}
                                value={value}
                                onChange={onChange}
                                error={error}
                                className='client-info__info'
                                disabled={disabled}
                            />
                        }
                    />
                )
                switch (currentClientType || 'Физ. лицо') {
                    case 'Физ. лицо':
                        return ({
                            data: [
                                [
                                    clientCounterpartyInput,
                                    (
                                        <Controller
                                            key='popup.sex'
                                            name='primaryInformation.sex'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                                <CustomSelect
                                                    title='Пол'
                                                    items={clientGenderItems}
                                                    value={value}
                                                    onChange={onChange}
                                                    error={error}
                                                    disabled={disabled}
                                                    className='client-info__info'
                                                />
                                            }
                                        />
                                    ),
                                ],
                                [(<Controller
                                    key='primaryInformation.clientRequisites.name'
                                    name='primaryInformation.clientRequisites.name'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='ФИО'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                        />
                                    }
                                />),
                                (<Controller
                                    key='primaryInformation.clientRequisites.phone'
                                    name='primaryInformation.clientRequisites.phone'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='Номер телефона'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                            mask={InputMask.PHONE_NUMBER}
                                        />
                                    }
                                />),
                                (<Controller
                                    key='primaryInformation.clientRequisites.birthDate'
                                    name='primaryInformation.clientRequisites.birthDate'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange }, fieldState: { error } }) =>
                                        <CustomDate
                                            value={value}
                                            title='Дата рождения'
                                            className='formSection__inputSelect'
                                            onChange={onChange}
                                            disabled={disabled}
                                        />
                                    }
                                />),
                                ],
                                [(<Controller
                                    key='primaryInformation.clientRequisites.passportSeries'
                                    name='primaryInformation.clientRequisites.passportSeries'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='Серия паспорта'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                            mask={InputMask.FOUR}
                                        />
                                    }
                                />),
                                (<Controller
                                    key='primaryInformation.clientRequisites.passportNumber'
                                    name='primaryInformation.clientRequisites.passportNumber'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='Номер паспорта'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                            mask={InputMask.SIX}
                                        />
                                    }
                                />),
                                (<Controller
                                    key='primaryInformation.clientRequisites.issueDate'
                                    name='primaryInformation.clientRequisites.issueDate'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange }, fieldState: { error } }) =>
                                        <CustomDate
                                            value={value}
                                            title='Дата выдачи'
                                            className='formSection__inputSelect'
                                            onChange={onChange}
                                            disabled={disabled}
                                        />
                                    }
                                />),
                                ],
                                [(<Controller
                                    key='primaryInformation.clientRequisites.issuedBy'
                                    name='primaryInformation.clientRequisites.issuedBy'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='Кем выдан'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                        />
                                    }
                                />),
                                (<Controller
                                    key='primaryInformation.clientRequisites.departmentCode'
                                    name='primaryInformation.clientRequisites.departmentCode'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='Код подразделения'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                            mask={InputMask.SIX_DASH}
                                        />
                                    }
                                />),
                                ],
                                [(<Controller
                                    key='primaryInformation.clientRequisites.passportAddress'
                                    name='primaryInformation.clientRequisites.passportAddress'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomAddressInput
                                            name={name}
                                            title='Адрес прописки по паспорту'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                        />
                                    }
                                />)],
                                clientInfoInputs,
                            ],
                        })
                    case 'Юр. лицо':
                        return ({
                            data: [
                                [clientCounterpartyInput,
                                    (
                                        <Controller
                                            key='organizationName.popup'
                                            name='primaryInformation.organizationRequisites'
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue={''}
                                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                                <CustomOrganizationInput
                                                    title='Организация'
                                                    value={value}
                                                    className='client-info__info'
                                                    values={value}
                                                    onChange={onChange}
                                                    error={error}
                                                    disabled={disabled}
                                                    withPopup={false}
                                                    additionalMethods={methods}
                                                    clientType={clientType}
                                                />
                                            }
                                        />
                                    )],
                                [(
                                    <Controller
                                        key='primaryInformation.organizationRequisites.inn'
                                        name='primaryInformation.organizationRequisites.inn'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationInn'
                                                title='ИНН'
                                                value={value}
                                                onChange={onChange}
                                                disabled={true}
                                                className='client-info__info'
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationKpp.kpp'
                                        name='primaryInformation.organizationRequisites.kpp'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationInn'
                                                title='КПП'
                                                value={value}
                                                onChange={onChange}
                                                disabled={true}
                                                className='client-info__info'
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationOgrn.ogrn'
                                        name='primaryInformation.organizationRequisites.ogrn'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationInn'
                                                title='ОГРН'
                                                value={value}
                                                onChange={onChange}
                                                disabled={true}
                                                className='client-info__info'
                                            />
                                        }
                                    />
                                )],
                                [(
                                    <Controller
                                        key='organizationFio.management.name'
                                        name='primaryInformation.organizationRequisites.management.name'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationFio'
                                                title='ФИО руководителя'
                                                value={value}
                                                onChange={onChange}
                                                disabled={true}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationJobTitle.popup'
                                        name='primaryInformation.organizationRequisites.management.post'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationJobTitle'
                                                title='Должность руководителя'
                                                value={value}
                                                onChange={onChange}
                                                disabled={true}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='primaryInformation.organizationRequisites.isProxy'
                                        name='primaryInformation.organizationRequisites.isProxy'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                                            return (
                                                <CustomSelect
                                                    items={clientProxyItems}
                                                    title='Доверенность'
                                                    value={isProxy ? value : false}
                                                    onChange={onChange}
                                                    error={error}
                                                    disabled={disabled}
                                                />
                                            )
                                        }}
                                    />
                                )],
                                isProxy ? [(
                                    <Controller
                                        key='primaryInformation.organizationRequisites.proxyFullname'
                                        name='primaryInformation.organizationRequisites.proxyFullname'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='proxyFullname'
                                                title='ФИО доверенного'
                                                value={value}
                                                onChange={onChange}
                                                error={error}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='primaryInformation.organizationRequisites.proxyAuthorityNumber'
                                        name='primaryInformation.organizationRequisites.proxyAuthorityNumber'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='proxyAuthorityNumber'
                                                title='Номер доверенности'
                                                value={value}
                                                onChange={onChange}
                                                error={error}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='primaryInformation.organizationRequisites.proxyAuthority'
                                        name='primaryInformation.organizationRequisites.proxyAuthority'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomFileInputSingle
                                                fileId={value}
                                                onChange={onChange}
                                                button='Загрузить файл'
                                                title='Скан доверенности'
                                                error={error}
                                                name='primaryInformation.organizationRequisites.proxyAuthority'
                                                key='primaryInformation.organizationRequisites.proxyAuthority'
                                                allowedFileTypes={['image', 'application']}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                )] : [],
                                [(
                                    <Controller
                                        key='organizationRequisites.bankName'
                                        name='primaryInformation.organizationRequisites.bankName'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationRequisites.bankName'
                                                title='Название банка'
                                                value={value}
                                                onChange={onChange}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationRequisites.bankId'
                                        name='primaryInformation.organizationRequisites.bankId'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationRequisites.bankId'
                                                title='БИК'
                                                value={value}
                                                onChange={onChange}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationRequisites.bankAccount'
                                        name='primaryInformation.organizationRequisites.bankAccount'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationRequisites.bankAccount'
                                                title='Р/С'
                                                value={value}
                                                onChange={onChange}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationRequisites.correspondentAccount'
                                        name='primaryInformation.organizationRequisites.correspondentAccount'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationRequisites.correspondentAccount'
                                                title='К/С'
                                                value={value}
                                                onChange={onChange}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                )],
                                clientInfoInputs,
                            ],
                        })
                    default:
                        return ({
                            data: [],
                        })
                }
            }
            case ClientInfoEnum.CONTRACTOR: {
                const clientInfoInputs = [(
                    <FormProvider
                        key={'FormProvider' + ClientInfoEnum.CONTRACTOR}
                        {...methods}
                    >
                        <CustomFieldArray
                            items={clientMainInfo as unknown as InputParams[]}
                            control={control}
                            setValue={setValue}
                            name='starting.contractor.contactInfo'
                            disabled={disabled}
                            getValues={getValues}
                            className='client-info__info'
                            isContractor={true}
                        />
                    </FormProvider>
                )]
                const clientCounterpartyInput = (
                    <Controller
                        key='counterparty.popup'
                        name='starting.contractor.counterparty'
                        control={control}
                        rules={{ required: true }}
                        defaultValue={''}
                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                            <CustomSelect
                                title='Контрагент'
                                items={clientTypeItems}
                                value={value}
                                onChange={onChange}
                                error={error}
                                className='client-info__info'
                                disabled={disabled}
                            />
                        }
                    />
                )
                switch (currentClientType) {
                    case 'Физ. лицо':
                        return ({
                            data: [
                                [
                                    clientCounterpartyInput,
                                    (
                                        <Controller
                                            key='popup.sex'
                                            name='starting.contractor.sex'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                                <CustomSelect
                                                    title='Пол'
                                                    items={clientGenderItems}
                                                    value={value}
                                                    onChange={onChange}
                                                    error={error}
                                                    disabled={disabled}
                                                    className='client-info__info'
                                                />
                                            }
                                        />
                                    ),
                                ],
                                [(<Controller
                                    key='starting.contractor.clientRequisites.name'
                                    name='starting.contractor.clientRequisites.name'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='ФИО'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                        />
                                    }
                                />),
                                (<Controller
                                    key='starting.contractor.clientRequisites.phone'
                                    name='starting.contractor.clientRequisites.phone'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='Номер телефона'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                            mask={InputMask.PHONE_NUMBER}
                                        />
                                    }
                                />),
                                (<Controller
                                    key='starting.contractor.clientRequisites.birthDate'
                                    name='starting.contractor.clientRequisites.birthDate'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange }, fieldState: { error } }) =>
                                        <CustomDate
                                            value={value}
                                            title='Дата рождения'
                                            className='formSection__inputSelect'
                                            onChange={onChange}
                                            disabled={disabled}
                                        />
                                    }
                                />),
                                ],
                                [(<Controller
                                    key='starting.contractor.clientRequisites.passportSeries'
                                    name='starting.contractor.clientRequisites.passportSeries'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='Серия паспорта'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                            mask={InputMask.FOUR}
                                        />
                                    }
                                />),
                                (<Controller
                                    key='starting.contractor.clientRequisites.passportNumber'
                                    name='starting.contractor.clientRequisites.passportNumber'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='Номер паспорта'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                            mask={InputMask.SIX}
                                        />
                                    }
                                />),
                                (<Controller
                                    key='starting.contractor.clientRequisites.issueDate'
                                    name='starting.contractor.clientRequisites.issueDate'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange }, fieldState: { error } }) =>
                                        <CustomDate
                                            value={value}
                                            title='Дата выдачи'
                                            className='formSection__inputSelect'
                                            onChange={onChange}
                                            disabled={disabled}
                                        />
                                    }
                                />),
                                ],
                                [(<Controller
                                    key='starting.contractor.clientRequisites.issuedBy'
                                    name='starting.contractor.clientRequisites.issuedBy'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='Кем выдан'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                        />
                                    }
                                />),
                                (<Controller
                                    key='starting.contractor.clientRequisites.departmentCode'
                                    name='starting.contractor.clientRequisites.departmentCode'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomInput
                                            name={name}
                                            title='Код подразделения'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                            mask={InputMask.SIX_DASH}
                                        />
                                    }
                                />),
                                ],
                                [(<Controller
                                    key='starting.contractor.clientRequisites.passportAddress'
                                    name='starting.contractor.clientRequisites.passportAddress'
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={''}
                                    render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                        <CustomAddressInput
                                            name={name}
                                            title='Адрес прописки по паспорту'
                                            value={value}
                                            onChange={onChange}
                                            disabled={disabled}
                                            className='client-info__info'
                                        />
                                    }
                                />)],
                                clientInfoInputs,
                            ],
                        })
                    case 'Юр. лицо':
                    default:
                        return ({
                            data: [
                                [clientCounterpartyInput,
                                    (
                                        <Controller
                                            key='organizationName.popup'
                                            name='starting.contractor'
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue={''}
                                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                                <CustomOrganizationInput
                                                    title='Организация'
                                                    value={value}
                                                    className='client-info__info'
                                                    values={value}
                                                    onChange={onChange}
                                                    error={error}
                                                    disabled={disabled}
                                                    withPopup={false}
                                                    additionalMethods={methods}
                                                    clientType={clientType}
                                                />
                                            }
                                        />
                                    )],
                                [(
                                    <Controller
                                        key='starting.contractor.inn'
                                        name='starting.contractor.inn'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationInn'
                                                title='ИНН'
                                                value={value}
                                                onChange={onChange}
                                                disabled={true}
                                                className='client-info__info'
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationKpp.kpp'
                                        name='starting.contractor.kpp'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationInn'
                                                title='КПП'
                                                value={value}
                                                onChange={onChange}
                                                disabled={true}
                                                className='client-info__info'
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationOgrn.ogrn'
                                        name='starting.contractor.ogrn'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationInn'
                                                title='ОГРН'
                                                value={value}
                                                onChange={onChange}
                                                disabled={true}
                                                className='client-info__info'
                                            />
                                        }
                                    />
                                )],
                                [(
                                    <Controller
                                        key='organizationFio.management.name'
                                        name='starting.contractor.management.name'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationFio'
                                                title='ФИО руководителя'
                                                value={value}
                                                onChange={onChange}
                                                disabled={true}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationJobTitle.popup'
                                        name='starting.contractor.management.post'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationJobTitle'
                                                title='Должность руководителя'
                                                value={value}
                                                onChange={onChange}
                                                disabled={true}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='starting.contractor.isProxy'
                                        name='starting.contractor.isProxy'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                                            return (
                                                <CustomSelect
                                                    items={clientProxyItems}
                                                    title='Доверенность'
                                                    value={isProxy ? value : false}
                                                    onChange={onChange}
                                                    error={error}
                                                    disabled={disabled}
                                                />
                                            )
                                        }}
                                    />
                                )],
                                isProxy ? [(
                                    <Controller
                                        key='starting.contractor.proxyFullname'
                                        name='starting.contractor.proxyFullname'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='proxyFullname'
                                                title='ФИО доверенного'
                                                value={value}
                                                onChange={onChange}
                                                error={error}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='starting.contractor.proxyAuthorityNumber'
                                        name='starting.contractor.proxyAuthorityNumber'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='proxyAuthorityNumber'
                                                title='Номер доверенности'
                                                value={value}
                                                onChange={onChange}
                                                error={error}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='starting.contractor.proxyAuthority'
                                        name='starting.contractor.proxyAuthority'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomFileInputSingle
                                                fileId={value}
                                                onChange={onChange}
                                                button='Загрузить файл'
                                                title='Скан доверенности'
                                                error={error}
                                                name='starting.contractor.proxyAuthority'
                                                key='starting.contractor.proxyAuthority'
                                                allowedFileTypes={['image', 'application']}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                )] : [],
                                [(
                                    <Controller
                                        key='organizationRequisites.bankName'
                                        name='starting.contractor.bankName'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationRequisites.bankName'
                                                title='Название банка'
                                                value={value}
                                                onChange={onChange}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationRequisites.bankId'
                                        name='starting.contractor.bankId'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationRequisites.bankId'
                                                title='БИК'
                                                value={value}
                                                onChange={onChange}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationRequisites.bankAccount'
                                        name='starting.contractor.bankAccount'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationRequisites.bankAccount'
                                                title='Р/С'
                                                value={value}
                                                onChange={onChange}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                ), (
                                    <Controller
                                        key='organizationRequisites.correspondentAccount'
                                        name='starting.contractor.correspondentAccount'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={''}
                                        render={({ field: { value, onChange }, fieldState: { error } }) =>
                                            <CustomInput
                                                name='organizationRequisites.correspondentAccount'
                                                title='К/С'
                                                value={value}
                                                onChange={onChange}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                )],
                                clientInfoInputs,
                            ],
                        })
                }
            }
            default:
                return ({ data: [] })
        }
    }, [currentClientType, isProxy])

    useEffect(() => {
        reset(contextMethods.getValues())
    }, [contextMethods, isPopup, reset])

    useClientEffect({ clientType, contextMethods, setValue })

    return (
        <>
            <div className='client-info'>
                <button
                    className={clsx('client-info__button', className, { 'client-info__button--error': contractorError && clientType === 'CONTRACTOR' })}
                    type='button'
                    onClick={() => setPopup(true)}
                >
                    {icon}
                    {isMobile && title}
                </button>
            </div>
            {isPopup && (
                <CustomPopup
                    onClose={handleClosePopup}
                    title={{ title: chooseTitle(clientType) }}
                    className='client-info__popup'
                    body={popupBody}
                    footer={[
                        (
                            <CustomButton
                                onClick={handleOnSubmit}
                                key={'client-info__popup_save'}
                                title='Сохранить'
                                theme={CustomButtonThemes.COLOR}
                                disabled={isLoader}
                            />
                        ), (
                            <CustomButton
                                onClick={handleClosePopup}
                                key={'client-info__popup_cancel'}
                                title='Отмена'
                                theme={CustomButtonThemes.SMOKE}
                                disabled={isLoader}
                            />
                        ),
                    ]}
                />
            )}
        </>
    )
})
