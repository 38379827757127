import { Controller } from 'react-hook-form'
import { TabsTablePageType } from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import { getTabsTableValidation } from '~/components/common/ReusingComponents/TabsTable/utils/getTabsTableValidation'
import { validateInput } from '~/hooks/useFormConstructor/lib/additionalFunctions/validateInput'

interface ToolsReportValidationProps {
    baseName: string
    pages?: TabsTablePageType<any>[]
    inputsMap?: Record<string, any>
    rows?: any[]
    inputs?: any[]
}

// This component is used for form validation, because when validation is triggered, the actual inputs are not in the DOM
const MobileListValidation = (props: ToolsReportValidationProps) => {
    const {
        baseName,
        pages,
        inputsMap,
        rows,
        inputs,
    } = props

    const renderController = (input: any, name: string, customValidationFunc?: (value: any, formValues: any) => string | boolean) =>
        <Controller
            key={input.name}
            name={name}
            render={() => <></>}
            rules={validateInput(input, customValidationFunc)}
        />

    // pages mode
    if (pages && inputsMap) {
        return (
            <>
                {pages.map((page, pageIndex) => page.items.map((_, rowIndex) => {
                    const inputs = inputsMap[page.type]

                    return inputs.map((input: any) => {
                        const name = `${baseName}.${pageIndex}.items.${rowIndex}.${input.name}`
                        return renderController(input, name, input.tableValidationType ? getTabsTableValidation(input.tableValidationType, page.type, rowIndex) : undefined)
                    })
                }))}
            </>
        )
    }

    // rows mode
    if (rows && inputs) {
        return (
            <>
                {rows.map((_, rowIndex) =>
                    inputs.map((input) =>
                        renderController(
                            input,
                            `${baseName}.${rowIndex}.${input.name}`,
                            input.tableValidationType ? getTabsTableValidation(input.tableValidationType, '', rowIndex) : undefined,
                        ),
                    ),
                )}
            </>
        )
    }

    return null
}

export default MobileListValidation
