import { User } from '~/API/userApi/user.types'
import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import { ClientType } from '~/components/common/ReusingComponents/ClientInfo/ClientInfo.types'
import {
    CheckTypes,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableHeader/subComponents/TabsTableCheckIcon/TabsTableCheckIcon'
import { TabsTableMods } from '~/components/common/ReusingComponents/TabsTable/TabsTable'
import { TaskItem } from '~/components/pages/KP/kp.types'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import { MobileComponentKeys } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { rules } from '~/shared/order/rules'
import { StageManager, VERSIONS } from '~/shared/order/StageManager'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { foremanWorkStarting } from '~/shared/templates/inputTemplates/foremanWorkStarting'

export const SERVICES_VERIFICATION_SECTIONS = {
    actualServices: (disabled: boolean) => ({
        version: VERSIONS.V2,
        required: false,
        disabled: disabled,
        name: 'actualServices',
        type: 'table',
        mobileComponent: MobileComponentKeys.SERVICES_VERIFICATION,
        title: 'Выполняемые услуги',
        withHideButton: true,
        alwaysShowHeader: true,
        checkIcons: {
            inputName: 'status',
            checkType: CheckTypes.NOT_NULL,
        },
        headerSections: [
            {
                'name': 'Комната',
                'title': 'Комната',
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование работ',
                        'default': '',
                        'required': true,
                        'disabled': true,
                        'className': 'td50',
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                        'disabled': true,
                    },
                    {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'select',
                        'tableValidationType': 'someServicesStatus',
                        'items': [
                            {
                                value: true,
                                text: 'Подтверждено',
                            },
                            {
                                value: false,
                                text: 'Не требуется',
                            },
                        ],
                    },
                ],
            },
            {
                'name': 'Кухня',
                'title': 'Кухня',
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование работ',
                        'default': '',
                        'required': true,
                        'className': 'td50',
                        'disabled': true,
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                        'disabled': true,
                    },
                    {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'select',
                        'tableValidationType': 'someServicesStatus',
                        'items': [
                            {
                                value: true,
                                text: 'Подтверждено',
                            },
                            {
                                value: false,
                                text: 'Не требуется',
                            },
                        ],
                    },
                ],
            },
            {
                'name': 'Окна',
                'title': 'Окна',
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование работ',
                        'default': '',
                        'required': true,
                        'className': 'td50',
                        'disabled': true,
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                        'disabled': true,
                    },
                    {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'select',
                        'tableValidationType': 'someServicesStatus',
                        'items': [
                            {
                                value: true,
                                text: 'Подтверждено',
                            },
                            {
                                value: false,
                                text: 'Не требуется',
                            },
                        ],
                    },
                ],
            },
            {
                'name': 'Балкон',
                'title': 'Балкон',
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование работ',
                        'default': '',
                        'required': true,
                        'className': 'td50',
                        'disabled': true,
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                        'disabled': true,
                    },
                    {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'select',
                        'tableValidationType': 'someServicesStatus',
                        'items': [
                            {
                                value: true,
                                text: 'Подтверждено',
                            },
                            {
                                value: false,
                                text: 'Не требуется',
                            },
                        ],
                    },
                ],
            },
            {
                'name': 'Санузел',
                'title': 'Санузел',
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование работ',
                        'default': '',
                        'required': true,
                        'className': 'td50',
                        'disabled': true,
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                        'disabled': true,
                    },
                    {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'select',
                        'tableValidationType': 'someServicesStatus',
                        'items': [
                            {
                                value: true,
                                text: 'Подтверждено',
                            },
                            {
                                value: false,
                                text: 'Не требуется',
                            },
                        ],
                    },
                ],
            },
        ],
    }),
    additionalServices: (disabled: boolean, isAdditionalServicesCreating: boolean) => ({
        version: VERSIONS.V2,
        type: 'table',
        mobileComponent: isAdditionalServicesCreating ? MobileComponentKeys.ADDITIONAL_SERVICES : MobileComponentKeys.SERVICES_VERIFICATION,
        disabled: disabled,
        disabledHeader: !isAdditionalServicesCreating,
        required: false,
        itemsNotNull: true,
        name: 'additionalServices',
        tabsTableMode: TabsTableMods.SINGLE,
        title: 'Дополнительные услуги',
        withToolbar: isAdditionalServicesCreating,
        withHideButton: true,
        alwaysShowHeader: true,
        withAddButton: isAdditionalServicesCreating,
        hocType: 'additionalServices',
        headerSections: [
            {
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование',
                        'default': '',
                        'required': true,
                        'disabled': !isAdditionalServicesCreating,
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                    },
                    {
                        'name': 'price',
                        'title': 'Сумма',
                        'mask': InputMask.COST,
                        'default': 0,
                        'className': 'td30',
                        'required': true,
                        'isSubscription': true,
                        'disabled': !isAdditionalServicesCreating,
                    },
                    !isAdditionalServicesCreating ? {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'select',
                        'isSubscription': true,
                        'tableValidationType': 'notNull',
                        'items': [
                            {
                                value: true,
                                text: 'Подтверждено',
                            },
                            {
                                value: false,
                                text: 'Не требуется',
                            },
                        ],
                    } : null,
                ],
            },
        ],
    }),
}

export const SERVICES_VERIFICATION_ACTIONS = {
    foremanActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'OBJECT_REPORT_FILLING',
                text: 'Подтвердить услуги',
                icon: 'forward',
            },
            {
                value: 'ADDITIONAL_SERVICES_CREATING',
                text: 'Требуются дополнительные работы',
                icon: 'repeat',
            },
            {
                value: 'SEND_TO_MANAGER',
                text: 'Отправить руководителю',
                icon: 'back',
            },
        ],
        version: 'v2',
    }),
    managerActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'OBJECT_REPORT_FILLING',
                text: 'Подтвердить услуги',
                icon: 'forward',
            },
            {
                value: 'ADDITIONAL_SERVICES_CREATING',
                text: 'Требуются дополнительные работы',
                icon: 'repeat',
            },
            {
                value: 'SEND_TO_MANAGER',
                text: 'Отправить руководителю',
                icon: 'back',
            },
        ],
        version: 'v2',
    }),
    additionalServicesAction: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'SERVICES_VERIFICATION',
                text: 'Отправить на подтверждение доп. услуг',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
}

interface SERVICES_VERIFICATION_PARAMS {
    additionalServices: TaskItem[]
    additionalServicesPhotos: string[]
    stages: string[]
    currentStage: StageType
    user: User
    archived: boolean
    responsibleUserId: string
    isLastSection: boolean
    counterparty: ClientType
}

export const SERVICES_VERIFICATION = (params: SERVICES_VERIFICATION_PARAMS) => {
    const {
        additionalServices,
        additionalServicesPhotos,
        stages,
        currentStage,
        user,
        archived,
        responsibleUserId,
        isLastSection,
        counterparty,
    } = params
    const newInputs = []
    const documentInputs = []
    if (rules[user?.role]?.sections.foremanWorkStarting || responsibleUserId === user.id) {
        const availableAction = StageManager.checkAvailableStageByRole(currentStage, user.role)
        const disabled = stages.includes('OBJECT_REPORT_FILLING')

        newInputs.push([SERVICES_VERIFICATION_SECTIONS.actualServices(disabled || !availableAction || (currentStage === 'ADDITIONAL_SERVICES_CREATING' && user.role === 'OPERATOR'))])

        const addServicesApproved = !!additionalServices
        if ((additionalServices || currentStage === 'ADDITIONAL_SERVICES_CREATING') && (availableAction || addServicesApproved)) {
            newInputs.push([
                SERVICES_VERIFICATION_SECTIONS.additionalServices(
                    disabled || !availableAction,
                    currentStage === 'ADDITIONAL_SERVICES_CREATING',
                ),
            ])
        }

        if (counterparty !== 'Юр. лицо') {
            newInputs.push([foremanWorkStarting.contractPhoto(disabled || !availableAction)])
            documentInputs.push(foremanWorkStarting.contractPhoto(disabled || !availableAction))
        }
        additionalServicesPhotos && newInputs.push([foremanWorkStarting.additionalServicesPhotos(true)])

        if (addServicesApproved && currentStage === 'SERVICES_VERIFICATION' && (availableAction || disabled)) {
            newInputs.push([foremanWorkStarting.addContractPhoto(disabled)])
            documentInputs.push(foremanWorkStarting.addContractPhoto(disabled))
        }

        if (availableAction && isLastSection) {
            const action = fetchFormAction({
                archived,
                section: 'REPORTS',
                user,
                currentStage,
                responsibleUserId,
            })
            if (action) {
                newInputs.push([action])
            }
        }
    }
    return {
        title: 'Сверка с клиентом выполняемых услуг',
        name: 'foremanReport',
        inputs: [...newInputs],
    }
}
