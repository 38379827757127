import { UseFormGetValues } from 'react-hook-form'
import { useSaveContractorMutation } from '~/API/contractorApi/contractor'
import { useSaveOrganizationMutation } from '~/API/organizationApi/organization'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { useAppDispatch } from '~/redux/store'

interface useSaveClientParams {
    clientType: ClientInfoEnum
}

export const useSaveClient = ({ clientType }: useSaveClientParams) => {
    const dispatch = useAppDispatch()

    const [saveOrganization] = useSaveOrganizationMutation()
    const [saveContractor] = useSaveContractorMutation()

    return (getValues: UseFormGetValues<any>, counterparty: 'Юр. лицо' | 'Физ. лицо') => {
        switch (clientType) {
            case ClientInfoEnum.ORGANIZATION: {
                if (counterparty === 'Юр. лицо') {
                    saveOrganization(getValues('primaryInformation.organizationRequisites')).unwrap()
                        .then(() => {
                            dispatch(SHOW_NOTICE({ type: 'success', message: 'Организация сохранена успешно' }))
                        })
                        .catch(() => {
                            dispatch(SHOW_NOTICE({
                                type: 'error',
                                message: 'Произошла ошибка при сохранении организации',
                            }))
                        })
                }
                break
            }
            case ClientInfoEnum.CONTRACTOR: {
                saveContractor(getValues('starting.contractor')).unwrap()
                    .then(() => {
                        dispatch(SHOW_NOTICE({ type: 'success', message: 'Подрядчик сохранен успешно' }))
                    })
                    .catch(() => {
                        dispatch(SHOW_NOTICE({ type: 'error', message: 'Произошла ошибка при сохранении подрядчика' }))
                    })
                break
            }
        }
    }
}
