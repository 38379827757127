import '../../../popups.scss'
import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ArchiveSvg from '../../../../../../assets/svg/archive.svg'
import { useOrderToArchive } from '~/hooks/useOrderToArchive/useOrderToArchive'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { selectActiveOrderId } from '~/redux/selectors/orderSlice.selectors'
import { closeArchivePopup } from '~/redux/slices/popupSlice/popupSlice'
import { useAppDispatch } from '~/redux/store'

const PopupToArchive = () => {
    const dispatch = useAppDispatch()

    const ref = useRef<HTMLDivElement>(null)

    const user = useSelector(selectUser)
    const activeOrderId = useSelector(selectActiveOrderId)

    const [error, setError] = useState<string>('')
    const [reason, setReason] = useState('')

    const orderToArchive = useOrderToArchive()

    const handleChange = (e: any) => {
        setError('')
        setReason(e.target.value)
    }

    const handleClose = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            dispatch(closeArchivePopup())
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (reason.trim() === '') {
            setError('Ошибка ввода')
        } else if (user && activeOrderId) {
            orderToArchive(reason)
        }
    }

    return (
        <div className='popupWrapper' onClick={handleClose}>
            <div className='popup popupToArchive' ref={ref}>
                <div className='popupToArchive__header'>
                    <div className='popupToArchive__icon'>
                        <ArchiveSvg />
                    </div>
                    <div className='popupToArchive__header__body'>
                        <h4>Перемещение в архив</h4>
                        <p>Укажите причину перемещения заявки</p>
                    </div>
                </div>
                <form>
                    <textarea
                        value={reason}
                        onChange={handleChange}
                        placeholder='Опишите подробно причины переноса заявки'
                        rows={4}
                        className={clsx({ error })}
                    />
                    {error && <p className='error_subscription'>*{error}</p>}
                    <button className='button popupToArchive__button' onClick={handleSubmit}>
                        Переместить
                    </button>
                </form>
                <CloseIcon className={'close'} onClick={() => dispatch(closeArchivePopup())} />
            </div>
        </div>
    )
}

export default PopupToArchive
