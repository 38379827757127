import { formatNumber, formatRUB } from '~/shared/util/formatNumberWithIntlOptions'
import { moneyToString } from '~/shared/util/moneyToString'

export interface SalaryIssuedPersonnel {
    name: string,
    objects: string,
    shifts: number,
    sum: string,
    note?: string,
}

export interface SalaryIssuedValues {
    division: string,
    period: string,
    recipient: string,
    issueDate: string,
    personnel: SalaryIssuedPersonnel[]
}

export const salaryIssued = async (values: SalaryIssuedValues) => {
    const {
        division,
        period,
        recipient,
        issueDate,
        personnel,
    } = values

    const personnelPerPage = 25
    const pageCount = Math.ceil(personnel.length / personnelPerPage)
    const amount = personnel.reduce((acc, item) => acc + +item.sum, 0)
    const amountString = moneyToString(amount)

    const html = await fetch('/html/salary_issued.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    const domHtml = parser.parseFromString(htmlText, 'text/html')

    domHtml.querySelectorAll('.division').forEach((element) => element.innerHTML = division)
    domHtml.querySelectorAll('.period').forEach((element) => element.innerHTML = period)

    domHtml.querySelectorAll('.personnel').forEach((element, i) => {
        element.innerHTML = personnel
            .slice(personnelPerPage * i, personnelPerPage * (i + 1))
            .map((item, index) => {
                return `
                    <tr>
                        <td class='center'>${personnelPerPage * i + index + 1}</td>
                        <td class='center'>${item.name}</td>
                        <td class='center'>${item.objects}</td>
                        <td class='center'>${item.shifts}</td>
                        <td class='center'>${formatRUB(Number(item.sum || 0), false)}</td>
                        <td class='center'>${item.note ?? ''}</td>
                    </tr>
                `
            })
            .join('')
    })

    domHtml.querySelectorAll('.recipient').forEach((element) => element.innerHTML = recipient)
    domHtml.querySelectorAll('.issueDate').forEach((element) => element.innerHTML = issueDate)
    domHtml.querySelectorAll('.amount').forEach((element) => element.innerHTML = `${formatNumber({ num: amount })} (${amountString})`)
    domHtml.querySelectorAll('.currentPage').forEach((element, i) => element.innerHTML = (i + 1).toString())
    domHtml.querySelector('.pageCount')!.innerHTML = pageCount.toString()


    domHtml.querySelectorAll('.page').forEach((element, i) => i >= pageCount && element.remove())

    return { style: domHtml.querySelector('style')!.innerHTML, body: domHtml.querySelector('body')!.innerHTML }
}
