import ExpandMore from '@mui/icons-material/ExpandMore'
import clsx from 'clsx'
import Fuse from 'fuse.js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FieldValues, UseFormClearErrors } from 'react-hook-form'
import Modal from 'react-modal'
import CustomSearchInput from '~/components/common/inputs/CustomSearchInput/CustomSearchInput'
import {
    CustomSelectPresents,
    CustomSelectThemes,
    SELECT_PRESETS,
} from '~/components/common/inputs/CustomSelect/CustomSelect'
import {
    MobileCustomSelectItem,
} from '~/components/mobile/mobileCommon/mobileInputs/MobileCustomSelect/MobileCustomSelectItem/MobileCustomSelectItem'
import './MobileCustomSelect.scss'
import { InputParamsItem } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { StageData } from '~/redux/slices/ArchiveSlice/ArchiveSlice.types'

interface IMobileCustomSelect {
    title?: string,
    items: InputParamsItem<any>[],
    value: any,
    className?: string,
    onChange: (value: any) => void,
    error?: any,
    disabled?: boolean,
    placeholder?: string,
    name?: string,
    inputName?: string,
    clearErrors?: UseFormClearErrors<FieldValues>,
    isSearchable?: boolean
    theme?: CustomSelectThemes
    onClick?: (...args: any[]) => void
    preset?: CustomSelectPresents
}

const MobileCustomSelect = (props: IMobileCustomSelect) => {
    const {
        title,
        items,
        value,
        className,
        onChange,
        error,
        disabled,
        placeholder,
        name,
        inputName,
        clearErrors,
        isSearchable = false,
        theme = CustomSelectThemes.OUTLINE,
        onClick,
        preset,
    } = props
    const ref = useRef<HTMLDivElement>(null)

    const currentItems = preset ? SELECT_PRESETS[preset] : items

    const [modalIsOpen, setIsOpen] = useState(false)
    const [searchText, setSearchText] = useState<string>('')
    const [searchResult, setSearchResult] = useState<Array<StageData>>(currentItems)
    const fuse = new Fuse(currentItems, {
        keys: [
            'text',
            'value',
        ],
    })

    const openModal = useCallback(() => {
        setIsOpen(true)
    }, [])
    const closeModal = useCallback(() => {
        setSearchText('')
        setIsOpen(false)
    }, [])
    const handleClick: (value: any) => void = (value: any) => {
        inputName && clearErrors && clearErrors(inputName)
        onChange && onChange(value)
        closeModal()
    }

    const handleSetSearch = useCallback((value: string) => {
        setSearchText(value)
        ref?.current?.scroll(0, 0)
    }, [])

    useEffect(() => {
        if (searchText === '') {
            setSearchResult(currentItems)
        }
        if (searchText) {
            const res = fuse.search(searchText)
            setSearchResult(res.map((r) => r.item))
        }
    }, [searchText, currentItems])

    return (
        <>
            <div className={clsx('customField', className, { error, disabled })}>
                <span>{title}</span>
                <div
                    className={clsx('customField__select', theme && `customField__select--${theme}`)}
                    onClick={() => {
                        !disabled && openModal()
                    }}
                >
                    {value !== undefined &&
                    value !== null &&
                    value !== '' &&
                    currentItems?.some((item: any) => item.value === value) ? (
                        <span>{currentItems.find((item: any) => item.value === value)?.text}</span>
                            ) : value === undefined || value === null || value === '' ? (
                                <span className='customField__placeholder'>
                                    {placeholder !== null && placeholder !== undefined ? placeholder : 'Выберите значение'}
                                </span>
                            ) : (
                                <span>{value}</span>
                            )}
                    <ExpandMore className={clsx('customField__expand', { 'active': modalIsOpen })} />
                </div>
            </div>
            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    id='customSelectModal'
                    style={{
                        overlay: {
                            zIndex: 100,
                        },
                    }}
                >
                    <div className='modalCustomSelect'>
                        <div
                            className='modalCustomSelect__header'
                            ref={ref}
                        >
                            {isSearchable && (
                                <CustomSearchInput
                                    onChange={handleSetSearch}
                                    placeholder='Поиск сотрудника'
                                    className='modalCustomSelect__search'
                                />
                            )}
                            <ul className='modalCustomSelect__list'>
                                {searchResult?.map((option: any, index: number) => {
                                    return (
                                        <MobileCustomSelectItem
                                            key={`${option.text}_${index}`}
                                            option={option}
                                            handleClick={handleClick}
                                            index={index}
                                            searchText={searchText}
                                            onClick={onClick}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default MobileCustomSelect
