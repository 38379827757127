import clsx from 'clsx'
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa'
import './CheckIcon.scss'

interface CheckIconProps {
    isSuccess?: boolean
    active?: boolean
}

const CheckIcon = (props: CheckIconProps) => {
    const {
        isSuccess,
        active,
    } = props

    return !isSuccess && active ? <FaRegCheckCircle size={16} className={clsx('checkIconBorder')} /> :
    <FaCheckCircle size={16} className={clsx('checkIcon', {
        'checkIcon--green': isSuccess && !active,
        'checkIcon--white': isSuccess && active,
    })} />
}

export default CheckIcon
