import { Dayjs } from 'dayjs'
import Fuse from 'fuse.js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import {
    MobileCustomSelectItem,
} from '../../mobileInputs/MobileCustomSelect/MobileCustomSelectItem/MobileCustomSelectItem'
import CustomSearchInput from '~/components/common/inputs/CustomSearchInput/CustomSearchInput'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import '../../mobileInputs/MobileCustomSelect/MobileCustomSelect.scss'
import './MobileCustomPopup.scss'

interface ButtonParams {
    className?: string,
    title: string,
    callback: (...args: any[]) => void,
    disabled?: boolean
}

interface MobileCustomPopupProps {
    modalIsOpen: boolean
    closeModal: (...args: any[]) => void
    isSearchable?: boolean
    searchTitle?: string
    handleClick: (value: any) => void
    items: any[]
    currentItem?: string | Date | Dayjs | null | boolean
    buttons?: {
        cancelBtn: ButtonParams,
        submitBtn: ButtonParams,
    }
}

const MobileCustomPopup = (props: MobileCustomPopupProps) => {
    const {
        modalIsOpen,
        closeModal,
        isSearchable,
        searchTitle = 'Поиск',
        handleClick,
        items,
        currentItem,
        buttons,
    } = props
    const ref = useRef<HTMLDivElement>(null)

    const [searchText, setSearchText] = useState('')
    const [searchResult, setSearchResult] = useState(items)

    const fuse = new Fuse(items, {
        keys: [
            'text',
            'value',
        ],
    })

    const handleSetSearch = useCallback((value: string) => {
        setSearchText(value)
        ref?.current?.scroll(0, 0)
    }, [])

    useEffect(() => {
        if (searchText === '') {
            setSearchResult(items)
        }
        if (searchText) {
            const res = fuse.search(searchText)
            setSearchResult(res.map((r) => r.item))
        }
    }, [searchText, items])

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            id='customSelectModal'
        >
            <div className='mobile-custom-popup'>
                <div
                    className='mobile-custom-popup__header'
                    ref={ref}
                >
                    {isSearchable && handleSetSearch && (
                        <CustomSearchInput
                            onChange={handleSetSearch}
                            placeholder={searchTitle}
                            className='mobile-custom-popup__search'
                        />
                    )}
                    <ul className='mobile-custom-popup__list'>
                        {searchResult?.map((option, index) => {
                            return (
                                <MobileCustomSelectItem
                                    key={`${option.text}_${index}`}
                                    option={option}
                                    handleClick={handleClick}
                                    index={index}
                                    searchText={searchText}
                                    isActive={currentItem === option.value}
                                />
                            )
                        })}
                    </ul>
                </div>
                {buttons && (
                    <div className='mobile-custom-popup__buttons'>
                        <CustomButton
                            className={buttons.cancelBtn.className}
                            onClick={buttons.cancelBtn.callback}
                            theme={CustomButtonThemes.SMOKE}
                        >
                            {buttons.cancelBtn.title}
                        </CustomButton>
                        <CustomButton
                            className={buttons.submitBtn.className}
                            onClick={buttons.submitBtn.callback}
                            theme={CustomButtonThemes.COLOR}
                            disabled={buttons.submitBtn.disabled}
                        >
                            {buttons.submitBtn.title}
                        </CustomButton>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default MobileCustomPopup
