import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useParams } from 'react-router-dom'
import { buildSingleDateComments } from './lib/helpers'
import { useLazyFetchUserByIdQuery } from '~/API/userApi/user'
import { User } from '~/API/userApi/user.types'
import Preloader from '~/components/common/preloaders/Preloader/Preloader'
import {
    CommentListCommon,
} from '~/components/common/ReusingComponents/Comments/subComponents/CommentList/components/CommentListCommon'
import {
    CommentListImportant,
} from '~/components/common/ReusingComponents/Comments/subComponents/CommentList/components/CommentListImportant'
import { useChat } from '~/hooks/useChat/useChat'
import { rules } from '~/shared/order/rules'
import { IRulesComments } from '~/types/types.order'

const commentTitles: Record<keyof IRulesComments, string> = {
    orderConfirmation: 'Подтверждение заявки',
    visitConfirmation: 'Выезд на уборку',
    servicesConfirmation: 'Согласование перечня работ',
    orderClosing: 'Завершение заявки',
    archiveReason: 'Причина переноса в архив',
    archiveOutReason: 'Причина восстановления из архива',
    contractSigning: 'Согласование договора',
    updatePersonnelInfo: 'Изменение рабочей информации сотрудника',
    informationToBeTransmitted: 'Информация для передачи',
    stageTransition: 'Подтверждение заявки',
    needChanges: 'Нужны изменения',
    dateBooking: 'Бронирование даты',
    toDivision: 'Отправление в подразделение',
    dateChanging: 'Изменение даты',
    IMPORTANT: '',
    CLASSIC: '',
}

interface CommentListProps {
    isScrolled: boolean
    handleSetScrolled: (value: boolean) => void
    user: User
}

export const CommentList = (props: CommentListProps) => {
    const {
        isScrolled,
        handleSetScrolled,
        user,
    } = props
    const { id } = useParams()

    const [fetchUserById] = useLazyFetchUserByIdQuery({ refetchOnReconnect: false })
    const [usersInfo, setUsersInfo] = useState<Record<string, User> | null>(null)
    const { messages, isLoading } = useChat({ id })
    const ref = useRef<HTMLDivElement>(null)
    const { ref: inViewRef, inView } = useInView({
        threshold: 0,
    })

    useEffect(() => {
        /** preventing memory leaks */
        let isMounted = true

        if (messages.length > 0) {
            if (ref.current) {
                if (!isScrolled || inView) {
                    ref.current.scrollTo(0, ref.current.scrollHeight)
                    handleSetScrolled(true)
                }
            }
            const uniqueIds = new Set([...messages.map((e) => e.userId)].filter((value) => value))
            Promise.all([...uniqueIds].map((id) => fetchUserById(id, true).unwrap()))
                .then((usersInfo) => {
                    if (isMounted) {
                        setUsersInfo(usersInfo.reduce((accum, userInfo) => ({
                            ...accum,
                            [userInfo.id]: userInfo,
                        }), {} as Record<string, User>))
                    }
                })
        }

        return () => {
            /** when the component unmounts, set isMounted to false */
            isMounted = false
        }
    }, [messages])

    const CommentBody = useMemo(() => {
        if (user && usersInfo) {
            return Object.entries(buildSingleDateComments(messages))
                .map(([key, values]) => {
                    const allowedMessages = values.filter((commentGroup) =>
                        rules[user.role as keyof typeof rules].comments[commentGroup[0].type as unknown as keyof IRulesComments],
                    )
                    return allowedMessages.length > 0 && (
                        <React.Fragment key={key}>
                            <div className='comments__container'>
                                <div className='comments__date'>
                                    <span className='comments__date__text'>
                                        {key}
                                    </span>
                                </div>
                                {allowedMessages.map((commentGroup, groupIndex) => {
                                    switch (commentGroup[0].type) {
                                        case 'IMPORTANT':
                                            return commentGroup.map((comment, index: number) => {
                                                return (
                                                    <CommentListImportant
                                                        key={`${comment.message}${index}`}
                                                        message={comment.message}
                                                        date={comment.date}
                                                    />
                                                )
                                            })
                                        default:
                                            return (
                                                <CommentListCommon
                                                    key={'comment_' + commentGroup[0].type + groupIndex}
                                                    commentGroup={commentGroup}
                                                    user={user}
                                                    groupIndex={groupIndex}
                                                    usersInfo={usersInfo}
                                                    title={commentTitles[commentGroup[0].type as unknown as keyof IRulesComments]}
                                                />
                                            )
                                    }
                                })}
                            </div>
                        </React.Fragment>
                    )
                })
        }
        return null
    }, [messages, user, usersInfo])

    return (
        <div className='comments__body' ref={ref}>
            {messages?.length > 0 && user ? (
                <>
                    {CommentBody}
                    <div style={{ marginBottom: 1 }} ref={inViewRef} />
                </>
            ) : (
                isLoading ? (
                    <Preloader className='comments__preloader' size='small' />
                ) : (
                    <div className='comments__empty-message__container'>
                        <span className='comments__empty-message'>Нет сообщений</span>
                    </div>
                )
            )}
        </div>
    )
}
