import { RouteProps } from 'react-router-dom'
import { AnalyticPage } from '../../pages/Analytic'
import { Archive } from '../../pages/Archive'
import { ContractPage } from '../../pages/Contract'
import { EventsPage } from '../../pages/Events'
import { HomePage } from '../../pages/Home'
import { OrderPage } from '../../pages/Order'
import { SalaryPage } from '../../pages/Salary'
import { ServiceSmetaPage } from '../../pages/ServiceSmeta'
import { SettingsPage } from '../../pages/Settings'
import { Staff } from '../../pages/Staff'
import { StaffForm } from '../../pages/Staff/subComponents/StaffForm'
import { ErrorPage, ErrorPageThemes } from '~/components/pages/ErrorPage'
import { FTZPage } from '~/components/pages/FTZ'
import { KpPage } from '~/components/pages/KP'
import { KpoPage } from '~/components/pages/Kpo'
import OrderCreator from '~/components/pages/Order/subComponents/OrderCreator/OrderCreator'
import { SmetaPage } from '~/components/pages/Smeta'
import { StartingSmetaPage } from '~/components/pages/StartingSmeta'
import { Test2Async, TestAsync } from '~/test/Test.async'
import { IRulesPagesKeys } from '~/types/types.order'

export enum DashboardRoutes {
    HOME = 'home',
    SETTINGS = 'settings',
    EVENTS = 'events',
    SALARY = 'salary',
    ANALYTIC = 'analytic',
    ORDER = 'order',
    ORDER_APPLICATION = 'order_application',
    CONTACT = 'contract',
    CONTACT_FILE = 'contract_file',
    TEST = 'test',
    TEST2 = 'test2',
    STAFF = 'staff',
    STAFF_FORM = 'staff_form',
    STAFF_FORM_DIVISION = 'staff_form_division',
    STAFF_FORM_WORKSTATUS = 'staff_form_workstatus',
    ARCHIVE = 'archive',
    // SIDEBAR = 'sidebar',
    ORDER_CREATOR = 'order_creator',
    ORDER_CREATOR_APPLICATION = 'order_creator_application',
    SMETA = 'smeta',
    NOT_FOUND = 'not_found',
    KPO = 'kpo',
    KPO_STARTING = 'kpo_starting',
    SERVICE_SMETA = 'service_smeta',
    SERVICE_SMETA_STARTING = 'service_smeta_starting',
    KP = 'kp',
    FTZ = 'ftz'
}

export const DashboardRoutePath: Record<DashboardRoutes, string> = {
    [DashboardRoutes.HOME]: '/',
    [DashboardRoutes.SETTINGS]: '/settings',
    [DashboardRoutes.EVENTS]: '/events',
    [DashboardRoutes.SALARY]: '/salary',
    [DashboardRoutes.ANALYTIC]: '/analytic',
    [DashboardRoutes.ORDER]: '/report/',
    [DashboardRoutes.ORDER_APPLICATION]: '/report/:id/',
    [DashboardRoutes.CONTACT]: '/report/:id/contract/',
    [DashboardRoutes.CONTACT_FILE]: '/report/:id/contract/:fileId/',
    [DashboardRoutes.TEST]: '/test',
    [DashboardRoutes.TEST2]: '/test2',
    [DashboardRoutes.STAFF]: '/staff',
    [DashboardRoutes.STAFF_FORM]: '/staff/:id',
    [DashboardRoutes.STAFF_FORM_DIVISION]: '/staff/:id/:division',
    [DashboardRoutes.STAFF_FORM_WORKSTATUS]: '/staff/:id/:division/:workStatus',
    [DashboardRoutes.ARCHIVE]: '/archive',
    // [DashboardRoutes.SIDEBAR]: '/sidebar',
    [DashboardRoutes.ORDER_CREATOR]: '/order/',
    [DashboardRoutes.ORDER_CREATOR_APPLICATION]: '/order/:id/',
    [DashboardRoutes.SMETA]: '/order/:id/smeta/:serviceNumber',
    [DashboardRoutes.SERVICE_SMETA]: '/order/:id/service-smeta/:serviceNumber',
    [DashboardRoutes.SERVICE_SMETA_STARTING]: '/order/:id/service-smeta-starting',
    [DashboardRoutes.KP]: '/order/:id/kp',
    [DashboardRoutes.KPO_STARTING]: '/order/:id/kpo-starting',
    [DashboardRoutes.KPO]: '/order/:id/kpo',
    [DashboardRoutes.FTZ]: '/order/:id/ftz',
    [DashboardRoutes.NOT_FOUND]: '*',
}

export const dashboardRouteConfig: Record<DashboardRoutes, RouteProps & { rulesCheck?: IRulesPagesKeys }> = {
    [DashboardRoutes.HOME]: {
        path: DashboardRoutePath.home,
        element: <HomePage />,
    },
    [DashboardRoutes.SETTINGS]: {
        path: DashboardRoutePath.settings,
        element: <SettingsPage />,
    },
    [DashboardRoutes.EVENTS]: {
        path: DashboardRoutePath.events,
        element: <EventsPage />,
    },
    [DashboardRoutes.SALARY]: {
        path: DashboardRoutePath.salary,
        element: <SalaryPage />,
        rulesCheck: 'salary',
    },
    [DashboardRoutes.ANALYTIC]: {
        path: DashboardRoutePath.analytic,
        element: <AnalyticPage />,
        rulesCheck: 'analytic',
    },
    [DashboardRoutes.ORDER]: {
        path: DashboardRoutePath.order,
        element: <OrderPage />,
    },
    [DashboardRoutes.ORDER_APPLICATION]: {
        path: DashboardRoutePath.order_application,
        element: <OrderPage />,
    },
    [DashboardRoutes.CONTACT]: {
        path: DashboardRoutePath.contract,
        element: <ContractPage />,
    },
    [DashboardRoutes.CONTACT_FILE]: {
        path: DashboardRoutePath.contract_file,
        element: <ContractPage />,
    },
    [DashboardRoutes.TEST]: {
        path: DashboardRoutePath.test,
        element: <TestAsync />,
    },
    [DashboardRoutes.TEST2]: {
        path: DashboardRoutePath.test2,
        element: <Test2Async />,
    },
    [DashboardRoutes.STAFF]: {
        path: DashboardRoutePath.staff,
        element: <Staff />,
        rulesCheck: 'schedule',
    },
    [DashboardRoutes.STAFF_FORM]: {
        path: DashboardRoutePath.staff_form,
        element: <StaffForm />,
        rulesCheck: 'schedule',
    },
    [DashboardRoutes.STAFF_FORM_DIVISION]: {
        path: DashboardRoutePath.staff_form_division,
        element: <StaffForm />,
        rulesCheck: 'schedule',
    },
    [DashboardRoutes.STAFF_FORM_WORKSTATUS]: {
        path: DashboardRoutePath.staff_form_workstatus,
        element: <StaffForm />,
        rulesCheck: 'schedule',
    },
    [DashboardRoutes.ARCHIVE]: {
        path: DashboardRoutePath.archive,
        element: <Archive />,
        rulesCheck: 'archive',
    },
    // [DashboardRoutes.SIDEBAR]: {
    //     path: DashboardRoutePath.sidebar,
    //     element: <MobileSidebarPage />,
    // },
    [DashboardRoutes.ORDER_CREATOR]: {
        path: DashboardRoutePath.order_creator,
        element: <OrderCreator />,
    },
    [DashboardRoutes.ORDER_CREATOR_APPLICATION]: {
        path: DashboardRoutePath.order_creator_application,
        element: <OrderCreator />,
    },
    [DashboardRoutes.SMETA]: {
        path: DashboardRoutePath.smeta,
        element: <SmetaPage />,
    },
    [DashboardRoutes.NOT_FOUND]: {
        path: DashboardRoutePath.not_found,
        element: <ErrorPage theme={ErrorPageThemes.NOT_FOUND} errorCode={404} />,
    },
    [DashboardRoutes.KPO]: {
        path: DashboardRoutePath.kpo,
        element: <KpoPage />,
    },
    [DashboardRoutes.KPO_STARTING]: {
        path: DashboardRoutePath.kpo_starting,
        element: <KpoPage />,
    },
    [DashboardRoutes.SERVICE_SMETA]: {
        path: DashboardRoutePath.service_smeta,
        element: <ServiceSmetaPage />,
    },
    [DashboardRoutes.SERVICE_SMETA_STARTING]: {
        path: DashboardRoutePath.service_smeta_starting,
        element: <StartingSmetaPage />,
    },
    [DashboardRoutes.KP]: {
        path: DashboardRoutePath.kp,
        element: <KpPage />,
    },
    [DashboardRoutes.FTZ]: {
        path: DashboardRoutePath.ftz,
        element: <FTZPage />,
    },
}
