import { FaEye } from '@react-icons/all-files/fa/FaEye'
import clsx from 'clsx'
import React, { MouseEvent, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import {
    WorkersAdditionalDataParams,
} from '~/components/common/ReusingComponents/TabsTable/hocs/withPersonnel/withPersonnel'
import {
    TabsTableCellProps,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTablePage/subComponents/TabsTableCell/TabsTableCell'
import SinglePaymentCancelModal
    from '~/components/pages/Salary/subComponents/SalaryCalendar/subComponents/SalaryTable/subComponents/SalaryTableCell/SinglePaymentCancelModal/SinglePaymentCancelModal'
import { selectSalarySinglePayment } from '~/redux/selectors/salarySlice.selectors'
import { formatNumber } from '~/shared/util/formatNumberWithIntlOptions'

interface SingleSalaryCellProps extends TabsTableCellProps {
    value: any
    workersAdditionalData: WorkersAdditionalDataParams
}

export const SingleSalaryCell = (props: SingleSalaryCellProps) => {
    const {
        value,
        workersAdditionalData,
        name,
        rowIndex,
    } = props

    const {
        getValues,
    } = useFormContext<OrderInterface>()

    const singleSalaries = useSelector(selectSalarySinglePayment)

    const [isSingleSalaryHovered, setSingleSalaryHovered] = useState(false)
    const [isSingleSalaryModalOpen, setSingleSalaryModalOpen] = useState(false)

    const onSingleSalaryClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setSingleSalaryModalOpen(true)
    }

    const currentSingleSalary = useMemo(() => {
        return singleSalaries.find((singleSalary) =>
            singleSalary.personnelId === getValues(`${name}.${rowIndex}.personnelId` as keyof OrderInterface) &&
            getValues().id === singleSalary.cleaningId)
    }, [singleSalaries])

    return (
        <>
            <a
                onMouseEnter={() => setSingleSalaryHovered(true)}
                onMouseLeave={() => setSingleSalaryHovered(false)}
                onClick={onSingleSalaryClick}
                className={clsx('nzSection--payed')}
            >
                {isSingleSalaryHovered ? <FaEye /> : formatNumber({ num: value })}
            </a>
            {isSingleSalaryModalOpen && currentSingleSalary &&
                <SinglePaymentCancelModal
                    onClose={() => setSingleSalaryModalOpen(false)}
                    singleSalary={currentSingleSalary}
                    cleaningDate={workersAdditionalData.date}
                    orderId={workersAdditionalData.orderId}
                    address={workersAdditionalData.address}
                    isNzTeam={true}
                />
            }
        </>
    )
}
