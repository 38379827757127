import { useWatch } from 'react-hook-form'

interface UseCustomDisabledOptions {
    customDisabledType?: string
    rowName: string
}

export const useCustomDisabled = (options: UseCustomDisabledOptions): boolean => {
    const {
        customDisabledType,
        rowName,
    } = options

    const watchRow = useWatch({ name: rowName })

    if (!watchRow) return false

    switch (customDisabledType) {
        case 'toolsReportFile': {
            return +watchRow.in >= watchRow.amount
        }
        default:
            return false
    }
}
