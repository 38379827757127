import {
    calculateWithDiscount,
    organizationRequisitesNds,
    organizationRequisitesOrisclean,
    organizationRequisitesWithoutNds,
} from './docUtil'
import { fetchDate } from '../../util/currentTime'
import { moneyToString } from '../../util/moneyToString'
import { KpService } from '~/components/pages/KP/kp.types'
import { formatRUB } from '~/shared/util/formatNumberWithIntlOptions'

const ORISCLEAN = 'ORISCLEAN'

export const contractBill = async (values: any) => {
    const html = await fetch('/html/contract_bill.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    const domHtml = parser.parseFromString(htmlText, 'text/html')

    const date = fetchDate()
    const dateString = date.format('LL')
    const day = dateString.split(' ')[0]

    let ourRequisites: typeof organizationRequisitesWithoutNds | typeof organizationRequisitesNds | typeof organizationRequisitesOrisclean
    if (values.division === ORISCLEAN) {
        ourRequisites = organizationRequisitesOrisclean
    } else if (values.paymentType === 'р/с (НДС)') {
        ourRequisites = organizationRequisitesNds
    } else {
        ourRequisites = organizationRequisitesWithoutNds
    }

    const ourRequisitesArray = [
        ourRequisites.name,
        'ИНН ' + ourRequisites.inn,
        'КПП ' + ourRequisites.kpp,
        ourRequisites.postAddress,
        'тел.: ' + ourRequisites.phone,
    ]
    const ourRequisitesString = ourRequisitesArray.join(', ')
    const theirRequisitesArray = [
        values.organizationRequisites.name,
        'ИНН ' + values.organizationRequisites.inn,
        ...(values.organizationRequisites.kpp ? ['КПП ' + values.organizationRequisites.kpp] : []),
        values.organizationRequisites.postAddress,
        'тел.: ' + (values.organizationRequisites.phone || values.contactInfo?.[0]?.clientPhone),
    ]
    const theirRequisitesString = theirRequisitesArray.join(', ')

    const discount = Number(values.kpDiscount)
    let servicesCounter = 0
    domHtml.querySelector('#services')!.innerHTML = values?.commercialOffer?.services
        .map((item: KpService, index: number) => {
            if (item.isSelected) {
                servicesCounter++
                const costWithDiscount = item.price ? +calculateWithDiscount(+item.price, +item.discount, +item.discount > 0) : 0
                const unitCost = () => {
                    return costWithDiscount && Number(item.amount) ? (costWithDiscount / Number(item.amount)).toFixed(2) : '0.00'
                }

                return `
                    <tr> 
                        <td>${servicesCounter}</td>
                        <td colspan='2'>${item.name} по адресу ${values.address}</td>
                        <td class='center'>${item.amount}</td>
                        <td class='center'>${item.measure}</td>
                        <td class='center'>${formatRUB(Number(unitCost()), false)}</td>
                        <td class='center'>${formatRUB(Math.floor(costWithDiscount), false)}</td>
                    </tr>
                `
            }
        },
        )
        .join('')

    const cost = Number(values.cost)
    const priceBeforeTax = values.commercialOffer.services.reduce((acc: any, service: any) => service.priceBeforeTax ? acc + service.priceBeforeTax : acc, 0)
    const nds = 20 * cost / 120

    domHtml.querySelector('#cost')!.innerHTML = formatRUB(cost, false)
    domHtml.querySelector('#tax')!.innerHTML = formatRUB(Math.floor(nds), false)

    domHtml.querySelectorAll('.ourName').forEach((element) => (element.innerHTML = ourRequisites.name))
    domHtml.querySelector('#ourInn')!.innerHTML = ourRequisites.inn
    domHtml.querySelector('#ourKpp')!.innerHTML = ourRequisites.kpp
    domHtml.querySelector('#ourBankId')!.innerHTML = ourRequisites.bankId
    domHtml.querySelector('#ourBankName')!.innerHTML = ourRequisites.bankName
    domHtml.querySelector('#ourBankAccount')!.innerHTML = ourRequisites.bankAccount
    domHtml.querySelector('#ourCorrespondentAccount')!.innerHTML = ourRequisites.correspondentAccount

    domHtml.querySelectorAll('.directorShortName').forEach((element) => (element.innerHTML = ourRequisites.directorShortName))

    domHtml.querySelectorAll('.contractNumber').forEach((element) => (element.innerHTML = values.contractNumber))
    domHtml
        .querySelectorAll('.createdAt')
        .forEach((element) => (element.innerHTML = ' от «' + day + '» ' + dateString.substring(day.length)))
    domHtml.querySelector('#ourRequisites')!.innerHTML = ourRequisitesString
    domHtml.querySelector('#theirRequisites')!.innerHTML = theirRequisitesString
    domHtml.querySelectorAll('.requisitesName').forEach((element) => (element.innerHTML = ourRequisites.name))

    domHtml.querySelector(
        '#servicesDescription',
    )!.innerHTML = `Всего наименований ${values?.commercialOffer?.services?.length}, на сумму ${formatRUB(values.cost, false)}`
    domHtml.querySelector('#costString')!.innerHTML = moneyToString(values.cost)

    if (values.paymentType !== 'р/с (НДС)') {
        domHtml.querySelectorAll<HTMLElement>('.taxRow').forEach((element) => (element.style.display = 'none'))
    }
    domHtml.querySelector<HTMLElement>('#sign')!.style.backgroundImage = 'url(' + ourRequisites.sign + ')'

    return { style: domHtml.querySelector('style')!.innerHTML, body: domHtml.querySelector('body')!.innerHTML }
}
