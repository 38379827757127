import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useFetchOrderQuery } from '~/API/orderApi/order'
import { InventoryItem } from '~/API/orderApi/types/accountingAndSelling.type'
import { ToolGroupEnum } from '~/API/toolsApi/tools.types'
import { AmortizationItem } from '~/components/pages/Smeta/smeta.types'

type ToolsReportValues = {
    type: ToolGroupEnum,
    title: string,
    items: InventoryItem[] | AmortizationItem[]
}[]

export const useToolsReportSubscription = (name: string): {
    handleSubscriptionFieldsChange: () => void,
    isLoadingOrder: boolean,
} => {
    const { id: orderId } = useParams()
    const {
        getValues,
        setValue,
    } = useFormContext()
    const { data: order, isFetching: isLoadingOrder } = useFetchOrderQuery(orderId!)

    const handleSubscriptionFieldsChange = () => {
        if (order) {
            if ((!order.toolsReport.actualConsumables || !order.toolsReport.actualEquipment) || order.stageName === 'INVENTORY_ITEMS_RETURNING' || order.stageName === 'MANAGER_CHECKING') {
                const tableValues: ToolsReportValues = getValues(name)
                const daysCount: number = getValues('foremanReport.daysReport').length

                const { actualConsumables, actualEquipment } = tableValues.reduce((accum, section) => {
                    if (section.items.length === 0) {
                        return accum
                    }

                    const sectionReduce = section.items?.reduce((accum, tool) => {
                        if (isInventoryItem(tool, section.type)) {
                            return tool.unitPrice * (tool.amount - Number(tool.in || 0)) + accum
                        }
                        return +tool.amount * +tool.unitPrice * daysCount + accum
                    }, 0)

                    if (section.type === ToolGroupEnum.CHEMISTRY || section.type === ToolGroupEnum.CONSUMABLES || section.type === ToolGroupEnum.WIPINGS) {
                        accum.actualConsumables += sectionReduce
                    } else {
                        accum.actualEquipment += sectionReduce
                    }

                    return accum
                }, {
                    actualConsumables: 0,
                    actualEquipment: 0,
                })

                if (name.includes('actualTools')) {
                    setValue('toolsReport.actualConsumables', Math.round(actualConsumables))
                } else {
                    setValue('toolsReport.actualEquipment', Math.round(actualEquipment))
                }
            }
        }
    }

    useEffect(() => {
        if (order) {
            handleSubscriptionFieldsChange()
        }
    }, [order])

    return { handleSubscriptionFieldsChange, isLoadingOrder }
}

function isInventorySection(sectionType: ToolGroupEnum): boolean {
    return (
        sectionType === ToolGroupEnum.CONSUMABLES ||
        sectionType === ToolGroupEnum.WIPINGS ||
        sectionType === ToolGroupEnum.CHEMISTRY
    )
}

function isInventoryItem(tool: InventoryItem | AmortizationItem, sectionType: ToolGroupEnum): tool is InventoryItem {
    return isInventorySection(sectionType)
}
