import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ruRU } from '@mui/x-date-pickers/locales'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ReactNode } from 'react'
import 'dayjs/locale/ru'

export const MuiLocalizationProvider = ({ children }: { children?: ReactNode }) => {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='ru'
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            {children}
        </LocalizationProvider>
    )
}
