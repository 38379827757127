import { BiLeftArrowAlt } from '@react-icons/all-files/bi/BiLeftArrowAlt'
import { BiRightArrowAlt } from '@react-icons/all-files/bi/BiRightArrowAlt'
import clsx from 'clsx'
import { useContext, ReactElement } from 'react'
import { TabsTableContext } from '../../TabsTableContext'
import TabsTableHeaderButton from './subComponents/TabsTableHeaderButton/TabsTableHeaderButton'
import TabsTableHeaderTabs from './subComponents/TabsTableHeaderTabs/TabsTableHeaderTabs'

import './TabsTableHeader.scss'

interface TabsTableHeaderProps {
    customHeader?: ReactElement
    className?: string
}

const TabsTableHeader = (props: TabsTableHeaderProps) => {
    const {
        customHeader,
        className,
    } = props

    const {
        activeTabIndex,
        setActiveTabIndex,
        pages,
        disabled,
        withAddButton,
    } = useContext(TabsTableContext)

    const leftArrowHandler = () => {
        if (activeTabIndex !== 0) {
            setActiveTabIndex((activeTabIndex) => activeTabIndex - 1)
        }
    }

    const rightArrowHandler = () => {
        if (activeTabIndex < pages.length - 1) {
            setActiveTabIndex((activeTabIndex) => activeTabIndex + 1)
        }
    }

    return (
        <div className='tabsTableHeader'>
            <div className={clsx('tabsTableHeader__tabs', className, {
                'tabsTableHeader__tabs--withAddButton': withAddButton,
            })}>
                <TabsTableHeaderTabs />
                <div className='tabsTableHeader__arrows'>
                    <div className={clsx('tabsTableHeader__arrow', {
                        'tabsTableHeader__arrow--disabled': activeTabIndex === 0,
                    })}
                    >
                        <BiLeftArrowAlt
                            size={22}
                            onClick={leftArrowHandler}
                        />
                    </div>
                    <div className={clsx('tabsTableHeader__arrow', {
                        'tabsTableHeader__arrow--disabled': activeTabIndex >= pages.length - 1,
                    })}
                    >
                        <BiRightArrowAlt
                            size={22}
                            onClick={rightArrowHandler}
                        />
                    </div>
                </div>
            </div>
            <div className='tabsTableHeader__content'>
                {withAddButton && !disabled && <TabsTableHeaderButton />}
                {customHeader}
            </div>
        </div>
    )
}

export default TabsTableHeader
