import './CustomFieldArray.scss'
import { useEffect } from 'react'
import { Control, FieldValues, useFieldArray, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { CustomFieldArrayBody } from './subComponents/CustomFieldArrayBody'
import { InputParams, InputTemplateType } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { setReplaceMethod } from '~/redux/slices/clientSlice/clientSlice'
import { useAppDispatch } from '~/redux/store'

interface CustomFieldArrayProps {
    items?: InputParams[] | InputTemplateType
    name: string
    control: Control
    setValue: UseFormSetValue<FieldValues>
    getValues?: UseFormGetValues<FieldValues>
    disabled: boolean
    disableMode?: boolean
    division?: string
    className?: string
    isContractor?: boolean
}

const CustomFieldArray = (props: CustomFieldArrayProps) => {
    const {
        items = [],
        name,
        control,
        setValue,
        getValues,
        disabled,
        disableMode,
        division,
        className,
        isContractor,
    } = props

    const dispatch = useAppDispatch()

    const {
        fields,
        append,
        remove,
        replace,
    } = useFieldArray({
        control,
        name,
    })

    useEffect(() => {
        if (isContractor) {
            dispatch(setReplaceMethod(replace))
        }
    }, [])

    useEffect(() => {
        if (!fields || fields.length === 0) {
            setValue(name, [Object.values(items).reduce((accum, item) => ({ ...accum, [item.name]: '' }), {})])
        }
    }, [fields])

    return (
        <div className='customFieldArray'>
            <div className='customFieldArray__body'>
                {fields.map((field, index) => (
                    <div className='customFieldArray__row' key={field.id}>
                        {Object.values(items).map((item, itemIndex) => (
                            <CustomFieldArrayBody
                                item={item}
                                name={name}
                                index={index}
                                disableMode={disableMode}
                                fieldsLength={fields.length}
                                division={division}
                                key={`${field.id}_${name}_${index}_${item.name}_item`}
                                className={className}
                                append={append}
                                remove={remove}
                                items={items}
                                disabled={disabled}
                                isLastIndex={itemIndex === Number(items.length) - 1}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CustomFieldArray
