import ExpandLess from '@mui/icons-material/ExpandLess'
import Collapse from '@mui/material/Collapse'
import clsx from 'clsx'
import React, { useEffect, useState, ReactNode } from 'react'
import { isMobile } from 'react-device-detect'
import { UseFormGetValues } from 'react-hook-form'
import { Tab } from '~/components/common/formConstructor/FormSection/CustomTabsWithSlider/CustomTabsWithSlider'

export const isSectionDisabled = (isDisabled?: isSectionDisabledParams, getValues?: UseFormGetValues<any>) => {
    let condition = false
    if (getValues && isDisabled?.watchInputs?.length) {
        switch (isDisabled?.mode) {
            case 'EVERY':
                condition = isDisabled?.watchInputs.every((input) => {
                    return !getValues(input)
                })
                break
            case 'SOME':
            default:
                isDisabled?.watchInputs.some((input) => {
                    if (!getValues(input)) {
                        condition = !getValues(input)
                        return true
                    }
                    return false
                })
                break
        }
    }
    return condition
}

export interface isSectionDisabledParams {
    watchInputs: string[]
    mode: 'EVERY' | 'SOME'
}

interface InputBodyProps {
    title: string
    isActive: boolean
    isBlock?: boolean
    isMultiple?: boolean
    isDisabled?: isSectionDisabledParams
    getValues?: UseFormGetValues<any>
    tabs?: Tab[] // todo посмотреть что за табы и на каких инпутах они есть
    defaultTab?: number
    divisionDependence?: string[]
    children?: ReactNode
}

export const WithSectionBody = (props: InputBodyProps) => {
    const {
        title,
        isActive,
        isBlock,
        isDisabled,
        isMultiple,
        tabs,
        defaultTab,
        children,
        getValues,
        divisionDependence,
    } = props
    const [inStatus, setInStatus] = useState(isActive)

    useEffect(() => {
        setInStatus(isActive)
    }, [isActive])

    return (
        <>
            {!isSectionDisabled(isDisabled, getValues) && (
                <div className={clsx('form-constructor', { 'form-constructor__block': isBlock })}>
                    <div className='form-constructor__header'>
                        <h3
                            onClick={() => {
                                setInStatus((prev) => !prev)
                            }}
                        >
                            {title}
                        </h3>
                        {!isBlock && !isMobile && (
                            <div className='form-constructor__icon'>
                                <ExpandLess
                                    className={clsx({ active: inStatus })}
                                    onClick={() => {
                                        setInStatus((prev) => !prev)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    {!isBlock && !isMobile ? (
                        <Collapse in={inStatus} timeout={100}>
                            <div className='form-constructor__body'>
                                {children}
                            </div>
                        </Collapse>
                    ) : (
                        <div className='form-constructor__body'>
                            {children}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
