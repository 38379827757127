import { rules } from '~/shared/order/rules'
import { StageManager } from '~/shared/order/StageManager'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { managerOrderReport } from '~/shared/templates/inputTemplates/managerOrderReport'
import { additionalInputs } from '~/shared/templates/inputTemplates/v2/additionalInputs'

export const MANAGER_CHECKING_ACTIONS = {
    mainActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'GENERATE_NZ_RESULT',
                text: 'Сформировать наряд-заказ (результат)',
                icon: 'repeat',
            },
            {
                value: 'QUALITY_CONTROLLING',
                text: 'Отправить на контроль качества',
                icon: 'forward',
            },
            {
                value: 'DEFECTS_CORRECTING',
                text: 'Отправить на исправление недочетов',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
    withoutNzActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'GENERATE_NZ_RESULT',
                text: 'Сформировать наряд-заказ (результат)',
                icon: 'repeat',
            },
        ],
        version: 'v2',
    }),
}

interface MANAGER_CHECKING_PARAMS {
    order: any,
    businessType: string,
    reportDate: Date,
    stages: string[],
    currentStage: string,
    user: any,
    archived: boolean,
    responsibleUserId?: string
    isLastSection: boolean
}

export const MANAGER_CHECKING = (params: MANAGER_CHECKING_PARAMS) => {
    const {
        order,
        businessType,
        reportDate,
        stages,
        currentStage,
        user,
        archived,
        responsibleUserId,
        isLastSection,
    } = params
    const newInputs = []
    if (rules[user?.role]?.sections.orderManagerReport) {
        if (reportDate) {
            newInputs.push([
                additionalInputs.division(true),
                additionalInputs.responsibleUserId(true, 'orderManagerReport.division', 'responsibleManager'),
                additionalInputs.reportDate(true),
            ])
        } else {
            newInputs.push([
                additionalInputs.division(true),
            ])
        }
        const availableAction = StageManager.checkAvailableStageByRole('MANAGER_CHECKING', user.role)
        const disabled = stages.includes('DEFECTS_CORRECTING')

        if (availableAction) {
            newInputs.push([managerOrderReport.orderManagerComment(disabled)])
            if (order.documents?.orderManagerReport?.nzFull) {
                newInputs.push([additionalInputs.nzFull(true, 'documents.orderManagerReport.nzFull')])
            }
            if (order.documents?.orderManagerReport?.nzFrontPhoto || order.documents?.orderManagerReport?.nzBackPhoto) {
                newInputs.push([additionalInputs.nzFrontPhoto(disabled, 'documents.orderManagerReport.nzFrontPhoto'), additionalInputs.nzBackPhoto(disabled, 'documents.orderManagerReport.nzBackPhoto')])
            }
        }

        if (availableAction && isLastSection) {
            const action = fetchFormAction({
                order,
                archived,
                section: 'ORDER_MANAGER_REPORT',
                user,
                currentStage,
            })
            if (action) {
                newInputs.push([action])
            }
        }
    }
    return {
        title: 'Проверка руководителя',
        name: 'orderManagerReport',
        inputs: [...newInputs],
    }
}
