export enum InputTypeEnum {
    action = 'action',
    address = 'address',
    checkbox = 'checkbox',
    checkboxGroup = 'checkboxGroup',
    date = 'date',
    document = 'document',
    documentList = 'documentList',
    fieldArray = 'fieldArray',
    fieldArrayPatent = 'fieldArrayPatent',
    inputNZ = 'inputNZ',
    muiDate = 'muiDate',
    multipleSelector = 'multipleSelector',
    organization = 'organization',
    personnel = 'personnel',
    radioGroup = 'radioGroup',
    rating = 'rating',
    reportTable = 'reportTable',
    select = 'select',
    table = 'table',
    tableServiceDescription = 'tableServiceDescription',
    text = 'text',
    title = 'title',
    user = 'user',
    client = 'client',
    button = 'button',
    clientInfo = 'clientInfo',
    comment = 'comment',
    linkButton = 'linkButton',
    qualityReminder = 'qualityReminder'
    // statusBar = 'statusBar',
    // clientOrganization = 'clientOrganization'
}
