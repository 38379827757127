import clsx from 'clsx'
import React, { memo, useRef, useState } from 'react'
import './PopupFilter.scss'
import { Portal } from '../../ReusingComponents/Portal/Portal'
import { orderTypes } from '~/shared/util/types'

interface PopupFilterProps {
    onClose: () => void
    sections: string[]
    handleSelectSection: (value: string) => void
}

export const PopupFilter = memo(({ onClose, sections, handleSelectSection }: PopupFilterProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const [selectedSection, setSelectedSection] = useState<string>('')

    const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
            onClose()
            handleSelectSection('')
        }
    }

    const handeCloseButton = () => {
        onClose()
        handleSelectSection('')
    }

    const handleSelect = (item: string) => {
        setSelectedSection(item)
    }

    const handleSendSection = () => {
        handleSelectSection(selectedSection)
        onClose()
    }

    return (
        <Portal>
            <div className='popupWrapper--filter' onClick={handleClose}>
                <div className='popup-sections' ref={ref}>
                    <div className='popup-sections__body'>
                        {sections.map((section) => (
                            <button
                                key={section}
                                className={clsx(
                                    'popup-sections__section',
                                    { 'popup-sections__section_selected': selectedSection === section },
                                )}
                                onClick={() => handleSelect(section)}
                            >
                                {orderTypes[section as keyof typeof orderTypes]}
                            </button>
                        ))}
                    </div>
                    <div className='popup-sections__buttons'>
                        <button
                            onClick={handeCloseButton}
                            className='popup-sections__buttons_close'
                        >
                            Отмена
                        </button>
                        <button
                            onClick={handleSendSection}
                            className='popup-sections__buttons_select'
                        >
                            Выбрать
                        </button>
                    </div>
                </div>
            </div>
        </Portal>
    )
})
