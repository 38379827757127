import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StaffScheduleSliceInitialState } from './staffSchedule.type'
import { Division, User } from '~/API/userApi/user.types'
import { ScheduleObj } from '~/redux/slices/orderSlice/types/orderSlice.type'
import { fetchDate } from '~/shared/util/currentTime'

const staffScheduleSliceInitialState: StaffScheduleSliceInitialState = {
    watchingRoles: ['FOREMAN', 'ORDER_MANAGER'],
    isLoading: false,
    schedule: [],
    activeSchedule: [],
    personnelBD: [],
    activeDate: new Date(),
    filtersForEvents: [],
    scheduleForMiniCalendar: [],
    dayDetailPopup: false,
}

export const staffScheduleSlice = createSlice({
    name: 'staffSchedule',
    initialState: staffScheduleSliceInitialState,
    reducers: {
        setActiveDate: (state: StaffScheduleSliceInitialState, action) => {
            state.activeDate = action.payload
        },
        setSchedule: (state: StaffScheduleSliceInitialState, action) => {
            state.schedule = action.payload
        },
        setActiveSchedule: (state: StaffScheduleSliceInitialState, action) => {
            state.activeSchedule = action.payload
        },
        setStaffScheduleLoading: (state: StaffScheduleSliceInitialState, action) => {
            state.isLoading = action.payload
        },
        setFilerForEvents: (state: StaffScheduleSliceInitialState, action) => {
            state.filtersForEvents = action.payload
        },
        setScheduleForMiniCalendar: (state: StaffScheduleSliceInitialState, action) => {
            state.scheduleForMiniCalendar = action.payload
        },
        setDayDetailPopup: (state, action: PayloadAction<boolean>) => {
            state.dayDetailPopup = action.payload
        },
        getActiveScheduleFulfilled: (state, action: PayloadAction<{
            personnelBD: User[],
            schedule: ScheduleObj[],
            activeDate: Date
            division: Division
        }>) => {
            const { personnelBD, schedule, activeDate, division } = action.payload
            state.activeSchedule = schedule.map((scheduleDay, index) => {
                return {
                    // добавляем на каждый день start и end для того, чтоб они отмечались как events в календаре
                    ...scheduleDay,
                    start: new Date(activeDate.getFullYear(), activeDate.getMonth(), fetchDate(scheduleDay.date).date(), 0, 0, 0, 0),
                    end: new Date(activeDate.getFullYear(), activeDate.getMonth(), fetchDate(scheduleDay.date).date(), 23, 59, 59, 0),
                }
            })
            // personnelBD это все сотрудники из базы данных, которые мы вытянули по фильтрам (должность)
            state.personnelBD = personnelBD as User[]

            const daysInMonth = 33 - new Date(activeDate.getFullYear(), activeDate.getMonth(), 33).getDate()

            const daysSchedule: any = Array(daysInMonth)
                .fill(1337) // просто чтоб массив не был пустым
                .reduce((acc, _, index) => {
                    const date = new Date(activeDate.getFullYear(), activeDate.getMonth(), index + 1)
                    const scheduleDay = schedule.find((schedule: any) => fetchDate(schedule.date).isSame(fetchDate(date), 'day'))

                    if (scheduleDay && personnelBD && scheduleDay.personnel.length > 0) {
                        scheduleDay.personnel
                            .map((person: any) => ({
                                ...person,
                                ...(personnelBD.find((e: User) => e.id === person.personnelId) || {}),
                            }))
                            .forEach((person: any) => {
                                acc.push({
                                    id: scheduleDay.id,
                                    title: fetchDate(date).format('D MMM YYYY'),
                                    start: new Date(activeDate.getFullYear(), activeDate.getMonth(), index + 1, 0, 0, 0, 0),
                                    end: new Date(activeDate.getFullYear(), activeDate.getMonth(), index + 1, 23, 59, 59, 0),
                                    personnel: person,
                                })
                            })
                    } else {
                        acc.push({
                            id: `${new Date(activeDate.getFullYear(), activeDate.getMonth(), index + 1).toUTCString()}_${division}`,
                            title: fetchDate(new Date(activeDate.getFullYear(), activeDate.getMonth(), index + 1)).format('D MMM YYYY'),
                            start: new Date(activeDate.getFullYear(), activeDate.getMonth(), index + 1, 0, 0, 0, 0),
                            end: new Date(activeDate.getFullYear(), activeDate.getMonth(), index + 1, 23, 59, 59, 0),
                            personnel: [],
                        })
                    }
                    return acc
                }, [])
                .filter((scheduleDay: any) => scheduleDay.personnel.status !== 'NOT_WORKING')

            state.isLoading = false
            if (state.activeSchedule.length === 0) {
                state.activeSchedule = daysSchedule
            }
            state.schedule = daysSchedule
        },
    },
})

export const {
    setStaffScheduleLoading,
    setActiveDate,
    setFilerForEvents,
    setScheduleForMiniCalendar,
    setDayDetailPopup,
    getActiveScheduleFulfilled,
} = staffScheduleSlice.actions


export default staffScheduleSlice.reducer
