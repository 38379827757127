import 'dayjs/locale/ru'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ParallaxProvider } from 'react-scroll-parallax'
import App from './components/app/App'
import './styles/index.scss'
import './styles/global.scss'
import store from './redux/store'
import ThemeContextWrapper from './styles/darktheme/ThemeContextWrapper'
import { ErrorBoundary } from '~/components/app/providers/ErrorBoundary'
import dayjs from '~/shared/util/dayjsConfig'

dayjs.locale('ru')

const container = document.getElementById('root')

if (!container) {
    throw new Error('Контейнер root не найден. НЕ удалось вмонтировать реакт приложение')
}

const root = createRoot(container)

root.render(
    <ThemeContextWrapper>
        <React.StrictMode>
            <Provider store={store}>
                <ParallaxProvider>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </ParallaxProvider>
            </Provider>
        </React.StrictMode>
    </ThemeContextWrapper>,
)
