import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DaDataParty, DaDataSuggestion, PartySuggestions } from 'react-dadata'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { BsQuestionCircle } from 'react-icons/bs'
import { PopupOrganization } from '../../popups/PopupOrganization/PopupOrganization'
import { BankDataType } from '../../popups/PopupOrganization/PopupOrganization.types'
import { useLazyFetchContractorByIdQuery } from '~/API/contractorApi/contractor'
import { useLazyFetchOrganizationByInnQuery } from '~/API/organizationApi/organization'
import { ClientInfoType } from '~/components/common/inputs/CustomClientInput/CustomClientInput.types'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'
import { useUpdateOrganization } from '~/hooks/useUpdateOrganization/useUpdateOrganization'
import './CustomOrganizationInput.scss'

export interface DaDataSuggestionValues<T> extends DaDataSuggestion<T> {
    bankData: BankDataType,
    name: string
}

interface ICustomOrganizationInput {
    values: DaDataSuggestionValues<DaDataParty>
    value?: ClientInfoType,
    onChange: (value: any) => void
    title: string
    error?: any
    className?: string
    disabled: boolean
    withPopup?: boolean
    placeholder?: string
    additionalMethods?: UseFormReturn
    clientType?: ClientInfoEnum
}

const CustomOrganizationInput = (props: ICustomOrganizationInput) => {
    const {
        values,
        onChange,
        title,
        error,
        className,
        disabled,
        value,
        withPopup = true,
        placeholder,
        additionalMethods,
        clientType = ClientInfoEnum.ORGANIZATION,
    } = props

    const updateOrganization = useUpdateOrganization({ onChange, additionalMethods, clientType })

    const [fetchOrganizationByInn] = useLazyFetchOrganizationByInnQuery()
    const [fetchContractorById] = useLazyFetchContractorByIdQuery()

    const [popupOrganization, setPopupOrganization] = useState<boolean>(false)
    const [organizationValues, setOrganizationValues] = useState<DaDataSuggestion<DaDataParty>>(values)
    const [bankData, setBankData] = useState<BankDataType>({} as BankDataType)
    const [currentValues, setCurrentValues] = useState<DaDataSuggestion<DaDataParty>>(values)

    const ref = useRef<PartySuggestions>(null)

    const handleSetOrganization = useCallback((value: boolean) => {
        setPopupOrganization(value)
    }, [])

    const handleSetBankData = useCallback((value: BankDataType) => {
        setBankData(value)
    }, [])

    const handleChange = (values: any) => {
        updateOrganization(values)
            .then(({ newValues, bankData }) => {
                setCurrentValues(newValues)
                setOrganizationValues(newValues)
                setBankData(bankData)
            })
    }

    useEffect(() => {
        let isMounted = true

        if (isMounted && values && values.bankData) {
            setBankData(values.bankData)
        }

        return () => {
            isMounted = false
        }
    }, [values])

    useEffect(() => {
        if ((value?.inn || value?.value) && !values) {
            switch (clientType) {
                case ClientInfoEnum.ORGANIZATION: {
                    fetchOrganizationByInn((value?.inn || value?.value), true).unwrap()
                        .then((res) => {
                            updateOrganization({ data: { ...res, name: { short_with_opf: res.name } } })
                                .then(({ newValues, bankData }) => {
                                    setCurrentValues(newValues)
                                    setOrganizationValues(newValues)
                                    setBankData(bankData)
                                })
                        })
                    break
                }
                case ClientInfoEnum.CONTRACTOR: {
                    fetchContractorById((value?.inn || value?.value), true).unwrap()
                        .then((res) => {
                            updateOrganization({ data: { ...res, name: { short_with_opf: res.name } } })
                                .then(({ newValues, bankData }) => {
                                    setCurrentValues(newValues)
                                    setOrganizationValues(newValues)
                                    setBankData(bankData)
                                })
                        })
                    break
                }
            }
        }
    }, [])

    useEffect(() => {
        if (ref.current) {
            ref.current.setInputValue(value?.value || '')
        }
    }, [value])

    return (
        <>
            <div className={clsx('customField', 'customOrganizationInput', className, { error, disabled })}>
                <span>{title}</span>
                <div className='customField__input'>
                    {!disabled ? (
                        <PartySuggestions
                            token={import.meta.env.VITE_DADATA_TOKEN}
                            value={{ ...currentValues, value: value?.text ? value.text : currentValues?.value }}
                            onChange={handleChange}
                            delay={300}
                            defaultQuery={value?.value}
                            minChars={1}
                            containerClassName='customOrganizationInput__body'
                            ref={ref}
                            filterLocationsBoost={[{ kladr_id: '78' }, { kladr_id: '47' }]}
                            inputProps={{
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (!e.target.value) {
                                        onChange(null)
                                    }
                                },
                                placeholder,
                                // ref,
                            }}
                        />
                    ) : (
                        <input
                            className='customOrganizationInput__body'
                            disabled={disabled}
                            value={values?.value || value?.value || ''}
                            placeholder={placeholder}
                        />
                    )}
                    {(values || value) && organizationValues && withPopup && (
                        <BsQuestionCircle
                            title='Информация об организации'
                            className={clsx('customOrganizationInput__icon', { disabled })}
                            onClick={() => setPopupOrganization(true)}
                        />
                    )}
                </div>
            </div>
            {popupOrganization && (
                <PopupOrganization
                    organizationValues={organizationValues}
                    bankData={bankData}
                    handleSetOrganization={handleSetOrganization}
                    handleSetBankData={handleSetBankData}
                    disabled={disabled}
                />
            )}
        </>
    )
}

export default CustomOrganizationInput
