export const throttle = (fn: (...args: any[]) => void, ms: number) => {
    let ready = true

    return (...args: any[]) => {
        if (!ready) return

        fn(...args)
        ready = false
        const timer = setTimeout(() => {
            ready = true
            clearTimeout(timer)
        }, ms)
    }
}
