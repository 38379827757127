import { parseServices } from '../collectCommercialOfferServices/collectCommercialOfferServices'
import { KpAdditionalService, KpServiceWithNumberWageFund } from '~/components/pages/KP/kp.types'
import {
    firstKpServicesPageTemplate, firstKpServicesPageTemplateOrisclean,
    KpServicesPageTemplate, KpServicesPageTemplateOrisclean,
    lastKpServicesPageTemplate,
    lastKpServicesPageTemplateOrisclean,
    singleKpServicesPageTemplate,
    singleKpServicesPageTemplateOrisclean,
} from '~/shared/templates/mainTemplates/additionalFunctions/collectKpServices/collectKpServices.templates'
import { calculateWithDiscount } from '~/shared/templates/mainTemplates/docUtil'
import { formatNumber } from '~/shared/util/formatNumberWithIntlOptions'

export const collectKpServices = (serviceDescriptions: KpServiceWithNumberWageFund[], style: string) => {
    const newServices = serviceDescriptions.map((item, index) => {
        return buildServiceDescriptionTable(item, index)
    })
    return parseServices(newServices, 563, style)
}

const buildServiceDescriptionTable = (serviceDescription: KpServiceWithNumberWageFund, index: number) => {
    const costWithDiscount = serviceDescription.price ? +calculateWithDiscount(+serviceDescription.price, +serviceDescription.discount, +serviceDescription.discount > 0) : 0
    const unitCost = () => {
        return costWithDiscount && Number(serviceDescription.amount) ? (costWithDiscount / Number(serviceDescription.amount)).toFixed(2) : '0.00'
    }

    return `
        <tr>
            <td colspan='1' style='height:35px; text-align:center'>${index + 1 + '.'}</td>
            <td colspan='6' style='text-align:center'>${serviceDescription.name}</td>
            <td colspan='2' style='text-align:center'>${serviceDescription.amount}</td>
            <td colspan='1' style='text-align:center'>${serviceDescription.measure}</td>
            <td colspan='2' style='text-align:center'>${formatNumber({ num: Number(unitCost()), minimumFractionDigits: 2 })}</td>
            <td colspan='2' style='text-align:center'>${Math.floor(costWithDiscount).toLocaleString()}</td>
        </tr>
        <tr>
            <th colspan='2' style='text-align: right; padding-right: 5px; height: 40px;'>График работ:</th>
            <td colspan='13' style='padding-left: 5px;padding-right: 5px'>${serviceDescription.schedule.replace(/\n/g, '<br>')}</td>
        </tr>
        <tr>
            <th colspan='2' style='text-align: right; padding-right: 5px; height: 40px;'>План выполнения работ:</th>
            <td colspan='13' class='servicesDesc' style='padding-left: 5px;padding-right: 5px'>${serviceDescription.workDescription.replace(/\n/g, '<br>')}</td>
        </tr>
        <tr>
            <th colspan='2' style=' text-align: right; padding-right: 5px; height: 40px;'>Количество персонала:</th>
            <td colspan='13' style='padding-left: 5px; padding-right: 5px;'>${serviceDescription.personnelDescription.replace(/\n/g, '<br>')}</td>
        </tr>
    `
}

export const buildAdditionalServiceTable = (services: KpAdditionalService[]) => {
    return services.map((service, index) => (`
        <tr>
            <td colspan='1' style='height:35px; text-align:center'>${index + 1 + '.'}</td>
            <td colspan='6' style='text-align:center'>${service.name}</td>
            <td colspan='2' style='text-align:center'>${service.amount}</td>
            <td colspan='1' style='text-align:center'>${service.measure}</td>
            <td colspan='2' style='text-align:center'>${service.unitPrice}</td>
            <td colspan='2' style='text-align:center'>${service.priceWithDiscount}</td>
        </tr>
    `)).join('')
}

export const makeAdditionalKpPages = (servicesAdditionalPages: string[][], isOrisclean: boolean) => {
    return servicesAdditionalPages.map((item, index) => generateNewKpServicesPageWithBody(item.join(''), index === 0 ? 'FIRST' : 'CENTER', isOrisclean)).join('')
}

type generateNewPageWithBodyPage = 'LAST' | 'FIRST' | 'CENTER' | 'SINGLE'

export const generateNewKpServicesPageWithBody = (body: string, page: generateNewPageWithBodyPage, isOrisclean: boolean) => {
    const div = document.createElement('div')
    switch (page) {
        case 'SINGLE':
            div.innerHTML = isOrisclean ? singleKpServicesPageTemplateOrisclean.trim() : singleKpServicesPageTemplate.trim()
            break
        case 'LAST': {
            div.innerHTML = isOrisclean ? lastKpServicesPageTemplateOrisclean.trim() : lastKpServicesPageTemplate.trim()
            break
        }
        case 'FIRST': {
            div.innerHTML = isOrisclean ? firstKpServicesPageTemplateOrisclean.trim() : firstKpServicesPageTemplate.trim()
            break
        }
        case 'CENTER': {
            div.innerHTML = isOrisclean ? KpServicesPageTemplateOrisclean.trim() : KpServicesPageTemplate.trim()
            break
        }
    }
    div.querySelector('#services')!.innerHTML = body.trim()
    return div.innerHTML
}

export const generateAdditionalServicesTable = (services: KpAdditionalService[]) => {
    return `<table class='servicesTable' style='margin-top: -1px;'>
                <thead>
                    <tr>
                        <th colspan='14' style='text-align:center; height: 35px;'>Дополнительные услуги</th>
                    </tr>
                    <tr>
                        <th colspan='1' style='width: 20px; text-align:center; height: 35px;'>№</th>
                        <th colspan='6' style='width: 48%; text-align:center;'>Наименование работ/услуг</th>
                        <th colspan='2' style='width: 30px; text-align:center;'>Кол-во</th>
                        <th colspan='1' style='width: 30px; text-align:center;'>Ед.</th>
                        <th colspan='2' style='width: 40px; text-align:center;'>Цена за ед.</th>
                        <th colspan='2' style='text-align:center;'>Сумма</th>
                    </tr>
                </thead>
                <tbody>
                   ${buildAdditionalServiceTable(services)}
                </tbody>
           </table>`
}
