import { FC, memo, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useFetchOrderQuery } from '~/API/orderApi/order'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import TabsTableSubtotalFooter
    from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableSubtotalFooter/TabsTableSubtotalFooter'
import { TabsTableProps } from '~/components/common/ReusingComponents/TabsTable/TabsTable'
import { formatRUB } from '~/shared/util/formatNumberWithIntlOptions'

const withAdditionalServices = (TabsTable: FC<TabsTableProps>): FC<TabsTableProps> => {
    const WrappedComponent = (props: TabsTableProps) => {
        const { id: orderId } = useParams()
        const { data: order, isFetching: isLoadingOrder } = useFetchOrderQuery(orderId!)

        const [total, setTotal] = useState(0)

        const { getValues } = useFormContext<OrderInterface>()

        const setTotalValue = () => {
            const total = getValues(props.name as 'foremanReport.additionalServices').reduce((acc, item) => {
                if (item.status !== false || order?.stageName === 'ADDITIONAL_SERVICES_CREATING') {
                    acc += item.price ? +item.price : 0
                }
                return acc
            }, 0)
            setTotal(total)
        }

        useEffect(() => {
            setTotalValue()
        }, [order])

        return <TabsTable
            {...props}
            isLoading={isLoadingOrder}
            onSubscriptionFieldChange={setTotalValue}
            onRowChange={setTotalValue}
            footer={<TabsTableSubtotalFooter value={formatRUB(total)} text='Подытог' />}
        />
    }

    return memo(WrappedComponent)
}

export default withAdditionalServices
