import clsx from 'clsx'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import './CustomTabNavbarItem.scss'
import { useSelector } from 'react-redux'
import { selectEventLoader } from '~/redux/selectors/orderSlice.selectors'
import { isStaffEdit } from '~/redux/selectors/popupSlice.selectors'

interface CustomTabNavbarItemProps {
    value: string | number,
    title: string | number,
    index: number,
    onChange: (
        value: string | number,
        index: number,
        offset: number,
        width: number,
    ) => void,
    currentItem: number,
    handleGetUnderline: (offsetLeft: number, width: number) => void,
    isActive: boolean,
}

export const CustomTabNavbarItem = (props: CustomTabNavbarItemProps) => {
    const {
        value,
        title,
        index,
        onChange,
        currentItem,
        handleGetUnderline,
        isActive,
    } = props

    const menuItemRef = useRef<HTMLDivElement>(null)

    const isStaffSchedule = useSelector(isStaffEdit)
    const eventLoader = useSelector(selectEventLoader)

    useEffect(() => {
        if (isStaffSchedule) {
            isActive && onChange(
                value,
                index,
                menuItemRef.current ? menuItemRef.current.offsetLeft : 0,
                menuItemRef.current ? menuItemRef.current.clientWidth : 0,
            )
        }
    }, [isStaffSchedule])

    useEffect(() => {
        if (menuItemRef.current && index === currentItem) {
            handleGetUnderline(menuItemRef.current.offsetLeft, menuItemRef.current.clientWidth)
        }
    }, [currentItem, eventLoader])

    return (
        <div
            className={
                clsx('custom-tab-navbar-item',
                     { 'custom-tab-navbar-item_selected': index === currentItem || isActive })
            }
            ref={menuItemRef}
            onClick={() => onChange(
                value,
                index,
                menuItemRef.current ? menuItemRef.current.offsetLeft : 0,
                menuItemRef.current ? menuItemRef.current.clientWidth : 0,
            )}
        >
            {title}
        </div>
    )
}
