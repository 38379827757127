import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChartColumn, CostChartsPopupKeys, Month } from '~/components/pages/ServiceSmeta/serviceSmeta.types'

interface ServiceSmetaSliceState {
    costChart: ServiceSmetaCostChart
    budgetChart: {
        withNds: boolean
        marginality: ServiceSmetaMarginality
    }
    disabled: boolean
    errorKeys: CostChartsPopupKeys[]
    customAverageProceeds: number | null
}

export interface ServiceSmetaCostChart {
    tmc: ChartColumn[][],
    contract: ChartColumn[][],
    overhead: ChartColumn[][],
    personnel: ChartColumn[][],
    equipment: ChartColumn[][],
}

export type ServiceSmetaMarginality = Record<Month, number>

interface SetCostChartSectionPayload {
    key: CostChartsPopupKeys
    chartData: ChartColumn[][]
}

interface SetBudgetChartMarginalityPayload {
    month?: Month
    value: number
}

const serviceSmetaSliceInitialState: ServiceSmetaSliceState = {
    costChart: {
        tmc: [],
        contract: [],
        overhead: [],
        personnel: [],
        equipment: [],
    },
    budgetChart: {
        withNds: true,
        marginality: {
            january: 0.2,
            february: 0.2,
            march: 0.2,
            april: 0.2,
            may: 0.2,
            june: 0.2,
            july: 0.2,
            august: 0.2,
            september: 0.2,
            october: 0.2,
            november: 0.2,
            december: 0.2,
        },
    },
    disabled: true,
    errorKeys: [],
    customAverageProceeds: null,
}

export const serviceSmetaSlice = createSlice({
    name: 'serviceSmeta',
    initialState: serviceSmetaSliceInitialState,
    reducers: {
        setCostChartSection: (state, action: PayloadAction<SetCostChartSectionPayload>) => {
            state.costChart[action.payload.key] = action.payload.chartData
        },
        resetCostChartSection: (state, action: PayloadAction<CostChartsPopupKeys>) => {
            state.costChart[action.payload] = []
        },
        setBudgetChartMarginality: (state, action: PayloadAction<SetBudgetChartMarginalityPayload>) => {
            if (action.payload.month) {
                state.budgetChart.marginality[action.payload.month] = action.payload.value
            } else {
                state.budgetChart.marginality.january = action.payload.value
                state.budgetChart.marginality.february = action.payload.value
                state.budgetChart.marginality.march = action.payload.value
                state.budgetChart.marginality.april = action.payload.value
                state.budgetChart.marginality.may = action.payload.value
                state.budgetChart.marginality.june = action.payload.value
                state.budgetChart.marginality.july = action.payload.value
                state.budgetChart.marginality.august = action.payload.value
                state.budgetChart.marginality.september = action.payload.value
                state.budgetChart.marginality.october = action.payload.value
                state.budgetChart.marginality.november = action.payload.value
                state.budgetChart.marginality.december = action.payload.value
            }
        },
        setAllMarginality: (state, action: PayloadAction<ServiceSmetaMarginality>) => {
            state.budgetChart.marginality = action.payload
        },
        resetServiceSmeta: () => {
            return serviceSmetaSliceInitialState
        },
        setWithNds: (state, action: PayloadAction<boolean>) => {
            state.budgetChart.withNds = action.payload
        },
        setDisabled: (state, action: PayloadAction<boolean>) => {
            state.disabled = action.payload
        },
        setErrorKeys: (state, action: PayloadAction<CostChartsPopupKeys[]>) => {
            state.errorKeys = action.payload
        },
        setCustomAverageProceeds: (state, action: PayloadAction<number | null>) => {
            state.customAverageProceeds = action.payload
        },
    },
})

export const {
    setCostChartSection,
    resetCostChartSection,
    setBudgetChartMarginality,
    resetServiceSmeta,
    setAllMarginality,
    setWithNds,
    setDisabled,
    setErrorKeys,
    setCustomAverageProceeds,
} = serviceSmetaSlice.actions

export default serviceSmetaSlice.reducer
