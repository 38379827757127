import { ToolGroupEnum } from '~/API/toolsApi/tools.types'

export const orderTypes = {
    abonement: 'Абонемент',
    accountingAndSelling: 'Расчет/Продажа', // for documents
    accounting_and_selling: 'Расчет/Продажа',
    agreement: 'Договор',
    all_files: 'Все документы',
    cleaning: 'Уборка',
    closed: 'Завершено',
    commercial: 'Осмотр',
    complex: 'Комплекс',
    contractWork: 'Подрядные работы', // for documents
    contractorAccounting: 'Расчет с подрядчиком', // for documents
    documents_returning: 'Возврат документов и постоплата', // for calendar filters
    empty: 'Комплекс',
    emptyComplex: 'Комплекс',
    emptyGlazing: 'Остекление',
    emptyInspectionAndAccounting: 'Осмотр/Расчет',
    emptyMaintenance: 'Поддержка',
    final_report: 'Финальный отчет', // for calendar filters
    foremanReport: 'Отчет Менеджера по клинингу - бригада',
    glazing: 'Остекление',
    handling: 'Обслуживание',
    inspection: 'Осмотр',
    maintenance: 'Поддержка',
    orderClosing: 'Завершение заявки',
    orderManagerReport: 'Отчет менеджера исполнения',
    primary: 'Заявка',
    primaryInformation: 'Первичная информация',
    quality_control: 'Контроль качества',
    re_cleaning: 'Переделка',
    report: 'Отчеты',
    returningDocuments: 'Возвращение документов',
    starting: 'Запуск уборки',
    toolsReport: 'Отчет Менеджера по клинингу - инвентарь',

    photo: 'Личное фото',
    passportPhoto: 'Паспорт',
    snilsPhoto: 'Снилс',
    medicalBookPhoto: 'Медицинские документы',
    permitPhoto: '',
    patentPhoto: 'Разрешение на работу',
    otherDocuments: 'Другие документы',
}

export const study = {
    start: 'Создание заявки',
    confirmManager: 'Взять заявку',
    choosingDateInspect: 'Выбор даты осмотра',
    fillingKPR: 'Заполнение КПР',
    fillingSmeta: 'Заполнение Сметы',
    fillingKP: 'Заполнение КП',
    fillingDogovor: 'Заполнение договора',
    signingDogovor: 'Подписание договора',
    choosingForeman: 'Выбор ответственного за уборку',
    gatheringTeam: 'Сбор команды',
    tools: 'Указание инвентаря',
    choosingCleaningDate: 'Выбор даты уборки',
    cleaningCompletion: 'Завершение уборки',
    expanses: 'Указание расходов',
    qualityControl: 'Контроль качества',
    eventCompletion: 'Закрытие заявки',
    reCleaning: 'Повторная уборка',
}

export const roleNames = {
    CHIEF: 'Начальник',
    OPERATOR: 'Менеджер продаж',
    ORDER_MANAGER: 'Руководитель',
    FOREMAN: 'Менеджер по клинингу',
    TOOLS_MANAGER: 'Менеджер АХО',
    HR_MANAGER: 'Рекрутер',
    OFFICE_MANAGER: 'Офис менеджер',
}
export type RoleNamesType = typeof roleNames

export const toolsTypes: Record<ToolGroupEnum, string> = {
    consumables: 'Расходники',
    chemistry: 'Химия',
    inventory: 'Инвентарь',
    overall: 'Спец.одежда/СИЗ',
    equipment: 'Оборудование',
    wipings: 'Протирочные материалы',
    appliances: 'Быт. техника',
    components: 'Комплектующие и расходники',
}

export const documentTypes = {
    KPO: 'КПО',
    SMETA: 'Смета',
    KP: 'КП',
    CONTRACT: 'Договор',
    NARYAD_ZAKAZ: 'Наряд-заказ',
}

export const serviceGroups = {
    glazing: 'Мытьё окон',
    bath: 'Санузел',
    rooms: 'Работы, производимые во всех комнатах и помещениях',
    kitchen: 'Кухня',
    balcony: 'Мытьё балконов',
}

export const serviceTypesNames = {
    luxe: 'Генеральная уборка Luxe',
    postConstruction: 'Послестроительная уборка(комплексная)',
    maintenance: 'Поддерживающая уборка',
    glasses: 'Остекление',
    dryCleaning: 'Химчистка',
    rotaryCleaning: 'Роторная чистка',
}

export const serviceTypes = (serviceType: string): keyof typeof serviceTypesNames => {
    return Object.keys(serviceTypesNames).find((key) => {
        return (serviceTypesNames[key as keyof typeof serviceTypesNames] === serviceType)
    }) as keyof typeof serviceTypesNames || 'luxe'
}

export const divisions = [
    {
        value: 'B2B',
        text: 'B2B (ПРР)',
    },
    {
        value: 'B2C',
        text: 'B2C (ПРР)',
    },
]

export const cleaningTypes = [
    {
        value: 'Разовая уборка',
        text: 'Разовая уборка',
    },
    {
        value: 'Абонемент',
        text: 'Абонемент',
    },
    {
        value: 'Рамочная',
        text: 'Рамочная',
    },
    {
        value: 'Отмененная',
        text: 'Отмененная',
    },
]

export const defineDivision = (division: string[]) => {
    if (division.includes('B2B')) {
        return 'B2B'
    }
    if (division.includes('B2C')) {
        return 'B2C'
    }
    return null
}
