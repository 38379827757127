import { klona } from 'klona/full'
import { FieldValues, UseFormClearErrors, UseFormReset } from 'react-hook-form'
import { NavigateFunction } from 'react-router-dom'
import { ButtonSubmitClickHandlerParams } from './orderManager.types'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import { useLazyFetchUserByIdQuery } from '~/API/userApi/user'
import { User } from '~/API/userApi/user.types'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { CHANGE_MENU_TYPE, CHANGE_ORDER_LOADER, setStageName, UPDATE_ORDER } from '~/redux/slices/orderSlice/orderSlice'
import { convertOrderToText } from '~/shared/order/operatorService'
import { handleOrderSubmit } from '~/shared/order/orderDataService'
import { getGlobalDivision } from '~/shared/util/localStorage'

export const constructData = (activeOrder: any, formValues: any) => {
    return klona(formValues)
}

export const resetData = (activeOrder: any) => {
    const serviceTemplate = (getGlobalDivision() === 'B2C' ?
            [
                { name: 'glasses', title: 'Окна', values: [] },
                { name: 'balcony', title: 'Балконы', values: [] },
                { name: 'rooms', title: 'Комнаты', values: [] },
                { name: 'kitchen', title: 'Кухня', values: [] },
                { name: 'bath', title: 'Санузел', values: [] },
            ] :
            [{ name: 'area', title: 'Помещение' }])

    let windows
    let contactInfo = []
    let services = activeOrder.primaryInformation?.services
    if (services) {
        services = klona(activeOrder.primaryInformation.services)
    } else {
        services = serviceTemplate
    }

    let inspectionServices = activeOrder.inspectionAndAccounting?.services
    if (inspectionServices) {
        inspectionServices = klona(activeOrder.inspectionAndAccounting.services)
    } else {
        inspectionServices = serviceTemplate
    }
    if (activeOrder?.primaryInformation?.windows) {
        windows = klona(activeOrder.primaryInformation.windows)
    }
    if (activeOrder.primaryInformation?.contactInfo) {
        contactInfo = klona(activeOrder.primaryInformation?.contactInfo)
    }

    return {
        ...activeOrder,
        primaryInformation: {
            ...activeOrder?.primaryInformation,
            contactInfo: contactInfo || [],
            windows: windows || [],
            balconies: activeOrder.primaryInformation?.balconies || [],
            services,
        },
        inspectionAndAccounting: { ...activeOrder?.inspectionAndAccounting, services: inspectionServices },
        selling: activeOrder?.selling || {},
        starting:
            {
                ...activeOrder?.starting,
                cleaningApprovalInfo: activeOrder?.starting?.cleaningApprovalInfo || [],
            },
        foremanReport: activeOrder.foremanReport ?
                {
                    ...activeOrder.foremanReport,
                    contactInfo: activeOrder.primaryInformation.contactInfo,
                // actualTeam: actualTeam,
                // cleaningDate: activeOrder.selling.cleaningDate,
                // cleaningTask: activeOrder.starting?.nzTask?.cleaningTask,
                } :
            null,
    }
}

export const copyOrderText = (formValues: any, user: User, fetchUserById: ReturnType<typeof useLazyFetchUserByIdQuery>[0]) => {
    // вынести
    const text = convertOrderToText(formValues, user, fetchUserById)
    const textArea = document.createElement('textarea')
    textArea.value = text.join('\n')
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
}

export const resetForm = (
    activeOrder: OrderInterface,
    reset: UseFormReset<OrderInterface>,
    clearErrors: UseFormClearErrors<OrderInterface>,
) => {
    if (!activeOrder) {
        reset()
    } else {
        reset(resetData(activeOrder))
    }
    clearErrors()
}

export const buttonSubmitClickHandler = (params: ButtonSubmitClickHandlerParams) => {
    const {
        isFormValid,
        formValues,
        fakeStage,
        id,
        setWarning,
        dispatch,
        activeOrder,
        user,
        formAction,
        restoreFromArchive,
        updateBitrixDeal,
        fetchOrder,
        saveOrganization,
        updateOrder,
    } = params
    if (isFormValid) {
        if (fakeStage !== formValues.stageName) {
            dispatch(setStageName(formValues.stageName))
        }
        dispatch(CHANGE_ORDER_LOADER(true))
        handleOrderSubmit({
            order: formValues,
            formAction,
            user,
            id,
            updateBitrixDeal,
            fetchOrder,
            paymentType: activeOrder?.accountingAndSelling?.commercialOffer?.paymentType || 'р/с (без НДС)',
        })
            .then((newOrder: any) => {
                if (newOrder?.primaryInformation?.organizationRequisites) {
                    saveOrganization(newOrder?.primaryInformation?.organizationRequisites).unwrap()
                        .catch(() => dispatch(SHOW_NOTICE({
                            type: 'error',
                            message: 'Ошибка при сохранении организации',
                        })))
                }
                switch (newOrder) {
                    case 'FROM_ARCHIVE':
                        restoreFromArchive(formValues.id)
                        dispatch(CHANGE_ORDER_LOADER(false))
                        return
                    case 'INFO_TO_FOREMAN_ERROR':
                        dispatch(SHOW_NOTICE({
                            type: 'error',
                            message: 'Менеджер по клинингу уже дал ответ по выезду на объект',
                        }))
                        dispatch(CHANGE_ORDER_LOADER(false))
                        return
                    case 'EDIT_WARNING_ERROR':
                        dispatch(CHANGE_ORDER_LOADER(false))
                        setWarning(formAction.action as any)
                        return
                    case 'TRANSITION_STAGE_ERROR':
                        dispatch(SHOW_NOTICE({ type: 'error', message: 'Недопустимое действие для данной стадии' }))
                        dispatch(CHANGE_ORDER_LOADER(false))
                        return
                    default:
                        break
                }
                if (!newOrder) {
                    dispatch(SHOW_NOTICE({ type: 'error', message: 'Ошибка сохранения заявки' }))
                    dispatch(CHANGE_ORDER_LOADER(false))
                    return
                }
                if (formAction.isSaveButton) {
                    updateOrder({ ...newOrder, save: true })
                } else {
                    updateOrder(newOrder)
                }
            })
            .catch((error: Error) => {
                console.log(error)
                dispatch(CHANGE_ORDER_LOADER(false))
                dispatch(SHOW_NOTICE({ type: 'error', message: 'Ошибка выполнения этапа\n' + error }))
            })
            .finally(() => {
                dispatch(changeFormAction({ action: 'NONE' }))
            })
    } else {
        dispatch(SHOW_NOTICE({ type: 'error', message: 'Заполнены не все поля' }))
    }
}
export const onError = (error: any, e: any, dispatch: any) => {
    dispatch(SHOW_NOTICE({ type: 'error', message: 'Заполнены не все поля' }))
}

export const parseJson = (obj: any) => {
    return JSON.parse(JSON.stringify(obj))
}

export const handleNavbarClick = (value: string | number, dispatch: any, navigate: NavigateFunction, id: string, redirect?: boolean) => {
    if (typeof value === 'string') {
        dispatch(CHANGE_MENU_TYPE(value))

        switch (value) {
            case 'MENU_REPORT':
                navigate(`/report/${id}`, {
                    state: {
                        skipBlocker: true,
                    },
                })
                return
            case 'MENU_MAIN':
                // case 'MENU_DOCUMENTS':
                navigate(`/order/${id}`, {
                    state: {
                        redirect: !!redirect,
                        skipBlocker: true,
                    },
                })
                return
        }
    }
}
