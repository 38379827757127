import { FC, ReactElement, memo, useMemo } from 'react'
import withAdditionalServices from '~/components/common/ReusingComponents/TabsTable/hocs/withAdditionalServices'
import withForemanReportChecklist
    from '~/components/common/ReusingComponents/TabsTable/hocs/withForemanReportChecklist/withForemanReportChecklist'
import withPersonnel from '~/components/common/ReusingComponents/TabsTable/hocs/withPersonnel/withPersonnel'
import withToolsReport from '~/components/common/ReusingComponents/TabsTable/hocs/withToolsReport'
import TabsTable, { TabsTableProps } from '~/components/common/ReusingComponents/TabsTable/TabsTable'

enum TabsTableHocType {
    TOOLS_REPORT = 'toolsReport',
    ADDITIONAL_SERVICES = 'additionalServices',
    PERSONNEL = 'personnel',
    FOREMAN_CHECK_LIST = 'foremanCheckList'
}

type InputParams = TabsTableProps & {
    hocType?: TabsTableHocType;
};

const TabsTableWrapper = (inputParams: InputParams): ReactElement => {
    const { hocType } = inputParams

    const WrappedComponent = useMemo(() => {
        let Component: FC<TabsTableProps> = TabsTable

        switch (hocType) {
            case TabsTableHocType.TOOLS_REPORT:
                Component = withToolsReport(Component)
                break
            case TabsTableHocType.ADDITIONAL_SERVICES:
                Component = withAdditionalServices(Component)
                break
            case TabsTableHocType.FOREMAN_CHECK_LIST:
                Component = withForemanReportChecklist(Component)
                break
            case TabsTableHocType.PERSONNEL:
                Component = withPersonnel(Component)
                break
            default:
        }

        return memo(Component)
    }, [hocType])


    return <WrappedComponent {...inputParams} key={inputParams.name} />
}

export default TabsTableWrapper


