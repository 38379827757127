import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Notice, NotificationSliceInitialState } from './notificationSlice.types'

const notificationSliceInitialState: NotificationSliceInitialState = {
    notification: [
        {
            id: 1,
            eventId: '24',
            title: 'Заполнить расходы по заявке',
            type: 'reports',
            text: 'Карина Дмитриевна, +7(999)0001488',
        },
        {
            id: 2,
            eventId: '104',
            title: 'Провести осмотр квартиры',
            type: 'inspectionAndAccounting',
            text: 'Клара, +7(999)1482381',
        },
        {
            id: 3,
            eventId: '24',
            title: 'Убраться',
            type: 'cleaning',
            text: 'Карина Дмитриевна, +7(999)0001488',
        },
        {
            id: 4,
            eventId: '104',
            title: 'Заполнить бумажки',
            type: 'selling',
            text: 'Клара, +7(999)1482381',
        },
    ],
    notice: null,
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: notificationSliceInitialState,
    reducers: {
        changeNotification: (state: NotificationSliceInitialState, action) => {
            state.notification = action.payload
        },
        deleteNotification: (state: NotificationSliceInitialState, action) => {
            state.notification = [...state.notification.filter((notification) => notification.id!==action.payload)]
        },
        SHOW_NOTICE: (state: NotificationSliceInitialState, action: PayloadAction<Notice>) =>{
            state.notice = action.payload
        },
    },
})

export const {
    changeNotification,
    deleteNotification,
    SHOW_NOTICE,
} = notificationSlice.actions


export default notificationSlice.reducer
