import React from 'react'
import './TileDatePicker.scss'
import clsx from 'clsx'

interface TileDatePickerProps {
    date: Date,
    className: string,
    tooltip: string | null,
}

const TileDatePicker = (props: TileDatePickerProps) => {
    const {
        date,
        className,
        tooltip,
    } = props
    return (
        <div className={clsx('tileDatePicker', className)} data-tooltip={tooltip}>
            <span>{date.getDate()}</span>
        </div>
    )
}

export default TileDatePicker
