import { rules } from '~/shared/order/rules'
import { StageManager } from '~/shared/order/StageManager'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { managerOrderClose } from '~/shared/templates/inputTemplates/managerOrderClose'
import { additionalInputs } from '~/shared/templates/inputTemplates/v2/additionalInputs'

export const DEFECTS_CORRECTING_ACTIONS = {
    mainActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'QUALITY_CONTROLLING',
                text: 'Отправить на контроль качества',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
}

interface DEFECTS_CORRECTING_PARAMS {
    businessType: string,
    isLastSection: boolean,
    sectionNumber: number,
    reportDate: Date,
    stages: string[],
    currentStage: string,
    user: any,
    archived: boolean,
}

export const DEFECTS_CORRECTING = (params: DEFECTS_CORRECTING_PARAMS) => {
    const {
        businessType,
        isLastSection,
        sectionNumber,
        reportDate,
        stages,
        currentStage,
        user,
        archived,
    } = params
    const newInputs = []
    const documentInputs = []
    if (rules[user?.role]?.sections.orderManagerOrderClosing) {
        const availableAction = StageManager.checkAvailableStageByRole(currentStage, user.role)
        const disabled = !isLastSection || stages.includes('TO_CHIEF_REPORT')

        if (reportDate) {
            newInputs.push([
                additionalInputs.division(true),
                additionalInputs.responsibleUserId(true, `orderClosing.${sectionNumber}.division`, 'responsibleManager'),
                additionalInputs.reportDate(true),
            ])
        } else {
            newInputs.push([
                additionalInputs.division(true),
            ])
        }
        if (availableAction || (user.role === 'ORDER_MANAGER' && user.division.includes(businessType))) {
            newInputs.push([managerOrderClose.additionalExpanses(disabled)])
            newInputs.push([managerOrderClose.orderManagerComment(disabled)])
            // newInputs.push([additionalInputs.mistakesFiles(disabled, 'documents.orderClosing.' + sectionNumber + '.mistakesFiles')])

            newInputs.push([managerOrderClose.mistakesFiles(disabled)])
            documentInputs.push(managerOrderClose.mistakesFiles(disabled))
        }

        if (availableAction && isLastSection) {
            const action = fetchFormAction({
                archived,
                section: 'ORDER_MANAGER_ORDER_CLOSING',
                user,
                currentStage,
            })
            if (action) {
                newInputs.push([action])
            }
        }
    }
    return {
        title: 'Исправление недочетов',
        name: 'orderClosing.' + sectionNumber,
        documentInputs,
        inputs: [...newInputs],
    }
}
