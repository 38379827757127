import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import './MobileOrderDocuments.scss'
import { MobileDocumentGroup } from '../MobileDocumentGroup/MobileDocumentGroup'
import { MobileSearch } from '../MobileSearch/MobileSearch'
import { DocumentTool } from '~/API/orderApi/types/documents.type'
import { OrderInterface } from '~/API/orderApi/types/order.types'

interface MobileOrderDocumentsProps {
    documents: any
    currentStage: string
    setValue: UseFormSetValue<OrderInterface>
    getValues: UseFormGetValues<OrderInterface>
}

interface SortedDocumentsType {
    mainBlock: any
}

const MobileOrderDocuments = (props: MobileOrderDocumentsProps) => {
    const {
        documents,
        currentStage,
        setValue,
        getValues,
    } = props
    const [sortedDocuments, setSortedDocuments] = useState<SortedDocumentsType>({ mainBlock: [] })
    const [selectedSection, setSelectedSection] = useState<string>('')
    const [searchText, setSearchText] = useState<string>('')

    const handleSelectSection = useCallback((item: string) => {
        setSelectedSection(item)
    }, [])

    const handleSetSearch = useCallback((value: string) => {
        setSearchText(value)
    }, [])

    const buildDocsForArraySection = (sectionName: string, files: any[]) => {
        if (!files) return []
        return files.flatMap((file, i) =>
            file && Object.entries(file).reduce((accum: any[], [name, ids]: [name: any, ids: any]) => {
                if (!ids) return accum

                if (Array.isArray(ids)) {
                    if (ids.length === 0) return accum
                    return [
                        ...accum,
                        ...ids.map((e) => ({
                            id: e,
                            name: 'documents.' + sectionName + '.' + i + '.' + name,
                            inputName: name,
                        })),
                    ]
                } else if (typeof ids === 'string') {
                    return [
                        ...accum,
                        {
                            name: 'documents.' + sectionName + '.' + i + '.' + name,
                            id: ids,
                            inputName: name,
                        },
                    ]
                } else {
                    const inputs = Object.entries(ids).flatMap(([inputName, files]) => {
                        return (files as DocumentTool[]).map((file) => ({
                            id: file.fileId,
                            inputName,
                            name: 'documents.' + sectionName + '.' + i + '.' + name,
                        }))
                    })

                    return [
                        ...accum,
                        ...inputs,
                    ]
                }
            }, []),
        )
    }

    const buildDocsForSimpleSection = (sectionName: string, files: any) => {
        if (!files) return []
        return Object.entries(files).reduce((sectionFiles: any, [name, ids]) => {
            if (!ids) return sectionFiles

            if (Array.isArray(ids)) {
                if (ids.length === 0) return sectionFiles
                return [
                    ...sectionFiles,
                    ...ids.map((e) => {
                        return ({
                            id: e,
                            name: 'documents.' + sectionName + '.' + name,
                            inputName: name,
                        })
                    }),
                ]
            } else {
                return [
                    ...sectionFiles,
                    {
                        name: 'documents.' + sectionName + '.' + name,
                        id: ids,
                        inputName: name,
                    },
                ]
            }
        }, [])
    }

    const buildDocsForActualAmortization = (sectionName: string, files: any) => {
        const result: any[] = []

        for (const key in files) {
            if (Array.isArray(files[key])) {
                files[key].forEach((item: any, index: number) => {
                    if (item) {
                        result.push({
                            name: `documents.${sectionName}.${key}.${index}`,
                            id: item.fileId,
                            inputName: 'actualAmortization',
                        })
                    }
                })
            }
        }

        return result
    }

    const formSections = useMemo(() => {
        const sections: string[] = []
        Object.entries(sortedDocuments.mainBlock).map(([section, _]) => sections.push(section))
        return sections
    }, [sortedDocuments])

    const formAllFiles = useMemo(() => {
        let files: any[] = []
        Object.entries(sortedDocuments.mainBlock).map(([_, file]) => files = files.concat(file))
        return files
    }, [sortedDocuments])

    useEffect(() => {
        if (documents) {
            let mainBlock = {}
            Object.entries(documents).forEach(([sectionName, files]: [sectionName: string, files: any]) => {
                if (!files || !sectionName) return
                if (sectionName === 'foremanReport') {
                    mainBlock = {
                        ...mainBlock,
                        foremanReport: [
                            ...buildDocsForSimpleSection('foremanReport', { ...files, daysReport: null }),
                            ...buildDocsForArraySection('foremanReport.daysReport', files.daysReport),
                        ],
                    }
                    return
                }

                if (sectionName === 'toolsReport') {
                    const { actualAmortization, ...restFiles } = files
                    mainBlock = {
                        ...mainBlock,
                        toolsReport: [
                            ...buildDocsForSimpleSection('toolsReport', { ...restFiles, daysReport: null }),
                            ...buildDocsForActualAmortization('toolsReport.actualAmortization', actualAmortization),
                        ],
                    }
                    return
                }

                const newIds = buildDocsForSimpleSection(sectionName, files)
                if (newIds.length !== 0) {
                    mainBlock = {
                        ...mainBlock,
                        [sectionName]: newIds,
                    }
                }

                if (sectionName === 'orderClosing') {
                    mainBlock = {
                        ...mainBlock,
                        [sectionName]: buildDocsForArraySection(sectionName, files)
                            .filter((element: any) => element !== null),
                    }
                }
            })
            setSortedDocuments({ mainBlock })
        }
    }, [documents])


    return (
        <div className='mobile-order-documents'>
            <MobileSearch
                sections={formSections}
                handleSelectSection={handleSelectSection}
                handleSetSearch={handleSetSearch}
            />
            {sortedDocuments && selectedSection !== '' ? (
                Object.entries(sortedDocuments.mainBlock).map(([section, files]) => {
                    if (section === selectedSection) {
                        return (
                            <MobileDocumentGroup
                                searchText={searchText}
                                name={section}
                                files={files}
                                getValues={getValues}
                                setValue={setValue}
                                currentStage={currentStage}
                                key={'dg' + section}
                            />
                        )
                    }
                })
            ) : (
                <MobileDocumentGroup
                    searchText={searchText}
                    name={'all_files'}
                    files={formAllFiles}
                    getValues={getValues}
                    setValue={setValue}
                    currentStage={currentStage}
                    key={'dg-all_files'}
                />
            )}
        </div>
    )
}

export default MobileOrderDocuments
