import clsx from 'clsx'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FaEye, FaThumbtack } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useUploadFileMutation } from '~/API/fileApi/file'
import { useCustomDisabled } from '~/components/common/ReusingComponents/TabsTable/hooks/useCustomDisabled'
import {
    SectionDocument,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTablePage/subComponents/TabsTableCell/subComponents/TabsTableFileInput/TabsTableFileInput'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { OPEN_LIGHTBOX } from '~/redux/slices/appSlice/appSlice'
import { useAppDispatch } from '~/redux/store'
import { fetchDate } from '~/shared/util/currentTime'
import './EditModalFileInput.scss'

interface EditModalFileInputProps {
    name: string
    title: string
    onChange: () => void
    rowIndex: number,
    rowName: string
    disabled: boolean
    sectionName?: string
    customDisabledType?: string
    usingData?: string
    sectionTitle?: string
    isError?: boolean
}

const EditModalFileInput = (props: EditModalFileInputProps) => {
    const {
        name,
        title,
        onChange,
        rowIndex,
        disabled,
        customDisabledType,
        usingData,
        rowName,
        sectionName,
        sectionTitle,
        isError,
    } = props

    const dispatch = useAppDispatch()
    const user = useSelector(selectUser)

    const [fileId, setFileId] = useState<string>()
    const fileInputRef = useRef<HTMLInputElement>(null)
    const {
        setValue,
        getValues,
    } = useFormContext()

    const [uploadFile, { isLoading }] = useUploadFileMutation()

    const customDisabled = useCustomDisabled({
        customDisabledType,
        rowName,
    })

    const fileTitle = useMemo(() => {
        const item = getValues(rowName)

        // personnel report actualTeam table
        if (rowName.includes('actualTeam')) {
            return `СЛУЖЕБНАЯ ЗАПИСКА - ${item?.name}`
        }

        return item?.name ? `${sectionTitle} - ${item.name}` : 'Файл'
    }, [rowName, sectionTitle])

    const valuesName = sectionName ? `${usingData}.${sectionName}` : `${usingData}.${name}`

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!usingData) return

        const file = event.target.files?.[0]
        if (file) {
            try {
                const fileModel = {
                    fileInfo: {
                        fileName: file.name || '',
                        title: fileTitle,
                        inputName: name,
                        creator: user?.id || '',
                        fileType: file.type,
                    },
                    createdAt: fetchDate().toDate(),
                }

                const response = await uploadFile({
                    file,
                    fileModel,
                }).unwrap()

                const newFileId = response.fileId

                if (newFileId) {
                    const sectionDocuments: SectionDocument[] = getValues(valuesName) ?? []
                    let updatedSectionDocuments: SectionDocument[]

                    if (fileId) {
                        updatedSectionDocuments = sectionDocuments.map((item) => {
                            if (item.index === rowIndex) {
                                return {
                                    ...item,
                                    fileId: newFileId,
                                }
                            }
                            return item
                        })
                    } else {
                        updatedSectionDocuments = [...sectionDocuments, { index: rowIndex, fileId: newFileId }]
                    }

                    setValue(valuesName, updatedSectionDocuments)
                    setFileId(newFileId)
                    onChange()
                }
            } catch (error) {
                console.error('Ошибка загрузки файла:', error)
            }
        }
    }

    const handleIconClick = () => {
        if (fileId) {
            dispatch(
                OPEN_LIGHTBOX({
                    sources: [
                        fileId,
                    ],
                    sourceIndex: 0,
                }),
            )
        } else {
            fileInputRef.current?.click()
        }
    }

    useEffect(() => {
        const sectionDocuments: SectionDocument[] = getValues(valuesName) || []
        const existingFile = sectionDocuments.find((item) => item?.index === rowIndex)

        if (existingFile) {
            setFileId(existingFile.fileId)
        }
    }, [])

    return (
        <div className='editModalFileInput'>
            <button
                type='button'
                className={clsx('editModalFileInput__button', {
                    'editModalFileInput__button--disabled': !fileId ? (disabled || customDisabled) : false,
                    'editModalFileInput__button--error': isError,
                })}
                onClick={handleIconClick}
                disabled={(!fileId ? (disabled || customDisabled) : false) || isLoading}
            >
                {fileId ? <FaEye size={16} /> : <FaThumbtack size={16} />}
                {title}
            </button>
            <input
                type='file'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </div>
    )
}

export default EditModalFileInput
