export const makeAdditionalData = ({format, ...values}: any, domHtml: Document) => {
    if (format === 'docx') {
        if (values.paymentType === 'р/с (НДС)') {
            if (values.prepaymentType !== 'Внесена') {
                domHtml.querySelector('#ACCEPTANCE')!.innerHTML = 'принять'
                domHtml.querySelector('#CLAIMS')!.innerHTML = 'письменные претензии'
                domHtml.querySelector('#PROCEDURE_FOR_EXECUTION_OF_THE_AGREEMENT')!.innerHTML = `
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;border:none;padding:0cm'>
                    <b>
                        <span style='font-size:10.0pt;font-family:"Times New Roman",serif;
        color:black;'>
                            3.4
                            <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <span style='font-size:10.0pt;font-family:"Times New Roman",serif;
    color:black;'>
                        Сдача-приемка оказанных Услуг производится по универсальному
                        передаточному документу оказанных услуг (Далее-УПД). УПД подписывается уполномоченными на то
                        представителями Сторон с указанием должности и расшифровкой подписи, с указанием даты подписания, а
                        также предоставлением копии доверенности при необходимости.
                    </span>
                </p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Исполнитель, до 1 (Первого) числа месяца, следующего за
                    месяцем оказания услуг, передает Заказчику подписанный со своей стороны УПД и приложенные к нему
                    документы (при наличии) лично, нарочно (курьером) или посредством почтовых служб или транспортных
                    компаний таких как («Почта России», «DHL», и.т.д.), в соответствии с описью в которой указывается
                    перечень передаваемых Заказчику документов. При этом датой получения указанных документов является дата,
                    указанная в уведомлении о получении, квитанции о получении или ином документе, подтверждающим получение
                    документов.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Заказчик подписывает полученный УПД в течение 3 (трех) рабочих
                    дней после получения и направляет один экземпляр Исполнителю (при отсутствии замечаний к качеству
                    выполненных Услуг), либо направляет Исполнителю письменный мотивированный отказ, в котором указывает,
                    объём не оказанных услуг или недостатков и других нарушений, ухудшающих качество оказанной Услуги, и
                    сроков их устранения.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Невозвращение УПД в установленный срок п.3.6. Заказчиком
                    Исполнителю (при отсутствии мотивированных возражений), свидетельствует о том, что оказанные услуги
                    приняты, Заказчиком, в полном объёме и надлежащем качестве.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>В случае получения от Заказчика письменного мотивированного
                    отказа от подписания УПД, с причинами отказа, Исполнитель в течение 5 (пяти) рабочих дней с момента
                    получения отказа, обязуется устранить выявленные замечания, внести соответствующие изменения в УПД и
                    приложенные к нему документы, и повторно направить их для подписания Заказчиком.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>УПД после подписания его полномочными представителями Сторон
                    является основанием для расчетов между ними. В случае невозвращения Заказчиком УПД в установленный срок
                    Исполнителю (при отсутствии мотивированных возражений) Заказчик обязан оплатить Услуги в срок,
                    установленный п. 4.2. Договора.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Ответственное лицо осуществляет контроль за оказанием Услуг
                    на Объекте, осуществляет соответствующее информирование другой стороны договора о ходе оказания Услуг в
                    порядке, предусмотренном Договором, оформляет и подписывает УПД, осуществляет иные полномочия,
                    предусмотренные Договором.
                </span></p>
            `
                domHtml.querySelector('#COST_AND_PAYMENT_PROCEDURE_FOR_SERVICES')!.innerHTML = `
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Оплата Услуг, производится ежемесячно до 5(пятого) числа следующего за отсчётным месяца, по факту подписания УПД, в течение 3 (трех) календарных дней.
</span></p>
            `
            } else {
                domHtml.querySelector('#PROCEDURE_FOR_EXECUTION_OF_THE_AGREEMENT')!.innerHTML = `
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Сдача-приемка оказанных Услуг производится по универсальному
                    передаточному документу оказанных услуг (Далее-УПД). УПД подписывается уполномоченными на то
                    представителями Сторон с указанием должности и расшифровкой подписи, с указанием даты подписания, а
                    также предоставлением копии доверенности при необходимости.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Исполнитель, до 1 (Первого) числа месяца, следующего за
                    месяцем оказания услуг, передает Заказчику подписанный со своей стороны УПД и приложенные к нему
                    документы (при наличии) лично, нарочно (курьером) или посредством почтовых служб или транспортных
                    компаний таких как («Почта России», «DHL», и.т.д.), в соответствии с описью в которой указывается
                    перечень передаваемых Заказчику документов. При этом датой получения указанных документов является дата,
                    указанная в уведомлении о получении, квитанции о получении или ином документе, подтверждающим получение
                    документов.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Заказчик подписывает полученный УПД в течение 3 (трех)
                    рабочих дней и направляет один экземпляр Исполнителю (при отсутствии замечаний к качеству выполненных
                    Услуг), либо направляет Исполнителю письменный мотивированный отказ, в котором указывает, объём не
                    оказанных услуг или недостатков и других нарушений, ухудшивших качество оказанной Услуги, и сроков их
                    устранения.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Невозвращение УПД в установленный срок п.3.6. Заказчиком
                    Исполнителю (при отсутствии мотивированных возражений), свидетельствует о том, что оказанные услуги
                    приняты, Заказчиком, в полном объёме и надлежавшем качестве.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>В случае получения от Заказчика письменного мотивированного
                    отказа от подписания УПД, с причинами отказа, Исполнитель в течение 5 (пяти) рабочих дней с момента
                    получения отказа, обязуется устранить выявленные замечания, внести соответствующие изменения в УПД и
                    приложенные к нему документы, и повторно направить их для подписания Заказчиком.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>УПД после подписания его полномочными представителями Сторон
                    является основанием для расчетов между ними. В случае невозвращения Заказчиком УПД в установленный срок
                    Исполнителю (при отсутствии мотивированных возражений) Заказчик обязан оплатить Услуги в срок,
                    установленный п. 4.2. Договора.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Ответственное лицо осуществляет контроль за оказанием Услуг
                    на Объекте, осуществляет соответствующее информирование другой стороны договора о ходе оказания Услуг в
                    порядке, предусмотренном Договором, оформляет и подписывает УПД, осуществляет иные полномочия,
                    предусмотренные Договором.
                </span></p>
            `
                domHtml.querySelector('#COST_AND_PAYMENT_PROCEDURE_FOR_SERVICES')!.innerHTML = `
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Оплата цены Услуг, производится ежемесячно 100% авансовым платежом до 5 (пятого) числа текущего месяца оказания Услуг, по факту подписания Акта, в течение 3 (трех) календарных дней.
</span></p>
            `
            }
        } else {
            if (values.prepaymentType !== 'Внесена') {
                domHtml.querySelector('#PROCEDURE_FOR_EXECUTION_OF_THE_AGREEMENT')!.innerHTML = `
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Сдача-приемка оказанных Услуг производится по Акту
                    сдачи-приемки оказанных услуг (Далее – Акт). Акт подписывается уполномоченными на то представителями
                    Сторон с указанием должности и расшифровкой подписи, с указанием даты подписания, а также
                    предоставлением копии доверенности при необходимости.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Исполнитель, до 1 (Первого) числа месяца, следующего за
                    месяцем оказания услуг, передает Заказчику подписанный со своей стороны Акт и приложенные к нему
                    документы (при наличии) лично, нарочно (курьером) или посредством почтовых служб или транспортных
                    компаний таких как («Почта России», «DHL», и.т.д.), в соответствии с описью в которой указывается
                    перечень передаваемых Заказчику документов. При этом датой получения указанных документов является дата,
                    указанная в уведомлении о получении, квитанции о получении или ином документе, подтверждающим получение
                    документов.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Заказчик подписывает полученный Акт в течение 3 (трех)
                    рабочих дней после получения Акта сдачи-приемки работ и направляет один экземпляр Исполнителю (при
                    отсутствии замечаний к качеству выполненных Услуг), либо направляет Исполнителю письменный
                    мотивированный отказ, в котором указывает, объём не оказанных услуг или недостатков и других нарушений,
                    ухудшивших качество оказанной Услуги, и сроков их устранения.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Невозвращение Акта в установленный срок п.3.6. Заказчиком
                    Исполнителю (при отсутствии мотивированных возражений), свидетельствует о том, что оказанные услуги
                    приняты, Заказчиком, в полном объёме и надлежавшем качестве.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>В случае получения от Заказчика письменного
                    мотивированного отказа от подписания Акта, с причинами отказа, Исполнитель в течении 5 (пяти) рабочих
                    дней с момента получения отказа, обязуется устранить выявленные замечания, внести соответствующие
                    изменения в Акт и приложенные к нему документы, и повторно направить их для подписания Заказчиком.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Акт после подписания его полномочными представителями
                    Сторон является основанием для расчетов между ними. В случае невозвращения Заказчиком Акта в
                    установленный срок Исполнителю (при отсутствии мотивированных возражений) Заказчик обязан оплатить
                    Услуги в срок, установленный п. 4.2. Договора.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Ответственное лицо осуществляет контроль за оказанием
                    Услуг на Объекте, осуществляет соответствующее информирование другой стороны договора о ходе оказания
                    Услуг в порядке, предусмотренном Договором, оформляет и подписывает Акты, осуществляет иные полномочия,
                    предусмотренные Договором.
                </span></p>
            `
                domHtml.querySelector('#COST_AND_PAYMENT_PROCEDURE_FOR_SERVICES')!.innerHTML = `
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Оплата Услуг, производится ежемесячно до 5 (пятого) числа следующего
                за отсчётным месяца, по факту подписания Акта, в течение 3 (трех) календарных дней.
                </span></p>
            `
            } else {
                domHtml.querySelector('#DELAY')!.innerHTML = `Исполнитель в праве, приостановить оказание Услуг по
                Договору, в случае просрочки оплаты Заказчиком на срок более чем на 1 (Один) банковский день сверх срока
                указанного в п.4.2. Договора, без уведомления Заказчика, до момента погашения задолженности Заказчиком.`
                domHtml.querySelector('#PROCEDURE_FOR_EXECUTION_OF_THE_AGREEMENT')!.innerHTML = `
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Сдача-приемка оказанных Услуг производится по Акту
                    сдачи-приемки оказанных услуг (Далее – Акт). Акт подписывается уполномоченными на то представителями
                    Сторон с указанием должности и расшифровкой подписи, с указанием даты подписания, а также
                    предоставлением копии доверенности при необходимости.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Исполнитель, до 1 (Первого) числа месяца, следующего за
                    месяцем оказания услуг, передает Заказчику подписанный со своей стороны Акт и приложенные к нему
                    документы (при наличии) лично, нарочно (курьером) или посредством почтовых служб или транспортных
                    компаний таких как («Почта России», «DHL», и.т.д.), в соответствии с описью в которой указывается
                    перечень передаваемых Заказчику документов. При этом датой получения указанных документов является дата,
                    указанная в уведомлении о получении, квитанции о получении или ином документе, подтверждающим получение
                    документов.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Заказчик подписывает полученный Акт в течение 3 (трех)
                    рабочих дней после получения Акта сдачи-приемки работ и направляет один экземпляр Исполнителю (при
                    отсутствии замечаний к качеству выполненных Услуг), либо направляет Исполнителю письменный
                    мотивированный отказ, в котором указывает, объём не оказанных услуг или недостатков и других нарушений,
                    ухудшивших качество оказанной Услуги, и сроков их устранения.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Невозвращение Акта в установленный срок п.3.6. Заказчиком
                    Исполнителю (при отсутствии мотивированных возражений), свидетельствует о том, что оказанные услуги
                    приняты, Заказчиком, в полном объёме и надлежавшем качестве.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>В случае получения от Заказчика письменного
                    мотивированного отказа от подписания Акта, с причинами отказа, Исполнитель в течении 5 (пяти) рабочих
                    дней с момента получения отказа, обязуется устранить выявленные замечания, внести соответствующие
                    изменения в Акт и приложенные к нему документы, и повторно направить их для подписания Заказчиком.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Акт после подписания его полномочными представителями
                    Сторон является основанием для расчетов между ними. В случае невозвращения Заказчиком Акта в
                    установленный срок Исполнителю (при отсутствии мотивированных возражений) Заказчик обязан оплатить
                    Услуги в срок, установленный п. 4.2. Договора.
                </span></p>
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>3.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Ответственное лицо осуществляет контроль за оказанием
                    Услуг на Объекте, осуществляет соответствующее информирование другой стороны договора о ходе оказания
                    Услуг в порядке, предусмотренном Договором, оформляет и подписывает Акты, осуществляет иные полномочия,
                    предусмотренные Договором.
                </span></p>
            `
                domHtml.querySelector('#COST_AND_PAYMENT_PROCEDURE_FOR_SERVICES')!.innerHTML = `
                <p style='margin-top:2.0pt;margin-right:0cm;margin-bottom:0cm;
margin-left:0cm;margin-bottom:.0001pt;text-align:justify;text-indent:0cm;
border:none;padding:0cm'><b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:black;'>Оказываемые в рамках настоящего Договора услуги, Заказчик оплачивает ежемесячно 100%
                авансовым платежом до 3(третьего) числа текущего месяца оказания услуг при соблюдении Исполнителем пункта 3.5. В случае просрочки
                платежа более чем на 1 (Один) календарный день со стороны Заказчика, Исполнитель имеет право в одностороннем порядке приостановить
                оказание услуг по Договору, без уведомления Заказчика, до момента погашения задолженности Заказчиком.
</span></p>
            `
            }
        }
    } else {
        if (values.paymentType === 'р/с (НДС)') {
            if (values.prepaymentType !== 'Внесена') {
                domHtml.querySelector('#ACCEPTANCE')!.innerHTML = 'принять'
                domHtml.querySelector('#CLAIMS')!.innerHTML = 'письменные претензии'
                domHtml.querySelector('#PROCEDURE_FOR_EXECUTION_OF_THE_AGREEMENT')!.innerHTML = `
                <li><span class='listNumber'><b>3.4</b></span>Сдача-приемка оказанных Услуг производится по универсальному
                    передаточному документу оказанных услуг (Далее-УПД). УПД подписывается уполномоченными на то
                    представителями Сторон с указанием должности и расшифровкой подписи, с указанием даты подписания, а
                    также предоставлением копии доверенности при необходимости.
                </li>
                <li><span class='listNumber'><b>3.5</b></span>Исполнитель, до 1 (Первого) числа месяца, следующего за
                    месяцем оказания услуг, передает Заказчику подписанный со своей стороны УПД и приложенные к нему
                    документы (при наличии) лично, нарочно (курьером) или посредством почтовых служб или транспортных
                    компаний таких как («Почта России», «DHL», и.т.д.), в соответствии с описью в которой указывается
                    перечень передаваемых Заказчику документов. При этом датой получения указанных документов является дата,
                    указанная в уведомлении о получении, квитанции о получении или ином документе, подтверждающим получение
                    документов.
                </li>
                <li><span class='listNumber'><b>3.6</b></span>Заказчик подписывает полученный УПД в течение 3 (трех) рабочих
                    дней после получения и направляет один экземпляр Исполнителю (при отсутствии замечаний к качеству
                    выполненных Услуг), либо направляет Исполнителю письменный мотивированный отказ, в котором указывает,
                    объём не оказанных услуг или недостатков и других нарушений, ухудшающих качество оказанной Услуги, и
                    сроков их устранения.
                </li>
                <li><span class='listNumber'><b>3.7</b></span>Невозвращение УПД в установленный срок п.3.6. Заказчиком
                    Исполнителю (при отсутствии мотивированных возражений), свидетельствует о том, что оказанные услуги
                    приняты, Заказчиком, в полном объёме и надлежащем качестве.
                </li>
                <li><span class='listNumber'><b>3.8</b></span>В случае получения от Заказчика письменного мотивированного
                    отказа от подписания УПД, с причинами отказа, Исполнитель в течение 5 (пяти) рабочих дней с момента
                    получения отказа, обязуется устранить выявленные замечания, внести соответствующие изменения в УПД и
                    приложенные к нему документы, и повторно направить их для подписания Заказчиком.
                </li>
                <li><span class='listNumber'><b>3.9</b></span>УПД после подписания его полномочными представителями Сторон
                    является основанием для расчетов между ними. В случае невозвращения Заказчиком УПД в установленный срок
                    Исполнителю (при отсутствии мотивированных возражений) Заказчик обязан оплатить Услуги в срок,
                    установленный п. 4.2. Договора.
                </li>
                <li><span class='listNumber'><b>3.10</b></span>Ответственное лицо осуществляет контроль за оказанием Услуг
                    на Объекте, осуществляет соответствующее информирование другой стороны договора о ходе оказания Услуг в
                    порядке, предусмотренном Договором, оформляет и подписывает УПД, осуществляет иные полномочия,
                    предусмотренные Договором.
                </li>
            `
                domHtml.querySelector('#COST_AND_PAYMENT_PROCEDURE_FOR_SERVICES')!.innerHTML = `
                <span class='listNumber'><b>4.2</b></span>Оплата Услуг, производится ежемесячно до 5(пятого) числа следующего за отсчётным месяца, по факту подписания УПД, в течение 3 (трех) календарных дней.
            `
            } else {
                domHtml.querySelector('#PROCEDURE_FOR_EXECUTION_OF_THE_AGREEMENT')!.innerHTML = `
                <li><span class='listNumber'><b>3.4</b></span>Сдача-приемка оказанных Услуг производится по универсальному
                    передаточному документу оказанных услуг (Далее-УПД). УПД подписывается уполномоченными на то
                    представителями Сторон с указанием должности и расшифровкой подписи, с указанием даты подписания, а
                    также предоставлением копии доверенности при необходимости.
                </li>
                <li><span class='listNumber'><b>3.5</b></span>Исполнитель, до 1 (Первого) числа месяца, следующего за
                    месяцем оказания услуг, передает Заказчику подписанный со своей стороны УПД и приложенные к нему
                    документы (при наличии) лично, нарочно (курьером) или посредством почтовых служб или транспортных
                    компаний таких как («Почта России», «DHL», и.т.д.), в соответствии с описью в которой указывается
                    перечень передаваемых Заказчику документов. При этом датой получения указанных документов является дата,
                    указанная в уведомлении о получении, квитанции о получении или ином документе, подтверждающим получение
                    документов.
                </li>
                <li><span class='listNumber'><b>3.6</b></span>Заказчик подписывает полученный УПД в течение 3 (трех)
                    рабочих дней и направляет один экземпляр Исполнителю (при отсутствии замечаний к качеству выполненных
                    Услуг), либо направляет Исполнителю письменный мотивированный отказ, в котором указывает, объём не
                    оказанных услуг или недостатков и других нарушений, ухудшивших качество оказанной Услуги, и сроков их
                    устранения.
                </li>
                <li><span class='listNumber'><b>3.7</b></span>Невозвращение УПД в установленный срок п.3.6. Заказчиком
                    Исполнителю (при отсутствии мотивированных возражений), свидетельствует о том, что оказанные услуги
                    приняты, Заказчиком, в полном объёме и надлежавшем качестве.
                </li>
                <li><span class='listNumber'><b>3.8</b></span>В случае получения от Заказчика письменного мотивированного
                    отказа от подписания УПД, с причинами отказа, Исполнитель в течение 5 (пяти) рабочих дней с момента
                    получения отказа, обязуется устранить выявленные замечания, внести соответствующие изменения в УПД и
                    приложенные к нему документы, и повторно направить их для подписания Заказчиком.
                </li>
                <li><span class='listNumber'><b>3.9</b></span>УПД после подписания его полномочными представителями Сторон
                    является основанием для расчетов между ними. В случае невозвращения Заказчиком УПД в установленный срок
                    Исполнителю (при отсутствии мотивированных возражений) Заказчик обязан оплатить Услуги в срок,
                    установленный п. 4.2. Договора.
                </li>
                <li><span class='listNumber'><b>3.10</b></span>Ответственное лицо осуществляет контроль за оказанием Услуг
                    на Объекте, осуществляет соответствующее информирование другой стороны договора о ходе оказания Услуг в
                    порядке, предусмотренном Договором, оформляет и подписывает УПД, осуществляет иные полномочия,
                    предусмотренные Договором.
                </li>
            `
                domHtml.querySelector('#COST_AND_PAYMENT_PROCEDURE_FOR_SERVICES')!.innerHTML = `
                <span class='listNumber'><b>4.2</b></span>Оплата цены Услуг, производится ежемесячно 100% авансовым платежом до 5 (пятого) числа текущего месяца оказания Услуг, по факту подписания Акта, в течение 3 (трех) календарных дней.
            `
            }
        } else {
            if (values.prepaymentType !== 'Внесена') {
                domHtml.querySelector('#PROCEDURE_FOR_EXECUTION_OF_THE_AGREEMENT')!.innerHTML = `
                <li><span class='listNumber'><b>3.4</b></span>Сдача-приемка оказанных Услуг производится по Акту
                    сдачи-приемки оказанных услуг (Далее – Акт). Акт подписывается уполномоченными на то представителями
                    Сторон с указанием должности и расшифровкой подписи, с указанием даты подписания, а также
                    предоставлением копии доверенности при необходимости.
                </li>
                <li><span class='listNumber'><b>3.5</b></span>Исполнитель, до 1 (Первого) числа месяца, следующего за
                    месяцем оказания услуг, передает Заказчику подписанный со своей стороны Акт и приложенные к нему
                    документы (при наличии) лично, нарочно (курьером) или посредством почтовых служб или транспортных
                    компаний таких как («Почта России», «DHL», и.т.д.), в соответствии с описью в которой указывается
                    перечень передаваемых Заказчику документов. При этом датой получения указанных документов является дата,
                    указанная в уведомлении о получении, квитанции о получении или ином документе, подтверждающим получение
                    документов.
                </li>
                <li><span class='listNumber'><b>3.6</b></span>Заказчик подписывает полученный Акт в течение 3 (трех)
                    рабочих дней после получения Акта сдачи-приемки работ и направляет один экземпляр Исполнителю (при
                    отсутствии замечаний к качеству выполненных Услуг), либо направляет Исполнителю письменный
                    мотивированный отказ, в котором указывает, объём не оказанных услуг или недостатков и других нарушений,
                    ухудшивших качество оказанной Услуги, и сроков их устранения.
                </li>
                <li><span class='listNumber'><b>3.7</b></span>Невозвращение Акта в установленный срок п.3.6. Заказчиком
                    Исполнителю (при отсутствии мотивированных возражений), свидетельствует о том, что оказанные услуги
                    приняты, Заказчиком, в полном объёме и надлежавшем качестве.
                </li>
                <li><span class='listNumber'><b>3.8</b></span>В случае получения от Заказчика письменного
                    мотивированного отказа от подписания Акта, с причинами отказа, Исполнитель в течении 5 (пяти) рабочих
                    дней с момента получения отказа, обязуется устранить выявленные замечания, внести соответствующие
                    изменения в Акт и приложенные к нему документы, и повторно направить их для подписания Заказчиком.
                </li>
                <li><span class='listNumber'><b>3.9</b></span>Акт после подписания его полномочными представителями
                    Сторон является основанием для расчетов между ними. В случае невозвращения Заказчиком Акта в
                    установленный срок Исполнителю (при отсутствии мотивированных возражений) Заказчик обязан оплатить
                    Услуги в срок, установленный п. 4.2. Договора.
                </li>
                <li><span class='listNumber'><b>3.10</b></span>Ответственное лицо осуществляет контроль за оказанием
                    Услуг на Объекте, осуществляет соответствующее информирование другой стороны договора о ходе оказания
                    Услуг в порядке, предусмотренном Договором, оформляет и подписывает Акты, осуществляет иные полномочия,
                    предусмотренные Договором.
                </li>
            `
                domHtml.querySelector('#COST_AND_PAYMENT_PROCEDURE_FOR_SERVICES')!.innerHTML = `
                <span class='listNumber'><b>4.2</b></span>Оплата Услуг, производится ежемесячно до 5 (пятого) числа следующего
                за отсчётным месяца, по факту подписания Акта, в течение 3 (трех) календарных дней.
            `
            } else {
                domHtml.querySelector('#DELAY')!.innerHTML = `Исполнитель в праве, приостановить оказание Услуг по
                Договору, в случае просрочки оплаты Заказчиком на срок более чем на 1 (Один) банковский день сверх срока
                указанного в п.4.2. Договора, без уведомления Заказчика, до момента погашения задолженности Заказчиком.`
                domHtml.querySelector('#PROCEDURE_FOR_EXECUTION_OF_THE_AGREEMENT')!.innerHTML = `
                <li><span class='listNumber'><b>3.4</b></span>Сдача-приемка оказанных Услуг производится по Акту
                    сдачи-приемки оказанных услуг (Далее – Акт). Акт подписывается уполномоченными на то представителями
                    Сторон с указанием должности и расшифровкой подписи, с указанием даты подписания, а также
                    предоставлением копии доверенности при необходимости.
                </li>
                <li><span class='listNumber'><b>3.5</b></span>Исполнитель, до 1 (Первого) числа месяца, следующего за
                    месяцем оказания услуг, передает Заказчику подписанный со своей стороны Акт и приложенные к нему
                    документы (при наличии) лично, нарочно (курьером) или посредством почтовых служб или транспортных
                    компаний таких как («Почта России», «DHL», и.т.д.), в соответствии с описью в которой указывается
                    перечень передаваемых Заказчику документов. При этом датой получения указанных документов является дата,
                    указанная в уведомлении о получении, квитанции о получении или ином документе, подтверждающим получение
                    документов.
                </li>
                <li><span class='listNumber'><b>3.6</b></span>Заказчик подписывает полученный Акт в течение 3 (трех)
                    рабочих дней после получения Акта сдачи-приемки работ и направляет один экземпляр Исполнителю (при
                    отсутствии замечаний к качеству выполненных Услуг), либо направляет Исполнителю письменный
                    мотивированный отказ, в котором указывает, объём не оказанных услуг или недостатков и других нарушений,
                    ухудшивших качество оказанной Услуги, и сроков их устранения.
                </li>
                <li><span class='listNumber'><b>3.7</b></span>Невозвращение Акта в установленный срок п.3.6. Заказчиком
                    Исполнителю (при отсутствии мотивированных возражений), свидетельствует о том, что оказанные услуги
                    приняты, Заказчиком, в полном объёме и надлежавшем качестве.
                </li>
                <li><span class='listNumber'><b>3.8</b></span>В случае получения от Заказчика письменного
                    мотивированного отказа от подписания Акта, с причинами отказа, Исполнитель в течении 5 (пяти) рабочих
                    дней с момента получения отказа, обязуется устранить выявленные замечания, внести соответствующие
                    изменения в Акт и приложенные к нему документы, и повторно направить их для подписания Заказчиком.
                </li>
                <li><span class='listNumber'><b>3.9</b></span>Акт после подписания его полномочными представителями
                    Сторон является основанием для расчетов между ними. В случае невозвращения Заказчиком Акта в
                    установленный срок Исполнителю (при отсутствии мотивированных возражений) Заказчик обязан оплатить
                    Услуги в срок, установленный п. 4.2. Договора.
                </li>
                <li><span class='listNumber'><b>3.10</b></span>Ответственное лицо осуществляет контроль за оказанием
                    Услуг на Объекте, осуществляет соответствующее информирование другой стороны договора о ходе оказания
                    Услуг в порядке, предусмотренном Договором, оформляет и подписывает Акты, осуществляет иные полномочия,
                    предусмотренные Договором.
                </li>
            `
                domHtml.querySelector('#COST_AND_PAYMENT_PROCEDURE_FOR_SERVICES')!.innerHTML = `
                <span class='listNumber'><b>4.2</b></span>Оказываемые в рамках настоящего Договора услуги, Заказчик оплачивает ежемесячно 100%
                авансовым платежом до 3(третьего) числа текущего месяца оказания услуг при соблюдении Исполнителем пункта 3.5. В случае просрочки
                платежа более чем на 1 (Один) календарный день со стороны Заказчика, Исполнитель имеет право в одностороннем порядке приостановить
                оказание услуг по Договору, без уведомления Заказчика, до момента погашения задолженности Заказчиком.

            `
            }
        }
    }

    return domHtml
}
