export enum ToolGroupEnum {
    CHEMISTRY = 'chemistry',
    CONSUMABLES = 'consumables',
    WIPINGS = 'wipings',
    INVENTORY = 'inventory',
    OVERALL = 'overall',
    COMPONENTS = 'components',
    EQUIPMENT = 'equipment',
    APPLIANCES = 'appliances',
}

export interface FetchToolsParams {
    buildingType: string
    serviceType?: string
}

export interface FetchToolsTemplateParams {
    buildingType: string
    serviceType?: string
    cleanerNumber: number
}

export type FetchToolsApiResponse = ToolGroup[]
export type FetchToolsTransformedResponse = Record<ToolGroupEnum, ToolSelectItem[]>
export type FetchToolsTemplateApiResponse = ServiceTypeToolsTemplate[]

interface ToolGroup {
    name: ToolGroupEnum
    title: string
    items: Tool[]
}

interface ServiceTypeToolsTemplate {
    name: string
    title: string
    groups: ToolTemplateGroup[]
}

interface ToolTemplateGroup {
    name: ToolGroupEnum
    items: ToolTemplate[]
}

export interface Tool {
    id: string
    measure: string
    name: string
    unitPrice: number
}

export interface ToolSelectItem extends Tool {
    value: string
    text: string
}

export interface ToolTemplate extends Tool {
    amount: number
    amortization: number | null
}
