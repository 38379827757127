import { RootState } from '../store'

export const selectSalaryMode = (state: RootState) => state.salary.salaryMode
export const selectSalaryWorkers = (state: RootState) => state.salary.salaryWorkers
export const selectSalaryReport = (state: RootState) => state.salary.salaryReport
export const selectSalaryTableInfo = (state: RootState) => state.salary.salaryTableInfo
export const selectSalaryPayment = (state: RootState) => state.salary.salaryPayment
export const selectSalaryActiveDateRange = (state: RootState) => state.salary.salaryActiveDateRange
export const selectSalarySinglePayment = (state: RootState) => state.salary.salarySinglePayment
export const selectSalaryFiles = (state: RootState) => state.salary.salaryFiles
export const selectPersonnelToIssued = (state: RootState) => state.salary.personnelToIssued
export const selectSalaryLoader = (state: RootState) => state.salary.salaryLoader
export const selectSalaryError = (state: RootState) => state.salary.salaryError
export const selectSalaryJobPosition = (state: RootState) => state.salary.salaryJobPosition
