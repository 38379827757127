import { Controller, useFormContext } from 'react-hook-form'
import CustomInput from '~/components/common/inputs/CustomInput/CustomInput'
import { OnSubscriptionFieldChange } from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import MobileCustomSelect from '~/components/mobile/mobileCommon/mobileInputs/MobileCustomSelect/MobileCustomSelect'
import EditModalFileInput
    from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileEditModal/subComponents/EditModalFileInput/EditModalFileInput'

interface EditModalItemProps {
    input: any
    inputName: string
    disabled: boolean
    sectionName?: string
    rowIndex: number
    usingData?: string
    sectionTitle?: string
    onSubscriptionFieldChange?: OnSubscriptionFieldChange
}

const EditModalItem = (props: EditModalItemProps) => {
    const {
        input,
        inputName,
        disabled,
        sectionName,
        rowIndex,
        usingData,
        sectionTitle,
        onSubscriptionFieldChange,
    } = props

    const {
        trigger,
        formState: { isSubmitted },
    } = useFormContext()

    return (
        <Controller
            name={`${inputName}.${input.name}`}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                let component
                switch (input.type) {
                    case 'file':
                        component = <EditModalFileInput
                            name={input.name}
                            title={input.title}
                            rowIndex={rowIndex}
                            onChange={() => {
                                if (input.isSubscription) {
                                    onSubscriptionFieldChange && onSubscriptionFieldChange(rowIndex, input.name, value)
                                }

                                if (input.withTrigger && (isSubmitted || input.ignoreSubmitted)) {
                                    void trigger(inputName)
                                }
                            }}
                            rowName={inputName}
                            customDisabledType={input.customDisabledType}
                            disabled={disabled || input.disabled}
                            sectionName={sectionName}
                            usingData={usingData}
                            sectionTitle={sectionTitle}
                            isError={!!error}
                        />
                        break
                    case 'select':
                        component = <MobileCustomSelect
                            name={input.name}
                            title={input.title}
                            items={input.items}
                            isSearchable={input.search}
                            disabled={disabled || input.disabled}
                            placeholder={input.placeholder}
                            error={error}
                            value={value}
                            onChange={(value) => {
                                onChange(value)

                                if (input.isSubscription) {
                                    onSubscriptionFieldChange && onSubscriptionFieldChange(rowIndex, input.name, value)
                                }

                                if (input.withTrigger && (isSubmitted || input.ignoreSubmitted)) {
                                    void trigger(inputName)
                                }
                            }}
                        />
                        break
                    default:
                        component = (
                            <CustomInput
                                title={input.title}
                                name={input.name}
                                onChange={(value) => {
                                    onChange(value)

                                    if (input.isSubscription) {
                                        onSubscriptionFieldChange && onSubscriptionFieldChange(rowIndex, input.name, value)
                                    }

                                    if (input.withTrigger && (isSubmitted || input.ignoreSubmitted)) {
                                        void trigger(inputName)
                                    }
                                }}
                                value={value}
                                placeholder={input.placeholder}
                                disabled={disabled || input.disabled}
                                mask={input.mask}
                                multiline={input.multiline}
                                error={error}
                            />
                        )
                        break
                }
                return component
            }}
        />
    )
}

export default EditModalItem
