import { Box, Popover } from '@mui/material'
import { FaListAlt } from '@react-icons/all-files/fa/FaListAlt'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { TabsTableTemplate } from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { useAppDispatch } from '~/redux/store'

export interface TabsTableTemplateButtonProps {
    baseName: string
    name: string
    pageIndex: number
    templates: TabsTableTemplate[]
    sectionName: string
    isStrictTemplateSetting?: boolean
    disabled?: boolean
    onTemplateLoad?: (pageIndex: number) => void
}

const TabsTableTemplateButton = (props: TabsTableTemplateButtonProps) => {
    const {
        baseName,
        name,
        pageIndex,
        templates,
        sectionName,
        isStrictTemplateSetting,
        disabled,
        onTemplateLoad,
    } = props

    const dispatch = useAppDispatch()
    const [mainAnchorEl, setMainAnchorEl] = useState<HTMLButtonElement | null>(null)

    const {
        setValue,
        trigger,
        formState: { isSubmitted },
    } = useFormContext()

    const submitChosenTemplate = (templateItems: any[]) => {
        setValue(name, templateItems)

        isSubmitted && trigger(baseName)
        isSubmitted && trigger(name)
        onTemplateLoad && onTemplateLoad(pageIndex)
        setMainAnchorEl(null)
    }

    const submitStrictTemplate = () => {
        const template = templates.find((item) => item.name === sectionName)

        if (template) {
            setValue(name, template.items)

            isSubmitted && trigger(baseName)
            isSubmitted && trigger(name)
            onTemplateLoad && onTemplateLoad(pageIndex)
        } else {
            dispatch(SHOW_NOTICE({
                type: 'error',
                message: 'Шаблон для данной секции отсутствует',
            }))
        }
    }

    const addTemplateBtnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isStrictTemplateSetting) {
            submitStrictTemplate()
        } else {
            setMainAnchorEl(event.currentTarget)
        }
    }

    return (
        <Box>
            <CustomButton
                className='tabsTableToolbar__button'
                theme={CustomButtonThemes.OUTLINE}
                disabled={disabled}
                onClick={addTemplateBtnHandler}
            >
                <FaListAlt className='tabsTableToolbar__icon' size={16} />
                Загрузить шаблон
            </CustomButton>
            <Popover
                className='tabsTableHeaderButton__popover'
                open={mainAnchorEl !== null}
                anchorEl={mainAnchorEl}
                onClose={() => setMainAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className='tabsTableHeaderButton__wrapper'>
                    {templates
                        .filter((template) => !!template.items.length)
                        .map((template) => {
                            return (
                                <div
                                    className='tabsTableHeaderButton__option'
                                    onClick={() => submitChosenTemplate(template.items)}
                                    key={`template-option_${template.name}`}
                                >
                                    {template.title}
                                </div>
                            )
                        })}
                </div>
            </Popover>
        </Box>
    )
}

export default TabsTableTemplateButton
