import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import InteractiveButton from './subComponents/InteractiveButton/InteractiveButton'
import Preloader from '../../../preloaders/Preloader/Preloader'
import CustomTable from '../../../ReusingComponents/CustomTable/CustomTable'
import { useLazyGetOrderReportQuery } from '~/API/orderApi/order'
import { GetOrderReportResponse } from '~/API/orderApi/types/order.types'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import { fetchDate } from '~/shared/util/currentTime'
import './ReportTable.scss'

interface ReportTableProps {
    table1: ReportTableType,
    table2: ReportTableType,
}

export interface ReportTableType {
    caption: string,
    name: keyof ResultDataType,
    th: string[],
}

interface ResultDataType {
    cost: number,
    expanses: {
        th: string[],
        values: {
            value: string | number,
        }[][]
    },
    object: {
        th: string[],
        values: {
            value: string | number,
            className?: string,
        }[][]
    },
    prepayment: number,
    tooltip: string,
}

const ReportTable = ({ table1, table2 }: ReportTableProps) => {
    const dispatch = useAppDispatch()

    const [getOrderReport] = useLazyGetOrderReportQuery()

    const { getValues } = useFormContext()

    const [resultData, setResultData] = useState<ResultDataType>({} as ResultDataType)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [currentOrderValues, setCurrentOrderValues] = useState<GetOrderReportResponse>({} as GetOrderReportResponse)

    useEffect(() => {
        setLoading(true)
        getOrderReport(getValues().id, true).unwrap()
            .then((res) => {
                setCurrentOrderValues(res)
            })
            .catch(() => {
                dispatch(SHOW_NOTICE({ type: 'error', message: 'Произошла ошибка при получении заявки' }))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        const values = getValues()

        const tooltip = `Безнал: ${currentOrderValues.costCard || 0} ₽\nНаличные: ${currentOrderValues.costCash || 0} ₽${currentOrderValues.actualPrepayment > 0 ? ' \nПредоплата: ' + (currentOrderValues.actualPrepayment || 0) + '₽' : ''}`


        const expanses = [
            [{ value: 'Стоимость' }, { value: currentOrderValues.simpleReport?.plan.cost || 0 }, { value: currentOrderValues.simpleReport?.fact.cost || 0 }],
            [{ value: 'ФОТ' }, { value: currentOrderValues.simpleReport?.plan.totalWage || 0 }, { value: currentOrderValues.simpleReport?.fact.totalWage || 0 }],
            [{ value: 'ТМЦ' }, { value: Math.floor(currentOrderValues.simpleReport?.plan.consumables || 0) }, { value: currentOrderValues.simpleReport?.fact.consumables || 0 }],
            [{ value: 'Амортизация' }, { value: Math.floor(currentOrderValues.simpleReport?.plan.amortization || 0) }, { value: Math.floor(currentOrderValues.simpleReport?.fact.amortization || 0) }],
            [{ value: 'Бензин' }, { value: currentOrderValues.simpleReport?.plan.petrol || 0 }, { value: currentOrderValues.simpleReport?.fact.petrol || 0 }],
            [{ value: 'Паркинг' }, { value: currentOrderValues.simpleReport?.plan.parking || 0 }, { value: currentOrderValues.simpleReport?.fact.parking || 0 }],
            [{ value: 'Логистика' }, { value: currentOrderValues.simpleReport?.plan.logistic || 0 }, { value: currentOrderValues.simpleReport?.fact.logistic || 0 }],
            [{ value: 'Аренда ТМЦ' }, { value: currentOrderValues.simpleReport?.plan.rent || 0 }, { value: currentOrderValues.simpleReport?.fact.rent || 0 }],
            [{ value: 'Подрядные работы' }, { value: currentOrderValues.simpleReport?.plan.contract || 0 }, { value: currentOrderValues.simpleReport?.fact.contract || 0 }],
            [{ value: 'Доп. расходы' }, { value: currentOrderValues.simpleReport?.plan.otherExpanses || 0 }, { value: Number(currentOrderValues.simpleReport?.fact.otherExpanses) || 0 }],
            [{ value: 'Исправление недочетов' }, { value: 0 }, { value: Number(currentOrderValues.simpleReport?.fact.additionalExpanses) || 0 }],
            [{ value: 'Налог' }, { value: currentOrderValues.simpleReport?.plan.tax || 0 }, { value: currentOrderValues.simpleReport?.fact.tax || 0 }],
        ].map((e) => [...e, { value: Number(e[2].value) - Number(e[1].value) }])

        const diff = expanses.some((e) => e[3].value !== 0)

        const data: ResultDataType = {
            object: {
                values: [
                    [{ value: 'Дата уборки' }, {
                        value: fetchDate(currentOrderValues?.generalInfo?.orderStartDate).format('DD MMMM HH:mm  -  ') +
                            fetchDate(currentOrderValues?.generalInfo?.orderEndDate).format('DD MMMM HH:mm'),
                        className: 'pre',
                    }],
                    [{ value: 'Адрес' }, { value: currentOrderValues?.generalInfo?.address }],
                    [{ value: 'Контрагент' }, { value: currentOrderValues?.generalInfo?.counterparty }],
                    [
                        { value: currentOrderValues?.generalInfo?.counterparty === 'Физ. лицо' ? 'Имя клиента' : 'Наименование организации' },
                        { value: currentOrderValues?.generalInfo?.organizationName || currentOrderValues?.generalInfo?.clientFullname },
                    ],
                    [{ value: 'Тип объекта' }, { value: currentOrderValues?.generalInfo?.buildingType }],
                    [{ value: 'Объект' }, { value: currentOrderValues?.generalInfo?.buildingClass }],
                    [{ value: 'Тип услуги' }, { value: currentOrderValues?.generalInfo?.cleaningType }],
                    [{ value: 'Вид услуги' }, { value: currentOrderValues?.generalInfo?.serviceType }],
                    [{ value: 'Квадратура(м2)' }, { value: currentOrderValues?.generalInfo?.actualObjectArea || '-' }],
                    [{ value: 'Кол-во клинеров' }, { value: currentOrderValues?.generalInfo?.actualCleanerNumber || '0' }],
                    [{ value: 'Время' }, { value: currentOrderValues?.generalInfo?.actualDurationCleaningTime || '0' }],
                ],
                th: ['Наименование', 'Факт'],
            },
            expanses: {
                values: expanses,
                th: diff ? ['Наименование', 'План', 'Факт', 'Экономия / перерасход'] : ['Наименование', 'Факт'],
            },
            cost: currentOrderValues.simpleReport?.plan.cost || 0,
            tooltip,
            prepayment: currentOrderValues.actualPrepayment,
        }
        setResultData(data)
    }, [currentOrderValues])


    const defineColor = (percent: number) => {
        if (percent >= 50) {
            return 'darkGreen'
        }
        if (percent >= 40) {
            return 'lightGreen'
        }
        if (percent >= 30) {
            return 'yellow'
        }
        if (percent >= 25) {
            return 'orange'
        }
        return 'red'
    }

    return (
        <div className='reportTable'>
            {resultData && !isLoading ? (
                <>
                    <div className='reportTable__tables'>
                        <CustomTable
                            caption={table1.caption}
                            // @ts-ignore
                            th={(resultData?.[table1.name as keyof typeof resultData])?.th}
                            // @ts-ignore
                            data={(resultData?.[table1.name as keyof typeof resultData])?.values}
                        />
                        <CustomTable
                            caption={table2.caption}
                            // @ts-ignore
                            th={(resultData?.[table2.name as keyof typeof resultData])?.th}
                            // @ts-ignore
                            data={(resultData?.[table2.name as keyof typeof resultData])?.values}
                        />
                    </div>
                    <div className='reportTable__buttons'>
                        <InteractiveButton
                            caption={currentOrderValues.simpleReport?.fact.cost}
                            title={'Выручка'}
                            tooltip={resultData.tooltip}
                        />
                        <InteractiveButton
                            color={defineColor(Math.round((currentOrderValues.simpleReport?.fact?.marginality || 0) * 100))}
                            caption={Math.round((currentOrderValues.simpleReport?.fact?.marginality || 0) * 100) + '%'}
                            title={'Маржинальность'}
                        />
                        <InteractiveButton
                            caption={currentOrderValues.simpleReport?.fact.grossProfit}
                            title={'Валовая прибыль'}
                        />
                    </div>
                </>
            ) : (
                <Preloader />
            )}
        </div>
    )
}

export default ReportTable
