import React, { useState } from 'react'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { FaArrowLeft, FaArrowRight, FaArrowRotateRight, FaCircleArrowLeft, FaCircleArrowRight } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import CustomDateInput from '../CustomDateInput/CustomDateInput'
import CustomFileInputGroup from '../CustomFileInputGroup/CustomFileInputGroup'
import CustomSelect from '../CustomSelect/CustomSelect'
import { CustomCommentInput } from '~/components/common/ReusingComponents/CustomCommentInput/CustomCommentInput'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import './CustomFormActionInput.scss'

export const textStages = [
    'CHANGE_COST',
    'RESERVE_ORDER',
    'RESERVE_CLEANING',
    'ORDER_TO_DIVISION',
    'CHANGE_REQUEST',
    'CONTRACT_SIGNING_INFO',
    'INFO_TO_FOREMAN',
    'RETURN_ORDER',
    'ADD_SERVICES_RESPONSE',
    'TO_ORDER_MANAGER_ORDER_CLOSING',
    'ORDER_MANAGER_ORDER_CLOSING_INFO',
    'TO_OPERATOR_ORDER_CLOSING',
    'INTERNSHIP',
    'FIRED',
    'WORKING',
    'NOT_WORKING',
    'BLACK_LIST',
]
const dateStages = ['RESERVE_ORDER', 'NEW_CLEANING_DATE']
const filesStages = ['ADD_SERVICES_RESPONSE']

export const enum ActionIconsEnum {
    FORWARD = 'forward',
    REPEAT = 'repeat',
    BACK = 'back'
}

export const selectActionIcon = (icon: ActionIconsEnum) => {
    switch (icon) {
        case ActionIconsEnum.BACK:
            return <FaArrowLeft className='action-icon action-icon--back'/>
        case ActionIconsEnum.FORWARD:
            return <FaArrowRight className='action-icon action-icon--forward'/>
        case ActionIconsEnum.REPEAT:
            return <FaArrowRotateRight className='action-icon action-icon--repeat'/>
    }
}

interface ICustomFormActionInput {
    items: {
        value: string,
        text: string,
        icon?: ActionIconsEnum
    }[],
    title: any,
    division: string,
}

const CustomFormActionInput = (props: ICustomFormActionInput) => {
    const {
        items,
        title,
        division,
    } = props
    const dispatch = useDispatch()
    const [action, setAction] = useState('Выберите действие')

    const {
        control,
        getValues,
        handleSubmit,
        clearErrors,
    } = useForm({})

    const {
        setValue,
    } = useFormContext()

    const inputsInfo: Record<string, any> = {
        NEW_CLEANING_DATE: {
            title: 'Новая дата уборки',
            singleDate: false,
        },
        ADD_SERVICES_RESPONSE: {
            title: 'Контент загрязнений',
            name: 'files',
            allowedFileTypes: ['image', 'video'],
            required: true,
        },
    }

    const onChange = (action: string) => {
        clearErrors()
        setValue && setValue('nextStageName', action)
        setAction(action)
    }

    const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (!action || action === 'Выберите действие') {
            return
        }
        handleSubmit(
            () => {
                dispatch(
                    changeFormAction({
                        action: action,
                        files: getValues().files,
                        comment: getValues().comment,
                        date: getValues().date,
                    }),
                )
            },
        )()
    }

    return (
        <div className='customFormActionInput'>
            {filesStages.includes(action) && (
                <Controller
                    name={inputsInfo[action as keyof typeof inputsInfo]?.name || 'files'}
                    defaultValue={[]}
                    control={control}
                    key='controller_files'
                    rules={{
                        validate: (value) => {
                            return (
                                !inputsInfo[action as keyof typeof inputsInfo]?.required ||
                                (value !== null && value !== undefined && value.length > 0)
                            )
                        },
                    }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <CustomFileInputGroup
                            allowedFileTypes={inputsInfo[action as keyof typeof inputsInfo]?.allowedFileTypes}
                            title={inputsInfo[action as keyof typeof inputsInfo]?.title || 'Дата задачи'}
                            values={value ? value : []}
                            error={error}
                            onChange={onChange}
                            name={inputsInfo[action as keyof typeof inputsInfo]?.name || 'files'}
                        />
                    )}
                />
            )}
            {textStages.includes(action) && (
                <Controller
                    key={'controller_comment'}
                    name={'comment'}
                    control={control}
                    rules={{ required: action !== 'ORDER_TO_DIVISION' }}
                    defaultValue=''
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <CustomCommentInput
                            className={'customFormActionInput__input'}
                            onChange={onChange}
                            name={'comment'}
                            error={error}
                            title={'Информация для передачи'}
                            value={value}
                            commentType={'informationToBeTransmitted'}
                        />
                    )}
                />
            )}
            {dateStages.includes(action) && (
                <Controller
                    name={'date'}
                    control={control}
                    key={'controller_date'}
                    rules={{ required: true }}
                    defaultValue=''
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <CustomDateInput
                            singleDate={
                                inputsInfo[action as keyof typeof inputsInfo]?.singleDate !== undefined ? inputsInfo[action as keyof typeof inputsInfo].singleDate : true}
                            title={inputsInfo[action as keyof typeof inputsInfo]?.title || 'Дата задачи'}
                            value={value}
                            error={error}
                            onChange={onChange}
                            division={division}
                            cleaningDate={true}
                        />
                    )}
                />
            )}
            <div className='customFormActionInput__body'>
                <CustomSelect
                    title={title}
                    items={items}
                    onChange={onChange}
                    value={action}
                />
                <button type={'button'} className={'button button_border-none'} onClick={onSubmit}>
                    Отправить
                </button>
            </div>
        </div>
    )
}

export default CustomFormActionInput
