import clsx from 'clsx'
import React from 'react'
import { chooseSvg } from '~/shared/util/svg'
import { orderTypes } from '~/shared/util/types'
import './FilterItems.scss'

type FilterItemProps = {
    items: string[]
    values: string[]
    title: string
    onClick: (items: string[]) => void
}
const FilterItems = (props: FilterItemProps) => {
    const {
        onClick,
        items,
        values,
        title,
    } = props
    const handleClick = (item: string) => {
        let newValues = [...values]
        if (newValues.includes(item)) {
            newValues = newValues.filter((v) => v !== item)
        } else {
            newValues.push(item)
        }
        onClick(newValues)
    }
    return (
        <div className='filterItems'>
            {items?.length > 0 && (
                <>
                    <h5>{title}</h5>
                    <div className='filterItems__body'>
                        {items.map((item) => (
                            <div
                                key={item}
                                onClick={() => handleClick(item)}
                                className={clsx('filterItems__typeItem', item, { active: values.includes(item) })}
                            >
                                {chooseSvg(item)}
                                {orderTypes[item as keyof typeof orderTypes]}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default FilterItems
