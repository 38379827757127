import { CleaningsInfoType } from '~/components/common/popups/PopupDatePicker/lib/types'
import { fetchDate, fetchLocalDate } from '~/shared/util/currentTime'

export const getAvailableTime = () => {
    const availableTime = fetchDate().add(1, 'hour').set('minute', 0)
    if (fetchDate(new Date()).isSame(availableTime, 'days')) {
        return availableTime.toDate()
    }
    return fetchDate(new Date()).set('hour', 23).set('minute', 30).toDate()
}

interface ConstructClassForHoursParams {
    value: string
    activeInput: 'START' | 'END'
    values: any
}

export const constructClassForHours = (params: ConstructClassForHoursParams) => {
    const {
        value,
        activeInput,
        values,
    } = params
    if (activeInput === 'START' && values.startDate) {
        if (value === fetchDate(values.startDate).format('HH:mm')) {
            return 'active'
        } else if (
            fetchLocalDate().isSame(values.startDate, 'day') &&
            fetchLocalDate().diff(fetchDate(values.startDate.toLocaleDateString() + ' ' + value, 'DD.MM.YYYY HH:mm')) >= 0
        ) {
            return 'disabled'
        } else {
            return ''
        }
    }
    if (activeInput === 'END' && values.endDate) {
        if (value === fetchDate(values.endDate).format('HH:mm')) {
            return 'active'
        } else if (
            fetchDate(values.endDate).isSame(values.startDate, 'day') &&
            fetchDate(values.startDate).diff(fetchDate(values.endDate.toLocaleDateString() + ' ' + value, 'DD.MM.YYYY HH:mm')) >= 0
        ) {
            return 'disabled'
        }
        return ''
    }

    if (activeInput === 'START' && values instanceof Date) {
        if (value === fetchDate(values).format('HH:mm')) {
            return 'active'
        }
    }

    if (
        (values.startDate && activeInput === 'START' && value === fetchDate(values.startDate).format('HH:mm')) ||
        (values.endDate && activeInput === 'END' && value === fetchDate(values.endDate).format('HH:mm'))
    ) {
        return 'active'
    }

    return 'disabled'
}

interface ConstructClassForDayParams {
    date: Date
    cleaningsInfo: CleaningsInfoType
    cleaningDate: boolean
    values: any
}

export const constructClassForDay = (params: ConstructClassForDayParams) => {
    const {
        date,
        cleaningsInfo,
        cleaningDate,
        values,
    } = params

    const resultClasses = new Set()
    if (!cleaningsInfo.has(fetchDate(date).startOf('day').toLocaleString()) && cleaningDate) {
        resultClasses.add('notAvailable')
    }
    if (date) {
        if (values.startDate && values.endDate) {
            if (fetchDate(date).isSame(values.startDate, 'day') &&
                fetchDate(values.startDate, 'day').isSame(values.endDate, 'day')
            ) {
                resultClasses.add('singleDate')
            } else if (fetchDate(date).isSame(values.startDate, 'day')) {
                resultClasses.add('startDate')
            } else if (fetchDate(date).isSame(values.endDate, 'day')) {
                resultClasses.add('endDate')
            } else if (
                fetchDate(values.endDate).isAfter(date, 'day') &&
                fetchDate(values.startDate).isBefore(date, 'day')
            ) {
                resultClasses.add('processDate')
            }
        } else if (values.startDate && fetchDate(date).isSame(values.startDate, 'day')) {
            resultClasses.add('singleDate')
        } else if (values.endDate && fetchDate(date).isSame(values.endDate, 'day')) {
            resultClasses.add('singleDate')
        } else if (values instanceof Date && fetchDate(date).isSame(values, 'day')) {
            resultClasses.add('singleDate')
        }
    }

    if (fetchDate(date).isBefore(fetchDate(), 'day')) {
        resultClasses.add('notAvailable')
    }

    if (cleaningsInfo) {
        const dayInfo = cleaningsInfo.get(fetchDate(date).startOf('day').toLocaleString())
        if (dayInfo && dayInfo.available === 0) {
            resultClasses.add('notAvailable')
        }
    }

    return [...resultClasses].join(' ')
}

export const constructTooltipForDay = (cleaningDate: boolean, dateInfo?: { available: number, ordered: number }) => {
    if (dateInfo) {
        return `${dateInfo.available} свободно\n${dateInfo.ordered} занято`
    }
    if (cleaningDate) {
        return '0 свободно\n0 занято'
    }
    return null
}

export const checkAvailableDate = (date: Date, type: 'START' | 'END', values: any) => {
    const checkDate = fetchDate(date)
    if (type === 'START') {
        // return checkDate.diff(nowDate) >= 0;//TODO Временно для добавление B2B
        return true
    } else {
        return values.startDate && checkDate.diff(values.startDate) > 0
    }
}

export const horizontalScroll = (e: any) => {
    const delta = Math.max(-5, Math.min(5, e.nativeEvent.wheelDelta || -e.nativeEvent.detail))
    e.currentTarget.scrollLeft -= delta * 10
}
