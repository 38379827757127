interface FormatNumberTypes extends Intl.NumberFormatOptions {
    num: number
}

export const formatNumber = (params: FormatNumberTypes): string => {
    const {
        num,
        minimumFractionDigits = 0,
        maximumFractionDigits = 2,
        ...options
    } = params
    return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
        ...options,
    }).format(num).replace(/,/g, '.')
}

export const formatRUB = (num: number = 0, kopeck: boolean = true) => formatNumber({
    num,
    minimumFractionDigits: kopeck ? 2 : 0,
    maximumFractionDigits: kopeck ? 2 : 0,
    style: 'currency',
    currency: 'RUB',
})

export const formatCurrencyRUB = (num: number = 0, kopeck: boolean = true) => {
    const formattedNum = formatNumber({
        num,
        minimumFractionDigits: kopeck ? 2 : 0,
        maximumFractionDigits: kopeck ? 2 : 0,
        style: 'decimal',
    })

    const currencyWord = getCurrencyWord(num)
    return `${formattedNum} ${currencyWord}`
}

function getCurrencyWord(amount: number): string {
    const lastDigit = amount % 10
    const lastTwoDigits = amount % 100

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
        return 'рублей'
    }

    switch (lastDigit) {
        case 1:
            return 'рубль'
        case 2:
        case 3:
        case 4:
            return 'рубля'
        default:
            return 'рублей'
    }
}
