import React from 'react'
import { ClientAddress } from '../subComponents/ClientAddress/ClientAddress'
import { ClientOrganization } from '../subComponents/ClientOrganization/ClientOrganization'
import { ClientService } from '../subComponents/ClientService/ClientService'
import { ClientType, ClientTypes } from '../subComponents/ClientType/ClientType'
import { ClientResponse } from '~/API/clientApi/client.types'
import {
    ClientAddressType,
    CustomClientInputActions,
} from '~/components/common/inputs/CustomClientInput/CustomClientInput.types'
import {
    ItemType,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientOrganization/ClientOrganization.types'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { CustomTitle } from '~/components/common/ReusingComponents/CustomTitle/CustomTitle'
import StatusBar, {
    StatusBarThemes,
} from '~/components/common/ReusingComponents/StatusBar/StatusBar'

interface ClientPopupBodyParams {
    clientStage: {
        current: number,
        amount: number,
    }
    clientType: ClientTypes
    clientInfo: ClientResponse<ItemType>
    clientAddress: ItemType
    handleSetClientType: (value: ClientTypes) => void
    handleSetClientInfo: (value: ClientResponse<ItemType>) => void
    handleSetClientAddress: (value: ClientAddressType) => void
    clientAction: CustomClientInputActions
}

export const clientPopupBody = (params: ClientPopupBodyParams): {
    className?: { title: string, index: number },
    data: JSX.Element[][]
} => {
    const {
        clientStage,
        clientType,
        clientInfo,
        clientAddress,
        handleSetClientType,
        handleSetClientInfo,
        handleSetClientAddress,
        clientAction,
    } = params

    const statusBar = (
        <StatusBar
            key='CustomClientInput__StatusBar'
            theme={StatusBarThemes.THIN}
            stage={{
                current: clientStage.current,
                amount: clientStage.amount,
            }}
            className='custom-client-input__progress'
        />
    )

    const title = (title: string) => (
        <CustomTitle
            key='CustomClientInput__CustomTitle'
            title={title}
            className='custom-client-input__title'
        />
    )

    if (clientAction === CustomClientInputActions.ACTIVE) {
        switch (clientStage.current) {
            case 1: {
                return ({
                    data: [
                        [statusBar],
                        [title('Выберите контрагента')],
                        [(
                            <ClientType
                                key='CustomClientInput__ClientType'
                                clientType={clientType}
                                onClick={handleSetClientType}
                            />
                        )],
                    ],
                })
            }
            case 2: {
                return ({
                    className: { title: 'custom-client-input__content__body', index: 2 },
                    data: [
                        [statusBar],
                        [title(clientType === ClientTypes.ENTITY ? 'Укажите организацию' : 'Укажите номер телефона')],
                        [(
                            <ClientOrganization
                                key='CustomClientInput__ClientOrganization'
                                className='custom-client-input__content'
                                handleSetClientInfo={handleSetClientInfo}
                                clientInfo={clientInfo}
                                placeholder={clientType === ClientTypes.ENTITY ?
                                    'Введите название организации или ИНН' : 'Введите номер телефона'}
                                type={clientType}
                            />
                        )],
                    ],
                })
            }
            case 3: {
                return ({
                    className: { title: 'custom-client-input__content__body', index: 2 },
                    data: [
                        [statusBar],
                        [title('Выберите адрес')],
                        [(
                            <ClientAddress
                                className='custom-client-input__content'
                                key='CustomClientInput__ClientAddress'
                                clientInfo={clientInfo}
                                clientAddress={clientAddress}
                                handleSetClientAddress={handleSetClientAddress}
                            />
                        )],
                    ],
                })
            }
            // case 4: {
            //     return ({
            //         className: { title: 'custom-client-input__content__body', index: 2 },
            //         data: [
            //             [statusBar],
            //             [title('Выберите дату')],
            //             [(
            //                 <ClientDate
            //                     className='custom-client-input__content'
            //                     key='CustomClientInput__ClientService'
            //                     clientInfo={clientInfo}
            //                     clientAddress={clientAddress}
            //                     handleSetClientDate={handleSetClientDate}
            //                     clientDate={clientDate}
            //                 />
            //             )],
            //         ],
            //     })
            // }
            default: {
                return {
                    data: [],
                }
            }
        }
    }
    return ({
        className: { title: 'custom-client-input__content__body', index: 2 },
        data: [
            [(
                <StatusBar
                    key='CustomClientInput__StatusBar'
                    theme={StatusBarThemes.THIN}
                    stage={{
                        current: 1,
                        amount: 1,
                    }}
                    className='custom-client-input__progress'
                />
            )],
            [title('Выберите выполняемые услуги')],
            [(
                <ClientService
                    className='custom-client-input__content'
                    key='CustomClientInput__ClientService'
                    clientInfo={clientInfo}
                    clientAddress={clientAddress}
                />
            )],
        ],
    })
}

interface ClientPopupFooterParams {
    clientAction: CustomClientInputActions
    clientStage: {
        current: number,
        amount: number,
    }
    clientType: ClientTypes
    clientAddress: ItemType
    clientInfo: ClientResponse<ItemType>
    handleSetStage: (value: 'prev' | 'next' | 'end', stage: number) => void
    clientServicesSize: number
}

export const clientPopupFooter = (params: ClientPopupFooterParams) => {
    const {
        clientAction,
        clientStage,
        clientType,
        clientAddress,
        clientInfo,
        clientServicesSize,
        handleSetStage,
    } = params
    if (clientAction === CustomClientInputActions.ACTIVE) {
        switch (clientStage.current) {
            case 1: {
                return [
                    (
                        <CustomButton
                            key='CustomClientInput__CustomButton'
                            onClick={() => handleSetStage('next', clientStage.current)}
                            className='custom-client-input__button'
                            title='Следующий шаг'
                            theme={CustomButtonThemes.COLOR}
                            disabled={clientType === ClientTypes.NONE}
                        />
                    ),
                ]
            }
            case 2: {
                return [
                    (
                        <CustomButton
                            key='CustomClientInput__CustomButton__1'
                            onClick={() => handleSetStage('next', clientStage.current)}
                            className='custom-client-input__button'
                            title='Следующий шаг'
                            theme={CustomButtonThemes.COLOR}
                            disabled={clientType === ClientTypes.NONE || !clientInfo?.additionalParams?.value}
                        />
                    ),
                    (
                        <CustomButton
                            key='CustomClientInput__CustomButton__2'
                            onClick={() => handleSetStage('prev', clientStage.current)}
                            className='custom-client-input__button'
                            title='Назад'
                            theme={CustomButtonThemes.SMOKE}
                            disabled={clientType === ClientTypes.NONE}
                        />
                    ),
                ]
            }
            case 3: {
                return [
                    (
                        <CustomButton
                            key='CustomClientInput__CustomButton__1'
                            onClick={() => handleSetStage(clientAction === CustomClientInputActions.ACTIVE ?
                                'end' : 'next', clientStage.current)}
                            className='custom-client-input__button'
                            title={clientAction === CustomClientInputActions.ACTIVE ? 'Завершить' : 'Следующий шаг'}
                            theme={CustomButtonThemes.COLOR}
                            disabled={clientType === ClientTypes.NONE || !clientAddress.value || clientAddress.value === 'none'}
                        />
                    ),
                    (
                        <CustomButton
                            key='CustomClientInput__CustomButton__2'
                            onClick={() => handleSetStage('prev', clientStage.current)}
                            className='custom-client-input__button'
                            title='Назад'
                            theme={CustomButtonThemes.SMOKE}
                            disabled={clientType === ClientTypes.NONE}
                        />
                    ),
                ]
            }
            // case 4: {
            //     return [
            //         (
            //             <CustomButton
            //                 key='CustomClientInput__CustomButton__1'
            //                 onClick={() => handleSetStage('next', clientStage.current)}
            //                 className='custom-client-input__button'
            //                 title='Следующий шаг'
            //                 theme={CustomButtonThemes.COLOR}
            //                 disabled={clientType === ClientTypes.NONE || !clientDate.value}
            //             />
            //         ),
            //         (
            //             <CustomButton
            //                 key='CustomClientInput__CustomButton__2'
            //                 onClick={() => handleSetStage('prev', clientStage.current)}
            //                 className='custom-client-input__button'
            //                 title='Назад'
            //                 theme={CustomButtonThemes.SMOKE}
            //                 disabled={clientType === ClientTypes.NONE}
            //             />
            //         ),
            //     ]
            // }
            default: {
                return []
            }
        }
    }
    return [
        (
            <CustomButton
                key='CustomClientInput__CustomButton__1'
                onClick={() => handleSetStage('end', 0)}
                className='custom-client-input__button'
                title='Повторить'
                theme={CustomButtonThemes.COLOR}
                disabled={!clientServicesSize}
            />
        ),
    ]
}
