import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    ActiveDateRange,
    SalaryJobPositions,
    SalaryNavbarButtonsMods,
    SalarySliceInitialState,
} from './salarySlice.type'
import { SalaryObject, SalaryReport } from '~/API/salaryApi/salary.types'
import {
    getInfoForTable,
    getSalary,
    getSalaryFiles,
    getSingleSalaries,
    getWorkers,
} from '~/components/pages/Salary/salaryFunctions'
import { getFirstDayOfWeek, getLastDayOfWeek } from '~/shared/util/currentTime'

const salarySliceInitialState: SalarySliceInitialState = {
    salaryMode: SalaryNavbarButtonsMods.DEFAULT,
    salaryWorkers: [],
    salaryReport: {} as SalaryReport,
    salaryTableInfo: [],
    salaryPayment: [],
    salaryActiveDateRange: {
        start: getFirstDayOfWeek(), // .add(1, 'day'), // there are magic error, that return Sunday instead of Monday
        end: getLastDayOfWeek(), // .add(1, 'day'),
    },
    salarySinglePayment: [],
    salaryFiles: {
        issuedFiles: [],
        payedFiles: [],
    },
    salaryLoader: false,
    salaryError: '',
    personnelToIssued: [],
    salaryJobPosition: 'Клинер',
}

export const salarySlice = createSlice({
    name: 'salary',
    initialState: salarySliceInitialState,
    reducers: {
        setSalaryMode: (state, action: PayloadAction<SalaryNavbarButtonsMods>) => {
            state.salaryMode = action.payload
        },
        changeSalaryPayment: (state, action: PayloadAction<SalaryObject>) => {
            state.salaryPayment = [...state.salaryPayment.filter((salary) => salary.id !== action.payload.id), action.payload]
        },
        setSalaryActiveDateRange: (state, action: PayloadAction<ActiveDateRange>) => {
            state.salaryActiveDateRange = action.payload
        },
        setPersonnelToIssued: (state, action: PayloadAction<string>) => {
            if (state.personnelToIssued.includes(action.payload)) {
                state.personnelToIssued = state.personnelToIssued.filter((item) => item !== action.payload)
            } else {
                state.personnelToIssued.push(action.payload)
            }
        },
        setPersonnelToIssuedArray: (state, action: PayloadAction<string[]>) => {
            state.personnelToIssued = action.payload
        },
        setSalaryJobPosition: (state, action: PayloadAction<SalaryJobPositions>) => {
            state.salaryJobPosition = action.payload
        },
        setSalaryLoader: (state, action: PayloadAction<boolean>) => {
            state.salaryLoader = action.payload
        },
        updateSalaryFulfilled: (state, action: PayloadAction<{ salary: SalaryReport }>) => {
            const { salary } = action.payload
            state.salaryTableInfo = getInfoForTable(salary, state.salaryActiveDateRange)
            state.salaryPayment = getSalary(salary)
            state.salaryWorkers = getWorkers(salary)
            state.salaryFiles = getSalaryFiles(salary)
            state.salarySinglePayment = getSingleSalaries(salary)
            state.salaryReport = salary
            state.salaryError = ''
        },
        updateSalaryRejected: (state, action: PayloadAction<string>) => {
            state.salaryTableInfo = []
            state.salaryPayment = []
            state.salaryWorkers = []
            state.salaryFiles = {
                issuedFiles: [],
                payedFiles: [],
            }
            state.salarySinglePayment = []
            state.salaryReport = {} as SalaryReport // !!!
            // if (action.error.message !== 'Aborted') {
            state.salaryError = action.payload
            state.salaryLoader = false
            // }
        },
    },
})

export const {
    updateSalaryFulfilled,
    updateSalaryRejected,
    setSalaryMode,
    changeSalaryPayment,
    setSalaryActiveDateRange,
    setPersonnelToIssued,
    setPersonnelToIssuedArray,
    setSalaryJobPosition,
    setSalaryLoader,
} = salarySlice.actions

export default salarySlice.reducer
