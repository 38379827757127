import { fetchDate, fetchDateToUTC } from '../util/currentTime'

export const dateForStages = (formAction: string, order?: any, formDate?: any) => {
    switch (formAction) {
        case 'ORDER_CREATION':
            return { startDate: fetchDate().toDate(), endDate: fetchDate().add(1, 'h').toDate() }
        case 'ORDER_TO_DIVISION':
            return { startDate: fetchDate().toDate(), endDate: fetchDate().add(20, 'm').toDate() }
        case 'EDIT_ORDER':
            return { startDate: fetchDate().toDate(), endDate: fetchDate().add(20, 'm').toDate() }
        case 'RESERVE_ORDER':
            return { startDate: fetchDate(formDate).toDate(), endDate: fetchDateToUTC(formDate).add(15, 'm').toDate() }
        case 'CHANGE_COST':
            return { startDate: fetchDate().toDate(), endDate: fetchDate().add(30, 'm').toDate() }
        case 'TAKE_ORDER':
            return {
                startDate: fetchDateToUTC(formDate).toDate(),
                endDate: fetchDateToUTC(formDate).add(1, 'h').toDate(),
            }
        case 'CHANGE_REQUEST':
            return { startDate: fetchDate().toDate(), endDate: fetchDate().add(20, 'm').toDate() }
        case 'SELECT_INSPECTION_DATE':
            return {
                startDate: fetchDateToUTC(order.inspectionAndAccounting.inspectionDate).toDate(),
                endDate: fetchDateToUTC(order.inspectionAndAccounting.inspectionDate).add(20, 'm').toDate(),
            }
        case 'NEW_CLEANING_APPROVAL_DATE':
            return { startDate: fetchDate().add(2, 'h').toDate(), endDate: fetchDate().add(2, 'h').add(15, 'm').toDate() }
        case 'NEW_CLEANING_DATE':
            return {
                startDate: fetchDateToUTC(formDate.startDate).subtract(1, 'd').toDate(),
                endDate: fetchDateToUTC(formDate.startDate).subtract(1, 'd').add(15, 'm').toDate(),
            }
        case 'APPROVE_CLEANING':
            return { startDate: fetchDate().toDate(), endDate: fetchDate().add(2, 'h').toDate() }
        case 'FILL_NZ_WITH_TASK':
            return {
                startDate: fetchDate().add(10, 'm').toDate(),
                endDate: fetchDate().add(20, 'm').toDate(),
            }
        case 'APPROVE_NZ':
            return {
                startDate: fetchDateToUTC(order.selling.cleaningDate.startDate).subtract(1, 'h').subtract(15, 'm').toDate(),
                endDate: fetchDateToUTC(order.selling.cleaningDate.startDate).subtract(1, 'h').toDate(),
            }
        case 'CLIENT_CONFIRMATION':
            return {
                startDate: fetchDateToUTC(order.selling.cleaningDate.startDate).toDate(),
                endDate: fetchDateToUTC(order.selling.cleaningDate.startDate).add(1, 'h').toDate(),
            }

        case 'INFO_TO_FOREMAN':
            return {
                startDate: order.startDate,
                endDate: order.endDate,
            }

        case 'ADD_SERVICES_RESPONSE':
            return {
                startDate: fetchDate().toDate(),
                endDate: fetchDate().add(10, 'm').toDate(),
            }

        case 'WORK_STARTING':
            return {
                startDate: fetchDateToUTC(order.selling.cleaningDate.startDate).add(2, 'h').toDate(),
                endDate: fetchDateToUTC(order.selling.cleaningDate.startDate).add(2, 'h').add(30, 'm').toDate(),
            }
        case 'WORK_PROCESS':
            return {
                startDate: fetchDate().add(2, 'h').toDate(),
                endDate: fetchDate().add(2, 'h').add(15, 'm').toDate(),
            }
        case 'WORK_ENDING':
            return {
                startDate: fetchDate().add(1, 'h').toDate(),
                endDate: fetchDate().add(1, 'h').add(15, 'm').toDate(),
            }
        case 'START_NEW_DAY':
            return {
                startDate: fetchDateToUTC(order.selling.cleaningDate.startDate).add(order.foremanReport.currentDayNumber, 'd').toDate(),
                endDate: fetchDateToUTC(order.selling.cleaningDate.startDate).add(order.foremanReport.currentDayNumber, 'd').add(30, 'm').toDate(),
            }
        case 'CLEANING_ENDED':
            return {
                startDate: fetchDate().add(20, 'm').toDate(),
                endDate: fetchDate().add(1, 'h').toDate(),
            }
        case 'ON_WAREHOUSE':
            return { startDate: fetchDate().add(20, 'm').toDate(), endDate: fetchDate().add(40, 'm').toDate() }
        case 'FOREMAN_REPORT':
            return { startDate: fetchDate().add(20, 'm').toDate(), endDate: fetchDate().add(40, 'm').toDate() }
        case 'TOOLS_REPORT':
            return { startDate: fetchDate().add(20, 'm').toDate(), endDate: fetchDate().add(40, 'm').toDate() }
        case 'ORDER_MANAGER_REPORT':
            return { startDate: fetchDate().toDate(), endDate: fetchDate().add(20, 'm').toDate() }
        default:
            return { startDate: fetchDate().toDate(), endDate: fetchDate().add(30, 'm').toDate() }
    }
}
