import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UseFieldArrayReplace } from 'react-hook-form'
import { ClientResponse, ClientServiceResponse } from '~/API/clientApi/client.types'
import { CustomClientInputActions } from '~/components/common/inputs/CustomClientInput/CustomClientInput.types'
import {
    ItemType,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientOrganization/ClientOrganization.types'
import { ClientTypes } from '~/components/common/inputs/CustomClientInput/subComponents/ClientType/ClientType'
import { PaymentType } from '~/components/pages/KP/kp.types'

interface ClientSliceState {
    isClientPopupOpen: boolean
    clientAction: CustomClientInputActions
    clientType: ClientTypes
    clientInfo: ClientResponse<ItemType>
    clientAddress: ItemType
    clientServices: ClientServiceResponse
    clientStage: { current: number, amount: number }
    title: string
    replaceMethod?: UseFieldArrayReplace<any>
}

const initialState: ClientSliceState = {
    isClientPopupOpen: false,
    clientAction: CustomClientInputActions.NONE,
    clientType: ClientTypes.NONE,
    clientInfo: {} as ClientResponse<ItemType>,
    clientAddress: {} as ItemType,
    clientServices: {} as ClientServiceResponse,
    clientStage: { current: 1, amount: 1 },
    replaceMethod: undefined,
    title: 'Тип клиента',
}

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClientStage: (state, action: PayloadAction<{ current: number, amount: number }>) => {
            state.clientStage = action.payload
        },
        setClientPopupOpen: (state, action: PayloadAction<boolean>) => {
            state.isClientPopupOpen = action.payload
        },
        setClientServices: (state, action: PayloadAction<ClientServiceResponse>) => {
            state.clientServices = action.payload
        },
        setClientAction: (state, action: PayloadAction<CustomClientInputActions>) => {
            state.clientAction = action.payload
        },
        setClientType: (state, action: PayloadAction<ClientTypes>) => {
            state.clientType = action.payload
        },
        setClientPopupTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload
        },
        setClientInfo: (state, action: PayloadAction<ClientResponse<ItemType>>) => {
            state.clientInfo = action.payload
        },
        setClientAddress: (state, action: PayloadAction<ItemType>) => {
            state.clientAddress = action.payload
        },
        setReplaceMethod: (state, action: PayloadAction<UseFieldArrayReplace<any>>) => {
            state.replaceMethod = action.payload
        },
    },
})

export const {
    setClientStage,
    setClientServices,
    setClientAddress,
    setClientInfo,
    setClientPopupOpen,
    setClientAction,
    setClientType,
    setClientPopupTitle,
    setReplaceMethod,
} = clientSlice.actions

export default clientSlice.reducer
