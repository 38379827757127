import { RouteProps } from 'react-router-dom'
import { AnalyticPage } from '../../pages/Analytic'
import { Archive } from '../../pages/Archive'
import { ContractPage } from '../../pages/Contract'
import { EventsPage } from '../../pages/Events'
import { SalaryPage } from '../../pages/Salary'
import { SettingsPage } from '../../pages/Settings'
import { MobileHomePage } from '../mobilePages/MobileHomePage'
import { MobileKpoPage } from '../mobilePages/MobileKpoPage'
import { MobileOrderPage } from '../mobilePages/MobileOrderPage'
import { MoreMobilePage } from '../mobilePages/MoreMobilePage'
import { StaffScheduleMobilePage } from '../mobilePages/StaffScheduleMobilePage'
import { DashboardRoutePath, DashboardRoutes } from '~/components/navigation/Dashboard/dashboardConfig'
import { ErrorPage, ErrorPageThemes } from '~/components/pages/ErrorPage/'
import OrderCreator from '~/components/pages/Order/subComponents/OrderCreator/OrderCreator'
import { IRulesPagesKeys } from '~/types/types.order'

export enum MobileDashboardRoutes {
    HOME = 'home',
    SETTINGS = 'settings',
    EVENTS = 'events',
    ORDER = 'order',
    ORDER_APPLICATION = 'order_application',
    CONTACT = 'contract',
    CONTACT_FILE = 'contract_file',
    ORDER_CREATOR = 'order_creator',
    ORDER_CREATOR_APPLICATION = 'order_creator_application',
    // SIDEBAR = 'sidebar',
    MORE = 'more',
    ARCHIVE = 'archive',
    STAFF = 'staff',
    SALARY = 'salary',
    ANALYTIC = 'analytic',
    KPO = 'kpo',
    KPO_STARTING = 'kpo_starting',
    NOT_FOUND = 'not_found'
}

export const MobileDashboardRoutePath: Record<MobileDashboardRoutes, string> = {
    [MobileDashboardRoutes.HOME]: '/',
    [MobileDashboardRoutes.SETTINGS]: '/settings',
    [MobileDashboardRoutes.EVENTS]: '/events',
    [MobileDashboardRoutes.ORDER]: '/report/',
    [MobileDashboardRoutes.ORDER_APPLICATION]: '/report/:id/',
    [MobileDashboardRoutes.CONTACT]: '/report/:id/contract/',
    [MobileDashboardRoutes.CONTACT_FILE]: '/report/:id/contract/:fileId/',
    [MobileDashboardRoutes.ORDER_CREATOR]: '/order/',
    [MobileDashboardRoutes.ORDER_CREATOR_APPLICATION]: '/order/:id/',
    // [MobileDashboardRoutes.SIDEBAR]: '/sidebar',
    [MobileDashboardRoutes.MORE]: '/more',
    [MobileDashboardRoutes.ARCHIVE]: '/archive',
    [MobileDashboardRoutes.STAFF]: '/staff',
    [MobileDashboardRoutes.SALARY]: '/salary',
    [MobileDashboardRoutes.ANALYTIC]: '/analytic',
    [MobileDashboardRoutes.KPO]: '/order/:id/kpo',
    [DashboardRoutes.KPO_STARTING]: '/order/:id/kpo-starting',
    [MobileDashboardRoutes.NOT_FOUND]: '*',
}

export const mobileDashboardRouteConfig: Record<MobileDashboardRoutes, RouteProps & {
    rulesCheck?: IRulesPagesKeys
}> = {
    [MobileDashboardRoutes.HOME]: {
        path: MobileDashboardRoutePath.home,
        element: <MobileHomePage />,
    },
    [MobileDashboardRoutes.SETTINGS]: {
        path: MobileDashboardRoutePath.settings,
        element: <SettingsPage />,
    },
    [MobileDashboardRoutes.EVENTS]: {
        path: MobileDashboardRoutePath.events,
        element: <EventsPage />,
    },
    [MobileDashboardRoutes.ORDER]: {
        path: MobileDashboardRoutePath.order,
        element: <MobileOrderPage />,
    },
    [MobileDashboardRoutes.ORDER_APPLICATION]: {
        path: DashboardRoutePath.order_application,
        element: <MobileOrderPage />,
    },
    [MobileDashboardRoutes.CONTACT]: {
        path: MobileDashboardRoutePath.contract,
        element: <ContractPage />,
    },
    [MobileDashboardRoutes.CONTACT_FILE]: {
        path: DashboardRoutePath.contract_file,
        element: <ContractPage />,
    },
    [MobileDashboardRoutes.ORDER_CREATOR]: {
        path: DashboardRoutePath.order_creator,
        element: <OrderCreator />,
    },
    [MobileDashboardRoutes.ORDER_CREATOR_APPLICATION]: {
        path: DashboardRoutePath.order_creator_application,
        element: <OrderCreator />,
    },
    // [MobileDashboardRoutes.SIDEBAR]: {
    //     path: MobileDashboardRoutePath.sidebar,
    //     element: <MobileSidebarPage />,
    // },
    [MobileDashboardRoutes.MORE]: {
        path: MobileDashboardRoutePath.more,
        element: <MoreMobilePage />,
    },
    [MobileDashboardRoutes.ARCHIVE]: {
        path: MobileDashboardRoutePath.archive,
        element: <Archive />,
        rulesCheck: 'archive',
    },
    [MobileDashboardRoutes.STAFF]: {
        path: MobileDashboardRoutePath.staff,
        element: <StaffScheduleMobilePage />,
        rulesCheck: 'schedule',
    },
    [MobileDashboardRoutes.SALARY]: {
        path: MobileDashboardRoutePath.salary,
        element: <SalaryPage />,
        rulesCheck: 'salary',
    },
    [MobileDashboardRoutes.ANALYTIC]: {
        path: MobileDashboardRoutePath.analytic,
        element: <AnalyticPage />,
        rulesCheck: 'analytic',
    },
    [MobileDashboardRoutes.KPO]: {
        path: MobileDashboardRoutePath.kpo,
        element: <MobileKpoPage />,
    },
    [MobileDashboardRoutes.KPO_STARTING]: {
        path: MobileDashboardRoutePath.kpo_starting,
        element: <MobileKpoPage />,
    },
    [MobileDashboardRoutes.NOT_FOUND]: {
        path: MobileDashboardRoutePath.not_found,
        element: <ErrorPage theme={ErrorPageThemes.NOT_FOUND} errorCode={404} />,
    },
}
