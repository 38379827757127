import { EventEmitter } from 'events'
import { ERROR_CODES } from '~/API/orderApi/types/order.types'

type ErrorCode = (typeof ERROR_CODES)[number]

class TypedEventEmitter extends EventEmitter {
    emit(event: ErrorCode, ...args: any[]): boolean {
        return super.emit(event, ...args)
    }

    on(event: ErrorCode, listener: (...args: any[]) => void): this {
        return super.on(event, listener)
    }

    removeListener(event: ErrorCode, listener: (...args: any[]) => void): this {
        return super.removeListener(event, listener)
    }
}

const eventEmitter = new TypedEventEmitter()

export default eventEmitter
