import { Role } from '~/API/userApi/user.types'

export const inputLogicRules: Record<string, (values: any[], role: Role) => boolean> = {
    less100k: (value) => +value[0] < 100000,
    more100k: (values) => +values[0] > 100000,
    roleChiefPrepayment100More100k: (values, role) => role === 'CHIEF' && +values[0] === 100 && +values[1] > 100000,
    prepayment: ([prepaymentType]) => {
        return prepaymentType === 'Внесена'
    },
    prepaymentContract: ([prepaymentType, contract, customContract]) => {
        return prepaymentType === 'Внесена' && (contract || customContract)
    },
    notPrepayment: ([prepaymentType]) => {
        return prepaymentType !== 'Внесена'
    },
    notPrepaymentContract: ([prepaymentType, contract, customContract]) => {
        return prepaymentType !== 'Внесена' && (contract || customContract)
    },
    individual: ([counterparty]) => {
        return counterparty === 'Физ. лицо'
    },
    /** Физ. лицо без договора */
    individualNotContract: ([counterparty, contractType]) => {
        return counterparty === 'Физ. лицо' && contractType === 'Без договора'
    },
    individualKP: ([counterparty, kp]) => {
        return counterparty === 'Физ. лицо' && kp
    },
    individualInspection: ([counterparty, inspection]) => {
        return counterparty === 'Физ. лицо' && inspection
    },
    individualInspectionKP: ([counterparty, inspection, kp]) => {
        return counterparty === 'Физ. лицо' && inspection && kp
    },
    individualWithoutPrepayment: ([counterparty, prepayment]) => {
        return counterparty === 'Физ. лицо' && !prepayment
    },
    individualPrepayment: ([counterparty, prepayment]) => {
        return counterparty === 'Физ. лицо' && prepayment
    },
    individualWithoutPrepaymentContract: ([counterparty, prepaymentType, contract]) => {
        return counterparty === 'Физ. лицо' && prepaymentType && prepaymentType !== 'Внесена' && contract
    },
    individualPrepaymentContract: ([counterparty, prepaymentType, contract]) => {
        return counterparty === 'Физ. лицо' && prepaymentType && prepaymentType === 'Внесена' && contract
    },
    /** Физ. лицо предоплата внесена без договора */
    individualPrepaymentNotContract: ([counterparty, prepaymentType, contractType]) => {
        return counterparty === 'Физ. лицо' && prepaymentType && prepaymentType === 'Внесена' && contractType === 'Без договора'
    },
    /** Физ. лицо предоплата не внесена без договора */
    individualNotPrepaymentNotContract: ([counterparty, prepaymentType, contractType]) => {
        return counterparty === 'Физ. лицо' && prepaymentType && prepaymentType !== 'Внесена' && contractType === 'Без договора'
    },
    individualPrepaymentKP: ([counterparty, prepayment, kp]) => {
        return counterparty === 'Физ. лицо' && prepayment && kp
    },
    individualPrepaymentInspection: ([counterparty, prepayment, inspection]) => {
        return counterparty === 'Физ. лицо' && prepayment && inspection
    },
    individualPrepaymentInspectionKP: ([counterparty, prepayment, inspection, kp]) => {
        return counterparty === 'Физ. лицо' && prepayment && inspection && kp
    },
    inspection: ([inspection]) => {
        return !!inspection
    },
    inspectionKP: ([inspection, KP]) => {
        return !!inspection && !!KP
    },
    /** Юр. лицо Счет */
    entityLess100kPrepayment100: ([counterparty, totalCost, prepayment, prepaymentType]) => {
        return counterparty === 'Юр. лицо' && Number(totalCost) < 100000 && Number(prepayment) === 100 && prepaymentType === 'Внесена'
    },
    /** Юр. лицо Счет коммерческие помещения */
    entityLess100kPrepayment100Commercial: ([counterparty, totalCost, prepayment, prepaymentType, buildingType, serviceType]) => {
        return counterparty === 'Юр. лицо' &&
            Number(totalCost) < 100000 &&
            Number(prepayment) === 100 &&
            prepaymentType === 'Внесена' &&
            buildingType === 'Коммерческий объект' &&
            serviceType === 'Ежедневная уборка (обслуживание)'
    },
    entityLess100kPrepayment100NotContract: ([counterparty, totalCost, prepayment, prepaymentType, contractType]) => {
        return counterparty === 'Юр. лицо' && Number(totalCost) < 100000 && Number(prepayment) === 100 && prepaymentType === 'Внесена' && contractType === 'Без договора'
    },
    entityLess100kPrepayment100Contract: ([counterparty, totalCost, prepayment, prepaymentType, contract]) => {
        return counterparty === 'Юр. лицо' && Number(totalCost) < 100000 && Number(prepayment) === 100 && prepaymentType === 'Внесена' && contract
    },
    entityContractBillWithContract: ([counterparty, contract, contractType]) => {
        return counterparty === 'Юр. лицо' && contractType === 'Счет договор' && contract
    },
    entityContractBillWithoutContract: ([counterparty, contract, contractType]) => {
        return counterparty === 'Юр. лицо' && contractType === 'Счет договор' && !contract
    },
    entityLess100kPrepayment100KP: ([counterparty, totalCost, prepayment, kp, prepaymentType]) => {
        return counterparty === 'Юр. лицо' && Number(totalCost) < 100000 && Number(prepayment) === 100 && kp && prepaymentType === 'Внесена' // TODO separate percent and number in prepayment
    },
    entityLess100kPrepayment100Inspection: ([counterparty, totalCost, prepayment, reportDate, prepaymentType]) => {
        return counterparty === 'Юр. лицо' && Number(totalCost) < 100000 && Number(prepayment) === 100 && reportDate && prepaymentType === 'Внесена'
    },
    entityLess100kPrepayment100InspectionKP: ([counterparty, totalCost, prepayment, reportDate, kp, prepaymentType]) => {
        return counterparty === 'Юр. лицо' && Number(totalCost) < 100000 && Number(prepayment) === 100 && reportDate && kp && prepaymentType === 'Внесена'
    },
    /** Юр. лицо коммерческие помещения */
    entityCommercial: ([counterparty, buildingType, serviceType]) => {
        return counterparty === 'Юр. лицо' && buildingType === 'Коммерческий объект' && serviceType === 'Ежедневная уборка (обслуживание)'
    },
    /** Юр. лицо */
    entity: ([counterparty]) => {
        return counterparty === 'Юр. лицо'
    },
    /** Юр. лицо без контракта */
    entityNotContract: ([counterparty, contractType]) => {
        return counterparty === 'Юр. лицо' && contractType === 'Без договора'
    },
    entityPrepaymentNotContract: ([counterparty, prepaymentType, contractType]) => {
        return counterparty === 'Юр. лицо' && prepaymentType === 'Внесена' && contractType === 'Без договора'
    },
    entityKP: ([counterparty, kp]) => {
        return counterparty === 'Юр. лицо' && kp
    },
    entityInspection: ([counterparty, inspection]) => {
        return counterparty === 'Юр. лицо' && inspection
    },
    entityInspectionKP: ([counterparty, inspection, kp]) => {
        return counterparty === 'Юр. лицо' && inspection && kp
    },
    KP: ([kp]) => {
        return !!kp
    },
    NZ: ([nz]) => {
        return !!nz
    },
    NZEntity: ([nz, counterparty]) => {
        return !!nz && counterparty === 'Юр. лицо'
    },
    NZIndividual: ([nz, counterparty]) => {
        return !!nz && counterparty === 'Физ. лицо'
    },
    NotNZ: ([nz]) => {
        return !nz
    },
    ContractWorkEntity: ([counterparty, workType]) => {
        return workType === 'Подрядчик' && counterparty === 'Юр. лицо'
    },
    ContractWorkIndividual: ([counterparty, workType]) => {
        return workType === 'Подрядчик' && counterparty === 'Физ. лицо'
    },
    MaintenanceEntity: ([counterparty, serviceType]) => {
        return counterparty === 'Юр. лицо' && serviceType === 'Ежедневная уборка (обслуживание)'
    },
    MaintenanceIndividual: ([counterparty, serviceType]) => {
        return counterparty === 'Физ. лицо' && serviceType === 'Ежедневная уборка (обслуживание)'
    },
    NotNZNotCoordinationIndividual: ([nz, dateType, counterparty]) => {
        return !nz && dateType !== 'По согласованию' && counterparty === 'Физ. лицо'
    },
    NotNZNotCoordinationEntity: ([nz, dateType, counterparty]) => {
        return !nz && dateType !== 'По согласованию' && counterparty === 'Юр. лицо'
    },
    /** Повтор */

    /** Повтор юр. лицо счет коммерческие помещения */
    repeatEntityBillCommercial: ([isRepeat, counterparty, totalCost, prepayment, prepaymentType, buildingType, serviceType, clientType]) => {
        if (clientType) {
            return (!!isRepeat || clientType === 'Действующий') &&
                counterparty === 'Юр. лицо' &&
                Number(totalCost) < 100000 &&
                Number(prepayment) === 100 &&
                prepaymentType === 'Внесена' &&
                buildingType === 'Коммерческий объект' &&
                serviceType === 'Ежедневная уборка (обслуживание)'
        }
        return !!isRepeat &&
            counterparty === 'Юр. лицо' &&
            Number(totalCost) < 100000 &&
            Number(prepayment) === 100 &&
            prepaymentType === 'Внесена' &&
            buildingType === 'Коммерческий объект' &&
            serviceType === 'Ежедневная уборка (обслуживание)'
    },
    /** Повтор юр. лицо счет */
    repeatEntityBill: ([isRepeat, counterparty, totalCost, prepayment, prepaymentType, clientType]) => {
        if (clientType) {
            return (!!isRepeat || clientType === 'Действующий') && counterparty === 'Юр. лицо' && Number(totalCost) < 100000 && Number(prepayment) === 100 && prepaymentType === 'Внесена'
        }
        return !!isRepeat && counterparty === 'Юр. лицо' && Number(totalCost) < 100000 && Number(prepayment) === 100 && prepaymentType === 'Внесена'
    },
    /** Повтор юр. лицо коммерческие помещения */
    repeatEntityCommercial: ([isRepeat, counterparty, buildingType, serviceType, clientType]) => {
        if (clientType) {
            return (!!isRepeat || clientType === 'Действующий') &&
                counterparty === 'Юр. лицо' &&
                buildingType === 'Коммерческий объект' &&
                serviceType === 'Ежедневная уборка (обслуживание)'
        }
        return !!isRepeat &&
            counterparty === 'Юр. лицо' &&
            buildingType === 'Коммерческий объект' &&
            serviceType === 'Ежедневная уборка (обслуживание)'
    },
    /** Повтор юр. лицо */
    repeatEntity: ([isRepeat, counterparty, clientType]) => {
        if (clientType) {
            return (!!isRepeat || clientType === 'Действующий') && counterparty === 'Юр. лицо'
        }
        return !!isRepeat && counterparty === 'Юр. лицо'
    },
    repeat: ([isRepeat, clientType]) => {
        if (clientType) {
            return !!isRepeat || clientType === 'Действующий'
        }
        return !!isRepeat
    },
    entityRoleOperator: ([counterparty], role) => {
        return counterparty === 'Юр. лицо' && role === 'OPERATOR'
    },
    entityRoleOrderManager: ([counterparty], role) => {
        return counterparty === 'Юр. лицо' && role === 'ORDER_MANAGER'
    },
    withCustomContract: ([contractType, contract], role) => {
        return contractType === 'Подгружаемый' && !!contract
    },
    withoutCustomContract: ([contractType, contract], role) => {
        return contractType === 'Подгружаемый' && !contract
    },
    withCustomContractPrepayment: ([prepaymentType, contractType, contract], role) => {
        return contractType === 'Подгружаемый' && !!contract && prepaymentType === 'Внесена'
    },
    withCustomContractWithoutPrepayment: ([prepaymentType, contractType, contract], role) => {
        return contractType === 'Подгружаемый' && !!contract && prepaymentType !== 'Внесена'
    },
}
