import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { useDispatch } from 'react-redux'
import appReducer from './slices/appSlice/appSlice'
import archiveReducer from './slices/ArchiveSlice/ArchiveSlice'
import clientReducer from './slices/clientSlice/clientSlice'
import filterReducer from './slices/FilterSlice/FilterSlice'
import kpoReducer from './slices/kpoSlice/kpoSlice'
import kpReducer from './slices/kpSlice/kpSlice'
import notificationReducer from './slices/notificationSlice/notificationSlice'
import orderReducer from './slices/orderSlice/orderSlice'
import popupReducer from './slices/popupSlice/popupSlice'
import salaryReducer from './slices/salarySlice/salarySlice'
import serviceSmetaReducer from './slices/serviceSmetaSlice/serviceSmetaSlice'
import smetaReducer from './slices/smetaSlice/smetaSlice'
import staffScheduleReducer from './slices/staffScheduleSlice/staffScheduleSlice'
import staffReducer from './slices/staffSlice/staffSlice'
import { loadState, saveState } from './util/localStorage'
import { rtkApi } from '~/shared/api/rtkApi'
import { throttle } from '~/shared/util/throttle'

const rootReducer = combineReducers({
    app: appReducer,
    order: orderReducer,
    notification: notificationReducer,
    popup: popupReducer,
    staff: staffReducer,
    archive: archiveReducer,
    staffSchedule: staffScheduleReducer,
    filter: filterReducer,
    salary: salaryReducer,
    smeta: smetaReducer,
    kpo: kpoReducer,
    kp: kpReducer,
    serviceSmeta: serviceSmetaReducer,
    client: clientReducer,
    [rtkApi.reducerPath]: rtkApi.reducer,
})

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(rtkApi.middleware),
    preloadedState: loadState(),
})
store.subscribe(
    throttle(() => {
        saveState({ filter: store.getState().filter })
    }, 1000),
)

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch<typeof store.dispatch>()

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            })
                .concat(rtkApi.middleware),
        preloadedState,
    })
}

export type AppStore = ReturnType<typeof setupStore>

export default store

