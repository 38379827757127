import clsx from 'clsx'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { InputParamsItem, InputTemplateType } from '../../../../hooks/useFormConstructor/useFormConstructor.types'
import CustomCheckbox, { CustomCheckboxTheme } from '../../checkbox/CustomCheckbox/CustomCheckbox'
import '../../checkbox/CustomCheckboxGroup/CustomCheckboxGroup.scss'

interface CustomRadioGroupProps {
    title?: string,
    className?: string,
    items?: InputParamsItem<any>[] | Record<string, InputParamsItem<any>[]> | InputTemplateType,
    value: any,
    onChange: (value: any) => void,
    error?: FieldError,
    disabled: boolean,
}

const CustomRadioGroup = (props: CustomRadioGroupProps) => {
    const {
        title,
        className,
        items = [],
        value,
        onChange,
        error,
        disabled,
    } = props

    const handleChange = (value: any) => {
        onChange(value)
    }

    return (
        <div
            className={clsx('customCheckboxGroup customField', className, {
                error,
            },
            )}>
            {title && <h5>{title}</h5>}
            <div className='customCheckboxGroup__body'>
                {Object.values(items).map((item) =>
                    <CustomCheckbox
                        caption={item.caption}
                        name={item.name}
                        id={`checkbox_${item.name}`}
                        key={`checkbox_${item.name}`}
                        value={value === item.value}
                        onChange={() => handleChange(item.value)}
                        disabled={disabled}
                        theme={CustomCheckboxTheme.ROUND}
                    />,
                )}
            </div>
        </div>
    )
}

export default CustomRadioGroup
