import './Dashboard.scss'
import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { dashboardRouteConfig } from './dashboardConfig'
import { SELECT_BODY_HIDDEN, selectUser } from '~/redux/selectors/appSlice.selectors'
import { rules } from '~/shared/order/rules'

const Dashboard = () => {
    const location = useLocation()
    const bodyHidden = useSelector(SELECT_BODY_HIDDEN)
    const dashboardBody = useRef<HTMLDivElement>(null)
    const user = useSelector(selectUser)

    useEffect(() => {
        dashboardBody.current?.scrollTo({
            top: 0,
        })
    }, [location.pathname])

    return (
        <div className='dashboard'>
            <div
                ref={dashboardBody}
                className={clsx('dashboard__body', {
                    hidden:
                        bodyHidden === 'hidden' && dashboardBody.current &&
                        dashboardBody.current.scrollHeight > dashboardBody.current.clientHeight,
                })}
            >
                <Routes>
                    {Object.values(dashboardRouteConfig).map((
                        { path, element, rulesCheck }, index) => {
                        if (user?.division.includes('B2B (ППО)') && user.division.length === 1 && rulesCheck === 'schedule') {
                            return null
                        }
                        if (rulesCheck && user && !rules[user.role]?.pages[rulesCheck]) {
                            return null
                        }

                        return (
                            <Route
                                key={`routeIndex${index}__path${path}`}
                                path={path}
                                element={element}
                            />
                        )
                    })}
                </Routes>
            </div>
        </div>
    )
}

export default Dashboard
