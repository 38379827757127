import '../../App.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import MobileDashboard from '../../../mobile/MobileDashboard/MobileDashboard'
import MobileHeader from '../../../mobile/MobileHeader/MobileHeader'
import MobileNavbar from '../../../mobile/MobileNavbar/MobileNavbar'
import PopupLoader from '~/components/common/preloaders/PopupLoader/PopupLoader'
import { selectIsPageError, selectUser } from '~/redux/selectors/appSlice.selectors'

const MobileWrapper = () => {
    const location = useLocation()
    const user = useSelector(selectUser)
    const isPageError = useSelector(selectIsPageError)

    return (
        <>
            {user ? (
                <div className='App__mobile'>
                    {(location.pathname !== '/more' && !isPageError) && <MobileHeader />}
                    <MobileDashboard />
                    {!isPageError && <MobileNavbar />}
                </div>
            ) : (
                <PopupLoader />
            )}
        </>

    )
}

export default MobileWrapper
