import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Popover } from '@mui/material'
import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ClientResponse } from '~/API/clientApi/client.types'
import { useLazyFetchClientOrderQuery, useLazyFetchOrderQuery } from '~/API/orderApi/order'
import {
    ClientAddressType,
    CustomClientInputActions,
} from '~/components/common/inputs/CustomClientInput/CustomClientInput.types'
import {
    clientPopupBody,
    clientPopupFooter,
} from '~/components/common/inputs/CustomClientInput/lib/CustomClientInput.helpers'
import {
    ItemType,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientOrganization/ClientOrganization.types'
import { ClientTypes } from '~/components/common/inputs/CustomClientInput/subComponents/ClientType/ClientType'
import { CustomSelectThemes } from '~/components/common/inputs/CustomSelect/CustomSelect'
import { CustomPopup } from '~/components/common/ReusingComponents/CustomPopup/CustomPopup'
import MobileCustomSelect from '~/components/mobile/mobileCommon/mobileInputs/MobileCustomSelect/MobileCustomSelect'
import { InputParamsItem } from '~/hooks/useFormConstructor/useFormConstructor.types'
import '../CustomSelect/CustomSelect.scss'
import './CustomClientInput.scss'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { selectActiveOrder } from '~/redux/selectors/orderSlice.selectors'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import {
    setClientAction,
    setClientAddress,
    setClientInfo,
    setClientPopupOpen,
    setClientPopupTitle,
    setClientStage,
    setClientType,
} from '~/redux/slices/clientSlice/clientSlice'
import {
    selectClientAction,
    selectClientAddress,
    selectClientInfo,
    selectClientPopup,
    selectClientServices,
    selectClientStage,
    selectClientTitle,
    selectClientType,
} from '~/redux/slices/clientSlice/clientSlice.selectors'
import { setActiveOrder, setOrderLoader, setStageName } from '~/redux/slices/orderSlice/orderSlice'
import { useAppDispatch } from '~/redux/store'
import { getGlobalDivision } from '~/shared/util/localStorage'

interface CustomClientInputProps {
    title?: string
    items: InputParamsItem<CustomClientInputActions>[]
    name?: string
    value?: string | boolean | null
    className?: string
    onChange: (e: any) => void
    error?: any
    disabled?: boolean
    placeholder?: string
    icon?: any
    inputName?: string
    theme?: CustomSelectThemes
}

export const CustomClientInput = memo((props: CustomClientInputProps) => {
    const {
        title,
        items,
        name,
        value,
        className,
        onChange,
        error,
        disabled,
        placeholder,
        icon,
        inputName,
        theme = CustomSelectThemes.OUTLINE,
    } = props

    const {
        setValue,
        getValues,
        clearErrors,
        reset,
    } = useFormContext()

    const dispatch = useAppDispatch()

    const user = useSelector(selectUser)
    const activeOrder = useSelector(selectActiveOrder)
    const isClientPopupOpen = useSelector(selectClientPopup)
    const clientAction = useSelector(selectClientAction)
    const clientType = useSelector(selectClientType)
    const clientTitle = useSelector(selectClientTitle)
    const clientInfo = useSelector(selectClientInfo)
    const clientAddress = useSelector(selectClientAddress)
    const clientServices = useSelector(selectClientServices)
    const clientStage = useSelector(selectClientStage)

    const [fetchClientOrder] = useLazyFetchClientOrderQuery()
    const [fetchOrder] = useLazyFetchOrderQuery()

    const selectorRefForWidth = useRef<HTMLDivElement>(null)
    const ref = useRef<HTMLDivElement>(null)

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const [active, setActive] = useState(false)
    const [blockWidth, setBlockWidth] = useState<number | null>(null)

    const handleClick = (value: string | Date | Dayjs | null | boolean, action: CustomClientInputActions = CustomClientInputActions.NONE) => {
        dispatch(setClientAction(action))
        switch (action) {
            case CustomClientInputActions.ACTIVE: {
                dispatch(setClientStage({ current: 1, amount: 3 }))
                dispatch(setClientPopupOpen(true))
                break
            }
            case CustomClientInputActions.REPEAT: {
                dispatch(setClientStage({ current: 1, amount: 1 }))
                dispatch(setClientPopupOpen(true))
                break
            }
            default: {
                break
            }
        }
        handleClose()
        inputName && clearErrors && clearErrors(inputName)
        onChange && onChange(value)
    }

    const handleSetClientType = useCallback((clientType: ClientTypes) => {
        dispatch(setClientType(clientType))
    }, [])

    const handleSetClientInfo = useCallback((info: ClientResponse<ItemType>) => {
        dispatch(setClientInfo(info))
    }, [])

    const handleSetClientAddress = useCallback((address: ItemType) => {
        dispatch(setClientAddress(address))
    }, [])

    const handleSetValues = (order: any) => {
        let newOrder
        switch (clientAction) {
            case CustomClientInputActions.REPEAT: {
                newOrder = {
                    id: getValues('id'),
                    stageName: 'REPEAT_CLIENT_STARTING',
                    section: 'ACCOUNTING_AND_SELLING',
                    businessType: getValues('primaryInformation.division'),
                    primaryInformation: {
                        ...getValues('primaryInformation'),
                        isRepeat: true,
                    },
                    inspection: order?.inspection,
                    accountingAndSelling: {
                        ...order?.accountingAndSelling,
                        division: getGlobalDivision(),
                        commercialOffer: {
                            ...order.accountingAndSelling.commercialOffer,
                            services: clientServices?.services,
                        },
                    },
                    agreement: {
                        ...order?.agreement,
                        cleaningDate: null,
                    },
                    documents: {
                        accountingAndSelling: order?.documents?.accountingAndSelling,
                        agreement: order?.documents?.agreement,
                        inspection: order?.documents?.inspection,
                    },
                }
                dispatch(setStageName('REPEAT_CLIENT_STARTING'))
                dispatch(setActiveOrder(newOrder))
                dispatch(changeFormAction({ action: 'SAVE_FORM' }))
                return
            }
            case CustomClientInputActions.ACTIVE: {
                newOrder = {
                    id: getValues('id'),
                    section: 'PRIMARY',
                    primaryInformation: {
                        ...order.primaryInformation,
                        orderCreatedAt: new Date(),
                        clientType: value,
                        orderCreator: user?.id,
                        division: getGlobalDivision(),
                        isRepeat: false,
                        crmLink: null,
                    },
                    documents: {
                        primaryInformation: {
                            proxyAuthority: order?.documents?.primaryInformation?.proxyAuthority,
                        },
                    },
                    businessType: getGlobalDivision(),
                }
                dispatch(setActiveOrder(newOrder))
                return
            }
            default:
                return
        }
    }

    const handleClosePopup = useCallback(() => {
        dispatch(setClientAction(CustomClientInputActions.NONE))
        dispatch(setClientPopupOpen(false))
    }, [clientAction])

    const handleSetStage = (value: 'prev' | 'next' | 'end', stage: number) => {
        switch (value) {
            case 'prev': {
                dispatch(setClientStage({
                    ...clientStage,
                    current: clientStage.current - 1,
                }))
                switch (stage) {
                    case 2: {
                        dispatch(setClientInfo({} as ClientResponse<ItemType>))
                        return
                    }
                    case 3: {
                        dispatch(setClientAddress({} as ClientAddressType))
                        return
                    }
                }
                return
            }
            case 'next': {
                dispatch(setClientStage({
                    ...clientStage,
                    current: clientStage.current + 1,
                }))
                switch (stage) {
                    case 1: {
                        dispatch(setClientInfo({} as ClientResponse<ItemType>))
                        return
                    }
                    case 2: {
                        dispatch(setClientAddress({} as ClientAddressType))
                        return
                    }
                }
                return
            }
            case 'end': {
                clearErrors([
                    'primaryInformation.counterparty',
                    'primaryInformation.organization',
                    'primaryInformation.organizationRequisites.bankName',
                    'primaryInformation.organizationRequisites.bankId',
                    'primaryInformation.organizationRequisites.bankAccount',
                    'primaryInformation.organizationRequisites.correspondentAccount',
                    'primaryInformation.contactInfo',
                    'primaryInformation.address',
                    'primaryInformation.city',
                ])
                setValue('primaryInformation.counterparty', clientType)
                // if (clientType !== ClientTypes.ENTITY) {
                //     setValue('primaryInformation.contactInfo', [{
                //         ...getValues().primaryInformation.contactInfo,
                //         clientPhone: clientInfo.additionalParams.value,
                //     }])
                // }
                handleClosePopup()

                if (clientInfo.id && clientAddress.value) {
                    dispatch(setOrderLoader(true))
                    switch (clientAction) {
                        case CustomClientInputActions.REPEAT: {
                            fetchOrder(clientServices.id, true).unwrap()
                                .then((res) => {
                                    handleSetValues(res)
                                })
                                .finally(() => {
                                    dispatch(setOrderLoader(false))
                                })
                            break
                        }
                        default: {
                            fetchClientOrder({ clientId: clientInfo.id, address: clientAddress.text }, true).unwrap()
                                .then((res) => {
                                    handleSetValues(res)
                                })
                                .finally(() => {
                                    dispatch(setOrderLoader(false))
                                })
                            break
                        }
                    }
                }
                return
            }
        }
    }

    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!disabled) {
            if (!active) {
                setAnchorEl(event.currentTarget)
                setActive(true)
            } else {
                handleClose()
            }
        }
    }

    const handleClose = () => {
        setActive(false)
        setAnchorEl(null)
    }

    useEffect(() => {
        if (selectorRefForWidth.current) {
            setBlockWidth(selectorRefForWidth.current.offsetWidth)
        }
    }, [active])

    useEffect(() => {
        if (clientAction) {
            switch (clientAction) {
                case CustomClientInputActions.NONE:
                case CustomClientInputActions.ACTIVE: {
                    dispatch(setClientPopupTitle('Тип клиента'))
                    break
                }
            }
        }
    }, [clientAction])

    return (
        <>
            <BrowserView>
                <Box className={clsx('customField', className, { error, disabled })}>
                    {title && <span>{title}</span>}
                    <div
                        className={clsx('customField__select', theme && `customField__select--${theme}`)}
                        onClick={onClick}
                        ref={selectorRefForWidth}
                    >
                        {icon ? <p className='customField__select__icon'>{icon}</p> : ''}
                        {!!value && items.some((item) => item.value === value) ? (
                            <span>
                                {items.find((item) => item.value === value)?.text}
                            </span>
                        ) : !value ? (
                            <span className='customField__placeholder'>
                                {placeholder !== null && placeholder !== undefined ? placeholder : 'Выберите значение'}
                            </span>
                        ) : (
                            <span>
                                {value}
                            </span>
                        )}
                        <ExpandMore className={clsx({ active }, 'customField__expand')} />
                    </div>
                    <Popover
                        className='customSelect__popover'
                        open={active}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div
                            className='customSelect__popover-list'
                            style={{ width: blockWidth ? `${blockWidth}px` : 'auto' }}
                            ref={ref}
                        >
                            {items?.map((option, index) => (
                                <div className='customSelect__popover-option'
                                    key={name + '_option_' + index}
                                    onClick={() => handleClick(option.value, option?.action)}
                                >
                                    {option.text}
                                </div>
                            ),
                            )}
                        </div>
                    </Popover>
                </Box>
            </BrowserView>
            <MobileView>
                <MobileCustomSelect
                    {...props}
                    items={items}
                    value={value}
                    disabled={disabled}
                    onClick={handleClick}
                />
            </MobileView>
            {isClientPopupOpen && (
                <CustomPopup
                    className={clsx('custom-client-input__popup', { 'custom-client-input__popup--first-stage': clientStage.current === 1 })}
                    onClose={handleClosePopup}
                    title={{
                        title: clientTitle,
                        className: 'custom-client-input__header',
                    }} // TODO too many rerenders, think about
                    body={clientPopupBody({
                        clientStage,
                        clientType,
                        clientInfo,
                        clientAddress,
                        handleSetClientType,
                        handleSetClientInfo,
                        handleSetClientAddress,
                        clientAction,
                    })}
                    footer={clientPopupFooter({
                        clientServicesSize: clientServices?.services?.length,
                        clientAction,
                        clientStage,
                        clientType,
                        clientInfo,
                        clientAddress,
                        handleSetStage,
                    })}
                />
            )}
        </>
    )
})
