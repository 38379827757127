import { Dayjs } from 'dayjs'
import { ERROR_CODES, OrderInterface } from '~/API/orderApi/types/order.types'
import { Division } from '~/API/userApi/user.types'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import { statusItemsTranslate } from '~/components/pages/Staff/model/status'
import { IFilterProps } from '~/types/types.filter'
import { FilteredEvent } from '~/types/types.order'

export type OrderSliceInitialState = {
    activeMonthDate: Date,
    filteredEvents: FilteredEvent[]
    events: OrderEvents,
    currentCleanings: Cleaning[],
    emptySlotsObj: Record<string, BuildEmptySlots>
    emptySlotsUniqObj: Record<string, BuildEmptySlotsType>
    removedCleaningsObj: Record<string, string[]>
    filter:
        | {
        cleaningTypes: []
        divisions: Division[]
        events: {
            task: any[]
            cleaning: any[]
        }
        searchString: string
        serviceTypes: any[]
        employees: any[]
    }
        | IFilterProps
    activeEvent: null | any
    activeDate: {
        date: Date
        view?: string
    }
    activeDateTarget: ActiveDateTarget,
    order?: OrderInterface
    activeOrder?: OrderInterface
    formState: boolean
    orderLoader: boolean
    eventLoader: boolean
    prices: {
        name: string
        notes: string[]
        title: string
        values: {
            cost: number
            title: string
        }[]
    }[] | null
    division?: Division
    menuType: string
    dateRange: DateRange
    eventsWithCustomFilter: any[]
    eventsWithCustomFilterUniq: any[]
    staffFormMenu: 'FORM' | 'DOCS',
    error: any,
    sectionName: string
    stageName: StageType | ''
    isServicesSet: boolean
    isServiceDescriptionsSet: boolean
    eventEmitters: Record<typeof ERROR_CODES[number], boolean>
}

export enum MenuTypes {
    'MENU_MAIN' = 'MENU_MAIN',
    'MENU_DOCUMENTS' = 'MENU_DOCUMENTS',
    'MENU_REPORT' = 'MENU_REPORT',
    'MENU_RECLEANING' = 'MENU_RECLEANING',
}

export type DateRange = {
    start: Dayjs
    end: Dayjs
}

export interface OrderEvents {
    tasks: CleaningSlot[]
    emptySlots: BuildEmptySlotsType[]
    emptySlotsUniq: BuildEmptySlotsType[]
    closedSlots: CleaningSlot[]
    // emptySlots: Map<string, BuildEmptySlotsType>
    // emptySlotsUniq: Map<string, BuildEmptySlotsType>
}

export type ActiveDateTarget = 'toolbar' | 'scroll' | 'default'

export type BuildEmptySlots = Map<number, BuildEmptySlotsType>

export interface BuildEmptySlotsType {
    date: string
    orderType: string
    type: string
    stageGroup: string
    address: string
    start: Date
    end: Date
    amount: number
    removedCleaningId?: string
    key: string
    index: number
}

export type Cleaning = {
    address: string
    archived: boolean
    businessType: Division
    cleaningDate: {
        endDate: Date
        startDate: Date
    }
    cleaningType: string
    clientFullname: string
    clientPhone: string
    division: Division
    events: StageChangeEvent[]
    id: string
    nzStatuses: null
    organization: string
    responsibleUsers: string[]
    serviceType: string
    stageName: StageType
    endDate: string | Dayjs
    startDate: string | Dayjs
    section: string
    inspectionDate?: string
    isInspectionInPast?: boolean
    isDateBooked?: boolean
    orderCreator: string
    accountingAndSellingResponsibleUser?: string
    agreementResponsibleUser?: string
}

export interface StageChangeEvent {
    userId: string
    startDate: Date
    endDate: Date
    stageName: StageType
}

export type CleaningSlot = Cleaning & {
    stageGroup: string
    type?: 'maintenance' | 'glazing' | 'cleaning' | 'inspection' | 'empty' | 'complex' | 'handling'
    start: Date
    end: Date
    status: string
    inspectionDate?: string
    amount?: number
}

export type ScheduleObj = {
    id: string
    date: string
    division: Division
    personnel: SchedulePersonnel[]
    // end: Date
    // start: Date
}

export type SchedulePersonnel = {
    personnelId: string
    status: keyof typeof statusItemsTranslate,
}

export interface FileModelType {
    businessType?: string,
    contractNumber?: number,
    createdAt?: string | Date | Dayjs,
    fileInfo:
        {
            creator: string,
            fileName: string,
            inputName: string,
            title: string,
            type?: string,
            fileType?: string,
        }
}
