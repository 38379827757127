import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import GtSvg from '../../../../../assets/svg/gt.svg'
import LtSvg from '../../../../../assets/svg/lt.svg'
import { selectOrderActiveDate } from '~/redux/selectors/orderSlice.selectors'
import { fetchDate } from '~/shared/util/currentTime'

interface MobileDateToolbarProps {
    className: string,
    date: Date,
    onNavigatePrev: () => void,
    onNavigateNext: () => void,
}

const MobileDateToolbar = (props: MobileDateToolbarProps) => {
    const {
        className,
        date,
        onNavigatePrev,
        onNavigateNext,
    } = props
    const activeDate = useSelector(selectOrderActiveDate)
    const [dateString, setDateString] = useState<string>(fetchDate().format('MMMM YYYY'))

    useEffect(() => {
        setDateString(fetchDate(activeDate.date).format('MMMM YYYY'))
    }, [activeDate])

    return (
        <div className={clsx('mobileHeader__toolbar', className)}>
            <h3>{dateString.substring(0, 1).toUpperCase() + dateString.substring(1)}</h3>
            <div className='mobileHeader__toolbar__buttonGroup'>
                <button type='button' onClick={onNavigatePrev}>
                    <LtSvg />
                </button>
                <button type='button' onClick={onNavigateNext}>
                    <GtSvg />
                </button>
            </div>
        </div>
    )
}

export default MobileDateToolbar
