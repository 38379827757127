import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'
import clsx from 'clsx'
import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'
import { FaCalculator } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Preloader from '../../../preloaders/Preloader/Preloader'
import { AllowedFileTypes } from '../CustomFileInputGroup'
import { useFetchFileModelQuery, useUploadFileMutation } from '~/API/fileApi/file'
import { FileResponse } from '~/API/fileApi/file.types'
import {
    getDocumentRoute,
} from '~/components/common/inputs/CustomFileInputGroup/CustomFileInput/CustomFileInput.helpers'
import { CustomFileInputSingleTheme } from '~/components/common/inputs/CustomFileInputSingle/CustomFileInputSingle'
import DocumentManager from '~/components/common/ReusingComponents/DocumentManager/DocumentManager'
import LinkButton from '~/components/common/ReusingComponents/LinkButton/LinkButton'
import {
    MobilePhotoViewer,
} from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobilePhotoViewer/MobilePhotoViewer'
import { isFileImage } from '~/components/pages/Order/subComponents/OrderDocuments/documentLib'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { selectActiveOrder } from '~/redux/selectors/orderSlice.selectors'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { fetchDate } from '~/shared/util/currentTime'
import { isMobile } from '~/shared/util/deviceDetect'
import { chooseSvg } from '~/shared/util/svg'
import './CustomFileInput.scss'

export enum DocumentsButton {
    KPO = 'kpo',
    KPO_STARTING = 'kpo-starting',
    KP = 'kp',
    NZ = 'nz',
    SERVICE_SMETA = 'serviceSmeta',
    FTZ = 'ftz'
}

interface CustomFileInputProps {
    fileId?: string
    fileInputRef?: any
    title?: string
    onChange: (value: any) => void
    onClick?: (value: string) => void
    multiple?: boolean
    button?: string
    name: string
    style?: CSSProperties
    fileType?: string
    accept?: string
    fileRules?: any
    className?: string
    error?: FieldError
    disabled?: boolean
    allowedFileTypes?: AllowedFileTypes[]
    theme?: CustomFileInputSingleTheme
    documentButton?: DocumentsButton
    isDocumentEditable?: boolean
    editableText?: string
    withCheckForm?: string[]
    roles?: string[]
    hideInput?: boolean
}

const CustomFileInput = (props: CustomFileInputProps) => {
    const {
        fileId,
        fileInputRef,
        title,
        onChange,
        onClick,
        multiple,
        button,
        name,
        style,
        fileType,
        accept,
        fileRules,
        className,
        error,
        disabled,
        allowedFileTypes,
        theme = CustomFileInputSingleTheme.DEFAULT,
        documentButton,
        isDocumentEditable,
        editableText = 'Редактировать',
        withCheckForm,
        roles,
        hideInput,
    } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const {
        data: fileData,
        isFetching,
    } = useFetchFileModelQuery({ fileId: fileId || '' }, {
        skip: !fileId,
        refetchOnFocus: false,
        refetchOnMountOrArgChange: true,
    })
    const [uploadFile] = useUploadFileMutation()

    const user = useSelector(selectUser)
    const activeOrder = useSelector(selectActiveOrder)

    const [fileInfo, setFileInfo] = useState<any>(null)
    const [active, setActive] = useState(false)
    const [loader, setLoader] = useState(false)
    const [isViewerOpen, setViewerOpen] = useState<boolean>(false)

    const methods = useFormContext()

    useEffect(() => {
        let isMounted = true
        if (!fileId) {
            if (isMounted) {
                setFileInfo(null)
            }
        }
        return () => {
            isMounted = false
        }
    }, [fileId])

    useEffect(() => {
        if (fileData) {
            setFileInfo(fileData.fileInfo)
        }
    }, [fileData])

    useEffect(() => {
        let isMounted = true
        isMounted && setLoader(isFetching)
        return () => {
            isMounted = false
        }
    }, [isFetching])

    // Загрузить файл
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation()
        if (multiple) {
            // @ts-ignore todo check types
            const fileArray = [...e.target.files]
            const ps: Promise<FileResponse>[] = []
            setLoader(true)
            fileArray.forEach((file) => {
                const fileModel = {
                    fileInfo: {
                        fileName: file.name || '',
                        title: title || 'Файл',
                        inputName: name,
                        creator: user?.id || '',
                        fileType: file.type,
                    },
                    createdAt: fetchDate().toDate(),
                }
                if (Array.isArray(allowedFileTypes) && allowedFileTypes.includes(DocumentManager.getDocumentType(file.name))) {
                    ps.push(uploadFile({ file, fileModel }).unwrap())
                } else {
                    dispatch(SHOW_NOTICE({ type: 'error', message: 'Недопустимый формат файла' }))
                }
            })
            Promise.all(ps)
                .then((values) => {
                    onChange(values.map((res) => res.fileId))
                })
                .finally(() => setLoader(false))
        } else {
            const file = e.target.files?.[0]
            const fileModel = {
                fileInfo: {
                    fileName: file?.name || '',
                    title: title || 'Файл',
                    inputName: name,
                    creator: user?.id || '',
                    fileType: file?.type || '',
                },
                createdAt: fetchDate(),
            }
            if (file && Array.isArray(allowedFileTypes) &&
                allowedFileTypes.includes(DocumentManager.getDocumentType(file?.name))
            ) {
                setLoader(true)
                uploadFile({ file, fileModel }).unwrap()
                    .then((data) => {
                        onChange(data.fileId)
                    })
                    .finally(() => setLoader(false))
            } else {
                dispatch(SHOW_NOTICE({ type: 'error', message: 'Недопустимый формат файла' }))
            }
        }
    }

    const handleClick = () => { // sorry for that function todo make it doesn't look like shit
        if (isMobile && fileId && fileData) {
            if (!fileType) {
                if (!isFileImage(DocumentManager.getDocumentFormat(fileData.fileInfo.fileName))) {
                    setViewerOpen(true)
                } else {
                    onClick && onClick(fileId || '')
                }
            } else if (!isFileImage(fileType)) {
                setViewerOpen(true)
            } else {
                onClick && onClick(fileId || '')
            }
        } else {
            onClick && onClick(fileId || '')
        }
    }

    const handleClose = useCallback(() => {
        setViewerOpen(false)
    }, [])

    return (
        <>
            <div
                className={clsx('customFileInput', className, { error })}
                ref={fileInputRef}
            >
                {theme === CustomFileInputSingleTheme.DEFAULT && !multiple && title && <h5>{title}</h5>}
                <div className='customFileInput__container'>
                    {!hideInput && <div
                        className={clsx('customFileInput__wrapper', theme && `customFileInput--${theme}`, { disabled })}
                        style={style || {}}
                    >
                        {fileInfo ? (
                            <>
                                {loader ? (
                                    <Preloader size='small' />
                                ) : (
                                    <div
                                        className={clsx('customFileInput__input', theme && `customFileInput__input--${theme}`, { disabled })}
                                    >
                                        <div
                                            className='customFileInput__docInfo'
                                            onClick={handleClick}
                                        >
                                            {chooseSvg(fileInfo.fileName.split('.')[1])}
                                            <span>{fileInfo.fileName}</span>
                                        </div>
                                        <button
                                            className={clsx('customFileInput__popup', theme && `customFileInput__popup--${theme}`)}
                                            type='button'
                                            onClick={handleClick}
                                        >
                                            <VisibilityIcon className='eyeIcon' />
                                            <span>Посмотреть</span>
                                        </button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {loader ? (
                                    <Preloader size='small' />
                                ) : (
                                    <>
                                        <input
                                            type={documentButton ? 'button' : 'file'}
                                            multiple={multiple}
                                            id={'file-input_' + name}
                                            onChange={(e) => {
                                                if (!documentButton) {
                                                    handleChange(e)
                                                }
                                            }}
                                            onClick={() => {
                                                if (documentButton && !activeOrder?.archived) {
                                                    if (withCheckForm) {
                                                        methods.trigger(withCheckForm)
                                                            .then((res) => res && navigate(getDocumentRoute(documentButton, activeOrder?.id ?? ''), {
                                                                state: {
                                                                    alwaysSave: true,
                                                                    title: 'Сохранить внесенные изменения?',
                                                                },
                                                            }))
                                                    } else {
                                                        navigate(getDocumentRoute(documentButton, activeOrder?.id ?? ''), {
                                                            state: {
                                                                alwaysSave: true,
                                                                title: 'Сохранить внесенные изменения?',
                                                            },
                                                        })
                                                    }
                                                }
                                            }}
                                            disabled={disabled || activeOrder?.archived}
                                            name={name}
                                            accept={accept || '*'}
                                        />
                                        {fileRules?.write ? (
                                            <div
                                                className={clsx({
                                                    'customFileInput__input': fileRules?.write, error, disabled,
                                                })}
                                                onClick={() => !disabled && setActive(true)}
                                            >
                                                <AddIcon className='customFileInput__add' />
                                                {theme === CustomFileInputSingleTheme.BUTTON ? (
                                                    <span>Загрузить файл</span>
                                                ) : (
                                                    <span>{style?.width === 40 ? '' : button || 'Загрузить файлы '}</span>
                                                )}
                                            </div>
                                        ) : (
                                            <label
                                                htmlFor={'file-input_' + name}
                                                className={clsx('customFileInput__label', { error, disabled })}
                                            >
                                                <AddIcon className='customFileInput__add' />
                                                {theme === CustomFileInputSingleTheme.BUTTON ? (
                                                    <span>Загрузить файл</span>
                                                ) : (
                                                    <span>{style?.width === 40 ? '' : button || 'Загрузить файлы '}</span>
                                                )}
                                            </label>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>}
                    {active && fileRules?.write && (
                        <>
                            <div className='customField__wrapper' onClick={() => setActive(false)} />
                            <ul className='customField__optionList customField__documentOptions'>
                                {fileRules?.write && (
                                    <li onClick={() => navigate(`/order/${id}/${fileType}/${fileId || ''}`)}>
                                        заполнить
                                    </li>
                                )}
                                {fileRules?.upload && (
                                    <li>
                                        <label htmlFor={'file-input_' + name}>загрузить</label>
                                    </li>
                                )}
                            </ul>
                        </>
                    )}
                    {documentButton && isDocumentEditable &&
                        <LinkButton editableText={editableText} documentType={documentButton} />}
                </div>
            </div>
            {isViewerOpen && fileId && (
                <MobilePhotoViewer
                    handleClose={handleClose}
                    photoId={fileId}
                    type={'pdf'}
                />
            )}
        </>
    )
}
export default CustomFileInput
