import { UseFormGetValues } from 'react-hook-form'
import { Role } from '~/API/userApi/user.types'
import { User } from '~/API/userApi/user.types'

export const checkAdditionalRoles = (roles: Role[], user: User, getValues: UseFormGetValues<any>) => {
    if (user.id === getValues('primaryInformation.orderCreator') && user.role === 'ORDER_MANAGER' && roles.includes('OPERATOR') && !roles.includes('ORDER_MANAGER')) {
        roles.push('ORDER_MANAGER')
    }
    return roles
}
