import ExpandLess from '@mui/icons-material/ExpandLess'
import Collapse from '@mui/material/Collapse'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ClientServiceResponse } from '~/API/clientApi/client.types'
import './ClientServiceItemsSection.scss'
import {
    ClientServiceTable,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientService/subComponents/ClientServiceItems/ClientServiceTable/ClientServiceTable'
import { setClientServices } from '~/redux/slices/clientSlice/clientSlice'
import { selectClientServices } from '~/redux/slices/clientSlice/clientSlice.selectors'
import { useAppDispatch } from '~/redux/store'
import { fetchLocalDate } from '~/shared/util/currentTime'

interface ClientServiceItemsSectionProps {
    item: ClientServiceResponse
}

export const ClientServiceItemsSection = (props: ClientServiceItemsSectionProps) => {
    const {
        item,
    } = props

    const dispatch = useAppDispatch()

    const selectedItem = useSelector(selectClientServices)

    const [inStatus, setInStatus] = useState(false)

    const handleSetStatus = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setInStatus((prev) => !prev)
    }

    return (
        <div
            className={clsx('client-service-table-section', { 'client-service-table-section--active': selectedItem?.id === item.id })}
            onClick={() => dispatch(setClientServices(item))}
        >
            <div className='client-service-table-section__header'>
                <span>{fetchLocalDate(item.cleaningStartDate).format('DD.MM.YYYY')}</span>
                <span>{item?.serviceType}</span>
                <span>{item?.additionalServices?.map((service, index) => {
                    const additionalSymbol = item?.additionalServices?.length > index + 1 ? ', ' : ''
                    return `${service}${additionalSymbol}`
                })}</span>
                <span>{item?.cost}</span>
                <Link
                    className='client-service-table-section__button'
                    to={`/order/${item.id}`}
                    target='_blank'
                    onClick={(e) => e.stopPropagation()}
                >
                    {item?.description || 'Разовая уборка B2B'}
                </Link>
                <div
                    className='client-service-table-section__icon__container'
                    onClick={(e) => handleSetStatus(e)}
                >
                    <ExpandLess className={clsx({ 'client-service-table-section__icon--active': inStatus })} />
                </div>
            </div>
            <Collapse in={inStatus} timeout={100}>
                <ClientServiceTable data={item?.services} />
            </Collapse>
        </div>
    )
}
