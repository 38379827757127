import { UseFormGetValues } from 'react-hook-form'
import { useSaveOrderMutation } from '~/API/orderApi/order'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'

interface useUpdateOrderParams {
    clientType: ClientInfoEnum
}

export const useUpdateOrder = ({ clientType }: useUpdateOrderParams) => {
    const [saveOrder] = useSaveOrderMutation()

    return async (activeOrder: any, getValues: UseFormGetValues<any>) => {
        switch (clientType) {
            case ClientInfoEnum.CONTRACTOR: {
                return await saveOrder({
                    ...activeOrder,
                    stageName: activeOrder.stageName ? activeOrder.stageName : 'ORDER_CREATING',
                    starting: {
                        ...activeOrder.starting,
                        ...getValues('starting'),
                    },
                    documents: {
                        ...activeOrder.documents,
                        ...getValues('documents'),
                    },
                }).unwrap()
            }
            case ClientInfoEnum.ORGANIZATION: {
                return await saveOrder({
                    ...activeOrder,
                    stageName: activeOrder.stageName ? activeOrder.stageName : 'ORDER_CREATING',
                    primaryInformation: {
                        ...activeOrder.primaryInformation,
                        ...getValues('primaryInformation'),
                    },
                    documents: {
                        ...activeOrder.documents,
                        ...getValues('documents'),
                    },
                }).unwrap()
            }
        }
    }
}
