import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Collapse from '@mui/material/Collapse'
import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import DocumentCard from '../DocumentCard/DocumentCard'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import { OPEN_LIGHTBOX } from '~/redux/slices/appSlice/appSlice'
import { orderTypes } from '~/shared/util/types'

interface DocumentGroupProps {
    name: string,
    files: any,
    currentStage: StageType,
    setValue: UseFormSetValue<any>,
    getValues: UseFormGetValues<any>,
}

export const DocumentGroup = (props: DocumentGroupProps) => {
    const {
        name,
        files,
        currentStage,
        setValue,
        getValues,
    } = props
    const dispatch = useDispatch()
    const [active, setActive] = useState(true)
    const switchSection = () => {
        setActive((prev) => !prev)
    }

    const handleClick = useCallback((fileId: any, fileType: string) => {
        const index = files.findIndex((value: any) => value.id === fileId)
        if (index !== -1) {
            dispatch(OPEN_LIGHTBOX({ sourceIndex: index, sources: files.map((e: any) => e.id) }))
        }
    }, [files])

    const handleDelete = useCallback((file: {
        id: string,
        inputName: string,
        name: string,
    }) => {
        const fileName = file.name as keyof OrderInterface
        const files = getValues(fileName)
        if (Array.isArray(files)) {
            const newArray = [...files]
            const index = files.findIndex((e) => e === file.id || e?.fileId === file.id)
            if (index !== -1) {
                newArray.splice(index, 1)
                setValue(fileName, newArray)
            }
        } else {
            setValue(fileName, null)
        }
    }, [])

    return (
        <div className='orderDocuments__stage'>
            <div className='orderDocuments__stage__header'>
                <h4 onClick={switchSection}>{orderTypes[name as keyof typeof orderTypes]}</h4>
                <ArrowDropDownIcon onClick={switchSection} className={clsx({ active })} />
            </div>
            <Collapse in={active} timeout={100}>
                <div className='orderDocuments__docGroup'>
                    {files.map((file: any) => (
                        <DocumentCard
                            currentStage={currentStage}
                            file={file}
                            onClick={handleClick}
                            onDelete={handleDelete}
                            key={'DC_' + file.id}
                            getValues={getValues}
                        />
                    ))}
                </div>
            </Collapse>
        </div>
    )
}
