import React, { useMemo } from 'react'
import { Control, Controller, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { InputTypeEnum } from '../../enum/InputTypeEnum'
import { inputsWithDifferentName } from '../../lib/additionalFunctions/inputsWithDifferentName'
import { inputsWithOwnController } from '../../lib/additionalFunctions/inputsWithOwnController'
import { orderValidationRules } from '../../lib/additionalFunctions/orderValidationRules'
import { uniteFormSection } from '../../lib/additionalFunctions/uniteFormSection'
import WithUseFormWatch from '../../lib/additionalHOC/withUseFormWatch'
import { StyledInputWrapper } from '../../useFormConstructor.styled'
import { InputParams } from '../../useFormConstructor.types'
import { checkAdditionalRoles } from '~/hooks/useFormConstructor/lib/additionalFunctions/checkAdditionalRoles'
import { selectUser } from '~/redux/selectors/appSlice.selectors'


type InputFormConstructorProps = {
    input: InputParams
    inputsArrIndex: number
    inputIndex: number
    sectionKey: string
    control: Control<any>
    setValue: UseFormSetValue<any>
    getValues: UseFormGetValues<any>
    submitButtonHandler: () => void
    isArchived?: boolean
}

const InputFormConstructor = (props: InputFormConstructorProps) => {
    const {
        sectionKey,
        control,
        getValues,
        input,
        setValue,
        submitButtonHandler,
        isArchived,
    } = props

    const user = useSelector(selectUser)

    const inputsNamePath = inputsWithDifferentName(sectionKey, input.name)
    const inputChildWithoutController = useMemo(() => {
        const params = {
            ...input,
            control,
            setValue,
            name: inputsNamePath,
        }

        if (isArchived) {
            params.disabled = isArchived
        }

        return uniteFormSection({
            inputType: InputTypeEnum[input.type],
            inputParams: params,
        })
    }, [input])

    return (
        <>
            {inputsWithOwnController.has(input.type) ? (
                <StyledInputWrapper className={input.className}>
                    <WithUseFormWatch inputChild={inputChildWithoutController} />
                </StyledInputWrapper>
            ) : (
                <Controller
                    name={input.usingData ? input.usingData : inputsNamePath}
                    control={control}
                    rules={orderValidationRules(input)}
                    defaultValue={input.defaultValue ? input.defaultValue : input.defaultData && getValues(input.defaultData)}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        let roles = input?.roles
                        if (roles && user) {
                            roles = checkAdditionalRoles(roles, user, getValues)
                        }
                        const isDisabled = user ?
                                !!isArchived ||
                            (input?.userDependence && (user?.id !== getValues(input.userDependence))) ||
                            !roles?.includes(user.role) : true
                        let combinedProps = {
                            ...input,
                            value,
                            onChange,
                            error,
                            control,
                            setValue,
                        }

                        if (isArchived) {
                            combinedProps.disabled = isArchived
                        }

                        if (input.type === InputTypeEnum.action) { // добавляем функцию для action инпута
                            combinedProps = {
                                ...combinedProps,
                                submitButtonHandler,
                                disabled: user?.role === 'CHIEF' && !isArchived ? false : isDisabled,
                            }
                        }

                        const inputComponent = uniteFormSection({
                            inputType: InputTypeEnum[input.type],
                            inputParams: combinedProps,
                        })

                        return (
                            <StyledInputWrapper className={input.className}>
                                <WithUseFormWatch inputChild={inputComponent} />
                            </StyledInputWrapper>
                        )
                    }}
                />
            )}
        </>
    )
}

export default InputFormConstructor
