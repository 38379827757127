import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'

const SidebarPersonnelButtons = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <div className='orderSidebarBody'>
            <button
                className={'button button_fill-none'}
                onClick={() => {
                    dispatch(changeFormAction({ action: 'RESET_FORM' }))
                }}
            >
                Сбросить
            </button>
            <button
                className={'button button_bc-grey'}
                onClick={() => {
                    navigate(-1)
                }}
            >
                Вернуться
            </button>
        </div>
    )
}

export default SidebarPersonnelButtons
