import { DocumentsButton } from '~/components/common/inputs/CustomFileInputGroup/CustomFileInput/CustomFileInput'
import { getOrderServiceSmetaIndex } from '~/shared/order/orderUtil'

export const getDocumentRoute = (document: DocumentsButton, id: string, order?: any) => {
    switch (document) {
        case DocumentsButton.KPO:
            return `/order/${id}/kpo`
        case DocumentsButton.KPO_STARTING:
            return `/order/${id}/kpo-starting`
        case DocumentsButton.KP:
            return `/order/${id}/kp`
        case DocumentsButton.SERVICE_SMETA:
            const serviceIndex = getOrderServiceSmetaIndex(order)
            return typeof serviceIndex === 'number' ? `/order/${id}/service-smeta-starting` : `/order/${id}/kp`
        case DocumentsButton.FTZ:
            return `/order/${id}/ftz`
        default:
            return '/'
    }
}
