import { ReactElement } from 'react'
import {
    FaChartLine,
    FaCheck,
    FaFileAudio,
    FaFileImage,
    FaFilePdf,
    FaFileVideo, FaHourglassHalf,
    FaPlus,
    FaRegComment, FaRegDotCircle,
} from 'react-icons/fa'
import { FaClockRotateLeft, FaRegFileLines } from 'react-icons/fa6'
import { FiDroplet, FiLayers, FiPhoneCall, FiBriefcase } from 'react-icons/fi'
import { RiSuitcaseLine } from 'react-icons/ri'
import Broom from '../../assets/svg/broom.svg'
import Maintenance from '../../assets/svg/maintenance.svg'
import Window from '../../assets/svg/window.svg'
import DocumentManager from '~/components/common/ReusingComponents/DocumentManager/DocumentManager'

export const chooseSvg = (type: string): ReactElement => {
    if (DocumentManager.types.image.includes(type.toLowerCase())) {
        return <FaFileImage />
    }
    if (DocumentManager.types.application.includes(type.toLowerCase())) {
        return <FaFilePdf />
    }
    if (DocumentManager.types.video.includes(type.toLowerCase())) {
        return <FaFileVideo />
    }
    if (DocumentManager.types.audio.includes(type.toLowerCase())) {
        return <FaFileAudio />
    }
    switch (type) {
        case 'primary':
            return <FaRegDotCircle />
        case 'inspectionAndAccounting':
            return <RiSuitcaseLine />
        case 'accounting_and_selling':
            return <FiLayers />
        case 'agreement':
            return <FaRegFileLines />
        case 'cleaning':
            return <FiDroplet />
        case 'maintenance':
            return <Broom />
        case 're_cleaning':
        case 'handling':
            return <Maintenance />
        case 'quality_control':
            return <FiPhoneCall />
        case 'report':
            return <FaRegComment />
        case 'starting':
            return <FaHourglassHalf />
        case 'documents_returning':
            return <FaClockRotateLeft />
        case 'final_report':
            return <FaChartLine />
        case 'empty':
            return <FaPlus />
        case 'glazing':
            return <Window />
        case 'closed':
            return <FaCheck />
        case 'orderClosing':
            return <FaCheck />
        case 'inspection':
            return <FiBriefcase />
        case 'emptyInspectionAndAccounting':
        case 'emptyComplex':
        case 'emptyGlazing':
        case 'emptyMaintenance':
            return <FaPlus />
        default:
            return <FiLayers />
    }
}
