export enum CustomClientInputActions {
    REPEAT = 'repeat',
    ACTIVE = 'active',
    NONE = 'none'
}

export interface ClientInfoType {
    value: string
    text: string
    inn?: string
}

export interface ClientAddressType extends ClientInfoType {
    city: string
}
