import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useEffect } from 'react'
import { Control, Controller, FieldValues, useFieldArray, UseFormSetValue } from 'react-hook-form'
import CustomInput from '~/components/common/inputs/CustomInput/CustomInput'
import MobileCustomDateInput
    from '~/components/mobile/mobileCommon/mobileInputs/MobileCustomDateInput/MobileCustomDateInput'
import MobileCustomSelect from '~/components/mobile/mobileCommon/mobileInputs/MobileCustomSelect/MobileCustomSelect'
import { InputParams, InputTemplateType } from '~/hooks/useFormConstructor/useFormConstructor.types'
import './MobileCustomFieldArray.scss'

interface MobileCustomFieldArrayProps {
    items?: InputParams[] | InputTemplateType
    name: string
    control: Control
    setValue: UseFormSetValue<FieldValues>
    disabled: boolean
    disableMode?: boolean
    division?: string
    className?: string
}

const MobileCustomFieldArray = (props: MobileCustomFieldArrayProps) => {
    const {
        items = [],
        name,
        control,
        setValue,
        disabled,
        disableMode,
    } = props
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
    })

    useEffect(() => {
        if (!fields || fields.length === 0) {
            setValue(name, [Object.values(items).reduce((accum, item) => ({ ...accum, [item.name]: '' }), {})])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields])

    return (
        <div className='mobile-custom-field-array'>
            <div className='mobile-custom-field-array__body'>
                {fields.map((field, index) => (
                    <div className='mobile-custom-field-array__row' key={field.id}>
                        {Object.values(items).map((item) => (
                            <Controller
                                key={`${name}_${index}_${item.name}`}
                                name={`${name}.${index}.${item.name}`}
                                control={control}
                                rules={{ required: item.required }}
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    switch (item.type) {
                                        case 'text':
                                            return (
                                                <CustomInput
                                                    {...item}
                                                    className={'formSection__inputText'}
                                                    error={error}
                                                    value={value}
                                                    onChange={onChange}
                                                    disabled={
                                                        item.disabled || (disableMode && index !== fields.length - 1)
                                                    }
                                                />
                                            )
                                        case 'select':
                                            return (
                                                <MobileCustomSelect
                                                    {...item}
                                                    className={'formSection__inputSelect'}
                                                    error={error}
                                                    onChange={onChange}
                                                    value={value}
                                                    disabled={
                                                        item.disabled || (disableMode && index !== fields.length - 1)
                                                    }
                                                />
                                            )
                                        case 'date':
                                            return (
                                                <MobileCustomDateInput
                                                    {...item}
                                                    error={error}
                                                    onChange={onChange}
                                                    value={value}
                                                    disabled={
                                                        item.disabled || (disableMode && index !== fields.length - 1)
                                                    }
                                                />
                                            )
                                        case 'tel':
                                            return (
                                                <CustomInput
                                                    {...item}
                                                    className={'formSection__inputText'}
                                                    error={error}
                                                    value={value}
                                                    onChange={onChange}
                                                    disabled={
                                                        item.disabled || (disableMode && index !== fields.length - 1)
                                                    }
                                                />
                                            )
                                        default:
                                            return <div></div>
                                    }
                                }}
                            />
                        ))}
                        {!disableMode && !disabled && (
                            <>
                                {index !== 0 ? (
                                    <button
                                        className={'mobile-custom-field-array__delete'}
                                        type={'button'}
                                        onClick={() => remove(index)}
                                    >
                                        <DeleteIcon />
                                        <span>Удалить</span>
                                    </button>
                                ) : (
                                    <button
                                        className={'mobile-custom-field-array__button'}
                                        type={'button'}
                                        onClick={() =>
                                            append(
                                                Object.values(items).reduce(
                                                    (accum, item) => ({
                                                        ...accum,
                                                        [item.name]: '',
                                                    }),
                                                    {},
                                                ))
                                        }
                                    >
                                        <AddIcon />
                                        <span>Добавить</span>
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MobileCustomFieldArray
