import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { CustomPopup } from '~/components/common/ReusingComponents/CustomPopup/CustomPopup'
import './PopupPrompt.scss'

interface PopupPromptProps {
    onSubmit: (...args: any) => void,
    onCancel: (...args: any) => void,
    onClose: (...args: any) => void,
    text: string,
    title?: string,
    submitText?: string
    cancelText?: string
    isAlwaysSave?: boolean
}

const PopupPrompt = (props: PopupPromptProps) => {
    const {
        onSubmit,
        onCancel,
        onClose,
        text,
        submitText = 'Сохранить',
        cancelText = 'Не сохранять',
        isAlwaysSave,
        title = 'Закрыть заявку?',
    } = props

    const handleClose = (e: any) => {
        onClose()
    }

    return (
        <CustomPopup
            onClose={handleClose}
            title={{ title, className: 'popup-prompt__title' }}
            body={{
                data: [
                    (
                        <p key='promptText' className='popup-prompt__text'>{text}</p>
                    ),
                ],
            }}
            footer={!isAlwaysSave ? [
                (
                    <CustomButton
                        key='submit_PopupPrompt_!isAlwaysSave'
                        onClick={onSubmit}
                        title={submitText}
                        theme={CustomButtonThemes.COLOR}
                    />
                ), (
                    <CustomButton
                        key='cancel_PopupPrompt'
                        onClick={onCancel}
                        title={cancelText}
                        theme={CustomButtonThemes.OUTLINE}
                    />
                ),
            ] : [
                <CustomButton
                    key='submit_PopupPrompt'
                    onClick={onSubmit}
                    title={submitText}
                    theme={CustomButtonThemes.COLOR}
                />,
            ]}
        />
    )
}

export default PopupPrompt
