import { AccountingAndSellingType } from '~/API/orderApi/types/accountingAndSelling.type'
import { AgreementType } from '~/API/orderApi/types/agreement.type'
import { ChiefReportType } from '~/API/orderApi/types/chiefReport.type'
import { ContractorAccountingType } from '~/API/orderApi/types/contractorAccounting.type'
import { ContractWorkReportType } from '~/API/orderApi/types/contractWorkReport.type'
import { DocumentsType } from '~/API/orderApi/types/documents.type'
import { ForemanReportType } from '~/API/orderApi/types/foremanReport.type'
import { InspectionType } from '~/API/orderApi/types/inspection.type'
import { OrderClosingType } from '~/API/orderApi/types/orderClosing.type'
import { OrderManagerReportType } from '~/API/orderApi/types/orderManagerReport.type'
import { PrimaryInformationType } from '~/API/orderApi/types/primaryInformation.type'
import { QualityControlType } from '~/API/orderApi/types/qualityControl.type'
import { ReturningDocumentsType } from '~/API/orderApi/types/returningDocuments.type'
import { SellingType } from '~/API/orderApi/types/selling.type'
import { StartingType } from '~/API/orderApi/types/starting.type'
import { ToolsReportType } from '~/API/orderApi/types/toolsReport.type'
import { WorkStartingType } from '~/API/orderApi/types/workStarting.type'
import { Division } from '~/API/userApi/user.types'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'

export interface OrderInterface {
    id: string
    orderType: OrderType
    section: StageGroup
    businessType: BusinessType
    division: Division
    stageName: StageType
    nextStageName: StageType
    archived: boolean
    archivedDate: Date
    responsibleUserId: string
    startDate: Date
    endDate: Date
    cleaningDate: TwiceDate
    primaryInformation: PrimaryInformationType
    inspection: InspectionType
    selling: SellingType
    accountingAndSelling: AccountingAndSellingType
    agreement: AgreementType
    starting: StartingType
    foremanReport: ForemanReportType
    contractWorkReport: ContractWorkReportType
    contractorAccounting: ContractorAccountingType
    toolsReport: ToolsReportType
    orderManagerReport: OrderManagerReportType
    orderClosing: OrderClosingType[]
    chiefReport: ChiefReportType
    workStarting: WorkStartingType
    returningDocuments: ReturningDocumentsType
    qualityControl: QualityControlType
    documents: DocumentsType
    events: StageChangeEvent[]
}

type OrderType = 'STANDART' | 'WITH_INSPECTION_AND_CALC'
export type BusinessType = 'B2B' | 'B2C'

export interface TwiceDate {
    startDate: Date
    endDate: Date
}

export interface FetchAllOrderByCleaningDateParams {
    startDate: string,
    endDate: string,
    division?: string
}

export interface ShortOrder {
    address: string
    archived: boolean
    businessType: string
    cleaningDate: { startDate: Date, endDate: Date, }
    cleaningType: string
    clientFullname: string
    clientPhone: string
    cost: number
    division: string
    endDate: Date
    events: StageChangeEvent[]
    id: string
    organization: string
    responsibleUsers: string[]
    section: StageGroup
    serviceType: string
    stageName: StageType
    startDate: string
}

export interface StageChangeEvent {
    userId: string
    startDate: Date
    endDate: Date
    stageName: StageType
}

export interface GetOrderReportResponse {
    actualPrepayment: number
    cleanerWage: number
    costCard: number
    costCash: number
    foremanWage: number
    prepaymentPercent: number
    generalInfo: GeneralReportData
    maintenance?: {
        plan: CommonReportData & MaintenanceReportData
        fact: CommonReportData & MaintenanceReportData
    }
    simpleReport?: {
        plan: CommonReportData & OneTimeWorkReportData
        fact: CommonReportData & OneTimeWorkReportData
    }
}

interface GeneralReportData {
    actualCleanerNumber: null
    actualDurationCleaningTime: null
    actualObjectArea: null
    additionalService: null
    address: string
    attractionSource: string
    buildingClass: string
    buildingType: string
    chiefComment?: string
    cleaningType: string
    clientComment?: string
    clientEmail: string
    clientFullname: string
    clientPhone: string
    clientType: string
    contractType: null
    counterparty: string
    crmLink: string
    foremanComment: null
    foremanFullname: null
    id: string
    inspectionDate: null
    inspectionManager: null
    misfitsComments: null
    month: null
    objectComment: null
    orderCreatedAt: string
    orderEndDate: string
    orderManagerComment: null
    orderStartDate: string
    orderType: null
    organizationName: string
    paymentType: string
    qualityControlResponsibleUser: null
    qualityRating: null
    serviceRating: null
    serviceType: string
    sex: null
    soldAtFullname: null
    sourceType: string
    stageGroup: string
}

export interface CommonReportData {
    cost: number
    expanses: number
    grossProfit: number
    marginality: number
}

export interface MaintenanceReportData {
    totalWage: number
    totalWageTax: number
    vacationPay: number
    grayWageTax: number
    chemistry: number
    consumables: number
    wipings: number
    inventory: number
    overall: number
    components: number
    equipment: number
    appliances: number
    contract: number
    additionalExpanses: number
}

export interface OneTimeWorkReportData {
    totalWage: number
    consumables: number
    amortization: number
    logistic: number
    petrol: number
    parking: number
    rent: number
    contract: number
    otherExpanses: number
    additionalExpanses: number
    tax: number
}

export interface UploadMonthStatisticParams {
    title: string
    startDate: Date | string
    endDate: Date | string
}

export type GetArchiveData = {
    pageSize: number
    page: number
    search: string | null
    businessType: Division
    archived: true
    stageGroup: StageGroup | null,
    orderStatus: boolean | null
    year: number
    sortBy: {
        path: 'address' | 'clientFullname' | 'startDate' | 'cost' | ''
        order: 'asc' | 'desc' | ''
    }
}

export type GetArchiveResponse = {
    pageSize: number
    page: number
    count: number
    orders: ShortOrder[]
}

export type StageGroup =
    'PRIMARY' |
    'INSPECTION' |
    'SELLING' |
    'ACCOUNTING_AND_SELLING' |
    'AGREEMENT' |
    'STARTING' |
    'REPORT' |
    'QUALITY_CONTROL' |
    'CLOSED' |
    'UNKNOWN' |
    'INSPECTION_AND_ACCOUNTING'

export const AllStages = [
    'EDIT_ORDER',
    'RESERVE_ORDER',
    'CREATE_KP',
    'APPROVE_KP',
    'RESERVE_CLEANING',
    'ORDER_TO_DIVISION',
    'CHANGE_COST',
    'CLIENT_DECLINE',
    'CLIENT_DECLINING',
    'TAKE_ORDER',

    'CONTRACT_SIGNED',

    'PREPAYMENT_APPROVED',
    'SELECT_INSPECTION_DATE',
    'TO_SELLING',
    'NEW_KP_DATE',
    'SELECT_KP_DATE',
    'SIGNING_KP',
    'SELECT_CONTRACT_DATE',
    'SIGNING_CONTRACT',
    'SELECT_CLEANING_DATE',
    'CHANGE_REQUEST',
    'FILL_NZ_WITH_TASK',
    'NZ_TASK_APPROVED',
    'APPROVE_NZ',
    'NEW_CLEANING_APPROVAL_DATE',
    'NEW_CLEANING_DATE',
    'APPROVE_CLEANING',
    'CLIENT_CONFIRMATION',
    'WAITING_CLIENT_CONFIRMATION',
    'INFO_TO_FOREMAN',
    'CLEANING_STARTED',
    'ADD_SERVICES_RESPONSE',
    'ADD_SERVICES_TO_CONFIRMATION',
    'WORK_STARTING',
    'WORK_PROCESS',
    'CLEANING_ENDED',
    'WORKING_DAY_ENDED',
    'WORK_ENDING',
    'LAST_DAY_ENDED',
    'ON_WAREHOUSE',
    'FOREMAN_REPORT',
    'TOOLS_REPORT',
    'APPROVE_NZ_BACK',
    'RETURNING_DOCUMENTS',
    'PAYMENT_RECEIVING',
    'TO_QUALITY_CONTROL',
    'TO_CHIEF_DECISION',
    'TO_ORDER_MANAGER_ORDER_CLOSING',
    'TO_OPERATOR_ORDER_CLOSING',
    'CHIEF_REPORT',
    'FINISH_ORDER',
    'ORDER_CLOSED',
    'TO_RECLEANING',
    'TO_ARCHIVE',

    'ORDER_CREATING',
    'REPEAT_CLIENT_STARTING',
    'COMMERCIAL_OFFER_APPROVING',
    'DATE_BOOKING',
    'NEED_CHANGES_TO_ORDER_CREATING',
    'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING',
    'NEED_CHANGES_TO_CONTRACT_SIGNING',
    'NEED_CHANGES_TO_WORKING_PLAN_APPROVING',
    'NEED_CHANGES_TO_CONTRACT_WORK_REPORT_FILLING',
    'NEED_CHANGES_TO_INSPECTION_DIVISION_APPROVING',
    'ORDER_INSPECTION_APPROVING', // delete after dev
    'INSPECTION_DIVISION_APPROVING',
    'INSPECTION_APPROVING',
    'INSPECTION_CONTENT_UPLOADING',
    'INSPECTION_PAYMENT_APPROVING',
    'INSPECTION_STARTING',
    'CONTRACT_SIGNING',
    'CONTRACT_SIGNING_BILL',
    'TO_OFFICE_MANAGER_CONTRACT_CREATING',
    'PREPAYMENT_APPROVING',
    'WORKING_PLAN_APPROVING',
    'ORDER_APPROVING',
    'ORDER_MANAGER_INFORMING',
    'PAYMENT_WAITING',
    'PAYMENT_CONFIRMED',

    'DEPARTURE_CONFIRMATION',
    'SERVICES_VERIFICATION',
    'ADDITIONAL_SERVICES_CREATING',
    'OBJECT_REPORT_FILLING',
    'SERVICE_TABLE_FILLING',
    'WORKING_DAY_ENDING',
    'TEAM_REPORT_FILLING',
    'WAREHOUSE_GOING',
    'INVENTORY_ITEMS_RETURNING',
    'MANAGER_CHECKING',
    'DEFECTS_CORRECTING',
    'QUALITY_CONTROLLING',
    'ACTUAL_PAYMENT_RESERVING',
    'DOCUMENTS_RETURNING',
    'FINAL_REPORT',
    'MAINTENANCE_FINAL_REPORT',

    'CONTRACT_WORK_REPORT_FILLING',
    'CONTRACTOR_BILLING',

    'NEED_CHANGES_TO_TEAM_REPORT_FILLING',
] as const

export type Team = {
    personnelId: string
    name: string
    jobPosition: string
    salary: number
    addSalary: number
    rate: number
    status: string
    note: string
    comment: string
}

export const ERROR_CODES = [
    'CLIENT_INFO_CONTRACTOR_VALIDATION_FAILED',
] as const
