import { FaArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import clsx from 'clsx'
import React, { memo, useEffect, useRef, useState } from 'react'
import { PatternFormat } from 'react-number-format'
import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { useDebounce } from '~/hooks/useDebounce'
import './CustomSearchInput.scss'
import { useKeyPress } from '~/hooks/useKeyPress'

export enum CustomSearchInputThemes {
    SMOKE = 'smoke',
    GREY = 'grey',
}

interface CustomSearchInputProps {
    onChange: (value: string) => void,
    defaultValue?: string
    placeholder?: string
    className?: string
    theme?: CustomSearchInputThemes
    isButton?: boolean
    onClick?: () => void
    mask?: InputMask
}

const CustomSearchInput = memo((props: CustomSearchInputProps) => {
    const {
        onChange,
        defaultValue,
        placeholder = 'Поиск события',
        className,
        theme = CustomSearchInputThemes.SMOKE,
        isButton = false,
        onClick,
        mask,
    } = props
    const searchRef = useRef<HTMLInputElement>(null)
    const debouncedSearch = useDebounce(search, 300)
    const isEnterPressed = useKeyPress('Enter')
    const [searchText, setSearchText] = useState<string>('')

    useEffect(() => {
        if (defaultValue) {
            setSearchText(defaultValue)
        }
    }, [defaultValue])

    function search(text: string) {
        onChange(text)
    }

    const handleChange = (event: any) => {
        if (typeof event === 'string') {
            search(event)
            return
        }
        debouncedSearch(event.target.value.trimStart())
    }

    useEffect(() => {
        if (isEnterPressed) {
            if (searchText) {
                onClick && onClick()
            }
            handleChange(searchText)
        }
    }, [isEnterPressed])

    return (
        <div className={clsx('searchInput__container', className, theme && `searchInput__container--${theme}`)}>
            <div
                onClick={() => {
                    searchRef.current && searchRef.current.focus()
                }}
                className={clsx('searchInput', className, theme && `searchInput--${theme}`)}
            >
                <FaSearch className='searchIcon' />
                {mask ? (
                    <PatternFormat
                        name='search'
                        value={searchText}
                        placeholder={placeholder}
                        format={mask}
                        onChange={(e) => {
                            setSearchText(e.target.value.trimStart())
                            !isButton && handleChange(e)
                        }}
                        autoComplete='off'
                        mask='_'
                    />
                ) : (
                    <input
                        ref={searchRef}
                        type='text'
                        placeholder={placeholder}
                        onChange={(e) => {
                            setSearchText(e.target.value.trimStart())
                            !isButton && handleChange(e)
                        }}
                        value={searchText}
                    />
                )}
                {searchText && (
                    <FaArrowLeft
                        className='clearIcon'
                        data-testid='clear-icon'
                        role='button'
                        onClick={() => {
                            onChange('')
                            setSearchText('')
                        }}
                    />
                )}
            </div>
            {isButton && (
                <CustomButton
                    className='searchInput__button'
                    onClick={() => {
                        if (searchText) {
                            onClick && onClick()
                        }
                        handleChange(searchText)
                    }}
                    title='Искать'
                    theme={CustomButtonThemes.COLOR}
                />
            )}
        </div>
    )
})

export default CustomSearchInput
