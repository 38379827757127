export const fStatusData = [
    { value: null, text: 'Сбросить' },
    { value: true, text: 'Завершено' },
    { value: false, text: 'Не завершено' },
]
export const fStageData = [
    { value: null, text: 'Сбросить' },
    { value: 'PRIMARY', text: 'Заявка' },
    { value: 'INSPECTION', text: 'Осмотр' },
    { value: 'ACCOUNTING_AND_SELLING', text: 'Расчет/Продажа' },
    { value: 'AGREEMENT', text: 'Договор' },
    { value: 'STARTING', text: 'Запуск уборки' },
    { value: 'REPORT', text: 'Отчеты' },
    { value: 'QUALITY_CONTROL', text: 'Контроль качества' },
    { value: 'DOCUMENTS_RETURNING', text: 'Возврат документов и постоплата' },
    { value: 'FINAL_REPORT', text: 'Финальный отчет' },
]

export const yearsData = [
    2024,
    2023,
    2022,
    2021,
    2020,
    2019,
]
