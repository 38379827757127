import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import { OnArgs } from 'react-calendar/dist/cjs/shared/types'
import { useFormContext } from 'react-hook-form'
import CalendarSvg from '../../../../assets/svg/calendar.svg'
import TileDatePicker from '../TileDatePicker/TileDatePicker'
import { CleaningsInfoType } from './lib/types'
import { useLazyFetchAllOrderByCleaningDateQuery } from '~/API/orderApi/order'
import { useLazyFetchScheduleQuery } from '~/API/scheduleApi/schedule'
import { useFetchUsersByRolesMutation } from '~/API/userApi/user'
import {
    checkAvailableDate,
    constructClassForDay,
    constructClassForHours,
    constructTooltipForDay,
    getAvailableTime,
    horizontalScroll,
} from '~/components/common/popups/PopupDatePicker/lib/additionalFunctions'
import { CustomPopupWrapper } from '~/components/common/ReusingComponents/CustomPopupWrapper/CustomPopupWrapper'
import { checkHiddenStages } from '~/components/pages/Home/subComponents/OrderCalendar/OrderCalendarFilterFunctions'
import { endOfMonth, fetchDate, fetchDateToUTC, fetchLocalDate, startOfMonth } from '~/shared/util/currentTime'
import dayjs from '~/shared/util/dayjsConfig'
import { getGlobalDivision } from '~/shared/util/localStorage'

type PopupDatePickerValue = {
    startDate: string,
    endDate: string,
} | string

interface PopupDatePickerProps {
    defaultValues: PopupDatePickerValue,
    singleDate: boolean
    onClose: () => void
    onSubmit: (value: PopupDatePickerValue) => void
    cleaningDate?: boolean
    division?: string
    withTooltip?: boolean
}

const PopupDatePicker = (props: PopupDatePickerProps) => {
    const {
        defaultValues,
        singleDate,
        onClose,
        onSubmit,
        cleaningDate = false,
        division,
        withTooltip,
    } = props
    const [fetchSchedule] = useLazyFetchScheduleQuery()
    const [fetchAllOrderByCleaningDate] = useLazyFetchAllOrderByCleaningDateQuery()
    const [fetchUsersByRoles] = useFetchUsersByRolesMutation()
    const ref = useRef<HTMLDivElement>(null)
    const [values, setValues] = useState<any>({})
    const [activeInput, setActiveInput] = useState<'START' | 'END'>('START')
    const [cleaningsInfo, setCleaningsInfo] = useState<CleaningsInfoType>(new Map())
    const [actualDate, setActualDate] = useState<Dayjs>(startOfMonth())
    const [notice, setNotice] = useState('')
    const [loader, setLoader] = useState(true)

    const methods = useFormContext()

    const handleClose = useCallback(() => {
        onClose()
    }, [])

    const handleClickDay = (date: Date) => {
        if (activeInput === 'START') {
            const newDate = dayjs(date).isSame(fetchLocalDate(), 'day') ? getAvailableTime() : dayjs(date).toDate()
            if (checkAvailableDate(newDate, 'START', values)) {
                setValues({ startDate: newDate })
                if (cleaningsInfo) {
                    const dayInfo = cleaningsInfo.get(fetchDate(date).startOf('day').toISOString())
                    if (dayInfo && dayInfo.available === 0) {
                        setNotice('Внимание! День который вы выбрали занят')
                    }
                } else {
                    setNotice('')
                }
            }
        }

        if (
            activeInput === 'END' &&
            checkAvailableDate(dayjs(date).set('hour', 23).set('minute', 30).toDate(), 'END', values)
        ) {
            setValues((prev: any) => ({ ...prev, endDate: dayjs(date).set('hour', 23).set('minute', 30).toDate() }))
            if (cleaningsInfo) {
                const dates = Array(
                    Math.abs(fetchDate(values.startDate).add(1, 'day').diff(fetchDate(date).set('hour', 23).set('minute', 30), 'days')) + 2,
                )
                    .fill(values.startDate)
                    .map((v, i) => fetchDate(v).startOf('day').add(i, 'd').toLocaleString())
                if (dates.some((d) => cleaningsInfo.get(d) && cleaningsInfo.get(d)?.available === 0)) {
                    setNotice('Внимание! День который вы выбрали занят')
                } else {
                    setNotice('')
                }
            }
        }
    }

    const handleSubmit = () => {
        if (singleDate) {
            onSubmit(fetchDateToUTC(values.startDate).format('YYYY-MM-DDTHH:mm:ss'))
        } else {
            onSubmit({
                startDate: fetchDateToUTC(values.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                endDate: fetchDateToUTC(values.endDate).format('YYYY-MM-DDTHH:mm:ss'),
            })
        }
        onClose()
    }

    const handleTimeClick = (e: any) => {
        if (activeInput === 'START' && values.startDate) {
            const date = dayjs(
                values.startDate.toLocaleDateString() + ' ' + e.target.value,
                'DD.MM.YYYY HH:mm',
            ).toDate()
            if (checkAvailableDate(date, 'START', values)) {
                if (values.endDate && dayjs(date).diff(values.endDate) >= 0) {
                    setValues({ startDate: date })
                } else {
                    setValues((prev: any) => ({
                        ...prev,
                        startDate: date,
                    }))
                }
            }
        }
        if (activeInput === 'END' && values.endDate) {
            const date = dayjs(values.endDate.toLocaleDateString() + ' ' + e.target.value, 'DD.MM.YYYY HH:mm').toDate()
            if (checkAvailableDate(date, 'END', values)) {
                setValues((prev: any) => ({
                    ...prev,
                    endDate: date,
                }))
            }
        }
    }

    useEffect(() => {
        let isMounted = true
        if (defaultValues) {
            if (typeof defaultValues !== 'string' && defaultValues.startDate) {
                if (isMounted) {
                    setValues({
                        startDate: fetchDate(defaultValues.startDate).toDate(),
                        endDate: fetchDate(defaultValues.endDate).toDate(),
                    })
                }
            } else {
                if (isMounted && typeof defaultValues === 'string') {
                    setValues(fetchDate(defaultValues).toDate())
                }
            }
        }
        return () => {
            isMounted = false
        }
    }, [defaultValues])

    useEffect(() => {
        let isMounted = true
        if (cleaningDate && methods) {
            const { getValues } = methods

            if (isMounted) {
                setLoader(true)
            }
            const cleaningsPromise = fetchAllOrderByCleaningDate({
                startDate: startOfMonth(actualDate).subtract(2, 'week').toISOString(),
                endDate: endOfMonth(actualDate).add(2, 'week').toISOString(),
                division: division || getValues().businessType,
            }).unwrap()
            const schedulePromise = fetchSchedule({
                startDate: startOfMonth(actualDate).subtract(2, 'week').toDate(),
                endDate: endOfMonth(actualDate).add(2, 'week').toDate(),
                division: division || getValues().businessType,
            }).unwrap()
            const usersPromise = fetchUsersByRoles({
                roles: ['FOREMAN', 'ORDER_MANAGER'],
                division: division || getValues().businessType,
            }).unwrap()
            const serviceType = getValues().primaryInformation?.serviceType || getValues().nzTask?.serviceType
            Promise.all([schedulePromise, cleaningsPromise, usersPromise]).then(([schedule, cleanings, users]) => {
                const dayInfo = schedule
                    .reduce((accum: CleaningsInfoType, day: any) => {
                        const available = day.personnel
                            .filter((p: any) => p.status === 'WORKING' && users?.some((u) => u.id === p.personnelId))
                            .filter((p: any) => {
                                const user = users?.find((u) => p.personnelId === u.id)
                                if (!user?.division.includes(division || getValues().businessType || getGlobalDivision())) {
                                    return false
                                }
                                if (user.specialization === 'maintenance') {
                                    return serviceType === 'Поддерживающая уборка'
                                }
                                if (user.specialization === 'glazing') {
                                    return serviceType === 'Остекление'
                                }
                                if (user.role === 'FOREMAN' || user.role === 'ORDER_MANAGER') {
                                    return serviceType !== 'Поддерживающая уборка' && serviceType !== 'Остекление'
                                }

                                return false
                            })
                            .length
                        const ordered = cleanings.filter(
                            (cleaning) => {
                                return (
                                    cleaning.serviceType === serviceType ||
                                        (cleaning.serviceType !== 'Поддерживающая уборка' &&
                                            cleaning.serviceType !== 'Остекление' &&
                                            serviceType !== 'Поддерживающая уборка' &&
                                            serviceType !== 'Остекление')) &&
                                    !cleaning.archived &&
                                    cleaning.section !== 'inspection' &&
                                    !checkHiddenStages(cleaning.stageName, !!cleaning.isDateBooked, !!cleaning.isInspectionInPast) &&
                                    cleaning?.cleaningDate &&
                                    fetchDate(day.date).isBetween(fetchDate(cleaning?.cleaningDate?.startDate), fetchDate(cleaning?.cleaningDate?.endDate), 'day', '[]')
                            },
                        ).length
                        accum.set(fetchDate(day.date).startOf('day').toLocaleString(), {
                            available: Math.max(available - ordered, 0),
                            ordered,
                        })
                        return accum
                    }, new Map())
                if (isMounted) {
                    setCleaningsInfo(dayInfo)
                    setLoader(false)
                }
            })
        }
        return () => {
            isMounted = false
        }
    }, [cleaningDate, actualDate, division])

    return (
        <CustomPopupWrapper
            onClose={handleClose}
            innerElementRef={ref}
        >
            <div className='popup popupDatePicker' ref={ref}>
                <div className='popupDatePicker__header'>
                    <div className='popupDatePicker__header__logo'>
                        <CalendarSvg />
                    </div>
                    <div className='popupDatePicker__header__body'>
                        <h3>Календарь</h3>
                        <p>Выберите дату и время события</p>
                    </div>
                </div>
                <div className='popupDatePicker__body'>
                    <div className='popupDatePicker__left'>
                        <div className='popupDatePicker__dates'>
                            <div
                                className={clsx('popupDatePicker__date', {
                                    focused: activeInput === 'START',
                                    singleDate,
                                })}
                            >
                                <h5>Дата начала</h5>
                                <div
                                    onClick={() => {
                                        setActiveInput('START')
                                    }}
                                >
                                    <input
                                        type='text'
                                        value={singleDate && values instanceof Date ? fetchDate(values).format('D MMM YYYY • HH:mm') :
                                            values.startDate ?
                                                fetchDate(values.startDate).format('D MMM YYYY • HH:mm') : ''
                                        }
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            {!singleDate && (
                                <>
                                    <hr className='popupDatePicker__line' />
                                    <div className={clsx('popupDatePicker__date', { focused: activeInput === 'END' })}>
                                        <h5>Дата окончания</h5>
                                        <div
                                            onClick={() => {
                                                values.startDate && setActiveInput('END')
                                            }}
                                        >
                                            <input
                                                type='text'
                                                value={
                                                    values.endDate ?
                                                        fetchDate(values.endDate).format('D MMM YYYY • HH:mm') : ''
                                                }
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className='popupDatePicker__miniCalendar'>
                            <Calendar
                                locale='ru-RU'
                                minDetail='month'
                                maxDetail='month'
                                navigationLabel={({ label }: { label: any }) =>
                                    <span>{label.substring(0, label.length - 2)}</span>}
                                tileContent={({ date }: { date: Date }) => {
                                    return (
                                        <TileDatePicker
                                            date={date}
                                            className={constructClassForDay({
                                                date,
                                                cleaningDate,
                                                cleaningsInfo,
                                                values,
                                            })}
                                            tooltip={withTooltip ? constructTooltipForDay(
                                                cleaningDate,
                                                cleaningsInfo?.get(fetchDate(date).startOf('day').toLocaleString()),
                                            ) : null}
                                        />
                                    )
                                }}
                                onActiveStartDateChange={({ action, activeStartDate, value, view }: OnArgs) => {
                                    setActualDate(startOfMonth(activeStartDate))
                                }}
                                onClickDay={handleClickDay}
                                className={clsx({ loading: cleaningDate && loader })}
                                next2Label={null}
                                prev2Label={null}
                            />
                        </div>
                    </div>

                    <div className='popupDatePicker__right'>
                        <div className='popupDatePicker__hours' onWheel={horizontalScroll}>
                            {new Array(48).fill(2).map((_, index) => {
                                const value = fetchDate()
                                    .startOf('day')
                                    .add(30 * index, 'm')
                                    .format('HH:mm')
                                const className = constructClassForHours({ value, activeInput, values })
                                return (
                                    <input
                                        type='button'
                                        className={className}
                                        key={`timePicker_${index}`}
                                        value={value}
                                        onClick={handleTimeClick}
                                        disabled={className === 'disabled'}
                                    />
                                )
                            })}
                        </div>
                    </div>

                    <div className='popupDatePicker__notice'>{withTooltip ? notice : ''}</div>
                </div>
                <div className='popupDatePicker__buttons'>
                    <button
                        className='button'
                        type='button'
                        onClick={handleSubmit}
                        disabled={
                            (singleDate && !values.startDate) || (!singleDate && (!values.startDate || !values.endDate))
                        }
                    >
                        Выбрать
                    </button>
                    <button className={'button button_border-none'} type={'button'} onClick={onClose}>
                        Отмена
                    </button>
                </div>
            </div>
        </CustomPopupWrapper>
    )
}

export default PopupDatePicker
