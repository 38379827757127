import { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFetchOrderQuery } from '~/API/orderApi/order'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import { StartingTeam } from '~/API/orderApi/types/starting.type'
import { useFetchAllPersonnelQuery, useFetchPersonnelByIdsMutation } from '~/API/personnelApi/personnel'
import { PersonnelResponse } from '~/API/personnelApi/personnel.types'
import { useFetchSalariesByDateQuery } from '~/API/salaryApi/salary'
import {
    getFilteredAndSortedPersonnel,
} from '~/components/common/formConstructor/formCommonComponents/PersonnelTable/lib/getFilteredAndSortedPersonnel'
import BackButton from '~/components/common/ReusingComponents/BackButton/BackButton'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import MobileTitle from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTitle/MobileTitle'
import MobileTitleButton
    from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTitleButton/MobileTitleButton'
import MobileListValidation
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileListValidation/MobileListValidation'
import MobileSinglePaymentModal
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobilePersonnelReport/subComponents/MobileSinglePaymentModal/MobileSinglePaymentModal'
import PersonnelReportItem
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobilePersonnelReport/subComponents/PersonnelReportItem/PersonnelReportItem'
import './MobilePersonnelReport.scss'
import { selectSalaryPayment } from '~/redux/selectors/salarySlice.selectors'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { updateSalaryFulfilled } from '~/redux/slices/salarySlice/salarySlice'
import { SalaryWorkers } from '~/redux/slices/salarySlice/salarySlice.type'
import { useAppDispatch } from '~/redux/store'
import { fetchDate } from '~/shared/util/currentTime'
import { get } from '~/shared/util/get'

interface MobilePersonnelReportProps {
    title: string
    name: `foremanReport.daysReport.${number}.actualTeam`
    tabIndex?: number
    disabled?: boolean
    inputs: any[]
    usingData?: string
}

const MobilePersonnelReport = (props: MobilePersonnelReportProps) => {
    const {
        title,
        name,
        tabIndex = 0,
        disabled,
        inputs,
        usingData,
    } = props

    const { id: orderId } = useParams()
    const dispatch = useAppDispatch()
    const [listModalOpen, setListModalOpen] = useState<boolean>(false)
    const [singlePaymentModalOpen, setSinglePaymentModalOpen] = useState<boolean>(false)
    const [mergedPersonnel, setMergedPersonnel] = useState<PersonnelResponse[]>()
    const [filteredAndSortedPersonnel, setFilteredAndSortedPersonnel] = useState<PersonnelResponse[]>([])
    const [workersData, setWorkersData] = useState<SalaryWorkers[]>([])
    const salary = useSelector(selectSalaryPayment)

    const {
        control,
        formState,
        getValues,
        setValue,
    } = useFormContext<OrderInterface>()

    const { fields, append, remove } = useFieldArray({ control, name })
    const personnel = fields as Array<StartingTeam & Record<'id', string>>

    const personnelErrors = useMemo(() => get(formState.errors, name), [formState])
    const division = getValues().foremanReport.division
    const actualTeam = getValues().foremanReport?.daysReport[tabIndex!].actualTeam

    const [fetchPersonnelByIds] = useFetchPersonnelByIdsMutation()
    const { data: order } = useFetchOrderQuery(orderId!)
    const { data: allPersonnel } = useFetchAllPersonnelQuery({
        workStatus: 'WORKING',
        division: order?.starting.division || order?.businessType,
    }, { skip: !order })
    const {
        data: salaryData,
        isSuccess: isSalarySuccess,
    } = useFetchSalariesByDateQuery({
        startDateRange: fetchDate(getValues().foremanReport?.daysReport[tabIndex].cleaningDate).startOf('day'),
        endDateRange: fetchDate(getValues().foremanReport?.daysReport[tabIndex].cleaningDate).endOf('day'),
        jobPosition: 'Клинер',
        division,
    }, { refetchOnFocus: false })

    const inputsWithPersonnelItems = inputs.map((input) => {
        if (input.name === 'personnelId') {
            return {
                ...input,
                items: filteredAndSortedPersonnel,
            }
        }
        return input
    })

    const addNewPerson = () => {
        append(inputs.reduce((accum, value) => ({ ...accum, [value.name]: value.defaultValue || '' }), {}))
    }

    const onSubscriptionFieldChange = (rowIndex: number, _: string, value: any) => {
        const foundedPerson = filteredAndSortedPersonnel.find((item) => item.id === value)

        if (!foundedPerson) return

        setValue(`${name}.${rowIndex}.salary`, foundedPerson.salary)
        setValue(`${name}.${rowIndex}.jobPosition`, foundedPerson.jobPosition)
        setValue(`${name}.${rowIndex}.name`, foundedPerson.fullname)

        if (!allPersonnel) return
        setFilteredAndSortedPersonnel(getFilteredAndSortedPersonnel(mergedPersonnel ?? allPersonnel, actualTeam).map((person) => ({
            ...person,
            value: person.id,
            text: person.fullname,
        })))
    }

    useEffect(() => {
        if (
            actualTeam &&
            allPersonnel &&
            actualTeam.find((item) => item.personnelId && !allPersonnel.some((person) => person.id === item.personnelId))
        ) {
            fetchPersonnelByIds(actualTeam
                .filter((item) => !allPersonnel.some((person) => person.id === item.personnelId))
                .map((person) => person.personnelId),
            ).unwrap()
                .then((personnel) => {
                    setMergedPersonnel([...allPersonnel, ...personnel])
                })
                .catch(() => {
                    dispatch(SHOW_NOTICE({
                        type: 'error',
                        message: 'Произошла ошибка при получении персонала',
                    }))
                })
        }
    }, [division, actualTeam, allPersonnel])

    useEffect(() => {
        if (!allPersonnel) return
        setFilteredAndSortedPersonnel(getFilteredAndSortedPersonnel(mergedPersonnel ?? allPersonnel, actualTeam).map((person) => ({
            ...person,
            value: person.id,
            text: person.fullname,
        })))
    }, [allPersonnel, mergedPersonnel, actualTeam])

    useEffect(() => {
        if (isSalarySuccess && salaryData) {
            dispatch(updateSalaryFulfilled({ salary: salaryData }))
        }
    }, [salaryData, isSalarySuccess])

    useEffect(() => {
        if (mergedPersonnel || allPersonnel) {
            setWorkersData(() => {
                const cleaningDay = getValues().foremanReport.daysReport[tabIndex]
                const res = cleaningDay.actualTeam
                    .filter((worker) => worker.status === 'WORKING' || worker.status === 'BUFFER')
                    .filter((worker) => {
                        if (salary.length) {
                            return !salary.find((salaryObject) => salaryObject.orderId === getValues().id && salaryObject.personnelId === worker.personnelId)
                        }
                        return true
                    })
                    .reduce((accum: SalaryWorkers[], worker) => {
                        if (!worker.jobPosition.toLowerCase().includes('бригадир')) {
                            accum.push({
                                id: worker.personnelId,
                                jobPosition: worker.jobPosition,
                                name: (mergedPersonnel ?? allPersonnel!).find((person) => person.id === worker.personnelId)?.fullname ?? '',
                                cleanings: [{
                                    salaryAmount: worker.salary,
                                    addSalary: +worker.addSalary,
                                    cleaningDate: cleaningDay?.cleaningDate,
                                    date: fetchDate(cleaningDay?.workingDate).format('DD / dd'),
                                    id: getValues().id,
                                    petrolExpanses: cleaningDay.actualExpansesPetrol,
                                    qualityRating: '', // cleaningDay.qualityRating, todo разобраться почему их нет в типах
                                    serviceRating: '', // cleaningDay.serviceRating,
                                    comment: '', // cleaningDay.comment,
                                    clientComment: '', // cleaningDay.clientComment,
                                    rate: worker.rate,
                                    address: getValues().primaryInformation.address,
                                    salary: null,
                                }],
                            })
                        }
                        return accum
                    }, [])
                return res
            })
        }
    }, [mergedPersonnel, allPersonnel, salary])

    return (
        <>
            <MobileTitleButton
                title={title}
                onClick={() => setListModalOpen(true)}
                isError={!!personnelErrors}
            />
            <MobileListValidation
                baseName={name}
                rows={personnel}
                inputs={inputs}
            />
            <Modal
                isOpen={listModalOpen}
                onRequestClose={() => setListModalOpen(false)}
                className='mobileServicesVerification__modal'
            >
                <BackButton callback={() => setListModalOpen(false)} />
                <MobileTitle title={title} />
                <div className='mobilePersonnelReport__content'>
                    <div className='mobilePersonnelReport__list'>
                        {personnel.map((person, rowIndex) => (
                            <PersonnelReportItem
                                key={person.id}
                                inputName={`${name}.${rowIndex}`}
                                disabled={!!disabled}
                                inputs={inputsWithPersonnelItems}
                                isError={!!personnelErrors?.[rowIndex]}
                                rowIndex={rowIndex}
                                usingData={usingData}
                                onSubscriptionFieldChange={onSubscriptionFieldChange}
                                onDelete={remove}
                            />
                        ))}
                    </div>
                    <div className='mobilePersonnelReport__buttons'>
                        <CustomButton
                            className='mobilePersonnelReport__buttonGray'
                            onClick={() => setSinglePaymentModalOpen(true)}
                            disabled={!disabled}
                        >
                            Единоразовая выплата
                        </CustomButton>
                        {!disabled && <CustomButton onClick={addNewPerson}>Добавить сотрудника</CustomButton>}
                    </div>
                </div>
            </Modal>
            {singlePaymentModalOpen && <MobileSinglePaymentModal
                isOpen={singlePaymentModalOpen}
                closeModal={() => setSinglePaymentModalOpen(false)}
                division={division}
                workersData={workersData}
            />}
        </>
    )
}

export default MobilePersonnelReport
