import { RootState } from '~/redux/store'

export const selectClientPopup = (state: RootState) => state.client.isClientPopupOpen
export const selectClientAction = (state: RootState) => state.client.clientAction
export const selectClientType = (state: RootState) => state.client.clientType
export const selectClientTitle = (state: RootState) => state.client.title
export const selectClientInfo = (state: RootState) => state.client.clientInfo
export const selectClientAddress = (state: RootState) => state.client.clientAddress
export const selectClientServices = (state: RootState) => state.client.clientServices
export const selectClientStage = (state: RootState) => state.client.clientStage
export const selectReplaceMethod = (state: RootState) => state.client.replaceMethod
