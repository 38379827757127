import { RootState } from '../store'

export const getScheduleSelector = (state: RootState) => state.staffSchedule.schedule
export const getActiveScheduleSelector = (state: RootState) => state.staffSchedule.activeSchedule
export const getIsLoadingSelector = (state: RootState) => state.staffSchedule.isLoading
export const getActiveDateSelector = (state: RootState) => state.staffSchedule.activeDate
export const getPersonnelBD = (state: RootState) => state.staffSchedule.personnelBD
export const getFiltersForEvents = (state: RootState) => state.staffSchedule.filtersForEvents
export const getScheduleForMiniCalendar = (state: RootState) => state.staffSchedule.scheduleForMiniCalendar
export const getDayDetailPopup = (state: RootState) => state.staffSchedule.dayDetailPopup
export const selectWatchingRoles = (state: RootState) => state.staffSchedule.watchingRoles
