export enum InputNameEnum {
    foreman = 'foreman',
    cleaningTask = 'cleaningTask',
    clientConfirmationInfo = 'clientConfirmationInfo',
    actualServices = 'actualServices',
    workBeginning = 'workBeginning',
    workProcess = 'workProcess',
    workEnding = 'workEnding',
    actualObjectArea = 'actualObjectArea',
    actualDurationCleaningTime = 'actualDurationCleaningTime',
    actualCleanerNumber = 'actualCleanerNumber',
    actualEmployeesSalary = 'actualEmployeesSalary',
    actualExpansesTaxiThere = 'actualExpansesTaxiThere',
    actualExpansesTaxiBack = 'actualExpansesTaxiBack',
    actualExpansesPetrol = 'actualExpansesPetrol',
    actualRentTMC = 'actualRentTMC',
    actualContractWork = 'actualContractWork',
    actualTeam = 'actualTeam',
    foremanComment = 'foremanComment',
    onWarehouseAction = 'onWarehouseAction',
    orderCreator = 'orderCreator',
    orderCreatedAt = 'orderCreatedAt',
    cleaningType = 'cleaningType',
    orderType = 'orderType',
    buildingType = 'buildingType',
    buildingClass = 'buildingClass',
    counterparty = 'counterparty',
    organizationRequisites = 'organizationRequisites',
    paymentType = 'paymentType',
    organizationRequisitesBankName = 'organizationRequisites.bankName',
    organizationRequisitesBankId = 'organizationRequisites.bankId',
    organizationRequisitesBankAccount = 'organizationRequisites.bankAccount',
    organizationRequisitesCorrespondentAccount = 'organizationRequisites.correspondentAccount',
    contactInfo = 'contactInfo',
    address = 'address',
    objectArea = 'objectArea',
    bathroom = 'bathroom',
    serviceType = 'serviceType',
    additionalService = 'additionalService',
    windows = 'windows',
    attractionSource = 'attractionSource',
    sourceType = 'sourceType',
    crmLink = 'crmLink',
    comment = 'comment',
    photosObject = 'photosObject',
    services = 'services',
    jobDescription = 'jobDescription',
    jobSchedule = 'jobSchedule',
    stuffNumber = 'stuffNumber',
    discount = 'discount',
    kpDiscount = 'kpDiscount',
    prepaymentType = 'prepaymentType',
    cost = 'cost',
    division = 'division',
    responsibleManager = 'responsibleManager',
    cleaningDate = 'cleaningDate',
    soldAt = 'soldAt',
    isProxy = 'isProxy',
    kp = 'kp',
    contract = 'contract',
    cleaningApprovalInfo = 'cleaningApprovalInfo',
    nzFront = 'nzFront',
    stageName = 'stageName'
}
