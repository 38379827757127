export const getObjectValueByPath = (obj: any, path: string) => {
    const parts = path.split('.')
    let current = obj

    parts.some((part) => {
        current = current[part]
        if (!current) {
            return true
        }
        return false
    })
    return current
}
