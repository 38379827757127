import { createSlice } from '@reduxjs/toolkit'

export const staffSlice = createSlice({
    name: 'staff',
    initialState: {
        comments: [],
        division: null,
    },
    reducers: {
        SET_COMMENTS: (state, action) => {
            state.comments = action.payload
        },
        CHANGE_DIVISION: (state, action) => {
            state.division = action.payload
        },
    },
})

export const { SET_COMMENTS, CHANGE_DIVISION } = staffSlice.actions

export default staffSlice.reducer
