import { fetchDate } from '~/shared/util/currentTime'

export const filterEvents = (searchText: string, eventName: string) => {
    if (searchText === '') {
        return true
    }
    if (!eventName) {
        return false
    }
    return eventName.toLowerCase().includes(searchText.toLowerCase())
}

export const betweenDates = (startDate: string | Date, endDate: string | Date) => {
    const days: Date[] = []

    let currDate = fetchDate(startDate).startOf('day')
    const lastDate = fetchDate(endDate).startOf('day')

    while (currDate.isSameOrBefore(lastDate)) {
        days.push(currDate.toDate())
        currDate = currDate.add(1, 'days')
    }

    return days
}

export const AgendaFilterOptions = (searchText: string, item: any, date: string | string[]) => {
    if (typeof date === 'string') {
        const splitedDate = date.split('.')
        const dayMonth = splitedDate[0] + '.' + splitedDate[1]
        return (
            !filterEvents(searchText, item?.address) &&
            !filterEvents(searchText, item?.organization) &&
            !filterEvents(searchText, dayMonth)
        )
    }

    const isEmptyMonth = date.some((item) => {
        const splitedDate = item.split('.')
        const dayMonth = splitedDate[0] + '.' + splitedDate[1]
        return filterEvents(searchText, dayMonth)
    })

    return (
        !filterEvents(searchText, item?.address) &&
        !filterEvents(searchText, item?.organization) &&
        !isEmptyMonth
    )
}
