import React from 'react'
import { useSelector } from 'react-redux'
import CustomLightbox from './subComponents/CustomLightbox/CustomLightbox'
import PopupFromArchive from './subComponents/PopupFromArchive/PopupFromArchive'
import PopupOrganization from './subComponents/PopupOrganization/PopupOrganization'
import PopupToArchive from './subComponents/PopupToArchive/PopupToArchive'
import { SELECT_LIGHTBOX } from '~/redux/selectors/appSlice.selectors'
import { isArchivePopup, isBankPopup, isUnzipPopup } from '~/redux/selectors/popupSlice.selectors'

const PopupWrapper = () => {
    const archivePopupStatus = useSelector(isArchivePopup)
    const bankPopupStatus = useSelector(isBankPopup)
    const unzipPopupStatus = useSelector(isUnzipPopup)
    const lightboxController = useSelector(SELECT_LIGHTBOX)

    return (
        <>
            {archivePopupStatus && <PopupToArchive />}
            {unzipPopupStatus && <PopupFromArchive />}
            {bankPopupStatus && <PopupOrganization />}
            <CustomLightbox controller={lightboxController} />
        </>
    )
}

export default PopupWrapper
