import '../../App.scss'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Dashboard from '../../../navigation/Dashboard/Dashboard'
import Navbar from '../../../navigation/Navbar/Navbar'
import Sidebar from '../../../navigation/Sidebar/Sidebar'
import PopupLoader from '~/components/common/preloaders/PopupLoader/PopupLoader'
import { useDebounce } from '~/hooks/useDebounce'
import { selectIsPageError, selectUser } from '~/redux/selectors/appSlice.selectors'
import { isStaffEdit } from '~/redux/selectors/popupSlice.selectors'

const pathsWithoutSidebar = new Set([
    '/archive',
    '/salary',
    '/staff',
])

const Wrapper = () => {
    const location = useLocation()
    const user = useSelector(selectUser)
    const isStaffSchedule = useSelector(isStaffEdit)
    const isPageError = useSelector(selectIsPageError)
    const [windowInnerWidth, setWindowInnerWidth] = useState<number>(window.innerWidth)

    const handleResize = () => {
        setWindowInnerWidth(window.innerWidth)
    }

    const debouncedResize = useDebounce(handleResize, 300)

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', debouncedResize)
        return () => {
            window.removeEventListener('resize', debouncedResize)
        }
    }, [])

    return (
        <>
            {user ? (
                <>
                    <div className='App__leftSide'>
                        {!isPageError && <Navbar />}
                        <Dashboard />
                    </div>
                    {(windowInnerWidth <= 1280 || !pathsWithoutSidebar.has(location.pathname) || isStaffSchedule) && !isPageError && (
                        <Sidebar />
                    )}
                </>
            ) : (
                <PopupLoader />
            )}
        </>
    )
}

export default Wrapper
