import { FaArchive } from '@react-icons/all-files/fa/FaArchive'
import { FaCalendarAlt } from '@react-icons/all-files/fa/FaCalendarAlt'
import { FaFileAlt } from '@react-icons/all-files/fa/FaFileAlt'
import { FaPlus } from '@react-icons/all-files/fa/FaPlus'
import { HiDotsCircleHorizontal } from '@react-icons/all-files/hi/HiDotsCircleHorizontal'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { IconType } from 'react-icons'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { rules } from '~/shared/order/rules'
import './MobileNavbar.scss'

interface ButtonParams {
    id: number
    pathTo: string
    onClick?: () => void
    icon: IconType
    name?: string
    rule: boolean
}

const MobileNavbar = () => {
    const user = useSelector(selectUser)
    const [isOpen, setOpen] = useState<boolean>(false)

    const buttons: Record<string, ButtonParams> = useMemo(() => {
        return {
            '/': {
                id: 1,
                pathTo: '/',
                icon: FaCalendarAlt,
                name: 'Календарь',
                rule: true,
            },
            '/events': {
                id: 2,
                pathTo: '/events',
                icon: FaFileAlt,
                name: 'События',
                rule: true,
            },
            '/order': {
                id: 3,
                pathTo: '/order',
                icon: FaPlus,
                rule: user?.role === 'CHIEF' || user?.role === 'ORDER_MANAGER' || user?.role === 'OPERATOR',
            },
            '/archive': {
                id: 4,
                pathTo: '/archive',
                icon: FaArchive,
                name: 'Архив',
                rule: !!(user && rules[user.role]?.pages.archive),
            },
            '/more': {
                id: 5,
                pathTo: '/more',
                icon: HiDotsCircleHorizontal,
                name: 'Ещё',
                rule: true,
            },

        }
    }, [user])

    const closeModal = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <div className='MobileNavbar'>
            {Object.values(buttons).map(
                (button, index) =>
                    (
                        <NavLink
                            key={`MobileNavbar_${index}`}
                            to={button.pathTo}
                            className={clsx('MobileNavbar__tags ', { 'createOrder': button.id === 3 })}
                        >
                            <button.icon
                                className={clsx({ 'createOrder__picture': button.id === 3 })}
                                onClick={button.onClick}
                            />
                            {button.id !== 3 && <p>{button.name}</p>}
                        </NavLink>
                    ),
            )}
            <Popup open={isOpen} onClose={closeModal}>
                <div className='modal'>
                    <div>
                        will be soon
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default MobileNavbar
