function getDayRange(str: string) {
    const matches = str.match(/\d+/g)
    if (!matches) return null

    const numbers = matches.map(Number)

    if (numbers.length === 1) {
        return { start: numbers[0], end: numbers[0] }
    }

    return { start: numbers[0], end: numbers[numbers.length - 1] }
}

export function calculateDayDifference(str: string) {
    const range = getDayRange(str)
    if (!range) return 0

    return range.end - range.start + 1
}
