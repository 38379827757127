import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { selectToken } from '~/redux/selectors/appSlice.selectors'

interface RequireAuthProps {
    children: JSX.Element
}

export const RequireAuth = ({ children }: RequireAuthProps) => {
    const location = useLocation()
    const token = useSelector(selectToken)

    if (!token) {
        return <Navigate to='/login' state={{ from: location }} replace />
    }

    return children
}
