import { FC, memo, ReactElement, useMemo } from 'react'
import CustomInput, { ICustomInput } from '~/components/common/inputs/CustomInput/CustomInput'
import withComment from '~/components/common/inputs/CustomInput/hocs/withComment'
import { IRulesComments } from '~/types/types.order'

export enum CustomInputHocType {
    COMMENT = 'comment',
}

type InputParams = ICustomInput & {
    hocType?: CustomInputHocType
    commentType?: keyof IRulesComments
}

const CustomInputWrapper = (inputParams: InputParams): ReactElement => {
    const { hocType, commentType, name } = inputParams

    const WrappedComponent = useMemo(() => {
        let Component: FC<ICustomInput> = CustomInput

        switch (hocType) {
            case CustomInputHocType.COMMENT:
                if (commentType) {
                    Component = withComment(Component, commentType)
                } else {
                    throw new Error('Не передан тип комментария')
                }
                break
            default:
        }

        return memo(Component)
    }, [commentType, hocType])


    return <WrappedComponent {...inputParams} key={name} />
}

export default CustomInputWrapper


