import { Path } from '~/API/lib/path/path.types'

const CLEANPROC_URL = import.meta.env.VITE_CLEANPROC_URL
const FILEUP_URL = import.meta.env.VITE_FILEUP_URL
const STUFF_URL = import.meta.env.VITE_STUFF_URL
const NOTIFIER_URL = import.meta.env.VITE_NOTIFIER_URL
const TOOLS_URL = import.meta.env.VITE_TOOLS_URL
const WS_URL = import.meta.env.VITE_WS_URL

export const path = {
    auth: STUFF_URL + '/auth',
    file: FILEUP_URL + '/file',
    norms: STUFF_URL + '/personnel/norms',
    order: CLEANPROC_URL + '/order',
    chat: CLEANPROC_URL + '/chat',
    ws: WS_URL + '/ws',
    organization: CLEANPROC_URL + '/organization',
    contractor: CLEANPROC_URL + '/contractor',
    personnel: STUFF_URL + '/personnel',
    salary: STUFF_URL + '/salary',
    schedule: STUFF_URL + '/schedule',
    cashFlow: STUFF_URL + '/cashflow',
    tools: TOOLS_URL,
    user: STUFF_URL + '/user',
    util: CLEANPROC_URL + '/util',
    notifier: NOTIFIER_URL,
    client: CLEANPROC_URL + '/client',
}

export const fetchPath = <T extends keyof Path>(service: T) => {
    return path[service]
}
