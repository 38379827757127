import { createSlice } from '@reduxjs/toolkit'
import { ArchiveSliceInitialState } from './ArchiveSlice.types'
import { fStageData, fStatusData, yearsData } from './ArchiveSliceMoreData'
import { Division } from '~/API/userApi/user.types'

const archiveSliceInitialState: ArchiveSliceInitialState = {
    archiveData: [],
    totalCountArchiveData: 0,
    isLoading: false,
    division: localStorage.getItem('division') !== undefined ?
        localStorage.getItem('division') as Division :
        'B2C',
    search: '',
    year: yearsData[0],
    pageSize: 10,
    currentPage: 0,
    stageGroup: null,
    orderStatus: null,
    sortBy: {
        path: '',
        order: '',
    },
    sortCount: 0,
    yearData: yearsData,
    fStatusData: fStatusData,
    fStageData: fStageData,
}

export const archiveSlice = createSlice({
    name: 'archive',
    initialState: archiveSliceInitialState,
    reducers: {
        setArchiveIsLoading: (state: ArchiveSliceInitialState, action) => {
            state.isLoading = action.payload
        },
        setArchiveData: (state: ArchiveSliceInitialState, action) => {
            state.archiveData = action.payload
        },
        setDivision: (state: ArchiveSliceInitialState, action) => {
            state.division = action.payload
        },
        setSearch: (state: ArchiveSliceInitialState, action) => {
            state.search = action.payload
        },
        setYear: (state: ArchiveSliceInitialState, action) => {
            state.year = action.payload
        },
        setPageSize: (state: ArchiveSliceInitialState, action) => {
            state.pageSize = action.payload
        },
        setCurrentPage: (state: ArchiveSliceInitialState, action) => {
            state.currentPage = action.payload
        },
        setSortBy: (state: ArchiveSliceInitialState, action) => {
            state.sortBy = action.payload
        },
        setStageGroup: (state: ArchiveSliceInitialState, action) => {
            state.stageGroup = action.payload
        },
        setOrderStatus: (state: ArchiveSliceInitialState, action) => {
            state.orderStatus = action.payload
        },
        setTotalCountArchiveData: (state: ArchiveSliceInitialState, action) => {
            state.totalCountArchiveData = action.payload
        },
        setSortCount: (state: ArchiveSliceInitialState, action) => {
            state.sortCount = action.payload
        },
    },
})

export const {
    setArchiveData,
    setArchiveIsLoading,
    setCurrentPage,
    setDivision,
    setOrderStatus,
    setPageSize,
    setSearch,
    setSortBy,
    setSortCount,
    setStageGroup,
    setTotalCountArchiveData,
    setYear,
} = archiveSlice.actions


export default archiveSlice.reducer
