import { FC, memo } from 'react'
import { TabsTableProps } from '~/components/common/ReusingComponents/TabsTable/TabsTable'
import { useToolsReportSubscription } from '~/hooks/useToolsReportSubscription/useToolsReportSubscription'

const withToolsReport = (TabsTable: FC<TabsTableProps>): FC<TabsTableProps> => {
    const WrappedComponent = (props: TabsTableProps) => {
        const { name } = props

        const {
            handleSubscriptionFieldsChange,
            isLoadingOrder,
        } = useToolsReportSubscription(name)

        return <TabsTable
            {...props}
            onSubscriptionFieldChange={handleSubscriptionFieldsChange}
            isLoading={isLoadingOrder}
        />
    }

    return memo(WrappedComponent)
}

export default withToolsReport
