import MoreVertIcon from '@mui/icons-material/MoreVert'
import Skeleton from '@mui/material/Skeleton'
import React, { useEffect, useState } from 'react'
import { UseFormGetValues } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { fetchFile, useLazyFetchFileModelQuery } from '~/API/fileApi/file'
import DocumentManager from '~/components/common/ReusingComponents/DocumentManager/DocumentManager'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { fetchFileRules } from '~/shared/order/filesRules'
import { fetchDate } from '~/shared/util/currentTime'
import { chooseSvg } from '~/shared/util/svg'
import './DocumentCard.scss'

interface DocumentCardProps {
    file: {
        id: string,
        inputName: string,
        name: string,
    }
    currentStage: string
    onDelete: (file: {
        id: string,
        inputName: string,
        name: string,
    }) => void
    onClick: (id: string, format: string) => void
    disabled?: boolean
    getValues?: UseFormGetValues<any>
}

const DocumentCard = (props: DocumentCardProps) => {
    const {
        file,
        currentStage,
        onDelete,
        onClick,
        disabled = false,
        getValues,
    } = props
    const [fetchFileModel] = useLazyFetchFileModelQuery()
    const user = useSelector(selectUser)
    const [active, setActive] = useState(false)
    const [fileModel, setFileModel] = useState<any>(null)
    const [fileRules, setFileRules] = useState<{ download: boolean, delete: boolean }>({
        download: false,
        delete: false,
    })

    const handleDeleteClick = () => {
        if (fileRules.delete && !disabled) {
            onDelete && onDelete(file)
            setActive(false)
        }
    }

    const handleClickDownload = () => {
        if (fileModel) {
            fetchFile(file.id).then((file: Blob) => {
                DocumentManager.saveFile(file, fileModel)
            })
        }
        setActive(false)
    }

    useEffect(() => {
        fetchFileModel({ fileId: file.id }, true).unwrap().then((fileModel: any) => {
            if (fileModel?.inputName === 'avatar') {
                setFileModel({
                    fileInfo: {
                        fileName: fileModel.fileName,
                        title: 'Аватар',
                        inputName: fileModel.inputName,
                        creator: fileModel.creator,
                        fileType: 'image/jpeg',
                    },
                    createdAt: new Date(),
                })
            } else {
                setFileModel(fileModel)
            }
        })
    }, [file])

    useEffect(() => {
        if (fileModel && user) {
            setFileRules(fetchFileRules({
                fileModel,
                user,
                currentStage,
                inputName: file.name,
                getValues,
            }))
        }
    }, [fileModel, currentStage, user])

    return (
        <div className='documentCard'>
            {fileModel ? (
                <>
                    <div
                        className='documentCard__logo'
                        onClick={() => onClick(file.id, DocumentManager.getDocumentFormat(fileModel.fileInfo.fileName))}
                    >
                        {chooseSvg(DocumentManager.getDocumentFormat(fileModel.fileInfo.fileName))}
                    </div>
                    <div className='documentCard__body'>
                        <h5 onClick={() => onClick(file.id, DocumentManager.getDocumentFormat(fileModel.fileInfo.fileName))}>
                            {fileModel.fileInfo.title}
                        </h5>
                        <p
                            className='documentCard__subscription'
                            onClick={() => onClick(file.id, DocumentManager.getDocumentFormat(fileModel.fileInfo.fileName))}
                            data-tooltip={fileModel.fileInfo.fileName}
                        >
                            {fileModel.fileInfo.fileName}
                        </p>
                        <p className='documentCard__date'>
                            {fetchDate(fileModel.createdAt).format('DD.MM.YY HH:mm')}
                        </p>
                    </div>
                    <MoreVertIcon className='documentCard__more' onClick={() => setActive((prev) => !prev)} />
                    {active && (
                        <>
                            <div
                                className='customField__wrapper'
                                onClick={() => {
                                    setActive(false)
                                }}
                            />
                            <ul className='documentCard__settings'>
                                <li onClick={handleClickDownload}>скачать</li>
                                {fileRules.delete && !disabled &&
                                    <li onClick={handleDeleteClick}>удалить</li>}
                            </ul>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Skeleton animation='wave' width={70} height={60} />
                    <div className='documentCard__body'>
                        <Skeleton className='documentCard__title' variant='text' animation='wave'>
                            <span>Документ</span>
                        </Skeleton>
                        <Skeleton className='documentCard__subscription' variant='text' animation='wave'>
                            <span>Unnamed13.05.2021.pdf</span>
                        </Skeleton>
                        <Skeleton className='documentCard__subscription' variant='text' animation='wave'>
                            <span>23.12.2020 14:56</span>
                        </Skeleton>
                    </div>
                </>
            )}
        </div>
    )
}

export default DocumentCard
