import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import CustomSelect, { CustomSelectThemes } from '../CustomSelect/CustomSelect'
import { CustomClientInputActions } from '~/components/common/inputs/CustomClientInput/CustomClientInput.types'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { CustomPopup } from '~/components/common/ReusingComponents/CustomPopup/CustomPopup'
import MobileCustomPopup from '~/components/mobile/mobileCommon/mobilePopups/MobileCustomPopup/MobileCustomPopup'
import { createInputs } from '~/hooks/useFormConstructor/useFormConstructor'
import { Data, InputParams, InputParamsItem } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { selectFormComment } from '~/redux/selectors/appSlice.selectors'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import {
    setClientAction,
    setClientPopupOpen,
    setClientPopupTitle,
    setClientStage,
} from '~/redux/slices/clientSlice/clientSlice'
import { useAppDispatch } from '~/redux/store'
import './CustomSubmitFormInput.scss'

interface CustomSubmitFormInputProps extends Pick<InputParams, 'value' | 'division' | 'submitButtonHandler' | 'onChange' | 'disabled'> {
    items: InputParamsItem<{
        title: string,
        body: Record<string, Record<string, Data>>
    }>[]
}

export const CustomSubmitFormInput = (props: CustomSubmitFormInputProps) => {
    const {
        value,
        division,
        submitButtonHandler,
        items,
        onChange,
        disabled,
    } = props

    const dispatch = useAppDispatch()
    const commentType = useSelector(selectFormComment)

    const currentItemsMap = items.reduce((accum, item) => {
        accum.set(item.value, item)
        return accum
    }, new Map())
    const [currentItem, setCurrentItem] = useState<InputParamsItem<{
        title: string,
        body: Record<string, Record<string, Data>>
    }> | null>(null)

    const [isPopupOpen, setPopupOpen] = useState(false)
    const [isMobilePopupOpen, setMobilePopupOpen] = useState(false)

    const methods = useFormContext()

    const { handleSubmit: formSubmit, getValues } = methods

    const onSubmit = () => {
        if (!currentItem?.value) {
            return
        }
        dispatch(
            changeFormAction({
                action: currentItem?.value,
                files: getValues().files,
                comment: getValues().comment,
                date: getValues().date,
            }),
        )
    }

    const handleSubmit = (value?: any) => {
        switch (currentItem?.withAdditionalPopup?.type) {
            case 'custom': {
                setPopupOpen(true)
                return
            }
            case 'clientRepeat': {
                dispatch(setClientAction(CustomClientInputActions.REPEAT))
                dispatch(setClientStage({ current: 2, amount: 2 }))
                dispatch(setClientPopupOpen(true))
                dispatch(setClientPopupTitle(currentItem?.withAdditionalPopup?.title || 'Тип клиента'))
                return
            }
            default: {
                if (submitButtonHandler) {
                    submitButtonHandler(value ? value : undefined)
                } else {
                    onSubmit()
                }
                return
            }
        }
    }

    const handleChange = useCallback((newValue: string) => {
        setCurrentItem(currentItemsMap.get(newValue) || null)
        onChange(newValue)
    }, [currentItemsMap])

    const handleClosePopup = useCallback(() => {
        setPopupOpen(false)
    }, [])

    const handleMobilePopup = useCallback((value: boolean) => {
        setMobilePopupOpen(value)
    }, [])

    const getSubmitFormBody = useMemo(() => {
        if (!!currentItem?.action?.body?.main.stageTransition?.inputs.length && submitButtonHandler) {
            return ({
                className: { title: 'custom-submit-form-input__input__body', index: -1 },
                data: createInputs({
                    tabName: 'main',
                    methods,
                    inputsTemplate: currentItem?.action.body,
                    submitButtonHandler: submitButtonHandler,
                    user: null,
                }) || [],
            })
        }
        return ({
            data: [],
        })
    }, [currentItem])

    useEffect(() => {
        if (!Object.values(items).some((item) => item.value === value)) {
            setCurrentItem(null)
        }
    }, [items])

    useEffect(() => {
        if (value) {
            handleChange(value)
        }
    }, [value])

    return (
        <>
            <div className='custom-submit-form-input'>
                {!isMobile && (
                    <hr className='custom-submit-form-input__separator' />
                )}
                <BrowserView className='custom-submit-form-input__body--desktop'>
                    <CustomSelect
                        {...props}
                        className='custom-submit-form-input__body--desktop__select'
                        theme={CustomSelectThemes.GREY}
                        value={Object.values(items).some((item) => item.value === value) ? value : null}
                        items={items}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                    <CustomButton
                        className='custom-submit-form-input__body--desktop__submit-btn'
                        onClick={handleSubmit}
                        theme={CustomButtonThemes.BORDERLESS}
                        disabled={disabled}
                    >
                        Выбрать
                    </CustomButton>
                </BrowserView>
                <MobileView className='custom-submit-form-input__body--mobile'>
                    <CustomButton
                        className='custom-submit-form-input__body--mobile__submit-btn'
                        onClick={() => handleMobilePopup(true)}
                        theme={CustomButtonThemes.COLOR}
                        disabled={disabled}
                    >
                        Выберите следующий шаг
                    </CustomButton>
                </MobileView>
            </div>
            {isPopupOpen && (
                <CustomPopup
                    className='custom-submit-form-input__popup'
                    onClose={handleClosePopup}
                    title={{ title: currentItem?.action?.title || 'Отправить' }}
                    body={getSubmitFormBody}
                    footer={[
                        <CustomButton
                            onClick={() => submitButtonHandler && submitButtonHandler(methods.getValues('comments'), commentType)}
                            title='Отправить'
                            key='CustomSubmitFormInput__button'
                        />,
                    ]}
                />
            )}
            {isMobilePopupOpen && (
                <MobileCustomPopup
                    modalIsOpen={isMobilePopupOpen}
                    closeModal={() => handleMobilePopup(false)}
                    handleClick={handleChange}
                    items={items}
                    currentItem={currentItem?.value}
                    buttons={{
                        cancelBtn: {
                            title: 'Отмена',
                            callback: () => handleMobilePopup(false),
                        },
                        submitBtn: {
                            title: 'Выбрать',
                            callback: () => {
                                handleSubmit(currentItem?.value)
                                handleMobilePopup(false)
                            },
                            disabled: !currentItem?.value,
                        },
                    }}
                />
            )}
        </>
    )
}
