import { RegisterOptions } from 'react-hook-form'

export enum ValidationType {
    ITEMS_NOT_NULL = 'itemsNotNull'
}

type ValidationMap = Record<ValidationType, (value: any, formValues: any) => string | boolean>

export const validateInput = (input: any, customValidationFunc?: (value: any, formValues: any) => string | boolean): RegisterOptions => {
    const rulesObj: RegisterOptions = {
        required: false,
        validate: {},
        ...input.rules,
    }

    if (input.required) {
        rulesObj.required = input.required
    }

    if (input.pattern) {
        rulesObj.pattern = new RegExp(input.pattern)
    }

    if (input.validationType) {
        const validationTypes: ValidationType[] = input.validationType.split(' ')

        validationTypes.forEach((item) => {
            if (typeof rulesObj.validate === 'object') {
                rulesObj.validate![item] = validationMap[item]
            }
        })
    }

    if (customValidationFunc) {
        if (typeof rulesObj.validate === 'object') {
            rulesObj.validate!.customValidation = customValidationFunc
        }
    }

    return rulesObj
}

const validationMap: ValidationMap = {
    itemsNotNull: (value: Array<any>) => {
        const errorIndexes: number[] = []

        value.forEach((item, index) => {
            if (item.items.length < 1) {
                errorIndexes.push(index)
            }
        })

        return errorIndexes.length > 0 ? errorIndexes.join(',') : true
    },
}
