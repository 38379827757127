import clsx from 'clsx'
import { selectActionIcon } from '~/components/common/inputs/CustomFormActionInput/CustomFormActionInput'

interface MobileCustomSelectItemProps {
    option: any
    handleClick: (value: any) => void
    index: number
    searchText: string
    isActive?: boolean
    onClick?: (...args: any[]) => void
}

export const MobileCustomSelectItem = (props: MobileCustomSelectItemProps) => {
    const {
        option,
        handleClick,
        index,
        isActive,
        onClick,
    } = props

    return (
        <li
            className={clsx('modalCustomSelect__header__item', { 'modalCustomSelect__header__item--active': isActive })}
            onClick={() => {
                handleClick(option.value)
                onClick && onClick(option.value, option?.action)
            }}
            key={option + '_option_' + index}
        >
            {option.icon ? selectActionIcon(option.icon) : null}
            {option.text}
        </li>
    )
}
