import clsx from 'clsx'
import './ClientServiceTable.scss'
import { ClientServiceTableItem } from '../ClientServiceTableItem/ClientServiceTableItem'
import { ClientServiceResponse } from '~/API/clientApi/client.types'

interface ClientServiceTableProps {
    className?: string
    data?: ClientServiceResponse['services']
}

export const ClientServiceTable = (props: ClientServiceTableProps) => {
    const {
        className,
        data = [],
    } = props

    return (
        <div className='client-service-table__wrapper'>
            <table className={clsx('client-service-table', className)}>
                <thead>
                    <tr>
                        <td className='client-service-table__amount'>
                            <span>Описание работ</span>
                        </td>
                        <td className='client-service-table__unit'>
                            <span>Кол-во</span>
                        </td>
                        <td className='client-service-table-item__price'>
                            <span>Ед. измерен.</span>
                        </td>
                        <td className='client-service-table-item__price'>
                            <span>Цена за ед.</span>
                        </td>
                        <td className='client-service-table-item__price'>
                            <span>Стоимость</span>
                        </td>
                        <td className='client-service-table-item__price'>
                            <span>Стоимость со скидкой</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {data.map((items, index) => {
                        return (
                            <ClientServiceTableItem
                                item={items}
                                key={items.name + items.measure + index}
                            />
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
