import { useContext } from 'react'
import { useWatch } from 'react-hook-form'
import { FaCheckCircle } from 'react-icons/fa'
import { TabsTableContext } from '~/components/common/ReusingComponents/TabsTable/TabsTableContext'

import './TabsTableCheckIcon.scss'

export enum CheckTypes {
    REQUIRED = 'required',
    NOT_NULL = 'notNull'
}

interface TabsTableCheckIcon {
    index: number
}

const TabsTableCheckIcon = (props: TabsTableCheckIcon) => {
    const { index } = props

    const {
        baseName,
        checkIcons,
    } = useContext(TabsTableContext)

    const pageValues = useWatch({
        name: `${baseName}.${index}.items`,
    })

    const isSuccess = checkIcons ? getSuccessStatus(pageValues, checkIcons.checkType, checkIcons.inputName) : false

    return (
        <FaCheckCircle size={16} className={isSuccess ? 'tabsTableCheckIcon--success' : 'tabsTableCheckIcon'} />
    )
}

export default TabsTableCheckIcon

const checkFunctions: Record<CheckTypes, (value: any) => boolean> = {
    [CheckTypes.REQUIRED]: (value: any) => !!value,
    [CheckTypes.NOT_NULL]: (value: any) => value != null,
}

export const getSuccessStatus = (values: any[], checkType: CheckTypes, inputName: string): boolean => {
    if (!values || values.length === 0) return false

    return values.every((value) => checkFunctions[checkType](value[inputName]))
}
