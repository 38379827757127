import { useCallback, useEffect, useMemo, useState } from 'react'
import { useStompClient, useSubscription } from 'react-stomp-hooks'
import { Message, MessageCreateInput } from './useChat.types'
import { useLazyFetchMessagesQuery } from '~/API/orderApi/order'

interface useChatParams {
    id?: string
}

export const useChat = ({ id }: useChatParams) => {
    const [fetchMessages, { isLoading }] = useLazyFetchMessagesQuery()

    const stompClient = useStompClient()
    const [messages, setMessages] = useState<Message[]>([])

    useSubscription(`/queue/${id}`, (msg: { body: string }) => {
        setMessages((prevState) => ([...prevState, JSON.parse(msg.body)]))
    })

    useEffect(() => {
        if (id) {
            fetchMessages(id).unwrap()
                .then((res) => {
                    setMessages(res)
                })
        }
    }, [id, fetchMessages])

    const send = useCallback((payload: MessageCreateInput) => {
        if (stompClient) {
            stompClient.publish({ destination: '/api/cleanproc/chat', body: JSON.stringify(payload) })
        }
    }, [stompClient])

    const chatActions = useMemo(
        () => ({
            send,
        }),
        [stompClient],
    )

    return { messages, chatActions, isLoading }
}
