import { fetchDate } from '../../util/currentTime'
import { ClientInfoType } from '~/API/orderApi/types/primaryInformation.type'
import { PaymentType } from '~/components/pages/KP/kp.types'
import { FotItem } from '~/components/pages/Smeta/smeta.types'
import { calculateDayDifference } from '~/components/pages/Smeta/utils/calculateDayDifference'
import { AmortizationItemWithNumberTotal, TmcItemWithNumberTotal } from '~/shared/templates/utils/createNzFile'
import { formatRUB } from '~/shared/util/formatNumberWithIntlOptions'

interface TeamItem {
    title: string
    taxiThere: string
    taxiBack: string
    petrol: string
    parking: string
    count: number
    salary: number
    personnel: Omit<FotItem, 'totalAmount'>[]
    manager: string
    serviceName: string
}

interface TeamTable {
    result: HTMLDivElement
    newPage: HTMLDivElement
    leftSide: HTMLDivElement
    rightSide: HTMLDivElement
}

export interface NzFrontValues {
    cleaningDate: {
        startDate: string
        endDate: string
    }
    responsibleManager: string,
    nzNumber: string
    serviceType: string
    buildingType: string
    buildingClass: string
    contactInfo: ClientInfoType[]
    cost: number
    prepayment: number | null
    prepaymentType: string | null
    paymentType: PaymentType
    organizationName?: string
    counterparty: string
    address: string
    objectArea: number
    cleaningTask: string
    tools: {
        type: string,
        title: string,
        items: TmcItemWithNumberTotal[] | AmortizationItemWithNumberTotal[]
    }[]
    amortizationTotal: number
    inventoryTotal: number
    wageFund: TeamItem[][]
    otherExpanses: string
}

export const nzFrontNew = async (values: NzFrontValues) => {
    const html = await fetch('/html/newNz.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    const domHtml = parser.parseFromString(htmlText, 'text/html')
    const nzDate = fetchDate().local().format('DD.MM.YY')
    const nzTime = fetchDate().local().format('HH:mm')
    const cleaningDate = fetchDate(values.cleaningDate.startDate).format('DD.MM.YY HH:mm')
    const cleaningEndDate = fetchDate(values.cleaningDate.endDate).format('DD.MM.YY HH:mm')

    domHtml.querySelector('#nzDate')!.innerHTML = nzDate
    domHtml.querySelector('#nzTime')!.innerHTML = nzTime
    domHtml.querySelector('#nzNumber')!.innerHTML = values.nzNumber
    domHtml.querySelector('#responsibleManager')!.innerHTML = values.responsibleManager
    domHtml.querySelector('#serviceType')!.innerHTML = values.serviceType
    // TODO: как должны работать доп услуги

    // domHtml.querySelector('#serviceType')!.innerHTML =
    //     values.serviceType + (values.additionalService?.length > 0 ? ' + ' + values.additionalService.join(', ') : '')
    domHtml.querySelector('#buildingClass')!.innerHTML = values.buildingClass.includes('Коттедж') ?
        values.buildingType :
        values.buildingType + ' ' + values.buildingClass

    domHtml.querySelector('#trBeforeName')!.insertAdjacentHTML(
        'afterend',
        values.contactInfo
            .map(
                (ci) => `<tr>
                <td colspan='4' class='left'>
                    <span class='bold'>Имя клиента:</span> <span>${ci.clientFullname}</span>
                </td>
                <td colspan='3' class='left'>
                    <span class='bold'>Телефон:</span> <span>${ci.clientPhone}</span>
                </td>
            </tr>`,
            )
            .join(''),
    )

    domHtml.querySelectorAll('.cleaningDate').forEach((e) => (e.innerHTML = cleaningDate))
    domHtml.querySelectorAll('.cost').forEach((e) => (e.innerHTML = formatRUB(values.cost, false)))

    if (values.prepayment && values.prepaymentType) {
        domHtml.querySelector('#prepayment')!.innerHTML = formatRUB(values.prepayment, false) + ' (' + values.prepaymentType + ')'
    }
    domHtml.querySelector('#paymentType')!.innerHTML = values.paymentType
    domHtml.querySelectorAll('.organizationName').forEach((e) => (e.innerHTML = values.organizationName || ''))
    if (values.counterparty === 'Юр. лицо') {
        domHtml.querySelectorAll<HTMLTableRowElement>('.individuals-rows').forEach((e) => (e.style.display = 'none'))
    } else {
        domHtml.querySelectorAll<HTMLTableRowElement>('.legals-rows').forEach((e) => (e.style.display = 'none'))
    }
    domHtml.querySelector('#address')!.innerHTML = values.address
    domHtml.querySelector('#objectArea')!.innerHTML = values.objectArea + ' м<sup>2</sup>'

    const { salary, count } = values.wageFund.flat().reduce((acc, item) => {
        acc.salary += item.salary
        acc.count += item.count

        return acc
    }, { salary: 0, count: 0 })

    // TODO: как считать время выполнения

    domHtml.querySelector('#durationCleaningTime')!.innerHTML = '-'
    domHtml.querySelector('#cleanerNumber')!.innerHTML = count.toString()
    domHtml.querySelector('#consumables')!.innerHTML = formatRUB(Math.floor(values.inventoryTotal), false)
    domHtml.querySelector('#amortization')!.innerHTML = formatRUB(Math.floor(values.amortizationTotal), false)
    domHtml.querySelector('#employeesSalary')!.innerHTML = formatRUB(salary, false)
    domHtml.querySelector('#otherExpanses')!.innerHTML = formatRUB(Number(values.otherExpanses || 0), false)

    // TODO:нужны ли чекбоксы?

    // domHtml.querySelector('#documents_contract').checked = values.documentsStatus.includes('Договор')
    // domHtml.querySelector('#documents_UPD').checked = values.documentsStatus.includes('Акт(УПД)')
    // domHtml.querySelector('#documents_register').checked = values.documentsStatus.includes('Опись')
    // domHtml.querySelector('#documents_checkList').checked = values.documentsStatus.includes('Чек-лист/Буклет')
    // domHtml.querySelector('#documents_account').checked = values.documentsStatus.includes('Счёт(чек)')
    // domHtml.querySelector('#documents_gift').checked = values.documentsStatus.includes('Подарок')

    const cleaningTaskElement = domHtml.querySelector<HTMLTableCellElement>('#cleaningTask')
    cleaningTaskElement!.innerHTML = values.cleaningTask
    const fontSizeCleaningTask = 12
    const task = values.cleaningTask
    let countLine = 0
    let pos = 0
    while (pos !== -1) {
        const newPos = task.indexOf('\n', pos + 1)
        countLine += ~~(((newPos === -1 ? task.length : newPos) - pos) / 80) + 1
        pos = newPos
    }

    // const sumTeam = values.team.map((day) => {
    //     return day.values.reduce(
    //         (accum, e) => ({
    //             count: day.cleanerNumber,
    //             salary: day.employeesSalary,
    //             taxiThere: parseInt(day.logisticExpansesTaxiRouteThere),
    //             taxiBack: parseInt(day.logisticExpansesTaxiRouteBack),
    //             taxi: parseInt(day.logisticExpansesTaxiRouteBack) + parseInt(day.logisticExpansesTaxiRouteThere),
    //             petrol: day.logisticExpansesPetrolRoute,
    //             note: e.note,
    //             personnel: day.values,
    //             foreman: day.foreman,
    //             cleaningDate: day.cleaningDate,
    //         }),
    //         {},
    //     )
    // })
    //
    // Нужно проверять количество символов в строке после смены размера шрифта + менять шрифт на людях
    // const maxAvailableLine = countLine + (values.team.length > 4 ? sumTeam.length : sumTeam.length * 2)
    // if (maxAvailableLine > 22) {
    //     fontSizeCleaningTask = 10
    // }
    // if (maxAvailableLine > 24) {
    //     fontSizeCleaningTask = 9
    // }
    // if (maxAvailableLine > 28) {
    //     fontSizeCleaningTask = 8
    // }
    // if (maxAvailableLine > 32) {
    //     fontSizeCleaningTask = 7
    // }
    // if (maxAvailableLine > 36) {
    //     fontSizeCleaningTask = 6
    // }
    // cleaningTaskElement!.style.fontSize = `${fontSizeCleaningTask}px`


    // /////////////////////////////////TEAM/////////////////////////////////////////////////////

    const teamTbody = values.wageFund.map((service) => {
        const serviceTitle = `<tr>
            <td colspan='10'>${service[0].serviceName}</td>
        </tr>`

        const periodRows = service.map((period, index) => {
            const dayCount = calculateDayDifference(period.title)
            const total = (+period.taxiThere + +period.taxiBack + +period.petrol + +period.parking) * dayCount

            return `<tr>
                <td>${index + 1}</td>
                <td style='font-size: 8px'>${period.title}</td>
                <td>${period.count}</td>
                <td>${formatRUB(period.salary, false)}</td>
                <td>${formatRUB(Number(period.taxiThere || 0), false)}</td>
                <td>${formatRUB(Number(period.taxiBack || 0), false)}</td>
                <td>${formatRUB(Number(period.petrol || 0), false)}</td>
                <td>${formatRUB(Number(period.parking || 0), false)}</td>
                <td>${formatRUB(total, false)}</td>
                <td>${period.manager}</td>
            </tr>`
        })

        return serviceTitle + periodRows.join('')
    })
    domHtml.querySelector('#team')!.innerHTML = teamTbody.join('')
    domHtml.querySelector<HTMLTableSectionElement>('#team')!.style.fontSize = '12px'

    const tableTeamTemplate = domHtml.querySelector('#teamTemplate')!
    const teamPages = document.createElement('div')
    const constructTable = (period: TeamItem) => {
        const table = tableTeamTemplate.cloneNode(true) as HTMLDivElement
        table.querySelector('#dayDate')!.innerHTML = values.wageFund.length > 1 ? `${period.title} - ${period.serviceName}` : period.title
        const fontSizeTeam = period.personnel.length > 8 ? 10 : 12
        const teamTbody = period.personnel.map(
            (p, index) => `<tr>
            <td>${index + 1}</td>
            <td>${p.name}</td>
            <td>${p.area}</td>
            <td>${p.workHours}</td>
            <td>${p.average}</td>
            <td>${formatRUB(Number(p.salary || 0), false)}</td>
            <td>${p.amount}</td>
            `,
        )
        table.querySelector('#dayTeam')!.innerHTML = teamTbody.join('')
        table.style.fontSize = fontSizeTeam + 'px'
        return table
    }

    // Если день только один то добавляем его на 1 страницу, в остальных случаях генерим новые страницы
    if (values.wageFund.flat().length === 1) {
        const teamTable = constructTable(values.wageFund.flat()[0])

        domHtml.querySelector('#teamTableWrapper')!.remove()
        domHtml.querySelector('#teamTableSingleDayWrapper')!.appendChild(teamTable)
    } else {
        const teamTable = values.wageFund.flat().reduce<TeamTable>(
            // @ts-ignore
            (accum, d, i) => {
                const p = i % 4
                if (p === 0) {
                    const newPage = document.createElement('div')
                    newPage.classList.add('page')
                    const leftSide = document.createElement('div')
                    leftSide.classList.add('page__leftSide')
                    const table = constructTable(d)
                    leftSide.appendChild(table)
                    if (i === values.wageFund.flat().length - 1) {
                        newPage.appendChild(leftSide)
                        accum.result.appendChild(newPage)
                    }
                    return { result: accum.result, newPage, leftSide }
                }
                if (p === 1) {
                    const table = constructTable(d)
                    const { newPage, leftSide } = accum
                    leftSide.appendChild(table)
                    newPage.appendChild(leftSide)
                    if (i === values.wageFund.flat().length - 1) {
                        accum.result.appendChild(newPage)
                    }
                    return { result: accum.result, newPage }
                }
                if (p === 2) {
                    const rightSide = document.createElement('div')
                    rightSide.classList.add('page__rightSide')
                    const table = constructTable(d)
                    rightSide.appendChild(table)
                    if (i === values.wageFund.flat().length - 1) {
                        accum.newPage.appendChild(rightSide)
                        accum.result.appendChild(accum.newPage)
                    }
                    return { result: accum.result, newPage: accum.newPage, rightSide }
                }
                if (p === 3) {
                    const table = constructTable(d)
                    const { newPage, rightSide } = accum
                    rightSide.appendChild(table)
                    newPage.appendChild(rightSide)
                    accum.result.appendChild(newPage)
                    return { result: accum.result }
                }
            },
            { result: teamPages },
        )

        domHtml.querySelector('#teamTableWrapper')!.innerHTML = ''
        // @ts-ignore
        domHtml.querySelector('#teamTableWrapper')!.appendChild(teamTable.result)
    }

    // /////////////////////////////////////TOOLS/////////////////////////////////////////////
    let index = 1

    let toolsTbody = values.tools.map((tools) => {
        let groupPrice = 0
        const groupTools = tools.items
            .map((tool) => {
                const toolPrice = +tool.total

                groupPrice += toolPrice

                // @ts-ignore
                return `<tr><td>${index++}</td><td>${tool.name}</td><td>${tool.measure || 'шт'}</td><td>${
                    tool.amount
                }</td><td>${toolPrice === 0 ? '' : formatRUB(toolPrice, false)}</td></tr>`
            })
            .join('')

        const header = `<tr><td colspan='5' class='bold'>${tools.title} (${formatRUB(groupPrice, false)})</td></tr>`
        return header + groupTools
    }).join('')

    let fontSize = 14
    let fontSizeSign = 14
    if (index > 18) {
        fontSize = 12
    }
    if (index > 26) {
        fontSize = 10
        fontSizeSign = 12
    }
    if (index > 36) {
        fontSize = 9
        fontSizeSign = 10
    }
    if (index > 44) {
        fontSize = 8
    }
    if (index > 51) {
        fontSize = 7
    }
    if (index > 55) {
        fontSize = 6
    }
    domHtml.querySelector<HTMLTableElement>('.tableTools')!.style.fontSize = `${fontSize}px`
    toolsTbody =
        toolsTbody +
        `<tr style='font-size:${fontSizeSign}px'>
        <td colspan='2'>
            <p class='center marginBottom4 bold'>Выдал</p>
            <p class='marginBottom4'>ФИО: ______________________________</p>
            <p style='margin-bottom: 2px'>Подпись: ____________________________</p>
        </td>
        <td colspan='3'>
            <p class='center marginBottom4 bold'>Принял</p>
            <p class='marginBottom4'>ФИО: ____________________________</p>
            <p style='margin-bottom: 2px'>Подпись: _________________________</p>
        </td>
    </tr>`
    domHtml.querySelector('#tools')!.innerHTML = toolsTbody

    const allDoc = domHtml.querySelector('body')!.innerHTML
    // if (values.documentsStatus?.includes('Подарок')) {
    //     allDoc = allDoc.replace('id="documents_gift"', 'id="documents_gift" checked="true"')
    // }
    // if (values.documentsStatus?.includes('Счёт(чек)')) {
    //     allDoc = allDoc.replace('id="documents_account"', 'id="documents_account" checked="true"')
    // }
    // if (values.documentsStatus?.includes('Опись')) {
    //     allDoc = allDoc.replace('id="documents_register"', 'id="documents_register" checked="true"')
    // }
    // if (values.documentsStatus?.includes('Чек-лист/Буклет')) {
    //     allDoc = allDoc.replace('id="documents_checkList"', 'id="documents_checkList" checked="true"')
    // }
    // if (values.documentsStatus?.includes('Договор')) {
    //     allDoc = allDoc.replace('id="documents_contract"', 'id="documents_contract" checked="true"')
    // }
    // if (values.documentsStatus?.includes('Акт(УПД)')) {
    //     allDoc = allDoc.replace('id="documents_UPD"', 'id="documents_UPD" checked="true"')
    // }

    return { style: domHtml.querySelector('style')!.innerHTML, body: allDoc }
}

