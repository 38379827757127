import './TabsTableSubtotalFooter.scss'

interface TabsTableSubtotalFooterProps {
    value: string | number
    text?: string
}

const TabsTableSubtotalFooter = (props: TabsTableSubtotalFooterProps) => {
    const {
        value,
        text = 'Итого:',
    } = props

    return (
        <div className='tabsTableSubtotalFooter'>
            <span className='tabsTableSubtotalFooter__label'>{text}</span>
            <span className='tabsTableSubtotalFooter__value'>{value}</span>
        </div>
    )
}

export default TabsTableSubtotalFooter
