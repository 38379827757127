import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import CustomFileInput from './CustomFileInput/CustomFileInput'
import { useDebounce } from '~/hooks/useDebounce'
import { OPEN_LIGHTBOX } from '~/redux/slices/appSlice/appSlice'
import { CHANGE_MENU_TYPE, changeStaffFormMenu } from '~/redux/slices/orderSlice/orderSlice'
import './CustomFileInputGroup.scss'

export type AllowedFileTypes = 'application' | 'audio' | 'image' | 'video' | 'other'

interface CustomFileInputGroupProps {
    values: any[],
    title: string,
    button?: string,
    max?: number,
    name: string,
    onChange: (value: any) => void,
    disabled?: boolean,
    error?: FieldError,
    className?: string,
    allowedFileTypes?: AllowedFileTypes[],
}

const CustomFileInputGroup = (props: CustomFileInputGroupProps) => {
    const {
        values,
        title,
        button,
        max,
        name,
        onChange,
        disabled,
        error,
        className,
        allowedFileTypes,
    } = props
    const dispatch = useDispatch()
    const [readFileGroupParams, setReadFileGroupParams] = useState({
        readMoreButton: 200,
        inputWidth: 200,
        margin: 25,
        newInputWidth: 200,
        show: false,
        max: 1,
    })
    const [images, setImages] = useState<any[]>(values)

    const fileGroupWrapper = useRef<HTMLDivElement>(null)
    const fileInput = useRef()

    const openLightboxOnSlide = (number: number) => {
        dispatch(OPEN_LIGHTBOX({ sources: [...values], sourceIndex: number }))
    }

    const handleResize = (files: string[]) => {
        if (!fileGroupWrapper.current) return
        const wrapperWidth = fileGroupWrapper.current.clientWidth
        let readMoreButton = 200 // 90
        let inputWidth = 200
        let margin = 15
        let newInputWidth = 200
        if (wrapperWidth < 1200) {
            readMoreButton = 200 // 75
            inputWidth = 200 // 175
            margin = 10
            newInputWidth = 200 // 40
        }
        if (wrapperWidth < 700) {
            readMoreButton = 200 // 60
            inputWidth = 200 // 150
            margin = 10
            newInputWidth = 200 // 40
        }
        if (wrapperWidth < 500) {
            readMoreButton = 40 // 40
            inputWidth = 200 // 120
            margin = 10
            newInputWidth = 30 // 30
        }
        if (wrapperWidth < 300) {
            readMoreButton = 30 // 30
            inputWidth = 90
            margin = 10
            newInputWidth = 25
        }
        let max = Math.floor((wrapperWidth - inputWidth - margin) / (inputWidth + margin))
        if (files.length > max) {
            max = Math.floor((wrapperWidth - readMoreButton - newInputWidth - margin) / (inputWidth + margin))
            setReadFileGroupParams({
                readMoreButton: readMoreButton,
                inputWidth: inputWidth,
                margin: margin,
                newInputWidth: 200,
                show: true,
                max: disabled ? Math.max(max, 0) + 1 : Math.max(max, 0),
            })
            return
        }
        if (readFileGroupParams.show) {
            setReadFileGroupParams({
                readMoreButton: readMoreButton,
                inputWidth: inputWidth,
                margin: margin,
                newInputWidth: 200,
                show: false,
                max: disabled ? files.length + 1 : files.length,
            })
            return
        }
        setReadFileGroupParams((prev) => {
            return {
                ...prev,
                max,
            }
        })
    }

    const debouncedResize = useDebounce(() => handleChange([]), 300)

    const handleChange = useCallback((fileId: string | string[]) => {
        setImages((prev) => {
            let newValues = [...prev]
            if (Array.isArray(fileId)) {
                newValues = [...newValues, ...fileId]
            } else {
                newValues = [...newValues, fileId]
            }
            onChange(newValues)
            handleResize(newValues)
            return newValues
        })
    }, [images])

    const handleClick = (fileId: string) => {
        const index = values.findIndex((value: any) => value === fileId)
        if (index !== -1) {
            openLightboxOnSlide(index)
        }
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            setImages(values)
        }

        return () => {
            isMounted = false
        }
    }, [values])

    useEffect(() => {
        handleResize(images)
        window.addEventListener('resize', debouncedResize)
        return () => {
            window.removeEventListener('resize', debouncedResize)
        }
    }, [])

    return (
        <div className={clsx('customFileInputGroup__wrapper', className)}>
            {(!disabled || !!values.length) &&
                <>
                    {title && (
                        <h5 className={clsx('customFileInputGroup__title', { error })}>
                            {title}
                        </h5>
                    )}
                    <div
                        className={clsx('customFileInputGroup',
                                        {
                                            'items-left': images.length <= readFileGroupParams.max,
                                        },
                        )}
                        ref={fileGroupWrapper}
                    >
                        {!disabled && (
                            <CustomFileInput
                                onChange={handleChange}
                                className={clsx('', { 'active': readFileGroupParams.show })}
                                style={{
                                    width: readFileGroupParams.show ?
                                        readFileGroupParams.newInputWidth : readFileGroupParams.inputWidth,
                                }}
                                fileInputRef={fileInput}
                                title={title}
                                multiple={true}
                                name={name}
                                disabled={disabled}
                                button={readFileGroupParams.show ? '' : button}
                                fileRules={{
                                    write: false,
                                    upload: true,
                                }}
                                allowedFileTypes={allowedFileTypes}
                            />
                        )}
                        {readFileGroupParams.show ? (
                            <>
                                {new Array(Math.min(readFileGroupParams.max, images.length))
                                    .fill(0)
                                    .map((_, index) => (
                                        <CustomFileInput
                                            title=''
                                            key={`${name}_fileInput_${index}`}
                                            fileId={values[index]}
                                            style={{
                                                width: readFileGroupParams.inputWidth,
                                            }}
                                            name={name + '_' + index}
                                            onChange={() => {
                                            }}
                                            onClick={handleClick}
                                            button={button}
                                            fileRules={{
                                                write: false,
                                                upload: false,
                                            }}
                                            allowedFileTypes={allowedFileTypes}
                                        />
                                    ),
                                    )}
                                {images.length > readFileGroupParams.max && (
                                    <button
                                        className='customFileInputGroup__readMoreButton'
                                        style={{
                                            width: readFileGroupParams.inputWidth,
                                        }}
                                        type='button'
                                        onClick={() => {
                                            dispatch(changeStaffFormMenu('DOCS'))
                                            dispatch(CHANGE_MENU_TYPE('MENU_DOCUMENTS'))
                                        }}
                                    >
                                        ещё +{images.length - readFileGroupParams.max}
                                    </button>
                                )}
                            </>
                        ) : (
                            <>
                                {values && values.map((fileId: string, index: number) =>
                                    <CustomFileInput
                                        fileId={fileId}
                                        title=''
                                        key={`${name}_fileInput_${index}`}
                                        style={{
                                            width: readFileGroupParams.inputWidth,
                                        }}
                                        onChange={() => {
                                        }}
                                        name={name + '_' + index}
                                        onClick={handleClick}
                                        button={button}
                                        fileRules={{
                                            write: false,
                                            upload: false,
                                        }}
                                        allowedFileTypes={allowedFileTypes}
                                    />,
                                )}
                            </>
                        )}
                    </div>
                </>
            }
        </div>
    )
}

export default CustomFileInputGroup
