function moneyToRanks(input: any) {
    let result = ''
    const currency = [
        ['', 'тысяч', 'миллион', 'миллиард', 'триллион', 'квадриллион', 'квинтиллион', 'секстиллион', 'септиллион', 'октиллион', 'нониллион', 'дециллион'],
        ['а', 'и', ''],
        ['', 'а', 'ов'],
    ]
    if (input === '' || input === '0') return ' ноль' // 0
    input = input.split(/(?=(?:\d{3})+$)/)
    if (input[0].length === 1) input[0] = '00' + input[0]
    if (input[0].length === 2) input[0] = '0' + input[0]
    for (let j = input.length - 1; j >= 0; j--) {
        if (input[j] !== '000') {
            result =
                (j === input.length - 2 && (input[j][2] === '1' || input[j][2] === 1 || input[j][2] === '2' || input[j][2] === 2) ? morphMoneyToString(input[j], 1) : morphMoneyToString(input[j])) +
                declensionOfInput(input[j], currency[0][input.length - 1 - j], j === input.length - 2 ? currency[1] : currency[2]) +
                result
        }
    }

    function morphMoneyToString(input: any, units?: any) {
        // преобразовать трёхзначные числа
        const currency = [
            ['', ' один', ' два', ' три', ' четыре', ' пять', ' шесть', ' семь', ' восемь', ' девять'],
            [' десять', ' одиннадцать', ' двенадцать', ' тринадцать', ' четырнадцать', ' пятнадцать', ' шестнадцать', ' семнадцать', ' восемнадцать', ' девятнадцать'],
            ['', '', ' двадцать', ' тридцать', ' сорок', ' пятьдесят', ' шестьдесят', ' семьдесят', ' восемьдесят', ' девяносто'],
            ['', ' сто', ' двести', ' триста', ' четыреста', ' пятьсот', ' шестьсот', ' семьсот', ' восемьсот', ' девятьсот'],
            ['', ' одна', ' две'],
        ]
        return currency[3][input[0]] + (input[1] == 1 ? currency[1][input[2]] : currency[2][input[1]] + (units ? currency[4][input[2]] : currency[0][input[2]]))
    }

    return result
}

function declensionOfInput(input: any, currency: string, declension: any) {
    const arr = [2, 0, 1, 1, 1, 2, 2, 2, 2, 2]
    return currency === '' ? '' : ' ' + currency + (input[input.length - 2] === '1' ? declension[2] : declension[arr[input[input.length - 1]]])
}

function firstUpperCase(currency: string) {
    return currency[1].toUpperCase() + currency.substring(2)
}

export function moneyToString(input: string | number) {
    const _input = Number(input).toFixed(2).split('.')
    return firstUpperCase(moneyToRanks(_input[0]) + declensionOfInput(_input[0], 'рубл', ['ь', 'я', 'ей']))
}

export const divideByBills = (rubles: any, bills = [5000, 1000, 500, 100, 50, 1]) => {
    const result: Record<number, number> = {}
    bills.reduce((accum, bill) => {
        if (accum >= bill) {
            result[bill] = Math.floor(accum / bill)
        }
        return accum % bill
    }, rubles)
    return result
}

export const divideArrayByBills = (arrayRub: any[]) => {
    if (!arrayRub) return null

    const result = arrayRub.reduce((accum, rub) => {
        let newAccum = { ...accum }
        Object.entries(divideByBills(rub)).forEach(([bill, amount]) => {
            newAccum = { ...newAccum, [bill]: (newAccum[bill] || 0) + amount }
        })
        return newAccum
    }, {})
    return Object.entries(result).reduce((accum: any[], [bill, amount]) => {
        return [...accum, bill + ' x ' + amount]
    }, []).join('\n')
}
