import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        archive: false,
        unzip: false,
        prompt: false,
        bank: false,
        staffEdit: false,
        orderToUpdateFromArchive: '',
    },
    reducers: {
        showArchivePopup: (state) => {
            state.archive = true
        },
        closeArchivePopup: (state) => {
            state.archive = false
        },
        showUnzipPopup: (state) => {
            state.unzip = true
        },
        closeUnzipPopup: (state) => {
            state.unzip = false
        },
        showBankPopup: (state) => {
            state.bank = true
        },
        closeBankPopup: (state) => {
            state.bank = false
        },
        CLOSE_POPUP_PROMPT: (state) => {
            state.prompt = false
        },
        OPEN_POPUP_PROMPT: (state) => {
            state.prompt = true
        },
        showStaffEdit: (state) => {
            state.staffEdit = true
        },
        hideStaffEdit: (state) => {
            state.staffEdit = false
        },
        setOrderToUpdateFromArchive: (state, action: PayloadAction<string>) => {
            state.orderToUpdateFromArchive = action.payload
        },
    },
})

export const {
    showArchivePopup,
    closeArchivePopup,
    showUnzipPopup,
    closeUnzipPopup,
    showBankPopup,
    closeBankPopup,
    CLOSE_POPUP_PROMPT,
    OPEN_POPUP_PROMPT,
    showStaffEdit,
    hideStaffEdit,
    setOrderToUpdateFromArchive,
} = popupSlice.actions

export default popupSlice.reducer
