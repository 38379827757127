import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import { CleaningSlot, StageChangeEvent } from '~/redux/slices/orderSlice/types/orderSlice.type'
import { IFilterProps } from '~/types/types.filter'

export const filterEvents = (events: any, filter: IFilterProps, isNeedUniq: boolean = true) => {
    let newEvents: any[] = []
    if (filter.events.tasks.length > 0) {
        newEvents = [...newEvents, ...filterTasks(events.tasks, filter)]
    }
    if (filter.events.closedSlots.length > 0) {
        newEvents = [...newEvents, ...filterClosedSlots(events.closedSlots, filter)]
    }
    if (filter.events.emptySlots.length > 0) {
        newEvents = [...newEvents, ...filterEmptySlots(isNeedUniq ? events.emptySlotsUniq : events.emptySlots, filter)]
    }
    newEvents = filterByEmployees(newEvents as CleaningSlot & any[], filter)
    newEvents = filterByServiceTypes(newEvents as CleaningSlot & any[], filter)
    newEvents = filterByCounterparty(newEvents as CleaningSlot & any[], filter)

    return newEvents
}
export const filterEmptySlots = (events: CleaningSlot & any[], filters: IFilterProps) => {
    return events.filter((personnelEvent) => filters.events.emptySlots.some((emptySlot) => emptySlot === personnelEvent.stageGroup))
}

const hiddenStages: StageType[] = [
    'NEED_CHANGES_TO_ORDER_CREATING',
    'COMMERCIAL_OFFER_APPROVING',
    'ORDER_APPROVING',
    'ORDER_CREATING',
    'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING',
]
export const checkHiddenStages = (stage: StageType, isDateBooked: boolean, isInspectionInPast: boolean) => {
    if ((stage === 'ORDER_APPROVING' || stage === 'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING') && isDateBooked) {
        return false
    }
    return hiddenStages.includes(stage) && !isInspectionInPast
}

export const filterClosedSlots = (events: CleaningSlot & any[], filters: IFilterProps) => {
    return events.filter((event: CleaningSlot) => {
        if (checkHiddenStages(event.stageName, !!event.isDateBooked, !!event.isInspectionInPast)) return false
        return filters.events.closedSlots.some((closedSlot) => {
            switch (closedSlot) {
                case 'inspection':
                    return event.stageGroup === 'inspection'
                case 'complex':
                case 'cleaning':
                    return event.stageGroup !== 'inspection' && event.serviceType !== 'Поддерживающая уборка' && event.serviceType !== 'Остекление' && event.serviceType !== 'Ежедневная уборка (обслуживание)'
                case 'maintenance':
                    return event.serviceType === 'Поддерживающая уборка' && !event.isInspectionInPast && event.stageGroup !== 'inspection'
                case 'glazing':
                    return event.serviceType === 'Остекление' && !event.isInspectionInPast && event.stageGroup !== 'inspection'
                case 'handling':
                    return event.serviceType === 'Ежедневная уборка (обслуживание)' && !event.isInspectionInPast && event.stageGroup !== 'inspection'
                default:
                    return false
            }
        })
    })
}

export const filterTasks = (events: CleaningSlot & any[], filters: IFilterProps) => {
    return events.filter((event: CleaningSlot) => {
        return filters.events.tasks.some((task) => event.section === task)
    })
}

export const filterByEmployees = (events: CleaningSlot & any[], filters: IFilterProps) => {
    if (filters?.employees?.length > 0) {
        return events.filter((event) => {
            return filters.employees.some((employerFilter) => {
                if (event.responsibleUsers !== undefined) {
                    return event.responsibleUsers.some((rU: string) => rU === employerFilter) // for cleanings
                } else {
                    return event.personnelId === employerFilter // for employees
                }
            })
        }) as CleaningSlot & any[]
    }
    return events
}

export const filterByServiceTypes = (events: CleaningSlot & any[], filters: IFilterProps) => {
    if (filters?.serviceTypes?.length > 0) {
        const onlyCleaningEvents: CleaningSlot[] = events.filter((event) => event.stageGroup !== null)
        return onlyCleaningEvents.filter((event) => filters.serviceTypes.some((serviceType) => {
            return serviceType === event.serviceType
        })) as CleaningSlot & any[]
    }
    return events
}

export const filterByCounterparty = (events: CleaningSlot & any[], filters: IFilterProps) => {
    if (filters?.counterparty?.length > 0) {
        return events.filter((event) => {
            return filters.counterparty.some((counterpartyFilter) => {
                if (event.organization !== undefined) {
                    return event.organization === counterpartyFilter // for cleanings
                }
            })
        }) as CleaningSlot & any[]
    }
    return events
}
