import clsx from 'clsx'
import { useContext, useMemo, useState } from 'react'
import { Controller, FieldValues, UseFieldArrayUpdate, useFormContext } from 'react-hook-form'
import { FaBars } from 'react-icons/fa'
import TabsTableCell from '../TabsTableCell/TabsTableCell'
import CustomCheckbox, { CustomCheckboxTheme } from '~/components/common/checkbox/CustomCheckbox/CustomCheckbox'
import { TabsTableContext } from '~/components/common/ReusingComponents/TabsTable/TabsTableContext'
import { calculateCellStyles } from '~/components/common/ReusingComponents/TabsTable/utils/calculateCellStyles'

import './TabsTableBody.scss'

interface TabsTableBody {
    name: string
    rows: Record<'id', string>[]
    inputs: any[]
    sectionName: string
    pageIndex: number
    withCheckboxes?: boolean
    isCheckAll?: boolean
    checkedRows?: boolean[]
    handleCheckRow?: (index: number) => void
    handleCheckAll?: (value: boolean) => void
    updateRow?: UseFieldArrayUpdate<FieldValues, string>
}

const TabsTableBody = (props: TabsTableBody) => {
    const {
        name,
        rows,
        sectionName,
        pageIndex,
        withCheckboxes,
        isCheckAll,
        checkedRows,
        handleCheckRow,
        handleCheckAll,
        inputs,
        updateRow,
    } = props

    const {
        disabled,
        headerSections,
    } = useContext(TabsTableContext)

    const {
        control,
    } = useFormContext()

    const [isExpanded, setIsExpanded] = useState(true)

    const columnsToHide = useMemo(() => {
        return headerSections.find((section) => {
            if ('name' in section) {
                return section.name === sectionName
            }
            return section
        })?.columnsToHide
    }, [])

    const hasMarker = (index: number) => {
        if (columnsToHide) {
            if (index === columnsToHide[0] - 1) return 'left'
            if (index === columnsToHide[1] + 1) return 'right'
        }
    }

    return (
        <div className={clsx('tabsTableBody', {
            'tabsTableBody--withColumnsToHide': columnsToHide,
        })}>
            <table className='tabsTableBody__table'>
                <thead>
                    <tr className='tabsTableBody__row--header'>
                        {withCheckboxes && (
                            <th className='tabsTableBody__checkbox'>
                                <CustomCheckbox
                                    id={`${name}_checkAll`}
                                    value={isCheckAll}
                                    onChange={(e) => handleCheckAll && handleCheckAll(e.target.checked)}
                                    theme={CustomCheckboxTheme.TABLE}
                                    disabled={disabled}
                                />
                            </th>
                        )}

                        {inputs?.map((input, index) => (
                            <th
                                key={input.name}
                                className={clsx('tabsTableBody__cell tabsTableBody__cell--header', input.className, {
                                    'tabsTableBody__cell--hide': columnsToHide && !isExpanded && index >= columnsToHide[0] && index <= columnsToHide[1],
                                    'tabsTableBody__cell--markerLeft': hasMarker(index) === 'left',
                                    'tabsTableBody__cell--markerRight': hasMarker(index) === 'right' && isExpanded,
                                    'tabsTableBody__cell--sticky': columnsToHide && (index < columnsToHide[0] || index > columnsToHide[1]),
                                })}
                                style={columnsToHide ? calculateCellStyles(index, columnsToHide, !!withCheckboxes, inputs) : undefined}
                            >
                                {input.title}
                                {(hasMarker(index) === 'left' || hasMarker(index) === 'right' && isExpanded) &&
                                    <div
                                        className={clsx('tabsTableBody__expand', {
                                            'tabsTableBody__expand--right': hasMarker(index) === 'right',
                                        })}
                                        onClick={() => setIsExpanded((prev) => !prev)}
                                    >
                                        <FaBars />
                                    </div>
                                }
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>

                    {rows.map((row, rowIndex) => (
                        <tr key={row.id + rowIndex} className='tabsTableBody__row'>
                            {withCheckboxes && (
                                <td className='tabsTableBody__checkbox'>
                                    <CustomCheckbox
                                        id={`${name}_${rowIndex}`}
                                        value={checkedRows?.[rowIndex]}
                                        onChange={() => handleCheckRow && handleCheckRow(rowIndex)}
                                        theme={CustomCheckboxTheme.TABLE}
                                        disabled={disabled}
                                    />
                                </td>
                            )}
                            <td style={{ display: 'none' }}>
                                <Controller
                                    render={() => <></>}
                                    name={`${name}.${rowIndex}.internalId`}
                                    defaultValue={row.id}
                                    control={control}
                                />
                            </td>

                            {inputs?.map((input, index) => (
                                <TabsTableCell
                                    key={`${row.id}_${input.name}_${rowIndex}_${index}`}
                                    rowIndex={rowIndex}
                                    updateRow={updateRow}
                                    name={name}
                                    sectionName={sectionName}
                                    pageIndex={pageIndex}
                                    input={input}
                                    className={clsx({
                                        'tabsTableBody__cell--hide': columnsToHide && !isExpanded && index >= columnsToHide[0] && index <= columnsToHide[1],
                                        'tabsTableBody__cell--markerLeft': hasMarker(index) === 'left',
                                        'tabsTableBody__cell--markerRight': hasMarker(index) === 'right' && isExpanded,
                                        'tabsTableBody__cell--sticky': columnsToHide && (index < columnsToHide[0] || index > columnsToHide[1]),
                                    })}
                                    style={columnsToHide ? calculateCellStyles(index, columnsToHide, !!withCheckboxes, inputs) : undefined}
                                />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TabsTableBody
