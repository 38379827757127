export const actionsB2B = {
    suggestCommercialOffer: (disabled?: boolean) => ({
        disabled,
        name: 'commercialOffer',
        title: 'Выберите действие',
        items: [
            {
                value: 'CREATE_KP',
                text: 'Сформировать КП',
            },
        ],
        type: 'action',
    }),

    approveCommercialOffer: (disabled?: boolean) => ({
        disabled,
        name: 'commercialOffer',
        title: 'Выберите действие',
        items: [
            {
                value: 'CREATE_KP',
                text: 'Сформировать КП',
            },
            {
                value: 'APPROVE_KP',
                text: 'Подтвердить КП',
            },
        ],
        type: 'action',
    }),

    orderToDivisionCleaningAction: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'orderToDivisionCleaningAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'ORDER_TO_DIVISION',
                text: 'Передать заявку на подтверждение',
            },
            {
                value: 'RESERVE_CLEANING',
                text: 'Передать информацию подразделению',
            },
            {
                value: 'RESERVE_ORDER',
                text: 'Передать информацию без подтверждения',
            },
        ],
        type: 'action',
    }),
    takeOrderAction: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'takeOrderAction',
        title: 'Выберите действие',
        items:
            [
                {
                    value: 'TAKE_ORDER',
                    text: 'Принять заявку',
                },
                {
                    value: 'CHANGE_COST',
                    text: 'Передать информацию отделу продаж',
                }],
        type: 'action',
    }),

    orderTransferAction: (contractBillAvailable: boolean) => {
        const items = []
        items.push({
            value: 'CONTRACT_SIGNING',
            text: 'Передать на согласование договора',
        })
        if (contractBillAvailable) {
            items.push({
                value: 'CREATE_CONTRACT_BILL',
                text: 'Создать счёт-договор',
            })
        }

        return {
            name: 'orderToDivisionCleaningAction',
            title: 'Выберите действие',
            items,
            type: 'action',
        }
    },

    contractSigningAction: (contract: any) => {
        {
            const items = []
            items.push({
                value: 'RECREATE_CONTRACT',
                text: 'Сформировать договор',
            })
            if (contract) {
                items.push({
                    value: 'CONTRACT_SIGNED',
                    text: 'Согласовать договор',
                })
            }
            items.push({
                value: 'RETURN_ORDER',
                text: 'Передать заявку менеджерам продаж',
            })
            items.push({
                value: 'CONTRACT_SIGNING_INFO',
                text: 'Передать информацию по согласованию',
            })
            return {
                name: 'orderToDivisionCleaningAction',
                title: 'Выберите действие',
                items,
                type: 'action',
            }
        }
    },

    prepaymentApprovedAction: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'prepaymentApprovedAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'PREPAYMENT_APPROVED',
                text: 'Подтвердить предоплату / Подтвердить документы',
            },
            {
                value: 'RETURN_ORDER',
                text: 'Передать заявку менеджерам продаж',
            },
            {
                value: 'TO_ARCHIVE',
                text: 'Перенести в архив',
            },
        ],
        type: 'action',
    }),
    cleaningStartedAction: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'cleaningStartedAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'CLEANING_STARTED',
                text: 'Подтвердить услуги',
            },
        ],
        type: 'action',
    }),

    workProcessAction: (lastWorkingDay: boolean, disabled: boolean) => ({
        disabled: disabled,
        name: 'workProcessAction',
        title: 'Выберите действие',
        items: lastWorkingDay ?
                [
                    {
                        value: 'CLEANING_ENDED',
                        text: 'Завершить уборку',
                    },
                ] :
                [
                    {
                        value: 'WORKING_DAY_ENDED',
                        text: 'Перейти к завершению рабочего дня',
                    },
                ],
        type: 'action',
    }),
    workEndingAction: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'workEndingAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'WORK_ENDING',
                text: 'Завершить работы',
            },
        ],
        type: 'action',
    }),
    orderManagerReportAction: () => ({
        name: 'orderManagerReportAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'RETURNING_DOCUMENTS',
                text: 'Закончить отчёт',
            },
        ],
        type: 'action',
    }),
    returningDocumentsAction: (isFullPrepayment: boolean, disabled?: boolean) => ({
        disabled,
        name: 'returningDocumentsAction',
        title: 'Выберите действие',
        items: [isFullPrepayment ?
                {
                    value: 'TO_QUALITY_CONTROL',
                    text: 'На контроль качества',
                } :
                {
                    value: 'PAYMENT_RECEIVING',
                    text: 'К подтверждению оплаты',
                },
        ],
        type: 'action',
    }),
    paymentReceivedAction: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'returningDocumentsAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'TO_QUALITY_CONTROL',
                text: 'Отправить на контроль качества',
            },
        ],
        type: 'action',
    }),
}
