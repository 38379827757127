import React, { useEffect, useMemo, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { FieldError, useFormContext } from 'react-hook-form'
import CustomSelect from '../CustomSelect/CustomSelect'
import { useFetchUsersByRolesMutation, useLazyFetchUserByIdQuery } from '~/API/userApi/user'
import { Role } from '~/API/userApi/user.types'
import { User } from '~/API/userApi/user.types'
import MobileCustomSelect from '~/components/mobile/mobileCommon/mobileInputs/MobileCustomSelect/MobileCustomSelect'
import { sortByRole } from '~/shared/util/filter'
import { getGlobalDivision } from '~/shared/util/localStorage'

interface CustomUserInputProps {
    title: string
    value: any
    name: string
    onChange: (e: any) => void
    className: string
    disabled?: boolean
    error?: FieldError
    role: Role[]
    usingDivision?: string,
    division?: string
}

const CustomUserInput = (props: CustomUserInputProps) => {
    const {
        title,
        value,
        name,
        onChange,
        className,
        disabled,
        error,
        role,
        usingDivision,
        division,
    } = props
    const [fetchUsersByRoles] = useFetchUsersByRolesMutation()
    const [fetchUserById] = useLazyFetchUserByIdQuery()

    const [userInfo, setUserInfo] = useState<{ fullname: string } | null>(null)
    const [items, setItems] = useState<any[]>([])
    const {
        getValues,
    } = useFormContext()

    const getValue = useMemo(() => {
        if (name === 'orderCreator') {
            return userInfo?.fullname
        }
        if (!value || value.length === 0) {
            return ''
        }
        return userInfo?.fullname || 'Подождите...'
    }, [items, value, userInfo])

    useEffect(() => {
        let isMounted = true
        if (value && value !== '') {
            fetchUserById(value, true).unwrap()
                .then((_userInfo: any) => {
                    if (isMounted) {
                        setUserInfo(_userInfo)
                    }
                })
                .catch((error: Error) => {
                    if (isMounted) {
                        setUserInfo({ fullname: value })
                    }
                })
        }
        return () => {
            isMounted = false
        }
    }, [value])

    useEffect(() => {
        let isMounted = true
        if (role) {
            fetchUsersByRoles({
                roles: role,
                division: division || (usingDivision ? getValues(usingDivision) : getGlobalDivision())?.toUpperCase(),
            }).unwrap()
                .then((users) => {
                    if (Array.isArray(users) && !!users.length && isMounted) {
                        setItems([...users].sort(sortByRole).map((user: User) => ({
                            value: user.id,
                            text: user.fullname,
                        })))
                    }
                })
        }
        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <BrowserView style={{ flex: 1 }}>
                <CustomSelect
                    className={className}
                    items={items}
                    onChange={onChange}
                    name={name}
                    title={title}
                    value={getValue}
                    error={error}
                    disabled={disabled}
                />
            </BrowserView>
            <MobileView>
                <MobileCustomSelect
                    className={className}
                    items={items}
                    onChange={onChange}
                    name={name}
                    title={title}
                    value={value ? userInfo?.fullname || 'Подождите...' : ''}
                    error={error}
                    disabled={disabled}
                />
            </MobileView>
        </>
    )
}

export default CustomUserInput
