import { Message } from '~/hooks/useChat/useChat.types'
import { fetchLocalDate } from '~/shared/util/currentTime'
import { IRulesComments } from '~/types/types.order'

export const buildSingleDateComments = (comments: Message[]) => {
    const commentsObject = [...comments].sort((a, b) => {
        const dateA = new Date(a.date).getTime()
        const dateB = new Date(b.date).getTime()
        return dateA - dateB
    })
        .reduce((accum: Record<string, Message[]>, item) => {
            const currentDate = fetchLocalDate(item.date).format('D MMMM YYYY')
            if (!Object.keys(accum).includes(currentDate)) {
                accum[currentDate] = [item]
            } else {
                accum[currentDate] = [...accum[currentDate], item]
            }
            return accum
        }, {})
    const resultObject: Record<string, Message[][]> = {}
    Object.entries(commentsObject)
        .forEach(([key, values]) => {
            resultObject[key] = buildCommentGroup(values)
        })
    return resultObject
}

const buildCommentGroup = (comments: Message[]) => {
    let tmpTitle: keyof IRulesComments
    const resultArray: Message[][] = []
    const newArray = [...comments]
    newArray.sort((a, b) => {
        return Date.parse(new Date(a.date as Date).toDateString()) - Date.parse(new Date(b.date as Date).toDateString())
    })
        .forEach((comment) => {
            if (tmpTitle === comment.type) {
                resultArray[resultArray.length - 1].push(comment)
            } else {
                tmpTitle = comment.type
                const newCommentGroup = [comment]
                resultArray.push(newCommentGroup)
            }
        })
    return resultArray
}
