import { LoginData } from './auth.types'
import { instance } from '~/API/lib/axios/axios'
import { fetchPath } from '~/API/lib/path/path'

const path = fetchPath('auth')

export const loginUser = async (loginData: LoginData) =>
    await instance(
        {
            method: 'post',
            url: path + '/login',
            data: loginData,
        },
    )
        .then((response) => {
            instance.defaults.headers['Authorization'] = 'Bearer ' + response.data.token
            localStorage.setItem('jwtToken', 'Bearer ' + response.data.token)
            return response.data.token
        })
        .catch((error) => {
            if (error.response.status === 401) {
                return Promise.reject(new Error('Неверный логин или пароль'))
            }
            if (error.response.status === 400) {
                return Promise.reject(new Error('Не указаны данные для авторизации'))
            }
        })

export const logoutUser = async () =>
    await instance(
        {
            method: 'post',
            url: path + '/logout',
        },
    )
        .then((response) => {
            localStorage.removeItem('jwtToken')
            return response
        })
        .catch((error) => {
        })
