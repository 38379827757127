import Popover from '@mui/material/Popover'
import { MouseEvent, useState, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { TabsTableHeaderSection } from '../../../../tabsTable.types'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { TabsTableContext } from '~/components/common/ReusingComponents/TabsTable/TabsTableContext'

import './TabsTableHeaderButton.scss'


const TabsTableHeaderButton = () => {
    const {
        appendPage,
        pages,
        headerSections,
        baseName,
    } = useContext(TabsTableContext)

    const {
        trigger,
        formState: { isSubmitted },
    } = useFormContext()

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const addNewPage = (option: TabsTableHeaderSection) => {
        const count = pages.filter((page) => page.type === option.name).length

        appendPage && appendPage({
            type: option.name,
            title: option.title + (count === 0 ? '' : ' ' + (count + 1)),
            items: [],
        })

        isSubmitted && trigger(baseName)
        closePopover()
    }

    return (
        <>
            <CustomButton
                className='tabsTableHeaderButton'
                onClick={openPopover}
                theme={CustomButtonThemes.OUTLINE}
            >
                Добавить лист
            </CustomButton>
            <Popover
                className='tabsTableHeaderButton__popover'
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className='tabsTableHeaderButton__wrapper'>
                    {headerSections.map((option) => {
                        const sectionOption = option as TabsTableHeaderSection
                        return (
                            <div
                                key={sectionOption.name}
                                className='tabsTableHeaderButton__option'
                                onClick={() => addNewPage(sectionOption)}
                            >
                                {sectionOption.title}
                            </div>
                        )
                    })}
                </div>
            </Popover>
        </>
    )
}

export default TabsTableHeaderButton
