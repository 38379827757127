import clsx from 'clsx'
import './ServicesVerificationItem.scss'

interface ServicesVerificationItemProps {
    title: string;
    description?: string;
    price?: number;
    status?: boolean;
    error?: boolean;
}

const getText = (status?: boolean) => status === true ? 'Подтверждено' : status === false ? 'Не требуется' : 'Не выбрано'

const ServicesVerificationItem = (props: ServicesVerificationItemProps) => {
    const {
        title,
        description,
        price,
        status,
        error,
    } = props

    return (
        <div className={clsx('servicesVerificationItem', error && 'servicesVerificationItem--error')}>
            <h4 className='servicesVerificationItem__title'>{title}</h4>
            <div className='servicesVerificationItem__content'>
                {description && <p className='servicesVerificationItem__description'>{description}</p>}
                {price && <p className='servicesVerificationItem__price'>{price} ₽</p>}
                <div className={clsx('servicesVerificationItem__status', {
                    'servicesVerificationItem__status--error': error,
                    'servicesVerificationItem__status--primary': status,
                    'servicesVerificationItem__status--secondary': status === false,
                })}>{getText(status)}
                </div>
            </div>
        </div>
    )
}

export default ServicesVerificationItem
