import {
    CreateMoneyFlowBody,
    fetchSalariesByDateParams,
    MoneyFlow,
    MoneyFlowOptionsResponse,
    MoneyFlowQuery,
    MoneyFlowResponse,
    PaginationQuery,
    SalaryObject,
    SalaryReport,
    SalaryResponse,
    saveCommonSalaryParams,
    saveSingleSalaryParams,
    saveWithoutApplicationSalaryParams,
    updateSalaryParams,
} from './salary.types'
import { fetchPath } from '~/API/lib/path/path'
import { rtkApi } from '~/shared/api/rtkApi'
import { fetchDate, fetchLocalDate } from '~/shared/util/currentTime'
import { getGlobalDivision } from '~/shared/util/localStorage'

const salaryPath = fetchPath('salary')
const cashFlowPath = fetchPath('cashFlow')
const orderPath = fetchPath('order')

const salaryApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchSalariesByDate: build.query<SalaryReport, fetchSalariesByDateParams>({
            query: ({
                startDateRange,
                endDateRange,
                jobPosition,
                division,
            }) => ({
                method: 'GET',
                url: orderPath + '/salary-info',
                params: {
                    startDate: JSON.parse(JSON.stringify(fetchLocalDate(startDateRange).toDate())),
                    endDate: JSON.parse(JSON.stringify(fetchLocalDate(endDateRange).toDate())),
                    businessType: division || getGlobalDivision(),
                    jobPosition,
                },
            }),
            providesTags: ['Salary'],
        }),
        saveSingleSalary: build.mutation<SalaryReport, saveSingleSalaryParams>({
            query: ({
                personnelId,
                date,
                fileId,
                salaryType,
                orderId,
            }) => ({
                url: salaryPath + '/save',
                method: 'POST',
                body: {
                    personnelId,
                    date: JSON.parse(JSON.stringify(fetchDate(date).toDate())),
                    fileId,
                    salaryType,
                    orderId,
                },
            }),
            invalidatesTags: ['Salary'],
        }),
        cancelSingleSalary: build.mutation<SalaryReport, { id: string }>({
            query: ({ id }) => ({
                url: salaryPath + '/single/' + id,
                method: 'POST',
            }),
            invalidatesTags: ['Salary'],
        }),
        saveWithoutApplicationSalary: build.mutation<SalaryReport, saveWithoutApplicationSalaryParams>({
            query: ({
                personnelId,
                date,
                comment,
                salaryAmount,
                jobPosition,
                division,
            }) => ({
                url: orderPath + '/cleaning-day/save',
                method: 'POST',
                body: {
                    personnelId,
                    date: JSON.parse(JSON.stringify(fetchLocalDate(date).toDate())),
                    comment,
                    salaryAmount,
                    jobPosition,
                    division,
                },
            }),
            invalidatesTags: ['Salary'],
        }),
        updateSalary: build.mutation<unknown, updateSalaryParams>({
            query: (salary) => ({
                url: salaryPath + '/update',
                method: 'POST',
                body: salary,
            }),
            invalidatesTags: ['Salary'],
        }),
        saveCommonSalary: build.mutation<SalaryResponse<SalaryObject[]>, saveCommonSalaryParams>({
            query: ({
                personnelIds,
                startDateRange,
                endDateRange,
                fileId,
                salaryType,
                isUpdate = false,
            }) => ({
                url: `${salaryPath}${isUpdate ? '/update/many' : '/save/many'}`,
                method: 'POST',
                body: {
                    personnelIds,
                    startDate: JSON.parse(JSON.stringify(fetchLocalDate(startDateRange).toDate())),
                    endDate: JSON.parse(JSON.stringify(fetchLocalDate(endDateRange).toDate())),
                    fileId,
                    salaryType,
                },
            }),
            invalidatesTags: ['Salary'],
        }),
        cashFlow: build.query<MoneyFlowResponse, PaginationQuery & MoneyFlowQuery & { userId: string }>({
            query: ({ userId, ...params }) => ({
                url: cashFlowPath,
                method: 'GET',
                params,
            }),
            providesTags: ['MoneyFlow'],
        }),
        createCashFlow: build.mutation<MoneyFlow, CreateMoneyFlowBody>({
            query: (body) => ({
                url: cashFlowPath,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['MoneyFlow'],
        }),
        updateCashFlow: build.mutation<MoneyFlow, MoneyFlow>({
            query: (body) => ({
                url: cashFlowPath,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['MoneyFlow'],
        }),
        deleteCashFlow: build.mutation<null, string>({
            query: (id) => ({
                url: cashFlowPath + '/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['MoneyFlow'],
        }),
        cashFlowOptions: build.query<MoneyFlowOptionsResponse, string>({
            query: (_userId) => ({
                url: cashFlowPath + '/options',
                method: 'GET',
            }),
            providesTags: ['MoneyFlow'],
        }),
    }),
})

export const {
    useFetchSalariesByDateQuery,
    useSaveSingleSalaryMutation,
    useCancelSingleSalaryMutation,
    useSaveWithoutApplicationSalaryMutation,
    useUpdateSalaryMutation,
    useSaveCommonSalaryMutation,
    useCashFlowQuery,
    useCreateCashFlowMutation,
    useUpdateCashFlowMutation,
    useDeleteCashFlowMutation,
    useCashFlowOptionsQuery,
} = salaryApi
