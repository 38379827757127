const KEY = 'storage'

export function loadState() {
    try {
        const serializedState = localStorage.getItem(KEY)
        if (!serializedState) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        return undefined
    }
}

export function saveState(state: any) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem(KEY, serializedState)
    } catch (_) {}
}
