import './ToolsReportItem.scss'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FaPen, FaThumbtack } from 'react-icons/fa'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import { OnSubscriptionFieldChange } from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import MobileEditModal from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileEditModal/MobileEditModal'

interface ToolsReportItemProps {
    inputName: string
    disabled: boolean
    inputs: any[]
    isError: boolean
    sectionName: string
    rowIndex: number
    onSubscriptionFieldChange: OnSubscriptionFieldChange
    sectionTitle?: string
    usingData?: string
}

const ToolsReportItem = (props: ToolsReportItemProps) => {
    const {
        inputName,
        disabled,
        inputs,
        isError,
        sectionName,
        rowIndex,
        onSubscriptionFieldChange,
        sectionTitle,
        usingData,
    } = props

    const [editModalOpen, setEditModalOpen] = useState<boolean>(false)

    const { getValues } = useFormContext<OrderInterface>()

    const { value, hasFile } = useMemo(() => {
        const value = getValues(inputName as `toolsReport.actualTools.${number}.items.${number}` | `toolsReport.actualAmortization.${number}.items.${number}`)
        const sectionDocuments = getValues(`${usingData}.${sectionName}` as `documents.toolsReport.actualAmortization.${string}`) || []
        const hasFile = !!sectionDocuments.find((item) => item?.index === rowIndex)

        return { value, hasFile }
    }, [editModalOpen, usingData, sectionName])

    return (
        <>
            <div className={clsx('toolsReportItem', isError && 'toolsReportItem--error')}>
                <div className='toolsReportItem__header'>
                    <h4 className='toolsReportItem__title'>
                        {value.name}
                        {hasFile && (
                            <span className='toolsReportItem__tag'>
                                <FaThumbtack size={8} />
                                ЗАПИСКА
                            </span>
                        )}
                    </h4>
                    <button type='button' className='toolsReportItem__icon' onClick={() => setEditModalOpen(true)}>
                        <FaPen size={16} />
                    </button>
                </div>
                <div className='toolsReportItem__content'>
                    <div className='toolsReportItem__item'>
                        <p>Взято</p>
                        <div className='toolsReportItem__value'>{value.amount}</div>
                    </div>
                    <div className='toolsReportItem__item'>
                        <p>Вернулось</p>
                        <div className='toolsReportItem__value'>{value.in}</div>
                    </div>
                </div>
            </div>
            <MobileEditModal
                inputName={inputName}
                isOpen={editModalOpen}
                closeModal={() => setEditModalOpen(false)}
                inputs={inputs}
                disabled={disabled}
                sectionName={sectionName}
                rowIndex={rowIndex}
                usingData={usingData}
                sectionTitle={sectionTitle}
                onSubscriptionFieldChange={onSubscriptionFieldChange}
            />
        </>
    )
}

export default ToolsReportItem
