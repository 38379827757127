import React from 'react'
import { FieldError } from 'react-hook-form'
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox'
import { InputParamsItem, InputTemplateType } from '~/hooks/useFormConstructor/useFormConstructor.types'

interface CustomCheckboxGroupItem {
    name: string,
    caption: string,
}

interface CustomCheckboxGroupProps {
    values: any[],
    onChange: (value: any[]) => void,
    title: string,
    items?: InputParamsItem<any>[] | Record<string, InputParamsItem<any>[]> | InputTemplateType | CustomCheckboxGroupItem[],
    className?: string,
    error?: FieldError,
    name: string,
    disabled: boolean,
    watch?: string,
}

const CustomCheckboxGroup = (props: CustomCheckboxGroupProps) => {
    const {
        values,
        onChange,
        title,
        items = [],
        className,
        error,
        name,
        disabled,
        watch,
    } = props
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValues = [...values]
        if (e.target.checked) {
            newValues.push(Object.values(items).find((item) => item.name === e.target.name)?.caption)
            onChange([...newValues])
        } else {
            const pos = values.indexOf(Object.values(items).find((item) => item.name === e.target.name).caption)
            if (pos !== -1) {
                newValues.splice(pos, 1)
                onChange([...newValues])
            }
        }
    }
    return (
        <div className={`customCheckboxGroup customField ${className || ''} ${error ? 'error' : ''}`}>
            <h5>{title}</h5>
            <div className='customCheckboxGroup__body'>
                {Object.values(items).map((item) =>
                    <CustomCheckbox
                        caption={item.caption}
                        name={item.name}
                        id={`checkbox_${name}_${item.name}`}
                        key={`checkbox_${name}_${item.name}`}
                        value={values.includes(item.caption)}
                        disabled={disabled}
                        onChange={handleChange}
                    />,
                )}
            </div>
        </div>
    )
}

export default CustomCheckboxGroup
