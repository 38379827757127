import './Sidebar.scss'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import ProfileToolbar from './subComponents/ProfileToolbar/ProfileToolbar'
import ScheduleFilter from './subComponents/ScheduleFilter/ScheduleFilter'
import SidebarDocumentButtons from './subComponents/SidebarDocumentButtons/SidebarDocumentButtons'
import SidebarPersonnelButtons from './subComponents/SidebarPersonnelButtons/SidebarPersonnelButtons'
import ProfileSidebarBody
    from '../../pages/Staff/subComponents/StaffProfiles/subComponents/ProfileSidebarBody/ProfileSidebarBody'
import StaffScheduleFilter
    from '../../pages/Staff/subComponents/StaffSchedule/subComponents/StaffScheduleFilter/StaffScheduleFilter'
import { useUploadCleaningForYearMutation } from '~/API/orderApi/order'
import FtzSidebarButtons from '~/components/navigation/Sidebar/subComponents/FtzSidebarButtons/FtzSidebarButtons'
import KpoSidebarButtons from '~/components/navigation/Sidebar/subComponents/KpoSidebarButtons/KpoSidebarButtons'
import KpSidebarButtons from '~/components/navigation/Sidebar/subComponents/KpSidebarButtons/KpSidebarButtons'
import MiniCalendar from '~/components/navigation/Sidebar/subComponents/MiniCalendar/MiniCalendar'
import OrderSidebarBody from '~/components/navigation/Sidebar/subComponents/OrderSidebarBody/OrderSidebarBody'
import SmetaSidebarButtons from '~/components/navigation/Sidebar/subComponents/SmetaSidebarButtons/SmetaSidebarButtons'
import StartingSmetaSidebarButtons
    from '~/components/navigation/Sidebar/subComponents/StartingSmetaSidebarButtons/StartingSmetaSidebarButtons'
import CalendarFilter from '~/components/pages/Home/subComponents/CalendarFilter/CalendarFilter'
import { SELECT_SIDEBAR_STATUS, selectUser } from '~/redux/selectors/appSlice.selectors'
import { isStaffEdit } from '~/redux/selectors/popupSlice.selectors'
import { getActiveDateSelector } from '~/redux/selectors/staffSchedule.selector'
import { changeFormAction, HIDE_SIDEBAR } from '~/redux/slices/appSlice/appSlice'

const Sidebar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [uploadCleaningForYear] = useUploadCleaningForYearMutation()

    const user = useSelector(selectUser)
    const active = useSelector(SELECT_SIDEBAR_STATUS)
    const isStaffSchedule = useSelector(isStaffEdit)
    const activeDate = useSelector(getActiveDateSelector)

    return (
        <div className={clsx('sidebar', { active })}>
            <Routes>
                <Route path='/login' />
                <Route path='/schedule' element={
                    <>
                        <ProfileToolbar />
                        <ScheduleFilter />
                    </>
                } />
                <Route path='/staff/:id/*' element={
                    <>
                        <ProfileToolbar />
                        <ProfileSidebarBody />
                    </>
                } />
                <Route path='/staff' element={
                    <>
                        <ProfileToolbar />
                        {isStaffSchedule && <StaffScheduleFilter />}
                    </>
                } />
                <Route path='/salary' element={<ProfileToolbar />} />
                <Route path='/archive' element={<ProfileToolbar />} />
                <Route path='/analytic' element={
                    <>
                        <ProfileToolbar />
                        <button
                            className={'sidebar__mainButton button'}
                            onClick={() => {
                                dispatch(changeFormAction({ action: 'UPLOAD_MONTH_STATISTIC' }))
                            }}
                        >
                            Выгрузить
                        </button>
                        <button
                            className={'sidebar__mainButton button'}
                            onClick={() => {
                                uploadCleaningForYear('B2C').unwrap().then((r) => console.log('r', r))
                            }}
                        >
                            Уборки за год B2C
                        </button>
                        <button
                            className={'sidebar__mainButton button'}
                            onClick={() => {
                                uploadCleaningForYear('B2B').unwrap().then((r) => console.log('r', r))
                            }}
                        >
                            Уборки за год B2B
                        </button>
                        <button
                            className={'sidebar__mainButton button'}
                            onClick={() => {
                                uploadCleaningForYear('ORISCLEAN').unwrap().then((r) => console.log('r', r))
                            }}
                        >
                            Уборки за год ORISCLEAN
                        </button>
                        <button
                            className={'sidebar__mainButton button'}
                            onClick={() => {
                                uploadCleaningForYear('B2B (ППО)').unwrap().then((r) => console.log('r', r))
                            }}
                        >
                            Уборки за год B2B (ППО)
                        </button>
                    </>
                } />
                <Route path='/inputs' element={
                    <>
                        <ProfileToolbar />
                        <SidebarPersonnelButtons />
                    </>
                } />
                <Route
                    path='/report/'
                    element={
                        <>
                            <ProfileToolbar />
                            <Outlet />
                        </>
                    }
                >
                    <Route path=':id/nz/' element={<SidebarDocumentButtons />} />
                    <Route path=':id/kp/' element={<SidebarDocumentButtons />} />
                    <Route path=':id/contract/' element={<SidebarDocumentButtons />} />
                    <Route path=':id/contract/:fileId' element={<SidebarDocumentButtons />} />
                    <Route path=':id' element={<OrderSidebarBody />} />
                </Route>
                <Route
                    path='/order'
                    element={
                        <>
                            <ProfileToolbar />
                            <Outlet />
                        </>
                    }
                >
                    <Route path=':id/inputnz/' element={<SidebarDocumentButtons />} />
                    <Route path=':id/nz/' element={<SidebarDocumentButtons />} />
                    <Route path=':id/contract/' element={<SidebarDocumentButtons />} />
                    <Route path=':id/contract/:fileId' element={<SidebarDocumentButtons />} />
                    <Route path=':id/smeta/:serviceNumber' element={<SmetaSidebarButtons />} />
                    <Route path=':id/kpo' element={<KpoSidebarButtons />} />
                    <Route path=':id/kpo-starting' element={<KpoSidebarButtons />} />
                    <Route path=':id/service-smeta/:serviceNumber' element={<SmetaSidebarButtons />} />
                    <Route path=':id/service-smeta-starting' element={<StartingSmetaSidebarButtons />} />
                    <Route path=':id/kp' element={<KpSidebarButtons />} />
                    <Route path=':id/ftz' element={<FtzSidebarButtons />} />
                    <Route path=':id' element={<OrderSidebarBody />} />
                </Route>
                <Route path='/*' element={
                    <>
                        <ProfileToolbar />
                        {(user?.role === 'CHIEF' || user?.role === 'ORDER_MANAGER' || user?.role === 'OPERATOR') && (
                            <button
                                className={'sidebar__mainButton button'}
                                onClick={() => {
                                    dispatch(HIDE_SIDEBAR())
                                    navigate('/order')
                                }}
                            >
                                Создать событие
                            </button>
                        )}
                        <MiniCalendar
                            propsActiveDate={activeDate}
                        />
                        <CalendarFilter />
                    </>
                } />
            </Routes>
        </div>
    )
}

export default Sidebar
