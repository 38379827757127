import './ProfileSidebarBody.scss'
import { ImCross } from '@react-icons/all-files/im/ImCross'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import Comments from '../../../../../../common/ReusingComponents/Comments/Comments'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { SELECT_COMMENTS } from '~/redux/selectors/staffSlice.selectors'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import { useAppDispatch } from '~/redux/store'

const ProfileSidebarBody = () => {
    const dispatch = useAppDispatch()
    const comments = useSelector(SELECT_COMMENTS)
    const user = useSelector(selectUser)
    const [open, setOpen] = useState(false)
    const closeModal = () => setOpen(false)

    return (
        <>
            <div className='profileSidebarBody'>
                <div className={'profileSidebarBody__buttonGroup'}>
                    <button
                        className={'button'}
                        onClick={() => setOpen((o) => !o)}
                    >
                        Сохранить анкету
                    </button>
                    <button
                        className={'button button_bc-grey'}
                    >
                        Отмена
                    </button>
                </div>
                <Comments user={user} />
            </div>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <div className='modal'>
                    <div className='modal__header'>
                        <span>Важная информация</span>
                        <ImCross onClick={() => {
                            closeModal()
                        }} />
                    </div>
                    <div className='modal__body'>
                        <span>
                            Анкета будет сохранена в “базе анкет сотрудников”. Для передачи анкеты менеджеру выберите действие внизу станицы.
                        </span>
                    </div>
                    <div className='modal__buttons'>
                        <button type='button' className='button' onClick={() => {
                            dispatch(changeFormAction({ action: 'SAVE_PERSONNEL' }))
                            closeModal()
                        }}>Сохранить
                        </button>
                        <div className='button' onClick={() => {
                            closeModal()
                        }}>Вернутся в анкету
                        </div>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default ProfileSidebarBody
