import Rating from '@mui/material/Rating'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import React from 'react'
import { InputTypeEnum } from '../../enum/InputTypeEnum'
import { Data, InputParams, InputParamsItem } from '../../useFormConstructor.types'
import CustomCheckbox from '~/components/common/checkbox/CustomCheckbox/CustomCheckbox'
import CustomCheckboxGroup from '~/components/common/checkbox/CustomCheckboxGroup/CustomCheckboxGroup'
import CustomFieldArray from '~/components/common/fields/CustomFieldArray/CustomFieldArray'
import CustomFieldArrayPatent
    from '~/components/common/formConstructor/FormBlock/subComponents/CustomFieldArrayPatent/CustomFieldArrayPatent'
import ReportTable from '~/components/common/formConstructor/formCommonComponents/ReportTable/ReportTable'
import CustomAddressInput from '~/components/common/inputs/CustomAddressInput/CustomAddressInput'
import { CustomClientInput } from '~/components/common/inputs/CustomClientInput/CustomClientInput'
import CustomDateInput from '~/components/common/inputs/CustomDateInput/CustomDateInput'
import CustomFileInputGroup from '~/components/common/inputs/CustomFileInputGroup/CustomFileInputGroup'
import CustomFileInputSingle from '~/components/common/inputs/CustomFileInputSingle/CustomFileInputSingle'
import CustomInput from '~/components/common/inputs/CustomInput/CustomInput'
import CustomInputWrapper, { CustomInputHocType } from '~/components/common/inputs/CustomInput/utils/CustomInputWrapper'
import CustomMultipleSelector from '~/components/common/inputs/CustomMultipleSelector/CustomMultipleSelector'
import CustomOrganizationInput from '~/components/common/inputs/CustomOrganizationInput/CustomOrganizationInput'
import CustomRadioGroup from '~/components/common/inputs/CustomRadioGroup/CustomRadioGroup'
import CustomSelect from '~/components/common/inputs/CustomSelect/CustomSelect'
import { CustomSubmitFormInput } from '~/components/common/inputs/CustomSubmitFormInput/CustomSubmitFormInput'
import CustomUserInput from '~/components/common/inputs/CustomUserInput/CustomUserInput'
import InputNZ from '~/components/common/inputs/InputNZ/InputNZ'
import { ClientInfo } from '~/components/common/ReusingComponents/ClientInfo/ClientInfo'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import CustomDate from '~/components/common/ReusingComponents/CustomDate/CustomDate'
import { CustomTitle } from '~/components/common/ReusingComponents/CustomTitle/CustomTitle'
import QualityReminder from '~/components/common/ReusingComponents/QualityReminder/QualityReminder'
import TabsTableWrapper from '~/components/common/ReusingComponents/TabsTable/utils/TabsTableWrapper'
import MobileCustomFieldArray
    from '~/components/mobile/mobileCommon/mobileFormConstructor/MobileCustomFieldArray/MobileCustomFieldArray'
import MobileCustomDateInput
    from '~/components/mobile/mobileCommon/mobileInputs/MobileCustomDateInput/MobileCustomDateInput'
import MobileCustomMultipleSelector
    from '~/components/mobile/mobileCommon/mobileInputs/MobileCustomMultipleSelector/MobileCustomMultipleSelector'
import MobileCustomSelect from '~/components/mobile/mobileCommon/mobileInputs/MobileCustomSelect/MobileCustomSelect'
import { getInputMobileComponent } from '~/hooks/useFormConstructor/lib/additionalFunctions/getInputMobileComponent'
import { isMobile } from '~/shared/util/deviceDetect'

export const uniteFormSection = ({ inputType, inputParams }: {
    inputType: InputTypeEnum,
    inputParams: InputParams
}) => {
    switch (inputType) {
        case InputTypeEnum.comment:
            return (
                <CustomInputWrapper
                    {...inputParams}
                    hocType={CustomInputHocType.COMMENT}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.text:
            return (
                <CustomInput
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.address:
            return (
                <CustomAddressInput
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.select:
            return (
                !isMobile ? (
                    <CustomSelect
                        {...inputParams}
                        items={inputParams.items as InputParamsItem<any>[]}
                        key={inputParams.name}
                    />
                ) : (
                    <MobileCustomSelect
                        {...inputParams}
                        items={inputParams.items as InputParamsItem<any>[]}
                        key={inputParams.name}
                    />
                )
            )
        case InputTypeEnum.client:
            return (
                <CustomClientInput
                    {...inputParams}
                    items={inputParams.items as InputParamsItem<any>[]}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.user:
            return (
                <CustomUserInput
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.document:
            return (
                <CustomFileInputSingle
                    {...inputParams}
                    name={`documents.${inputParams.name}`}
                    key={inputParams.name}
                    fileId={inputParams.value}
                />
            )
        case InputTypeEnum.documentList:
            return (
                <CustomFileInputGroup
                    {...inputParams}
                    name={`documents.${inputParams.name}`}
                    values={inputParams.value ? inputParams.value : []}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.date:
            return (
                !isMobile ? (
                    <CustomDateInput
                        {...inputParams}
                        key={inputParams.name}
                    />
                ) : (
                    <MobileCustomDateInput
                        {...inputParams}
                        key={inputParams.name}
                    />
                )
            )
        case InputTypeEnum.multipleSelector:
            return (
                !isMobile ? (
                    <CustomMultipleSelector
                        {...inputParams}
                        initialValues={[]}
                        values={inputParams.value || []}
                        key={inputParams.name}
                    />
                ) : (
                    <MobileCustomMultipleSelector
                        {...inputParams}
                        values={inputParams.value || []}
                        key={inputParams.name}
                    />
                )
            )
        case InputTypeEnum.checkbox:
            return (
                <CustomCheckbox
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.checkboxGroup:
            return (
                <CustomCheckboxGroup
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.radioGroup:
            return (
                <CustomRadioGroup
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.action:
            return (
                <CustomSubmitFormInput
                    {...inputParams}
                    items={inputParams.items as InputParamsItem<{
                        title: string,
                        body: Record<string, Record<string, Data>>
                    }>[]}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.inputNZ:
            return (
                <InputNZ
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.rating: // todo make independent component
            return (
                <div className={clsx('formSection__rating')}>
                    <div className={'formSection__rating__title'}>
                        <Typography component='legend'>{inputParams.title}</Typography>
                    </div>
                    <Rating key={`document_${inputParams.name}`} /* {...field} {...inputParams}*/ />
                </div>
            )
        case InputTypeEnum.title:
            return (
                <CustomTitle
                    title={inputParams.title}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.table:
            if (isMobile && inputParams.mobileComponent) {
                return getInputMobileComponent(inputParams)
            }

            return (
                <TabsTableWrapper
                    {...inputParams}
                    name={inputParams.formName}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.fieldArray:
            return (
                !isMobile ? (
                    <CustomFieldArray
                        {...inputParams}
                        disableMode={inputParams.disabled}
                        items={inputParams.items as unknown as InputParams[]}
                        key={inputParams.name}
                    />
                ) : (
                    <MobileCustomFieldArray
                        {...inputParams}
                        disableMode={inputParams.disabled}
                        items={inputParams.items as unknown as InputParams[]}
                        key={inputParams.name}
                    />
                )
            )
        case InputTypeEnum.reportTable:
            return (
                <ReportTable
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.organization:
            return (
                <CustomOrganizationInput
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.muiDate: // todo стили посередине выводится
            return (
                <CustomDate
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.fieldArrayPatent:
            return (
                <CustomFieldArrayPatent
                    {...inputParams}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.button: // dubious need
            return (
                <CustomButton
                    {...inputParams}
                    onClick={inputParams.onClick as (...args: any) => void}
                    key={inputParams.name}
                />
            )
        case InputTypeEnum.clientInfo:
            return (
                <ClientInfo
                    {...inputParams}
                    clientType={inputParams.clientType as ClientInfoEnum}
                />
            )
        case InputTypeEnum.qualityReminder:
            return (
                <QualityReminder />
            )
        default:
            return (<div>{inputType}</div>)
    }
}
