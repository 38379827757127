import { Dayjs } from 'dayjs'
import { SalaryObject, SalaryReport } from '~/API/salaryApi/salary.types'
import { JobPositionType } from '~/components/pages/Staff/model/jobPosition'

export type SalarySliceInitialState = {
    salaryMode: SalaryNavbarButtonsMods,
    salaryWorkers: SalaryWorkers[],
    salaryReport: SalaryReport,
    salaryTableInfo: SalaryTableInfo[],
    salaryPayment: SalaryObject[],
    salaryActiveDateRange: ActiveDateRange,
    salarySinglePayment: SingleSalary[],
    salaryFiles: SalaryFiles
    salaryLoader: boolean
    salaryError?: string
    personnelToIssued: string[]
    salaryJobPosition: SalaryJobPositions
}

export enum SalaryNavbarButtonsMods {
    DEFAULT = 'default',
    ACCOUNTABLE = 'accountable',
}

export interface SalaryFiles {
    issuedFiles: string[]
    payedFiles: string[]
}

export type SalaryJobPositions = 'Клинер' | 'Бригадир'

export interface SalaryWorkers {
    cleanings: SalaryCleaning[]
    id: string
    jobPosition: JobPositionType
    name: string
}

export interface SalaryCleaning {
    addSalary: string | number
    cleaningDate: Dayjs | Date
    date: string // date in "13 / пн" format
    id: string
    salary: SalaryObject | null
    petrolExpanses: string | number // check type
    rate: string | number
    salaryAmount: string | number
    address: string
    comment: string
    clientComment: string
    serviceRating: string
    qualityRating: string
}

export interface SalaryTableInfo {
    cleanings: SalaryCleaning[][]
    jobPosition: JobPositionType
    name: string
    personnelId: string
    sum: number
}

export interface SingleSalary {
    address: string
    cleaningId: string
    cleaningStartDate: string | Date | Dayjs
    id: string
    payment: number
    personnelId: string
    payedFileId: string
}

export interface ActiveDateRange {
    start: Dayjs
    end: Dayjs
}

