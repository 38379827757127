import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { InputParams } from '../../useFormConstructor.types'
import { StyledRowDiv } from '~/hooks/useFormConstructor/useFormConstructor.styled'

type InputsRowFormConstructorProps = {
    inputsRow: InputParams[]
    inputsArrIndex: number
    sectionKey: string
    methods: UseFormReturn<any>
    render: (
        input: InputParams,
        inputIndex: number,
        handleSetRowEmpty: (mode: 'inc' | 'dec') => void,
    ) => React.ReactNode
}

const InputsRowFormConstructor = (props: InputsRowFormConstructorProps) => {
    const {
        inputsRow,
        inputsArrIndex,
        sectionKey,
        methods,
        render,
    } = props
    const [emptyInputCount, setEmptyInputCount] = useState<number>(0)

    const handleSetRowEmpty = (mode: 'inc' | 'dec') => {
        if (mode === 'inc') {
            setEmptyInputCount((prev) => prev + 1)
        } else {
            setEmptyInputCount((prev) => {
                if (prev) {
                    return prev - 1
                }
                return 0
            })
        }
    }

    return (
        <FormProvider {...methods}>
            <StyledRowDiv // этот див будет отвечать за новую строчку
                $flexDirection={'row'}
                $isHidden={emptyInputCount === inputsRow.length}
            >
                {inputsRow.map((input: InputParams, inputIndex) => {
                    return render(input, inputIndex, handleSetRowEmpty)
                })}
            </StyledRowDiv>
        </FormProvider>
    )
}

export default InputsRowFormConstructor
