const tableTitle = `
        <h1>Коммерческое предложение №<span id='kpNumber'>___</span></h1>

        <table class='borderNoneTable' style='width: 100%; margin: 20px 0;'>
            <tbody>
            <tr id='kpOrganizationTr'>
                <td style='width: 50%'>Дата: <span id='kpDate'></span></td>
                <td>Название организации: <span id='kpOrganization'></span></td>
            </tr>
            <tr id='legalFaceAddressTr'>
                <td>Действительно до: <span id='kpEndDate'></span></td>
                <td>Адрес: <span id='legalFaceAddress'></span></td>
            </tr>
            <tr>
                <td>Менеджер: <span id='responsibleManager'></span></td>
                <td>Кому: <span id='organizationContactName'></span></td>
            </tr>
            <tr>
                <td>E-mail: <span id='responsibleManagerEmail'></span></td>
                <td>E-mail: <span id='organizationEmail'></span></td>
            </tr>
            <tr>
                <td>Контактный номер: <span id='kpPhone'></span></td>
                <td>Контактный номер: <span id='organizationPhone'></span></td>
            </tr>
            </tbody>
        </table>
`

const tableHeader = `
    <table class='servicesTable'>
        <tbody>
        <tr>
            <th colspan='6' style='text-align:center; height: 40px;'>Технологическая карта уборки</th>
        </tr>
        <tr>
            <th colspan='2' style='text-align:right; padding-right: 10px; width: 32%;'>Тип объекта:</th>
            <td colspan='4' id='objectType' style='padding-left: 10px;'>Коммерческий объект</td>
        </tr>

        <tr>
            <th colspan='2' style='text-align:right; padding-right: 10px;'>Контрагент:</th>
            <td colspan='4' id='counterparty' style='padding-left: 10px;'>Юр. лицо</td>
        </tr>
        <tr id='organizationNameTr'>
            <th colspan='2' style='text-align:right; padding-right: 10px;'>Название организации:</th>
            <td colspan='4' id='organizationName' style='padding-left: 10px;'>ООО «МЕБЕЛЬНАЯ МЕЛОДИЯ»
            </td>
        </tr>
        <tr>
            <th colspan='2' style='text-align:right; padding-right: 10px;'>Адрес объекта:</th>
            <td colspan='4' id='address' style='padding-left: 10px;'>Всеволожский р-н, поселок
                Щеглово, ул.
                Инженерная, д. 14
            </td>
        </tr>
        <tr>
            <th colspan='2' style='text-align:right; padding-right: 10px;'>Площадь:</th>
            <td colspan='4' id='area' style='padding-left: 10px;'>500м2(внутренняя) + 1500м2(внешняя)</td>
        </tr>
        </tbody>
    </table>
`

const servicesMainTable = `
    <table class='servicesTable' style='margin-top: -1px;'>
        <thead>
        <tr>
            <th colspan='1' style='width: 20px; text-align:center; height: 35px;'>№</th>
            <th colspan='6' style='width: 48%; text-align:center;'>Наименование работ/услуг</th>
            <th colspan='2' style='width: 30px; text-align:center;'>Кол-во</th>
            <th colspan='1' style='width: 30px; text-align:center;'>Ед.</th>
            <th colspan='2' style='width: 40px; text-align:center;'>Цена за ед.</th>
            <th colspan='2' style='text-align:center;'>Сумма</th>
        </tr>
        </thead>
        <tbody id='services'></tbody>
    </table>
`

const totalTable = `
        <table id='totalTable' class='servicesTable' style='margin-top: -1px;'>
            <tbody>
            <tr>
                <th colspan='6' style='background-color: #54D3C2; text-align:center; height: 25px;'>
                    Стоимость:
                </th>
            </tr>
            <tr>
                <th colspan='2' style='width:23%; text-align: right; padding-right: 5px; height: 35px;'>
                    Общая сумма<span class='nds'></span>
                </th>
                <td colspan='4' style='padding-left: 5px;'><span id='kpSum'></span>
                </td>
            </tr>
            <tr id='trDiscount'>
                <th colspan='2' style='text-align: right; padding-right: 5px; height: 25px;'>Скидка:</th>
                <td colspan='4' style='padding-left: 5px;'><span id='kpDiscount'></span>%
                </td>
            </tr>
            <tr id='trDiscountResult'>
                <th colspan='2' style='text-align: right; padding-right: 5px; height: 35px;'>
                    Итого со скидкой<span class='nds'></span>
                </th>
                <td colspan='4' style='padding-left: 5px;'><span id='kpSumDisc'></span></td>
            </tr>
            <tr id='trNds'>
                <th colspan='2' style='text-align: right; padding-right: 5px; height: 25px;'>В том числе
                    НДС:
                </th>
                <td colspan='4' style='padding-left: 5px;'><span id='kpNds'></span>
                </td>
            </tr>
            </tbody>
        </table>
    `

const totalTableOrisclean = `
        <table id='totalTable' class='servicesTable' style='margin-top: -1px;'>
            <tbody>
            <tr>
                <th colspan='6' style='background-color: #7cad42; text-align:center; height: 25px;'>
                    Стоимость:
                </th>
            </tr>
            <tr>
                <th colspan='2' style='width:23%; text-align: right; padding-right: 5px; height: 35px;'>
                    Общая сумма<span class='nds'></span>
                </th>
                <td colspan='4' style='padding-left: 5px;'><span id='kpSum'></span>
                </td>
            </tr>
            <tr id='trDiscount'>
                <th colspan='2' style='text-align: right; padding-right: 5px; height: 25px;'>Скидка:</th>
                <td colspan='4' style='padding-left: 5px;'><span id='kpDiscount'></span>%
                </td>
            </tr>
            <tr id='trDiscountResult'>
                <th colspan='2' style='text-align: right; padding-right: 5px; height: 35px;'>
                    Итого со скидкой<span class='nds'></span>
                </th>
                <td colspan='4' style='padding-left: 5px;'><span id='kpSumDisc'></span></td>
            </tr>
            <tr id='trNds'>
                <th colspan='2' style='text-align: right; padding-right: 5px; height: 25px;'>В том числе
                    НДС:
                </th>
                <td colspan='4' style='padding-left: 5px;'><span id='kpNds'></span>
                </td>
            </tr>
            </tbody>
        </table>
    `

const additionalServices = `<table class='servicesTable' style='margin-top: -1px;' id='adServicesTable'>
                <thead>
                    <tr>
                        <th colspan='14' style='text-align:center; height: 35px;'>Дополнительные услуги</th>
                    </tr>
                    <tr>
                        <th colspan='1' style='width: 20px; text-align:center; height: 35px;'>№</th>
                        <th colspan='6' style='width: 48%; text-align:center;'>Наименование работ/услуг</th>
                        <th colspan='2' style='width: 30px; text-align:center;'>Кол-во</th>
                        <th colspan='1' style='width: 30px; text-align:center;'>Ед.</th>
                        <th colspan='2' style='width: 40px; text-align:center;'>Цена за ед.</th>
                        <th colspan='2' style='text-align:center;'>Сумма</th>
                    </tr>
                </thead>
                <tbody id='adServices'>
                   
                </tbody>
           </table>`

export const lastKpServicesPageTemplate = `
<div class='pageWrapper pageWrapper_2' style='margin-top: 0;'>
<table style='border-collapse: collapse; border: none; width: 100%; table-layout:fixed; height: 100%;'>
            <tbody>
            <tr>
                <td style='border: none;background-color: #8eecdf; width: 30px; height: 100%; '></td>
                <td style='border: none; vertical-align: baseline; width: 673px; padding-left: 20px;'>
                    <table class='borderNoneTable' style='display: inline-block; margin: 20px 0;'>

                        <tbody>
                        <tr>
                            <td class='logoImg' rowspan='2' style='width: 10%'></td>
                            <th class='ourCompanyTitle' style='text-align:center; padding-left: 0;'>КЛИНИНГОВАЯ КОМПАНИЯ
                                «H2O»
                            </th>
                        </tr>
                        <tr>
                            <td style='text-align:center; color:gray; padding-left: 0; word-break: break-all;'>
                                <p style='text-align: center' class='firstCompanyLine'>196628, г. Санкт-Петербург,
                                    п.Шушары, Колпинское шоссе д.38, к.1, кв.531</p>
                                <p style='text-align: center' class='secondCompanyLine'>Телефон: 8(812) 504-88-15, E-mail:
                                    office@h2ocleaner.ru</p>
                                <p style='text-align: center' class='thirdCompanyLine'>ИНН 7820047425, КПП 782001001, ОГРН
                                    1167847105030</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <div id='additionalPageBody'>
                        ${servicesMainTable}
                        ${totalTable}
                    </div>
            
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
            
`

export const firstKpServicesPageTemplate = `
<div class='pageWrapper pageWrapper_2' style='margin-top: 0;'>
<table style='border-collapse: collapse; border: none; width: 100%; table-layout:fixed; height: 100%;'>
            <tbody>
            <tr>
                <td style='border: none;background-color: #8eecdf; width: 30px; height: 100%; '></td>
                <td style='border: none; vertical-align: baseline; width: 673px; padding-left: 20px;'>
                    <table class='borderNoneTable' style='display: inline-block; margin: 20px 0;'>

                        <tbody>
                        <tr>
                            <td class='logoImg' rowspan='2' style='width: 10%'></td>
                            <th class='ourCompanyTitle' style='text-align:center; padding-left: 0;'>КЛИНИНГОВАЯ КОМПАНИЯ
                                «H2O»
                            </th>
                        </tr>
                        <tr>
                            <td style='text-align:center; color:gray; padding-left: 0; word-break: break-all;'>
                                <p style='text-align: center' class='firstCompanyLine'>196628, г. Санкт-Петербург,
                                    п.Шушары, Колпинское шоссе д.38, к.1, кв.531</p>
                                <p style='text-align: center' class='secondCompanyLine'>Телефон: 8(812) 504-88-15, E-mail:
                                    office@h2ocleaner.ru</p>
                                <p style='text-align: center' class='thirdCompanyLine'>ИНН 7820047425, КПП 782001001, ОГРН
                                    1167847105030</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <div id='additionalPageBody'>
                        ${tableTitle}
                        ${tableHeader}
                        ${servicesMainTable}
                    </div>
            
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
`

export const KpServicesPageTemplate = `
<div class='pageWrapper pageWrapper_2' style='margin-top: 0;'>
<table style='border-collapse: collapse; border: none; width: 100%; table-layout:fixed; height: 100%;'>
            <tbody>
            <tr>
                <td style='border: none;background-color: #8eecdf; width: 30px; height: 100%; '></td>
                <td style='border: none; vertical-align: baseline; width: 673px; padding-left: 20px;'>
                    <table class='borderNoneTable' style='display: inline-block; margin: 20px 0;'>

                        <tbody>
                        <tr>
                            <td class='logoImg' rowspan='2' style='width: 10%'></td>
                            <th class='ourCompanyTitle' style='text-align:center; padding-left: 0;'>КЛИНИНГОВАЯ КОМПАНИЯ
                                «H2O»
                            </th>
                        </tr>
                        <tr>
                            <td style='text-align:center; color:gray; padding-left: 0; word-break: break-all;'>
                                <p style='text-align: center' class='firstCompanyLine'>196628, г. Санкт-Петербург,
                                    п.Шушары, Колпинское шоссе д.38, к.1, кв.531</p>
                                <p style='text-align: center' class='secondCompanyLine'>Телефон: 8(812) 504-88-15, E-mail:
                                    office@h2ocleaner.ru</p>
                                <p style='text-align: center' class='thirdCompanyLine'>ИНН 7820047425, КПП 782001001, ОГРН
                                    1167847105030</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <div id='additionalPageBody'>
                        ${servicesMainTable}
                    </div>
            
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
`

export const singleKpServicesPageTemplate = `
<div class='pageWrapper pageWrapper_2' style='margin-top: 0;'>
<table style='border-collapse: collapse; border: none; width: 100%; table-layout:fixed; height: 100%;'>
            <tbody>
            <tr>
                <td style='border: none;background-color: #8eecdf; width: 30px; height: 100%; '></td>
                <td style='border: none; vertical-align: baseline; width: 673px; padding-left: 20px;'>
                    <table class='borderNoneTable' style='display: inline-block; margin: 20px 0;'>

                        <tbody>
                        <tr>
                            <td class='logoImg' rowspan='2' style='width: 10%'></td>
                            <th class='ourCompanyTitle' style='text-align:center; padding-left: 0;'>КЛИНИНГОВАЯ КОМПАНИЯ
                                «H2O»
                            </th>
                        </tr>
                        <tr>
                            <td style='text-align:center; color:gray; padding-left: 0; word-break: break-all;'>
                                <p style='text-align: center' class='firstCompanyLine'>196628, г. Санкт-Петербург,
                                    п.Шушары, Колпинское шоссе д.38, к.1, кв.531</p>
                                <p style='text-align: center' class='secondCompanyLine'>Телефон: 8(812) 504-88-15, E-mail:
                                    office@h2ocleaner.ru</p>
                                <p style='text-align: center' class='thirdCompanyLine'>ИНН 7820047425, КПП 782001001, ОГРН
                                    1167847105030</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <div id='additionalPageBody'>
                        ${tableTitle}
                        ${tableHeader}
                        ${servicesMainTable}
                        ${additionalServices}
                        ${totalTable}
                    </div>
            
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
`


// костыль шаблоны для орисклина где отличатся только цвет полосы
export const lastKpServicesPageTemplateOrisclean = `
<div class='pageWrapper pageWrapper_2' style='margin-top: 0;'>
<table style='border-collapse: collapse; border: none; width: 100%; table-layout:fixed; height: 100%;'>
            <tbody>
            <tr>
                <td style='border: none;background-color: #7cad42; width: 30px; height: 100%; '></td>
                <td style='border: none; vertical-align: baseline; width: 673px; padding-left: 20px;'>
                    <table class='borderNoneTable' style='display: inline-block; margin: 20px 0;'>

                        <tbody>
                        <tr>
                            <td class='logoImg' rowspan='2' style='width: 10%'></td>
                            <th class='ourCompanyTitle' style='text-align:center; padding-left: 0;'>КЛИНИНГОВАЯ КОМПАНИЯ
                                «H2O»
                            </th>
                        </tr>
                        <tr>
                            <td style='text-align:center; color:gray; padding-left: 0; word-break: break-all;'>
                                <p style='text-align: center' class='firstCompanyLine'>196628, г. Санкт-Петербург,
                                    п.Шушары, Колпинское шоссе д.38, к.1, кв.531</p>
                                <p style='text-align: center' class='secondCompanyLine'>Телефон: 8(812) 504-88-15, E-mail:
                                    office@h2ocleaner.ru</p>
                                <p style='text-align: center' class='thirdCompanyLine'>ИНН 7820047425, КПП 782001001, ОГРН
                                    1167847105030</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <div id='additionalPageBody'>
                        ${servicesMainTable}
                        ${totalTableOrisclean}
                    </div>
            
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
            
`

export const firstKpServicesPageTemplateOrisclean = `
<div class='pageWrapper pageWrapper_2' style='margin-top: 0;'>
<table style='border-collapse: collapse; border: none; width: 100%; table-layout:fixed; height: 100%;'>
            <tbody>
            <tr>
                <td style='border: none;background-color: #7cad42; width: 30px; height: 100%; '></td>
                <td style='border: none; vertical-align: baseline; width: 673px; padding-left: 20px;'>
                    <table class='borderNoneTable' style='display: inline-block; margin: 20px 0;'>

                        <tbody>
                        <tr>
                            <td class='logoImg' rowspan='2' style='width: 10%'></td>
                            <th class='ourCompanyTitle' style='text-align:center; padding-left: 0;'>КЛИНИНГОВАЯ КОМПАНИЯ
                                «H2O»
                            </th>
                        </tr>
                        <tr>
                            <td style='text-align:center; color:gray; padding-left: 0; word-break: break-all;'>
                                <p style='text-align: center' class='firstCompanyLine'>196628, г. Санкт-Петербург,
                                    п.Шушары, Колпинское шоссе д.38, к.1, кв.531</p>
                                <p style='text-align: center' class='secondCompanyLine'>Телефон: 8(812) 504-88-15, E-mail:
                                    office@h2ocleaner.ru</p>
                                <p style='text-align: center' class='thirdCompanyLine'>ИНН 7820047425, КПП 782001001, ОГРН
                                    1167847105030</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <div id='additionalPageBody'>
                        ${tableTitle}
                        ${tableHeader}
                        ${servicesMainTable}
                    </div>
            
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
`

export const KpServicesPageTemplateOrisclean = `
<div class='pageWrapper pageWrapper_2' style='margin-top: 0;'>
<table style='border-collapse: collapse; border: none; width: 100%; table-layout:fixed; height: 100%;'>
            <tbody>
            <tr>
                <td style='border: none;background-color: #7cad42; width: 30px; height: 100%; '></td>
                <td style='border: none; vertical-align: baseline; width: 673px; padding-left: 20px;'>
                    <table class='borderNoneTable' style='display: inline-block; margin: 20px 0;'>

                        <tbody>
                        <tr>
                            <td class='logoImg' rowspan='2' style='width: 10%'></td>
                            <th class='ourCompanyTitle' style='text-align:center; padding-left: 0;'>КЛИНИНГОВАЯ КОМПАНИЯ
                                «H2O»
                            </th>
                        </tr>
                        <tr>
                            <td style='text-align:center; color:gray; padding-left: 0; word-break: break-all;'>
                                <p style='text-align: center' class='firstCompanyLine'>196628, г. Санкт-Петербург,
                                    п.Шушары, Колпинское шоссе д.38, к.1, кв.531</p>
                                <p style='text-align: center' class='secondCompanyLine'>Телефон: 8(812) 504-88-15, E-mail:
                                    office@h2ocleaner.ru</p>
                                <p style='text-align: center' class='thirdCompanyLine'>ИНН 7820047425, КПП 782001001, ОГРН
                                    1167847105030</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <div id='additionalPageBody'>
                        ${servicesMainTable}
                    </div>
            
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
`

export const singleKpServicesPageTemplateOrisclean = `
<div class='pageWrapper pageWrapper_2' style='margin-top: 0;'>
<table style='border-collapse: collapse; border: none; width: 100%; table-layout:fixed; height: 100%;'>
            <tbody>
            <tr>
                <td style='border: none;background-color: #7cad42; width: 30px; height: 100%; '></td>
                <td style='border: none; vertical-align: baseline; width: 673px; padding-left: 20px;'>
                    <table class='borderNoneTable' style='display: inline-block; margin: 20px 0;'>

                        <tbody>
                        <tr>
                            <td class='logoImg' rowspan='2' style='width: 10%'></td>
                            <th class='ourCompanyTitle' style='text-align:center; padding-left: 0;'>КЛИНИНГОВАЯ КОМПАНИЯ
                                «H2O»
                            </th>
                        </tr>
                        <tr>
                            <td style='text-align:center; color:gray; padding-left: 0; word-break: break-all;'>
                                <p style='text-align: center' class='firstCompanyLine'>196628, г. Санкт-Петербург,
                                    п.Шушары, Колпинское шоссе д.38, к.1, кв.531</p>
                                <p style='text-align: center' class='secondCompanyLine'>Телефон: 8(812) 504-88-15, E-mail:
                                    office@h2ocleaner.ru</p>
                                <p style='text-align: center' class='thirdCompanyLine'>ИНН 7820047425, КПП 782001001, ОГРН
                                    1167847105030</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <div id='additionalPageBody'>
                        ${tableTitle}
                        ${tableHeader}
                        ${servicesMainTable}
                        ${additionalServices}
                        ${totalTableOrisclean}
                    </div>
            
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
`
