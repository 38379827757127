import clsx from 'clsx'
import { ChangeEvent, memo } from 'react'
import { FaCheck } from 'react-icons/fa'
import '../CustomCheckboxGroup/CustomCheckboxGroup.scss'

export enum CustomCheckboxTheme {
    THIN = 'thin',
    TABLE = 'table',
    CHECK_MARK = 'checkMark',
    ROUND = 'round',
}

interface ICustomCheckbox {
    caption?: any
    title?: string
    name?: string
    id: string
    className?: string
    value: any
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
    error?: any
    theme?: CustomCheckboxTheme
}

const CustomCheckbox = memo((props: ICustomCheckbox) => {
    const {
        caption,
        title,
        name,
        id,
        className,
        value,
        onChange,
        disabled,
        error,
        theme,
    } = props

    return (
        <div className={clsx('customCheckbox', className, { disabled, error })}>
            {title && <h5>{title}</h5>}
            <div className='customCheckbox__body'>
                <input
                    type='checkbox'
                    name={name}
                    checked={value}
                    onChange={onChange}
                    id={id}
                    disabled={disabled}
                />
                <label
                    className={clsx('customCheckbox__label', theme && `customCheckbox__label--${theme}`)}
                    htmlFor={id}
                >
                    <div className={clsx('customCheckbox__square', { error })}>
                        {theme === CustomCheckboxTheme.CHECK_MARK && value &&
                            <FaCheck role='img' className='customCheckbox__icon' />
                        }
                    </div>
                    {caption}
                </label>
            </div>
        </div>
    )
})

export default CustomCheckbox
