import { SERVICES } from '../../util/templates'
import { serviceTypes } from '../../util/types'
import { CustomInputIcons, InputMask } from '~/components/common/inputs/CustomInput/CustomInput'

export const primaryInformation = {
    bankName: (disabled?: boolean) => ({
        title: 'Наименование банка',
        name: 'organizationRequisites.bankName',
        type: 'text',
        disabled,
    }),
    bankId: (disabled?: boolean) => ({
        title: 'БИК',
        name: 'organizationRequisites.bankId',
        type: 'text',
        disabled,
    }),
    bankAccount: (disabled?: boolean) => ({
        title: 'Р/С',
        name: 'organizationRequisites.bankAccount',
        type: 'text',
        disabled,
    }),
    correspondentAccount: (disabled?: boolean) => ({
        title: 'К/С',
        name: 'organizationRequisites.correspondentAccount',
        type: 'text',
        disabled,
    }),
    organizationInn: (disabled?: boolean) => ({
        title: 'ИНН',
        name: 'organizationRequisites.inn',
        type: 'text',
        disabled,
    }),
    organizationManagementName: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'ФИО руководителя',
        name: 'organizationRequisites.management.name',
        type: 'text',
    }),
    organizationManagementPost: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Должность руководителя',
        name: 'organizationRequisites.management.post',
        type: 'text',
    }),
    organizationNameShort: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Наименование',
        name: 'organizationRequisites.name',
        type: 'text',
    }),
    organizationKpp: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'КПП',
        name: 'organizationRequisites.kpp',
        type: 'text',
    }),
    organizationOgrn: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Код ОКАТО',
        name: 'organizationRequisites.okato',
        type: 'text',
    }),
    organizationOkato: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'ОГРН',
        name: 'organizationRequisites.ogrn',
        type: 'text',
    }),
    orderCreator: (disabled?: boolean) => ({
        title: 'Заявку создал',
        name: 'orderCreator',
        className: 'input50',
        disabled: true,
        type: 'user',
    }),
    orderCreatedAt: (disabled?: boolean) => ({
        title: 'Дата создания',
        name: 'orderCreatedAt',
        className: 'input50',
        singleDate: true,
        disabled: true,
        type: 'date',
    }),
    cleaningType: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Тип уборки',
        name: 'cleaningType',
        className: 'input50',
        type: 'select',
        items: [
            { value: 'Разовая уборка', text: 'Разовая уборка' },
            { value: 'Рамочный договор', text: 'Рамочный договор' },
            { value: 'Абонемент', text: 'Абонемент' },
        ],
    }),
    orderType: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Тип заявки',
        name: 'orderType',
        className: 'input50',
        type: 'select',
        items: [
            { value: 'Осмотр/расчет', text: 'Осмотр/расчет' },
            { value: 'Уборка', text: 'Уборка' },
        ],
    }),
    buildingType: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Тип объекта',
        name: 'buildingType',
        className: 'input50 input_objectType',
        type: 'select',
        items: [
            { value: 'Квартира', text: 'Квартира' },
            { value: 'Коттедж', text: 'Коттедж' },
            { value: 'Коммерческий объект', text: 'Коммерческий объект' },
            { value: 'Проектный объект', text: 'Проектный объект' },
        ],
    }),
    buildingClass: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Объект',
        name: 'buildingClass',
        className: 'input50',
        type: 'select',
        search: true,
        items: {
            flat: [
                { value: 'студия', text: 'студия' },
                { value: '1к.', text: '1к.' },
                { value: 'евро2', text: 'евро2' },
                { value: '2к.', text: '2к.' },
                { value: 'евро3', text: 'евро3' },
                { value: '3к.', text: '3к.' },
                { value: '4к.', text: '4к.' },
                { value: '5к.', text: '5к.' },
                { value: 'свыше 5к.', text: 'свыше 5к.' },
                { value: 'этажная', text: 'этажная' },
            ],
            cottage: [{ value: 'Коттедж/Дом/Таунхаус', text: 'Коттедж/Дом/Таунхаус' }],
            commercial: [
                { value: 'Авто/ЖД вокзалы', text: 'Авто/ЖД вокзалы' },
                { value: 'Автостоянки(паркинг)', text: 'Автостоянки(паркинг)' },
                { value: 'АЗС/АГНКС', text: 'АЗС/АГНКС' },
                { value: 'Аптека', text: 'Аптека' },
                { value: 'Арендатор', text: 'Арендатор' },
                { value: 'Аэропорт', text: 'Аэропорт' },
                { value: 'Бани/Сауны', text: 'Бани/Сауны' },
                { value: 'Банкетные залы/площадки для мероприятий', text: 'Банкетные залы/площадки для мероприятий' },
                { value: 'Банки', text: 'Банки' },
                { value: 'Бассейн', text: 'Бассейн' },
                { value: 'Батутный центр', text: 'Батутный центр' },
                { value: 'Бизнес центр/Деловой центр', text: 'Бизнес центр/Деловой центр' },
                { value: 'Больница/Клиника/Стоматология', text: 'Больница/Клиника/Стоматология' },
                { value: 'Военные объекты/Объекты силовых ведомств', text: 'Военные объекты/Объекты силовых ведомств' },
                { value: 'Гипермаркет', text: 'Гипермаркет' },
                { value: 'Гольфклубы', text: 'Гольфклубы' },
                { value: 'Гостиница/отель/хостел', text: 'Гостиница/отель/хостел' },
                { value: 'Государственное учреждение', text: 'Государственное учреждение' },
                {
                    value: 'Детский сад/детский центр/частные образовательные учреждения',
                    text: 'Детский сад/детский центр/частные образовательные учреждения',
                },
                { value: 'Диллерский центр', text: 'Диллерский центр' },
                { value: 'Кинотеатр', text: 'Кинотеатр' },
                { value: 'Клубы/кальянные/бары', text: 'Клубы/кальянные/бары' },
                { value: 'Кондитерские', text: 'Кондитерские' },
                { value: 'Лаборатории', text: 'Лаборатории' },
                { value: 'Логистические центры', text: 'Логистические центры' },
                { value: 'Магазин', text: 'Магазин' },
                { value: 'Мастерские столярные и т.п.', text: 'Мастерские столярные и т.п.' },
                { value: 'Музей/филармония/капелла/театр', text: 'Музей/филармония/капелла/театр' },
                { value: 'НИИ', text: 'НИИ' },
                { value: 'Общепит/кафе/ресторан', text: 'Общепит/кафе/ресторан' },
                { value: 'Офисные помещения (В БЦ)', text: 'Офисные помещения (В БЦ)' },
                { value: 'Офисные помещения (Отдельностоящие)', text: 'Офисные помещения (Отдельностоящие)' },
                { value: 'Пищевые комбинаты', text: 'Пищевые комбинаты' },
                { value: 'Прачечные', text: 'Прачечные' },
                { value: 'Предприятия по фасовке', text: 'Предприятия по фасовке' },
                {
                    value: 'Производственные помещения/Завод/Фабрика/Цех',
                    text: 'Производственные помещения/Завод/Фабрика/Цех',
                },
                { value: 'Салон красоты/Барбершоп', text: 'Салон красоты/Барбершоп' },
                { value: 'Санаторий/база отдыха/пансионат', text: 'Санаторий/база отдыха/пансионат' },
                { value: 'Сетевой ресторан/общепит', text: 'Сетевой ресторан/общепит' },
                { value: 'Сетевые магазины', text: 'Сетевые магазины' },
                { value: 'Сетевые мед. центры/стоматологии', text: 'Сетевые мед. центры/стоматологии' },
                { value: 'Сетевые спортклубы', text: 'Сетевые спортклубы' },
                { value: 'Сетевые СТО', text: 'Сетевые СТО' },
                { value: 'Складские помещения', text: 'Складские помещения' },
                { value: 'Спа комплекс/аквапарки', text: 'Спа комплекс/аквапарки' },
                { value: 'Спортивный центр/спортклуб/стадион', text: 'Спортивный центр/спортклуб/стадион' },
                { value: 'Станции технического обслуживания (СТО)', text: 'Станции технического обслуживания (СТО)' },
                { value: 'Стройплощадка/стройка', text: 'Стройплощадка/стройка' },
                { value: 'Студии частного мастерства', text: 'Студии частного мастерства' },
                {
                    value: 'Типографии/Предприятия печатного производства',
                    text: 'Типографии/Предприятия печатного производства',
                },
                { value: 'Торговый центр/ТРЦ', text: 'Торговый центр/ТРЦ' },
                {
                    value: 'Транспорт (автобусы, автомобили, фургоны, вагоны, самолеты)',
                    text: 'Транспорт (автобусы, автомобили, фургоны, вагоны, самолеты)',
                },
                { value: 'Университет/Школа', text: 'Университет/Школа' },
                { value: 'Управляющие компании/ЖКХ', text: 'Управляющие компании/ЖКХ' },
                { value: 'Церковь/Монастырь', text: 'Церковь/Монастырь' },
                { value: 'Яхты,корабль', text: 'Яхты,корабль' },
            ],
        },
    }),
    counterparty: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Контрагент',
        name: 'counterparty',
        className: 'input50',
        type: 'select',
        items: [
            { value: 'Физ. лицо', text: 'Физ. лицо' },
            { value: 'Юр. лицо', text: 'Юр. лицо' },
        ],
    }),
    sex: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Пол',
        name: 'sex',
        className: 'input50',
        type: 'select',
        items: [
            { value: 'Мужской', text: 'Мужской' },
            { value: 'Женский', text: 'Женский' },
        ],
    }),
    organization: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Организация',
        name: 'organizationRequisites',
        className: 'input50',
        type: 'organization',
    }),
    contactInfo: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'contactInfo',
        type: 'fieldArray',
        items: [
            {
                disabled: disabled,
                title: 'Имя',
                name: 'clientFullname',
                className: 'input50',
                type: 'text',
                required: true,
            },
            {
                disabled: disabled,
                title: 'Должность',
                name: 'clientPost',
                className: 'input50',
                type: 'text',
                required: false,
            },
            {
                disabled: disabled,
                title: 'Телефон',
                name: 'clientPhone',
                className: 'input50',
                type: 'text',
                mask: InputMask.PHONE_NUMBER,
                required: true,
                maxLength: 18,
                icon: CustomInputIcons.PHONE,
            },
            {
                disabled: disabled,
                title: 'Email',
                name: 'clientEmail',
                className: 'input50',
                type: 'text',
                required: false,
                icon: CustomInputIcons.EMAIL,
            },
        ],
    }),

    isPassport: (disabled?: boolean) => ({
        disabled: disabled,
        caption: 'Ввести паспортные данные?',
        title: 'Паспортные данные',
        name: 'isPassport',
        className: 'input100',
        type: 'checkbox',
        required: false,
    }),
    passportFullname: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'ФИО',
        name: 'passportData.passportFullname',
        className: 'input33',
        type: 'text',
        required: true,
    }),
    passportPhone: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Номер телефона',
        name: 'passportData.passportPhone',
        className: 'input33',
        type: 'text',
        mask: InputMask.PHONE_NUMBER,
        required: true,
        maxLength: 10,
    }),
    passportBirthday: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Дата рождения',
        name: 'passportData.passportBirthday',
        className: 'input33',
        type: 'text',
        required: true,
        mask: InputMask.DATE,
    }),
    passportSeries: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Серия паспорта',
        name: 'passportData.passportSeries',
        className: 'input33',
        type: 'text',
        mask: InputMask.FOUR,
        required: true,
        maxLength: 4,
    }),
    passportNumber: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Номер паспорта',
        name: 'passportData.passportNumber',
        className: 'input33',
        type: 'text',
        mask: InputMask.SIX,
        required: true,
        maxLength: 6,
    }),
    subdivisionCode: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Код подразделения',
        name: 'passportData.subdivisionCode',
        className: 'input33',
        type: 'text',
        mask: InputMask.SIX_DASH,
        required: true,
        maxLength: 6,
    }),
    issuedBy: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Кем выдан',
        name: 'passportData.issuedBy',
        className: 'input66',
        type: 'text',
        multiline: true,
        required: true,
    }),
    issuedDate: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Дата выдачи',
        name: 'passportData.issuedDate',
        className: 'input66',
        type: 'text',
        required: true,
        mask: InputMask.DATE,
    }),
    residencePlace: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Адрес прописки',
        name: 'passportData.residencePlace',
        className: 'input100',
        type: 'text',
        required: true,
    }),
    address: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Адрес',
        name: 'address',
        type: 'address',
        className: 'input100',
    }),
    objectArea: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Площадь(м2)',
        name: 'objectArea',
        className: 'input33',
        type: 'text',
        mask: InputMask.NUMBER,
    }),
    bathroom: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Кол-во сан.узлов',
        name: 'bathroom',
        className: 'input33',
        type: 'text',
        mask: InputMask.NUMBER,
    }),
    serviceType: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Вид услуги',
        name: 'serviceType',
        className: 'input50',
        type: 'select',
        items: [
            { value: 'Поддерживающая уборка', text: 'Поддерживающая уборка' },
            { value: 'Генеральная уборка Luxe', text: 'Генеральная уборка Luxe' },
            {
                value: 'Послестроительная уборка(комплексная)',
                text: 'Послестроительная уборка(комплексная)',
            },
            { value: 'Химчистка', text: 'Химчистка' },
            { value: 'Остекление', text: 'Остекление' },
            { value: 'Пром. альпинизм', text: 'Пром. альпинизм' },
            { value: 'Роторная чистка', text: 'Роторная чистка' },
            { value: 'Дезинфекция', text: 'Дезинфекция' },
            { value: 'Нанесение полимера', text: 'Нанесение полимера' },
            { value: 'Уборка территории', text: 'Уборка территории' },
            { value: 'Уборка снега', text: 'Уборка снега' },
            { value: 'Спец. работы', text: 'Спец. работы' },
        ],
    }),
    additionalService: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Доп.услуги',
        name: 'additionalService',
        className: 'customField input50',
        type: 'multipleSelector',
        placeholder: 'Выберите значения',
        items: [
            { value: 'Химчистка', text: 'Химчистка' },
            { value: 'Балкон', text: 'Балкон' },
            { value: 'Дезинфекция', text: 'Дезинфекция' },
            { value: 'Роторная чистка', text: 'Роторная чистка' },
            { value: 'Нанесение полимера', text: 'Нанесение полимера' },
            { value: 'Доп. работы', text: 'Доп. работы' },
            { value: 'Спец. работы', text: 'Спец. работы' },
        ],
    }),
    balconies: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'balconies',
        type: 'fieldArray',
        items: [
            {
                disabled: disabled,
                title: 'Площадь балкона',
                name: 'balconyArea',
                className: 'input33',
                type: 'text',
                mask: InputMask.NUMBER,
                required: true,
            },
            {
                disabled: disabled,
                title: 'Вид балкона',
                name: 'balconyType',
                className: 'input33',
                type: 'select',
                required: true,
                items: [
                    { value: 'Стандартный', text: 'Стандартный' },
                    { value: 'Балкон панорамный', text: 'Балкон панорамный' },
                    { value: 'Французский балкон', text: 'Французский балкон' },
                ],
            },
            {
                disabled: disabled,
                title: 'Материал балкона',
                name: 'balconyMaterial',
                className: 'input33',
                type: 'select',
                required: true,
                items: [
                    { value: 'Дерево', text: 'Дерево' },
                    { value: 'Пластик', text: 'Пластик' },
                ],
            },
        ],
    }),
    windows: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'windows',
        type: 'fieldArray',
        items: [
            {
                disabled: disabled,
                title: 'Количество окон',
                name: 'windowNumber',
                className: 'input33',
                type: 'text',
                mask: InputMask.NUMBER,
                required: false,
            },
            {
                disabled: disabled,
                title: 'Вид окна',
                name: 'windowType',
                className: 'input33',
                type: 'select',
                required: false,
                items: [
                    { value: 'Входная группа', text: 'Входная группа' },
                    {
                        value: 'Одностворчатое (классическое 1300*800мм)',
                        text: 'Одностворчатое (классическое 1300*800мм)',
                    },
                    {
                        value: 'Двухстворчатое (классическое 1300*1400мм)',
                        text: 'Двухстворчатое (классическое 1300*1400мм)',
                    },
                    {
                        value: 'Трехстворчатое окно (классическое 2040*1400мм)',
                        text: 'Трехстворчатое окно (классическое 2040*1400мм)',
                    },
                    {
                        value: 'Трехстворчатое окно (классическое 2040*2040мм)',
                        text: 'Трехстворчатое окно (классическое 2040*2040мм)',
                    },
                    { value: 'Т-образное двухстворчатое', text: 'Т-образное двухстворчатое' },
                    { value: 'Т-образное трёхстворчатое', text: 'Т-образное трёхстворчатое' },
                    {
                        value: 'Панорамное (с одной стороны)',
                        text: 'Панорамное окно (с одной стороны)',
                    },
                ],
            },
            {
                disabled: disabled,
                title: 'Материал окна',
                name: 'windowMaterial',
                className: 'input33',
                type: 'select',
                required: false,
                items: [
                    { value: 'Дерево', text: 'Дерево' },
                    { value: 'Пластик', text: 'Пластик' },
                ],
            },
        ],
    }),
    comment: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Комментарий',
        name: 'comment',
        className: 'input100',
        type: 'text',
        multiline: true,
        required: false,
    }),
    services: (disabled: boolean, serviceType: string, businessType: string) => ({
        disabled: disabled,
        title: 'Выполняемые работы',
        className: 'primaryServices',
        buttonCaption: 'Помещение не убирается',
        placeholderIn: 'Закрыть таблицу',
        placeholderOut: 'Открыть таблицу',
        type: 'table',
        name: 'services',
        template: SERVICES[serviceTypes(serviceType) as keyof typeof SERVICES] || SERVICES.luxe,
        validate: (v: any[]) => v.length > 0,
        custom: 'Добавить помещение',
        sections:
            businessType === 'B2C' ?
                    [
                        { name: 'glasses', title: 'Окна' },
                        { name: 'balcony', title: 'Балкон' },
                        { name: 'rooms', title: 'Комната' },
                        { name: 'kitchen', title: 'Кухня' },
                        { name: 'bath', title: 'Санузел' },
                    ] :
                    [{ name: 'area', title: 'Помещение' }],
        inputs: [
            {
                name: 'name',
                title: 'Наименование работ',
                default: '',
                multiline: true,
                required: true,
            },
            {
                name: 'note',
                title: 'Примечание',
                default: '',
                className: 'table__td_40',
                multiline: true,
                required: false,
            },
        ],
    }),
    cleaningDuration: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Продолжительность',
        name: 'cleaningDuration',
        className: 'input33',
        type: 'text',
    }),
    cost: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Стоимость',
        name: 'cost',
        className: 'input33',
        type: 'text',
        mask: InputMask.COST,
    }),
    discount: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Скидка',
        name: 'discount',
        className: 'input50',
        type: 'select',
        items: [
            { value: 0, text: '0%' },
            { value: 5, text: '5%' },
            { value: 10, text: '10%' },
            { value: 15, text: '15%' },
            { value: 20, text: '20%' },
            { value: 25, text: '25%' },
            { value: 30, text: '30%' },
            { value: 35, text: '35%' },
            { value: 40, text: '40%' },
            { value: 45, text: '45%' },
            { value: 50, text: '50%' },
        ],
    }),
    paymentType: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Тип оплаты',
        name: 'paymentType',
        className: 'input50',
        type: 'select',
        items: [
            {
                value: 'р/с (НДС)',
                text: 'р/с (НДС)',
            },
            {
                value: 'р/с (без НДС)',
                text: 'р/с (без НДС)',
            },
            {
                value: 'Наличные',
                text: 'Наличные',
            },
        ],
    }),
    prepaymentType: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Тип предоплаты',
        name: 'prepaymentType',
        className: 'input50',
        type: 'select',
        items: [
            {
                value: 'Без предоплаты',
                text: 'Без предоплаты',
            },
            {
                value: 'На объекте',
                text: 'На объекте',
            },
            {
                value: 'Внесена',
                text: 'Внесена',
            },
            {
                value: 'Бронирование даты',
                text: 'Бронирование даты',
            },
        ],
    }),
    prepaymentSelect: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Предоплата(%)',
        name: 'prepayment',
        className: 'input100',
        type: 'select',
        required: true,
        items: [
            { value: 0, text: '0%' },
            { value: 10, text: '10%' },
            { value: 20, text: '20%' },
            { value: 30, text: '30%' },
            { value: 40, text: '40%' },
            { value: 50, text: '50%' },
            { value: 60, text: '60%' },
            { value: 70, text: '70%' },
            { value: 80, text: '80%' },
            { value: 90, text: '90%' },
            { value: 100, text: '100%' },
        ],
    }),
    prepaymentText: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Предоплата(₽)',
        name: 'prepayment',
        className: 'input100',
        type: 'text',
        required: true,
        items: [],
    }),
    attractionSource: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Источник',
        name: 'attractionSource',
        className: 'input50',
        type: 'select',
        items: [
            { value: 'Контекстная реклама (Google)', text: 'Контекстная реклама (Google)' },
            { value: 'Контекстная реклама (Yandex)', text: 'Контекстная реклама (Yandex)' },
            { value: 'SEO (сайт)', text: 'SEO (сайт)' },
            {
                value: 'Таргетированная реклама (Instagram)',
                text: 'Таргетированная реклама (Instagram)',
            },
            { value: 'ВК', text: 'ВК' },
            { value: 'Тендер', text: 'Тендер' },
            { value: 'Наша база', text: 'Наша база' },
            { value: 'Холодная база', text: 'Холодная база' },
            { value: 'Instagram', text: 'Instagram' },
            { value: 'Реклама на машине', text: 'Реклама на машине' },
            { value: 'Рамочный договор', text: 'Рамочный договор' },
            { value: 'Объект из ППО', text: 'Объект из ППО' },
            { value: 'Листовки/Буклеты/Визитки', text: 'Листовки/Буклеты/Визитки' },
            { value: 'Почта (office)', text: 'Почта (office)' },
            { value: 'Постоянный клиент', text: 'Постоянный клиент' },
            { value: 'от Партнеров', text: 'от Партнеров' },
            { value: 'Рекомендации', text: 'Рекомендации' },
            { value: 'Абонемент', text: 'Абонемент' },
        ],
    }),
    sourceTypeSelect: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Способ контакта',
        name: 'sourceType',
        className: 'input50',
        type: 'select',
        items: [
            { value: '-', text: '-' },
            { value: 'Входящий звонок', text: 'Входящий звонок' },
            { value: 'Заявка', text: 'Заявка' },
            { value: 'Переписка', text: 'Переписка' },
            { value: 'Перевод на звонок', text: 'Перевод на звонок' },
            { value: 'Обзвон', text: 'Обзвон' },
            { value: 'Блогер', text: 'Блогер' },
            { value: 'Рассылка', text: 'Рассылка' },
            { value: 'Повторный заказ (Клиент)', text: 'Повторный заказ (Клиент)' },
        ],
    }),
    sourceTypeText: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Тип источника',
        name: 'sourceType',
        className: 'input50',
        type: 'text',
    }),
    crmLink: (disabled?: boolean) => ({
        disabled: disabled,
        title: 'Ссылка на ЦРМ',
        name: 'crmLink',
        className: 'input33',
        type: 'text',
    }),
    photosObject: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'photosObject',
        title: 'Контент с объекта',
        max: 150,
        type: 'documentList',
        button: 'Загрузить фото',
        allowedFileTypes: ['image', 'video'],
    }),
}
