import { BiTime } from 'react-icons/bi'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'

import './BitrixButton.scss'

interface BitrixButtonProps {
    bitrixUrl: string
}

const BitrixButton = (props: BitrixButtonProps) => {
    const { bitrixUrl } = props

    return (
        <a
            className='bitrixButton__link'
            href={bitrixUrl}
            target='_blank' rel='noreferrer'
        >
            <CustomButton
                className='bitrixButton'
                onClick={() => {
                }}
            >
                Битрикс
                <span>24</span>
                <BiTime
                    size={12}
                    className='bitrixButton__icon'
                />
            </CustomButton>
        </a>
    )
}

export default BitrixButton
