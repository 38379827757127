export const counterpartyInterceptor = (order: any) => {
    const orderClone = {
        ...order,
        primaryInformation: {
            ...order.primaryInformation,
            ...(order.primaryInformation.counterparty === 'Физ. лицо' ?
                    { organizationRequisites: null } :
                    { sex: null, clientRequisites: null }),
        },
        nextStageName: null,
    }

    return orderClone
}
