import { Dayjs } from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useCancelSingleSalaryMutation, useUpdateSalaryMutation } from '~/API/salaryApi/salary'
import { SalaryObject } from '~/API/salaryApi/salary.types'
import CustomFileInputSingle, {
    CustomFileInputSingleTheme,
} from '~/components/common/inputs/CustomFileInputSingle/CustomFileInputSingle'
import CustomInput, { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { CustomPopup } from '~/components/common/ReusingComponents/CustomPopup/CustomPopup'
import { selectSalaryWorkers } from '~/redux/selectors/salarySlice.selectors'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { SingleSalary } from '~/redux/slices/salarySlice/salarySlice.type'
import { fetchDate } from '~/shared/util/currentTime'
import '../../../../SalaryNavbar/SinglePaymentModal/SinglePaymentModal.scss'

interface SinglePaymentCancelModalProps {
    onClose: () => void,
    cleaningDate: Date | Dayjs,
    singleSalary: SingleSalary | SalaryObject,
    orderId: string,
    address: string,
    isNzTeam?: boolean
}

const SinglePaymentCancelModal = (props: SinglePaymentCancelModalProps) => {
    const {
        onClose,
        cleaningDate,
        singleSalary,
        orderId,
        address,
        isNzTeam = false,
    } = props
    const dispatch = useDispatch()
    const [cancelSingleSalary] = useCancelSingleSalaryMutation()
    const [updateSalary] = useUpdateSalaryMutation()

    const salaryWorkers = useSelector(selectSalaryWorkers)
    const {
        control,
        handleSubmit,
        setValue,
    } = useForm()

    const [disabled, setDisabled] = useState<boolean>(false)
    const [currentFileId, setCurrentFileId] = useState<string>(singleSalary.payedFileId || '')
    const [isFileChanging, setFileChanging] = useState<boolean>(false)
    const currentPersonnel = salaryWorkers.find((worker) => worker.id === singleSalary.personnelId)

    const handleChangeFile = useCallback(() => {
        setCurrentFileId('')
        setFileChanging(true)
    }, [])

    const handleSetFileId = useCallback((fileId: string) => {
        setCurrentFileId(fileId)
    }, [])

    const handleSaveNewFile = handleSubmit(
        async (data) => {
            setDisabled(true)
            await updateSalary({
                id: singleSalary.id,
                payedFileId: data.singleCancelPaymentModal.file,
            }).unwrap()
                .then(() => {
                    onClose()
                })
                .finally(() =>
                    setDisabled(false),
                )
        },
        () => {
            dispatch(SHOW_NOTICE({ type: 'error', message: 'Необходимо заполнить все поля' }))
        })

    const onSubmit = useCallback(async () => {
        setDisabled(true)
        await cancelSingleSalary({
            id: singleSalary.id,
        }).unwrap()
            .then(() => {
                onClose()
            })
            .finally(() =>
                setDisabled(false),
            )
    }, [])

    useEffect(() => {
        setValue('singleCancelPaymentModal.file', currentFileId)
    }, [currentFileId])

    return (
        <CustomPopup
            onClose={onClose}
            title={{ title: 'Единоразовая выплата' }}
            body={{
                data: [
                    (
                        <CustomInput
                            key='singleCancelPaymentModal.personnelId'
                            disabled
                            name='personnelId'
                            value={currentPersonnel?.name}
                            title='Имя сотрудника'
                        />
                    ),
                    isNzTeam || !address ? (
                        <CustomInput
                            key='singleCancelPaymentModal.date'
                            disabled
                            name='date'
                            value={fetchDate(cleaningDate).format('DD.MM - ') + (address || 'без адреса')}
                            title='Дата выплаты'
                        />
                    ) : (
                        <CustomInput
                            key='singleCancelPaymentModal.date'
                            disabled
                            name='date'
                            mask={InputMask.A}
                            value={fetchDate(cleaningDate).format('DD.MM - ') + address}
                            href={`/order/${orderId}`}
                            title='Дата выплаты'
                        />
                    ), (
                        <CustomInput
                            key='singleCancelPaymentModal.payment'
                            disabled
                            name='payment'
                            value={singleSalary.payment?.toString()}
                            mask={InputMask.COST}
                            title='Сумма выплаты'
                        />
                    ), (
                        <Controller
                            key='singleCancelPaymentModal.file'
                            name='singleCancelPaymentModal.file'
                            rules={{ required: true }}
                            defaultValue={''}
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <CustomFileInputSingle
                                        title={'Загрузить файл'}
                                        fileId={value}
                                        className='single-payment-modal__file'
                                        disabled={!isFileChanging}
                                        onChange={(value) => {
                                            handleSetFileId(value)
                                            onChange(value)
                                        }}
                                        name={'name'}
                                        error={error}
                                        allowedFileTypes={['application', 'image']}
                                        theme={CustomFileInputSingleTheme.BUTTON}
                                    />
                                )
                            }}
                        />
                    ),
                ],
            }}
            footer={[
                (
                    <CustomButton
                        key='singleCancelPaymentModal.cancelPayment'
                        onClick={onSubmit}
                        title='Отменить выплату'
                        theme={CustomButtonThemes.COLOR}
                        disabled={disabled}
                    />
                ), (
                    <CustomButton
                        key='singleCancelPaymentModal.saveDocument'
                        onClick={() => {
                            if (isFileChanging) {
                                handleSaveNewFile()
                            } else {
                                handleChangeFile()
                            }
                        }}
                        title={isFileChanging ? 'Сохранить документ' : 'Изменить документ'}
                        theme={CustomButtonThemes.COLOR}
                        disabled={disabled}
                    />
                ),
            ]}
        />
    )
}

export default SinglePaymentCancelModal
