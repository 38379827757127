import MobileAdditionalServices
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileAdditionalServices/MobileAdditionalServices'
import MobileForemanChecklist
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileForemanChecklist/MobileForemanChecklist'
import MobilePersonnelReport
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobilePersonnelReport/MobilePersonnelReport'
import MobileServicesVerification
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileServicesVerification/MobileServicesVerification'
import MobileToolsReport
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileToolsReport/MobileToolsReport'
import { InputParams, MobileComponentKeys } from '~/hooks/useFormConstructor/useFormConstructor.types'

export const getInputMobileComponent = (inputParams: InputParams) => {
    switch (inputParams.mobileComponent) {
        case MobileComponentKeys.SERVICES_VERIFICATION:
            return <MobileServicesVerification key={inputParams.name} {...inputParams} name={inputParams.formName} />
        case MobileComponentKeys.TOOLS_REPORT:
            return <MobileToolsReport key={inputParams.name} {...inputParams} name={inputParams.formName} />
        case MobileComponentKeys.FOREMAN_CHECK_LIST:
            return <MobileForemanChecklist key={inputParams.name} {...inputParams} name={inputParams.formName} />
        case MobileComponentKeys.PERSONNEL:
            return <MobilePersonnelReport
                {...inputParams}
                key={inputParams.name}
                name={inputParams.formName as `foremanReport.daysReport.${number}.actualTeam`}
            />
        case MobileComponentKeys.ADDITIONAL_SERVICES:
            return <MobileAdditionalServices
                key={inputParams.name}
                {...inputParams}
                name={inputParams.formName as 'foremanReport.additionalServices'}
            />
        default:
            return <></>
    }
}
