import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectOrderLoader } from '~/redux/selectors/orderSlice.selectors'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import { useAppDispatch } from '~/redux/store'

const SidebarDocumentButtons = () => {
    const { fileId } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const loader = useSelector(selectOrderLoader)

    return (
        <div className='orderSidebarBody'>
            <button
                className={'button'}
                onClick={() => {
                    dispatch(changeFormAction({ action: 'SAVE_FORM' }))
                }}
                disabled={loader}
            >
                Сохранить
            </button>
            {fileId && (
                <button
                    className={'button'}
                    onClick={() => {
                        dispatch(changeFormAction({ action: 'EDIT_DOCUMENT' }))
                    }}
                    disabled={loader}
                >
                    Редактировать документ
                </button>
            )}
            <button
                className={'button button_fill-none'}
                onClick={() => {
                    dispatch(changeFormAction({ action: 'RESET_FORM' }))
                }}
                disabled={loader}
            >
                Сбросить
            </button>
            <button
                className={'button button_bc-grey'}
                onClick={() => {
                    navigate(-1)
                }}
                disabled={loader}
            >
                Вернуться
            </button>
        </div>
    )
}

export default SidebarDocumentButtons
