import React, { useState } from 'react'
import { StompSessionProvider } from 'react-stomp-hooks'
import { fetchPath } from '~/API/lib/path/path'
import { User } from '~/API/userApi/user.types'
import { CommentInput } from '~/components/common/ReusingComponents/Comments/subComponents/CommentInput/CommentInput'
import { CommentList } from '~/components/common/ReusingComponents/Comments/subComponents/CommentList/CommentList'
import './Comments.scss'

interface CommentsProps {
    user: User | null,
}

const wsPath = fetchPath('ws')

const Comments = (props: CommentsProps) => {
    const {
        user,
    } = props

    const [isScrolled, setScrolled] = useState(false)

    const handleSetScrolled = (value: boolean) => {
        setScrolled(value)
    }

    return user && (
        <StompSessionProvider url={wsPath}>
            <div className='comments'>
                <CommentList
                    handleSetScrolled={handleSetScrolled}
                    isScrolled={isScrolled}
                    user={user}
                />
                {user.role !== 'FOREMAN' && (
                    <CommentInput
                        handleSetScrolled={handleSetScrolled}
                        user={user}
                    />
                )}
            </div>
        </StompSessionProvider>
    )
}

export default Comments
