import { Dayjs } from 'dayjs'
import { SalaryObject, SalaryReport, SalaryReportDay } from '~/API/salaryApi/salary.types'
import {
    ActiveDateRange,
    SalaryFiles,
    SalaryJobPositions,
    SalaryTableInfo,
    SalaryWorkers,
    SingleSalary,
} from '~/redux/slices/salarySlice/salarySlice.type'
import type { SalaryIssuedPersonnel } from '~/shared/templates/mainTemplates/salaryIssued'
import { countCalendarDays, fetchDate } from '~/shared/util/currentTime'
import { separateCityInAddress } from '~/shared/util/strings'

interface SalaryInfo {
    sum: number
    issuedSum: number
    notIssuedSum: number
    payedSum: number
    notPayedSum: number
    payedSingle: number
    payedCommon: number
}

export const changeDateRange = (jobPosition: SalaryJobPositions, dateRange: ActiveDateRange, operation: 'prev' | 'next'): ActiveDateRange => {
    switch (jobPosition) {
        case 'Клинер':
            if (operation === 'prev') {
                return {
                    start: dateRange.start.clone().subtract(1, 'week'),
                    end: dateRange.end.clone().subtract(1, 'week'),
                }
            } else {
                return {
                    start: dateRange.start.clone().add(1, 'week'),
                    end: dateRange.end.clone().add(1, 'week'),
                }
            }
        case 'Бригадир':
            let newDate
            if (dateRange.start.date() === 1) {
                newDate = {
                    start: dateRange.start.clone().date(16),
                    end: dateRange.end.clone().endOf('month'),
                }
                if (operation === 'prev') {
                    newDate = {
                        start: dateRange.start.clone().subtract(1, 'month').date(16),
                        end: dateRange.end.clone().subtract(1, 'month').endOf('month'),
                    }
                }
                return newDate
            } else {
                newDate = {
                    start: dateRange.start.clone().startOf('month'),
                    end: dateRange.end.clone().date(15).endOf('day'),
                }
                if (operation === 'next') {
                    newDate = {
                        start: dateRange.start.clone().add(1, 'month').startOf('month'),
                        end: dateRange.end.clone().add(1, 'month').date(15).endOf('day'),
                    }
                }
                return newDate
            }
        default:
            return dateRange
    }
}

export const getSingleSalaries = (salaryData: SalaryReport): SingleSalary[] => {
    return salaryData.data
        .filter((cleaning) => cleaning.salary?.id && cleaning.salary?.type === 'SINGLE')
        .map((day) => {
            return {
                address: day.address,
                cleaningId: day.salary!.orderId,
                cleaningStartDate: day.salary!.workingDate,
                id: day.salary!.id,
                payment: day.salary!.payment,
                personnelId: day.salary!.personnelId,
                payedFileId: day.salary!.payedFileId,
            }
        })
}

export const getInfoForTable = (salaryData: SalaryReport, activeDateRange: ActiveDateRange): SalaryTableInfo[] => {
    const workers = new Set([...salaryData.data.map((cleaning) => cleaning.personnelId)])
    return [...workers].map((worker) => {
        const period = [
            ...Array(countCalendarDays(activeDateRange.start, activeDateRange.end))
                .fill(1)
                .map((_, index) => fetchDate(activeDateRange.start).add(index, 'day')),
        ]
        const workerCleanings = salaryData.data.filter((cleaning) => worker === cleaning.personnelId)
        let counter = 1
        const cleaningsByDay = period.map((day) => {
            const dayCleaning = workerCleanings
                .filter((cleaning) => fetchDate(cleaning.date).date() === day.date())
                .map((cleaning) => ({ ...cleaning, date: day }))
            counter = Math.max(counter, dayCleaning.length)
            return dayCleaning.length > 0 ? dayCleaning : [{ date: day } as any] // makes type error down
        })

        const cleaningsByPersonnelRows = Array(counter)
            .fill(1)
            .map((_, i) => {
                return cleaningsByDay.map((day: SalaryReportDay[]) => {
                    return {
                        id: day[i]?.orderId,
                        salaryAmount: day[i]?.salaryAmount || '-',
                        addSalary: day[i]?.addSalaryAmount || '-',
                        petrolExpanses: day[i]?.petrolExpanses || '-',
                        rate: day[i]?.rate || '-',
                        qualityRating: day[i]?.qualityRating || '-',
                        serviceRating: day[i]?.serviceRating || '-',
                        salary: day[i]?.salary,
                        date: fetchDate(day[i]?.date).format('DD / dd'),
                        cleaningDate: day[i]?.date,
                        address: day[i]?.address,
                        comment: day[i]?.comment,
                        clientComment: day[i]?.clientComment,
                    }
                })
            })

        return {
            personnelId: workerCleanings[0].personnelId,
            name: workerCleanings[0].fullname,
            jobPosition: workerCleanings[0].jobPosition,
            sum: workerCleanings.reduce((sum: number, cleaning) => {
                return sum + cleaning.salaryAmount + cleaning.addSalaryAmount
            }, 0),
            cleanings: cleaningsByPersonnelRows,
        }
    })
}

export const getSalary = (salaryData: SalaryReport): SalaryObject[] => {
    return salaryData.data
        .filter((cleaning) => cleaning.salary?.id)
        .map((cleaning) => cleaning.salary) as SalaryObject[]
}

export const getWorkers = (salaryData: SalaryReport): SalaryWorkers[] => {
    const workers = new Set([...salaryData.data.map((cleaning) => cleaning.personnelId)])
    const workerCleaning = [...workers].map((worker) => {
        return salaryData.data.filter((cleaning) => worker === cleaning.personnelId)
    })
    return workerCleaning.map((cleanings) => {
        return {
            id: cleanings[0]?.personnelId,
            name: cleanings[0]?.fullname || 'Имя не найдено в системе',
            jobPosition: cleanings[0]?.jobPosition,
            cleanings: cleanings.map((day) => {
                return {
                    addSalary: day.addSalaryAmount,
                    cleaningDate: fetchDate(day?.date),
                    date: fetchDate(day?.date).format('DD / dd'), // date in "13 / пн" format
                    id: day.orderId || day.id,
                    petrolExpanses: day.petrolExpanses,
                    rate: day.rate,
                    salaryAmount: day.salaryAmount,
                    salary: day.salary,
                    address: day.address,
                    comment: day?.comment,
                    clientComment: day?.clientComment,
                    qualityRating: day?.qualityRating || '-',
                    serviceRating: day?.serviceRating || '-',
                }
            }),
        }
    })
}

export const getSalaryFiles = (salaryData: SalaryReport): SalaryFiles => {
    const issuedFiles = [...new Set(salaryData.data.map((cleaning) => cleaning?.salary?.issuedFileId))]
    const payedFiles = [...new Set(salaryData.data.map((cleaning) => cleaning?.salary?.payedFileId))]

    return ({
        issuedFiles: issuedFiles.filter((id): id is 'string' => !!id),
        payedFiles: payedFiles.filter((id): id is 'string' => !!id),
    })
}

export const getSalaryIssuedPersonnel = (salaryData: SalaryTableInfo[], personnelIdToIssued: string[]): SalaryIssuedPersonnel[] => {
    const salaryIssuedPersonnel = salaryData
        .filter((item) => personnelIdToIssued.includes(item.personnelId))
        .reduce((acc, item) => {
            const cleanings = item.cleanings.flat().filter((cleaning) => {
                const isSingle = cleaning.salary?.type === 'SINGLE'

                return cleaning?.id !== undefined && !isSingle
            })

            const objectsAndSum = cleanings.reduce((acc, cleaning, index) => {
                acc.objects = acc.objects + `${index !== 0 ? ', ' : ''}${(cleaning.cleaningDate as Dayjs).format('DD.MM')} ${separateCityInAddress(cleaning.address)}`
                acc.sum = acc.sum + (typeof cleaning.salaryAmount === 'number' ? cleaning.salaryAmount : 0) + (typeof cleaning.addSalary === 'number' ? cleaning.addSalary : 0)
                acc.note = acc.note + (cleaning.comment ? `${cleaning.comment}, ` : '')

                return acc
            }, { objects: '', sum: 0, note: '' })

            acc.push({
                name: item.name,
                shifts: cleanings.length,
                objects: objectsAndSum.objects,
                sum: objectsAndSum.sum.toString(),
                note: objectsAndSum.note.slice(0, -2),
            })

            return acc
        }, [] as SalaryIssuedPersonnel[]).filter((item) => item.shifts > 0)

    return salaryIssuedPersonnel
}

export const getSalaryInfo = (salary: SalaryReport): SalaryInfo => {
    if (salary.status === 'OK') {
        const sum = salary.data.reduce((accum: number, p) => p.salaryAmount + p.addSalaryAmount + accum, 0)
        const issuedSum = salary.data
            .filter((p) => p.salary?.issued)
            .reduce((accum: number, p) => p.salaryAmount + p.addSalaryAmount + accum, 0)
        const notIssuedSum = sum - issuedSum
        const payedSum = salary.data
            .filter((p) => p.salary?.payed)
            .reduce((accum: number, p) => p.salaryAmount + p.addSalaryAmount + accum, 0)
        const notPayedSum = sum - payedSum
        const payedSingle = salary.data
            .filter((p) => p.salary?.type === 'SINGLE')
            .reduce((accum: number, p) => p.salaryAmount + p.addSalaryAmount + accum, 0)
        const payedCommon = salary.data
            .filter((p) => p.salary?.type === 'STANDARD' && p.salary?.payed)
            .reduce((accum: number, p) => p.salaryAmount + p.addSalaryAmount + accum, 0)
        return ({
            sum,
            payedSum,
            notPayedSum,
            issuedSum,
            notIssuedSum,
            payedSingle,
            payedCommon,
        })
    } else {
        return ({
            sum: 0,
            issuedSum: 0,
            notIssuedSum: 0,
            payedSum: 0,
            notPayedSum: 0,
            payedSingle: 0,
            payedCommon: 0,
        })
    }
}
