import { StartingTeam } from '~/API/orderApi/types/starting.type'
import type { PersonnelResponse } from '~/API/personnelApi/personnel.types'
import { sortByAllJobPositions, sortByFullname } from '~/shared/util/filter'

export const getFilteredAndSortedPersonnel = (personnel: PersonnelResponse[], actualTeam: StartingTeam[]): PersonnelResponse[] => {
    return personnel.filter((person) => {
        if (actualTeam) {
            return !actualTeam.some((item) => item.personnelId === person.id)
        }
        return true
    }).sort(sortByFullname).sort(sortByAllJobPositions)
}
