function toLowerCaseSubstring(str: string, substr: string): string {
    const index = str.indexOf(substr)
    if (index !== -1) {
        const before = str.substring(0, index)
        const middle = str.substring(index, index + substr.length).toLowerCase()
        const after = str.substring(index + substr.length)
        return before + middle + after
    }
    return str
}

export const toLowerOOO = (str: string) => {
    return toLowerCaseSubstring(str, 'БЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ')
}
