import { useLazyFetchUserInfoQuery } from '~/API/userApi/user'
import useLocalStorage from '~/hooks/useLocalStorage'
import {
    CHANGE_USER_INFO,
    FETCH_USER_INFO_FAILED,
    FETCH_USER_INFO_REQUESTED,
    FETCH_USER_INFO_SUCCESS,
    logout,
} from '~/redux/slices/appSlice/appSlice'
import { useAppDispatch } from '~/redux/store'

export const useUpdateUser = () => {
    const [fetchUserInfo] = useLazyFetchUserInfoQuery()

    const [jwtToken, setJwtToken, removeJwtToken] = useLocalStorage({ key: 'jwtToken' })

    const dispatch = useAppDispatch()

    return () => {
        dispatch(FETCH_USER_INFO_REQUESTED())
        fetchUserInfo().unwrap()
            .then((userInfo) => {
                dispatch(CHANGE_USER_INFO({ ...userInfo, roleName: userInfo.role }))
                dispatch(FETCH_USER_INFO_SUCCESS())
            })
            .catch((error) => {
                removeJwtToken()
                dispatch(logout())
                dispatch(FETCH_USER_INFO_FAILED())
            })
    }
}
