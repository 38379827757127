import Avatar from '@mui/material/Avatar'
import clsx from 'clsx'
import { User } from '~/API/userApi/user.types'
import { fetchDate } from '~/shared/util/currentTime'
import './Comment.scss'

interface CommentProps {
    userInfo?: User
    date: Date
    message: string
    direction: 'right' | 'left'
}

const Comment = (props: CommentProps) => {
    const {
        userInfo,
        date,
        message,
        direction,
    } = props

    return (
        <div className='comment__container'>
            <div className={clsx('comment__header__container', `comment__header__container--${direction}`)}>
                <div className={clsx('comment__cloud__container')}>
                    <div className={clsx('comment', `comment--${direction}`)}>
                        <div className='comment__header'>
                            <div className='comment__info'>
                                <h4 className={clsx('comment__author', `comment__author--${direction}`)}>
                                    {userInfo?.fullname ?? 'Без имени'}
                                </h4>
                            </div>
                        </div>
                        <p className='comment__message'>{message}</p>
                    </div>
                    <span className='comment__date'>{fetchDate(date).format('HH:mm')}</span>
                </div>
                <Avatar src={userInfo?.photo ?? ''} />
            </div>
        </div>
    )
}

export default Comment
