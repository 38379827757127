import clsx from 'clsx'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FieldValues, UseFormClearErrors } from 'react-hook-form'
import { FaPhoneAlt } from 'react-icons/fa'
import { IoMdMail } from 'react-icons/io'
import { NumericFormat, PatternFormat } from 'react-number-format'
import '../CustomField.scss'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectUser } from '~/redux/selectors/appSlice.selectors'

export enum InputMask {
    NUMBER = 'number',
    NUMBER_WITH_SEPARATOR = 'numberWithSeparator',
    PERCENT = 'percent',
    COST = 'cost',
    PHONE_NUMBER = '+7 (###) ###-##-##',
    DATE = '##.##.####',
    CREDIT_CARD = '#### #### #### ####',
    FOUR = '####',
    SIX = '######',
    SIX_DASH = '###-###',
    A = 'a',
}

export enum CustomInputIcons {
    PHONE = 'phone',
    EMAIL = 'email',
}

export interface ICustomInput {
    title?: string
    value?: string | number
    href?: string
    name: string
    onChange?: (e: any) => void
    className?: string
    disabled?: boolean
    error?: any
    multiline?: boolean
    mask?: InputMask
    placeholder?: string
    maxLength?: number
    inputName?: string
    clearErrors?: UseFormClearErrors<FieldValues>
    rerender?: any
    innerClassName?: string
    icon?: CustomInputIcons
    decimalScale?: number
    displayType?: 'text' | 'input'
    renderText?: (formattedValue: string, otherProps: any) => React.ReactNode
}

const minRows = 1
const maxRows = 8

const CustomInput = (props: ICustomInput) => {
    const {
        title,
        value = '',
        name,
        onChange,
        className,
        disabled,
        error,
        multiline,
        mask,
        placeholder,
        maxLength,
        inputName,
        clearErrors,
        rerender,
        innerClassName,
        href,
        icon,
        decimalScale,
        displayType,
        renderText,
    } = props
    const [rows, setRows] = useState(1)
    const textarea = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        if (multiline && value && value !== '' && textarea.current) {
            handleRows(textarea.current)
        }
    }, [value, textarea, rerender])

    useEffect(() => {
        if (multiline && value && value !== '' && textarea.current) {
            textarea.current.scrollTop = 0
        }
    }, [rerender])

    const handleRows = (textarea: any) => {
        const previousRows = textarea.rows
        textarea.rows = minRows
        const lineHeight = ~~+window.getComputedStyle(textarea).getPropertyValue('line-height').replaceAll('px', '')

        const currentRows = ~~(textarea.scrollHeight / lineHeight)
        if (currentRows === previousRows) {
            textarea.rows = currentRows
        }
        if (currentRows >= maxRows) {
            textarea.rows = maxRows
            textarea.scrollTop = textarea.scrollHeight
        }
        setRows(currentRows < maxRows ? currentRows : maxRows)
    }

    const handleChange = (e: any) => {
        onChange && onChange(e)
        inputName && clearErrors && clearErrors(inputName)
    }

    const handledValue = useMemo(() => {
        if (typeof value === 'string') {
            return value.trimStart()
        }
        return value
    }, [value])

    return (
        <div className={clsx('customField', className, { error, disabled })}>
            {title && <span>{title}</span>}
            <div className={clsx('customField__input', className, { 'textarea': multiline })}>
                {multiline && (
                    <textarea
                        ref={textarea}
                        value={handledValue ?? ''}
                        onChange={(e) => {
                            handleChange(e)
                        }}
                        name={name}
                        disabled={disabled}
                        rows={rows}
                        placeholder={placeholder || ''}
                        className={innerClassName}
                    />
                )}
                {(mask === undefined) && !multiline && (
                    <input
                        type='text'
                        value={typeof value === 'string' ? value.trimStart() : value ?? ''}
                        name={name}
                        disabled={disabled}
                        autoComplete='off'
                        placeholder={placeholder || ''}
                        onChange={(e) => {
                            handleChange(e)
                        }}
                        maxLength={maxLength}
                        className={innerClassName}
                    />
                )}
                {mask === InputMask.NUMBER && (
                    <NumericFormat
                        name={name}
                        displayType={displayType}
                        value={value ?? ''}
                        disabled={disabled}
                        thousandSeparator={false}
                        decimalSeparator='.'
                        allowedDecimalSeparators={[',']}
                        decimalScale={decimalScale}
                        allowNegative={false}
                        valueIsNumericString
                        onValueChange={(e) => {
                            handleChange(e.value)
                        }}
                        renderText={renderText} // renderText works only with displayType === 'text'
                        autoComplete='off'
                    />)}
                {mask === InputMask.NUMBER_WITH_SEPARATOR && (
                    <NumericFormat
                        name={name}
                        displayType={displayType}
                        value={value ?? ''}
                        disabled={disabled}
                        thousandSeparator={' '}
                        decimalSeparator='.'
                        allowedDecimalSeparators={[',']}
                        decimalScale={decimalScale}
                        allowNegative={false}
                        valueIsNumericString
                        onValueChange={(e) => {
                            handleChange(e.value)
                        }}
                        renderText={renderText} // renderText works only with displayType === 'text'
                        autoComplete='off'
                    />)}
                {(mask === InputMask.A) && !multiline && href && (
                    <Link
                        to={href}
                        target={'_blank'}
                    >
                        {value}
                    </Link>
                )}
                {mask === InputMask.PHONE_NUMBER &&
                    (!disabled ? (
                        <PatternFormat
                            name={name}
                            value={value ?? ''}
                            format={mask}
                            onChange={(e: any) => {
                                handleChange(e)
                            }}
                            disabled={disabled}
                            autoComplete='off'
                            className={innerClassName}
                            mask='_'
                        />
                    ) : (
                        <input
                            type={mask || 'text'}
                            value={value ?? ''}
                            name={name}
                            disabled={disabled}
                            autoComplete='off'
                            placeholder={placeholder || ''}
                            onChange={(e) => {
                                handleChange(e)
                            }}
                            maxLength={maxLength}
                            className={innerClassName}
                        />
                    ))}
                {(mask === InputMask.FOUR ||
                    mask === InputMask.SIX ||
                    mask === InputMask.SIX_DASH ||
                    mask === InputMask.DATE ||
                    mask === InputMask.CREDIT_CARD) && (
                        <PatternFormat
                            name={name}
                            value={value ?? ''}
                            format={mask}
                            onChange={(e: any) => {
                                handleChange(e)
                            }}
                            disabled={disabled}
                            autoComplete='off'
                            mask='_'
                        />
                )}
                {mask === InputMask.COST && (
                    <NumericFormat
                        name={name}
                        displayType={displayType}
                        value={value ?? ''}
                        disabled={disabled}
                        thousandSeparator={' '}
                        decimalSeparator='.'
                        suffix={' ₽'}
                        allowedDecimalSeparators={[',']}
                        decimalScale={decimalScale}
                        allowNegative={false}
                        valueIsNumericString
                        onValueChange={(e) => {
                            handleChange(e.value)
                        }}
                        renderText={renderText} // renderText works only with displayType === 'text'
                        autoComplete='off'
                    />
                )}
                {mask === InputMask.PERCENT && (
                    <NumericFormat
                        name={name}
                        value={value ?? ''}
                        allowNegative={false}
                        isAllowed={(values) => {
                            const { floatValue = 0 } = values
                            return floatValue >= 0 && floatValue <= 100
                        }}
                        valueIsNumericString={true}
                        suffix={mask === InputMask.PERCENT ? ' %' : ''}
                        onValueChange={(e: any) => {
                            handleChange(e.value)
                        }}
                        disabled={disabled}
                        autoComplete='off'
                    />
                )}
                {icon === CustomInputIcons.PHONE && (
                    <a
                        href={`tel:${value}`}
                        className='customField__icon'
                    >
                        <FaPhoneAlt className='customField__icon--phone' />
                    </a>
                )}
                {icon === CustomInputIcons.EMAIL && (
                    <a
                        href={`mailto:${value}`}
                        className='customField__icon'
                    >
                        <IoMdMail className='customField__icon--email' />
                    </a>
                )}

            </div>
        </div>
    )
}

export default CustomInput
