import { Dayjs } from 'dayjs'
import React from 'react'
import { Control, FieldError, FieldValues, RegisterOptions, UseFormSetValue } from 'react-hook-form'
import { InputTypeEnum } from './enum/InputTypeEnum'
import { Division, Role } from '~/API/userApi/user.types'
import { ReportTableType } from '~/components/common/formConstructor/formCommonComponents/ReportTable/ReportTable'
import { CustomDateInputModes } from '~/components/common/inputs/CustomDateInput/CustomDateInput'
import { ActionIconsEnum } from '~/components/common/inputs/CustomFormActionInput/CustomFormActionInput'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'
import { TabsTableMods } from '~/components/common/ReusingComponents/TabsTable/TabsTable'
import { TabsTableHeaderSection } from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import { isSectionDisabledParams } from '~/hooks/useFormConstructor/lib/additionalHOC/withSectionBody'
import { IRulesComments } from '~/types/types.order'
import { ISTInputs } from '~/types/types.services'

/**
 * основные типы, которые используются в хуке
 * */

export type Data = {
    title: string,
    name: string,
    inputs: Array<InputParams[]>,
    documentInputs: DocumentInputType[],
    isActive: boolean,
    isBlock: boolean,
    isMultiple: boolean,
    isDisabled?: isSectionDisabledParams
    divisionDependence?: Division[]
    roles: Role[]
}

export type InputType = {
    disabled: boolean
    title: string
    name: string
    className: string
    type: string
    role: Role[]
    rules: (value: any) => any // todo исправить на нормальный вариант
    // todo добавить placeholder в настройки инпута
}
export type DocumentInputType = {
    disabled: boolean
    name: string
    title: string
    type: string
    button: string
    fileType: string
    fileRules: {
        write: boolean
        read: boolean
    }
}

/**
 * Дополнительные типы, которые используются в доп. функциях
 * или пробрасываются куда-то дальше
 * */

export interface InputParams {
    className: string,
    items: InputParamsItem<any>[] | Record<string, InputParamsItem<any>[]>
    name: string, // input name
    formName: string, // full form input name
    title: string,
    type: InputTypeEnum,
    role: Role[],
    required?: boolean | undefined
    validationType?: string
    pattern?: string
    userDependence?: string // id юзера который будет видеть инпут

    options?: { // todo по идее это поле нужно только для HOC, нужно ли нам загрязнять обычные компоненты?
        watchInputs: string[]
        itemRules: Array<{
            rule: string,
            itemsKey: string,
            dependencyInputs: string[]
        }>
        inputRules: {
            watchRuleInputs: string[],
            inputsValues: string[] | string[][]
            booleanInputs?: boolean[]
            mode: 'boolean' | 'OR' | 'NOT' | 'MANY' | 'ARRAY' | 'ARRAY_NOT' | 'ARRAY_MANY_NOT' | 'ARRAY_MANY'
        }
        mode: 'boolean'
    }

    submitButtonHandler?: (...arg: any) => void // это функция, которая есть только у action инпута

    value: any, // todo split into different value string and DaDataSuggestion<DaDataParty> и прописать нормальные типы

    onChange: (...args: any) => void,

    error?: FieldError,
    division: Division,
    values: any, // todo make not any
    singleDate: boolean,
    cleaningDate: boolean,
    id: string,
    sections: InputSectionItem[],
    placeholderIn: string,
    placeholderOut: string,
    buttonCaption: string,
    inputs: ISTInputs[], // wtf is this
    control: Control,
    setValue: UseFormSetValue<FieldValues>,
    errors: any[],
    inputNames: any, // todo make not any
    table1: ReportTableType,
    table2: ReportTableType,
    disableMode: boolean, // todo сделать одно поле
    disabled: boolean, // todo сделать одно поле
    template: InputTemplateType,
    flexDirection?: 'row' | 'column',
    toolbar?: any,
    checkList?: any,
    custom?: any,
    fileId?: string,
    fileType?: string,
    fileRules?: any,
    mode?: CustomDateInputModes,
    allowedFileTypes?: AllowedFileTypes[],
    onClick?: (...args: any) => void // CustomButton
    theme?: any
    usingData?: string
    defaultData?: string
    commentType?: keyof IRulesComments
    defaultValue?: string

    rules: RegisterOptions,

    /** TabsTable */
    withHideButton: boolean
    withAddButton: boolean
    withToolbar: boolean
    headerSections: TabsTableHeaderSection[]
    footer?: React.ReactElement
    customHeader?: React.ReactElement
    customTools?: React.ReactElement
    tabsTableMode?: TabsTableMods
    tabIndex?: number

    /** Mobile components */
    mobileComponent?: MobileComponentKeys

    /** Action */
    roles?: Role[]

    /** ClientInfo */
    clientType?: ClientInfoEnum
}

export type AllowedFileTypes = 'application' | 'audio' | 'image' | 'video' | 'other'

export type InputParamsItem<T> = {
    value: string | Date | Dayjs | null | boolean
    text: string | number
    action?: T
    icon?: ActionIconsEnum
    disabled?: boolean
    withAdditionalPopup?: {
        type: 'custom' | 'clientRepeat',
        title?: string
    }
}

export interface InputSectionItem {
    name: string,
    title: string,
}

export interface InputTemplateType {
    [key: string]: {
        single?: {
            caption: string,
            values: {
                name: string,
                note: string,
            }[]
        }
        both?: {
            caption: string,
            values: {
                name: string,
                note: string,
            }[]
        }
        name?: string,
        note?: string,
    }
}

export enum MobileComponentKeys {
    SERVICES_VERIFICATION = 'servicesVerification',
    TOOLS_REPORT = 'toolsReport',
    FOREMAN_CHECK_LIST = 'foremanCheckList',
    PERSONNEL = 'personnel',
    ADDITIONAL_SERVICES = 'additionalServices',
}
