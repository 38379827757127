import { FC, memo } from 'react'
import ChecklistStatusBar
    from '~/components/common/ReusingComponents/TabsTable/hocs/withForemanReportChecklist/subComponents/ChecklistStatusBar'
import { TabsTableProps } from '~/components/common/ReusingComponents/TabsTable/TabsTable'

const withForemanReportChecklist = (TabsTable: FC<TabsTableProps>): FC<TabsTableProps> => {
    const WrappedComponent = (props: TabsTableProps) => {
        return <TabsTable
            {...props}
            customButtonNeighbor={<ChecklistStatusBar name={props.name} title='Состояние выполнения работ' />}
        />
    }

    return memo(WrappedComponent)
}

export default withForemanReportChecklist


