import { fetchDate } from './currentTime'


/*
* TODO тут нужно везде прописать нормальные типы
* */

const filterCleaningsBySearchString = (events: any, searchString: string) => {
    if (!searchString) return events
    return events.filter((event: any) => {
        const lowerCaseSearch = searchString.toLowerCase()
        const month = fetchDate(event?.cleaningDate?.startDate).format('MMMM')
        const cleaningDateStart = event?.cleaningDate?.startDate ?
            fetchDate(event?.cleaningDate?.startDate).format('LLLL').toLowerCase() :
            ''
        const cleaningDateEnd = event?.cleaningDate?.endDate ?
            fetchDate(event?.cleaningDate?.endDate).format('LT').toLowerCase() :
            ''
        const address = event?.address ? event?.address.toLowerCase() : ''
        const clientFullName = event?.clientFullname ? event?.clientFullname.toLowerCase() : ''
        const clientPhone = event?.clientPhone ? event?.clientPhone.toLowerCase() : ''
        return (
            address.includes(lowerCaseSearch) ||
            month.includes(lowerCaseSearch) ||
            clientFullName.includes(lowerCaseSearch) ||
            clientPhone.includes(lowerCaseSearch) ||
            cleaningDateEnd.includes(lowerCaseSearch) ||
            cleaningDateStart.includes(lowerCaseSearch)
        )
    })
}

export const sortByFullname = (a: any, b: any) => {
    return a.fullname.localeCompare(b.fullname)
}

export const sortByString = (a: any, b: any, sortString: string) => {
    return a[sortString].localeCompare(b[sortString])
}

export const sortByJobPosition = (a: any, b: any) => {
    if (a.jobPosition.includes('Бригадир') && b.jobPosition.includes('Бригадир')) {
        return 0
    }
    if (a.jobPosition.includes('Бригадир')) {
        return 1
    }
    if (b.jobPosition.includes('Бригадир')) {
        return -1
    }
    return 1
}

export const sortByAllJobPositions = (a: any, b: any) => {
    const jobPositionsOrder: any = {
        'Клинер стажер': 6,
        'Вызывной клинер': 5,
        'Клинер': 4,
        'Клинер Экперт': 3,
        'Менеджер': 2,
        'Бригадир': 1,
    }

    const positionA = jobPositionsOrder[a.jobPosition] || Infinity
    const positionB = jobPositionsOrder[b.jobPosition] || Infinity

    return positionA - positionB || a.fullname.localeCompare(b.fullname)
}


export const sortByRole = (a: any, b: any): number => {
    if (a.role > b.role) {
        return 1
    }
    if (a.role < b.role) {
        return -1
    }
    return 0
}
