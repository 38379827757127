import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { FieldValues, UseFormClearErrors } from 'react-hook-form'
import '../../inputs/CustomField.scss'
import { setFormComment } from '~/redux/slices/appSlice/appSlice'
import { useAppDispatch } from '~/redux/store'
import { IRulesComments } from '~/types/types.order'

export interface CommentType {
    userId: string,
    comment: string,
    date: Date | Dayjs,
    type: keyof IRulesComments,
}

interface CustomCommentInputProps {
    title?: string
    value?: string
    href?: string
    name: string
    onChange?: (e: any) => void
    className?: string
    disabled?: boolean
    error?: any
    placeholder?: string
    inputName?: string
    clearErrors?: UseFormClearErrors<FieldValues>
    innerClassName?: string
    commentType: keyof IRulesComments
}

const minRows = 1
const maxRows = 8

export const CustomCommentInput = (props: CustomCommentInputProps) => {
    const {
        className,
        title,
        value,
        name,
        onChange,
        disabled,
        error,
        placeholder,
        inputName,
        clearErrors,
        innerClassName,
        commentType,
    } = props
    const dispatch = useAppDispatch()

    const [rows, setRows] = useState(minRows)

    const textarea = useRef<HTMLTextAreaElement>(null)

    const handleRows = (textarea: HTMLTextAreaElement) => {
        const previousRows = textarea.rows
        textarea.rows = minRows
        const lineHeight = Math.floor(+window.getComputedStyle(textarea).getPropertyValue('line-height').replaceAll('px', ''))

        const currentRows = Math.floor(textarea.scrollHeight / lineHeight)

        if (currentRows === previousRows) {
            textarea.rows = currentRows
        }
        if (currentRows >= maxRows) {
            textarea.rows = maxRows
            textarea.scrollTop = textarea.scrollHeight
        }
        setRows(currentRows < maxRows ? currentRows : maxRows)
    }

    const handleChange = (e: any) => {
        onChange && onChange(e.target.value)
    }

    useEffect(() => {
        if (value && value !== '' && textarea.current) {
            handleRows(textarea.current)
        }
    }, [value, textarea])

    useEffect(() => {
        dispatch(setFormComment(commentType))

        if (value && value !== '' && textarea.current) {
            textarea.current.scrollTop = 0
        }
    }, [])

    return (
        <div className={clsx('customField', className, { error, disabled })}>
            <span>{title}</span>
            <div className={clsx('customField__input', 'textarea', className)}>
                <textarea
                    ref={textarea}
                    value={value}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    name={name}
                    disabled={disabled}
                    rows={rows}
                    placeholder={placeholder || ''}
                    className={innerClassName}
                />
            </div>
        </div>
    )
}
