import { memo, useCallback, useState } from 'react'
import { FaClipboardQuestion } from 'react-icons/fa6'
import { CustomPopup } from '~/components/common/ReusingComponents/CustomPopup/CustomPopup'

import './QualityReminder.scss'

const QualityReminderPopup = memo(function QualityReminderPopup() {
    return (
        <div className='qualityReminder__popup'>
            <p>Качество работ и клиентский сервис 2 основных показателя на которых строится взаимодействие нашей
                Компании с
                Клиентом при оказании услуг. Наша основная задача оставить Клиента довольным с желанием пользоваться
                нашими
                услугами в будущем и рекомендовать нас другим.
            </p>
            <p><strong>Качество работ</strong> - это показатель профессионализма (знание технологий клининга, алгоритмов
                компании, процесса
                работ и т.п.) бригады при оказании услуг на объекте.</p>
            <p><strong>Клиентский сервис</strong> - это показатель поддержка клиента на каждом этапе покупки (от
                обращения/заявки до контроля
                качества). За
                клиентский сервис в компании отвечает каждый сотрудник.</p>
            <div style={{ overflow: 'auto' }}>
                <table cellPadding='10' cellSpacing='0' className='qualityReminder__table'>
                    <thead>
                        <tr className='qualityReminder__table__row--header'>
                            <th className='qualityReminder__table__cell qualityReminder__table__cell--header'>Оценка</th>
                            <th className='qualityReminder__table__cell qualityReminder__table__cell--header'>Качество</th>
                            <th className='qualityReminder__table__cell qualityReminder__table__cell--header'>Клиентский
                                сервис
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='qualityReminder__table__row'>
                            <td className='qualityReminder__table__cell qualityReminder__table__cell--center'>5<br />(отлично)
                            </td>
                            <td className='qualityReminder__table__cell'>Все отлично, очень понравилось, обращусь еще/буду
                                рекомендовать.
                            </td>
                            <td className='qualityReminder__table__cell'>Сотрудники молоды, вежливы и опрятны, нареканий
                                нет, обращусь еще/ буду рекомендовать.
                            </td>
                        </tr>
                        <tr className='qualityReminder__table__row'>
                            <td className='qualityReminder__table__cell qualityReminder__table__cell--center'>4<br />(хорошо)
                            </td>
                            <td className='qualityReminder__table__cell'>В целом хорошо, но... (мелочи/без конкретики/сухо
                                «все ок»).
                            </td>
                            <td className='qualityReminder__table__cell'>В целом хорошо, но... (мелочи/без конкретики/сухо
                                «все ок»).
                            </td>
                        </tr>
                        <tr className='qualityReminder__table__row'>
                            <td className='qualityReminder__table__cell qualityReminder__table__cell--center'>3<br />(удовлетворительно)
                            </td>
                            <td className='qualityReminder__table__cell'>Что-то не по технологической карте (предоставляем
                                скидку 10%).
                            </td>
                            <td className='qualityReminder__table__cell'>Не отзвонились за час до выезда/ опоздание/
                                отсутствие формы/ не провели осмотр перед началом работ.
                            </td>
                        </tr>
                        <tr className='qualityReminder__table__row'>
                            <td className='qualityReminder__table__cell qualityReminder__table__cell--center'>2<br />(плохо)
                            </td>
                            <td className='qualityReminder__table__cell'>Качество уборки ожидалось лучше, много недочетов,
                                труднодоступные места не убраны (предоставляем скидку 15%).
                            </td>
                            <td className='qualityReminder__table__cell'>Трогали личные вещи, которые просили не трогать
                                и/или не исполнили заявленные обещания (парогенератор, инвентарь, пожелания и пр.).
                            </td>
                        </tr>
                        <tr className='qualityReminder__table__row'>
                            <td className='qualityReminder__table__cell qualityReminder__table__cell--center'>1<br />(очень
                                плохо)
                            </td>
                            <td className='qualityReminder__table__cell'>Категорически не устраивает качество уборки, клиент
                                возмущен (просим фото, согласовываем переделку).
                            </td>
                            <td className='qualityReminder__table__cell'>Бригадир не сдал работу. Сотрудники ругались между
                                собой или с бригадиром.
                            </td>
                        </tr>
                        <tr className='qualityReminder__table__row'>
                            <td className='qualityReminder__table__cell qualityReminder__table__cell--center'>0<br />(ужас)
                            </td>
                            <td className='qualityReminder__table__cell'>Ужас! Вообще ничего не убрали и/или испортили
                                имущество (возврат ДС и/или возмещение ущерба сумма ущерба выше 3000).
                            </td>
                            <td className='qualityReminder__table__cell'>Сотрудники конфликтовали с клиентом.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
})

const QualityReminder = () => {
    const [isPopup, setPopup] = useState(false)

    const handleClosePopup = useCallback(() => {
        setPopup(false)
    }, [])

    return (
        <>
            <div className='qualityReminder__wrapper'>
                <button
                    type='button'
                    className='qualityReminder'
                    onClick={() => setPopup(true)}
                >
                    <FaClipboardQuestion className='qualityReminder__icon' />
                </button>
            </div>
            {isPopup && (
                <CustomPopup
                    onClose={handleClosePopup}
                    title={{ title: 'Оценка качества работ и клиентского сервиса', className: 'qualityReminder__popup__header' }}
                    className='client-info__popup'
                    body={{
                        data: [<QualityReminderPopup key={'client-info__popup'} />],
                    }}
                    footer={[]}
                />
            )}
        </>
    )
}

export default QualityReminder
