import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchOrderQuery } from '~/API/orderApi/order'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { selectSmetaHandleSubmit } from '~/redux/selectors/smetaSlise.selectors'

const SmetaSidebarButtons = () => {
    const { id: orderId } = useParams()
    const navigate = useNavigate()
    const handleSubmit = useSelector(selectSmetaHandleSubmit)

    const {
        data: order,
    } = useFetchOrderQuery(orderId!)

    return (
        <div className='sidebar__buttons'>
            <CustomButton
                className='sidebar__buttonsItem'
                onClick={() => handleSubmit()}
                disabled={!order || order.stageName !== 'WORKING_PLAN_APPROVING'}
            >
                Сохранить Смету
            </CustomButton>
            <CustomButton
                className='sidebar__buttonsItem sidebar__buttonsItem--secondary'
                onClick={() => navigate(`/order/${orderId}`)}
            >
                Вернуться к Запуск уборки
            </CustomButton>
        </div>
    )
}

export default SmetaSidebarButtons
