import { UseFormClearErrors, UseFormGetValues, UseFormHandleSubmit, UseFormReset } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import { useLazyFetchUserByIdQuery } from '~/API/userApi/user'
import { selectFormAction, selectUser } from '~/redux/selectors/appSlice.selectors'
import { selectActiveOrder, selectDivision } from '~/redux/selectors/orderSlice.selectors'
import { FormActionEnum } from '~/redux/slices/appSlice/appSlice.types'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import { copyOrderText, resetForm } from '~/shared/order/orderManager/orderManager'
import { defineStageAction } from '~/shared/order/orderUtil'
import { STANDARD_ORDER } from '~/shared/util/templates'

interface UseHandleFormActionParams {
    getValues: UseFormGetValues<OrderInterface>
    reset: UseFormReset<OrderInterface>
    clearErrors: UseFormClearErrors<OrderInterface>
    id: string
    isFormValid: boolean
    handleButtonSubmitClick: (isFormValid: boolean, formValues: any, stageName?: FormActionEnum) => void
    handleSubmit: UseFormHandleSubmit<Record<string, any>, undefined>
}

export const useHandleFormAction = (params: UseHandleFormActionParams) => {
    const {
        getValues,
        reset,
        clearErrors,
        id,
        isFormValid,
        handleButtonSubmitClick,
        handleSubmit,
    } = params
    const dispatch = useAppDispatch()

    const [fetchUserById] = useLazyFetchUserByIdQuery()

    const user = useSelector(selectUser)
    const activeOrder = useSelector(selectActiveOrder)
    const division = useSelector(selectDivision)
    const { action } = useSelector(selectFormAction)

    return () => {
        if (activeOrder) {
            switch (action) {
                case FormActionEnum.COPY_TEXT:
                    try {
                        user && copyOrderText(getValues(), user, fetchUserById)
                        dispatch(SHOW_NOTICE({ type: 'success', message: 'Заявка скопирована в буфер!' }))
                    } catch (error) {
                        console.error(error)
                        dispatch(SHOW_NOTICE({ type: 'warning', message: 'Не удалось скопировать заявку' }))
                    }
                    return

                case FormActionEnum.RESET_FORM:
                    resetForm(activeOrder, reset, clearErrors)
                    return

                case FormActionEnum.ADMIN_RESET_FORM:
                    // @ts-ignore
                    reset({ id, ...STANDARD_ORDER(division, user!.id) })
                    return

                case FormActionEnum.DELETE_ORDER:
                case FormActionEnum.EDIT_DOCUMENT:
                case FormActionEnum.CREATE_DOCUMENT:
                case FormActionEnum.NONE:
                case FormActionEnum.HANDLE_SUBMIT:
                case FormActionEnum.UPDATE_ORDER:
                case FormActionEnum.WORKING_PLAN_APPROVING:
                    return

                default:
                    if (defineStageAction(action) === FormActionEnum.HANDLE_SUBMIT) {
                        handleSubmit(() => handleButtonSubmitClick(isFormValid, getValues()))()
                    } else {
                        handleButtonSubmitClick(true, getValues())
                    }
            }
        }
    }
}
