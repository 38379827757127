import { FetchClientServicesParams } from '~/API/clientApi/client.types'
import { modifyRequestBody } from '~/API/lib/normilizeRequestBody'
import { fetchPath } from '~/API/lib/path/path'
import { counterpartyInterceptor } from '~/API/orderApi/interceptors'
import {
    FetchAllOrderByCleaningDateParams,
    GetArchiveData,
    GetArchiveResponse,
    GetOrderReportResponse,
    OrderInterface,
    ShortOrder,
    UploadMonthStatisticParams,
} from '~/API/orderApi/types/order.types'
import { Message } from '~/hooks/useChat/useChat.types'
import { Cleaning } from '~/redux/slices/orderSlice/types/orderSlice.type'
import { rtkApi } from '~/shared/api/rtkApi'
import { getGlobalDivision } from '~/shared/util/localStorage'

const path = fetchPath('order')
const chatPath = fetchPath('chat')

const orderApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAllOrderByCleaningDate: build.query<Cleaning[], FetchAllOrderByCleaningDateParams>({
            query: ({ startDate, endDate, division }) => ({
                method: 'get',
                url: path + '/active/short-description-by-date',
                params: { startDate, endDate, division: division || getGlobalDivision() },
            }),
            providesTags: ['Order'],
        }),
        fetchAllOrderByActionDate: build.query<ShortOrder[], FetchAllOrderByCleaningDateParams>({
            query: ({ startDate, endDate, division }) => ({
                method: 'get',
                url: path + '/active/short-description-by-action-date',
                params: { startDate, endDate, division: division || getGlobalDivision() },
            }),
            providesTags: ['Order'],
        }),
        getOrderReport: build.query<GetOrderReportResponse, string>({
            query: (orderId) => ({
                method: 'get',
                url: path + '/report',
                params: { orderId },
            }),
            providesTags: ['Order'],
        }),
        fetchOrder: build.query<OrderInterface, string>({
            query: (id) => ({
                method: 'get',
                url: path + '/' + id,
            }),
            providesTags: ['Order'],
        }),
        fetchAllOrder: build.query<any, { startDate: Date | string, endDate: Date | string }>({
            query: ({ startDate, endDate }) => ({
                method: 'get',
                url: path + '/all',
                params: { startDate, endDate },
            }),
            providesTags: ['Order'],
        }),
        fetchCleaningWorkers: build.query<any, { startDate: Date | string, endDate: Date | string }>({
            query: ({ startDate, endDate }) => ({
                method: 'get',
                url: path + '/active/cleaning-workers-by-date',
                params: { startDate, endDate, division: getGlobalDivision() },
            }),
            providesTags: ['Order'],
        }),
        fetchClientOrder: build.query<any, FetchClientServicesParams>({
            query: ({ clientId, address }) => ({
                method: 'get',
                url: path,
                params: { clientId, address },
            }),
            providesTags: ['Order'],
        }),
        fetchMessages: build.query<Message[], string>({
            query: (id: string) => ({
                method: 'get',
                url: chatPath + '/' + id,
            }),
        }),
        createOrder: build.mutation<any, string>({
            query: (orderCreator) => ({
                method: 'POST',
                url: path + '/create',
                params: { businessType: getGlobalDivision(), orderCreator },
            }),
            invalidatesTags: ['Order'],
        }),

        updateOrder: build.mutation<any, any>({
            query: (order) => {
                const modifiedOrder = modifyRequestBody(order, [counterpartyInterceptor])
                return {
                    method: 'POST',
                    url: path + '/update',
                    body: modifiedOrder,
                }
            },
            invalidatesTags: ['Order'],
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedOrder } = await queryFulfilled
                    const patchResult = dispatch(
                        orderApi.util.updateQueryData('fetchOrder', id, (draft) => {
                            Object.assign(draft, updatedOrder)
                        }),
                    )
                } catch {
                }
            },
        }),
        saveOrder: build.mutation<any, any>({
            query: (order) => {
                const modifiedOrder = modifyRequestBody(order, [counterpartyInterceptor])
                return {
                    method: 'POST',
                    url: path + '/save',
                    body: modifiedOrder,
                }
            },
            invalidatesTags: ['Order'],
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedOrder } = await queryFulfilled
                    const patchResult = dispatch(
                        orderApi.util.updateQueryData('fetchOrder', id, (draft) => {
                            Object.assign(draft, updatedOrder)
                        }),
                    )
                } catch {
                }
            },
        }),
        uploadMonthStatistic: build.mutation<any, UploadMonthStatisticParams>({
            query: ({ title, startDate, endDate }) => ({
                method: 'POST',
                url: path + '/upload-month-statistic',
                body: { title, startDate, endDate, division: getGlobalDivision() },
            }),
            invalidatesTags: ['Order'],
        }),
        uploadCleaningForYear: build.mutation<any, string>({
            query: (division) => ({
                method: 'POST',
                url: path + '/handleupload',
                params: { division },
            }),
            invalidatesTags: ['Order'],
        }),
        restoreOrderFormArchive: build.mutation<any, { id: string, reason: string }>({
            query: ({ id, reason }) => ({
                method: 'POST',
                url: path + '/restore',
                params: { orderId: id, reason },
            }),
            invalidatesTags: ['Order'],
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedOrder } = await queryFulfilled
                    const patchResult = dispatch(
                        orderApi.util.updateQueryData('fetchOrder', id, (draft) => {
                            Object.assign(draft, updatedOrder)
                        }),
                    )
                } catch {
                }
            },
        }),
        updateBitrixDeal: build.mutation<any, string>({
            query: (id) => ({
                method: 'POST',
                url: path + '/bitrix/update-deal',
                params: { id },
            }),
            invalidatesTags: ['Order'],
        }),
        fetchOrdersPage: build.mutation<GetArchiveResponse, GetArchiveData>({
            query: ({
                archived,
                pageSize,
                search,
                businessType,
                page,
                stageGroup,
                orderStatus,
                year,
                sortBy,
            }) => ({
                method: 'POST',
                url: path,
                body: {
                    archived,
                    search,
                    businessType,
                    stageGroup,
                    orderStatus,
                    year,
                },
                params: {
                    size: pageSize,
                    page,
                    sort: sortBy.path ? `${sortBy.path},${sortBy.order}` : null,
                },
            }),
        }),
    }),
})

export const {
    useFetchOrderQuery,
    useGetOrderReportQuery,
    useLazyFetchClientOrderQuery,
    useLazyFetchAllOrderByCleaningDateQuery,
    useLazyFetchAllOrderByActionDateQuery,
    useLazyGetOrderReportQuery,
    useLazyFetchOrderQuery,
    useLazyFetchAllOrderQuery,
    useFetchMessagesQuery,
    useLazyFetchCleaningWorkersQuery,
    useLazyFetchMessagesQuery,
    useCreateOrderMutation,
    useUpdateOrderMutation,
    useSaveOrderMutation,
    useUploadMonthStatisticMutation,
    useUploadCleaningForYearMutation,
    useRestoreOrderFormArchiveMutation,
    useUpdateBitrixDealMutation,
    useFetchOrdersPageMutation,
} = orderApi
