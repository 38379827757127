import { instance } from '~/API/lib/axios/axios'
import { fetchPath } from '~/API/lib/path/path'
import { FetchScheduleParams, UpdateScheduleParams } from '~/API/scheduleApi/schedule.types'
import { Division } from '~/API/userApi/user.types'
import { ScheduleObj } from '~/redux/slices/orderSlice/types/orderSlice.type'
import { rtkApi } from '~/shared/api/rtkApi'

const path = fetchPath('schedule')

const scheduleApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchSchedule: build.query<ScheduleObj[], FetchScheduleParams>({
            query: ({
                startDate,
                endDate,
                division,
            }) => ({
                method: 'GET',
                url: path,
                params: {
                    startDate: JSON.parse(JSON.stringify(startDate)),
                    endDate: JSON.parse(JSON.stringify(endDate)),
                    division,
                },
            }),
            providesTags: ['Schedule'],
        }),
        updateSchedule: build.mutation<ScheduleObj[], UpdateScheduleParams>({
            query: ({
                schedules,
            }) => ({
                method: 'POST',
                url: path + '/update',
                body: schedules,
            }),
            invalidatesTags: ['Schedule'],
        }),
    }),
})

export const {
    useLazyFetchScheduleQuery,
    useFetchScheduleQuery,
    useUpdateScheduleMutation,
} = scheduleApi

export const fetchSchedule = async (startDate: Date, endDate: Date, division?: Division): Promise<ScheduleObj[]> =>
    await instance({
        method: 'get',
        url: path,
        params: {
            startDate,
            endDate,
            division,
        },
    }).then((response) => {
        return response.data
    })

// export const updateSchedule = async (schedules: any) =>
//     await instance({
//         method: 'post',
//         url: path + 'update',
//         data: schedules,
//     }).then((response) => {
//         return response.data
//     })
