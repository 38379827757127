import { KpoFormData } from '~/components/pages/Kpo/kpo.types'
import { fetchLocalDate } from '~/shared/util/currentTime'

export interface KpoSimpleValues {
    kpoData: KpoFormData
    responsiblePerson: string
}

export const kpoSimple = async (values: KpoSimpleValues) => {
    const {
        kpoData: {
            propertyInformation,
            windows,
            balconies,
            rooms,
            kitchen,
            bathroom,
            other,
            calculation,
        },
        responsiblePerson,
    } = values

    const hasBalcony = balconies.hasBalcony
    const hasOther = other.hasOther

    const html = await fetch('/html/kpo_simple.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    const domHtml = parser.parseFromString(htmlText, 'text/html')

    domHtml.getElementById('inspectionDate')!.innerHTML = fetchLocalDate().format('DD.MM.YYYY')
    domHtml.getElementById('responsiblePerson')!.innerHTML = responsiblePerson

    domHtml.getElementById('objectType')!.innerHTML = propertyInformation.objectType
    domHtml.getElementById('objectClass')!.innerHTML = propertyInformation.objectClass
    domHtml.getElementById('serviceType')!.innerHTML = propertyInformation.serviceType
    domHtml.getElementById('address')!.innerHTML = propertyInformation.address
    domHtml.getElementById('isPassNeeded')!.innerHTML = propertyInformation.isPassNeeded! ? 'Да' : 'Нет'
    domHtml.getElementById('cleaningStartDate')!.innerHTML = propertyInformation.cleaningStartDate
    domHtml.getElementById('paymentType')!.innerHTML = propertyInformation.paymentType
    domHtml.getElementById('contactPerson')!.innerHTML = propertyInformation.contactPerson
    domHtml.getElementById('comment')!.innerHTML = propertyInformation.comment

    domHtml.getElementById('windowNumber')!.innerHTML = windows.windowNumber
    domHtml.getElementById('glazingType')!.innerHTML = windows.glazingType.join(', ')
    domHtml.getElementById('frameType')!.innerHTML = windows.frameType.join(', ')
    domHtml.getElementById('sillType')!.innerHTML = windows.sillType.join(', ')
    domHtml.getElementById('hasJalousie')!.innerHTML = windows.hasJalousie
    domHtml.getElementById('hasMosquitoNet')!.innerHTML = windows.hasMosquitoNet
    domHtml.getElementById('hasSecondLightWindows')!.innerHTML = windows.hasSecondLightWindows
    domHtml.getElementById('pollutionType')!.innerHTML = windows.pollutionType
    domHtml.getElementById('alpinism')!.innerHTML = windows.alpinism
    domHtml.getElementById('commentWindows')!.innerHTML = windows.comment

    domHtml.getElementById('balconies')!.innerHTML = hasBalcony ? balconies.balconies : '-'
    domHtml.getElementById('glazingTypeBalconies')!.innerHTML = hasBalcony ? balconies.glazingType : '-'
    domHtml.getElementById('frameTypeBalconies')!.innerHTML = hasBalcony ? balconies.frameType.join(', ') : '-'
    domHtml.getElementById('needDismantling')!.innerHTML = hasBalcony ? balconies.needDismantling : '-'
    domHtml.getElementById('pollutionTypeBalconies')!.innerHTML = hasBalcony ? balconies.pollutionType : '-'
    domHtml.getElementById('commentBalconies')!.innerHTML = hasBalcony ? balconies.comment : '-'

    domHtml.getElementById('premises')!.innerHTML = rooms.premises
    domHtml.getElementById('wallsRooms')!.innerHTML = rooms.walls
    domHtml.getElementById('floorsRooms')!.innerHTML = rooms.floors
    domHtml.getElementById('ceilingsRooms')!.innerHTML = rooms.ceilings
    domHtml.getElementById('furnitureRooms')!.innerHTML = rooms.furniture
    domHtml.getElementById('bedclothes')!.innerHTML = rooms.bedclothes
    domHtml.getElementById('curtains')!.innerHTML = rooms.curtains
    domHtml.getElementById('dryCleaning')!.innerHTML = rooms.dryCleaning
    domHtml.getElementById('commentRooms')!.innerHTML = rooms.comment

    domHtml.getElementById('kitchen')!.innerHTML = kitchen.kitchen
    domHtml.getElementById('wallsKitchen')!.innerHTML = kitchen.walls
    domHtml.getElementById('floorsKitchen')!.innerHTML = kitchen.floors
    domHtml.getElementById('furnitureKitchen')!.innerHTML = kitchen.furniture
    domHtml.getElementById('appliancesKitchen')!.innerHTML = kitchen.appliances
    domHtml.getElementById('commentKitchen')!.innerHTML = kitchen.comment

    domHtml.getElementById('bathroom')!.innerHTML = bathroom.bathroom
    domHtml.getElementById('wallsBathroom')!.innerHTML = bathroom.walls
    domHtml.getElementById('floorsBathroom')!.innerHTML = bathroom.floors
    domHtml.getElementById('bath')!.innerHTML = bathroom.bath
    domHtml.getElementById('showerCabin')!.innerHTML = bathroom.showerCabin
    domHtml.getElementById('appliancesBathroom')!.innerHTML = bathroom.appliances
    domHtml.getElementById('commentBathroom')!.innerHTML = bathroom.comment

    domHtml.getElementById('sauna')!.innerHTML = hasOther ? other.sauna : '-'
    domHtml.getElementById('cluttering')!.innerHTML = hasOther ? other.cluttering : '-'
    domHtml.getElementById('pollution')!.innerHTML = hasOther ? other.pollution : '-'
    domHtml.getElementById('equipmentOther')!.innerHTML = hasOther ? other.equipment : '-'
    domHtml.getElementById('services')!.innerHTML = hasOther ? other.services : '-'
    domHtml.getElementById('commentOther')!.innerHTML = hasOther ? other.comment : '-'

    domHtml.getElementById('personnel')!.innerHTML = calculation.personnel
    domHtml.getElementById('days')!.innerHTML = calculation.days
    domHtml.getElementById('logistics')!.innerHTML = calculation.logistics
    domHtml.getElementById('equipment')!.innerHTML = calculation.equipment

    return { style: domHtml.querySelector('style')!.innerHTML, body: domHtml.querySelector('body')!.innerHTML }
}
