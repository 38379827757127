import clsx from 'clsx'
import { CSSProperties, useContext } from 'react'
import { Controller, FieldValues, UseFieldArrayUpdate, useFormContext } from 'react-hook-form'
import CustomCheckbox from '~/components/common/checkbox/CustomCheckbox/CustomCheckbox'
import CustomInput from '~/components/common/inputs/CustomInput/CustomInput'
import CustomRadioGroup from '~/components/common/inputs/CustomRadioGroup/CustomRadioGroup'
import CustomSelect, { CustomSelectThemes } from '~/components/common/inputs/CustomSelect/CustomSelect'
import TabsTableFileInput
    from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTablePage/subComponents/TabsTableCell/subComponents/TabsTableFileInput/TabsTableFileInput'
import { TabsTableContext } from '~/components/common/ReusingComponents/TabsTable/TabsTableContext'

import './TabsTableCell.scss'
import { getTabsTableValidation } from '~/components/common/ReusingComponents/TabsTable/utils/getTabsTableValidation'
import { validateInput } from '~/hooks/useFormConstructor/lib/additionalFunctions/validateInput'

export interface TabsTableCellProps {
    rowIndex: number
    name: string
    sectionName: string
    pageIndex: number
    input: any
    className?: string
    style?: CSSProperties
    updateRow?: UseFieldArrayUpdate<FieldValues, string>
}

const TabsTableCell = (props: TabsTableCellProps) => {
    const {
        rowIndex,
        name,
        sectionName,
        pageIndex,
        input,
        className,
        style,
        updateRow,
    } = props

    const {
        control,
        trigger,
        formState: { isSubmitted },
    } = useFormContext()
    const {
        disabled,
        activeTabIndex,
        onSubscriptionFieldChange,
        customCell,
    } = useContext(TabsTableContext)

    return (
        <Controller
            name={`${name}.${rowIndex}.${input.name}`}
            rules={validateInput(input, input.tableValidationType ? getTabsTableValidation(input.tableValidationType, sectionName, rowIndex) : undefined)}
            control={control}
            render={({ field: { value, onChange, name: fullInputName }, fieldState }) => {
                let component
                const currentCustomCell = customCell?.find((cell) => input.name === cell.name)

                if (currentCustomCell && currentCustomCell.rule(props)) {
                    component = currentCustomCell.component({ ...props, value, disabled, updateRow })
                } else {
                    switch (input.type) {
                        case 'checkbox':
                            component = (
                                <CustomCheckbox
                                    {...input}
                                    id={`${fullInputName}.${rowIndex}.${input.name}`}
                                    title={''}
                                    onChange={(value) => {
                                        onChange(value)
                                        if (input.isSubscription) {
                                            onSubscriptionFieldChange && onSubscriptionFieldChange(rowIndex, input.name, value)
                                        }
                                    }}
                                    value={value}
                                    disabled={disabled || input.disabled}
                                />
                            )
                            break
                        case 'select':
                            component = (
                                <CustomSelect
                                    name={input.name}
                                    onChange={(value) => {
                                        onChange(value)

                                        if (input.isSubscription) {
                                            onSubscriptionFieldChange && onSubscriptionFieldChange(rowIndex, input.name, value)
                                        }

                                        if (input.withTrigger && (isSubmitted || input.ignoreSubmitted)) {
                                            // валидируем всю строку на случай если селект сетит значения в других ячейках
                                            void trigger(`${name}.${rowIndex}`)
                                        }
                                    }}
                                    value={value}
                                    placeholder={input.placeholder}
                                    disabled={input.disabled || disabled}
                                    innerClassName='tabsTableCell__select'
                                    items={input.items}
                                    search={input.search}
                                    theme={CustomSelectThemes.TABLE}
                                />
                            )
                            break
                        case 'radioGroup':
                            component = (
                                <CustomRadioGroup
                                    value={value}
                                    onChange={(value) => {
                                        onChange(value)
                                        if (input.isSubscription) {
                                            onSubscriptionFieldChange && onSubscriptionFieldChange(rowIndex, input.name, value)
                                        }
                                    }}
                                    items={input.items.map((item: any) => ({
                                        ...item,
                                        name: `${rowIndex}_${activeTabIndex}_${item.name}`,
                                    }))}
                                    disabled={input.disabled || disabled}
                                />
                            )
                            break
                        case 'file':
                            component = (
                                <TabsTableFileInput
                                    name={input.name}
                                    sectionName={sectionName}
                                    pageIndex={pageIndex}
                                    rowIndex={rowIndex}
                                    onChange={() => {
                                        if (input.withTrigger && (isSubmitted || input.ignoreSubmitted)) {
                                            void trigger(`${name}.${rowIndex}`)
                                        }
                                    }}
                                    disabled={input.disabled || disabled}
                                    customDisabledType={input.customDisabledType}
                                />
                            )
                            break
                        default:
                            component = (
                                <CustomInput
                                    className='tabsTableCell__text'
                                    name={fullInputName}
                                    onChange={(value) => {
                                        onChange(value)
                                        if (input.isSubscription) {
                                            onSubscriptionFieldChange && onSubscriptionFieldChange(rowIndex, input.name, value.target?.value || value)
                                        }

                                        if (input.withTrigger && (isSubmitted || input.ignoreSubmitted)) {
                                            void trigger(`${name}.${rowIndex}`)
                                        }
                                    }}
                                    value={value}
                                    placeholder={input.placeholder}
                                    disabled={input.disabled || disabled}
                                    mask={input.mask}
                                    multiline={input.multiline}
                                    rerender={activeTabIndex}
                                    decimalScale={input.decimalScale}
                                    displayType={input.displayType}
                                    renderText={input.renderText}
                                />
                            )
                            break
                    }
                }

                return (
                    <td
                        className={clsx('tabsTableBody__cell', input.className, `${name}_${rowIndex}`, className, {
                            'tabsTableBody__cell--error': fieldState.error,
                        })}
                        style={style}
                    >
                        {component}
                    </td>
                )
            }}
        />
    )
}

export default TabsTableCell
