import clsx from 'clsx'
import { memo } from 'react'
import { ServicesWithoutInspection } from '~/API/clientApi/client.types'
import './ClientServiceTableItem.scss'

interface ClientServiceTableItemProps {
    className?: string
    item: ServicesWithoutInspection
}

export const ClientServiceTableItem = memo((props: ClientServiceTableItemProps) => {
    const {
        className,
        item,
    } = props

    return (
        <tr className={clsx('client-service-table-item', className)}>
            <td className='client-service-table-item__amount'>
                <span>{item.name}</span> {/* Описание работ */}
            </td>
            <td className='client-service-table-item__amount'>
                <span>{item.amount}</span> {/* Кол-во */}
            </td>
            <td className='client-service-table-item__amount'>
                <span>{item.measure}</span> {/* Ед. измерен. */}
            </td>
            <td className='client-service-table-item__amount'>
                <span>{Number(item.price) / Number(item.amount)}</span> {/* Цена за ед. */}
            </td>
            <td className='client-service-table-item__amount'>
                <span>{Number(item.price)}</span> {/* Стоимость */}
            </td>
            <td className='client-service-table-item__amount'>
                <span>{Number(item.price) - (Number(item.price) / 100 * Number(item.discount))}</span> {/* Стоимость со скидкой */}
            </td>
        </tr>
    )
})
