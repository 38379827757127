export const qualityControl = {
    division: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Подразделение',
        name: 'division',
        className: 'input100',
        type: 'select',
        items: [
            { value: 'B2C', text: 'B2C' },
            { value: 'B2B (ПРР)', text: 'B2B (ПРР)' },
        ],
    }),
    responsibleManager: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Ответственный руководитель',
        name: 'responsibleManager',
        className: 'input50 input_inspectManager',
        type: 'user',
    }),
    reportDate: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Дата отчета',
        name: 'reportDate',
        className: 'input33',
        singleDate: true,
        type: 'date',
    }),
    qualityRating: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Оценка качества',
        name: 'qualityRating',
        className: 'input33',
        type: 'select',
        items: [
            { value: '-', text: '-' },
            { value: 1, text: 1 },
            { value: 2, text: 2 },
            { value: 3, text: 3 },
            { value: 4, text: 4 },
            { value: 5, text: 5 },
        ],
    }),
    serviceRating: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Оценка сервиса',
        name: 'serviceRating',
        className: 'input33',
        type: 'select',
        items: [
            { value: '-', text: '-' },
            { value: 1, text: 1 },
            { value: 2, text: 2 },
            { value: 3, text: 3 },
            { value: 4, text: 4 },
            { value: 5, text: 5 },
        ],
    }),
    discountNextCleaning: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Скидка на след. уборку',
        name: 'discount',
        className: 'input50',
        type: 'select',
        items: [
            { value: 0, text: '0%' },
            { value: 5, text: '5%' },
            { value: 10, text: '10%' },
            { value: 15, text: '15%' },
            { value: 20, text: '20%' },
        ],
    }),
    clientComment: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Комментарий клиента',
        name: 'clientComment',
        className: 'input100',
        multiline: true,
        type: 'text',
    }),
    qualityControlComment: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Комментарий менеджера продаж',
        name: 'qualityControlComment',
        className: 'input100',
        multiline: true,
        type: 'text',
    }),
    qualityControlFiles: (disabled: boolean) => ({
        disabled: disabled,
        name: 'qualityControlFiles',
        title: 'Скрин переписки/Запись разговора',
        max: 15,
        type: 'documentList',
        button: 'Добавить файл',
        required: true,
        allowedFileTypes: ['image', 'audio', 'video'],
    }),
}
