import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetchAllClientsQuery } from '~/API/clientApi/client'
import { ClientResponse } from '~/API/clientApi/client.types'
import { ClientData } from '~/components/common/inputs/CustomClientInput/subComponents/ClientData/ClientData'
import {
    ItemType,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientOrganization/ClientOrganization.types'
import { ClientTypes } from '~/components/common/inputs/CustomClientInput/subComponents/ClientType/ClientType'
import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import CustomSearchInput, {
    CustomSearchInputThemes,
} from '~/components/common/inputs/CustomSearchInput/CustomSearchInput'
import './ClientOrganization.scss'
import Preloader from '~/components/common/preloaders/Preloader/Preloader'

interface ClientOrganizationProps {
    className?: string
    handleSetClientInfo?: (value: ClientResponse<ItemType>) => void
    clientInfo: ClientResponse<ItemType>
    placeholder: string
    type: ClientTypes
}

export const ClientOrganization = (props: ClientOrganizationProps) => {
    const {
        className,
        handleSetClientInfo,
        clientInfo,
        placeholder,
        type,
    } = props

    const [searchText, setSearchText] = useState<string>('')
    const [organizations, setOrganizations] = useState<ClientResponse<ItemType>[]>([])

    const {
        data: clientData,
        isFetching: clientLoading,
    } = useFetchAllClientsQuery({
        counterparty: type,
        query: searchText,
    })

    const handleSetSearch = useCallback((value: string) => {
        setSearchText(value)
    }, [])

    const handleSearchClick = useCallback(() => {
        handleSetClientInfo && handleSetClientInfo({} as ClientResponse<ItemType>)
    }, [])

    useEffect(() => {
        if (clientData?.length) {
            setOrganizations(() => {
                return clientData.map((client) => ({
                    ...client,
                    additionalParams: {
                        value: client.counterparty === 'Юр. лицо' ? client.inn : client.phone,
                        text: client.counterparty === 'Юр. лицо' ? client.organizationName : client.phone,
                    },
                }),
                )
            })
        } else {
            setOrganizations([])
        }
    }, [clientData])

    return (
        <div className={clsx('client-organization', className)}>
            <CustomSearchInput
                onChange={handleSetSearch}
                theme={CustomSearchInputThemes.GREY}
                isButton={true}
                onClick={handleSearchClick}
                placeholder={placeholder}
                mask={type === ClientTypes.INDIVIDUAL ? InputMask.PHONE_NUMBER : undefined}
            />
            {!clientLoading ? (
                <ClientData
                    data={organizations}
                    handleSetData={handleSetClientInfo}
                    currentItem={clientInfo}
                />
            ) : (
                <Preloader />
            )}
        </div>
    )
}
