import { UseFormGetValues } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'

export const checkClientPath = (clientType: ClientInfoEnum) => {
    switch (clientType) {
        case ClientInfoEnum.CONTRACTOR: {
            return {
                currentClientType: 'starting.contractor.counterparty',
                isProxy: 'starting.contractor.isProxy',
            }
        }
        case ClientInfoEnum.ORGANIZATION: {
            return {
                currentClientType: 'primaryInformation.counterparty',
                isProxy: 'primaryInformation.organizationRequisites.isProxy',
            }
        }
    }
}

interface handleResetParams {
    contextMethods: UseFormReturn<any>
    getValues: UseFormGetValues<any>
    clientType: ClientInfoEnum

    handleClosePopup(): void
}

export const handleReset = (params: handleResetParams) => {
    const {
        contextMethods,
        getValues,
        handleClosePopup,
        clientType,
    } = params
    switch (clientType) {
        case ClientInfoEnum.CONTRACTOR: {
            contextMethods.reset({
                ...contextMethods.getValues(),
                starting: {
                    ...contextMethods.getValues('starting'),
                    ...getValues('starting'),
                },
                documents: {
                    ...contextMethods.getValues('documents'),
                    ...getValues('documents'),
                },
            })
            break
        }
        case ClientInfoEnum.ORGANIZATION: {
            contextMethods.reset({
                ...contextMethods.getValues(),
                primaryInformation: {
                    ...contextMethods.getValues('primaryInformation'),
                    ...getValues('primaryInformation'),
                },
                documents: {
                    ...contextMethods.getValues('documents'),
                    ...getValues('documents'),
                },
            })
            break
        }
    }
    handleClosePopup()
}

export const chooseTitle = (clientType: ClientInfoEnum) => {
    switch (clientType) {
        case ClientInfoEnum.CONTRACTOR: {
            return 'Информация о подрядчике'
        }
        case ClientInfoEnum.ORGANIZATION: {
            return 'Информация о клиенте'
        }
    }
}
