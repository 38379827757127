export const orderValidationRules = (input: any) => {
    const result: any = {}

    // Задаем required по умолчанию
    if (input.required) {
        result.required = input.required
    } else {
        result.required = false
    }

    if (input.pattern) {
        result.pattern = new RegExp(input.pattern)
    }

    const validationFunctions: ((value: any) => boolean)[] = []

    if (input.validationType) {
        // разделяем validationType по пробелам и добавляем соответствующие функции проверки в массив
        const validationTypes = input.validationType.split(' ')

        validationTypes.forEach((validationType: string) => {
            switch (validationType) {
                case 'itemsNotNull': {
                    validationFunctions.push((value: any) => {
                        return value.every((item: any) => item.items.length > 0)
                    })
                    break
                }
                case 'minLength_1':
                    validationFunctions.push((value: any) => value.length > 0)
                    break
                case 'notUndefined':
                    validationFunctions.push((value: any) => value !== undefined)
                    break
                case 'notNull':
                    validationFunctions.push((value: any) => value !== null)
                    break
                case 'valueNot_0':
                    validationFunctions.push((value: any) => value !== 0 && value !== '0')
                    break
                // case 'notNullNotUndefinedMinLength(1)': todo раньше было так, но сейчас можно заполнить с разными валидаторами
                //     validationFunctions.push((value: any) => value !== null && value !== undefined && value.length > 0)
                //     break
                case 'validateDate':
                    validationFunctions.push((value: any) => {
                        if (value) {
                            if (
                                typeof value === 'object' &&
                                value.startDate &&
                                value.endDate &&
                                value.startDate !== '' &&
                                value.endDate !== ''
                            ) {
                                return true
                            }
                            if (typeof value === 'string' && value !== '') {
                                return true
                            }
                        }
                        return false
                    })
                    break
                case 'validateNZ':
                    validationFunctions.push((value: any) => value && value.completed)
                    break
                default:
                    break
            }
        })
    }

    if (validationFunctions.length > 0) {
        result.validate = (value: any) => {
            return validationFunctions.every((fn) => fn(value))
        }
    }

    return result
}
