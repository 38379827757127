import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { WithHideInput } from './lib/additionalHOC/withHideInput'
import { WithSectionBody } from './lib/additionalHOC/withSectionBody'
import InputFormConstructor from './subComponents/InputFormConstructor/InputFormConstructor'
import InputsRowFormConstructor from './subComponents/InputsRowFormConstructor/InputsRowFormConstructor'
import { Data, InputParams } from './useFormConstructor.types'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import { Specialization } from '~/API/userApi/user.types'
import { User } from '~/API/userApi/user.types'
import { checkAdditionalRoles } from '~/hooks/useFormConstructor/lib/additionalFunctions/checkAdditionalRoles'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { FormActionEnum } from '~/redux/slices/appSlice/appSlice.types'
import { defineStageAction } from '~/shared/order/orderUtil'
import { IRulesComments } from '~/types/types.order'
import './styles/useFormConstructor.scss'

interface CreateInputsParams {
    tabName: string
    inputsTemplate: Record<string, Record<string, Data>> | null
    methods: UseFormReturn<any>
    submitButtonHandler: (...args: any) => void
    user: User | null
    isArchived?: boolean
}

const defineUserSpecialization: Map<Specialization, string[]> = new Map([
    ['commercial', ['inspection']],
])

export const createInputs = (params: CreateInputsParams) => {
    const {
        tabName,
        inputsTemplate,
        methods,
        submitButtonHandler,
        user,
        isArchived,
    } = params

    const {
        control,
        setValue,
        getValues,
    } = methods
    if (inputsTemplate) {
        const uniqUUID = v4()
        return Object.keys(inputsTemplate[tabName]!)

            // фильтруем секции и показываем только те что доступны для текущей роли и для текущего подразделения
            .filter((sectionKey) => {
                if (user?.specialization && defineUserSpecialization.get(user?.specialization)?.includes(sectionKey)) {
                    return true
                }

                const isRole = user?.role ? checkAdditionalRoles(inputsTemplate[tabName]![sectionKey].roles, user, getValues)?.includes(user?.role) : true
                if (inputsTemplate[tabName]![sectionKey]?.divisionDependence) {
                    const isDivision = inputsTemplate[tabName]![sectionKey]?.divisionDependence?.some((division) => user?.division.includes(getValues(division)))
                    return isRole && isDivision
                }

                return isRole
            })
            .map((sectionKey) => { // получаем каждую отдельную секцию с ее ключом (primaryInformation || selling)
                const sectionData: Data = inputsTemplate[tabName]![sectionKey]
                return (
                    <WithSectionBody
                        title={sectionData.title}
                        isActive={sectionData.isActive}
                        isBlock={sectionData.isBlock}
                        isMultiple={sectionData.isMultiple}
                        isDisabled={sectionData.isDisabled}
                        divisionDependence={sectionData.divisionDependence}
                        getValues={getValues}
                        key={`${sectionKey}__inputsArrIndex`}
                    >
                        {sectionData.inputs.map((inputsRow: InputParams[], inputsArrIndex) => { // перебираем структуру inputs для каждой секции, каждый [] - новая строчка
                            return (
                                <InputsRowFormConstructor
                                    key={`${sectionKey}__inputsArrIndex-${inputsArrIndex}__inputIndex-${uniqUUID}`}
                                    inputsRow={inputsRow}
                                    sectionKey={sectionKey}
                                    inputsArrIndex={inputsArrIndex}
                                    methods={methods}
                                    render={(input, inputIndex, handleSetRowEmpty) => (
                                        <WithHideInput
                                            input={input}
                                            resetField={methods.resetField}
                                            getValues={methods.getValues}
                                            setValue={methods.setValue}
                                            getFieldState={methods.getFieldState}
                                            sectionName={sectionKey}
                                            key={`${sectionKey}__inputsArrIndex-${inputIndex}-${uniqUUID}`}
                                            handleSetRowEmpty={handleSetRowEmpty}
                                            user={user}
                                        >
                                            <InputFormConstructor
                                                key={`${sectionKey}__inputsArrIndex-${inputsArrIndex}__inputIndex-${inputIndex}-${input.name}-${uniqUUID}`}
                                                input={input}
                                                inputsArrIndex={inputsArrIndex}
                                                sectionKey={sectionKey}
                                                inputIndex={inputIndex}
                                                control={control}
                                                setValue={setValue}
                                                getValues={getValues}
                                                submitButtonHandler={submitButtonHandler}
                                                isArchived={isArchived}
                                            />
                                        </WithHideInput>
                                    )}
                                />
                            )
                        })}
                    </WithSectionBody>
                )
            })
    }
    return null
}

type UseFormConstructorProps = {
    inputsTemplate: {
        main: Record<string, Data>
        report?: Record<string, Data>
    } | null
    order?: OrderInterface
    functionForSubmitOrder: (isFormValid: boolean, formValues: any, stageName?: FormActionEnum) => void
    handleSetFormValid: (value: boolean) => void
}

export function useFormConstructor(props: UseFormConstructorProps) {
    const {
        inputsTemplate,
        order,
        functionForSubmitOrder,
        handleSetFormValid,
    } = props
    const user = useSelector(selectUser)

    const [createdInputs, setCreatedInputs] = useState<{
        main: JSX.Element[] | null,
        report?: JSX.Element[] | null
    } | null>(null)

    const methods = useForm({
        values: order,
    })

    const {
        control,
        reset,
        handleSubmit,
        formState,
        getValues,
    } = methods

    const documents = useWatch({ control, name: 'documents' })

    const onSubmit = (data: any) => {
        handleSetFormValid(true)
        functionForSubmitOrder(true, data, data?.nextStageName)
    }

    const onError = (data: any) => {
        handleSetFormValid(false)
        functionForSubmitOrder(false, data)
    }

    const submitButtonHandler = (message?: string, commentType?: keyof IRulesComments) => {
        if (defineStageAction(getValues('nextStageName')) === 'HANDLE_SUBMIT') {
            handleSubmit(
                (data, event) => onSubmit(data),
                (data, event) => onError(data),
            )()
        } else {
            onSubmit(getValues())
        }
    }

    useEffect(() => {
        reset(order)
    }, [order])

    useEffect(() => {
        setCreatedInputs(() => {
            return {
                main: createInputs({
                    tabName: 'main',
                    methods,
                    inputsTemplate,
                    submitButtonHandler,
                    user,
                    isArchived: order?.archived,
                }),
            }
        })

        return () => {

        }
    }, [inputsTemplate, order?.archived])

    return {
        createdInputs,
        documents,
        methods,
        isDirty: formState.isDirty,
    }
}
