import { useSelector } from 'react-redux'
import {
    filterStageItemsByRole,
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import { Data } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { parseJson } from '~/shared/order/orderManager/orderManager'

export const useDynamicImportStageTemplate = () => {
    const user = useSelector(selectUser)

    async function dynamicImportInputsTemplate(stageName: StageType) {
        const currentStage = stageName.split('_TO_')
        let template
        try {
            template = await import(`../../shared/templates/orderStageTemplates/jsonInputsTemplates/realTemplates/${currentStage.length === 1 ? currentStage[0] : currentStage[1]}.json`)
        } catch (ignored) {
            template = await import('../../shared/templates/orderStageTemplates/jsonInputsTemplates/realTemplates/APPROVE_NZ.json')
        }

        const parsedTemplate: {
            main: Record<string, Data>
            report?: Record<string, Data>
        } = parseJson(template.default) // todo убрать parseJson когда сделаем api

        const filterActionInputByRole = filterStageItemsByRole({
            items: parsedTemplate.main,
            role: user?.role,
            stage: stageName,
        })

        return ({ ...parsedTemplate, main: filterActionInputByRole })
    }

    return dynamicImportInputsTemplate
}
