import CloseIcon from '@mui/icons-material/Close'
import React, { ChangeEvent, useRef, useState } from 'react'
import '../../../popups.scss'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ArchiveSvg from '../../../../../../assets/svg/archive.svg'
import { useFetchExistOrder } from '~/hooks/useFetchExistOrder/useFetchExistOrder'
import { useRestoreFromArchive } from '~/hooks/useRestoreFromArchive/useRestoreFromArchive'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { selectActiveOrderId } from '~/redux/selectors/orderSlice.selectors'
import { orderToUpdateFromArchiveSelector } from '~/redux/selectors/popupSlice.selectors'
import { closeUnzipPopup } from '~/redux/slices/popupSlice/popupSlice'
import { useAppDispatch } from '~/redux/store'

const PopupFromArchive = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const restoreFromArchive = useRestoreFromArchive()
    const fetchExistOrder = useFetchExistOrder()

    const ref = useRef(null)

    const [error, setError] = useState<null | string>(null)
    const [reason, setReason] = useState('')

    const orderToUpdateFromArchive = useSelector(orderToUpdateFromArchiveSelector)
    const activeOrderId = useSelector(selectActiveOrderId)
    const user = useSelector(selectUser)

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setError(null)
        setReason(e.target.value)
    }

    const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
        if (ref.current && !(ref.current as any).contains(e.target)) {
            dispatch(closeUnzipPopup())
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (reason.trim() === '') {
            setError('Ошибка ввода')
        } else if (user && orderToUpdateFromArchive) {
            await restoreFromArchive(orderToUpdateFromArchive, reason)
            if (!location.pathname.includes('/order/')) {
                navigate(`/order/${orderToUpdateFromArchive}`)
            } else {
                fetchExistOrder(orderToUpdateFromArchive)
            }
        }
    }
    return (
        <div className={'popupWrapper'} onClick={handleClose}>
            <div className='popup popupToArchive' ref={ref}>
                <div className='popupToArchive__header'>
                    <div className='popupToArchive__icon'>
                        <ArchiveSvg />
                    </div>
                    <div className='popupToArchive__header__body'>
                        <h4>Восстановление заявки</h4>
                        <p>Укажите причину восстановления заявки</p>
                    </div>
                </div>
                <form>
                    <textarea
                        value={reason}
                        onChange={handleChange}
                        placeholder={'Опишите подробно причины восстановления заявки'}
                        rows={4}
                        className={error ? 'error' : ''}
                    />
                    {error && <p className={'error_subscription'}>*{error}</p>}
                    <button className={'button popupToArchive__button'} onClick={handleSubmit}>
                        Восстановить
                    </button>
                </form>
                <CloseIcon className={'close'} onClick={() => dispatch(closeUnzipPopup())} />
            </div>
        </div>
    )
}

export default PopupFromArchive
