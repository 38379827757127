import { calculateWithDiscount, organizationRequisitesNds, organizationRequisitesWithoutNds } from './docUtil'
import { fetchDate } from '../../util/currentTime'
import { fetchUserById } from '~/API/userApi/user'
import { User } from '~/API/userApi/user.types'
import {
    ContactInfo,
    KpAdditionalService,
    KpServiceWithNumberWageFund,
    OrganizationRequisites,
    PaymentType,
} from '~/components/pages/KP/kp.types'
import {
    collectServices,
    generateNewKpPageWithBody,
    ServicesType,
} from '~/shared/templates/mainTemplates/additionalFunctions'
import {
    buildAdditionalServiceTable,
    collectKpServices,
    generateNewKpServicesPageWithBody,
    makeAdditionalKpPages,
} from '~/shared/templates/mainTemplates/additionalFunctions/collectKpServices/collectKpServices'
import { formatCurrencyRUB } from '~/shared/util/formatNumberWithIntlOptions'

export interface KpValues {
    paymentType: PaymentType
    orderCreator: string
    contractNumber: number
    address: string
    buildingType: string
    counterparty?: string
    area: string
    kpDiscount: number
    contactInfo: ContactInfo[]
    organizationRequisites?: OrganizationRequisites
    subtotal: number
    services: any
    serviceDescriptions: KpServiceWithNumberWageFund[]
    wageFund: number
    userId?: string
    userPhone?: string
    additionalServices?: KpAdditionalService[]
    isOrisclean: boolean
}

const pageLimit = 20

export const kpWithInspection = async (values: KpValues) => {
    const html = await fetch(values.isOrisclean ? '/html/kpOrisclean.html' : '/html/kpWithInspection.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    const domHtml = parser.parseFromString(htmlText, 'text/html')

    const date = fetchDate()

    const responsibleManager = await fetchUserById(values.userId || values.orderCreator) as User
    const phone = values.userPhone ?? '88125048815'
    let ourRequisites = values.paymentType === 'р/с (НДС)' ? organizationRequisitesNds : organizationRequisitesWithoutNds
    if (values.isOrisclean) {
        // @ts-ignore
        ourRequisites = {
            shortAddress: '188642, Ленинградская область, м.р-н Всеволожский, г.п. Всеволожское, г. Всеволожск, ул. Пушкинская, д. 1, ком. 3',
            formatPhone: '8(812) 615 77 08',
            email: 'office@orisclean.ru',
            inn: '4703146628',
            kpp: '470301001',
            ogrn: '1164704062500',
            name: 'ООО «ОРИС КЛИН»',
        }
    }

    const firstCompanyLine = ourRequisites.shortAddress
    const secondCompanyLine = 'Телефон: ' + ourRequisites.formatPhone + ', E-mail: ' + ourRequisites.email
    const thirdCompanyLine = 'ИНН ' + ourRequisites.inn + ', КПП ' + ourRequisites.kpp + ', ОГРН: ' + ourRequisites.ogrn

    const discount = Number(values.kpDiscount)
    const hasMoreServiceDescriptions = values.serviceDescriptions.length > 1

    /** формирование технологической карты уборки */
    const additionalServiceDescriptionPages = collectKpServices(values.serviceDescriptions, domHtml.querySelector('style')!.innerHTML)

    if (additionalServiceDescriptionPages?.length > 1) {
        domHtml.querySelector('#additionalServiceDescriptionsPage')!.innerHTML = generateNewKpServicesPageWithBody(additionalServiceDescriptionPages.pop()!.join(''), 'LAST', values.isOrisclean)
        domHtml.querySelector('#additionalPage')!.innerHTML = makeAdditionalKpPages(additionalServiceDescriptionPages, values.isOrisclean)
    } else if (additionalServiceDescriptionPages?.length) {
        domHtml.querySelector('#additionalServiceDescriptionsPage')!.innerHTML = generateNewKpServicesPageWithBody(additionalServiceDescriptionPages[0].join(''), 'SINGLE', values.isOrisclean)
    }

    try {
        if (values.additionalServices && values.additionalServices.length > 0) {
            domHtml.querySelector('#adServices')!.innerHTML = buildAdditionalServiceTable(values.additionalServices)
        } else {
            domHtml.querySelector<HTMLTableElement>('#adServicesTable')!.style.display = 'none'
        }
    } catch (ignoredError) {
    }


    // if (hasMoreServiceDescriptions) {
    //     domHtml.querySelector<HTMLTableElement>('#totalTable')!.style.display = 'none'
    // }

    // Этап составления доп страниц для перечня услуг
    const services: ServicesType[] = values?.services?.filter((s: ServicesType) => s?.items?.length > 0)
    const additionalServicePages = collectServices({ services, pageLimit })
    if (additionalServicePages.length > 0) {
        const pages = additionalServicePages.map((p) =>
            generateNewKpPageWithBody(
                {
                    firstCompanyLine,
                    secondCompanyLine,
                    thirdCompanyLine,
                },
                buildPageTable(p.join('')),
                pageTemplate(values.isOrisclean),
            ),
        ).join('')
        domHtml.querySelector('#additionalServicePage')!.innerHTML = pages
    }

    // Этап заполнения итоговой стоимости
    const totalPrice = values.subtotal
    const totalDiscountPrice = Math.floor(+calculateWithDiscount(+totalPrice, discount, discount > 0))
    const priceBeforeTax = values.serviceDescriptions.reduce((acc, serviceDescription) => serviceDescription.priceBeforeTax ? acc + serviceDescription.priceBeforeTax : acc, 0)

    // domHtml.querySelector('#additionalServiceDescriptionsPage')!.innerHTML = additionalServiceDescriptionPages

    domHtml.querySelectorAll('.firstCompanyLine')!.forEach((html) => html.innerHTML = firstCompanyLine)
    domHtml.querySelectorAll('.secondCompanyLine')!.forEach((html) => html.innerHTML = secondCompanyLine)
    domHtml.querySelectorAll('.thirdCompanyLine')!.forEach((html) => html.innerHTML = thirdCompanyLine)

    domHtml.querySelector('#kpDate')!.innerHTML = date.format('DD.MM.YYYY')
    domHtml.querySelector('#kpEndDate')!.innerHTML = date.clone().add(1, 'month').format('DD.MM.YYYY')
    domHtml.querySelector('#kpPhone')!.innerHTML = values.isOrisclean ? '+78126157708, ' + responsibleManager.phone : phone
    domHtml.querySelector('#responsibleManagerEmail')!.innerHTML = responsibleManager.email

    domHtml.querySelector('#responsibleManager')!.innerHTML = responsibleManager.fullname
    domHtml.querySelector('#kpNumber')!.innerHTML = values.contractNumber.toString()
    domHtml.querySelector('#organizationEmail')!.innerHTML = values.contactInfo[0].clientEmail || '-'
    domHtml.querySelector('#organizationPhone')!.innerHTML = values.contactInfo[0].clientPhone || '-'
    domHtml.querySelector('#organizationContactName')!.innerHTML = values.contactInfo[0].clientFullname
    domHtml.querySelector('#address')!.innerHTML = values.address
    domHtml.querySelector('#objectType')!.innerHTML = values.buildingType
    domHtml.querySelector('#counterparty')!.innerHTML = values.counterparty || '-'
    domHtml.querySelector('#area')!.innerHTML = values.area || '-'

    if (values.organizationRequisites?.value) {
        domHtml.querySelector('#kpOrganization')!.innerHTML = values.organizationRequisites?.unrestricted_value || ''
        domHtml.querySelector('#organizationName')!.innerHTML = values.organizationRequisites?.unrestricted_value || ''
        domHtml.querySelector('#legalFaceAddress')!.innerHTML = values.organizationRequisites?.address || values.address
    } else {
        domHtml.querySelector<HTMLTableRowElement>('#kpOrganizationTr')!.style.display = 'none'
        domHtml.querySelector<HTMLTableRowElement>('#organizationNameTr')!.style.display = 'none'
        domHtml.querySelector<HTMLTableRowElement>('#legalFaceAddressTr')!.style.display = 'none'
    }

    domHtml.querySelector('#kpSum')!.innerHTML = formatCurrencyRUB(Math.floor(totalPrice), false)
    if (discount === 0) {
        domHtml.querySelectorAll<HTMLTableRowElement>('#trDiscount')!.forEach((elem) => elem.style.display = 'none')
        domHtml.querySelectorAll<HTMLTableRowElement>('#trDiscountResult')!.forEach((elem) => elem.style.display = 'none')
    } else if (discount > 0) {
        domHtml.querySelector('#kpDiscount')!.innerHTML = Math.abs(discount).toString()
        domHtml.querySelector('#kpSumDisc')!.innerHTML = formatCurrencyRUB(Math.floor(totalDiscountPrice), false)
    }

    if (values.paymentType === 'р/с (НДС)') {
        domHtml.querySelector('#kpNds')!.innerHTML = formatCurrencyRUB(Math.floor(Math.abs((discount > 0 ? totalDiscountPrice : totalPrice) * 20 / 120)), false)
    } else {
        domHtml.querySelector<HTMLTableRowElement>('#trNds')!.style.display = 'none'
    }

    domHtml.querySelectorAll('.nds').forEach((elem) => {
        switch (values.paymentType) {
            case 'р/с (НДС)': {
                elem.innerHTML = ' <br>(с учетом НДС 20%):'
                break
            }
            case 'Наличные': {
                elem.innerHTML = ':'
                break
            }
            case 'р/с (без НДС)': {
                elem.innerHTML = ' <br>(без учета НДС):'
                break
            }
        }
    },
    )

    domHtml.querySelectorAll('.ourCompanyTitle')!.forEach((html) => html.innerHTML = ourRequisites.name)

    domHtml.querySelectorAll('.pageWrapper').forEach((page, index, array) => {
        page.innerHTML += `<span class='pagesSign'>Страница <b>${index + 1}</b> из <b>${array.length}</b></span>`
    })

    return { style: domHtml.querySelector('style')!.innerHTML, body: domHtml.querySelector('body')!.innerHTML }
}

const pageTemplate = (isOrisclean: boolean) => `
<div class='pageWrapper pageWrapper_2' style='margin-top: 0;'>
        <table style='border-collapse: collapse; border: none; width: 100%; table-layout:fixed; height: 100%;'>
            <tbody>
            <tr>
                <td style='border: none;background-color: ${isOrisclean ? '#7cad42' : '#8eecdf'}; width: 30px; height: 100%; '></td>
                <td style='border: none; vertical-align: baseline; width: 673px; padding-left: 20px;'>
                    <table class='borderNoneTable' style='display: inline-block; margin: 20px 0;'>

                        <tbody>
                        <tr>
                            <td class='logoImg' rowspan='2' style='width: 10%'></td>
                            <th class='ourCompanyTitle' style='text-align:center; padding-left: 0;'>КЛИНИНГОВАЯ КОМПАНИЯ
                                «H2O»
                            </th>
                        </tr>
                        <tr>
                            <td style='text-align:center; color:gray; padding-left: 0; word-break: break-all;'>
                                <p style='text-align: center' class='firstCompanyLine'>196628, г. Санкт-Петербург,
                                    п.Шушары, Колпинское шоссе д.38, к.1, кв.531</p>
                                <p style='text-align: center' class='secondCompanyLine'>Телефон: 8(812) 504-88-15, E-mail:
                                    office@h2ocleaner.ru</p>
                                <p style='text-align: center' class='thirdCompanyLine'>ИНН 7820047425, КПП 782001001, ОГРН
                                    1167847105030</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <div id='additionalPageBody'>
                        your code here
                    </div>
            
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
`

const buildPageTable = (body: string) => {
    return `<table class='services'><tr><th colspan='5' class='center'>Перечень работ</th></tr><tbody id='services'>${body}</tbody></table>`
}
