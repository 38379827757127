import React, { useEffect, useState } from 'react'
import './DocumentViewer.scss'
import Preloader from '../../../../../preloaders/Preloader/Preloader'
import DocumentManager from '../../../../../ReusingComponents/DocumentManager/DocumentManager'
import { fetchFile, useLazyFetchFileModelQuery } from '~/API/fileApi/file'
import { isMobile } from '~/shared/util/deviceDetect'

interface DocumentViewerProps {
    fileId: string,
    local: boolean,
}

const DocumentViewer = ({ fileId, local }: DocumentViewerProps) => {
    const [body, setBody] = useState<JSX.Element | string>(<Preloader withoutBackground={true} />)
    const [fetchFileModel] = useLazyFetchFileModelQuery()

    const handleClickDownload = () => {
        fetchFileModel({ fileId }, true).unwrap()
            .then((fileModel) => {
                fetchFile(fileId).then((file: Blob) => {
                    DocumentManager.saveFile(file, fileModel)
                })
            })
    }

    useEffect(() => {
        if (local) {
            if (isMobile) {
                setBody(<button onClick={() => window.open(fileId)}>Открыть pdf в новой вкладке</button>)
            } else {
                setBody(<embed className={'pdf'} src={fileId} type='application/pdf' />)
            }
            return
        }
        if (fileId) {
            const fileModelPromise = fetchFileModel({ fileId }, true).unwrap()
            const filePromise = fetchFile(fileId)
            Promise.all([filePromise, fileModelPromise]).then((res) => {
                const fileData = res[0]
                const fileModel = res[1]
                if (fileModel && fileData) {
                    const fileInfo = fileModel.fileInfo || fileModel
                    const type = DocumentManager.getDocumentType(fileInfo.fileName)
                    const format = DocumentManager.getDocumentFormat(fileInfo.fileName)
                    const blob = new Blob([fileData], { type: fileInfo.fileType || type + '/' + format })
                    const file = new File([blob], fileInfo.fileName || 'unnamed', { type: fileInfo.fileType || type + '/' + format })
                    const url = URL.createObjectURL(file)
                    switch (type) {
                        case 'video':
                            setBody(<video src={url} controls={true} />)
                            break
                        case 'image':
                            setBody(<img src={url} alt={fileInfo.fileName} />)
                            break
                        case 'audio':
                            setBody(<audio controls autoPlay={false} src={url} />)
                            break
                        case 'application':
                            const pdf = new Blob([blob], { type: 'application/pdf' })
                            const href = URL.createObjectURL(pdf)
                            if (isMobile) {
                                setBody(<button onClick={() => window.open(href)}>Открыть pdf в новой вкладке</button>)
                            } else {
                                if (fileInfo.fileName.includes('docx')) {
                                    setBody(<button onClick={() => handleClickDownload()}>Скачайте docx файл для
                                        просмотра</button>)
                                } else {
                                    setBody(<embed className={'pdf'} src={href} type='application/pdf' />)
                                }
                            }
                            break
                        default:
                            setBody(<button onClick={() => window.open(url)}>Открыть файл в новой вкладке</button>)
                    }
                }
            },
            )
        }
    }, [fileId])


    return (
        <div className='documentViewer'>
            {body}
        </div>
    )
}

export default DocumentViewer
