import { fetchDate } from '../util/currentTime'
import { DaysReport } from '~/API/orderApi/types/foremanReport.type'
import { Division } from '~/API/userApi/user.types'
import { KpService } from '~/components/pages/KP/kp.types'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import { FormActionEnum } from '~/redux/slices/appSlice/appSlice.types'
import { NzTeam } from '~/types/types'

const alwaysSubmitStages: StageType[] = [
    'REPEAT_CLIENT_STARTING',
]

export const defineStageAction = (stageName: FormActionEnum | StageType, additionalStageCheck?: StageType) => {
    if (additionalStageCheck) {
        if (alwaysSubmitStages.includes(additionalStageCheck)) {
            return FormActionEnum.HANDLE_SUBMIT
        }
    }
    switch (stageName) {
        case FormActionEnum.TO_ARCHIVE:
        case FormActionEnum.FROM_ARCHIVE:
        case FormActionEnum.ORDER_CREATION:
        case FormActionEnum.EDIT_ORDER:
        case FormActionEnum.CREATE_KP:
            return FormActionEnum.UPDATE_ORDER
        case FormActionEnum.SAVE_FORM:
            return FormActionEnum.SAVE_ORDER
        case FormActionEnum.NEED_CHANGES_TO_WORKING_PLAN_APPROVING:
        case FormActionEnum.RESPONSIBLE_USER_CHANGING:
        case FormActionEnum.NEED_CHANGES_TO_CONTRACT_SIGNING:
        case FormActionEnum.NEED_CHANGES_TO_CONTRACT_SIGNING_WITH_DATE_CHANGING:
        case FormActionEnum.NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING:
        case FormActionEnum.SEND_TO_MANAGER:
        case FormActionEnum.FINAL_REPORT:
        case FormActionEnum.TO_ORDER_MANAGER_ORDER_CLOSING:
        case FormActionEnum.ORDER_CLOSED:
        case FormActionEnum.QUALITY_CONTROLLING_FINAL_REPORT:
        case FormActionEnum.NEED_CHANGES_TO_CONTRACT_WORK_REPORT_FILLING:
        case FormActionEnum.NEED_CHANGES_TO_SERVICE_TABLE_FILLING:
            return FormActionEnum.SAVE_WITHOUT_CHECK
        default:
            return FormActionEnum.HANDLE_SUBMIT
    }
}

export const defineGlassesType = (order: any) => {
    return order?.primaryInformation?.services
        ?.find((e: any) => e.name === 'glasses')
        ?.values?.some((service: any) => service.name?.includes('с двух сторон'))
}

export const updateLastArrayElement = (array: any[], data: any) => {
    if (!array) {
        return [{ ...data }]
    }
    return [...array.slice(0, -1), { ...array[array.length - 1], ...data }]
}

export const findNzTeamIndex = (nzTeam: NzTeam[], daysReports: DaysReport[], dayIndex: number) => {
    // return nzTeam.findIndex((team) => {
    //     return fetchDate(daysReports[dayIndex].cleaningDate.startDate)
    //             .isBetween(fetchDate(team.cleaningDate.startDate), fetchDate(team.cleaningDate.endDate), undefined, '[]') ||
    //         fetchDate(daysReports[dayIndex].cleaningDate.endDate)
    //             .isBetween(fetchDate(team.cleaningDate.startDate), fetchDate(team.cleaningDate.endDate), undefined, '[]')
    // })
}

export const buildPrimaryInformation = (order: any, user: any) => {
    return {
        ...order.primaryInformation,
        orderCreator: order.primaryInformation.orderCreator || user.id,
        orderCreatedAt: order.primaryInformation.orderCreatedAt || fetchDate(),
    }
}

export const buildComments = (comments: any, text: string | undefined, user: any, type: string) => {
    if (text) {
        return [
            ...(comments || []),
            {
                userId: user.id,
                date: fetchDate(),
                comment: text,
                type,
            },
        ]
    }
    return [...(comments || [])]
}
export const buildEvents = (events: any, order: any, user: any) => {
    return [
        ...(events || []),
        {
            userId: user.id,
            startDate: order.startDate,
            endDate: fetchDate().toDate(),
            stageName: order.stageName,
        },
    ]
}

export const buildReportInfo = (user: any, division: Division, orderClosing?: any) => {
    if (!orderClosing?.reportDate) {
        return {
            reportDate: fetchDate(),
            responsibleManager: user.id,
            division,
        }
    }
    return {}
}

export const buildOrderClosing = (orderClosing: any, sectionName: string | null, user: any, division: Division) => {
    if (!orderClosing || orderClosing.length === 0) {
        return [{ sectionNumber: 0, sectionName, division }]
    }
    if (!sectionName) {
        return [...orderClosing.slice(0, -1), { ...orderClosing[orderClosing.length - 1], ...buildReportInfo(user, division, orderClosing[orderClosing.length - 1]) }]
    }
    return [
        ...orderClosing.slice(0, -1),
        { ...orderClosing[orderClosing.length - 1], ...buildReportInfo(user, division, orderClosing[orderClosing.length - 1]) },
        {
            sectionNumber: orderClosing.length,
            division,
            sectionName,
        },
    ]
}

export const getOrderServiceSmetaIndex = (order: any): number | undefined => {
    return order?.accountingAndSelling?.commercialOffer?.services?.findIndex((service: KpService) => service.serviceSmeta)
}

