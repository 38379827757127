import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppSliceInitialState, FormActionEnum } from './appSlice.types'
import { LessonStatuses } from '~/API/userApi/user.types'
import { IRulesComments } from '~/types/types.order'

const appSliceInitialState: AppSliceInitialState = {
    user: null,
    token: localStorage.getItem('jwtToken') !== undefined ? localStorage.getItem('jwtToken') : null,
    buttonAction: { message: '' },
    formAction: { action: FormActionEnum.NONE },
    formComment: undefined,
    appLoader: true,
    sidebarStatus: false,
    bodyHidden: 'auto',
    fslightboxController: { toggler: null, sourceIndex: 0, key: 1, sources: [], local: false },
    schedulePersonnel: null,
    headerToolbar: true,
    uniqueScheduleValues: [],
    skipPagination: false,
    agendaLoading: true,
    isPageError: false,
}

export const appSlice = createSlice({
    name: 'app',
    initialState: appSliceInitialState,
    reducers: {
        login: (state: AppSliceInitialState, action) => {
            state.token = action.payload
        },
        logout: (state: AppSliceInitialState) => {
            state.token = null
            state.user = null
        },
        CHANGE_SIDEBAR: (state: AppSliceInitialState) => {
            if (state.sidebarStatus) {
                state.bodyHidden = 'auto'
            } else {
                state.bodyHidden = 'hidden'
            }
            state.sidebarStatus = !state.sidebarStatus
        },
        HIDE_SIDEBAR: (state: AppSliceInitialState) => {
            state.sidebarStatus = false
        },
        FETCH_USER_INFO_REQUESTED: (state: AppSliceInitialState) => {
            state.appLoader = true
        },
        HIDE_APP_LOADER: (state: AppSliceInitialState) => {
            state.appLoader = false
        },
        DELETE_USER_INFO: (state: AppSliceInitialState) => {
            state.user = null
        },
        FETCH_USER_INFO_SUCCESS: (state: AppSliceInitialState) => {
            state.appLoader = false
        },
        FETCH_USER_INFO_FAILED: (state: AppSliceInitialState) => {
            state.appLoader = false
        },
        UPDATE_USER_INFO: (state) => {
            // todo переписать на санки
            // нужно быть аккуратным, так как скорее всего срабатывает сага
        },
        CHANGE_USER_INFO: (state: AppSliceInitialState, action) => {
            state.user = action.payload
        },

        changeButtonAction: (state: AppSliceInitialState, action) => {
            state.buttonAction = { message: action.payload }
        },
        changeFormAction: (state: AppSliceInitialState, action) => {
            state.formAction = { ...action.payload }
            state.sidebarStatus = false
        },
        setFormComment: (state: AppSliceInitialState, action: PayloadAction<keyof IRulesComments>) => {
            state.formComment = action.payload
        },
        SET_BODY_HIDDEN: (state: AppSliceInitialState, action) => {
            if (action.payload) {
                state.bodyHidden = 'hidden'
            } else {
                state.bodyHidden = 'auto'
            }
        },
        OPEN_LIGHTBOX: (state: AppSliceInitialState, action) => {
            state.fslightboxController = {
                ...action.payload,
                toggler: !state.fslightboxController.toggler,
                key: state.fslightboxController.key + 1,
            }
        },
        CHANGE_SCHEDULE_PERSONNEL: (state: AppSliceInitialState, action) => {
            state.schedulePersonnel = action.payload
        },

        changeHeaderToolbar: (state: AppSliceInitialState, action) => {
            state.headerToolbar = action.payload
        },
        UNIQUE_SCHEDULE_VALUES: (state: AppSliceInitialState, action) => {
            state.uniqueScheduleValues = action.payload
        },
        SET_SKIP_PAGINATION: (state: AppSliceInitialState, action: PayloadAction<boolean>) => {
            state.skipPagination = action.payload
        },
        setAgendaLoading: (state, action: PayloadAction<boolean>) => {
            state.agendaLoading = action.payload
        },
        setPageError: (state, action: PayloadAction<boolean>) => {
            state.isPageError = action.payload
        },
        setUserLessons: (state, action: PayloadAction<{ lessonName: LessonStatuses, status: boolean }>) => {
            const { lessonName, status } = action.payload
            if (state.user) {
                state.user.lessonStatuses = {
                    ...state?.user?.lessonStatuses,
                    [lessonName]: status,
                }
            }
        },
    },
})

export const {
    setUserLessons,
    login,
    logout,
    FETCH_USER_INFO_REQUESTED,
    FETCH_USER_INFO_SUCCESS,
    FETCH_USER_INFO_FAILED,
    CHANGE_USER_INFO,
    UPDATE_USER_INFO,
    DELETE_USER_INFO,
    HIDE_APP_LOADER,
    CHANGE_SIDEBAR,
    HIDE_SIDEBAR,
    changeButtonAction,
    changeFormAction,
    SET_BODY_HIDDEN,
    OPEN_LIGHTBOX,
    CHANGE_SCHEDULE_PERSONNEL,
    changeHeaderToolbar,
    UNIQUE_SCHEDULE_VALUES,
    SET_SKIP_PAGINATION,
    setAgendaLoading,
    setPageError,
    setFormComment,
} = appSlice.actions

export default appSlice.reducer
