import DocumentManager from '~/components/common/ReusingComponents/DocumentManager/DocumentManager'

export const isFileImage = (fileType: string) => {
    return DocumentManager.types.image.includes(fileType.toLowerCase())
}

export const checkFilesInputName = (files: {
    id: string,
    inputName: string,
    name: string,
}[]) => {
    return (!files.some((file) =>
        file.inputName === 'photosObject' ||
        file.inputName === 'passportPhoto' ||
        file.inputName === 'patentPhoto' ||
        file.inputName === 'otherDocuments' ||
        file.inputName === 'workProcess' ||
        file.inputName === 'additionalServicesPhotos' ||
        file.inputName === 'mistakesFiles' ||
        file.inputName === 'qualityControlFiles' ||
        file.inputName === 'workEnding' ||
        file.inputName === 'workBeginning')
    )
}
