import clsx from 'clsx'
import { useState, ChangeEvent, KeyboardEvent, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { TabsTableContext } from '../../../../TabsTableContext'

import './TabsTableHeaderPopover.scss'

interface TabsTableHeaderPopoverProps {
    popoverIndex: number | null
    setPopoverIndex: (value: (((prevState: number | null) => number | null) | number | null)) => void
    closePopover: () => void
}

const TabsTableHeaderPopover = (props: TabsTableHeaderPopoverProps) => {
    const {
        popoverIndex,
        setPopoverIndex,
        closePopover,
    } = props

    const {
        baseName,
        activeTabIndex,
        setActiveTabIndex,
        pages,
        removePage,
        movePage,
        updatePage,
        appendPage,
        headerPopover,
        getCustomPopoverOptions,
    } = useContext(TabsTableContext)

    const { getValues } = useFormContext()

    const [isEditMode, setIsEditMode] = useState(false)
    const [editText, setEditText] = useState('')

    const deleteTab = () => {
        if (pages.length > 1 && popoverIndex !== null && removePage && !headerPopover?.delete?.disabled) {
            if (activeTabIndex === pages.length - 1 || activeTabIndex > popoverIndex) {
                setActiveTabIndex((prev) => prev - 1)
            }

            headerPopover?.delete?.additionalActionBefore && headerPopover.delete.additionalActionBefore(popoverIndex)
            removePage(popoverIndex)
            headerPopover?.delete?.additionalAction && headerPopover.delete.additionalAction(popoverIndex)
        }

        closePopover()
    }

    const renameTab = (newTitle: string) => {
        if (popoverIndex !== null && newTitle.length > 0 && updatePage && !headerPopover?.delete?.disabled) {
            const page = getValues(`${baseName}.${popoverIndex}`)
            updatePage(popoverIndex, { ...page, title: newTitle })
            closePopover()
            headerPopover?.rename?.additionalAction && headerPopover.rename.additionalAction()
        }
    }

    const copyTab = () => {
        if (!headerPopover?.copy?.disabled) {
            const page = getValues(`${baseName}.${popoverIndex}`)
            appendPage && appendPage(page)
            closePopover()
            headerPopover?.copy?.additionalAction && headerPopover.copy.additionalAction()
        }
    }

    const moveTabLeft = () => {
        if (popoverIndex && movePage && !headerPopover?.moveLeft?.disabled) {
            if (popoverIndex === activeTabIndex) {
                setActiveTabIndex((prev) => prev - 1)
            }
            movePage(popoverIndex, popoverIndex - 1)
            setPopoverIndex((prev) => prev! - 1)
            headerPopover?.moveLeft?.additionalAction && headerPopover.moveLeft.additionalAction()
        }
    }

    const moveTabRight = () => {
        if (popoverIndex !== null && popoverIndex < pages.length - 1 && movePage && !headerPopover?.moveRight?.disabled) {
            if (popoverIndex === activeTabIndex) {
                setActiveTabIndex((prev) => prev + 1)
            }
            movePage(popoverIndex, popoverIndex + 1)
            setPopoverIndex((prev) => prev! + 1)
            headerPopover?.moveRight?.additionalAction && headerPopover.moveRight.additionalAction()
        }
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length >= 50) {
            return
        }
        setEditText(e.currentTarget.value)
    }

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement> | undefined) => {
        const key = event?.key
        if (key === 'Enter' || key === 'Escape') {
            renameTab(editText)
        }
    }

    return (
        <div className='tabsTableHeaderPopover'>
            {!headerPopover?.copy?.disabled && <div
                className='tabsTableHeaderPopover__option'
                onClick={copyTab}
            >
                Создать копию
            </div>}

            {!headerPopover?.delete?.disabled && <div
                className='tabsTableHeaderPopover__option'
                onClick={deleteTab}
            >
                Удалить
            </div>}

            {!headerPopover?.rename?.disabled && <div
                className='tabsTableHeaderPopover__option'
                onClick={() => setIsEditMode((prev) => !prev)}
            >
                Переименовать
                <input
                    className={clsx('tabsTableHeaderPopover__input',
                                    { 'tabsTableHeaderPopover__input--open': isEditMode },
                    )}
                    onClick={(e) => e.stopPropagation()}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    type='text'
                    placeholder='Введите'
                    value={editText}
                />
            </div>}

            {popoverIndex !== 0 && !headerPopover?.moveLeft?.disabled && <div
                className='tabsTableHeaderPopover__option'
                onClick={moveTabLeft}
            >
                Сдвинуть влево
            </div>}

            {popoverIndex !== pages.length - 1 && !headerPopover?.moveRight?.disabled && <div
                className='tabsTableHeaderPopover__option'
                onClick={moveTabRight}
            >
                Сдвинуть вправо
            </div>}
            {getCustomPopoverOptions && popoverIndex !== null && getCustomPopoverOptions(popoverIndex, closePopover).map((option) => !option.disabled && (
                <div
                    key={option.title}
                    className='tabsTableHeaderPopover__option'
                    onClick={option.onClick}
                >
                    {option.content || option.title}
                </div>
            ))}
        </div>
    )
}

export default TabsTableHeaderPopover
