import {
    editFileWithHtml,
    fetchDocumentSetting,
    fetchFileModel,
    generatePdf,
    uploadFileWithHtml,
} from '~/API/fileApi/file'
import { DocType } from '~/API/fileApi/file.types'
import { AllowedFileTypes } from '~/components/common/inputs/CustomFileInputGroup/CustomFileInputGroup'
import { PaymentType } from '~/components/pages/KP/kp.types'
import { FileModelType } from '~/redux/slices/orderSlice/types/orderSlice.type'
import { templates } from '~/shared/templates/templates'
import { fetchDate, fetchLocalDate } from '~/shared/util/currentTime'

class DocumentManager {
    types = {
        application: ['pdf', 'doc', 'docx'],
        audio: ['mp3', 'wav', 'm4a', 'flac', 'wma', 'aac', 'mpeg'],
        image: [
            'jpg',
            'jpeg',
            'png',
            'jpeg2000',
            'webp',
            'bmp',
            'jfif',
            'apng',
            'avif',
            'svg',
            'tiff',
            'xbm',
        ],
        video: ['mp4', 'avi', 'ogv', 'oga', 'ogx', 'ogg', 'webm', 'mkv', 'mov', 'wmv', 'avchd', 'flv', 'mpeg2'],
    }
    parameters = {
        nz: { orientation: 'landscape' },
        nzFront: { orientation: 'landscape' },
        nzBack: { orientation: 'landscape' },
    }

    createKP = async (params: {
        fileId: string
        title: string
        creator: any
        fileModel: any
        values: any
        type?: string
        fileName?: string
    }) => {
        const {
            fileId,
            title,
            creator,
            fileModel,
            values,
            type,
            fileName,
        } = params
        let kpNumber
        if (fileId) {
            const kpFileModel = await fetchFileModel(fileId)
            kpNumber = kpFileModel.contractNumber
        } else {
            const kpFileModel = await fetchDocumentSetting({ documentType: 'kp', businessType: fileModel.businessType })
            kpNumber = kpFileModel.documentNumber + 1
        }
        return await this.updateDocument({
            fileId,
            type: 'kp',
            title,
            creator,
            fileModel: { ...fileModel, contractNumber: kpNumber },
            values: { ...values, contractNumber: kpNumber },
        })
    }

    getDocumentType = (name: string): AllowedFileTypes => {
        const format = this.getDocumentFormat(name)
        const type = Object.entries(this.types).find(([_, value]) =>
            value.some((docType) => docType.toUpperCase() === format.toUpperCase()),
        )
        if (type) {
            return type[0] as AllowedFileTypes
        }
        return 'other'
    }

    getDocumentFormat = (name: string) => {
        const stringSplit = name.split('.')
        if (stringSplit && stringSplit.length > 0) {
            return stringSplit[stringSplit.length - 1]
        }
        return 'doc'
    }

    saveDocument = async (params: {
        type: any
        title: string
        creator: any
        fileModel?: any
        fileName?: string
        values: any
        docType?: DocType
    }) => {
        const { type, title, creator, fileModel, fileName, values, docType } = params
        const newFileModel = {
            ...fileModel,
            fileInfo: this.buildFileInfo({ type, title, creator, fileName, docType }),
            createdAt: fetchDate(),
        }
        const html = await this.generateHtml(type, values)
        const response = await uploadFileWithHtml(html, newFileModel, docType)
        return response.fileId
    }

    generateDocument = async (params: {
        type: any,
        values: any,
        fileName: string,
        title?: string,
        creator?: string
    }) => {
        const { type, values, fileName } = params
        const html = await this.generateHtml(type, values)
        const response = await generatePdf(html)
        const blob = new Blob([response], { type: 'application/pdf' })
        const file = new File([blob], fileName || 'unnamed', { type: 'application/pdf' })
        return file
    }

    editContractAndAct = async (params: {
        creator: any
        fileModel: any
        contractId: string
        actId: string
        values: any
    }) => {
        const { creator, fileModel, contractId, actId, values } = params
        if (actId) {
            const fileModels = await Promise.all([fetchFileModel(contractId), fetchFileModel(actId)])
            const contractFileModel = { ...fileModel, contractNumber: fileModels[0].contractNumber }
            const actFileModel = { ...fileModel, contractNumber: fileModels[1].contractNumber }

            const editContract = this.editDocument({
                fileId: contractId,
                type: values.counterparty === 'Физ. лицо' ? 'contract' : 'contractB2B',
                title: 'Договор',
                creator: creator,
                fileModel: contractFileModel,
                values,
            })
            const editAct = this.editDocument({
                fileId: actId,
                type: 'act',
                title: 'Акт',
                creator: creator,
                fileModel: actFileModel,
                values,
            })
            return await Promise.all([editContract, editAct])
        } else {
            const fileModels = await Promise.all([fetchFileModel(contractId)])
            const contractFileModel = { ...fileModel, contractNumber: fileModels[0].contractNumber }

            const editContract = this.editDocument({
                fileId: contractId,
                type: values.businessType === 'B2C' ? 'contract' : 'contractB2B',
                title: 'Договор',
                creator: creator,
                fileModel: contractFileModel,
                values,
            })
            return await Promise.all([editContract])
        }
    }

    handleContractStage = async ({ creator, order, paymentType, type, docType }: {
        creator: any;
        order: any,
        paymentType?: PaymentType,
        type?: 'contractB2B' | 'contractMaintenance'|'contractMaintenanceDocx',
        docType?: DocType,
    }) => {
        let contract, contractBill, act

        if (order.primaryInformation.counterparty === 'Юр. лицо') {
            let values = {
                ...order.primaryInformation,
                ...order.accountingAndSelling,
                ...order.agreement,
                division: order.starting?.division,
                cost: order.accountingAndSelling.cost,
                inspection: {
                    ...order?.inspection,
                },
                paymentType,
                commercialOffer: {
                    ...order?.accountingAndSelling?.commercialOffer,
                },
                format: docType?.toLowerCase(),
            }
            if (order.documents?.agreement?.contract) {
                const fileModel = await fetchFileModel(order.documents.agreement.contract)
                contract = await this.editDocument({
                    fileId: order.documents.agreement.contract,
                    type: type || 'contractB2B',
                    title: 'Договор',
                    creator,
                    fileModel,
                    values: { ...values, contractNumber: fileModel.contractNumber },
                    docType,
                })
                return { contract }
            }

            const fileModelLastContract = await fetchDocumentSetting({
                documentType: 'contract',
                businessType: order.businessType,
            })

            contract = await this.saveDocument({
                type: type || 'contractB2B',
                title: 'Договор',
                fileModel: {
                    contractNumber: +fileModelLastContract.documentNumber + 1,
                    businessType: order.businessType,
                },
                creator,
                values: { ...values, contractNumber: +fileModelLastContract.documentNumber + 1 },
                docType,
            })

            return { contract }
        }

        if (order.primaryInformation.counterparty === 'Физ. лицо') {
            const services = order?.accountingAndSelling?.services
            // order.businessType === 'B2C'
            //     ? order.primaryInformation.services
            //     : order.inspectionAndAccounting.services
            // const cost = order.businessType === 'B2C' ? order.primaryInformation.cost : order.selling.cost
            const cost = order?.accountingAndSelling?.cost
            const values = {
                ...order.accountingAndSelling,
                ...order.primaryInformation,
                ...order.agreement,
                services,
                cost,
                paymentType,
                inspection: {
                    ...order?.inspection,
                },
            }
            if (order.documents?.agreement?.contract && order.documents?.agreement?.act) {
                const fileModel = await fetchFileModel(order.documents.agreement?.contract)
                contract = await this.editDocument({
                    fileId: order.documents.agreement.contract,
                    type: 'contract',
                    title: 'Договор',
                    creator,
                    fileModel,
                    values: { ...values, contractNumber: fileModel.contractNumber },
                })
                act = await this.editDocument({
                    fileId: order.documents.agreement.act,
                    type: 'act',
                    title: 'Акт',
                    creator,
                    fileModel,
                    values: { ...values, contractNumber: fileModel.contractNumber },
                })
                return { contract, act }
            }
            const fileModelLastContract = await fetchDocumentSetting({
                documentType: 'contract',
                businessType: order.businessType,
            })
            contract = await this.saveDocument({
                type: 'contract',
                title: 'Договор',
                fileModel: {
                    contractNumber: +fileModelLastContract.documentNumber + 1,
                    businessType: order.businessType,
                },
                creator,
                values: { ...values, contractNumber: +fileModelLastContract.documentNumber + 1 },
            })
            act = await this.saveDocument({
                type: 'act',
                title: 'Акт',
                fileModel: {
                    contractNumber: +fileModelLastContract.documentNumber + 1,
                    businessType: order.businessType,
                },
                creator,
                values: { ...values, contractNumber: +fileModelLastContract.documentNumber + 1 },
            })
            return { contract, act }
        }

        return null
    }

    saveContractBill = async ({ order, creator, paymentType }: {
        creator: any;
        order: any,
        paymentType?: PaymentType
    }) => {
        const values = {
            ...order.primaryInformation,
            ...order.accountingAndSelling,
            ...order.inspection,
            division: order.starting?.division,
            paymentType,
            inspection: {
                ...order?.inspection,
            },
            commercialOffer: {
                ...order?.accountingAndSelling?.commercialOffer,
            },
        }

        if (order.documents?.agreement?.contract) {
            const fileModel = await fetchFileModel(order.documents?.agreement?.contract)
            return await this.editDocument({
                fileId: order.documents?.agreement?.contract,
                type: 'contractBill',
                title: 'Счет-договор',
                creator,
                values: { ...values, contractNumber: fileModel.contractNumber },
                fileModel,
            })
        }

        const fileModelLastContract = await fetchDocumentSetting({
            documentType: 'contract',
            businessType: order.businessType,
        })
        return await this.saveDocument({
            type: 'contractBill',
            title: 'Счет-договор',
            fileModel: {
                contractNumber: +fileModelLastContract.documentNumber + 1,
                businessType: order.businessType,
            },
            creator,
            values: { ...values, contractNumber: +fileModelLastContract.documentNumber + 1 },
        })
    }

    saveNz = async (params: {
        title: string
        type: any
        creator: any
        fileModel: any
        contractId: string
        values: any
    }) => {
        const { title, type, creator, fileModel, contractId, values } = params
        let nzNumber
        if (contractId) {
            const contractModel = await fetchFileModel(contractId)
            nzNumber = contractModel.contractNumber
        } else {
            nzNumber = fetchDate().format('DDMMHm')
        }
        return await this.saveDocument({
            type,
            title,
            creator,
            fileName: 'НЗ__' + values.address + fetchDate(values.cleaningDate.startDate).format('__DD/MM') + '.pdf',
            fileModel: { ...fileModel, nzNumber },
            values: { ...values, nzNumber },
        })
    }

    editDocument = async (params: {
        fileId: string
        type: any
        title: string
        creator: any
        fileModel: any
        values?: any
        docType?: DocType
    }) => {
        const { fileId, type, title, creator, fileModel, values, docType } = params
        const html = await this.generateHtml(type, { ...values, ...fileModel })
        const response = await editFileWithHtml(fileId, html, {
                ...fileModel,
                fileInfo: this.buildFileInfo({ type, creator, title, docType }),
                createdAt: fetchDate(),
            },
            docType,
        )
        return fileId
    }

    updateDocument = async (params: {
        fileId: string
        type: any
        title: string
        creator: any
        fileModel: any
        values: any
    }) => {
        const { fileId, type, title, creator, fileModel, values } = params
        if (fileId) {
            return await this.editDocument({ fileId, type, title, creator, fileModel, values })
        }
        return await this.saveDocument({ type, title, creator, fileModel, values })
    }

    saveFile = (res: Blob, fileModel: FileModelType) => {
        const fileType = this.getDocumentType(fileModel.fileInfo.fileName)
        const format = this.getDocumentFormat(fileModel.fileInfo.fileName)
        const file = new File([res], fileModel.fileInfo.fileName || 'unnamed', { type: fileModel.fileInfo.fileType || fileType + '/' + format })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(file)
        link.download = fileModel.fileInfo.fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(link.href)
    }

    generateHtml = async (document: any, values: any) => {
        return await (templates as any)[document](values)
    }

    buildFileInfo = (params: { type: any; title: string; creator: any; fileName?: string, docType?: DocType }) => {
        const { type, title, creator, fileName, docType } = params
        const date = fetchLocalDate()
        return {
            fileName: (fileName || title || (type === 'contractB2B' ? 'contract' : type)) + '_' + date.format('DD_MM_YY-HH_mm') + '.' + (docType ? docType.toLowerCase() : 'pdf'),
            inputName: type,
            title,
            type,
            creator,
        }
    }

}

export default new DocumentManager()
