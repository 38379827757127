import React from 'react'
import './PopupLoader.scss'
import Logo from '../../../../assets/svg/navbar_logo.svg'

const PopupLoader = () => {
    return (
        <div className='popupLoader'>
            <div className='e-loadholder'>
                <div className='m-loader'>
                    <span className='e-text'><Logo /></span>
                </div>
            </div>
        </div>
    )
}

export default PopupLoader
