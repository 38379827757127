import './Navbar.scss'
import MenuIcon from '@mui/icons-material/Menu'
import { FaArchive } from '@react-icons/all-files/fa/FaArchive'
import { FaCalendarAlt } from '@react-icons/all-files/fa/FaCalendarAlt'
import { FaUserFriends } from '@react-icons/all-files/fa/FaUserFriends'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import AnalyticSvg from '../../../assets/svg/analytic.svg'
import EventsSvg from '../../../assets/svg/events.svg'
import HandRub from '../../../assets/svg/hand-rub.svg'
import Logo from '../../../assets/svg/navbar_logo.svg'
import { SELECT_SIDEBAR_STATUS, selectUser } from '~/redux/selectors/appSlice.selectors'
import { CHANGE_SIDEBAR } from '~/redux/slices/appSlice/appSlice'
import { rules } from '~/shared/order/rules'

const Navbar = () => {
    const dispatch = useDispatch()
    const active = useSelector(SELECT_SIDEBAR_STATUS)
    const user = useSelector(selectUser)
    const footer = undefined

    return (
        <div className='navbar'>
            <NavLink to='/'>
                <Logo className='navbar__logo' />
            </NavLink>
            <div className='navbar__menu'>
                <NavLink to='/'>
                    <FaCalendarAlt size={32} />
                </NavLink>
                <NavLink to='/events'>
                    <EventsSvg style={{ height: '1.75rem', width: '29px' }} />
                </NavLink>
                {user && rules[user.role]?.pages.archive &&
                    <NavLink to='/archive'>
                        <FaArchive size={32} />
                    </NavLink>
                }
                {user && rules[user.role]?.pages.schedule && (!user.division.includes('B2B (ППО)') || user.division.length > 1) && (
                    <NavLink to='/staff'>
                        <FaUserFriends size={32} />
                    </NavLink>
                )}
                {user && rules[user.role]?.pages.salary && (
                    <NavLink to='/salary'>
                        <HandRub style={{ height: '1.75rem' }} />
                    </NavLink>
                )}
                {user && rules[user.role]?.pages.analytic && (
                    <NavLink to='/analytic'>
                        <AnalyticSvg style={{ height: '1.75rem' }} />
                    </NavLink>
                )}
                {footer}
            </div>
            <button
                type='button'
                className={clsx('navbar__add-menu', { active })}
                onClick={() => dispatch(CHANGE_SIDEBAR())}
            >
                <MenuIcon />
            </button>
        </div>
    )
}

export default Navbar
