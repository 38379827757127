import { IRules } from '~/types/types.order'

export const rules: IRules = {
    FOREMAN: {
        sections: {
            primaryInformation: false,
            inspectionAndAccounting: false,
            selling: false,
            starting: false,
            foremanWorkStarting: true,
            workingDayReport: true,
            foremanReportWarehouse: true,
            toolsReport: true,
            orderManagerReport: false,
            returningDocuments: false,
            chiefReport: false,
            chiefReportView: false,
            qualityControl: true,
            orderManagerOrderClosing: false,
            operatorOrderClosing: false,
            orderClosing: false,
            contractWorkReport: false,
        },
        fields: {
            qualityControl: {},
        },
        actions: {
            orderManagerTakeOrder: false,
            orderToDivisionCleaning: false,
        },
        comments: {
            CLASSIC: false,
            orderConfirmation: false,
            visitConfirmation: true,
            servicesConfirmation: true,
            orderClosing: false,
            archiveReason: false,
            archiveOutReason: false,
            contractSigning: false,
        },
        pages: {
            archive: false,
            schedule: false,
            salary: false,
            analytic: false,
            mainPersonnelBase: false,
            workingPersonnelBase: false,
        },
        filter: {
            events: {
                closedSlots: ['cleaning', 're_cleaning', 'maintenance', 'glazing', 'handling'],
                emptySlots: [],
                tasks: ['report'],
            },
            serviceTypes: [],
            employees: [],
            counterparty: [],
            userId: '',
        },
        defaultFilter: {
            events: {
                closedSlots: ['cleaning', 're_cleaning', 'maintenance', 'glazing'],
                emptySlots: [],
                tasks: [],
            },
            employees: [],
            counterparty: [],
            serviceTypes: [],
            userId: '',
        },
        salary: {
            issued: false,
            singlePayment: true,
        },
    },
    OFFICE_MANAGER: {
        sections: {
            primaryInformation: true,
            selling: true,
            inspectionAndAccounting: true,
            starting: false,
            foremanWorkStarting: false,
            workingDayReport: false,
            foremanReportWarehouse: false,
            toolsReport: false,
            orderManagerReport: false,
            returningDocuments: true,
            chiefReport: false,
            chiefReportView: false,
            qualityControl: false,
            orderManagerOrderClosing: false,
            operatorOrderClosing: false,
            orderClosing: false,
            contractWorkReport: false,
        },
        fields: {
            qualityControl: {},
        },
        actions: {
            orderManagerTakeOrder: false,
            orderToDivisionCleaning: false,
        },
        comments: {
            CLASSIC: true,
            orderConfirmation: true,
            visitConfirmation: false,
            servicesConfirmation: false,
            orderClosing: false,
            archiveReason: false,
            archiveOutReason: false,
            contractSigning: true,
            stageTransition: true,
            needChanges: true,
            dateBooking: true,
            toDivision: true,
            dateChanging: true,
            IMPORTANT: true,
        },
        pages: {
            archive: false,
            schedule: false,
            salary: false,
            analytic: false,
            mainPersonnelBase: false,
            workingPersonnelBase: false,
        },
        filter: {
            events: {
                emptySlots: [],
                closedSlots: ['cleaning', 'maintenance', 'glazing', 'handling'],
                tasks: ['agreement', 'report', 'documents_returning'],
            },
            serviceTypes: [],
            employees: [],
            counterparty: [],
            userId: '',
        },
        defaultFilter: {
            events: {
                emptySlots: [],
                closedSlots: ['cleaning', 'maintenance', 'glazing'],
                tasks: [],
            },
            employees: [],
            counterparty: [],
            serviceTypes: [],
            userId: '',
        },
        salary: {
            issued: false,
            singlePayment: false,
        },
    },

    TOOLS_MANAGER: {
        sections: {
            primaryInformation: false,
            selling: false,
            starting: false,
            foremanWorkStarting: false,
            workingDayReport: false,
            foremanReportWarehouse: false,
            toolsReport: true,
            orderManagerReport: false,
            returningDocuments: false,
            chiefReport: false,
            chiefReportView: false,
            qualityControl: false,
            orderManagerOrderClosing: false,
            operatorOrderClosing: false,
            orderClosing: false,
            contractWorkReport: false,
        },
        actions: {
            orderManagerTakeOrder: false,
            orderToDivisionCleaning: false,
        },
        comments: {
            CLASSIC: true,
            orderConfirmation: false,
            visitConfirmation: false,
            servicesConfirmation: false,
            orderClosing: false,
            archiveReason: false,
            archiveOutReason: false,
            contractSigning: true,
        },
        pages: {
            archive: false,
            schedule: false,
            salary: false,
            analytic: false,
            mainPersonnelBase: false,
            workingPersonnelBase: false,
        },
        filter: {
            events: {
                emptySlots: [],
                closedSlots: ['cleaning', 're_cleaning'],
                tasks: ['report'],
            },
            serviceTypes: [],
            employees: [],
            counterparty: [],
            userId: '',
        },
        defaultFilter: {
            events: {
                emptySlots: [],
                closedSlots: ['cleaning', 're_cleaning'],
                tasks: [],
            },
            employees: [],
            counterparty: [],
            serviceTypes: [],
            userId: '',
        },
        salary: {
            issued: false,
            singlePayment: false,
        },
    },

    OPERATOR: {
        sections: {
            primaryInformation: true,
            inspectionAndAccounting: true,
            selling: true,
            starting: true, // Только действие может совершить
            foremanWorkStarting: true,
            workingDayReport: true,
            foremanReportWarehouse: false,
            toolsReport: false,
            orderManagerReport: false,
            returningDocuments: false,
            chiefReport: false,
            chiefReportView: true,
            qualityControl: true,
            orderManagerOrderClosing: true,
            operatorOrderClosing: true,
            orderClosing: false,
            contractWorkReport: false,
        },
        actions: {
            orderToDivisionCleaning: true,
            orderManagerTakeOrder: false,
        },
        comments: {
            CLASSIC: true,
            orderConfirmation: true,
            visitConfirmation: true,
            servicesConfirmation: true,
            orderClosing: true,
            archiveReason: true,
            archiveOutReason: true,
            contractSigning: true,
            stageTransition: true,
            needChanges: true,
            dateBooking: true,
            toDivision: true,
            dateChanging: true,
            IMPORTANT: true,
        },
        pages: {
            archive: true,
            schedule: false,
            salary: false,
            analytic: false,
            mainPersonnelBase: false,
            workingPersonnelBase: false,
        },
        filter: {
            events: {
                emptySlots: ['emptyComplex', 'emptyMaintenance', 'emptyGlazing'],
                closedSlots: ['cleaning', 'maintenance', 'glazing', 'inspection', 'handling'],
                tasks: ['primary', 'inspection', 'accounting_and_selling', 'agreement', 'starting', 'report', 'quality_control', 'final_report'],
            },
            serviceTypes: [],
            employees: [],
            counterparty: [],
            userId: '',
        },
        defaultFilter: {
            events: {
                emptySlots: ['emptyComplex'],
                closedSlots: ['cleaning', 'glazing', 'maintenance', 'inspection'],
                tasks: [],
            },
            employees: [],
            counterparty: [],
            serviceTypes: [],
            userId: '',
        },
        salary: {
            issued: false,
            singlePayment: false,
        },
    },

    ORDER_MANAGER: {
        sections: {
            primaryInformation: true,
            inspectionAndAccounting: true,
            selling: true,
            starting: true,
            foremanWorkStarting: true,
            workingDayReport: true,
            foremanReportWarehouse: true,
            toolsReport: true,
            orderManagerReport: true,
            returningDocuments: false,
            chiefReport: false,
            chiefReportView: true,
            qualityControl: true,
            orderManagerOrderClosing: true,
            operatorOrderClosing: false,
            orderClosing: false,
            contractWorkReport: true,
        },
        actions: {
            orderManagerTakeOrder: true,
            orderToDivisionCleaning: false,
        },
        comments: {
            informationToBeTransmitted: true,
            CLASSIC: true,
            orderConfirmation: true,
            visitConfirmation: true,
            servicesConfirmation: true,
            orderClosing: true,
            archiveReason: true,
            archiveOutReason: true,
            contractSigning: true,
            updatePersonnelInfo: true,
            stageTransition: true,
            needChanges: true,
            dateBooking: true,
            toDivision: true,
            dateChanging: true,
            IMPORTANT: true,
        },
        pages: {
            archive: false,
            schedule: true,
            salary: true,
            analytic: false,
            mainPersonnelBase: false,
            workingPersonnelBase: true,
        },
        filter: {
            events: {
                emptySlots: ['emptyComplex', 'emptyMaintenance', 'emptyGlazing'],
                closedSlots: ['cleaning', 'maintenance', 'glazing', 'inspection', 'handling'],
                tasks: ['primary', 'inspection', 'accounting_and_selling', 'agreement', 'starting', 'report', 'quality_control', 'final_report'],
            },
            serviceTypes: [],
            employees: [],
            counterparty: [],
            userId: '',
        },
        defaultFilter: {
            events: {
                emptySlots: ['emptyComplex'],
                closedSlots: ['cleaning', 'maintenance', 'glazing', 'inspection'],
                tasks: [],
            },
            employees: [],
            counterparty: [],
            serviceTypes: [],
            userId: '',
        },
        salary: {
            issued: false,
            singlePayment: true,
        },
    },

    HR_MANAGER: {
        sections: {
            primaryInformation: false,
            inspectionAndAccounting: false,
            selling: false,
            starting: false,
            foremanWorkStarting: false,
            workingDayReport: false,
            foremanReportWarehouse: false,
            toolsReport: false,
            orderManagerReport: false,
            returningDocuments: false,
            chiefReport: false,
            chiefReportView: false,
            qualityControl: false,
            orderManagerOrderClosing: false,
            operatorOrderClosing: false,
            orderClosing: false,
            contractWorkReport: false,
        },
        actions: {
            orderManagerTakeOrder: false,
            orderToDivisionCleaning: false,
        },
        comments: {
            informationToBeTransmitted: true,
            CLASSIC: true,
            orderConfirmation: false,
            visitConfirmation: false,
            servicesConfirmation: false,
            orderClosing: false,
            archiveReason: false,
            archiveOutReason: false,
            contractSigning: false,
            updatePersonnelInfo: true,
            stageTransition: true,
            needChanges: true,
            dateBooking: true,
            toDivision: true,
            dateChanging: true,
            IMPORTANT: true,
        },
        pages: {
            archive: false,
            schedule: true,
            salary: false,
            analytic: false,
            mainPersonnelBase: true,
            workingPersonnelBase: false,
        },
        filter: {
            events: {
                emptySlots: [],
                closedSlots: [],
                tasks: [],
            },
            serviceTypes: [],
            employees: [],
            counterparty: [],
            userId: '',
        },
        defaultFilter: {
            events: {
                emptySlots: [],
                closedSlots: [],
                tasks: [],
            },
            employees: [],
            counterparty: [],
            serviceTypes: [],
            userId: '',
        },
        salary: {
            issued: false,
            singlePayment: false,
        },
    },

    CHIEF: {
        sections: {
            primaryInformation: true,
            inspectionAndAccounting: true,
            selling: true,
            starting: true,
            foremanWorkStarting: true,
            workingDayReport: true,
            foremanReportWarehouse: true,
            toolsReport: true,
            orderManagerReport: true,
            returningDocuments: true,
            chiefReport: true,
            chiefReportView: true,
            qualityControl: true,
            orderManagerOrderClosing: true,
            operatorOrderClosing: true,
            orderClosing: true,
            contractWorkReport: true,
        },
        actions: {
            orderToDivisionCleaning: true,
            orderManagerTakeOrder: false,
        },
        comments: {
            informationToBeTransmitted: true,
            CLASSIC: true,
            orderConfirmation: true,
            visitConfirmation: true,
            servicesConfirmation: true,
            orderClosing: true,
            archiveReason: true,
            archiveOutReason: true,
            contractSigning: true,
            updatePersonnelInfo: true,
            stageTransition: true,
            needChanges: true,
            dateBooking: true,
            toDivision: true,
            dateChanging: true,
            IMPORTANT: true,
        },
        pages: {
            archive: true,
            schedule: true,
            salary: true,
            analytic: true,
            mainPersonnelBase: true,
            workingPersonnelBase: true,
        },
        filter: {
            events: {
                emptySlots: ['emptyComplex', 'emptyGlazing', 'emptyMaintenance'], // , 'emptyInspectionAndAccounting'
                closedSlots: ['cleaning', 're_cleaning', 'maintenance', 'glazing', 'inspection', 'handling'],
                tasks: ['primary', 'inspection', 'accounting_and_selling', 'agreement', 'starting', 'report', 'quality_control', 'documents_returning', 'final_report'],
            },
            serviceTypes: [],
            employees: [],
            counterparty: [],
            userId: '',
        },
        defaultFilter: {
            events: {
                emptySlots: ['emptyComplex', 'emptyGlazing', 'emptyMaintenance'],
                closedSlots: ['cleaning', 'maintenance', 'glazing', 'inspection'],
                tasks: [],
            },
            employees: [],
            counterparty: [],
            serviceTypes: [],
            userId: '',
        },
        salary: {
            issued: true,
            singlePayment: true,
        },
    },
}
