import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StaffScheduleFilterPersonalGroup
    from './subComponents/StaffScheduleFilterPersonalGroup/StaffScheduleFilterPersonalGroup'
import CustomSelect, { CustomSelectThemes } from '../../../../../../common/inputs/CustomSelect/CustomSelect'
import FilterItems from '../../../../../../common/ReusingComponents/CustomFilter/subComponents/FilterItems/FilterItems'
import MiniCalendar from '../../../../../../navigation/Sidebar/subComponents/MiniCalendar/MiniCalendar'
import { SELECT_SCHEDULE_PERSONNEL } from '~/redux/selectors/appSlice.selectors'
import {
    getActiveDateSelector,
    getFiltersForEvents,
    getIsLoadingSelector,
    getPersonnelBD,
    getScheduleForMiniCalendar,
} from '~/redux/selectors/staffSchedule.selector'
import { CHANGE_SCHEDULE_PERSONNEL, changeFormAction } from '~/redux/slices/appSlice/appSlice'
import { setFilerForEvents } from '~/redux/slices/staffScheduleSlice/staffScheduleSlice'
import './StaffScheduleFilter.scss'

const StaffScheduleFilter = () => {
    const dispatch = useDispatch()
    const personnel = useSelector(SELECT_SCHEDULE_PERSONNEL)
    const personnelDB = useSelector(getPersonnelBD)
    const activeDate = useSelector(getActiveDateSelector)
    const filtersForEvents = useSelector(getFiltersForEvents)
    const isLoading = useSelector(getIsLoadingSelector)
    const scheduleForMiniCalendar = useSelector(getScheduleForMiniCalendar)

    const onSubmit = async () => {
        dispatch(
            changeFormAction({
                action: 'SAVE_SCHEDULE',
                personnelId: personnel,
                fullname: personnelDB.find((pers) => pers.id === personnel)?.fullname,
            }),
        )
    }

    const onDelete = () => {
        dispatch(
            changeFormAction({
                action: 'DELETE_SCHEDULE',
                personnelId: personnel,
                fullname: personnelDB.find((pers) => pers.id === personnel)?.fullname,
            }),
        )
        // Удалить человека из расписания
    }

    const onCancel = () => {
        dispatch(CHANGE_SCHEDULE_PERSONNEL(null))
    }

    return (
        <div className='scheduleFilter'>
            <MiniCalendar
                propsFilteredEvents={scheduleForMiniCalendar}
                propsActiveDate={activeDate}
                activeMiniCalendar={false}
            />
            <div className='scheduleFilter-groups'>
                <FilterItems
                    title='Свободная дата'
                    items={['emptyComplex', 'emptyGlazing', 'emptyMaintenance']} // , 'inspectionAndAccounting'
                    values={filtersForEvents}
                    onClick={(values: string[]) => {
                        dispatch(setFilerForEvents(values))
                    }}
                />
            </div>
            <h5>Изменить расписание сотрудника</h5>
            <CustomSelect
                value={personnel}
                placeholder='Выберите сотрудника'
                onChange={(value) => dispatch(CHANGE_SCHEDULE_PERSONNEL(value))}
                name='scheduleFilter.personnel'
                theme={CustomSelectThemes.GREY}
                disabled={personnelDB.length === 0 && isLoading}
                items={personnelDB.map((pers: any) => ({ value: pers.id, text: pers.fullname }))}
            />
            {personnel && (
                <p>
                    Выбран сотрудник <span>{personnelDB.find((pers: any) => pers.id === personnel)?.fullname}</span>
                </p>
            )}
            {personnel && (
                <div className='scheduleFilter__buttons'>
                    <button className='button '
                        onClick={onSubmit}
                        disabled={isLoading}
                    >
                        Применить
                    </button>
                    <button className='button filterDelete'
                        onClick={onDelete}
                        disabled={isLoading}
                    >
                        Удалить расписание
                    </button>
                    <button className='button button_bc-grey'
                        onClick={onCancel}
                        disabled={isLoading}
                    >
                        Отмена
                    </button>
                </div>
            )}
            {!personnel && personnelDB && (
                <div className='scheduleFilter__personnelList'>
                    <h5 className='scheduleFilter__personnelList-title'>
                        Руководители
                    </h5>
                    <StaffScheduleFilterPersonalGroup
                        propsRole={'ORDER_MANAGER'}
                        personnelDB={personnelDB}
                    />
                    <h5 className='scheduleFilter__personnelList-title'>
                        Менеджеры по клинингу
                    </h5>
                    <StaffScheduleFilterPersonalGroup
                        propsRole={'FOREMAN'}
                        personnelDB={personnelDB}
                    />
                </div>
            )}
        </div>
    )
}

export default StaffScheduleFilter
