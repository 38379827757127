import { fetchPath } from '~/API/lib/path/path'
import { fetchAllPersonnelParams, PersonnelResponse } from '~/API/personnelApi/personnel.types'
import { rtkApi } from '~/shared/api/rtkApi'
import { getGlobalDivision } from '~/shared/util/localStorage'

const path = fetchPath('personnel')

const personnelApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAllPersonnel: build.query<PersonnelResponse[], fetchAllPersonnelParams>({
            query: ({
                group,
                workStatus,
                division,
            }) => ({
                method: 'GET',
                url: path,
                params: { group, workStatus, division: division || getGlobalDivision() },
            }),
            providesTags: ['Personnel'],
        }),
        fetchPersonnelByIds: build.mutation<PersonnelResponse[], string[]>({
            query: (personnelIds) => ({
                method: 'POST',
                url: path + '/ids',
                body: personnelIds,
            }),
        }),
    }),
})

export const {
    useFetchAllPersonnelQuery,
    useLazyFetchAllPersonnelQuery,
    useFetchPersonnelByIdsMutation,
} = personnelApi
