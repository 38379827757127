export const createInputName = (watchingInputsValues: string[] | string[][], mode?: string) => {
    const flatItem = watchingInputsValues.flat()
    switch (mode) {
        case 'boolean':
            return flatItem.map((item) => !!item).join(' ')
        case 'ARRAY': {
            const resString = flatItem.every((name) => !name) ?
                'default' :
                flatItem.map((name) => name === undefined || name === null ? 'undefined' : name).join(' ')
            return resString.trim()
        }
        case 'ARRAY_MANY':
        case 'ARRAY_NOT': {
            return flatItem.every((name) => !name) ?
                    ['default'] :
                flatItem.map((name) => name === undefined || name === null ? 'undefined' : name)
        }
        default:
            return (flatItem.some((name) => name !== undefined) ? flatItem.join(' ') : 'default').trim()
    }
}
