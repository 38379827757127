import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import Modal from 'react-modal'
import BackButton from '~/components/common/ReusingComponents/BackButton/BackButton'
import {
    CheckTypes,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableHeader/subComponents/TabsTableCheckIcon/TabsTableCheckIcon'
import { TabsTablePageType } from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import CheckIconsTabs
    from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTabs/subComponents/CheckIconsTabs/CheckIconsTabs'
import MobileTitle from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTitle/MobileTitle'
import MobileTitleButton
    from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTitleButton/MobileTitleButton'
import './MobileServicesVerification.scss'
import ServicesVerificationSwiper
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileServicesVerification/subComponents/ServicesVerificationSwiper/ServicesVerificationSwiper'
import { TaskItem } from '~/components/pages/KP/kp.types'
import { get } from '~/shared/util/get'

interface MobileServicesVerificationProps {
    title: string
    name: string
    disabled?: boolean
    checkIcons?: {
        inputName: string,
        checkType: CheckTypes,
    },
}

const MobileServicesVerification = (props: MobileServicesVerificationProps) => {
    const {
        title,
        name,
        disabled,
        checkIcons,
    } = props

    const isAdditionalServices = name.includes('additionalServices')

    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
    const [listModalOpen, setListModalOpen] = useState<boolean>(false)

    const {
        control,
        formState,
    } = useFormContext()

    // if isAdditionalServices services = list of pages else services = list of rows
    const { fields: services } = useFieldArray({ control, name })

    const pagesErrors = useMemo(() => get(formState.errors, name), [formState])

    const tabs = useMemo(() => !isAdditionalServices ?
            (services as TabsTablePageType<TaskItem>[]).map((page, index) => ({
                title: page.title,
                isError: !!pagesErrors?.[index],
            })) :
        null, [services, formState, isAdditionalServices])

    const displayNone = useMemo(() => ({
        overlay: {
            display: !listModalOpen ? 'none' : '',
        },
        content: {
            padding: 0,
        },
    }), [listModalOpen])

    const ServicesBody = isAdditionalServices ?
            (
                <div className={clsx('mobileServicesVerification__list', 'mobileServicesVerification__list--active')}>
                    {(services as Array<TaskItem & Record<'id', string>>).map((item, itemIndex: number) => (
                        <Controller
                            key={itemIndex}
                            name={`${name}.${itemIndex}.status`}
                            control={control}
                            rules={{ validate: (value) => value != null }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => listModalOpen ? (
                                <ServicesVerificationSwiper
                                    title={item.name}
                                    description={item.description}
                                    price={item.price}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    disabled={disabled}
                                />
                            ) : <></>}
                        />
                    ))}
                </div>
            ) :
            (
                (services as TabsTablePageType<TaskItem>[]).map((page, pageIndex: number) => {
                    return (
                        <div
                            key={'mobileServicesVerification__list' + pageIndex}
                            className={clsx('mobileServicesVerification__list', {
                                'mobileServicesVerification__list--active': activeTabIndex === pageIndex,
                            })}
                        >
                            {page.items.map((item, itemIndex: number) => (
                                <Controller
                                    key={`${name}.${pageIndex}.items.${itemIndex}.status`}
                                    name={`${name}.${pageIndex}.items.${itemIndex}.status`}
                                    control={control}
                                    rules={{ validate: (value) => value != null }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => {
                                        if (listModalOpen && activeTabIndex === pageIndex) {
                                            return (
                                                <ServicesVerificationSwiper
                                                    title={item.name}
                                                    description={item.description}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                    disabled={disabled}
                                                />
                                            )
                                        }
                                        return <></>
                                    }}
                                />
                            ))}
                        </div>
                    )
                })
            )

    return (
        <>
            <MobileTitleButton
                title={title}
                onClick={() => setListModalOpen(true)}
                isError={!!pagesErrors}
            />
            <Modal
                isOpen={true}
                onRequestClose={() => setListModalOpen(false)}
                style={displayNone}
                className='mobileServicesVerification__modal'
            >
                <BackButton callback={() => setListModalOpen(false)} />
                <MobileTitle title={title} />
                {tabs && <CheckIconsTabs
                    activeTabIndex={activeTabIndex}
                    tabs={tabs}
                    setActiveTabIndex={setActiveTabIndex}
                    name={name}
                    control={control}
                    checkIcons={checkIcons}
                />}
                <div className='mobileServicesVerification__listWrapper'>
                    {ServicesBody}
                </div>
            </Modal>
        </>
    )
}

export default MobileServicesVerification
