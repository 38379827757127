import { StageGroup } from '~/API/orderApi/types/order.types'
import { Role, User } from '~/API/userApi/user.types'
import { checkHiddenStages } from '~/components/pages/Home/subComponents/OrderCalendar/OrderCalendarFilterFunctions'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import {
    BuildEmptySlots,
    BuildEmptySlotsType,
    Cleaning,
    CleaningSlot,
    ScheduleObj,
} from '~/redux/slices/orderSlice/types/orderSlice.type'
import { StageManager, VERSIONS } from '~/shared/order/StageManager'
import { countCalendarDays, fetchDate, fetchLocalDate } from '~/shared/util/currentTime'
import deepMerge from '~/shared/util/merge'
import { separateCityInAddress } from '~/shared/util/strings'
import { orderTypes } from '~/shared/util/types'

export const defaultDateRange = {
    start: fetchLocalDate().subtract(2, 'month').startOf('month'),
    end: fetchLocalDate().add(2, 'month').endOf('month'),
}

const stagesForOfficeManager = [
    'TO_OFFICE_MANAGER_CONTRACT_CREATING',
    'PREPAYMENT_APPROVING',
    'RETURNING_DOCUMENTS',
    'PAYMENT_RECEIVING',
]

type additionalStageGroup = 'REPORT_WITH_CHECK_FOREMAN'

const stagesWithCheckUser: Record<StageGroup | additionalStageGroup, Map<StageType, Role[]>> = {
    ACCOUNTING_AND_SELLING: new Map([
        ['REPEAT_CLIENT_STARTING', ['ORDER_MANAGER', 'OPERATOR', 'CHIEF']],
        ['COMMERCIAL_OFFER_APPROVING', ['ORDER_MANAGER', 'OPERATOR', 'CHIEF']],
        ['NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING', ['ORDER_MANAGER', 'OPERATOR', 'CHIEF']],
        ['DATE_BOOKING', ['ORDER_MANAGER', 'OPERATOR', 'CHIEF']],
    ]),
    AGREEMENT: new Map([
        ['CONTRACT_SIGNING', ['ORDER_MANAGER', 'OPERATOR', 'CHIEF']],
        ['NEED_CHANGES_TO_CONTRACT_SIGNING', ['ORDER_MANAGER', 'OPERATOR', 'CHIEF']],
    ]),
    CLOSED: new Map(),
    INSPECTION: new Map(),
    INSPECTION_AND_ACCOUNTING: new Map(),
    PRIMARY: new Map(),
    QUALITY_CONTROL: new Map(),
    REPORT: new Map([
        ['NEED_CHANGES_TO_WORKING_PLAN_APPROVING', ['CHIEF', 'ORDER_MANAGER']],
        ['ADDITIONAL_SERVICES_CREATING', ['ORDER_MANAGER', 'OPERATOR']],
        ['CONTRACT_WORK_REPORT_FILLING', ['CHIEF', 'ORDER_MANAGER']],
        ['CONTRACTOR_BILLING', ['CHIEF', 'OFFICE_MANAGER']],
        ['MANAGER_CHECKING', ['CHIEF', 'ORDER_MANAGER']],
        ['DEFECTS_CORRECTING', ['CHIEF', 'ORDER_MANAGER']],
        ['QUALITY_CONTROLLING', ['CHIEF', 'OPERATOR']],
        ['DOCUMENTS_RETURNING', ['CHIEF', 'OFFICE_MANAGER']],
        ['FINAL_REPORT', ['CHIEF']],
    ]),
    REPORT_WITH_CHECK_FOREMAN: new Map([
        ['DEPARTURE_CONFIRMATION', ['CHIEF', 'ORDER_MANAGER', 'FOREMAN']],
        ['SERVICES_VERIFICATION', ['CHIEF', 'ORDER_MANAGER', 'FOREMAN']],
        ['OBJECT_REPORT_FILLING', ['CHIEF', 'ORDER_MANAGER', 'FOREMAN']],
        ['SERVICE_TABLE_FILLING', ['CHIEF', 'ORDER_MANAGER', 'FOREMAN']],
        ['WORKING_DAY_ENDING', ['CHIEF', 'ORDER_MANAGER', 'FOREMAN']],
        ['TEAM_REPORT_FILLING', ['CHIEF', 'ORDER_MANAGER', 'FOREMAN']],
        ['WAREHOUSE_GOING', ['CHIEF', 'ORDER_MANAGER', 'FOREMAN']],
        ['INVENTORY_ITEMS_RETURNING', ['CHIEF', 'ORDER_MANAGER', 'FOREMAN']],
    ]),
    SELLING: new Map(),
    STARTING: new Map([]),
    UNKNOWN: new Map(),
}

export const formEventsForUser = (actions: Cleaning[], user: User) => {
    return actions.reduce((accum: CleaningSlot[], _order: Cleaning, index) => {
        const events = [...accum]
        const order = { ..._order, address: separateCityInAddress(_order.address) }

        // Если заявка в архиве, то выводить только для руководителя
        if (order.archived) {
            if (user.role === 'CHIEF') {
                return [
                    ...accum,
                    {
                        ...order,
                        events: [],
                        stageGroup: StageManager.defineStageGroup(order.stageName),
                        start: fetchDate(order.startDate).toDate(),
                        end: fetchDate(order.endDate).toDate(),
                        status: 'completed',
                    },
                ] as CleaningSlot[]
            }
            return accum
        }

        if (StageManager.defineStageGroup(order.stageName) === 'primary') {
            // Если Заявка только создана, видит ее только создавший
            if (order.responsibleUsers.includes(user.id)) {
                events.push({
                    ...order,
                    events: [],
                    stageGroup: StageManager.defineStageGroup(order.stageName),
                    start: fetchDate(order.startDate).toDate(),
                    end: fetchDate(order.endDate).toDate(),
                    status: 'process',
                })
            }
            return events
        }

        if (stagesForOfficeManager.includes(order.stageName) && user.role === 'OFFICE_MANAGER') {
            events.push({
                ...order,
                events: [],
                stageGroup: order.section,
                start: fetchDate(order.startDate).toDate(),
                end: fetchDate(order.endDate).toDate(),
                status: 'process',
            } as CleaningSlot)
            return events
        }

        if (stagesWithCheckUser.ACCOUNTING_AND_SELLING.has(order.stageName as StageType)) {
            const stageObj = stagesWithCheckUser.ACCOUNTING_AND_SELLING.get(order.stageName as StageType)
            if (stageObj?.includes(user.role)) {
                /** Условие, что ORDER_MANAGER может быть ответственным только если он создатель заявки */
                if (user.role === 'ORDER_MANAGER') {
                    if (order.orderCreator === order.accountingAndSellingResponsibleUser) {
                        events.push({
                            ...order,
                            events: [],
                            stageGroup: StageManager.defineStageGroup(order.stageName),
                            start: fetchDate(order.startDate).toDate(),
                            end: fetchDate(order.endDate).toDate(),
                            status: 'process',
                        } as CleaningSlot)
                    }
                } else {
                    events.push({
                        ...order,
                        events: [],
                        stageGroup: StageManager.defineStageGroup(order.stageName),
                        start: fetchDate(order.startDate).toDate(),
                        end: fetchDate(order.endDate).toDate(),
                        status: 'process',
                    } as CleaningSlot)
                }
            }
            return events
        }

        if (stagesWithCheckUser.AGREEMENT.has(order.stageName as StageType)) {
            const stageObj = stagesWithCheckUser.AGREEMENT.get(order.stageName as StageType)
            if (stageObj?.includes(user.role)) {
                events.push({
                    ...order,
                    events: [],
                    stageGroup: StageManager.defineStageGroup(order.stageName),
                    start: fetchDate(order.startDate).toDate(),
                    end: fetchDate(order.endDate).toDate(),
                    status: 'process',
                } as CleaningSlot)
                return events
            }
            return events
        }

        if (stagesWithCheckUser.REPORT_WITH_CHECK_FOREMAN.has(order.stageName as StageType)) {
            const stageObj = stagesWithCheckUser.REPORT_WITH_CHECK_FOREMAN.get(order.stageName as StageType)
            if (stageObj?.includes(user.role) && order.responsibleUsers.includes(user.id)) {
                events.push({
                    ...order,
                    events: [],
                    stageGroup: StageManager.defineStageGroup(order.stageName),
                    start: fetchDate(order.startDate).toDate(),
                    end: fetchDate(order.endDate).toDate(),
                    status: 'process',
                } as CleaningSlot)
                return events
            }
            return events
        }

        if (stagesWithCheckUser.REPORT.has(order.stageName as StageType)) {
            const stageObj = stagesWithCheckUser.REPORT.get(order.stageName as StageType)
            if (stageObj?.includes(user.role)) {
                events.push({
                    ...order,
                    events: [],
                    stageGroup: StageManager.defineStageGroup(order.stageName),
                    start: fetchDate(order.startDate).toDate(),
                    end: fetchDate(order.endDate).toDate(),
                    status: 'process',
                } as CleaningSlot)
                return events
            }
            return events
        }

        if (StageManager.checkAvailableStageByRole(order.stageName, user.role, VERSIONS.V1) ||
            StageManager.checkAvailableStageByRole(order.stageName, user.role, VERSIONS.V2)) {
            if ((user.role === 'FOREMAN' || user.role === 'TOOLS_MANAGER' || user.role === 'ORDER_MANAGER') && order.responsibleUsers.includes(user.id)) {
                events.push({
                    ...order,
                    events: [],
                    stageGroup: StageManager.defineStageGroup(order.stageName),
                    start: fetchDate(order.startDate).toDate(),
                    end: fetchDate(order.endDate).toDate(),
                    status: 'process',
                } as CleaningSlot)
                return events
            }
            events.push({
                ...order,
                events: [],
                stageGroup: StageManager.defineStageGroup(order.stageName),
                start: fetchDate(order.startDate).toDate(),
                end: fetchDate(order.endDate).toDate(),
                status: 'process',
            } as CleaningSlot)
            return events
        }
        return events
    }, [])
}

export const buildCleaningSlots = (cleanings: Cleaning[], user: User) =>
    cleanings.reduce((accum: CleaningSlot[], cleaning: Cleaning) => {
        if (
            cleaning.cleaningDate &&
            (user.role !== 'FOREMAN' || cleaning.responsibleUsers.includes(user.id)) &&
            (user.role !== 'OFFICE_MANAGER' || (cleaning.organization && cleaning.organization !== ''))
        ) {
            const newCleaning: CleaningSlot[] = []
            if (!cleaning.archived) {
                newCleaning.push({
                    ...cleaning,
                    address: separateCityInAddress(cleaning.address),
                    stageName: cleaning.stageName,
                    stageGroup: cleaning.section === 'inspection' ? 'inspection' : 'cleaning',
                    type: defineServiceType(cleaning.serviceType),
                    start: fetchDate(cleaning.section === 'inspection' ? cleaning.inspectionDate : cleaning.cleaningDate.startDate).toDate(),
                    end: fetchDate(cleaning.section === 'inspection' ? cleaning.inspectionDate : cleaning.cleaningDate.endDate).toDate(),
                    status: cleaning.section === 'inspection' ? '' : 'process',
                })
            }

            if (
                cleaning?.inspectionDate &&
                cleaning.section !== 'inspection' &&
                cleaning.stageName !== 'NEED_CHANGES_TO_ORDER_CREATING' &&
                cleaning.stageName !== 'ORDER_CREATING'
            ) {
                newCleaning.push({
                    ...cleaning,
                    status: cleaning.section === 'inspection' ? '' : 'process',
                    stageName: cleaning.stageName,
                    type: defineServiceType(cleaning.serviceType),
                    start: fetchDate(cleaning?.inspectionDate).toDate(),
                    end: fetchDate(cleaning?.inspectionDate).toDate(),
                    stageGroup: 'inspection',
                    isInspectionInPast: true,
                })
            }

            return [
                ...accum,
                ...newCleaning,
            ] as CleaningSlot[]
        }

        return accum
    }, [])

interface BuildEmptySlotsParams {
    schedule: ScheduleObj[]
    cleanings: Cleaning[]
    personnelBD: User[]
    oldAvailableSlotsBySchedule: Record<string, BuildEmptySlots>
    oldRemovedCleanings: Record<string, string[]>
    oldCleanings: Cleaning[]
}

export const buildEmptySlots = (params: BuildEmptySlotsParams) => {
    const {
        schedule,
        cleanings,
        personnelBD,
        oldAvailableSlotsBySchedule,
        oldRemovedCleanings,
        oldCleanings,
    } = params
    const availableSlotsBySchedule: Map<string, BuildEmptySlots> = new Map()
    schedule.forEach((scheduleDay) => {
        scheduleDay.personnel
            .filter((p) => p.status === 'WORKING')
            .reduce((accum: User[], p) => {
                const user = personnelBD.find((u) => u.id === p.personnelId)
                if (user?.role === 'FOREMAN' || user?.role === 'ORDER_MANAGER') {
                    return [...accum, user]
                }
                return accum
            }, [])
            .forEach((user, index) => {
                const type = defineSlotTypeForUser(user)
                const newMap = new Map(availableSlotsBySchedule.get(fetchDate(scheduleDay.date).format('YYYY-MM-DD') + transformEmptySlotNameBySpecialization(type)))
                newMap.set(index, {
                    date: scheduleDay.date,
                    orderType: type,
                    type: 'empty',
                    stageGroup: transformEmptySlotNameBySpecialization(type),
                    address: orderTypes[type],
                    start: fetchDate(scheduleDay.date).startOf('day').toDate(),
                    end: fetchDate(scheduleDay.date).endOf('day').toDate(),
                    amount: 1,
                    key: fetchDate(scheduleDay.date).format('YYYY-MM-DD') + transformEmptySlotNameBySpecialization(type), // + index.toString(),
                    index,
                })

                availableSlotsBySchedule.set(
                    fetchDate(scheduleDay.date).format('YYYY-MM-DD') + transformEmptySlotNameBySpecialization(type),
                    newMap,
                )
            })
    })
    const mergedSlots = new Map(Object.entries(deepMerge(Object.fromEntries(availableSlotsBySchedule), oldAvailableSlotsBySchedule)))
    const mergedCleanings = [...cleanings, ...oldCleanings]
    const removedCleanings = new Map(Object.entries(oldRemovedCleanings))
    mergedCleanings.forEach((cleaning) => {
        if (cleaning.cleaningDate &&
            cleaning.section !== 'inspection' &&
            !checkHiddenStages(cleaning.stageName, !!cleaning.isDateBooked, !!cleaning.isInspectionInPast) &&
            cleaning.stageName !== 'ORDER_CREATING' &&
            !cleaning.archived
        ) {
            new Array(countCalendarDays(fetchDate(cleaning.cleaningDate.startDate), fetchDate(cleaning.cleaningDate.endDate)))
                .fill(cleaning)
                .map((cleaning: Cleaning, index) => ({
                    ...cleaning,
                    startDate: fetchDate(cleaning.cleaningDate.startDate).add(index, 'day').toDate(),
                    endDate: fetchDate(cleaning.cleaningDate.startDate).add(index, 'day').toDate(),
                }))
                .forEach((cleaning, index) => {
                    const key = fetchDate(cleaning.startDate).format('YYYY-MM-DD') + 'empty' +
                        defineServiceType(cleaning.serviceType).substring(0, 1).toUpperCase() +
                        defineServiceType(cleaning.serviceType).substring(1)
                    if (mergedSlots.has(key)) {
                        [...mergedSlots.get(key)!.values()]
                            .some((value) => {
                                if (!removedCleanings.get(cleaning.id)?.includes(value.date.toString() + value.stageGroup.toString())) {
                                    removedCleanings.set(cleaning.id, [...(removedCleanings.get(cleaning.id) || []), value.date.toString() + value.stageGroup.toString()])
                                    const newMap = new Map(mergedSlots.get(key))
                                    newMap.delete(value.index)
                                    if (newMap.size) {
                                        mergedSlots.set(key, newMap)
                                    } else {
                                        mergedSlots.delete(key)
                                    }
                                    return true
                                }
                            })
                    }
                })
        }
    })
    const availableSlotsByScheduleUniq: Map<string, BuildEmptySlotsType> = new Map()
    for (const scheduleDays of mergedSlots.values()) {
        [...scheduleDays.values()].some((scheduleDay) => {
            if (!availableSlotsByScheduleUniq.has(scheduleDay.date.toString() + scheduleDay.stageGroup.toString())) {
                availableSlotsByScheduleUniq.set(
                    scheduleDay.date.toString() + scheduleDay.stageGroup.toString(),
                    scheduleDay,
                )
            } else {
                const currentUniqSlot = availableSlotsByScheduleUniq.get(scheduleDay.date.toString() + scheduleDay.stageGroup.toString())
                if (currentUniqSlot) {
                    availableSlotsByScheduleUniq.set(
                        scheduleDay.date.toString() + scheduleDay.stageGroup.toString(),
                        {
                            ...currentUniqSlot,
                            amount: currentUniqSlot.amount + 1,
                        },
                    )
                }
            }
        })
    }

    return {
        availableSlots: Object.fromEntries(mergedSlots),
        availableSlotsUniq: Object.fromEntries(availableSlotsByScheduleUniq),
        removedCleanings: Object.fromEntries(removedCleanings),
    }
}


const defineSlotTypeForUser = (user: User) => {
    // if (user.role === 'ORDER_MANAGER') {
    //     return 'inspectionAndAccounting'
    // }
    return user.specialization || 'complex'
}

// TODO udpate this in orderTypes util
const defineServiceType = (type: string) => {
    switch (type) {
        case 'Поддерживающая уборка':
            return 'maintenance'
        case 'Остекление':
            return 'glazing'
        case 'Ежедневная уборка (обслуживание)':
            return 'handling'
        default:
            return 'complex'
    }
}

const transformEmptySlotNameBySpecialization = (specialization: string) => {
    return 'empty' + specialization.substring(0, 1).toUpperCase() + specialization.substring(1)
}
