import { RussianNameProcessor } from '~/shared/util/names'

export const formatRussianPost = (post: string) => {
    return post
        .trim()
        .split(' ')
        .filter((item) => item)
        .map((item) => RussianNameProcessor.word(item, 'm', 'lastName', 'genitive'))
        .join(' ')
}
