import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'

export const managerOrderClose = {
    division: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Подразделение',
        name: 'division',
        className: 'input100',
        type: 'select',
        items: [
            { value: 'B2C', text: 'B2C' },
            { value: 'B2B (ПРР)', text: 'B2B (ПРР)' },
        ],
    }),
    responsibleManager: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Ответственный руководитель',
        name: 'responsibleManager',
        className: 'input50 input_inspectManager',
        type: 'user',
    }),
    reportDate: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Дата отчета',
        name: 'reportDate',
        className: 'input33',
        singleDate: true,
        type: 'date',
    }),
    orderManagerComment: (disabled: boolean) => ({
        disabled: disabled,
        name: 'orderManagerComment',
        title: 'Комментарий руководителя',
        type: 'text',
        multiline: true,
    }),
    additionalExpanses: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Доп. расходы',
        name: 'additionalExpanses',
        className: 'input20',
        type: 'text',
        mask: InputMask.NUMBER,
    }),
    mistakesFiles: (disabled: boolean) => ({
        disabled: disabled,
        name: 'mistakesFiles',
        title: 'Контент доработок',
        max: 15,
        type: 'documentList',
        button: 'Добавить файл',
        required: false,
        allowedFileTypes: ['image', 'video', 'application'],
    }),
}
