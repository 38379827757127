import React, { useEffect, useState } from 'react'
import { ThemeContext, themes } from './ThemeContext'

const ThemeContextWrapper = ({ children }: { children?: React.ReactNode }) => {
    const [theme, setTheme] = useState<themes>(themes.dark)

    function changeTheme(theme: themes) {
        setTheme(theme)
    }

    useEffect(() => {
        switch (theme) {
            case themes.light:
                document.body.classList.add('dark-content')
                break
            case themes.dark:
            default:
                document.body.classList.remove('dark-content')
                break
        }
    }, [theme])

    return (
        <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeContextWrapper
