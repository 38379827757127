import { Dayjs } from 'dayjs'
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFetchOrderQuery } from '~/API/orderApi/order'
import { OrderInterface, Team } from '~/API/orderApi/types/order.types'
import { useFetchPersonnelByIdsMutation, useLazyFetchAllPersonnelQuery } from '~/API/personnelApi/personnel'
import { PersonnelResponse } from '~/API/personnelApi/personnel.types'
import { useFetchSalariesByDateQuery } from '~/API/salaryApi/salary'
import {
    getFilteredAndSortedPersonnel,
} from '~/components/common/formConstructor/formCommonComponents/PersonnelTable/lib/getFilteredAndSortedPersonnel'
import CustomSelect, { CustomSelectThemes } from '~/components/common/inputs/CustomSelect/CustomSelect'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import {
    SingleSalaryCell,
} from '~/components/common/ReusingComponents/TabsTable/hocs/withPersonnel/subComponents/SingleSalaryCell'
import {
    TabsTableCellProps,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTablePage/subComponents/TabsTableCell/TabsTableCell'
import TabsTableSubtotalFooter
    from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableSubtotalFooter/TabsTableSubtotalFooter'
import { TabsTableProps } from '~/components/common/ReusingComponents/TabsTable/TabsTable'
import {
    SinglePaymentModal,
} from '~/components/pages/Salary/subComponents/SalaryCalendar/subComponents/SalaryNavbar/SinglePaymentModal/SinglePaymentModal'
import { selectSalaryPayment, selectSalarySinglePayment } from '~/redux/selectors/salarySlice.selectors'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { setSalaryActiveDateRange, updateSalaryFulfilled } from '~/redux/slices/salarySlice/salarySlice'
import { SalaryWorkers } from '~/redux/slices/salarySlice/salarySlice.type'
import { useAppDispatch } from '~/redux/store'
import { fetchDate, getFirstDayOfWeek, getLastDayOfWeek } from '~/shared/util/currentTime'
import { sortByAllJobPositions } from '~/shared/util/filter'
import { formatRUB } from '~/shared/util/formatNumberWithIntlOptions'

export interface WorkersAdditionalDataParams {
    date: Date | Dayjs
    orderId: string
    address: string
}

const withPersonnel = (TabsTable: FC<TabsTableProps>): FC<TabsTableProps> => {
    const WrappedComponent = (props: TabsTableProps) => {
        const {
            name,
            tabIndex = 0,
        } = props

        const { id: orderId } = useParams()
        const dispatch = useAppDispatch()
        const {
            getValues,
            setValue,
            control,
        } = useFormContext<OrderInterface>()

        const watchFields = useWatch({ control, name: name as keyof OrderInterface })

        const isSingleSalaryCache = useRef(new Map())

        const singleSalaries = useSelector(selectSalarySinglePayment)
        const salary = useSelector(selectSalaryPayment)

        const [total, setTotal] = useState(0)
        const [isLoading, setLoading] = useState(false)
        const [isPersonnelLoading, setPersonnelLoading] = useState(false)
        const [personnel, setPersonnel] = useState<PersonnelResponse[]>([])
        const [workersData, setWorkersData] = useState<SalaryWorkers[]>([])
        const [workersAdditionalData, setWorkersAdditionalData] = useState<WorkersAdditionalDataParams>({} as WorkersAdditionalDataParams)
        const [isSinglePaymentModal, setSinglePaymentModal] = useState(false)

        const division = getValues().foremanReport.division
        const actualTeam = getValues().foremanReport?.daysReport[tabIndex].actualTeam
        const {
            data: salaryData,
            isSuccess: isSalarySuccess,
            isFetching: isSalaryLoading,
        } = useFetchSalariesByDateQuery({
            startDateRange: fetchDate(getValues().foremanReport?.daysReport[tabIndex].cleaningDate).startOf('day'),
            endDateRange: fetchDate(getValues().foremanReport?.daysReport[tabIndex].cleaningDate).endOf('day'),
            jobPosition: 'Клинер',
            division,
        }, { refetchOnFocus: false })
        const { isFetching: isOrderLoading } = useFetchOrderQuery(orderId!)

        const [fetchAllPersonnel] = useLazyFetchAllPersonnelQuery()
        const [fetchPersonnelByIds] = useFetchPersonnelByIdsMutation()

        const filteredAndSortedPersonnel = useMemo(() => {
            return getFilteredAndSortedPersonnel(personnel, actualTeam)
        }, [personnel, actualTeam])

        const setTotalValue = () => {
            if (actualTeam) {
                const total = actualTeam.reduce((acc: { sum: number, count: number }, employee) => {
                    acc.sum += Number(employee.addSalary ?? 0) + Number(employee.salary ?? 0)
                    acc.count++
                    return acc
                }, { sum: 0, count: 0 })
                setTotal(total.sum)
                setValue(`foremanReport.daysReport.${tabIndex}.actualCleanerNumber`, total.count)
                setValue(`foremanReport.daysReport.${tabIndex}.actualEmployeesSalary`, total.sum)
            }
        }

        const isSingleSalary = useCallback((props: TabsTableCellProps) => {
            if (!singleSalaries.length) return false
            const personnelId = getValues(`${name}.${props.rowIndex}.personnelId` as keyof OrderInterface)
            const id = getValues().id

            const key = `${tabIndex}-${personnelId}-${id}`
            if (isSingleSalaryCache.current.has(key)) {
                return isSingleSalaryCache.current.get(key)
            }

            const result = singleSalaries.some((singleSalary) =>
                singleSalary.personnelId === personnelId &&
                singleSalary.cleaningId === id,
            )

            isSingleSalaryCache.current.set(key, result)
            return result
        }, [singleSalaries, getValues, name])

        useEffect(() => {
            if (isSingleSalaryCache.current) {
                isSingleSalaryCache.current = new Map()
            }
        }, [salaryData])

        useEffect(() => {
            if (isSalarySuccess && salaryData) {
                dispatch(updateSalaryFulfilled({ salary: salaryData }))
            }
        }, [salaryData, isSalarySuccess])

        useEffect(() => {
            if (personnel) {
                setWorkersAdditionalData(() => ({
                    date: fetchDate(getValues().foremanReport.daysReport[tabIndex]?.cleaningDate || getValues().foremanReport.daysReport[tabIndex]?.workingDate),
                    address: getValues().primaryInformation.address,
                    orderId: getValues().id,
                }))
                setWorkersData(() => {
                    const cleaningDay = getValues().foremanReport.daysReport[tabIndex]
                    const res = cleaningDay.actualTeam
                        .filter((worker) => {
                            return worker.status === 'WORKING' || worker.status === 'BUFFER'
                        })
                        .filter((worker) => {
                            if (salary.length) {
                                return !salary.find((salaryObject) => salaryObject.orderId === getValues().id && salaryObject.personnelId === worker.personnelId)
                            }
                            return true
                        })
                        .reduce((accum: SalaryWorkers[], worker) => {
                            if (!worker.jobPosition.toLowerCase().includes('бригадир')) {
                                accum.push({
                                    id: worker.personnelId,
                                    jobPosition: worker.jobPosition,
                                    name: personnel.find((person) => person.id === worker.personnelId)?.fullname ?? '',
                                    cleanings: [{
                                        salaryAmount: worker.salary,
                                        addSalary: +worker.addSalary,
                                        cleaningDate: cleaningDay?.cleaningDate,
                                        date: fetchDate(cleaningDay?.workingDate).format('DD / dd'),
                                        id: getValues().id,
                                        petrolExpanses: cleaningDay.actualExpansesPetrol,
                                        qualityRating: '', // cleaningDay.qualityRating, todo разобраться почему их нет в типах
                                        serviceRating: '', // cleaningDay.serviceRating,
                                        comment: '', // cleaningDay.comment,
                                        clientComment: '', // cleaningDay.clientComment,
                                        rate: worker.rate,
                                        address: getValues().primaryInformation.address,
                                        salary: null,
                                    }],
                                })
                            }
                            return accum
                        }, [])
                    return res
                })
            }
        }, [personnel, salary])

        useEffect(() => {
            if (getValues().foremanReport?.daysReport[tabIndex]?.actualTeam) {
                const currentDayReport = getValues().foremanReport?.daysReport[tabIndex]
                setPersonnelLoading(true)
                fetchAllPersonnel({
                    workStatus: 'WORKING',
                    division: getValues().starting?.division || getValues().businessType,
                }, true).unwrap()
                    .then((allPersonnel) => {
                        if (currentDayReport.actualTeam.find((p: Team) => p.personnelId && !allPersonnel.some((person) => person.id === p.personnelId))) {
                            fetchPersonnelByIds(currentDayReport.actualTeam
                                .filter((p: Team) => !allPersonnel.some((person) => person.id === p.personnelId))
                                .map((p: Team) => p.personnelId),
                            ).unwrap()
                                .then((personnel) => {
                                    setPersonnel([...allPersonnel, ...personnel])
                                })
                                .catch(() => {
                                    dispatch(SHOW_NOTICE({
                                        type: 'error',
                                        message: 'Произошла ошибка при получении персонала',
                                    }))
                                })
                                .finally(() => {
                                    setPersonnelLoading(false)
                                })
                        } else {
                            setPersonnel(allPersonnel)
                            setPersonnelLoading(false)
                        }
                    })
                    .catch(() => {
                        dispatch(SHOW_NOTICE({ type: 'error', message: 'Произошла ошибка при получении персонала' }))
                    })
            }
            return () => {
                dispatch(setSalaryActiveDateRange({
                    start: getFirstDayOfWeek(),
                    end: getLastDayOfWeek(),
                }))
            }
        }, [division, actualTeam])

        useEffect(() => {
            setLoading(isSalaryLoading || isOrderLoading || isPersonnelLoading)
        }, [isSalaryLoading, isOrderLoading, isPersonnelLoading])

        useEffect(() => {
            setTotalValue()
        }, [watchFields])

        return <TabsTable
            {...props}
            footer={!isLoading ? <TabsTableSubtotalFooter value={formatRUB(total)} text='Подытог' /> : <></>}
            isLoading={isLoading}
            onRowChange={setTotalValue}
            customTools={(
                <>
                    <CustomButton
                        onClick={() => setSinglePaymentModal(true)}
                        theme={CustomButtonThemes.OUTLINE}
                        title='Единоразовая выплата'
                        className='tabsTableHeaderButton'
                        disabled={!props.disabled}
                    />
                    {isSinglePaymentModal && (
                        <SinglePaymentModal
                            onClose={() => setSinglePaymentModal(false)}
                            workersData={workersData}
                            division={division}
                        />
                    )}
                </>
            )}
            customCell={[
                {
                    name: 'salary',
                    component: (props) => (
                        <SingleSalaryCell
                            {...props}
                            workersAdditionalData={workersAdditionalData}
                        />
                    ),
                    rule: isSingleSalary,
                },
                {
                    name: 'personnelId',
                    component: ({ input, updateRow, rowIndex, value, disabled }) => (
                        <CustomSelect
                            name={input.name}
                            onChange={(value) => {
                                const employee = filteredAndSortedPersonnel
                                    .find((pers) => pers.id === value)
                                updateRow(rowIndex, {
                                    personnelId: employee?.id,
                                    name: employee?.fullname,
                                    jobPosition: ((employee?.jobPosition ?? '') + ' ' + (employee?.rank ?? '')).trim(),
                                    salary: employee?.salary,
                                    status: employee?.workStatus ?? 'WORKING',
                                    note: '',
                                })
                            }}
                            value={personnel.find((item) => item.id === value)?.fullname ?? value}
                            items={
                                input.items ||
                                filteredAndSortedPersonnel
                                    .sort(sortByAllJobPositions)
                                    .map((elem: any) => ({
                                        value: elem.id,
                                        text: elem.fullname,
                                    }))
                            }
                            placeholder={input.placeholder}
                            search={input?.search}
                            theme={CustomSelectThemes.TABLE}
                            disabled={disabled}
                            innerClassName='tabsTableCell__select'
                        />
                    ),
                    rule: ({ input }: TabsTableCellProps) => input.name === 'personnelId',
                },
            ]}
        />
    }

    return memo(WrappedComponent)
}

export default withPersonnel
