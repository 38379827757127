import clsx from 'clsx'
import React, { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useSaveSingleSalaryMutation } from '~/API/salaryApi/salary'
import { Division } from '~/API/userApi/user.types'
import CustomFileInputSingle, {
    CustomFileInputSingleTheme,
} from '~/components/common/inputs/CustomFileInputSingle/CustomFileInputSingle'
import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import CustomSelect from '~/components/common/inputs/CustomSelect/CustomSelect'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { CustomPopup } from '~/components/common/ReusingComponents/CustomPopup/CustomPopup'
import { selectSalarySinglePayment } from '~/redux/selectors/salarySlice.selectors'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { SalaryCleaning, SalaryWorkers } from '~/redux/slices/salarySlice/salarySlice.type'
import { fetchDate } from '~/shared/util/currentTime'
import { sortByString } from '~/shared/util/filter'
import './SinglePaymentModal.scss'

export interface SalaryCleaningWithIndex extends SalaryCleaning {
    index: string
}

interface SinglePaymentModalProps {
    onClose: () => void
    workersData: SalaryWorkers[]
    division?: Division
}

export const SinglePaymentModal = (props: SinglePaymentModalProps) => {
    const {
        onClose,
        workersData,
        division,
    } = props
    const dispatch = useDispatch()
    const [updateSingleSalary] = useSaveSingleSalaryMutation()

    const {
        control,
        setValue,
        clearErrors,
        handleSubmit,
    } = useForm()

    const singleSalaries = useSelector(selectSalarySinglePayment)

    const [disabled, setDisabled] = useState<boolean>(false)
    const [personnelId, setPersonnelId] = useState<string | null>(null)
    const [cleanings, setCleanings] = useState<SalaryCleaningWithIndex[]>([])
    const [cleaningInfo, setCleaningInfo] = useState<SalaryCleaningWithIndex | null>(null)

    const handlePersonnel = (personnelId: string) => {
        setPersonnelId(personnelId)
        setValue('singlePaymentModal.date', '')
        const currentWorker = workersData.find((worker) => worker.id === personnelId)
        if (currentWorker) {
            const cleanings = currentWorker.cleanings
                .filter((cleaning) => {
                    return !singleSalaries.some((salary) =>
                        salary.cleaningStartDate + '_' + salary.cleaningId === cleaning.cleaningDate + '_' + cleaning.id &&
                        salary.personnelId === personnelId,
                    )
                })
                .map((cleaning) => {
                    return {
                        ...cleaning,
                        index: cleaning.cleaningDate + '_' + cleaning.id,
                    }
                })
                .filter((cleaning) => !cleaning.salary?.payed)
            setCleanings(cleanings)
            if (cleanings.length === 1) {
                handleCleaning(cleanings[0].index, cleanings)
                setValue('singlePaymentModal.date', cleanings[0].index)
                clearErrors('singlePaymentModal.date')
                return
            }
        }
        setCleaningInfo(null)
    }

    const handleCleaning = (value: string, currentCleanings?: any[]) => {
        let cleaning
        if (currentCleanings) {
            cleaning = currentCleanings.find((cleaning) => cleaning.index === value)
        } else {
            cleaning = cleanings.find((cleaning) => cleaning.index === value)
        }
        setCleaningInfo(cleaning)
        setValue('singlePaymentModal.payment', cleaning ? +cleaning.salaryAmount + +cleaning.addSalary : 0)
        clearErrors('singlePaymentModal.payment')
    }

    const onSubmit = handleSubmit(
        async (data) => {
            setDisabled(true)
            if (
                singleSalaries.some(
                    (salary) => {
                        return salary.cleaningStartDate + '_' + salary.cleaningId === cleaningInfo?.index &&
                            salary.personnelId === personnelId
                    },
                )
            ) {
                onClose()
                dispatch(SHOW_NOTICE({ type: 'error', message: 'Данная разовая выплата уже была добавлена' }))
                return
            }

            if (personnelId && cleaningInfo) {
                await updateSingleSalary({
                    salaryType: 'SINGLE',
                    personnelId: personnelId,
                    date: cleaningInfo.cleaningDate,
                    fileId: data.singlePaymentModal.file,
                    orderId: cleaningInfo.id,
                }).unwrap()
                    .then(() => {
                        // dispatch(updateSalaryThunk({ division }))
                        onClose()
                    })
                    .finally(() =>
                        setDisabled(false),
                    )
            }
        },
        () => {
            dispatch(SHOW_NOTICE({ type: 'error', message: 'Необходимо заполнить все поля' }))
        })

    const currentWorkersData = useMemo(() => {
        return workersData
            .filter((worker) => worker.cleanings.some((cleaning) => !cleaning.salary?.payed && !cleaning.salary?.issued))
            .map((cleaning) => ({
                value: cleaning.id,
                text: cleaning.name,
            }))
            .sort((a, b) => sortByString(a, b, 'text'))
    }, [workersData])

    const currentDatesData = useMemo(() => {
        return cleanings
            .map((cleaning) => ({
                value: cleaning.index,
                text: `${fetchDate(cleaning.cleaningDate).format('DD.MM')} - ${cleaning.address ? cleaning.address : 'без заявки'}`,
            }))
            .sort((a, b) => sortByString(a, b, 'text'))
    }, [cleanings])

    return (
        <CustomPopup
            onClose={onClose}
            title={{ title: 'Единоразовая выплата' }}
            body={{
                data: [
                    (
                        <Controller
                            key={'singlePaymentModal.personnelId'}
                            name={'singlePaymentModal.personnelId'}
                            rules={{ required: true }}
                            control={control}
                            defaultValue={personnelId}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <CustomSelect
                                    key={'singlePaymentModal.personnelId.input'}
                                    items={currentWorkersData}
                                    value={value}
                                    onChange={(value) => {
                                        handlePersonnel(value)
                                        onChange(value)
                                    }}
                                    error={error}
                                    title='Имя сотрудника'
                                    disabled={!currentWorkersData.length}
                                    search={true}
                                />
                            )}
                        />
                    ), (
                        <Controller
                            key='singlePaymentModal.date'
                            name='singlePaymentModal.date'
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <CustomSelect
                                    key='singlePaymentModal.date.input'
                                    items={currentDatesData}
                                    disabled={!personnelId || !cleanings.length}
                                    value={value}
                                    onChange={(value) => {
                                        handleCleaning(value)
                                        onChange(value)
                                    }}
                                    error={error}
                                    title='Дата выплаты'
                                />
                            )}
                        />
                    ), (
                        <Controller
                            key='singlePaymentModal.payment'
                            name='singlePaymentModal.payment'
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <CustomSelect
                                    key='singlePaymentModal.payment.input'
                                    items={cleanings?.map((cleaning) => ({
                                        value: cleaning.id,
                                        text: +cleaning.salaryAmount + +cleaning.addSalary,
                                    }))}
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    disabled={true}
                                    title='Сумма выплаты'
                                    mask={InputMask.COST}
                                    placeholder='0'
                                />
                            )}
                        />
                    ), (
                        <Controller
                            key='singlePaymentModal.file'
                            name='singlePaymentModal.file'
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <CustomFileInputSingle
                                    key='singlePaymentModal.file.input'
                                    name='singlePaymentModal.file.input'
                                    className={clsx('single-payment-modal__file', { 'single-payment-modal__file--disabled': !personnelId || !cleanings.length })}
                                    title='Загрузить файл'
                                    onChange={onChange}
                                    fileId={value}
                                    error={error}
                                    theme={CustomFileInputSingleTheme.BUTTON}
                                    allowedFileTypes={['application', 'image']}
                                    disabled={!personnelId || !cleanings.length}
                                />
                            )}
                        />
                    ),
                ],
            }}
            footer={[
                (
                    <CustomButton
                        onClick={onClose}
                        key='SinglePaymentModal-CancelBtn'
                        title='Отмена'
                        theme={CustomButtonThemes.OUTLINE}
                        disabled={disabled}
                    />
                ), (
                    <CustomButton
                        onClick={onSubmit}
                        key='SinglePaymentModal-SubmitBtn'
                        title='Выплатить'
                        theme={CustomButtonThemes.COLOR}
                        disabled={disabled}
                    />
                ),
            ]}
        />
    )
}
