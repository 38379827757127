import { AxiosPromise } from 'axios'
import { instance } from '~/API/lib/axios/axios'
import { fetchPath } from '~/API/lib/path/path'
import { SendErrorParams } from '~/API/notifierApi/notifier.types'

const path = fetchPath('notifier')

export const sendError = (params: SendErrorParams): AxiosPromise<unknown> => {
    const {
        text,
    } = params
    return instance.post(
        path + '/send-error',
        {
            text,
        },
    )
}
