import { fetchDate } from '~/shared/util/currentTime'

interface CommentListImportantProps {
    message: string
    date: Date
}

export const CommentListImportant = (props: CommentListImportantProps) => {
    const {
        message,
        date,
    } = props

    return (
        <div className='comments__body__container'>
            <div className='comments__body__important'>
                <span
                    className='comments__body__important__text'>
                    {message}
                </span>
            </div>
            <span className='comments__body__date'>
                {fetchDate(date).format('HH:mm')}
            </span>
        </div>
    )
}
