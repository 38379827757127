import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { rules } from '~/shared/order/rules'
import './MobileDashboard.scss'
import { mobileDashboardRouteConfig } from './mobileDashboardConfig'

const MobileDashboard = () => {
    const user = useSelector(selectUser)
    const dashboardBody = useRef(null)

    return (
        <div ref={dashboardBody} className='mobileDashboard'>
            <Routes>
                {Object.values(mobileDashboardRouteConfig).map((
                    {
                        path,
                        element,
                        rulesCheck,
                    }, index) => {
                    if (rulesCheck && user && rules[user.role]?.pages[rulesCheck] === false) {
                        return null
                    }

                    return (
                        <Route
                            key={`routeIndex${index}__path${path}`}
                            path={path}
                            element={element}
                        />
                    )
                })}
            </Routes>
        </div>
    )
}

export default MobileDashboard
