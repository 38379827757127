// @ts-ignore
import { nzBack as nzBackSingleDay } from './nz'
// @ts-ignore
import { nzBackMultipleDay } from './nzMultipleDay'
import { nzFrontNew, NzFrontValues } from '~/shared/templates/mainTemplates/nzNew'


export interface NzFullValues {
    nzFrontValues: NzFrontValues,
    nzBackValues: any,
    isMultipleDays: boolean
}

export const nzFull = async (values: NzFullValues) => {
    const nzFront = await nzFrontNew(values.nzFrontValues)
    console.log(values.isMultipleDays)
    const nzBack = values.isMultipleDays ? await nzBackMultipleDay(values.nzBackValues) : await nzBackSingleDay(values.nzBackValues)

    return {
        nzFront,
        nzBack,
    }
}
