import { RootState } from '../store'
import { FilteredEvent } from '~/types/types.order'

export const selectEvents = (state: RootState) => state.order.events
export const selectFilter = (state: RootState) => state.order.filter
export const selectFilteredEvents = (state: RootState): FilteredEvent[] => state.order.filteredEvents
export const selectActiveEvent = (state: RootState) => state.order.activeEvent
export const selectOrderActiveDate = (state: RootState) => state.order.activeDate
export const selectOrderActiveDateTarget = (state: RootState) => state.order.activeDateTarget
export const selectActiveOrder = (state: RootState) => state.order.activeOrder
export const selectActiveOrderId = (state: RootState) => state.order.activeOrder?.id || ''
export const selectOrderLoader = (state: RootState) => state.order.orderLoader
export const selectEventLoader = (state: RootState) => state.order.eventLoader
export const selectClearFormState = (state: RootState) => state.order.formState
export const selectMenuType = (state: RootState) => state.order.menuType
export const selectPrices = (state: RootState) => state.order.prices
export const selectDivision = (state: RootState) => state.order.division
export const getDateRangeSelector = (state: RootState) => state.order.dateRange
export const getEventsWithCustomFilter = (state: RootState) => state.order.eventsWithCustomFilter
export const getEventsWithCustomFilterUniq = (state: RootState) => state.order.eventsWithCustomFilterUniq
export const getStaffFormMenu = (state: RootState) => state.order.staffFormMenu
export const selectOrderError = (state: RootState) => state.order.error
export const selectSectionName = (state: RootState) => state.order.sectionName
export const selectStageName = (state: RootState) => state.order.stageName
export const selectIsServicesSet = (state: RootState) => state.order.isServicesSet
export const selectIsServiceDescriptionSet = (state: RootState) => state.order.isServiceDescriptionsSet
export const selectActiveMonthDate = (state: RootState) => state.order.activeMonthDate
export const selectClientInfoEvent = (state: RootState) => state.order.eventEmitters.CLIENT_INFO_CONTRACTOR_VALIDATION_FAILED
