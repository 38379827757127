import '../../App.scss'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from '../../providers/ErrorBoundary'
import NotificationWrapper from '~/components/common/popups/PopupNotification/NotificationWrapper'
import PopupWrapper from '~/components/common/popups/PopupWrapper/PopupWrapper'
import PopupLoader from '~/components/common/preloaders/PopupLoader/PopupLoader'

export const AppLayout = () => {
    return (
        <ErrorBoundary>
            <div className='App'>
                <Suspense fallback={<PopupLoader />}>
                    <Outlet />
                    <PopupWrapper />
                    <NotificationWrapper />
                </Suspense>
            </div>
        </ErrorBoundary>
    )
}
