import { FC, memo, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ICustomInput } from '~/components/common/inputs/CustomInput/CustomInput'
import { IRulesComments } from '~/types/types.order'

const withComment = (CustomInput: FC<ICustomInput>, commentType: keyof IRulesComments): FC<ICustomInput> => {
    const WrappedComponent = (props: ICustomInput) => {
        const { setValue, getValues } = useFormContext()

        useEffect(() => {
            if (commentType && setValue && !getValues('commentType')) {
                setValue('commentType', commentType)
            }
        }, [getValues, setValue])

        return <CustomInput {...props} />
    }

    return memo(WrappedComponent)
}

export default withComment
