import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaymentType } from '~/components/pages/KP/kp.types'

interface SmetaSliceStateFot {
    days: number,
    shifts: number,
    total: number,
}

interface SmetaSliceState {
    fot: SmetaSliceStateFot,
    wageFundSectionTotal: number
    marginality: number,
    logistics: number,
    tmc: number,
    amortization: number,
    otherExpenses: number,
    handleSubmit: Function
    paymentType: PaymentType
    totalPrice: number
}

const smetaSliceInitialState: SmetaSliceState = {
    marginality: 50,
    fot: {
        days: 0,
        shifts: 0,
        total: 0,
    },
    wageFundSectionTotal: 0,
    logistics: 0,
    tmc: 0,
    amortization: 0,
    otherExpenses: 0,
    handleSubmit: () => {
    },
    paymentType: 'р/с (НДС)',
    totalPrice: 0,
}

export const smetaSlice = createSlice({
    name: 'smeta',
    initialState: smetaSliceInitialState,
    reducers: {
        setMarginality: (state, action: PayloadAction<number>) => {
            state.marginality = action.payload
        },
        setFot: (state, action: PayloadAction<SmetaSliceStateFot>) => {
            state.fot = action.payload
        },
        setLogistics: (state, action: PayloadAction<number>) => {
            state.logistics = action.payload
        },
        setOtherExpenses: (state, action: PayloadAction<number>) => {
            state.otherExpenses = action.payload
        },
        setTmc: (state, action: PayloadAction<number>) => {
            state.tmc = action.payload
        },
        setAmortization: (state, action: PayloadAction<number>) => {
            state.amortization = action.payload
        },
        setHandleSubmit: (state, action: PayloadAction<Function>) => {
            state.handleSubmit = action.payload
        },
        setPaymentType: (state, action: PayloadAction<PaymentType>) => {
            state.paymentType = action.payload
        },
        setTotalPrice: (state, action: PayloadAction<number>) => {
            state.totalPrice = action.payload
        },
        setWageFundSectionTotal: (state, action: PayloadAction<number>) => {
            state.wageFundSectionTotal = action.payload
        },
        resetSmetaSlice: (state) => {
            return smetaSliceInitialState
        },
    },
})

export const {
    setMarginality,
    setFot,
    setLogistics,
    setOtherExpenses,
    setTmc,
    setAmortization,
    setHandleSubmit,
    setPaymentType,
    setTotalPrice,
    setWageFundSectionTotal,
    resetSmetaSlice,
} = smetaSlice.actions

export default smetaSlice.reducer
