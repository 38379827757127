import { OrderInterface } from '~/API/orderApi/types/order.types'
import { User } from '~/API/userApi/user.types'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import { rules } from '~/shared/order/rules'
import { StageManager } from '~/shared/order/StageManager'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { returningDocuments } from '~/shared/templates/inputTemplates/returningDocuments'
import { additionalInputs } from '~/shared/templates/inputTemplates/v2/additionalInputs'

export const DOCUMENTS_RETURNING_ACTIONS = {
    mainActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'PAYMENT_CONFIRMED',
                text: 'Подтвердить оплату',
                icon: 'repeat',
            },
            {
                value: 'DOCUMENTS_RETURNED',
                text: 'Подгрузить документы',
                icon: 'repeat',
            },
        ],
        version: 'v2',
    }),
    withDocumentsActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'PAYMENT_CONFIRMED',
                text: 'Подтвердить оплату',
                icon: 'repeat',
            },
        ],
        version: 'v2',
    }),
    withPaymentActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'DOCUMENTS_RETURNED',
                text: 'Подгрузить документы',
                icon: 'repeat',
            },
            {
                value: 'FINAL_REPORT',
                text: 'Отправить на финальный отчет',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
    withClosedPaymentActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'DOCUMENTS_RETURNED',
                text: 'Подгрузить документы',
                icon: 'repeat',
            },
        ],
        version: 'v2',
    }),
    withPaymentDocumentsActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'FINAL_REPORT',
                text: 'Отправить на финальный отчет',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
}

interface DOCUMENTS_RETURNING_PARAMS {
    order: OrderInterface
    reportDate: Date
    stages: string[]
    currentStage: StageType
    user: User
    archived: boolean
    nextStageName: string
}

export const DOCUMENTS_RETURNING = (params: DOCUMENTS_RETURNING_PARAMS) => {
    const {
        order,
        reportDate,
        stages,
        currentStage,
        user,
        archived,
        nextStageName,
    } = params
    const newInputs = []
    const documentInputs = []
    if (rules[user?.role]?.sections.returningDocuments) {
        const disabled = stages.includes('DOCUMENTS_RETURNING')
        const availableAction = StageManager.checkAvailableStageByRole('DOCUMENTS_RETURNING', user.role)

        if (reportDate) {
            newInputs.push([
                additionalInputs.division(true),
                additionalInputs.responsibleUserId(true, 'returningDocuments.division', 'responsibleManager'),
                additionalInputs.reportDate(true),
            ])
        } else {
            newInputs.push([
                additionalInputs.division(true),
            ])
        }

        if ((!(order?.agreement?.prepayment === 100 && order?.agreement?.prepaymentType === 'Внесена') && (nextStageName === 'PAYMENT_CONFIRMED' || nextStageName === 'FINAL_REPORT')) ||
            order.returningDocuments?.actualPayment) {
            newInputs.push([
                returningDocuments.actualPayment(order?.returningDocuments?.isPaymentConfirmed),
                returningDocuments.paymentPhoto(order?.returningDocuments?.isPaymentConfirmed),
            ])
        }

        if (nextStageName === 'DOCUMENTS_RETURNED' || (order.documents?.returningDocuments?.contractPhoto || order.documents?.returningDocuments?.actPhoto || order.documents?.returningDocuments?.updPhoto)) {
            if ((order.documents.agreement?.contract || order.documents.agreement?.customContract) && order?.agreement.contractType !== 'Счет договор') {
                documentInputs.push(returningDocuments.contractPhoto(!disabled))
            }
            if (order.accountingAndSelling.commercialOffer.paymentType === 'р/с (без НДС)') {
                documentInputs.push(returningDocuments.actPhoto(!disabled))
            }
            if (order.accountingAndSelling.commercialOffer.paymentType === 'р/с (НДС)') {
                documentInputs.push(returningDocuments.updPhoto(!disabled))
            }
        }

        newInputs.push(documentInputs)
        newInputs.push([returningDocuments.otherDocuments(!disabled)])

        if (availableAction) {
            const action = fetchFormAction({
                order,
                archived,
                section: 'RETURNING_DOCUMENTS',
                user,
                currentStage,
            })
            if (action) {
                newInputs.push([action])
            }
        }
    }
    return {
        title: 'Возврат документов и постоплата',
        name: 'returningDocuments',
        inputs: [...newInputs],
        documentInputs,
    }
}
