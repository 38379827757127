import { useRestoreOrderFormArchiveMutation } from '~/API/orderApi/order'
import { setArchiveIsLoading, setTotalCountArchiveData } from '~/redux/slices/ArchiveSlice/ArchiveSlice'
import { FETCH_ORDER_REQUESTED } from '~/redux/slices/orderSlice/orderSlice'
import { closeUnzipPopup } from '~/redux/slices/popupSlice/popupSlice'
import { useAppDispatch } from '~/redux/store'

export const useRestoreFromArchive = () => {
    const dispatch = useAppDispatch()
    const [restoreOrderFormArchive] = useRestoreOrderFormArchiveMutation()

    return async (id: string, reason: string) => {
        dispatch(setArchiveIsLoading(true))
        dispatch(closeUnzipPopup())
        dispatch(FETCH_ORDER_REQUESTED())
        dispatch(setTotalCountArchiveData(0))
        try {
            await restoreOrderFormArchive({ id, reason }).unwrap()
        } catch (e) {
            throw new Error('Ошибка при восстановлении заявки из архива')
        } finally {
            dispatch(setArchiveIsLoading(false))
        }
    }
}
