import clsx from 'clsx'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import CustomFileInput from '../CustomFileInputGroup/CustomFileInput/CustomFileInput'
import { AllowedFileTypes } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { OPEN_LIGHTBOX } from '~/redux/slices/appSlice/appSlice'
import './CustomFileInputSingle.scss'

export enum CustomFileInputSingleTheme {
    DEFAULT = 'default',
    BUTTON = 'button',
    SALARY = 'salary',
}

export interface CustomFileInputSingleProps {
    accept?: string
    button?: string
    disabled?: boolean
    error?: any
    fileId?: string
    fileType?: string
    name: string
    onChange: (value: any) => void
    title?: string
    type?: string
    style?: any
    fileRules?: any
    theme?: CustomFileInputSingleTheme
    allowedFileTypes?: AllowedFileTypes[]
    className?: string
    parentClassName?: string
    roles?: string[]
}

const CustomFileInputSingle = (props: CustomFileInputSingleProps) => {
    const dispatch = useDispatch()

    const handleClick = (fileId: string) => {
        dispatch(OPEN_LIGHTBOX({ sources: [props.fileId || fileId], sourceIndex: 0 }))
    }

    return (
        <div className={clsx('customFileInputSingle', props?.parentClassName)}>
            <CustomFileInput
                {...props}
                onClick={handleClick}
            />
        </div>
    )
}

export default CustomFileInputSingle

