import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaymentType } from '~/components/pages/KP/kp.types'

interface KpSliceState {
    paymentType: PaymentType
    handleSubmit: Function
    handleControl: Function
    handleKPSetter: (key: string, value: any) => void
    handleKPGetter: (key: string) => any
    handleKPTrigger: Function
    handleSave: Function
    kpDiscount: string
}

const kpSliceInitialState: KpSliceState = {
    paymentType: 'р/с (НДС)',
    handleSubmit: () => {
    },
    handleKPTrigger: () => {
    },
    handleControl: () => {
    },
    handleSave: () => {
    },
    handleKPSetter: () => {
    },
    handleKPGetter: () => {
    },
    kpDiscount: '0',
}

const kpSlice = createSlice({
    name: 'kp',
    initialState: kpSliceInitialState,
    reducers: {
        setPaymentType: (state, action: PayloadAction<PaymentType>) => {
            state.paymentType = action.payload
        },
        setHandleSubmit: (state, action: PayloadAction<Function>) => {
            state.handleSubmit = action.payload
        },
        setHandleKPTrigger: (state, action: PayloadAction<Function>) => {
            state.handleKPTrigger = action.payload
        },
        setHandleControl: (state, action: PayloadAction<Function>) => {
            state.handleControl = action.payload
        },
        setHandleSave: (state, action: PayloadAction<Function>) => {
            state.handleSave = action.payload
        },
        setKPSetter: (state, action: PayloadAction<KpSliceState['handleKPSetter']>) => {
            state.handleKPSetter = action.payload
        },
        setKPGetter: (state, action: PayloadAction<KpSliceState['handleKPGetter']>) => {
            state.handleKPGetter = action.payload
        },
        setKpDiscount: (state, action: PayloadAction<string>) => {
            state.kpDiscount = action.payload
        },
    },
})

export const {
    setPaymentType,
    setHandleSubmit,
    setHandleKPTrigger,
    setHandleControl,
    setKpDiscount,
    setHandleSave,
    setKPSetter,
    setKPGetter,
} = kpSlice.actions

export default kpSlice.reducer
