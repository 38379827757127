import { Tabs, Tab, Popover } from '@mui/material'
import React, { ChangeEvent, useContext, useState } from 'react'
import { FieldErrors, useFormContext } from 'react-hook-form'
import { TabsTableContext } from '../../../../TabsTableContext'
import TabsTableHeaderPopover from '../TabsTableHeaderPopover/TabsTableHeaderPopover'
import TabsTableTab from '../TabsTableTab/TabsTableTab'
import { get } from '~/shared/util/get'

import './TabsTableHeaderTabs.scss'

const TabsTableHeaderTabs = () => {
    const {
        activeTabIndex,
        setActiveTabIndex,
        pages,
        baseName,
    } = useContext(TabsTableContext)

    const { formState: { errors } } = useFormContext()

    const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null)
    const [popoverIndex, setPopoverIndex] = useState<number | null>(null)

    const tabHandler = (event: ChangeEvent<object>, index: number) => {
        event.preventDefault()
        setActiveTabIndex(index)
    }

    const openPopover = (event: React.MouseEvent<SVGElement, MouseEvent>, popoverIndex: number) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
        setPopoverIndex(popoverIndex)
    }

    const closePopover = () => {
        setAnchorEl(null)
        setPopoverIndex(null)
    }

    const getErrorIndexes = (): string[] => {
        const message = get(errors, baseName)?.root?.message

        if (message && typeof message === 'string') {
            return message.split(',')
        }

        return []
    }

    return (
        <>
            <Tabs
                value={activeTabIndex}
                onChange={tabHandler}
                variant='scrollable'
                scrollButtons={false}
            >
                {pages.map((page, index) => (
                    <Tab
                        key={page.id}
                        label={<TabsTableTab
                            index={index}
                            title={page.title}
                            openPopover={openPopover}
                            isError={!!(get(errors, baseName) as FieldErrors)?.[index] || getErrorIndexes().includes(index.toString())}
                        />}
                    />
                ))}
            </Tabs>
            <Popover
                className='tabsTableHeaderTabs__popover'
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <TabsTableHeaderPopover
                    popoverIndex={popoverIndex}
                    setPopoverIndex={setPopoverIndex}
                    closePopover={closePopover}
                />
            </Popover>
        </>

    )
}

export default TabsTableHeaderTabs
