export const managerOrderReport = {
    division: (disabled: boolean) => ({
        disabled,
        title: 'Подразделение',
        name: 'division',
        className: 'input100',
        type: 'select',
        items: [
            { value: 'B2C', text: 'B2C' },
            { value: 'B2B (ПРР)', text: 'B2B (ПРР)' },
        ],
    }),
    responsibleManager: (disabled: boolean) => ({
        disabled,
        title: 'Ответственный руководитель',
        name: 'responsibleManager',
        className: 'input50 input_inspectManager',
        type: 'user',
    }),
    reportDate: (disabled: boolean) => ({
        disabled,
        title: 'Дата отчета',
        name: 'reportDate',
        className: 'input33',
        singleDate: true,
        type: 'date',
    }),
    orderManagerComment: (disabled: boolean) => ({
        disabled,
        name: 'orderManagerComment',
        title: 'Комментарий руководителя',
        type: 'text',
        multiline: true,
    }),
    nzFrontPhoto: (disabled?: boolean) => ({
        disabled,
        name: 'nzFrontPhoto',
        title: 'Наряд заказ(задача)',
        type: 'document',
        button: 'Загрузить фото',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image', 'application'],
    }),
    nzBackPhoto: (disabled?: boolean) => ({
        disabled,
        name: 'nzBackPhoto',
        title: 'Наряд заказ(результат)',
        type: 'document',
        button: 'Загрузить фото',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image', 'application'],
    }),
}
