import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import './CustomLightbox.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect, useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import DocumentViewer from './DocumentViewer/DocumentViewer'
import { FslightboxController } from '~/redux/slices/appSlice/appSlice.types'

interface CustomLightboxProps {
    controller: FslightboxController,
}

interface SampleArrowProps {
    onClick?: () => void,
}

function SampleNextArrow({ onClick }: SampleArrowProps) {
    return (
        <button className={'customLightbox__button customLightbox__nextArrow'} onClick={onClick}>
            <ChevronRightIcon /></button>
    )
}

function SamplePrevArrow({ onClick }: SampleArrowProps) {
    return (
        <button className={'customLightbox__button customLightbox__prevArrow'} onClick={onClick}><ChevronLeftIcon />
        </button>
    )
}

interface CustomPagingProps {
    index: number,
    size: number,
}

function CustomPaging({ index, size }: CustomPagingProps) {
    return <div className={'customLightbox__button customLightbox__paging'}>{index + 1}/{size}</div>
}

const CustomLightbox = ({ controller }: CustomLightboxProps) => {
    const [active, setActive] = useState(false)
    const [localController, setLocalController] = useState<any>(null)
    const [activeSlide, setActiveSlide] = useState(0)
    const slider = useRef<any>(null)

    useEffect(() => {
        if (controller.toggler !== undefined && controller.toggler !== null) {
            setLocalController(controller)
            slider.current?.slickGoTo(controller.sourceIndex, true)
            setActive(true)
        }
    }, [controller])

    useEffect(() => {
        if (!active) {
            setLocalController(null)
        }
    }, [active])


    const settings: Settings = {
        dots: controller?.sources?.length > 1,
        infinite: controller?.sources?.length > 1,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: 'progressive',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dotsClass: 'slick-dots customLightbox__dots',
    }

    return (
        <div className='customLightbox popupWrapper popupWrapper_black'
            style={{ display: active && controller.sources ? 'block' : 'none' }}>
            <CustomPaging index={activeSlide} size={controller.sources.length} />
            <button type={'button'} className={'customLightbox__button customLightbox__close'}
                onClick={() => setActive(false)}>
                <CloseIcon className={'customLightbox__closeIcon'} />
            </button>
            <Slider
                {...settings}
                beforeChange={(o, n) => setActiveSlide(n)}
                ref={slider}
            >
                {localController && localController.sources.map((value: any) => (
                    <DocumentViewer
                        fileId={value}
                        key={'documentViewer_' + value}
                        local={controller.local}
                    />),
                )}
            </Slider>
        </div>
    )
}

export default CustomLightbox
