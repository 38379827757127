import { fetchPath } from '~/API/lib/path/path'
import { rtkApi } from '~/shared/api/rtkApi'

const path = fetchPath('util')

const utilApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchTaxiPrice: build.query<Record<string, any[]>, { longitude: string, latitude: string }>({
            query: ({
                longitude,
                latitude,
            }) => ({
                method: 'GET',
                url: path + '/taxi',
                params: {
                    longitude,
                    latitude,
                },
            }),
            providesTags: ['Util'],
        }),
    }),
})

export const {
    useLazyFetchTaxiPriceQuery,
} = utilApi
