import clsx from 'clsx'
import React from 'react'
import '../Preloaders.scss'

interface PreloaderProps {
    size?: 'small' | 'large',
    withoutBackground?: boolean,
    background?: 'white' | 'transparent',
    className?: string
}

const Preloader = (props: PreloaderProps) => {
    const {
        size,
        withoutBackground,
        background,
        className,
    } = props
    return (
        <div className={clsx(className, background, { preloader: !withoutBackground })}>
            <div className={clsx('loader', size)}>
                <div className='dot' />
                <div className='dot' />
                <div className='dot' />
                <div className='dot' />
                <div className='dot' />
            </div>
        </div>
    )
}

export default Preloader
