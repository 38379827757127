import { Role } from '~/API/userApi/user.types'

export const enum VERSIONS {
    'V2' = 'v2',
    'V1' = 'v1'
}

export const enum REPORT_STAGE_TYPE {
    'CONTRACT' = 'contract',
    'ONE_TIME' = 'oneTime',
    'MAINTENANCE' = 'maintenance',
    'ALL' = 'all', // for navigate from order to report
}

export enum FormStages {
    'ADD_SERVICES_RESPONSE' = 'ADD_SERVICES_RESPONSE',
    'ADD_SERVICES_TO_CONFIRMATION' = 'ADD_SERVICES_TO_CONFIRMATION',
    'APPROVE_CLEANING' = 'APPROVE_CLEANING',
    'APPROVE_NZ' = 'APPROVE_NZ',
    'APPROVE_NZ_BACK' = 'APPROVE_NZ_BACK',
    'CHANGE_COST' = 'CHANGE_COST',
    'CHANGE_REQUEST' = 'CHANGE_REQUEST',
    'CHIEF_REPORT' = 'CHIEF_REPORT',
    'CLEANING_ENDED' = 'CLEANING_ENDED',
    'CLEANING_STARTED' = 'CLEANING_STARTED',
    'CLIENT_CONFIRMATION' = 'CLIENT_CONFIRMATION',
    'CLIENT_DECLINE' = 'CLIENT_DECLINE',
    'COMPLETE' = 'COMPLETE',
    'EDIT_ORDER' = 'EDIT_ORDER',
    'FILL_NZ_WITH_TASK' = 'FILL_NZ_WITH_TASK',
    'FINISH_ORDER' = 'FINISH_ORDER',
    'FOREMAN_REPORT' = 'FOREMAN_REPORT',
    'INFO_TO_FOREMAN' = 'INFO_TO_FOREMAN',
    'LAST_DAY_ENDED' = 'LAST_DAY_ENDED',
    'NEW_CLEANING_APPROVAL_DATE' = 'NEW_CLEANING_APPROVAL_DATE',
    'NEW_CLEANING_DATE' = 'NEW_CLEANING_DATE',
    'NZ_TASK_APPROVED' = 'NZ_TASK_APPROVED',
    'ON_WAREHOUSE' = 'ON_WAREHOUSE',
    'ORDER_CLOSED' = 'ORDER_CLOSED',
    'ORDER_CREATION' = 'ORDER_CREATION',
    'ORDER_MANAGER_REPORT' = 'ORDER_MANAGER_REPORT',
    'ORDER_TO_DIVISION' = 'ORDER_TO_DIVISION',
    'PAYMENT_WAITING' = 'PAYMENT_WAITING',
    'RECLEANING' = 'RECLEANING',
    'RESERVE_CLEANING' = 'RESERVE_CLEANING',
    'RESERVE_ORDER' = 'RESERVE_ORDER',
    'TAKE_ORDER' = 'TAKE_ORDER',
    'TOOLS_REPORT' = 'TOOLS_REPORT',
    'TO_CHIEF_DECISION' = 'TO_CHIEF_DECISION',
    'TO_OPERATOR_ORDER_CLOSING' = 'TO_OPERATOR_ORDER_CLOSING',
    'TO_ORDER_MANAGER_ORDER_CLOSING' = 'TO_ORDER_MANAGER_ORDER_CLOSING',
    'TO_QUALITY_CONTROL' = 'TO_QUALITY_CONTROL',
    'WAITING_CLIENT_CONFIRMATION' = 'WAITING_CLIENT_CONFIRMATION',
    'WORKING_DAY_ENDED' = 'WORKING_DAY_ENDED',
    'WORK_ENDING' = 'WORK_ENDING',
    'WORK_PROCESS' = 'WORK_PROCESS',
    'WORK_STARTING' = 'WORK_STARTING',
}

export class StageManager {
    static formStages = [
        'ORDER_CREATION',
        'EDIT_ORDER',
        'RESERVE_ORDER',
        'RESERVE_CLEANING',
        'CHANGE_COST',
        'ORDER_TO_DIVISION',
        'TAKE_ORDER',
        'CLIENT_DECLINE',
        'CHANGE_REQUEST',
        'NEW_CLEANING_DATE',
        'NEW_CLEANING_APPROVAL_DATE',
        'APPROVE_CLEANING',
        'FILL_NZ_WITH_TASK',
        'NZ_TASK_APPROVED',
        'APPROVE_NZ',
        'WAITING_CLIENT_CONFIRMATION',
        'INFO_TO_FOREMAN',
        'CLIENT_CONFIRMATION',
        'ADD_SERVICES_RESPONSE',
        'ADD_SERVICES_TO_CONFIRMATION',
        'CLEANING_STARTED',
        'WORK_STARTING',
        'WORK_PROCESS',
        'CLEANING_ENDED',
        'LAST_DAY_ENDED',
        'WORKING_DAY_ENDED',
        'WORK_ENDING',
        'FOREMAN_REPORT',
        'ON_WAREHOUSE',
        'TOOLS_REPORT',
        'APPROVE_NZ_BACK',
        'ORDER_MANAGER_REPORT',
        'TO_QUALITY_CONTROL',
        'TO_ORDER_MANAGER_ORDER_CLOSING',
        'TO_OPERATOR_ORDER_CLOSING',
        'TO_CHIEF_DECISION',
        'CHIEF_REPORT',
        'FINISH_ORDER',
        'RECLEANING',
        'PAYMENT_WAITING',
        'ORDER_CLOSED',
        'COMPLETE',
    ]

    static formStagesStandard = [
        'ORDER_CREATION',
        'EDIT_ORDER',
        'RESERVE_ORDER',
        'RESERVE_CLEANING',
        'CHANGE_COST',
        'ORDER_TO_DIVISION',
        'TAKE_ORDER',
        'PREPAYMENT_APPROVED',
        'CLIENT_DECLINE',
        'CHANGE_REQUEST',
        'NEW_CLEANING_DATE',
        'NEW_CLEANING_APPROVAL_DATE',
        'APPROVE_CLEANING',
        'FILL_NZ_WITH_TASK',
        'NZ_TASK_APPROVED',
        'APPROVE_NZ',
        'WAITING_CLIENT_CONFIRMATION',
        'INFO_TO_FOREMAN',
        'CLIENT_CONFIRMATION',
        'ADD_SERVICES_RESPONSE',
        'ADD_SERVICES_TO_CONFIRMATION',
        'CLEANING_STARTED',
        'WORK_STARTING',
        'WORK_PROCESS',
        'CLEANING_ENDED',
        'WORKING_DAY_ENDED',
        'LAST_DAY_ENDED',
        'WORK_ENDING',
        'FOREMAN_REPORT',
        'ON_WAREHOUSE',
        'TOOLS_REPORT',
        'APPROVE_NZ_BACK',
        'ORDER_MANAGER_REPORT',
        'TO_QUALITY_CONTROL',
        'TO_ORDER_MANAGER_ORDER_CLOSING',
        'TO_OPERATOR_ORDER_CLOSING',
        'TO_CHIEF_DECISION',
        'CHIEF_REPORT',
        'FINISH_ORDER',
        'RECLEANING',
        'PAYMENT_WAITING',
        'ORDER_CLOSED',
        'COMPLETE',
    ]

    static cleaningProcessStages = [
        'WAITING_CLIENT_CONFIRMATION',
        'INFO_TO_FOREMAN',
        'CLIENT_CONFIRMATION',
        'ADD_SERVICES_RESPONSE',
        'ADD_SERVICES_TO_CONFIRMATION',
        'CLEANING_STARTED',
        'WORK_STARTING',
        'WORK_PROCESS',
        'CLEANING_ENDED',
        'WORKING_DAY_ENDED',
        'LAST_WORK_ENDED',
        'WORK_ENDING',
        'FOREMAN_REPORT',
        'ON_WAREHOUSE',
        'TOOLS_REPORT',
        'APPROVE_NZ_BACK',
    ]

    static defineStageGroup = (stageName: string) => {
        switch (stageName) {
            case undefined:
            case null:
            case 'COMMERCIAL_OFFER_CREATING':
            case 'EDIT_ORDER':
            case 'NEED_CHANGES_TO_ORDER_CREATING':
            case 'ORDER_CREATING':
            case 'ORDER_CREATION':
                return 'primary'
            case 'WAITING_CONFIRMATION':
            case 'NEW_CONTRACT_DATE':
                return 'selling'
            case 'INSPECTION_APPROVING':
            case 'INSPECTION_CONTENT_UPLOADING':
            case 'INSPECTION_DIVISION_APPROVING':
            case 'INSPECTION_PAYMENT_APPROVING':
            case 'INSPECTION_STARTING':
            case 'ORDER_INSPECTION_APPROVING':
            case 'SELECT_INSPECTION_DATE':
                return 'inspection'
            case 'APPROVE_KP':
            case 'CHANGE_COST':
            case 'CLIENT_DECLINE':
            case 'COMMERCIAL_OFFER_APPROVING':
            case 'CREATE_KP':
            case 'DATE_BOOKING':
            case 'NEW_KP_DATE':
            case 'ORDER_APPROVING':
            case 'ORDER_TO_DIVISION':
            case 'PREPAYMENT_APPROVED':
            case 'REPEAT_CLIENT_STARTING':
            case 'RESERVE_CLEANING':
            case 'RESERVE_ORDER':
            case 'SELECT_CONTRACT_DATE':
            case 'SELECT_KP_DATE':
            case 'TAKE_ORDER':
            case 'TO_SELLING':
                return 'accounting_and_selling'
            case 'CONTRACT_SIGNED':
            case 'CONTRACT_SIGNING':
            case 'CONTRACT_SIGNING_BILL':
            case 'NEED_CHANGES_TO_CONTRACT_SIGNING':
            case 'ORDER_MANAGER_INFORMING':
            case 'PREPAYMENT_APPROVING':
            case 'SIGNING_CONTRACT':
            case 'TO_OFFICE_MANAGER_CONTRACT_CREATING':
                return 'agreement'
            case 'APPROVE_CLEANING':
            case 'CHANGE_REQUEST':
            case 'FILL_NZ_WITH_TASK':
            case 'NEW_CLEANING_APPROVAL_DATE':
            case 'NEW_CLEANING_DATE':
            case 'NZ_TASK_APPROVED':
            case 'SELECT_CLEANING_DATE':
            case 'WORKING_PLAN_APPROVING':
                return 'starting'
            case 'ADD_SERVICES_RESPONSE':
            case 'ADD_SERVICES_TO_CONFIRMATION':
            case 'APPROVE_NZ':
            case 'APPROVE_NZ_BACK':
            case 'CLEANING_ENDED':
            case 'CLEANING_STARTED':
            case 'CLIENT_CONFIRMATION':
            case 'FOREMAN_REPORT':
            case 'INFO_TO_FOREMAN':
            case 'LAST_DAY_ENDED':
            case 'ON_WAREHOUSE':
            case 'PAYMENT_RECEIVING':
            case 'RETURNING_DOCUMENTS':
            case 'TOOLS_REPORT':
            case 'WAITING_CLIENT_CONFIRMATION':
            case 'WORKING_DAY_ENDED':
            case 'WORK_ENDING':
            case 'WORK_PROCESS':
            case 'WORK_STARTING':
            case 'DEPARTURE_CONFIRMATION':
            case 'SERVICES_VERIFICATION':
            case 'ADDITIONAL_SERVICES_CREATING':
            case 'OBJECT_REPORT_FILLING':
            case 'TEAM_REPORT_FILLING':
            case 'INVENTORY_ITEMS_RETURNING':
            case 'MANAGER_CHECKING':
            case 'DOCUMENTS_RETURNING':
            case 'CONTRACT_WORK_REPORT_FILLING':
            case 'CONTRACTOR_BILLING':
            case 'DEFECTS_CORRECTING':
                return 'reports'
            case 'CHIEF_REPORT':
            case 'TO_CHIEF_DECISION':
            case 'TO_OPERATOR_ORDER_CLOSING':
            case 'TO_ORDER_MANAGER_ORDER_CLOSING':
            case 'TO_QUALITY_CONTROL':
            case 'QUALITY_CONTROLLING':
            case 'FINAL_REPORT':
            case 'MAINTENANCE_FINAL_REPORT':
                return 'quality'
            case 'RECLEANING':
                return 're_cleaning'
            case 'CLEANING':
                return 'cleaning'
            case 'COMPLETE':
            case 'FINISH_ORDER':
            case 'ORDER_CLOSED':
            case 'PAYMENT_WAITING':
                return 'closed'
            default:
                return 'unknown'
        }
    }

    static availableStageForRole = {
        ADD_SERVICES_RESPONSE: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        ADD_SERVICES_TO_CONFIRMATION: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF'],
        APPROVE_CLEANING: ['ORDER_MANAGER', 'CHIEF'],
        APPROVE_KP: ['OPERATOR', 'CHIEF'],
        APPROVE_NZ: ['ORDER_MANAGER', 'CHIEF'],
        APPROVE_NZ_BACK: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        CHANGE_COST: ['ORDER_MANAGER', 'CHIEF'],
        CHANGE_REQUEST: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF'],
        CHIEF_REPORT: ['CHIEF'],
        CLEANING_ENDED: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        CLEANING_STARTED: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        CLIENT_CONFIRMATION: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        CLIENT_DECLINE: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        CONTRACT_SIGNED: ['OFFICE_MANAGER', 'CHIEF'],
        CONTRACT_SIGNING: ['OPERATOR', 'CHIEF'],
        CREATE_KP: ['OPERATOR', 'CHIEF'],
        DATE_BOOKING: ['ORDER_MANAGER', 'CHIEF'],
        EDIT_ORDER: ['ORDER_MANAGER', 'CHIEF'],
        FILL_NZ_WITH_TASK: ['ORDER_MANAGER', 'CHIEF'],
        FINISH_ORDER: ['CHIEF'],
        FOREMAN_REPORT: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        INFO_TO_FOREMAN: ['ORDER_MANAGER', 'OPERATOR', 'CHIEF'],
        LAST_DAY_ENDED: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        NEW_CLEANING_APPROVAL_DATE: ['ORDER_MANAGER', 'CHIEF'],
        NEW_CLEANING_DATE: ['ORDER_MANAGER', 'CHIEF'],
        NEW_KP_DATE: ['ORDER_MANAGER', 'CHIEF'],
        NZ_TASK_APPROVED: ['TOOLS_MANAGER', 'ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        ON_WAREHOUSE: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        ORDER_APPROVING: ['ORDER_MANAGER', 'CHIEF'],
        ORDER_CLOSED: ['CHIEF'],
        ORDER_CREATION: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF'],
        ORDER_MANAGER_INFORMING: ['ORDER_MANAGER', 'CHIEF'],
        ORDER_MANAGER_REPORT: ['ORDER_MANAGER', 'CHIEF'],
        ORDER_TO_DIVISION: ['OPERATOR', 'CHIEF'],
        PAYMENT_WAITING: ['CHIEF'],
        PREPAYMENT_APPROVED: ['OFFICE_MANAGER', 'CHIEF'],
        RECLEANING: ['CHIEF'],
        REPEAT_CLIENT_STARTING: ['ORDER_MANAGER', 'OPERATOR', 'CHIEF'],
        RESERVE_CLEANING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF'],
        RESERVE_ORDER: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF'],
        RETURNING_DOCUMENTS: ['OFFICE_MANAGER', 'CHIEF'],
        SELECT_CLEANING_DATE: ['ORDER_MANAGER', 'CHIEF'],
        SELECT_CONTRACT_DATE: ['ORDER_MANAGER', 'CHIEF'],
        SELECT_INSPECTION_DATE: ['ORDER_MANAGER', 'CHIEF'],
        SELECT_KP_DATE: ['ORDER_MANAGER', 'CHIEF'],
        SIGNING_KP: ['ORDER_MANAGER', 'CHIEF'],
        TAKE_ORDER: ['ORDER_MANAGER', 'CHIEF'],
        TOOLS_REPORT: ['TOOLS_MANAGER', 'ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        TO_CHIEF_DECISION: ['ORDER_MANAGER', 'OPERATOR', 'CHIEF'],
        TO_OPERATOR_ORDER_CLOSING: ['CHIEF'],
        TO_ORDER_MANAGER_ORDER_CLOSING: ['ORDER_MANAGER', 'CHIEF'],
        TO_QUALITY_CONTROL: ['ORDER_MANAGER', 'CHIEF'],
        TO_RECLEANING: ['CHIEF'],
        TO_SELLING: ['ORDER_MANAGER', 'CHIEF'],
        WAITING_CLIENT_CONFIRMATION: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        WORKING_DAY_ENDED: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        WORK_ENDING: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        WORK_PROCESS: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],
        WORK_STARTING: ['ORDER_MANAGER', 'FOREMAN', 'CHIEF'],

        /** v2 */
        /** ONE_TIME */
        DEPARTURE_CONFIRMATION: ['CHIEF', 'ORDER_MANAGER', 'FOREMAN'], // SERVICES_VERIFICATION
        CLIENT_DECLINING: ['CHIEF', 'ORDER_MANAGER', 'FOREMAN'], // SERVICES_VERIFICATION
        SERVICES_VERIFICATION: ['CHIEF', 'ORDER_MANAGER', 'FOREMAN'], // 'ADDITIONAL_SERVICES_CREATING', 'OBJECT_REPORT_FILLING'
        ADDITIONAL_SERVICES_CREATING: ['CHIEF', 'ORDER_MANAGER', 'OPERATOR'], // 'SERVICES_VERIFICATION'
        OBJECT_REPORT_FILLING: ['CHIEF', 'ORDER_MANAGER', 'FOREMAN'], // 'TEAM_REPORT_FILLING', 'RESPONSIBLE_USER_CHANGING'
        SERVICE_TABLE_FILLING: ['CHIEF', 'ORDER_MANAGER', 'FOREMAN'], // 'TEAM_REPORT_FILLING', 'RESPONSIBLE_USER_CHANGING'
        WORKING_DAY_ENDING: ['CHIEF', 'ORDER_MANAGER', 'FOREMAN'], // 'TEAM_REPORT_FILLING', 'RESPONSIBLE_USER_CHANGING'
        TEAM_REPORT_FILLING: ['CHIEF', 'ORDER_MANAGER', 'FOREMAN'], // 'OBJECT_REPORT_FILLING', 'INVENTORY_ITEMS_RETURNING'
        INVENTORY_ITEMS_RETURNING: ['CHIEF', 'ORDER_MANAGER', 'FOREMAN'], // 'MANAGER_CHECKING'
        MANAGER_CHECKING: ['CHIEF', 'ORDER_MANAGER'], // 'DEFECTS_CORRECTING', 'QUALITY_CONTROLLING'
        DEFECTS_CORRECTING: ['CHIEF', 'ORDER_MANAGER'], // 'QUALITY_CONTROLLING'
        QUALITY_CONTROLLING: ['CHIEF', 'OPERATOR'], // 'DOCUMENTS_RETURNING'
        ACTUAL_PAYMENT_RESERVING: ['CHIEF', 'OPERATOR'], // 'DOCUMENTS_RETURNING'
        DOCUMENTS_RETURNING: ['CHIEF', 'OFFICE_MANAGER'], // 'FINAL_REPORT'
        FINAL_REPORT: ['CHIEF'],

        /** CONTRACT */
        CONTRACT_WORK_REPORT_FILLING: ['CHIEF', 'ORDER_MANAGER'],
        CONTRACTOR_BILLING: ['CHIEF', 'OFFICE_MANAGER'],

        /** MAINTENANCE */
        MAINTENANCE_FINAL_REPORT: ['CHIEF'],
    }

    static availableTransitionStageToStage = {
        ADD_SERVICES_RESPONSE: ['ADD_SERVICES_TO_CONFIRMATION'],
        ADD_SERVICES_TO_CONFIRMATION: ['CLEANING_STARTED'],
        APPROVE_CLEANING: ['FILL_NZ_WITH_TASK'],
        APPROVE_NZ: ['CLIENT_CONFIRMATION', 'WAITING_CLIENT_CONFIRMATION', 'CLIENT_DECLINE'],
        APPROVE_NZ_BACK: ['ORDER_MANAGER_REPORT'],
        CHANGE_COST: ['RESERVE_ORDER', 'ORDER_TO_DIVISION'],
        CHANGE_REQUEST: ['EDIT_ORDER'],
        CHIEF_REPORT: ['ORDER_CLOSED'],
        CLEANING_ENDED: ['LAST_DAY_ENDED'],
        CLEANING_STARTED: ['WORK_STARTING'],
        CLIENT_CONFIRMATION: ['CLEANING_STARTED', 'ADD_SERVICES_RESPONSE'],
        CLIENT_DECLINE: ['APPROVE_CLEANING', 'NEW_CLEANING_DATE', 'NEW_CLEANING_APPROVAL_DATE'],
        COMMERCIAL_OFFER_APPROVING: ['ORDER_APPROVING', 'DATE_BOOKING'],
        DATE_BOOKING: ['DATE_BOOKING'],
        EDIT_ORDER: ['RESERVE_ORDER', 'ORDER_TO_DIVISION'],
        FILL_NZ_WITH_TASK: ['APPROVE_NZ'],
        FINISH_ORDER: ['ORDER_CLOSING'],
        FOREMAN_REPORT: ['ON_WAREHOUSE', 'CLEANING_STARTED'],
        INFO_TO_FOREMAN: ['INFO_TO_FOREMAN', 'CLIENT_CONFIRMATION', 'CLIENT_DECLINE', 'WAITING_CLIENT_CONFIRMATION'],
        LAST_DAY_ENDED: ['WORK_ENDING'],
        NEW_CLEANING_APPROVAL_DATE: ['APPROVE_CLEANING', 'NEW_CLEANING_DATE', 'NEW_CLEANING_APPROVAL_DATE'],
        NEW_CLEANING_DATE: ['APPROVE_CLEANING', 'NEW_CLEANING_DATE', 'NEW_CLEANING_APPROVAL_DATE'],
        ON_WAREHOUSE: ['TOOLS_REPORT'],
        ORDER_APPROVING: ['ORDER_APPROVING'],
        ORDER_CLOSED: [],
        ORDER_CREATION: ['RESERVE_ORDER', 'ORDER_TO_DIVISION', 'TAKE_ORDER'],
        ORDER_MANAGER_INFORMING: ['ORDER_MANAGER_INFORMING'],
        ORDER_MANAGER_REPORT: ['TO_QUALITY_CONTROL'],
        ORDER_TO_DIVISION: ['TAKE_ORDER', 'CHANGE_COST'],
        PAYMENT_WAITING: ['ORDER_CLOSING'],
        RECLEANING: ['ORDER_CLOSING'],
        REPEAT_CLIENT_STARTING: ['CONTRACT_SIGNING'],
        RESERVE_CLEANING: ['RESERVE_ORDER', 'RESERVE_CLEANING', 'ORDER_TO_DIVISION'],
        RESERVE_ORDER: ['RESERVE_ORDER', 'RESERVE_CLEANING', 'ORDER_TO_DIVISION'],
        TAKE_ORDER: ['CHANGE_REQUEST', 'APPROVE_CLEANING', 'NEW_CLEANING_DATE', 'NEW_CLEANING_APPROVAL_DATE'],
        TOOLS_REPORT: ['APPROVE_NZ_BACK', 'ON_WAREHOUSE'],
        TO_CHIEF_DECISION: ['TO_ORDER_MANAGER_ORDER_CLOSING', 'TO_OPERATOR_ORDER_CLOSING', 'ORDER_CLOSED', 'TO_RECLEANING'],
        TO_OPERATOR_ORDER_CLOSING: ['TO_CHIEF_DECISION'],
        TO_ORDER_MANAGER_ORDER_CLOSING: ['TO_CHIEF_DECISION', 'TO_QUALITY_CONTROL'],
        TO_QUALITY_CONTROL: ['TO_CHIEF_DECISION'],
        TO_RECLEANING: ['CHIEF_REPORT'],
        WAITING_CLIENT_CONFIRMATION: ['INFO_TO_FOREMAN', 'CLIENT_CONFIRMATION', 'CLIENT_DECLINE', 'WAITING_CLIENT_CONFIRMATION'],
        WORKING_DAY_ENDED: ['LAST_DAY_ENDED'],
        WORK_ENDING: ['FOREMAN_REPORT'],
        WORK_PROCESS: ['WORK_PROCESS', 'LAST_DAY_ENDED', 'WORKING_DAY_ENDED'],
        WORK_STARTING: ['WORK_PROCESS'],

        /** v2 */
        DEPARTURE_CONFIRMATION: ['SERVICES_VERIFICATION'],
        SERVICES_VERIFICATION: ['ADDITIONAL_SERVICES_CREATING', 'OBJECT_REPORT_FILLING'],
        ADDITIONAL_SERVICES_CREATING: ['SERVICES_VERIFICATION'],
        OBJECT_REPORT_FILLING: ['TEAM_REPORT_FILLING', 'RESPONSIBLE_USER_CHANGING'],
        TEAM_REPORT_FILLING: ['OBJECT_REPORT_FILLING', 'INVENTORY_ITEMS_RETURNING'],
        INVENTORY_ITEMS_RETURNING: ['MANAGER_CHECKING'],
        MANAGER_CHECKING: ['DEFECTS_CORRECTING', 'QUALITY_CONTROLLING'],
        DEFECTS_CORRECTING: ['QUALITY_CONTROLLING'],
        QUALITY_CONTROLLING: ['DOCUMENTS_RETURNING', 'ACTUAL_PAYMENT_RESERVING'],
        ACTUAL_PAYMENT_RESERVING: ['DOCUMENTS_RETURNING'],
        DOCUMENTS_RETURNING: ['FINAL_REPORT'],
        FINAL_REPORT: ['ORDER_CLOSED'],
    }

    static formStagesOneTime = [
        'DEPARTURE_CONFIRMATION',
        'CLIENT_DECLINING',
        'SERVICES_VERIFICATION',
        'ADDITIONAL_SERVICES_CREATING',
        'OBJECT_REPORT_FILLING',
        'SERVICE_TABLE_FILLING',
        'WORKING_DAY_ENDING',
        'TEAM_REPORT_FILLING',
        'WAREHOUSE_GOING',
        'INVENTORY_ITEMS_RETURNING',
        'MANAGER_CHECKING',
        'DEFECTS_CORRECTING',
        'QUALITY_CONTROLLING',
        'ACTUAL_PAYMENT_RESERVING',
        'DOCUMENTS_RETURNING',
        'FINAL_REPORT',
        'ORDER_CLOSED',
    ]

    static formStagesContract = [
        'CONTRACT_WORK_REPORT_FILLING',
        'DEFECTS_CORRECTING',
        'QUALITY_CONTROLLING',
        'CONTRACTOR_BILLING',
        'DOCUMENTS_RETURNING',
        'FINAL_REPORT',
        'ORDER_CLOSED',
    ]

    static formStagesMaintenance = [
        'MAINTENANCE_FINAL_REPORT',
        'ORDER_CLOSED',
    ]

    static formStagesAll = [...new Set(
        [...StageManager.formStagesOneTime, ...StageManager.formStagesContract, ...StageManager.formStagesMaintenance],
    )] as const

    static fetchStages = (currentStage: string, version: VERSIONS, type: REPORT_STAGE_TYPE) => {
        if (!currentStage) {
            return []
        }
        let pos = 0
        switch (version) {
            case VERSIONS.V1: {
                pos = StageManager.formStagesStandard.indexOf(currentStage)
                if (pos < 0) {
                    return []
                }
                return StageManager.formStagesStandard.slice(0, pos + 1)
            }
            case VERSIONS.V2: {
                switch (type) {
                    case REPORT_STAGE_TYPE.CONTRACT: {
                        pos = StageManager.formStagesContract.indexOf(currentStage)
                        if (pos < 0) {
                            return []
                        }
                        return StageManager.formStagesContract.slice(0, pos + 1)
                    }
                    case REPORT_STAGE_TYPE.MAINTENANCE: {
                        pos = StageManager.formStagesMaintenance.indexOf(currentStage)
                        if (pos < 0) {
                            return []
                        }
                        return StageManager.formStagesMaintenance.slice(0, pos + 1)
                    }
                    case REPORT_STAGE_TYPE.ONE_TIME: {
                        pos = StageManager.formStagesOneTime.indexOf(currentStage)
                        if (pos < 0) {
                            return []
                        }
                        return StageManager.formStagesOneTime.slice(0, pos + 1)
                    }
                    case REPORT_STAGE_TYPE.ALL: {
                        pos = StageManager.formStagesAll.indexOf(currentStage)
                        if (pos < 0) {
                            return []
                        }
                        return StageManager.formStagesAll.slice(0, pos + 1)
                    }
                }
            }
        }
    }

    static hideEvents = (stage: string, role: Role) => {
        return (role === 'CHIEF' && stage !== 'TO_CHIEF_DECISION') || (role === 'OPERATOR' && stage === 'TAKE_ORDER')
    }

    static checkAvailableStageByRole = (stage: string, role: Role, version: VERSIONS = VERSIONS.V2) => {
        if (!stage) {
            return role === 'ORDER_MANAGER' || role === 'OPERATOR'
        }
        if (stage === 'TO_CHIEF_DECISION') {
            return role === 'CHIEF'
        }
        if (stage === 'TO_QUALITY_CONTROL' || stage === 'TO_OPERATOR_ORDER_CLOSING') {
            return role === 'OPERATOR'
        }
        if (stage === 'WORKING_PLAN_APPROVING') {
            return role === 'ORDER_MANAGER'
        }
        if (stage === 'NEED_CHANGES_TO_CONTRACT_SIGNING') {
            return role === 'OPERATOR'
        }
        if (stage === 'TO_ORDER_MANAGER_ORDER_CLOSING') {
            return role === 'ORDER_MANAGER' || role === 'CHIEF'
        }
        switch (version) {
            case VERSIONS.V1: {
                const stageArray = StageManager.availableTransitionStageToStage[stage as keyof typeof StageManager.availableTransitionStageToStage]
                return stageArray?.some((stage) => StageManager.availableStageForRole[stage as keyof typeof StageManager.availableStageForRole].includes(role))
            }
            case VERSIONS.V2: {
                return StageManager.availableStageForRole[stage as keyof typeof StageManager.availableStageForRole]?.includes(role)
            }
        }
    }

    // INPUTS AVAILABLE
    static availableStagesForInput = {
        act: ['ORDER_CREATION', 'TAKE_ORDER'],
        actPhoto: ['DOCUMENTS_RETURNING'],
        addContractPhoto: ['SERVICES_VERIFICATION'],
        additionalServicesPhotos: ['ADD_SERVICES_TO_CONFIRMATION'],
        contract: ['ORDER_CREATION', 'ORDER_TO_DIVISION'],
        contractPhoto: ['SERVICES_VERIFICATION', 'DOCUMENTS_RETURNING'],
        customContract: [
            'CONTRACT_SIGNING',
            'TO_OFFICE_MANAGER_CONTRACT_CREATING',
            'NEED_CHANGES_TO_CONTRACT_SIGNING',
        ],
        customContractContractor: [
            'WORKING_PLAN_APPROVING',
        ],
        inspectionPayment: ['INSPECTION_PAYMENT_APPROVING'],
        mistakesFiles: ['DEFECTS_CORRECTING'],
        nzBack: [],
        nzBackPhoto: ['APPROVE_NZ_BACK'],
        nzFront: [],
        nzFrontPhoto: ['APPROVE_NZ_BACK'],
        objectPhotos: [
            'ORDER_CREATING',
            'INSPECTION_DIVISION_APPROVING',
            'INSPECTION_CONTENT_UPLOADING',
            'COMMERCIAL_OFFER_APPROVING',
            'DATE_BOOKING',
        ],
        organizationProxyId: [
            'ORDER_CREATING',
            'COMMERCIAL_OFFER_APPROVING',
            'INSPECTION_DIVISION_APPROVING',
            'INSPECTION_APPROVING',
            'INSPECTION_CONTENT_UPLOADING',
            'INSPECTION_PAYMENT_APPROVING',
            'INSPECTION_STARTING',
            'CONTRACT_SIGNING',
            'PREPAYMENT_APPROVING',
            'TO_OFFICE_MANAGER_CONTRACT_CREATING',
            'APPROVE_NZ',
            'APPROVE_CLEANING',
            'FILL_NZ_WITH_TASK',
            'DATE_BOOKING',
            'ORDER_APPROVING',
            'REPEAT_CLIENT_STARTING',
            'ORDER_MANAGER_INFORMING',
        ],
        other: ['ORDER_CREATING', 'INSPECTION_DIVISION_APPROVING', 'INSPECTION_CONTENT_UPLOADING'],
        ownContract: ['CONTRACT_SIGNING'],
        paymentCheck: ['OBJECT_REPORT_FILLING'],
        paymentPhoto: ['DOCUMENTS_RETURNING', 'WORKING_DAY_ENDING'],
        photosObject: ['ORDER_CREATION', 'CREATE_KP', null, undefined],
        prepayment: ['PREPAYMENT_APPROVING'],
        prepaymentContractor: ['WORKING_PLAN_APPROVING'],
        prepaymentCheck: ['OBJECT_REPORT_FILLING'],
        prepaymentPhoto: ['CONTRACT_SIGNED'],
        qualityControlFiles: ['QUALITY_CONTROLLING'],
        registerPhoto: ['CLIENT_CONFIRMATION'],
        requisites: ['ORDER_CREATION', null, undefined],
        updPhoto: ['DOCUMENTS_RETURNING'],
        workBeginning: ['OBJECT_REPORT_FILLING', 'CONTRACT_WORK_REPORT_FILLING'],
        workEnding: ['WORKING_DAY_ENDING', 'CONTRACT_WORK_REPORT_FILLING'],
        workProcess: ['SERVICE_TABLE_FILLING', 'CONTRACT_WORK_REPORT_FILLING'],
        workInterimProcess: ['TEAM_REPORT_FILLING'],
        actualAmortization: ['INVENTORY_ITEMS_RETURNING', 'MANAGER_CHECKING'],
        contractorAccountingPhoto: ['CONTRACTOR_BILLING'],
        contractorAccountingActPhoto: ['CONTRACTOR_BILLING'],
        contractorAccountingPaymentPhoto: ['CONTRACTOR_BILLING'],
        memo: ['TEAM_REPORT_FILLING'],
        otherDocuments: ['DOCUMENTS_RETURNING'],
    }

    static getAvailableStagesForInput = (inputName: string) => {
        return StageManager.availableStagesForInput[inputName as keyof typeof StageManager.availableStagesForInput] || []
    }
}
