import { KpService, PaymentType } from '~/components/pages/KP/kp.types'
import {
    buildLastPageTable,
    buildPageTable,
    collectCommercialOfferServices,
    collectServices,
    generateNewKpPageWithBodyContract,
    makeAdditionalContractPages,
    ServicesType,
} from '~/shared/templates/mainTemplates/additionalFunctions'
import {
    generateNewContractServicesPageWithBody,
    MaxServicesHeight,
} from '~/shared/templates/mainTemplates/additionalFunctions/collectCommercialOfferServices/collectCommercialOfferServices'
import { makeAdditionalData } from '~/shared/templates/mainTemplates/contractMaintenance/lib'
import {
    organizationRequisitesNds,
    organizationRequisitesOrisclean,
    organizationRequisitesWithoutNds,
} from '~/shared/templates/mainTemplates/docUtil'
import { fetchDate } from '~/shared/util/currentTime'
//@ts-ignore
import { RussianName } from '~/shared/util/names'
import { formatRussianPost } from '~/shared/util/posts'

const maxCOServicesPagesMaintenanceHeight: Record<PaymentType, number | MaxServicesHeight> = {
    'р/с (НДС)': {
        first: 204,
        second: 360,
    },
    'р/с (без НДС)': 360,
    'Наличные': 483,
}

const ORISCLEAN = 'ORISCLEAN'

export const contractMaintenance = async (values: any) => {
    const html = await fetch(values.format === 'docx' ? '/html/contract_maintenance_docx.html' : '/html/contract_maintenance.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    let domHtml = parser.parseFromString(htmlText, 'text/html')

    let date = fetchDate()
    const dateString = date.format('LL')
    const day = dateString.split(' ')[0]

    const contractNumber = 'Р' + values.contractNumber

    let ourRequisites: typeof organizationRequisitesWithoutNds | typeof organizationRequisitesNds | typeof organizationRequisitesOrisclean
    if (values.division === ORISCLEAN) {
        ourRequisites = organizationRequisitesOrisclean
    } else if (values.paymentType === 'р/с (НДС)') {
        ourRequisites = organizationRequisitesNds
    } else {
        ourRequisites = organizationRequisitesWithoutNds
    }
    const phone = ourRequisites.email + ',' + ourRequisites.phone

    const responsibleClientFace = values.organizationRequisites.isProxy
        ? {
            ...values.organizationRequisites.management,
            name: values.organizationRequisites.proxyFullname,
            post: 'Представитель',
            email: values.contactInfo[0].clientEmail || values.organizationRequisites.management?.email || '',
            phone: values.contactInfo[0].clientPhone || values.organizationRequisites.management?.phone || '',
            document: `Доверенности ${values.organizationRequisites.proxyAuthorityNumber}`,
        }
        : {
            ...values.organizationRequisites.management,
            phone: values.contactInfo[0].clientPhone || values.organizationRequisites.management?.phone || '',
            email: values.contactInfo[0].clientEmail || values.organizationRequisites.management?.email || '',
            post: values.organizationRequisites.management?.post || '',
            name: values.organizationRequisites.management?.name || '',
            document: values.organizationRequisites.management?.post === 'Индивидуальный предприниматель' ?
                `государственной регистрации физического лица в качестве индивидуального предпринимателя от ${fetchDate(values.organizationRequisites?.ogrn_date).format('DD.MM.YYYY')} ОГРНИП ${values.organizationRequisites?.ogrn}` :
                'Устава',
        }

    const rnResponsibleClientPost = formatRussianPost(responsibleClientFace.post)
    const rnResponsibleClientFace = new RussianName(responsibleClientFace.name)
    let responsibleFaceShortName =
        rnResponsibleClientFace.lastName('nominative') +
        ' ' +
        rnResponsibleClientFace.firstName('nominative').charAt(0) +
        '.'
    if (rnResponsibleClientFace.middleName('nominative').charAt(0)) {
        responsibleFaceShortName += rnResponsibleClientFace.middleName('nominative').charAt(0) + '.'
    }

    const directorRussianName = new RussianName(ourRequisites.director)
    const directorGcaseRod = directorRussianName.fullName(
        directorRussianName.gcaseRod,
    )

    /** составление доп страниц для перечня услуг */
    const services2: ServicesType[] = values.commercialOffer.services
        .reduce((acc: ServicesType[], item: KpService) => {
            acc.push(...item.tasks)

            return acc
        }, [])
        .filter((s: ServicesType) => s?.items?.length > 0)
    const additionalServicePages = collectServices({ services: services2, pageLimit: 20 })
    if (additionalServicePages.length > 0) {
        const pages = additionalServicePages.map((p, index) =>
            generateNewKpPageWithBodyContract(index !== additionalServicePages.length - 1 ? buildPageTable(p.join(''), values.format) : buildLastPageTable(p.join(''), values.format))).join('')
        domHtml.querySelector('#additionalPage')!.innerHTML += pages
    }

    /** формирование технологической карты уборки */
    const commercialOfferServicesPages = collectCommercialOfferServices({
        kpServices: values?.commercialOffer?.services,
        style: domHtml.querySelector('style')!.innerHTML,
        maxHeight: maxCOServicesPagesMaintenanceHeight[values.paymentType as PaymentType],
        format: values.format,
    })

    /** подставление динамических пунктов в договор */
    domHtml = makeAdditionalData(values, domHtml)

    domHtml.querySelector('#CONTRACT_EXPIRE_DATE')!.innerHTML = fetchDate().format('M') === '10' ?
        `31 декабря ${fetchDate().add(1, 'year').format('YYYY')}` :
        `31 декабря ${fetchDate().format('YYYY')}`
    if (commercialOfferServicesPages?.length > 1) {
        domHtml.querySelector('#servicesPage')!.innerHTML =
            generateNewContractServicesPageWithBody(commercialOfferServicesPages.pop()!.join(''), 'LAST', {
                contractType: values.contractType,
                paymentType: values.paymentType,
                format: values.format,
            })
        domHtml.querySelector('#additionalServicesPage')!.innerHTML =
            makeAdditionalContractPages(commercialOfferServicesPages, {
                contractType: values.contractType,
                paymentType: values.paymentType,
                format: values.format,
            })
    } else if (commercialOfferServicesPages?.length) {
        domHtml.querySelector('#servicesPage')!.innerHTML =
            generateNewContractServicesPageWithBody(commercialOfferServicesPages[0].join(''), 'SINGLE', {
                contractType: values.contractType,
                paymentType: values.paymentType,
                format: values.format,
            })
    }

    if (!values.contactInfo[0].clientPost || values.contactInfo[0].clientPost === '-') {
        domHtml.querySelector<HTMLElement>('#customerJobPositionRow')!.style.display = 'none'
    }

    /** расстановка значений в документ */
    if (values?.commercialOffer?.services?.length) {
        domHtml.querySelector<HTMLElement>('#servicesDescDiv')!.style.display = 'none'
        domHtml.querySelector<HTMLElement>('#operSchedDescDiv')!.style.display = 'none'
        domHtml.querySelector<HTMLElement>('#numOfPersDescDiv')!.style.display = 'none'
    }

    if (!values?.commercialOffer?.services?.length) {
        domHtml.querySelector<HTMLElement>('#servicesDescDiv')!.style.display = 'none'
    }

    domHtml.querySelector('#charterNumber')!.innerHTML = responsibleClientFace.document
    domHtml.querySelectorAll('.contractNumber').forEach((element) => (element.innerHTML = contractNumber))
    domHtml
        .querySelectorAll('.contractDate')
        .forEach((element) => (element.innerHTML = 'от «' + day + '» ' + dateString.substring(day.length)))
    domHtml
        .querySelectorAll('.organizationName')
        .forEach((element) => (element.innerHTML = values.organizationRequisites.name))
    domHtml.querySelector('#startDate')!.innerHTML = values.dateType === 'По согласованию' ? values.dateType : fetchDate(values?.cleaningDate?.startDate).format('DD.MM.YYYY')
    // domHtml.querySelector('#endDate')!.innerHTML = fetchDate(values?.cleaningDate?.endDate).format('DD.MM.YYYY')
    // domHtml.querySelector('#objectType')!.innerHTML = values.buildingType
    domHtml.querySelector('#object')!.innerHTML = values.buildingClass
    // domHtml.querySelector('#counterparty')!.innerHTML = values.counterparty
    domHtml
        .querySelectorAll('.legalFaceAddress')
        .forEach((element) => (element.innerHTML = values.organizationRequisites.address))
    domHtml
        .querySelectorAll('.objectAddress')
        .forEach((element) => (element.innerHTML = values.address))
    domHtml
        .querySelectorAll('.objectArea')
        .forEach((element) => (element.innerHTML = `${values.objectArea} кв.м.`))

    if (values.format !== 'docx') {
        domHtml.querySelectorAll('.pageWrapper').forEach((page, index, array) => {
            if (index < 4) {
                page.innerHTML += `<table class='borderNoneTable signTable'>
            <tbody>
                <tr>
                    <td style='width: 25%;'>Заказчик:__________________/</td>
                    <td style='width: 49%; text-align:center;'>Исполнитель:__________________/</td>
                    <td style='width:25%;text-align:right;'>Страница <b>${index + 1}</b> из <b>${array.length}</b></td>
                </tr>
            </tbody>
        </table>`
            } else {
                page.innerHTML += `<span class='pagesSign'>Страница <b>${index + 1}</b> из <b>${array.length}</b></span>`
            }
        })
    }

    domHtml.querySelector('#ourOrganizationFullname')!.innerHTML = ourRequisites.fullname
    domHtml.querySelector('#organizationFullname')!.innerHTML = values.organizationRequisites.unrestricted_value
    domHtml.querySelector('#responsibleClientFacePostRod')!.innerHTML = rnResponsibleClientPost
    domHtml.querySelector('#responsibleClientFaceNameRod')!.innerHTML = rnResponsibleClientFace.fullName(
        rnResponsibleClientFace.gcaseRod,
    )
    domHtml
        .querySelectorAll('.organizationNameShort')
        .forEach((element) => {
            if (responsibleClientFace.post === 'Индивидуальный предприниматель') {
                element.innerHTML = responsibleClientFace.name
            } else {
                element.innerHTML = values.organizationRequisites.name
            }
        })
    domHtml
        .querySelectorAll('.responsibleFaceName')
        .forEach((element) => (element.innerHTML = responsibleClientFace.post))
    domHtml
        .querySelectorAll('.responsibleFaceShortName')
        .forEach((element) => (element.innerHTML = responsibleFaceShortName))
    if (values.contactInfo[0].clientEmail?.length > 0) {
        domHtml.querySelector('#responsibleFaceEmail')!.innerHTML = values.contactInfo[0].clientEmail
    }

    domHtml.querySelector('#directorFullname2')!.innerHTML = directorGcaseRod
    domHtml.querySelectorAll('.directorShortName').forEach((element) => (element.innerHTML = ourRequisites.directorShortName))

    domHtml.querySelector('#postAddress')!.innerHTML = values.organizationRequisites.postAddress
    domHtml.querySelector('#ogrn')!.innerHTML = values.organizationRequisites.ogrn
    domHtml.querySelector('#tin_cat')!.innerHTML = `${values.organizationRequisites.inn} ${values.organizationRequisites.kpp ? `/ ${values.organizationRequisites.kpp}` : ''}`
    domHtml.querySelector('#tin_cat_title')!.innerHTML = `ИНН${values.organizationRequisites.kpp ? '/КПП' : ''}:`
    domHtml.querySelector('#ba')!.innerHTML = values.organizationRequisites.bankAccount
    domHtml.querySelector('#bank')!.innerHTML = values.organizationRequisites.bankName
    domHtml.querySelector('#bankId')!.innerHTML = values.organizationRequisites.bankId
    domHtml.querySelector('#ka')!.innerHTML = values.organizationRequisites.correspondentAccount
    domHtml.querySelector('#phone')!.innerHTML = responsibleClientFace.phone || ''
    domHtml.querySelector('#email')!.innerHTML = responsibleClientFace.email || ''

    domHtml.querySelectorAll('.ourOrganizationName').forEach((element) => (element.innerHTML = ourRequisites.name))
    domHtml.querySelector('#ourOrganizationPostAddress')!.innerHTML = ourRequisites.postAddress
    domHtml.querySelector('#ourOrganizationLegalFaceAddress')!.innerHTML = ourRequisites.legalFaceAddress
    domHtml.querySelector('#ourOrganizationOrgn')!.innerHTML = ourRequisites.ogrn
    domHtml.querySelector('#ourOrganizationInnKpp')!.innerHTML = ourRequisites.inn + ' / ' + ourRequisites.kpp
    domHtml.querySelector('#ourOrganizationBankAccount')!.innerHTML = ourRequisites.bankAccount
    domHtml.querySelector('#ourOrganizationBankName')!.innerHTML = ourRequisites.bankName
    domHtml.querySelector('#ourOrganizationBankId')!.innerHTML = ourRequisites.bankId
    domHtml.querySelector('#ourOrganizationCorrespondentAccount')!.innerHTML = ourRequisites.correspondentAccount
    domHtml.querySelector('#ourOrganizationPhone')!.innerHTML = ourRequisites.phone
    domHtml.querySelectorAll('.ourOrganizationEmail').forEach((element) => (element.innerHTML = ourRequisites.email))

    domHtml.querySelector('#operSched')!.innerHTML = values.cleaningDuration
    domHtml.querySelector('#numOfPers')!.innerHTML = `<pre>${values.cleanerNumber}</pre>`

    domHtml.querySelector('#execName')!.innerHTML = 'Call-центр'
    domHtml.querySelector('#execPhone')!.innerHTML = phone

    domHtml.querySelectorAll('.custName').forEach((e) => (e.innerHTML = values.contactInfo[0].clientFullname))
    domHtml.querySelectorAll('.custJob').forEach((e) => (e.innerHTML = values.contactInfo[0].clientPost || ''))
    domHtml.querySelector('#custPhone')!.innerHTML =
        values.contactInfo[0].clientPhone +
        (values.contactInfo[0].clientEmail?.length > 0 && values.contactInfo[0].clientPhone?.length > 0 ? '/' : '') +
        values.contactInfo[0].clientEmail

    /** действия со стоимостью */
    const cost = Number(values.cost)
    const priceBeforeTax = values.commercialOffer.services?.reduce((acc: any, service: any) => service.priceBeforeTax ? acc + service.priceBeforeTax : acc, 0)
    const nds = 20 * cost / 120
    switch (values.paymentType) {
        case 'р/с (НДС)': {
            domHtml.querySelector<HTMLElement>('#paymentTypeWithoutNds')!.style.display = 'none'
            domHtml.querySelector<HTMLElement>('#paymentTypeCash')!.style.display = 'none'
            domHtml.querySelector('#b2bNds')!.innerHTML = Math.floor(nds).toFixed() + ' рублей'
            domHtml.querySelector('#b2bSum')!.innerHTML = cost + ' рублей'
            break
        }
        case 'р/с (без НДС)': {
            domHtml.querySelector<HTMLElement>('#paymentTypeWithNds1')!.style.display = 'none'
            domHtml.querySelector<HTMLElement>('#paymentTypeWithNds2')!.style.display = 'none'
            domHtml.querySelector<HTMLElement>('#paymentTypeCash')!.style.display = 'none'
            domHtml.querySelector('#b2bNoNds')!.innerHTML = cost + ' рублей'
            break
        }
        case 'Наличные': {
            domHtml.querySelector<HTMLElement>('#paymentTypeWithoutNds')!.style.display = 'none'
            domHtml.querySelector<HTMLElement>('#paymentTypeWithNds1')!.style.display = 'none'
            domHtml.querySelector<HTMLElement>('#paymentTypeWithNds2')!.style.display = 'none'
            domHtml.querySelector('#b2bCash')!.innerHTML = cost + ' рублей'
            break
        }
    }

    return { style: domHtml.querySelector('style')!.innerHTML, body: domHtml.querySelector('body')!.innerHTML }
}
