import clsx from 'clsx'
import React, { memo, useEffect, useState } from 'react'
import { useFetchClientServicesQuery } from '~/API/clientApi/client'
import { ClientResponse, ClientServiceResponse, ServicesWithoutInspection } from '~/API/clientApi/client.types'
import {
    ItemType,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientOrganization/ClientOrganization.types'
import {
    ClientServiceItems,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientService/subComponents/ClientServiceItems/ClientServiceItems'
import CustomInput from '~/components/common/inputs/CustomInput/CustomInput'
import './ClientService.scss'
import Preloader from '~/components/common/preloaders/Preloader/Preloader'

export interface ClientServiceItemType extends ServicesWithoutInspection {
    id: string
}

interface ClientServiceProps {
    className?: string
    clientInfo: ClientResponse<ItemType>
    clientAddress: ItemType
}

export const ClientService = memo((props: ClientServiceProps) => {
    const {
        className,
        clientInfo,
        clientAddress,
    } = props

    const {
        data: clientServiceData,
        isFetching: clientServiceLoading,
    } = useFetchClientServicesQuery({
        clientId: clientInfo.id,
        address: clientAddress.text,
    })

    const [services, setServices] = useState<ClientServiceResponse[]>([])

    useEffect(() => {
        if (clientServiceData?.length) {
            setServices(clientServiceData)
        }
    }, [clientServiceData])

    return (
        <div className={clsx('client-service', className)}>
            <div className='client-service__inputs-wrapper'>
                <CustomInput
                    name='client-service__popup__1'
                    value={clientInfo?.additionalParams?.text}
                    disabled={true}
                />
                <CustomInput
                    name='client-service__popup__2'
                    value={clientAddress.text}
                    disabled={true}
                />
            </div>
            {!clientServiceLoading ? (
                <ClientServiceItems
                    data={services}
                />
            ) : (
                <Preloader />
            )}
        </div>
    )
})
