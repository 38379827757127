import clsx from 'clsx'
import './ClientDataItem.scss'
import { ClientResponse } from '~/API/clientApi/client.types'
import {
    ItemType,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientOrganization/ClientOrganization.types'
import { fetchLocalDate } from '~/shared/util/currentTime'

interface ClientDataItemProps {
    className?: string
    option: ClientResponse<ItemType> | ItemType
    handleSetData?: (value: any) => void
    currentItem: ClientResponse<ItemType> | ItemType
}

const checkItem = (option: ClientResponse<ItemType> | ItemType, currentItem: ClientResponse<ItemType> | ItemType) => {
    if ('additionalParams' in option && 'additionalParams' in currentItem) {
        return option.id === currentItem.id
    }
    if ('value' in option && 'value' in currentItem) {
        return option?.value === currentItem?.value
    }
}

const itemView = (option: ClientResponse<ItemType> | ItemType) => {
    if ('sign' in option) {
        switch (option.sign) {
            case 'date':
                return fetchLocalDate(option.text).format('DD.MM.YYYY • HH:mm')
            default:
                return
        }
    }
    if ('additionalParams' in option) {
        return option.additionalParams?.text
    } else {
        return option?.text
    }
}

export const ClientDataItem = (props: ClientDataItemProps) => {
    const {
        className,
        option,
        handleSetData,
        currentItem,
    } = props

    return (
        <li
            className={clsx('client-data-item', className, { 'client-data-item--checked': checkItem(option, currentItem) })}
            onClick={() => ('additionalParams' in option ? option.additionalParams?.value : option?.value) !== 'none' && handleSetData && handleSetData(option)}
        >
            <span className='client-data-item__title'>
                {itemView(option)}
                {/* {'additionalParams' in option ? option.additionalParams?.text : option?.text}*/}
            </span>
        </li>
    )
}
