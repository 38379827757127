import { DaDataParty } from 'react-dadata'
import { fetchPath } from '~/API/lib/path/path'
import { DaDataSuggestionValues } from '~/components/common/inputs/CustomOrganizationInput/CustomOrganizationInput'
import { rtkApi } from '~/shared/api/rtkApi'

const path = fetchPath('contractor')

const contractorApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAllContractors: build.query<DaDataSuggestionValues<DaDataParty>[], undefined>({
            query: () => ({
                method: 'GET',
                url: path + '/all',
            }),
            providesTags: ['Organization'],
        }),
        fetchContractorById: build.query<DaDataSuggestionValues<DaDataParty>, string>({
            query: (id) => ({
                method: 'GET',
                url: path + '/' + id,
            }),
            providesTags: ['Organization'],
        }),
        // todo make types
        saveContractor: build.mutation<any, any>({
            query: (organization) => ({
                method: 'POST',
                url: path + '/save',
                body: organization,
            }),
            invalidatesTags: ['Organization'],
        }),
    }),
})

export const {
    useLazyFetchContractorByIdQuery,
    useLazyFetchAllContractorsQuery,
    useSaveContractorMutation,
} = contractorApi


