import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface KpoSliceState {
    isLoading: boolean
    handleSubmit: Function
    resetForm: Function
}

const kpoSliceInitialState: KpoSliceState = {
    isLoading: false,
    handleSubmit: () => {
    },
    resetForm: () => {
    },
}

const kpoSlice = createSlice({
    name: 'kpo',
    initialState: kpoSliceInitialState,
    reducers: {
        setKpoIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setKpoHandleSubmit: (state, action: PayloadAction<Function>) => {
            state.handleSubmit = action.payload
        },
        setKpoResetForm: (state, action: PayloadAction<Function>) => {
            state.resetForm = action.payload
        },
    },
})

export const {
    setKpoIsLoading,
    setKpoHandleSubmit,
    setKpoResetForm,
} = kpoSlice.actions

export default kpoSlice.reducer
