import { Division } from '~/API/userApi/user.types'
import { User } from '~/API/userApi/user.types'
import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import { rules } from '~/shared/order/rules'
import { StageManager, VERSIONS } from '~/shared/order/StageManager'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { returningDocuments } from '~/shared/templates/inputTemplates/returningDocuments'
import { additionalInputs } from '~/shared/templates/inputTemplates/v2/additionalInputs'

export const CONTRACTOR_BILLING_ACTIONS = {
    mainActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'DOCUMENTS_RETURNING',
                text: 'Отправить на возврат документов',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
}

export const CONTRACTOR_BILLING_SECTIONS = {
    contractPhoto: (disabled: boolean) => ({
        disabled: disabled,
        name: 'contractorAccountingPhoto',
        title: 'Скан договора',
        type: 'document',
        button: 'Добавить файл',
        parentClassName: 'thin-content',
        usingData: 'documents.contractorAccounting.contractorAccountingPhoto',
        required: true,
        allowedFileTypes: ['application', 'image'],
        version: VERSIONS.V2,
    }),
    actUpdPhoto: (disabled: boolean) => ({
        disabled: disabled,
        name: 'contractorAccountingActPhoto',
        title: 'Скан акта / УПД',
        type: 'document',
        button: 'Добавить файл',
        parentClassName: 'thin-content',
        usingData: 'documents.contractorAccounting.contractorAccountingActPhoto',
        required: true,
        allowedFileTypes: ['application', 'image'],
        version: VERSIONS.V2,
    }),
    paymentPhoto: (disabled: boolean) => ({
        disabled: disabled,
        name: 'contractorAccountingPaymentPhoto',
        title: 'Платежное поручение',
        type: 'document',
        button: 'Добавить файл',
        usingData: 'documents.contractorAccounting.contractorAccountingPaymentPhoto',
        required: true,
        allowedFileTypes: ['application', 'image'],
        version: VERSIONS.V2,
    }),
    actualPayment: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Факт. сумма постоплаты',
        name: 'actualPayment',
        className: 'input33',
        type: 'text',
        mask: InputMask.COST,
        version: VERSIONS.V2,
    }),
}

interface CONTRACTOR_BILLING_PARAMS {
    order: any
    businessType: Division
    reportDate: Date
    stages: string[]
    currentStage: string
    user: User
    archived: boolean
    nextStageName: string
}

export const CONTRACTOR_BILLING = (params: CONTRACTOR_BILLING_PARAMS) => {
    const {
        order,
        businessType,
        reportDate,
        stages,
        currentStage,
        user,
        archived,
        nextStageName,
    } = params
    const newInputs = []
    if (rules[user?.role]?.sections.returningDocuments) {
        const disabled = stages.includes('DOCUMENTS_RETURNING')
        const availableAction = StageManager.checkAvailableStageByRole('CONTRACTOR_BILLING', user.role)

        if (reportDate) {
            newInputs.push([
                additionalInputs.division(true),
                additionalInputs.responsibleUserId(true, 'contractorAccounting.division', 'responsibleManager'),
                additionalInputs.reportDate(true),
            ])
        } else {
            newInputs.push([
                additionalInputs.division(true),
            ])
        }

        newInputs.push([
            CONTRACTOR_BILLING_SECTIONS.actualPayment(disabled),
            CONTRACTOR_BILLING_SECTIONS.paymentPhoto(disabled),
        ])
        newInputs.push([CONTRACTOR_BILLING_SECTIONS.contractPhoto(disabled), CONTRACTOR_BILLING_SECTIONS.actUpdPhoto(disabled)])

        if (availableAction) {
            const action = fetchFormAction({
                order,
                archived,
                section: 'CONTRACTOR_BILLING',
                user,
                currentStage,
            })
            if (action) {
                newInputs.push([action])
            }
        }
    }
    return {
        title: 'Расчет с подрядчиком',
        name: 'contractorAccounting',
        inputs: [...newInputs],
    }
}
