import React, { memo } from 'react'
import './InteractiveButton.scss'
import clsx from 'clsx'

interface InteractiveButtonProps {
    title: string,
    caption?: number | string,
    color?: string,
    tooltip?: string,
    onClick?: () => void,
}

const InteractiveButton = memo((props: InteractiveButtonProps) => {
    const {
        title,
        caption,
        color,
        tooltip,
        onClick,
    } = props

    return (
        <div className='interactiveButton'>
            <button
                className={clsx('button', 'button_border', color)}
                data-tooltip={tooltip}
                type='button'
                onClick={onClick}
            >
                <span>{title}: </span>
                {
                    typeof caption === 'number' || typeof caption === 'string' ?
                        caption :
                        'Загрузка...'
                }
            </button>
        </div>
    )
})

export default InteractiveButton
