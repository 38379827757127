import Skeleton from '@mui/material/Skeleton'
import Tabs from '@mui/material/Tabs'
import { FaAngleRight } from '@react-icons/all-files/fa/FaAngleRight'
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight'
import clsx from 'clsx'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Location, useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom'
import PopupPrompt from '../../../../common/popups/PopupPrompt/PopupPrompt'
import Preloader from '../../../../common/preloaders/Preloader/Preloader'
import {
    CustomTabNavbar,
    CustomTabNavbarData,
} from '../../../../common/ReusingComponents/CustomTabNavbar/CustomTabNavbar'
import OrderDocuments from '../OrderDocuments/OrderDocuments'
import OrderStatusBar from '../OrderStatusBar/OrderStatusBar'
import { ERROR_CODES } from '~/API/orderApi/types/order.types'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import MobileModalSwipe
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileModalSwipe/MobileModalSwipe'
import MobileOrderDocuments
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileOrderDocuments/MobileOrderDocuments'
import { useCreateNewOrder } from '~/hooks/useCreateOrder/useCreateNewOrder'
import { useDynamicImportStageTemplate } from '~/hooks/useDynamicImportStageTemplate/useDynamicImportStageTemplate'
import { useFetchExistOrder } from '~/hooks/useFetchExistOrder/useFetchExistOrder'
import { isSectionDisabled } from '~/hooks/useFormConstructor/lib/additionalHOC/withSectionBody'
import { useFormConstructor } from '~/hooks/useFormConstructor/useFormConstructor'
import { Data } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { useHandleFormAction } from '~/hooks/useHandleFormAction/useHandleFormAction'
import { useSubmitOrder } from '~/hooks/useSubmitOrder/useSubmitOrder'
import { selectFormAction, selectUser } from '~/redux/selectors/appSlice.selectors'
import {
    selectActiveOrder,
    selectMenuType,
    selectOrderError,
    selectOrderLoader,
    selectStageName,
} from '~/redux/selectors/orderSlice.selectors'
import { selectSmetaPaymentType } from '~/redux/selectors/smetaSlise.selectors'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import { FormActionEnum } from '~/redux/slices/appSlice/appSlice.types'
import {
    CHANGE_MENU_TYPE,
    CHANGE_ORDER_LOADER,
    clearOrder,
    setEventEmitter,
    setSectionName,
} from '~/redux/slices/orderSlice/orderSlice'
import { MenuTypes } from '~/redux/slices/orderSlice/types/orderSlice.type'
import { setPaymentType } from '~/redux/slices/smetaSlice/smetaSlice'
import { useAppDispatch } from '~/redux/store'
import { handleNavbarClick } from '~/shared/order/orderManager/orderManager'
import { defineStageAction } from '~/shared/order/orderUtil'
import { REPORT_STAGE_TYPE, StageManager, VERSIONS } from '~/shared/order/StageManager'
import { isMobile } from '~/shared/util/deviceDetect'
import eventEmitter from '~/shared/util/eventEmmiter'
import './OrderCreator.scss'

interface PromptParams {
    condition: boolean
    isAlwaysSave?: boolean
    text?: string
    title?: string
}

const OrderCreator = () => {
    const { id = '' } = useParams()
    const reactLocation = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const activeOrder = useSelector(selectActiveOrder)
    const user = useSelector(selectUser)
    const orderMenuType = useSelector(selectMenuType)
    const isLoading = useSelector(selectOrderLoader)
    const formAction = useSelector(selectFormAction)
    const orderError = useSelector(selectOrderError)
    const currentStageName = useSelector(selectStageName)

    // TODO: зарефакторить после релиза, сейчас нужно для подставки типа оплаты в договоры при флоу с осмотром
    const paymentType = useSelector(selectSmetaPaymentType)

    const fetchExistOrder = useFetchExistOrder()
    const createNewOrder = useCreateNewOrder()
    const dynamicImportInputsTemplate = useDynamicImportStageTemplate()

    const [inputsTemplate, setInputsTemplate] = useState<{
        main: Record<string, Data>
        report?: Record<string, Data>
    } | null>(null)

    const [location, setLocation] = useState('')

    const [isViewerVisible, setViewerVisible] = useState<boolean>(false)
    const [isPrompt, setPrompt] = useState<PromptParams>({ condition: false })
    const [isWarning, setWarning] = useState(false)
    const [isNeedHistoryPush, setIsNeedHistoryPush] = useState(false)
    const [isFormValid, setFormValid] = useState<boolean>(false)
    const [isMainModalOpen, setMainModal] = useState(false)

    const [tabIndex, setTabIndex] = useState(0)
    const [mainTabsIndex, setMainTabsIndex] = useState(0)

    const handleButtonSubmitClick = useSubmitOrder({
        id,
        setWarning,
    })

    const handleSetFormValid = (value: boolean) => {
        setFormValid(value)
    }

    const {
        createdInputs,
        documents,
        isDirty,
        methods: {
            reset,
            setValue,
            clearErrors,
            handleSubmit,
            getValues,
        },
    } = useFormConstructor({
        inputsTemplate,
        order: activeOrder,
        functionForSubmitOrder: handleButtonSubmitClick,
        handleSetFormValid,
    })

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => {
            return isDirty && currentLocation.pathname !== nextLocation.pathname && !nextLocation.state?.skipBlocker
        },
    )

    const handleFormAction = useHandleFormAction({
        getValues,
        reset,
        clearErrors,
        id,
        isFormValid,
        handleButtonSubmitClick,
        handleSubmit,
    })

    const openMainModal = useCallback(() => {
        setMainModal(true)
    }, [])

    const closeMainModal = useCallback(() => {
        setMainModal(false)
    }, [])

    const handleChangeMainTabsIndex = useCallback((index: number) => {
        setMainTabsIndex(index)
    }, [])

    const tabs: CustomTabNavbarData[] = useMemo(() => {
        if (activeOrder) {
            const stages = StageManager.fetchStages(activeOrder?.stageName, VERSIONS.V2, REPORT_STAGE_TYPE.ALL)
            const tabs = [
                {
                    value: MenuTypes.MENU_MAIN,
                    title: 'Планирование',
                    isActive: orderMenuType === MenuTypes.MENU_MAIN || orderMenuType === MenuTypes.MENU_DOCUMENTS,
                },
            ]
            if (stages.includes('DEPARTURE_CONFIRMATION')) {
                tabs.push({
                    value: MenuTypes.MENU_REPORT,
                    title: 'Фактирование',
                    isActive: orderMenuType === MenuTypes.MENU_REPORT,
                })
            }
            return tabs
        }
        return []
    }, [activeOrder, orderMenuType])

    const handleBlockNavigation = (nextLocation: Location<any>) => {
        if (!location) {
            setLocation(nextLocation.pathname)
        }
        setPrompt({
            condition: true,
            isAlwaysSave: nextLocation.state?.alwaysSave,
            text: nextLocation.state?.text,
            title: nextLocation.state?.title,
        })
    }

    const handleCloseViewer = useCallback(() => {
        setViewerVisible(false)
    }, [])

    const handleClosePrompt = () => {
        setPrompt({ condition: false })
        setLocation('')
        if (blocker.state === 'blocked') blocker.reset()
    }

    const handleCancelPrompt = () => {
        setPrompt({ condition: false })
        setLocation('')
        reset({}, { keepValues: true })
        if (blocker.state === 'blocked') blocker.proceed()
    }

    const handleSubmitPrompt = () => {
        setPrompt({ condition: false })
        setIsNeedHistoryPush(true)
        dispatch(changeFormAction({ action: 'SAVE_FORM', isSaveButton: true }))
    }

    const handleSubmitEditWarning = () => {
        dispatch(CHANGE_ORDER_LOADER(true))
        handleButtonSubmitClick(isFormValid, getValues())
        setWarning(false)
    }

    const handleCancelEditWarning = () => {
        setWarning(false)
    }

    useEffect(() => {
        switch (tabIndex) {
            case 0: {
                dispatch(CHANGE_MENU_TYPE('MENU_MAIN'))
                break
            }
            case 1: {
                dispatch(CHANGE_MENU_TYPE('MENU_DOCUMENTS'))
                break
            }
            case 2: {
                dispatch(CHANGE_MENU_TYPE('MENU_REPORT'))
                break
            }
            default: {
                break
            }
        }
    }, [tabIndex])

    useEffect(() => {
        if (blocker.state === 'blocked') {
            handleBlockNavigation(blocker.location)
        }
    }, [blocker.state])

    useEffect(() => {
        if (!id) {
            if (user) createNewOrder(user.id)
        } else {
            fetchExistOrder(id)
        }
    }, [id])

    useEffect(() => {
        if (currentStageName && defineStageAction(formAction.action, currentStageName) === FormActionEnum.HANDLE_SUBMIT) {
            dynamicImportInputsTemplate(currentStageName)
                .then((res) => {
                    dispatch(setSectionName(Object.keys(res.main)[Object.keys(res.main).length - 1]))
                    setInputsTemplate(res)
                })
        }
    }, [currentStageName])

    useEffect(() => {
        handleFormAction()
    }, [formAction])

    useLayoutEffect(() => {
        if (id && activeOrder) {
            const stages = StageManager.fetchStages(activeOrder?.stageName, VERSIONS.V2, REPORT_STAGE_TYPE.ALL)
            document.title = `${import.meta.env.MODE === 'production' ? '' : 'Dev. '}Заявка ${id ? '№' + id.substring(id.length - 8) : ''} ${activeOrder?.archived ? '(в архиве)' : ''}`
            if (stages.includes('DEPARTURE_CONFIRMATION') && reactLocation?.state?.redirect != false) {
                handleNavbarClick(MenuTypes.MENU_REPORT, dispatch, navigate, id)
            } else {
                dispatch(CHANGE_MENU_TYPE(MenuTypes.MENU_MAIN))
            }
        }
        if (activeOrder) {
            if (import.meta.env.MODE !== 'production') console.log('activeOrder', activeOrder)
            reset({})
        }
        if (activeOrder?.accountingAndSelling?.commercialOffer?.paymentType) {
            dispatch(setPaymentType(activeOrder?.accountingAndSelling?.commercialOffer?.paymentType))
        }
    }, [activeOrder])

    useEffect(() => {
        if (paymentType) {
            setValue('accountingAndSelling.paymentType', paymentType)
        }
    }, [paymentType])

    useEffect(() => {
        if (orderMenuType === MenuTypes.MENU_DOCUMENTS) {
            setMainModal(false)
        }
    }, [orderMenuType])

    useEffect(() => {
        if (!isLoading && isNeedHistoryPush) {
            setIsNeedHistoryPush(false)
            if (blocker.state === 'blocked') blocker.proceed()
        }
    }, [isLoading])

    useEffect(() => {
        return () => {
            if (blocker.state === 'blocked') blocker.reset()
            dispatch(clearOrder())
            reset({})
        }
    }, [])

    // event emitters
    useEffect(() => {
        const handleError = (errorCode: typeof ERROR_CODES[number]) => {
            dispatch(setEventEmitter({ code: errorCode, condition: true }))
        }

        ERROR_CODES.forEach((code) => {
            eventEmitter.on(code, () => handleError(code))
        })

        return () => {
            ERROR_CODES.forEach((code) => {
                eventEmitter.removeListener(code, () => handleError(code))
            })
        }
    }, [dispatch])

    return (
        <>
            {isPrompt.condition &&
                <PopupPrompt
                    onCancel={handleCancelPrompt}
                    onSubmit={handleSubmitPrompt}
                    onClose={handleClosePrompt}
                    isAlwaysSave={isPrompt.isAlwaysSave}
                    title={isPrompt.title}
                    text={isPrompt.text || 'Внесенные изменения не сохранены. Хотите сохранить?'}
                />
            }
            {isWarning &&
                <PopupPrompt
                    onCancel={handleCancelEditWarning}
                    onSubmit={handleSubmitEditWarning}
                    onClose={handleCancelEditWarning}
                    text='Заявка была изменена другим пользователем. Хотите продолжить?'
                />
            }
            {isLoading || orderError ? (
                <div className={isMobile ? 'mobile-order__error' : 'browser-order__error'}>
                    {!orderError ? (
                        <Preloader />
                    ) : (
                        <p>{orderError}</p>
                    )}
                </div>
            ) : (
                !isMobile ? activeOrder && (
                    <div className='browser-order'>
                        {!isLoading && (
                            <CustomTabNavbar
                                data={tabs}
                                onChange={(value) => handleNavbarClick(value, dispatch, navigate, id)}
                            />
                        )}
                        <header className='browser-order__header'>
                            <div className='browser-order__header__row'>
                                <div className='browser-order__title'>
                                    <div className='browser-order__title__main'>
                                        {isLoading ? (
                                            <Skeleton variant='text' animation='wave'>
                                                <h2>Заявка №ae1e4b34</h2>
                                            </Skeleton>
                                        ) : (
                                            <>
                                                <h2>{`Заявка ${id ? '№' + id.substring(id.length - 8) : ''} ${activeOrder.archived ? '(в архиве)' : ''}`}</h2>
                                                <OrderStatusBar type={activeOrder.section} />
                                            </>
                                        )}
                                    </div>
                                </div>
                                {documents && (orderMenuType !== MenuTypes.MENU_DOCUMENTS ? (
                                    <CustomButton
                                        onClick={() => dispatch(CHANGE_MENU_TYPE(MenuTypes.MENU_DOCUMENTS))}
                                        theme={CustomButtonThemes.SMOKE}
                                        className='browser-order__header__document-btn'
                                    >
                                        Все документы <FaChevronRight />
                                    </CustomButton>
                                ) : (
                                    <CustomButton
                                        onClick={() => dispatch(CHANGE_MENU_TYPE(MenuTypes.MENU_MAIN))}
                                        theme={CustomButtonThemes.SMOKE}
                                        className='browser-order__header__document-btn'
                                    >
                                        Назад к заявке <FaChevronRight />
                                    </CustomButton>
                                ))}
                            </div>
                            {orderMenuType === 'MENU_DOCUMENTS' &&
                                <div className='browser-order__header__row'>
                                    <button
                                        className='browser-order__header__delete-btn'
                                        onClick={() => setViewerVisible(true)}
                                    >
                                        <FaTrashAlt className='browser-order__header__trash-icon' />
                                        Удалить документы
                                    </button>
                                </div>
                            }
                        </header>
                        {orderMenuType === MenuTypes.MENU_MAIN && createdInputs?.main}
                        {orderMenuType === MenuTypes.MENU_DOCUMENTS && documents &&
                            <OrderDocuments
                                documents={documents}
                                setValue={setValue}
                                getValues={getValues}
                                viewer={isViewerVisible}
                                handleCloseViewer={handleCloseViewer}
                                currentStage={activeOrder.stageName}
                            />
                        }
                        {orderMenuType === MenuTypes.MENU_REPORT && createdInputs?.report}
                    </div>
                ) : activeOrder && (
                    <div className='mobile-order'>
                        <div className='mobile-order__header'>
                            <h2>
                                {`Заявка ${id ? '№' + id.substring(id.length - 8) : ''} ${activeOrder.archived ? '(в архиве)' : ''}`}
                            </h2>
                            <div className='mobile-order__statusbar__container'>
                                {activeOrder.stageName && (
                                    <OrderStatusBar
                                        className='mobile-order__statusbar'
                                        type={activeOrder.section}
                                    />
                                )}
                                {documents && (orderMenuType !== MenuTypes.MENU_DOCUMENTS ? (
                                    <CustomButton
                                        onClick={() => dispatch(CHANGE_MENU_TYPE(MenuTypes.MENU_DOCUMENTS))}
                                        theme={CustomButtonThemes.SMOKE}
                                        className='browser-order__header__document-btn'
                                    >
                                        Все документы <FaChevronRight />
                                    </CustomButton>
                                ) : (
                                    <CustomButton
                                        onClick={() => dispatch(CHANGE_MENU_TYPE(MenuTypes.MENU_MAIN))}
                                        theme={CustomButtonThemes.SMOKE}
                                        className='browser-order__header__document-btn'
                                    >
                                        Назад к заявке <FaChevronRight />
                                    </CustomButton>
                                ))}
                            </div>
                        </div>
                        <div className='mobile-order__navigator'>
                            <Tabs
                                value={tabIndex}
                                scrollButtons={true}
                                visibleScrollbar={false}
                            >
                                {tabs.map((tab, index: number) => {
                                    return (
                                        <button
                                            key={index + 'buttonNav'}
                                            className={clsx({ 'active': tabIndex === index })}
                                            onClick={() => {
                                                setTabIndex(index)

                                                // TODO remove this and make normal
                                                switch (tab.value) {
                                                    case 'MENU_REPORT':
                                                        navigate(`/report/${id}`, {
                                                            state: {
                                                                skipBlocker: true,
                                                            },
                                                        })
                                                        return
                                                    case 'MENU_MAIN':
                                                        navigate(`/order/${id}`, {
                                                            state: {
                                                                redirect: false,
                                                                skipBlocker: true,
                                                            },
                                                        })
                                                        return
                                                }
                                            }}
                                        >
                                            {tab.title}
                                        </button>
                                    )
                                })}
                            </Tabs>
                        </div>
                        <div className='mobile-order__content'>
                            {orderMenuType === MenuTypes.MENU_DOCUMENTS && documents && (
                                <div className='mobile-order__content__slide'>
                                    <div className='mobile-order__content__slide__body'>
                                        <MobileOrderDocuments
                                            documents={documents}
                                            setValue={setValue}
                                            getValues={getValues}
                                            currentStage={activeOrder.stageName}
                                        />
                                    </div>
                                </div>
                            )}
                            {orderMenuType === MenuTypes.MENU_MAIN && createdInputs?.main && (
                                <div className='mobile-order__content__slide'>
                                    {createdInputs?.main
                                        .filter((section) => !isSectionDisabled(section.props?.isDisabled, getValues))
                                        .map((section, index) => {
                                            return (
                                                <div
                                                    key={section.props.title}
                                                    className='mobile-order__content__slide__button'
                                                    onClick={() => {
                                                        openMainModal()
                                                        handleChangeMainTabsIndex(index)
                                                    }}
                                                >
                                                    <span>{section.props.title}</span>
                                                    <FaAngleRight />
                                                </div>
                                            )
                                        })}
                                </div>
                            )}
                        </div>

                        {createdInputs?.main && (
                            <MobileModalSwipe
                                index={mainTabsIndex}
                                onChangeIndex={handleChangeMainTabsIndex}
                                isOpen={isMainModalOpen}
                                onRequestClose={closeMainModal}
                                contentLabel='mainModal'
                                tabs={createdInputs?.main.filter((section) => !isSectionDisabled(section.props?.isDisabled, getValues))}
                            />
                        )}
                    </div>
                )
            )}
        </>
    )
}

export default OrderCreator
