import { useState } from 'react'

interface useLocalStorageParams {
    key: string
    initialValue?: string | boolean
}

function useLocalStorage(params: useLocalStorageParams) {
    const {
        key = 'app',
        initialValue,
    } = params
    const [storedValue, setStoredValue] = useState<any>(() => {
        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            return initialValue
        }
    })

    const setValue = (value: unknown) => {
        const valueToStore = value instanceof Function ? value(storedValue) : value

        if (typeof window !== 'undefined' && window) {
            try {
                setStoredValue(valueToStore)
                window.localStorage.setItem(key, JSON.stringify(valueToStore))
            } catch (error) {}
        } else {
            setStoredValue(valueToStore)
        }
    }

    const removeValue = () => {
        if (typeof window !== 'undefined' && window && window.localStorage.getItem(key)) {
            try {
                setStoredValue(null)
                window.localStorage.removeItem(key)
            } catch (error) {}
        } else {
            setStoredValue(null)
        }
    }

    return [storedValue, setValue, removeValue]
}

export default useLocalStorage
