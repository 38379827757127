import { AllStages } from '~/API/orderApi/types/order.types'
import { Role } from '~/API/userApi/user.types'
import { Data, InputParamsItem } from '~/hooks/useFormConstructor/useFormConstructor.types'

export const fakeStageManager = { // todo заменить на обычный stateManager после изменения названий стадий
    availableStageForRole: {
        /** stages */
        ORDER_CREATING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        COMMERCIAL_OFFER_APPROVING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        INSPECTION_DIVISION_APPROVING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        INSPECTION_APPROVING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        INSPECTION_CONTENT_UPLOADING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        INSPECTION_PAYMENT_APPROVING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        INSPECTION_STARTING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        CONTRACT_SIGNING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        CONTRACT_SIGNING_BILL: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        CONTRACT_SIGNING_INDIVIDUAL: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        PREPAYMENT_APPROVING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        TO_OFFICE_MANAGER_CONTRACT_CREATING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        WORKING_PLAN_APPROVING: ['ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER', 'OPERATOR'],
        CLEANING_DATE_CHANGING: ['ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER', 'OPERATOR'],
        APPROVE_NZ: ['ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        DEPARTURE_CONFIRMATION: ['ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        FILL_NZ_WITH_TASK: ['ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        DATE_BOOKING: ['ORDER_MANAGER', 'OPERATOR', 'CHIEF'],
        ORDER_APPROVING: ['ORDER_MANAGER', 'OPERATOR', 'CHIEF'],
        REPEAT_CLIENT_STARTING: ['ORDER_MANAGER', 'OPERATOR', 'CHIEF'],
        ORDER_CLOSED: ['CHIEF'],
        FINAL_REPORT: ['CHIEF'],
        MAINTENANCE_FINAL_REPORT: ['CHIEF'],
        SERVICES_VERIFICATION: ['CHIEF'],
        ADDITIONAL_SERVICES_CREATING: ['CHIEF'],
        OBJECT_REPORT_FILLING: ['CHIEF'],
        SERVICE_TABLE_FILLING: ['CHIEF'],
        WORKING_DAY_ENDING: ['CHIEF'],
        TEAM_REPORT_FILLING: ['CHIEF'],
        INVENTORY_ITEMS_RETURNING: ['CHIEF'],
        MANAGER_CHECKING: ['CHIEF'],
        DEFECTS_CORRECTING: ['CHIEF'],
        QUALITY_CONTROLLING: ['CHIEF'],
        DOCUMENTS_RETURNING: ['CHIEF'],
        WAREHOUSE_GOING: ['CHIEF'],
        CONTRACT_WORK_REPORT_FILLING: ['CHIEF'],
        CONTRACTOR_BILLING: ['CHIEF'],

        /** transition stages */
        NEED_CHANGES_TO_ORDER_CREATING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        NEED_CHANGES_TO_INSPECTION_DIVISION_APPROVING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        NEED_CHANGES_TO_CONTRACT_SIGNING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        NEED_CHANGES_TO_CONTRACT_SIGNING_WITH_DATE_CHANGING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        NEED_CHANGES_TO_WORKING_PLAN_APPROVING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        DATE_AND_TIME_CHANGING: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],

        /** documents generators */
        GENERATE_KP: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        GENERATE_CONTRACT: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        GENERATE_NZ: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],

        CHANGE_NZ: ['ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        test: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
        NO_VALUE: ['OPERATOR', 'ORDER_MANAGER', 'CHIEF', 'OFFICE_MANAGER'],
    },
    availableToSelectStageByRole: {
        CHIEF: [
            'ORDER_CREATING',
            'NEED_CHANGES_TO_ORDER_CREATING',
            'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING',
            'NEED_CHANGES_TO_INSPECTION_DIVISION_APPROVING',
            'COMMERCIAL_OFFER_APPROVING',
            'INSPECTION_DIVISION_APPROVING',
            'INSPECTION_APPROVING',
            'INSPECTION_CONTENT_UPLOADING',
            'INSPECTION_PAYMENT_APPROVING',
            'INSPECTION_STARTING',
            'CONTRACT_SIGNING',
            'CONTRACT_SIGNING_BILL',
            'CONTRACT_SIGNING_INDIVIDUAL',
            'PREPAYMENT_APPROVING',
            'TO_OFFICE_MANAGER_CONTRACT_CREATING',
            'WORKING_PLAN_APPROVING',
            'GENERATE_KP',
            'GENERATE_CONTRACT',
            'APPROVE_NZ',
            'DEPARTURE_CONFIRMATION',
            'CHANGE_NZ',
            'GENERATE_NZ',
            'FILL_NZ_WITH_TASK',
            'DATE_BOOKING',
            'ORDER_APPROVING',
            'REPEAT_CLIENT_STARTING',
            'NEED_CHANGES_TO_CONTRACT_SIGNING',
            'CLEANING_DATE_CHANGING',
            'DATE_AND_TIME_CHANGING',
            'MAINTENANCE_FINAL_REPORT',
            'test',
            'NO_VALUE',
            'CONTRACT_WORK_REPORT_FILLING',
            'CONTRACTOR_BILLING',
        ],
        ORDER_MANAGER: [
            'ORDER_CREATING',
            'NEED_CHANGES_TO_ORDER_CREATING',
            'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING',
            'NEED_CHANGES_TO_INSPECTION_DIVISION_APPROVING',
            'COMMERCIAL_OFFER_APPROVING',
            'INSPECTION_DIVISION_APPROVING',
            'INSPECTION_APPROVING',
            'INSPECTION_CONTENT_UPLOADING',
            'INSPECTION_PAYMENT_APPROVING',
            'INSPECTION_STARTING',
            'CONTRACT_SIGNING',
            'CONTRACT_SIGNING_BILL',
            'CONTRACT_SIGNING_INDIVIDUAL',
            'PREPAYMENT_APPROVING',
            'TO_OFFICE_MANAGER_CONTRACT_CREATING',
            'WORKING_PLAN_APPROVING',
            'GENERATE_KP',
            'GENERATE_CONTRACT',
            'APPROVE_NZ',
            'DEPARTURE_CONFIRMATION',
            'CHANGE_NZ',
            'GENERATE_NZ',
            'FILL_NZ_WITH_TASK',
            'DATE_BOOKING',
            'ORDER_APPROVING',
            'REPEAT_CLIENT_STARTING',
            'NEED_CHANGES_TO_CONTRACT_SIGNING',
            'CLEANING_DATE_CHANGING',
            'MAINTENANCE_FINAL_REPORT',
            'test',
            'DATE_AND_TIME_CHANGING',
            'CONTRACT_WORK_REPORT_FILLING',
            'NO_VALUE',
        ],
        OPERATOR: [
            'ORDER_CREATING',
            'NEED_CHANGES_TO_ORDER_CREATING',
            'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING',
            'NEED_CHANGES_TO_INSPECTION_DIVISION_APPROVING',
            'COMMERCIAL_OFFER_APPROVING',
            'INSPECTION_DIVISION_APPROVING',
            'INSPECTION_APPROVING',
            'INSPECTION_CONTENT_UPLOADING',
            'INSPECTION_PAYMENT_APPROVING',
            'INSPECTION_STARTING',
            'CONTRACT_SIGNING',
            'CONTRACT_SIGNING_BILL',
            'CONTRACT_SIGNING_INDIVIDUAL',
            'PREPAYMENT_APPROVING',
            'TO_OFFICE_MANAGER_CONTRACT_CREATING',
            'WORKING_PLAN_APPROVING',
            'GENERATE_KP',
            'GENERATE_CONTRACT',
            'APPROVE_NZ',
            'DEPARTURE_CONFIRMATION',
            'CHANGE_NZ',
            'GENERATE_NZ',
            'FILL_NZ_WITH_TASK',
            'DATE_BOOKING',
            'ORDER_APPROVING',
            'REPEAT_CLIENT_STARTING',
            'NEED_CHANGES_TO_CONTRACT_SIGNING',
            'test',
            'DATE_AND_TIME_CHANGING',
            'CONTRACT_WORK_REPORT_FILLING',
            'NO_VALUE',
        ],
        OFFICE_MANAGER: [
            'ORDER_CREATING',
            'NEED_CHANGES_TO_ORDER_CREATING',
            'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING',
            'NEED_CHANGES_TO_INSPECTION_DIVISION_APPROVING',
            'COMMERCIAL_OFFER_APPROVING',
            'INSPECTION_DIVISION_APPROVING',
            'INSPECTION_APPROVING',
            'INSPECTION_CONTENT_UPLOADING',
            'INSPECTION_PAYMENT_APPROVING',
            'INSPECTION_STARTING',
            'CONTRACT_SIGNING',
            'CONTRACT_SIGNING_BILL',
            'CONTRACT_SIGNING_INDIVIDUAL',
            'PREPAYMENT_APPROVING',
            'TO_OFFICE_MANAGER_CONTRACT_CREATING',
            'WORKING_PLAN_APPROVING',
            'GENERATE_KP',
            'GENERATE_CONTRACT',
            'APPROVE_NZ',
            'DEPARTURE_CONFIRMATION',
            'CHANGE_NZ',
            'GENERATE_NZ',
            'FILL_NZ_WITH_TASK',
            'REPEAT_CLIENT_STARTING',
            'NEED_CHANGES_TO_CONTRACT_SIGNING',
            'test',
            'DATE_AND_TIME_CHANGING',
            'CONTRACT_WORK_REPORT_FILLING',
            'NO_VALUE',
            'CONTRACTOR_BILLING',
        ],
    },
}

type FilterStageItemsByRoleArgs = {
    items: Record<string, Data>
    role: Role | undefined
    stage: StageType
}

export type StageType = typeof AllStages[number]

export const filterStageItemsByRole = ({ items, role, stage }: FilterStageItemsByRoleArgs): Record<string, Data> => {
    if (role === undefined) return {}
    const arrOfItemsKeys = Object.keys(items)
    return arrOfItemsKeys.reduce((result, key) => {
        const itemsSection = items[key]

        const sectionWithFilteredActionInputStages = itemsSection.inputs.map((rowArr) => {
            const actionInputIndex = rowArr.findIndex((obj) => obj.type === 'action')
            if (actionInputIndex !== -1) {
                const actionInputObj = rowArr[actionInputIndex]
                // Проверяем, должен ли быть action инпут для данной роли и этапа
                if (!fakeStageManager.availableStageForRole[stage as keyof typeof fakeStageManager.availableStageForRole].includes(role)) { // попали в кейс, когда action инпута под роль не должно быть
                    return [...rowArr.slice(0, actionInputIndex), ...rowArr.slice(actionInputIndex + 1)] // Просто удаляем объект с type === 'action'
                }
                if (Array.isArray(actionInputObj.items)) { // Попали в обычную структуру с массивом
                    const filteredItems = actionInputObj.items.filter((item) =>
                        fakeStageManager.availableToSelectStageByRole[role as keyof typeof fakeStageManager.availableToSelectStageByRole]
                            .includes(item.value as string),
                    )
                    return [
                        ...rowArr.slice(0, actionInputIndex),
                        { ...actionInputObj, items: filteredItems },
                        ...rowArr.slice(actionInputIndex + 1),
                    ]
                } else {// Попали в сложную структуру с объектом items
                    const filteredItems = Object.keys(actionInputObj.items).reduce((res: Record<string, InputParamsItem<any>[]>, key) => {
                        const filteredItemsForKey = (actionInputObj.items as any)[key].filter((item: InputParamsItem<any>) =>
                            fakeStageManager.availableToSelectStageByRole[role as keyof typeof fakeStageManager.availableToSelectStageByRole].includes(item.value as string),
                        )
                        return {
                            ...res,
                            [key]: filteredItemsForKey,
                        }
                    }, {})
                    return [
                        ...rowArr.slice(0, actionInputIndex),
                        { ...actionInputObj, items: filteredItems },
                        ...rowArr.slice(actionInputIndex + 1),
                    ]
                }
            }
            return rowArr
        })

        return {
            ...result,
            [key]: {
                ...itemsSection,
                inputs: sectionWithFilteredActionInputStages,
            },
        }
    }, {})
}
