import { useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Modal from 'react-modal'
import { AmortizationItem, InventoryItem } from '~/API/orderApi/types/accountingAndSelling.type'
import { ToolGroupEnum } from '~/API/toolsApi/tools.types'
import BackButton from '~/components/common/ReusingComponents/BackButton/BackButton'
import { CustomMobileSlider } from '~/components/common/ReusingComponents/CustomSlider/CustomMobileSlider'
import {
    TabsTableHeaderSection,
    TabsTablePageType,
} from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import MobileTabs from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTabs/MobileTabs'
import MobileTitle from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTitle/MobileTitle'
import MobileTitleButton
    from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTitleButton/MobileTitleButton'
import MobileListValidation
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileListValidation/MobileListValidation'
import ToolsReportItem
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileToolsReport/subComponents/ToolsReportItem/ToolsReportItem'
import { useToolsReportSubscription } from '~/hooks/useToolsReportSubscription/useToolsReportSubscription'
import { get } from '~/shared/util/get'
import './MobileToolsReport.scss'
import { toolsTypes } from '~/shared/util/types'

interface MobileToolsReport {
    title: string
    name: string
    headerSections: TabsTableHeaderSection[]
    disabled?: boolean
    usingData?: string
}

const MobileToolsReport = (props: MobileToolsReport) => {
    const {
        title,
        name,
        disabled,
        headerSections,
        usingData,
    } = props

    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
    const [listModalOpen, setListModalOpen] = useState<boolean>(false)

    const {
        control,
        formState,
    } = useFormContext()

    const { fields } = useFieldArray({ control, name })
    const pages = fields as TabsTablePageType<InventoryItem | AmortizationItem>[]
    const filteredPages = useMemo(() => pages.filter((item) => item.items.length), [pages])

    const { handleSubscriptionFieldsChange } = useToolsReportSubscription(name)

    const pagesErrors = useMemo(() => get(formState.errors, name), [formState])

    const tabs = useMemo(() => filteredPages.map((page, index) => ({
        title: page.title,
        isError: !!pagesErrors?.[index],
    })), [pages, formState])

    const inputsMap = useMemo(() => {
        return headerSections.reduce((acc, item) => ({
            ...acc,
            [item.name]: item.inputs.filter((input) => !!input),
        }), {} as Record<string, any>)
    }, [headerSections])

    return (
        <>
            <MobileTitleButton
                title={title}
                onClick={() => setListModalOpen(true)}
                isError={!!pagesErrors}
            />
            <MobileListValidation
                baseName={name}
                pages={filteredPages}
                inputsMap={inputsMap}
            />
            <Modal
                id={`MobileToolsReport__${name}`}
                isOpen={listModalOpen}
                onRequestClose={() => setListModalOpen(false)}
                className='mobileServicesVerification__modal'
            >
                <BackButton callback={() => setListModalOpen(false)} />
                <MobileTitle title={title} />
                <MobileTabs
                    activeTabIndex={activeTabIndex}
                    tabs={tabs}
                    setActiveTabIndex={setActiveTabIndex}
                />
                <CustomMobileSlider
                    index={activeTabIndex}
                    onChangeIndex={setActiveTabIndex}
                    className='mobileCustomService__slider--active'
                >
                    {filteredPages.map((page, pageIndex) => (
                        <div key={page.id + pageIndex} className='mobileToolsReport__list'>
                            {page.items.map((_, rowIndex: number) => (
                                <ToolsReportItem
                                    key={rowIndex}
                                    inputName={`${name}.${pageIndex}.items.${rowIndex}`}
                                    disabled={!!disabled}
                                    inputs={inputsMap[page.type]}
                                    isError={!!pagesErrors?.[pageIndex]?.items?.[rowIndex]}
                                    sectionName={page.type}
                                    sectionTitle={toolsTypes[page.type as ToolGroupEnum]}
                                    rowIndex={rowIndex}
                                    usingData={usingData}
                                    onSubscriptionFieldChange={handleSubscriptionFieldsChange}
                                />
                            ))}
                        </div>
                    ))}
                </CustomMobileSlider>
            </Modal>
        </>
    )
}

export default MobileToolsReport
