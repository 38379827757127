import { fetchDate } from './currentTime'
import { BusinessType } from '~/API/orderApi/types/order.types'

export const STANDARD_ORDER = (division: BusinessType, orderCreator: string) => {
    return {
        businessType: division,
        primaryInformation: {
            address: 'Московский пр-кт, д. 20',
            attractionSource: 'Контекстная реклама (Google)',
            balconies: [],
            bathroom: 2,
            buildingClass: 'Аптека',
            buildingType: 'Коммерческий объект',
            cleaningType: 'Разовая уборка',
            comment: 'это с автозаполнения пришло',
            contactInfo: [
                {
                    clientEmail: 'example@mail.ru',
                    clientPost: 'Самый главный директор',
                    clientFullname: 'Андрюха',
                    clientPhone: '9009090123',
                },
            ],
            crmLink: 'https://h2ocleaning.bitrix24.ru/crm/deal/details/43836/',
            city: 'Санкт-Петербург',
            clientType: 'Новый',
            counterparty: 'Юр. лицо',
            objectArea: 2000,
            orderCreatedAt: fetchDate().toDate(),
            orderCreator,
            orderType: 'Уборка',
            serviceType: 'Генеральная уборка Luxe',
            services: [
                {
                    empty: false,
                    name: 'area',
                    title: 'Помещение',
                    values: null,
                },
            ],
            sourceType: 'Заявка',
        },
    }
}

export const tasksTemlpates = [
    {
        name: 'luxe',
        groups: [
            {
                name: 'glassesSingle',
                title: 'Окна с внутренней стороны',
                items: [
                    { name: 'Мытьё оконного остекления с внутренней стороны', description: '' },
                    { name: 'Мытьё входной группы и фурнитуры с внутренней стороны', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                ],
            },
            {
                name: 'glassesBoth',
                title: 'Окна с обеих сторон',
                items: [
                    {
                        name: 'Мытьё оконного остекления с двух сторон',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё входной группы и фурнитуры с двух сторон', description: '' },
                    { name: 'Мытьё откосов оконной конструкции, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                    { name: 'Мытьё решеток', description: 'Индивидуальный расчет' },
                ],
            },
            {
                name: 'balconySingle',
                title: 'Балконы с внутренней стороны',
                items: [
                    {
                        name: 'Мытьё балконного остекления',
                        description: '',
                    },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    { name: 'Сухая и влажная уборка всех поверхностей внутри и снаружи', description: '' },
                    { name: 'Сухая и влажная уборка осветительных приборов и деталей интерьера', description: '' },
                    { name: 'Уборка пола влажным и вакуумным способом', description: '' },
                    { name: 'Разбор вещей и вынос мусора', description: 'Индивидуальный расчет' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
            {
                name: 'balconyBoth',
                title: 'Балконы с обеих сторон',
                items: [
                    {
                        name: 'Мытьё балконного остекления',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    { name: 'Сухая и влажная уборка всех поверхностей внутри и снаружи', description: '' },
                    { name: 'Сухая и влажная уборка осветительных приборов и деталей интерьера', description: '' },
                    { name: 'Уборка пола влажным и вакуумным способом', description: '' },
                    { name: 'Разбор вещей и вынос мусора', description: 'Индивидуальный расчет' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
            {
                name: 'rooms',
                title: 'Комнаты',
                items: [
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    {
                        name: 'Мытье батарей/радиаторов внутри и снаружи',
                        description: 'Клиент разбирает самостоятельно',
                    },
                    {
                        name: 'Обеспыливание и влажная уборка всех потолочных осветительных приборов',
                        description: 'плафоны и лампочки не откручиваются, мытьё внутри по мере доступности',
                    },
                    { name: 'Сухая и влажная уборка шкафов и фасадов мебели', description: '' },
                    {
                        name: 'Обеспыливание и влажная уборка мебели внутри со всем содержимым',
                        description: 'По желанию Клиента',
                    },
                    {
                        name: 'Обеспыливание и влажная уборка предметов интерьера',
                        description: 'картины, статуэтки и пр.',
                    },
                    {
                        name: 'Очистка и придание блеска зеркалам, стеклянным и хромированным поверхностям',
                        description: '',
                    },
                    { name: 'Влажная уборка дверей, дверных откосов, ножек столов и стульев', description: '' },
                    { name: 'Обеспыливание и влажная уборка розеток и выключателей', description: '' },
                    { name: 'Обработка деревянных и кожаных предметов мебели полиролью', description: '' },
                    { name: 'Замена постельного белья и заправка кровати', description: 'По желанию Клиента' },
                    { name: 'Сухая и влажная уборка пола, плинтусов, углов', description: '' },
                    {
                        name: 'Обеспыливание и влажная уборка хрустальных/стеклянных декоративных люстр',
                        description: 'Индивидуальный расчет',
                    },
                    {
                        name: 'Чистка и полировка фарфоровой, хрустальной, антикварной посуды',
                        description: 'Индивидуальный расчет',
                    },
                ],
            },
            {
                name: 'kitchen',
                title: 'Кухня',
                items: [
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    {
                        name: 'Мытьё пространства за кухонным цоколем',
                        description: 'Демонтаж цоколя производится клиентом',
                    },
                    { name: 'Влажная уборка бытовой техники внутри и снаружи (по мере доступности)', description: '' },
                    {
                        name: 'Мытьё холодильника внутри и снаружи',
                        description: 'При необходимости мыть морозильную камеру - заранее разморозить',
                    },
                    { name: 'Очистка кухонного гарнитура внутри и снаружи', description: '' },
                    { name: 'Обезжиривание кухонного фартука и межплиточных швов', description: '' },
                    { name: 'Удаление загрязнений с варочной панели', description: '' },
                    { name: 'Мытьё посуды', description: 'По желанию Клиента' },
                    { name: 'Очистка мусорной корзины (замена пакета, вынос мусора)', description: '' },
                    {
                        name: 'Сухая и влажная уборка пола, плинтусов, углов, чистка межплиточных швов (ручным и/или мех. способом)',
                        description: '',
                    },
                ],
            },
            {
                name: 'bath',
                title: 'Санузел',
                items: [
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен', description: '' },
                    {
                        name: 'Сухая и влажная уборка осветительных приборов и предметов интерьера (плафоны и лампочки не откручиваются, мытьё внутри по доступности)',
                        description: '',
                    },
                    {
                        name: 'Обеспыливание и влажная уборка мебели внутри со всем содержимым',
                        description: 'По желанию Клиента',
                    },
                    { name: 'Чистка стиральной машины (резинка и отсек для порошка)', description: '' },
                    { name: 'Санитарная обработка санузла (унитаз, раковина, ванная/душевая и пр.)', description: '' },
                    { name: 'Доведение до блеска смесителей, стеклянных и зеркальных поверхностей', description: '' },
                    { name: 'Удаление плесени и грибка, обработка дезинфицирующим средством', description: '' },
                    { name: 'Сухая и влажная уборка пола, углов', description: '' },
                    {
                        name: 'Обработка межплиточных швов ручным и/или мех. способом на полу и на стенах',
                        description: '',
                    },
                ],
            },
        ],
    },
    {
        name: 'postConstruction',
        groups: [
            {
                name: 'glassesSingle',
                title: 'Окна с внутренней стороны',
                items: [
                    { name: 'Мытьё оконного остекления с внутренней стороны', description: '' },
                    { name: 'Мытьё входной группы и фурнитуры с внутренней стороны', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    {
                        name: 'Удаление заводской плёнки с оконных конструкция ("прикипевшая" плёнка по возможности)',
                        description: '',
                    },
                    { name: 'Очистка локальных загрязнений(скотч, наклейки и т.п.)', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                ],
            },
            {
                name: 'glassesBoth',
                title: 'Окна с обеих сторон',
                items: [
                    {
                        name: 'Мытьё оконного остекления с двух сторон',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё входной группы и фурнитуры с двух сторон', description: '' },
                    { name: 'Мытьё откосов оконной конструкции, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    { name: 'Очистка локальных загрязнений (скотч, наклейки и т.п.)', description: '' },
                    {
                        name: 'Удаление заводской плёнки с оконных конструкция ("прикипевшая" плёнка по возможности)',
                        description: '',
                    },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                    { name: 'Мытьё решеток', description: 'Индивидуальный расчет' },
                ],
            },
            {
                name: 'balconySingle',
                title: 'Балконы с внутренней стороны',
                items: [
                    {
                        name: 'Мытьё балконного остекления',
                        description: '',
                    },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    { name: 'Сухая и влажная уборка всех поверхностей внутри и снаружи', description: '' },
                    { name: 'Сухая и влажная уборка осветительных приборов и деталей интерьера', description: '' },
                    { name: 'Уборка пола влажным и вакуумным способом', description: '' },
                    { name: 'Вынос малогабартиного мусора', description: 'При наличии мусорного бака на территории' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
            {
                name: 'balconyBoth',
                title: 'Балконы с обеих сторон',
                items: [
                    {
                        name: 'Мытьё балконного остекления',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    { name: 'Сухая и влажная уборка всех поверхностей внутри и снаружи', description: '' },
                    { name: 'Сухая и влажная уборка осветительных приборов и деталей интерьера', description: '' },
                    { name: 'Уборка пола влажным и вакуумным способом', description: '' },
                    { name: 'Вынос малогабартиного мусора', description: 'При наличии мусорного бака на территории' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
            {
                name: 'rooms',
                title: 'Комнаты',
                items: [
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    {
                        name: 'Мытье батарей/радиаторов внутри и снаружи',
                        description: 'Клиент разбирает самостоятельно',
                    },
                    {
                        name: 'Обеспыливание и влажная уборка всех потолочных осветительных приборов',
                        description: 'Плафоны и лампочки не откручиваются, мытьё внутри по мере доступности',
                    },
                    { name: 'Сухая и влажная уборка шкафов и фасадов мебели', description: '' },
                    { name: 'Обеспыливание и влажная уборка мебели внутри', description: 'По желанию Клиента' },
                    {
                        name: 'Обеспыливание и влажная уборка предметов интерьера',
                        description: 'картины, статуэтки и пр.',
                    },
                    {
                        name: 'Очистка и придание блеска зеркалам, стеклянным и хромированным поверхностям',
                        description: '',
                    },
                    { name: 'Влажная уборка дверей, дверных откосов, ножек столов и стульев', description: '' },
                    { name: 'Обеспыливание и влажная уборка розеток и выключателей', description: '' },
                    { name: 'Сухая и влажная уборка пола, плинтусов, углов', description: '' },
                    {
                        name: 'Удаление локальных послестроительных загрязнений (клей, краска, шпаклевка, наклейки и пр.)',
                        description: '',
                    },
                    { name: 'Вынос малогабартиного мусора', description: 'При наличии мусорного бака на территории' },
                    {
                        name: 'Обеспыливание и влажная уборка хрустальных/стеклянных декоративных люстр',
                        description: 'Индивидуальный расчет',
                    },
                ],
            },
            {
                name: 'kitchen',
                title: 'Кухня',
                items: [
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    {
                        name: 'Обеспыливание и влажная уборка всех потолочных осветительных приборов ',
                        description: 'Плафоны и лампочки не откручиваются, мытьё внутри по мере доступности',
                    },
                    {
                        name: 'Обеспылевание и влажная уборка бытовой техники внутри и снаружи (по мере доступности)',
                        description: '',
                    },
                    { name: 'Обеспыливание и влажная уборка вентиляционных отверстий и вытяжек', description: '' },
                    {
                        name: 'Обеспыливание и влажная уборка кухонного гарнитура внутри и снаружи (удаление пленки и наклеек)',
                        description: '',
                    },
                    { name: 'Обеспыливание и влажная уборка кухонного фартука и межплиточных швов', description: '' },
                    {
                        name: 'Мытьё пространства за кухонным цоколем',
                        description: 'Демонтаж цоколя производится клиентом',
                    },
                    {
                        name: 'Сухая и влажная уборка пола, плинтусов, углов, чистка межплиточных швов (ручным и/или мех. способом)',
                        description: '',
                    },
                    {
                        name: 'Удаление локальных послестроительных загрязнений (клей, краска, шпаклевка, наклейки и пр.)',
                        description: '',
                    },
                ],
            },
            {
                name: 'bath',
                title: 'Санузел',
                items: [
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен', description: '' },
                    {
                        name: 'Сухая и влажная уборка осветительных приборов и предметов интерьера (плафоны и лампочки не откручиваются, мытьё внутри по доступности)',
                        description: '',
                    },
                    { name: 'Обеспыливание и влажная уборка мебели внутри', description: 'По желанию Клиента' },
                    {
                        name: 'Снятие плёнки/наклеек и влажная уборка сантехники (унитаз, раковина, ванная/душевая и пр.)',
                        description: '',
                    },
                    {
                        name: 'Снятие плёнки и доведение до блеска смесителей, стеклянных и зеркальных поверхностей',
                        description: '',
                    },
                    { name: 'Сухая и влажная уборка пола, углов', description: '' },
                    {
                        name: 'Обработка межплиточных швов ручным и/или мех. способом на полу и на стенах',
                        description: '',
                    },
                ],
            },
        ],
    },
    {
        name: 'maintenance',
        groups: [
            {
                name: 'rooms',
                title: 'Комнаты',
                items: [
                    {
                        name: 'Сухая и влажная уборка шкафов и фасадов мебели снаружи по росту клинера',
                        description: '',
                    },
                    {
                        name: 'Очистка и придание блеска зеркалам, стеклянным и хромированным поверхностям',
                        description: '',
                    },
                    { name: 'Влажная уборка дверей, дверных откосов, ножек столов и стульев', description: '' },
                    { name: 'Обеспыливание и влажная уборка розеток и выключателей', description: '' },
                    { name: 'Замена постельного белья и заправка кровати', description: 'По желанию Клиента' },
                    { name: 'Обеспыливание и влажная уборка поверхностей, поддержание чистоты', description: '' },
                    { name: 'Сухая и влажная уборка пола, плинтусов, углов', description: '' },
                ],
            },
            {
                name: 'kitchen',
                title: 'Кухня',
                items: [
                    { name: 'Влажная уборка бытовой техники снаружи', description: '' },
                    { name: 'Очистка кухонного гарнитура и другой мебели снаружи', description: '' },
                    { name: 'Обезжиривание кухонного фартука ', description: '' },
                    { name: 'Удаление загрязнений с варочной панели', description: '' },
                    { name: 'Мытье грязной посуды', description: '' },
                    { name: 'Очистка мусорной корзины (замена пакета, вынос мусора)', description: '' },
                    { name: 'Влажная уборка дверей, дверных откосов, ножек столов и стульев', description: '' },
                    { name: 'Обеспыливание и влажная уборка розеток и выключателей', description: '' },
                    { name: 'Сухая и влажная уборка пола, плинтусов, углов', description: '' },
                ],
            },
            {
                name: 'bath',
                title: 'Санузел',
                items: [
                    { name: 'Сухая и влажная уборка стен по росту клинера', description: '' },
                    { name: 'Сухая и влажная уборка мебели снаружи по росту клинера', description: '' },
                    { name: 'Влажная уборка стиральной машины снаружи', description: '' },
                    { name: 'Санитарная обработка санузла (унитаз, раковина, ванная/душевая)', description: '' },
                    { name: 'Доведение до блеска сантехники, стеклянных и зеркальных поверхностей', description: '' },
                    { name: 'Удаление ржавчины и налета', description: '' },
                    { name: 'Удаление плесени и грибка, обработка дезинфицирующим средством', description: '' },
                    { name: 'Сухая и влажная уборка пола, плинтусов, углов', description: '' },
                ],
            },
        ],
    },
    {
        name: 'glasses',
        groups: [
            {
                name: 'glassesSingle',
                title: 'Окна с внутренней стороны',
                items: [
                    {
                        name: 'Мытьё оконного остекления с внутренней стороны',
                        description: '',
                    },
                    { name: 'Мытьё входной группы и фурнитуры с внутренней стороны', description: '' },
                    { name: 'Мытьё откосов оконной конструкции, отливов', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                ],
            },
            {
                name: 'glassesBoth',
                title: 'Окна с обеих сторон',
                items: [
                    {
                        name: 'Мытьё оконного остекления с двух сторон',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё входной группы и фурнитуры с двух сторон', description: '' },
                    { name: 'Мытьё откосов оконной конструкции, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                    { name: 'Мытьё решеток', description: 'Индивидуальный расчет' },
                ],
            },
            {
                name: 'balconySingle',
                title: 'Балконы с внутренней стороны',
                items: [
                    { name: 'Мытьё балконного остекления', description: '' },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
            {
                name: 'balconyBoth',
                title: 'Балконы с обеих сторон',
                items: [
                    {
                        name: 'Мытьё балконного остекления',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
        ],
    },
]


export const SERVICES = {
    luxe: {
        glasses: {
            single: {
                caption: 'С внутренней стороны',
                values: [
                    { name: 'Мытьё оконного остекления с внутренней стороны', description: '' },
                    { name: 'Мытьё входной группы и фурнитуры с внутренней стороны', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                ],
            },
            both: {
                caption: 'С обеих сторон',
                values: [
                    {
                        name: 'Мытьё оконного остекления с двух сторон',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё входной группы и фурнитуры с двух сторон', description: '' },
                    { name: 'Мытьё откосов оконной конструкции, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                    { name: 'Мытьё решеток', description: 'Индивидуальный расчет' },
                ],
            },
        },
        balcony: {
            single: {
                caption: 'С внутренней стороны',
                values: [
                    {
                        name: 'Мытьё балконного остекления',
                        description: '',
                    },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    { name: 'Сухая и влажная уборка всех поверхностей внутри и снаружи', description: '' },
                    { name: 'Сухая и влажная уборка осветительных приборов и деталей интерьера', description: '' },
                    { name: 'Уборка пола влажным и вакуумным способом', description: '' },
                    { name: 'Разбор вещей и вынос мусора', description: 'Индивидуальный расчет' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
            both: {
                caption: 'С обеих сторон',
                values: [
                    {
                        name: 'Мытьё балконного остекления',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    { name: 'Сухая и влажная уборка всех поверхностей внутри и снаружи', description: '' },
                    { name: 'Сухая и влажная уборка осветительных приборов и деталей интерьера', description: '' },
                    { name: 'Уборка пола влажным и вакуумным способом', description: '' },
                    { name: 'Разбор вещей и вынос мусора', description: 'Индивидуальный расчет' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
        },
        rooms: [
            { name: 'Обеспыливание потолка (сухим способом)', description: '' },
            { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
            { name: 'Мытье батарей/радиаторов внутри и снаружи', description: 'Клиент разбирает самостоятельно' },
            {
                name: 'Обеспыливание и влажная уборка всех потолочных осветительных приборов',
                description: 'плафоны и лампочки не откручиваются, мытьё внутри по мере доступности',
            },
            { name: 'Сухая и влажная уборка шкафов и фасадов мебели', description: '' },
            {
                name: 'Обеспыливание и влажная уборка мебели внутри со всем содержимым',
                description: 'По желанию Клиента',
            },
            { name: 'Обеспыливание и влажная уборка предметов интерьера', description: 'картины, статуэтки и пр.' },
            { name: 'Очистка и придание блеска зеркалам, стеклянным и хромированным поверхностям', description: '' },
            { name: 'Влажная уборка дверей, дверных откосов, ножек столов и стульев', description: '' },
            { name: 'Обеспыливание и влажная уборка розеток и выключателей', description: '' },
            { name: 'Обработка деревянных и кожаных предметов мебели полиролью', description: '' },
            { name: 'Замена постельного белья и заправка кровати', description: 'По желанию Клиента' },
            { name: 'Сухая и влажная уборка пола, плинтусов, углов', description: '' },
            {
                name: 'Обеспыливание и влажная уборка хрустальных/стеклянных декоративных люстр',
                description: 'Индивидуальный расчет',
            },
            {
                name: 'Чистка и полировка фарфоровой, хрустальной, антикварной посуды',
                description: 'Индивидуальный расчет',
            },
        ],
        kitchen: [
            { name: 'Обеспыливание потолка (сухим способом)', description: '' },
            { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
            { name: 'Мытьё пространства за кухонным цоколем', description: 'Демонтаж цоколя производится клиентом' },
            { name: 'Влажная уборка бытовой техники внутри и снаружи (по мере доступности)', description: '' },
            {
                name: 'Мытьё холодильника внутри и снаружи',
                description: 'При необходимости мыть морозильную камеру - заранее разморозить',
            },
            { name: 'Очистка кухонного гарнитура внутри и снаружи', description: '' },
            { name: 'Обезжиривание кухонного фартука и межплиточных швов', description: '' },
            { name: 'Удаление загрязнений с варочной панели', description: '' },
            { name: 'Мытьё посуды', description: 'По желанию Клиента' },
            { name: 'Очистка мусорной корзины (замена пакета, вынос мусора)', description: '' },
            {
                name: 'Сухая и влажная уборка пола, плинтусов, углов, чистка межплиточных швов (ручным и/или мех. способом)',
                description: '',
            },
        ],
        bath: [
            { name: 'Обеспыливание потолка (сухим способом)', description: '' },
            { name: 'Обеспыливание и влажная уборка стен', description: '' },
            {
                name: 'Сухая и влажная уборка осветительных приборов и предметов интерьера (плафоны и лампочки не откручиваются, мытьё внутри по доступности)',
                description: '',
            },
            {
                name: 'Обеспыливание и влажная уборка мебели внутри со всем содержимым',
                description: 'По желанию Клиента',
            },
            { name: 'Чистка стиральной машины (резинка и отсек для порошка)', description: '' },
            { name: 'Санитарная обработка санузла (унитаз, раковина, ванная/душевая и пр.)', description: '' },
            { name: 'Доведение до блеска смесителей, стеклянных и зеркальных поверхностей', description: '' },
            { name: 'Удаление плесени и грибка, обработка дезинфицирующим средством', description: '' },
            { name: 'Сухая и влажная уборка пола, углов', description: '' },
            { name: 'Обработка межплиточных швов ручным и/или мех. способом на полу и на стенах', description: '' },
        ],
    },
    postConstruction: {
        glasses: {
            single: {
                caption: 'С внутренней стороны',
                values: [
                    { name: 'Мытьё оконного остекления с внутренней стороны', description: '' },
                    { name: 'Мытьё входной группы и фурнитуры с внутренней стороны', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    {
                        name: 'Удаление заводской плёнки с оконных конструкция ("прикипевшая" плёнка по возможности)',
                        description: '',
                    },
                    { name: 'Очистка локальных загрязнений(скотч, наклейки и т.п.)', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                ],
            },
            both: {
                caption: 'С обеих сторон',
                values: [
                    {
                        name: 'Мытьё оконного остекления с двух сторон',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё входной группы и фурнитуры с двух сторон', description: '' },
                    { name: 'Мытьё откосов оконной конструкции, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    { name: 'Очистка локальных загрязнений (скотч, наклейки и т.п.)', description: '' },
                    {
                        name: 'Удаление заводской плёнки с оконных конструкция ("прикипевшая" плёнка по возможности)',
                        description: '',
                    },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                    { name: 'Мытьё решеток', description: 'Индивидуальный расчет' },
                ],
            },
        },
        balcony: {
            single: {
                caption: 'С внутренней стороны',
                values: [
                    {
                        name: 'Мытьё балконного остекления',
                        description: '',
                    },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    { name: 'Сухая и влажная уборка всех поверхностей внутри и снаружи', description: '' },
                    { name: 'Сухая и влажная уборка осветительных приборов и деталей интерьера', description: '' },
                    { name: 'Уборка пола влажным и вакуумным способом', description: '' },
                    { name: 'Вынос малогабартиного мусора', description: 'При наличии мусорного бака на территории' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
            both: {
                caption: 'С обоих сторон',
                values: [
                    {
                        name: 'Мытьё балконного остекления',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Обеспыливание потолка (сухим способом)', description: '' },
                    { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
                    { name: 'Сухая и влажная уборка всех поверхностей внутри и снаружи', description: '' },
                    { name: 'Сухая и влажная уборка осветительных приборов и деталей интерьера', description: '' },
                    { name: 'Уборка пола влажным и вакуумным способом', description: '' },
                    { name: 'Вынос малогабартиного мусора', description: 'При наличии мусорного бака на территории' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
        },
        rooms: [
            { name: 'Обеспыливание потолка (сухим способом)', description: '' },
            { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
            { name: 'Мытье батарей/радиаторов внутри и снаружи', description: 'Клиент разбирает самостоятельно' },
            {
                name: 'Обеспыливание и влажная уборка всех потолочных осветительных приборов',
                description: 'Плафоны и лампочки не откручиваются, мытьё внутри по мере доступности',
            },
            { name: 'Сухая и влажная уборка шкафов и фасадов мебели', description: '' },
            { name: 'Обеспыливание и влажная уборка мебели внутри', description: 'По желанию Клиента' },
            { name: 'Обеспыливание и влажная уборка предметов интерьера', description: 'картины, статуэтки и пр.' },
            { name: 'Очистка и придание блеска зеркалам, стеклянным и хромированным поверхностям', description: '' },
            { name: 'Влажная уборка дверей, дверных откосов, ножек столов и стульев', description: '' },
            { name: 'Обеспыливание и влажная уборка розеток и выключателей', description: '' },
            { name: 'Сухая и влажная уборка пола, плинтусов, углов', description: '' },
            {
                name: 'Удаление локальных послестроительных загрязнений (клей, краска, шпаклевка, наклейки и пр.)',
                description: '',
            },
            { name: 'Вынос малогабартиного мусора', description: 'При наличии мусорного бака на территории' },
            {
                name: 'Обеспыливание и влажная уборка хрустальных/стеклянных декоративных люстр',
                description: 'Индивидуальный расчет',
            },
        ],
        kitchen: [
            { name: 'Обеспыливание потолка (сухим способом)', description: '' },
            { name: 'Обеспыливание и влажная уборка стен (если позволяет поверхность)', description: '' },
            {
                name: 'Обеспыливание и влажная уборка всех потолочных осветительных приборов ',
                description: 'Плафоны и лампочки не откручиваются, мытьё внутри по мере доступности',
            },
            {
                name: 'Обеспылевание и влажная уборка бытовой техники внутри и снаружи (по мере доступности)',
                description: '',
            },
            { name: 'Обеспыливание и влажная уборка вентиляционных отверстий и вытяжек', description: '' },
            {
                name: 'Обеспыливание и влажная уборка кухонного гарнитура внутри и снаружи (удаление пленки и наклеек)',
                description: '',
            },
            { name: 'Обеспыливание и влажная уборка кухонного фартука и межплиточных швов', description: '' },
            { name: 'Мытьё пространства за кухонным цоколем', description: 'Демонтаж цоколя производится клиентом' },
            {
                name: 'Сухая и влажная уборка пола, плинтусов, углов, чистка межплиточных швов (ручным и/или мех. способом)',
                description: '',
            },
            {
                name: 'Удаление локальных послестроительных загрязнений (клей, краска, шпаклевка, наклейки и пр.)',
                description: '',
            },
        ],
        bath: [
            { name: 'Обеспыливание потолка (сухим способом)', description: '' },
            { name: 'Обеспыливание и влажная уборка стен', description: '' },
            {
                name: 'Сухая и влажная уборка осветительных приборов и предметов интерьера (плафоны и лампочки не откручиваются, мытьё внутри по доступности)',
                description: '',
            },
            { name: 'Обеспыливание и влажная уборка мебели внутри', description: 'По желанию Клиента' },
            {
                name: 'Снятие плёнки/наклеек и влажная уборка сантехники (унитаз, раковина, ванная/душевая и пр.)',
                description: '',
            },
            {
                name: 'Снятие плёнки и доведение до блеска смесителей, стеклянных и зеркальных поверхностей',
                description: '',
            },
            { name: 'Сухая и влажная уборка пола, углов', description: '' },
            { name: 'Обработка межплиточных швов ручным и/или мех. способом на полу и на стенах', description: '' },
        ],
    },
    maintenance: {
        glasses: [],
        balcony: [],
        rooms: [
            { name: 'Сухая и влажная уборка шкафов и фасадов мебели снаружи по росту клинера', description: '' },
            { name: 'Очистка и придание блеска зеркалам, стеклянным и хромированным поверхностям', description: '' },
            { name: 'Влажная уборка дверей, дверных откосов, ножек столов и стульев', description: '' },
            { name: 'Обеспыливание и влажная уборка розеток и выключателей', description: '' },
            { name: 'Замена постельного белья и заправка кровати', description: 'По желанию Клиента' },
            { name: 'Обеспыливание и влажная уборка поверхностей, поддержание чистоты', description: '' },
            { name: 'Сухая и влажная уборка пола, плинтусов, углов', description: '' },
        ],
        kitchen: [
            { name: 'Влажная уборка бытовой техники снаружи', description: '' },
            { name: 'Очистка кухонного гарнитура и другой мебели снаружи', description: '' },
            { name: 'Обезжиривание кухонного фартука ', description: '' },
            { name: 'Удаление загрязнений с варочной панели', description: '' },
            { name: 'Мытье грязной посуды', description: '' },
            { name: 'Очистка мусорной корзины (замена пакета, вынос мусора)', description: '' },
            { name: 'Влажная уборка дверей, дверных откосов, ножек столов и стульев', description: '' },
            { name: 'Обеспыливание и влажная уборка розеток и выключателей', description: '' },
            { name: 'Сухая и влажная уборка пола, плинтусов, углов', description: '' },
        ],
        bath: [
            { name: 'Сухая и влажная уборка стен по росту клинера', description: '' },
            { name: 'Сухая и влажная уборка мебели снаружи по росту клинера', description: '' },
            { name: 'Влажная уборка стиральной машины снаружи', description: '' },
            { name: 'Санитарная обработка санузла (унитаз, раковина, ванная/душевая)', description: '' },
            { name: 'Доведение до блеска сантехники, стеклянных и зеркальных поверхностей', description: '' },
            { name: 'Удаление ржавчины и налета', description: '' },
            { name: 'Удаление плесени и грибка, обработка дезинфицирующим средством', description: '' },
            { name: 'Сухая и влажная уборка пола, плинтусов, углов', description: '' },
        ],
    },
    windows: {
        glasses: {
            single: {
                caption: 'С внутренней стороны',
                values: [
                    {
                        name: 'Мытьё оконного остекления с внутренней стороны',
                        description: '',
                    },
                    { name: 'Мытьё входной группы и фурнитуры с внутренней стороны', description: '' },
                    { name: 'Мытьё откосов оконной конструкции, отливов', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                ],
            },
            both: {
                caption: 'С обеих сторон',
                values: [
                    {
                        name: 'Мытьё оконного остекления с двух сторон',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё входной группы и фурнитуры с двух сторон', description: '' },
                    { name: 'Мытьё откосов оконной конструкции, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё рам и подоконников', description: '' },
                    { name: 'Отбеливание рам и подоконников', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости окна)' },
                    { name: 'Мытьё решеток', description: 'Индивидуальный расчет' },
                ],
            },
        },
        balcony: {
            single: {
                caption: 'С внутренней стороны',
                values: [
                    { name: 'Мытьё балконного остекления', description: '' },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
            both: {
                caption: 'С обеих сторон',
                values: [
                    {
                        name: 'Мытьё балконного остекления',
                        description: 'Глухие окна и/или при наличии решетки с внешней стороны моются по возможности',
                    },
                    { name: 'Мытьё рам, откосов балконной конструкции, подоконников, отливов', description: '' },
                    { name: 'Чистка межрамного пространства', description: '' },
                    { name: 'Мытьё фурнитуры/ручек', description: '' },
                    { name: 'Мытьё москитных сеток', description: 'Клиент снимает самостоятельно' },
                    { name: 'Чистка жалюзи', description: 'Индивидуальный расчет (1,5 стоимости балкона)' },
                ],
            },
        },
        rooms: [],
        kitchen: [],
        bath: [],
    },
}

export const toolsTemplate = (cleanerNumber: number, objectArea: number) => ({
    luxe: {
        chemistry: [
            { id: 'B2B-CH-1', name: 'Антивандал 0,5 л', out: 1 },
            { id: 'CH-2', name: 'Blue Concentrate 1 л', out: 1 },
            { id: 'CH-3', name: 'Heavy Duty 0,5 л', out: 1 },
            { id: 'CH-4', name: 'Maline 0,75 л.', out: 1 },
            { id: 'CH-5', name: 'Alfa 50 0,75 л', out: 1 },
            { id: 'CH-6', name: 'Alfa 19 0,75 л', out: 1 },
            { id: 'CH-7', name: 'Glass Cleaner 1 л', out: 1 },
            { id: 'CH-8', name: 'Vitrex 1 л', out: 1 },
            { id: 'CH-9', name: 'СуперАктив 0,5 л', out: 1 },
            { id: 'CH-10', name: 'Синтил 1 л', out: 1 },
            { id: 'CH-11', name: 'Amol 0,5 л', out: 1 },
            { id: 'CH-12', name: 'Veksa 0,5 л', out: 1 },
            { id: 'CH-13', name: 'Alfa-Gel 0,75 л.', out: 1 },
            { id: 'CH-14', name: 'Fox 1 л.', out: 1 },
            { id: 'CH-15', name: 'Tripo-Gel 0.75 л.', out: 1 },
            { id: 'CH-16', name: 'Winter 30 5 л.', out: 1 },
        ],
        consumables: [
            { id: 'C-1', name: 'Перчатки резиновые', out: cleanerNumber },
            { id: 'C-2', name: 'Губки для мытья посуды', out: 2 * cleanerNumber },
            { id: 'C-3', name: 'Вафельное полотно 150 гр./м2', out: 2 * cleanerNumber },
            { id: 'C-4', name: 'Тряпки для микрофибры 30 на 30', out: 7 * cleanerNumber },
            { id: 'C-5', name: 'МОП 40см (серый/белый)', out: Math.floor(objectArea / 10) },
            { id: 'C-6', name: 'Пакеты для мусора 60л', out: 1 },
            { id: 'C-7', name: 'Шубки для мытья окон 35', out: 1 },
            { id: 'C-8', name: 'Щетка для швов малая (комплект)', out: 1 },
            { id: 'C-9', name: 'Лезвия для скребка Unger', out: cleanerNumber },
        ],
        inventory: [
            { id: 'I-1', name: 'Скребок короткий', out: cleanerNumber },
            { id: 'I-2', name: 'Щетка утюжок', out: cleanerNumber },
            { id: 'I-3', name: 'Держатель 35 см', out: 1 },
            { id: 'I-4', name: 'Склиз Ettore Gold', out: 1 },
            { id: 'I-5', name: 'Ведро 7 л.', out: cleanerNumber },
            { id: 'I-6', name: 'Флаундер 40 см.', out: cleanerNumber },
            { id: 'I-7', name: 'Телескопичка 1.2 м', out: 1 },
            { id: 'I-8', name: 'Стремянка 3 ступени', out: 1 },
            { id: 'I-9', name: 'Швабра для МОП', out: cleanerNumber },
            { id: 'I-10', name: 'Ящик Белый 70 л', out: 1 },
        ],
        overall: [
            { id: 'O-1', name: 'Костюм Мужской', out: cleanerNumber },
            { id: 'O-2', name: 'Фартук женский', out: cleanerNumber },
            { id: 'O-3', name: 'Маска одноразовая черного цвета', out: cleanerNumber },
        ],
        equipment: [{ id: 'E-1', name: 'Пылесос Karcher T8/1 L', out: 1 }],
    },
    postConstruction: {
        chemistry: [
            { name: 'Антивандал 0,5 л', out: 1 },
            { name: 'Blue Concentrate 1 л', out: 1 },
            { name: 'Heavy Duty 0,5 л', out: 1 },
            { name: 'Maline 0,75 л.', out: 1 },
            { name: 'Alfa 50 0,75 л', out: 1 },
            { name: 'Alfa 19 0,75 л', out: 1 },
            { name: 'Glass Cleaner 1 л', out: 1 },
            { name: 'Vitrex 1 л', out: 1 },
            { name: 'СуперАктив 0,5 л', out: 1 },
            { name: 'Синтил 1 л', out: 1 },
            { name: 'Amol 0,5 л', out: 1 },
            { name: 'Veksa 0,5 л', out: 1 },
            { name: 'Alfa-Gel 0,75 л.', out: 1 },
            { name: 'Fox 1 л.', out: 1 },
            { name: 'Tripo-Gel 0.75 л', out: 1 },
            { name: 'Winter 30 5 л', out: 1 },
        ],
        consumables: [
            { name: 'Перчатки резиновые', out: cleanerNumber },
            { name: 'Губки для мытья посуды', out: 2 * cleanerNumber },
            { name: 'Вафельное полотно 150 гр./м2', out: 3 * cleanerNumber },
            { name: 'Мешки для пылесоса Kar 07 pro', out: 2 },
            { name: 'Тряпки для микрофибры 30 на 30', out: 10 * cleanerNumber },
            { name: 'МОП 40см (серый/белый)', out: Math.floor(objectArea / 10) },
            { name: 'Пакеты для мусора 60л', out: 1 },
            { name: 'Шубки для мытья окон 35', out: 1 },
            { name: 'Щетка для швов малая (комплект)', out: 1 },
            { name: 'Лезвия для скребка Unger', out: cleanerNumber },
        ],
        inventory: [
            { name: 'Скребок короткий', out: cleanerNumber },
            { name: 'Щетка утюжок', out: cleanerNumber },
            { name: 'Держатель 35 см', out: 1 },
            { name: 'Склиз Ettore Gold', out: 1 },
            { name: 'Ведро 7 л.', out: cleanerNumber },
            { name: 'Флаундер 40 см.', out: cleanerNumber },
            { name: 'Телескопичка 1.2 м', out: 1 },
            { name: 'Стремянка 3 ступени', out: 1 },
            { name: 'Швабра для МОП', out: cleanerNumber },
            { name: 'Ящик Белый 70 л', out: 1 },
        ],
        overall: [
            { name: 'Костюм Мужской', out: cleanerNumber },
            { name: 'Фартук женский', out: cleanerNumber },
            { name: 'Маска одноразовая черного цвета', out: cleanerNumber },
        ],
        equipment: [{ name: 'Пылесос Karcher T8/1 L', out: 1 }],
    },
    maintenance: {
        chemistry: [
            { name: 'Blue Concentrate 1 л', out: 1 },
            { name: 'Heavy Duty 0,5 л', out: 1 },
            { name: 'Maline 0,75 л.', out: 1 },
            { name: 'Glass Cleaner 1 л', out: 1 },
            { name: 'Синтил 1 л', out: 1 },
            { name: 'Alfa-Gel 0,75 л.', out: 1 },
            { name: 'Fox 1 л.', out: 1 },
            { name: 'Tripo-Gel 0.75 л', out: 1 },
        ],
        consumables: [
            { name: 'Перчатки резиновые', out: cleanerNumber },
            { name: 'Губки для мытья посуды', out: cleanerNumber },
            { name: 'Вафельное полотно 150 гр./м2', out: cleanerNumber },
            { name: 'Тряпки для микрофибры 30 на 30', out: 5 * cleanerNumber },
            { name: 'МОП 40см (серый/белый)', out: Math.floor(objectArea / 10) },
            { name: 'Пакеты для мусора 60л', out: 1 },
            { name: 'Щетка для швов малая (комплект)', out: 1 },
            { name: 'Лезвия для скребка Unger', out: cleanerNumber },
        ],
        inventory: [
            { name: 'Скребок короткий', out: cleanerNumber },
            { name: 'Щетка утюжок', out: cleanerNumber },
            { name: 'Ведро 7 л.', out: cleanerNumber },
            { name: 'Флаундер 40 см.', out: cleanerNumber },
            { name: 'Швабра для МОП', out: cleanerNumber },
            { name: 'Сумка клинера', out: 1 },
        ],
        overall: [
            { name: 'Костюм Мужской', out: cleanerNumber },
            { name: 'Фартук женский', out: cleanerNumber },
            { name: 'Маска одноразовая черного цвета', out: cleanerNumber },
        ],
        equipment: [],
    },

    dryChemistry: {
        chemistry: [
            { name: 'Tapi Wash 1 л', out: 1 },
            { name: 'Enz All 2,7 кг.', out: 1 },
            { name: 'Neutralaizer 1 л.', out: 1 },
        ],
        consumables: [
            { name: 'Перчатки резиновые', out: cleanerNumber },
            { name: 'Губки для мытья посуды', out: cleanerNumber },
            { name: 'Вафельное полотно 150 гр./м2', out: cleanerNumber },
            { name: 'Тряпки для микрофибры 30 на 30', out: 5 * cleanerNumber },
        ],
        inventory: [{ name: 'Щетка утюжок', out: cleanerNumber }],
        overall: [
            { name: 'Костюм Мужской', out: cleanerNumber },
            { name: 'Фартук женский', out: cleanerNumber },
            { name: 'Маска одноразовая черного цвета', out: cleanerNumber },
        ],
        equipment: [{ name: 'Экстрактор Karcher Puzzi 10/1', out: 1 }],
    },
})
