import clsx from 'clsx'
import './CustomMobileSlider.scss'
import { memo, ReactNode, useEffect, useRef, useState } from 'react'

interface CustomSliderProps {
    className?: string
    children: ReactNode
    onChangeIndex?: (index: number) => void
    index?: number
}

export const CustomMobileSlider = memo((props: CustomSliderProps) => {
    const {
        className,
        children,
        onChangeIndex,
        index,
    } = props

    const ref = useRef<HTMLDivElement>(null)

    const [isInitialised, setInitialised] = useState(false)
    const [isOuterScroll, setOuterScroll] = useState(!!index)
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        const slider = ref.current
        if (slider) {
            const handleScroll = () => {
                if (!isOuterScroll) {
                    const slideWidth = slider.offsetWidth
                    const index = Math.round(slider.scrollLeft / slideWidth)
                    setCurrentIndex(index)
                    if (onChangeIndex && isInitialised) {
                        onChangeIndex(index)
                    }
                } else {
                    setOuterScroll(false)
                }
            }

            slider.addEventListener('scrollend', handleScroll)

            return () => {
                slider.removeEventListener('scrollend', handleScroll)
            }
        }
    }, [isInitialised, isOuterScroll])

    useEffect(() => {
        if (index !== undefined && index !== null && index !== currentIndex) {
            const slider = ref.current
            if (slider) {
                const slideWidth = slider.offsetWidth
                setCurrentIndex(index)
                setOuterScroll(true)
                slider.scrollTo({
                    left: index * slideWidth,
                    behavior: !isInitialised ? 'instant' : 'smooth',
                })
            }
        }
        setInitialised(true)
    }, [index])

    return (
        <div
            className={clsx('custom-slider', className)}
            ref={ref}
        >
            {children}
        </div>
    )
})
