import './ForemanChecklistItem.scss'
import MobileCheckbox from '~/components/mobile/mobileCommon/mobileCheckboxes/MobileCheckbox/MobileCheckbox'

interface ForemanChecklistItemProps {
    title: string
    description?: string
    status?: boolean
    onChange: (value: boolean) => void
    disabled?: boolean
}

const ForemanChecklistItem = (props: ForemanChecklistItemProps) => {
    const {
        title,
        description,
        status,
        onChange,
        disabled,
    } = props

    return (
        <div className='foremanChecklistItem'>
            <h4 className='foremanChecklistItem__title'>{title}</h4>
            <div className='foremanChecklistItem__content'>
                {description && <p className='foremanChecklistItem__description'>{description}</p>}
                <MobileCheckbox value={status} onChange={onChange} disabled={disabled} />
            </div>
        </div>
    )
}

export default ForemanChecklistItem
