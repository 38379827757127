import { useContext } from 'react'
import { FaPlus, FaTrashAlt } from 'react-icons/fa'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'

import './TabsTableToolbar.scss'
import { TabsTableContext } from '~/components/common/ReusingComponents/TabsTable/TabsTableContext'

interface TabsTableToolbar {
    addNewRow: () => void
    deleteRows: () => void
    disabledDelete: boolean
}

const TabsTableToolbar = (props: TabsTableToolbar) => {
    const {
        addNewRow,
        deleteRows,
        disabledDelete,
    } = props

    const { disabled } = useContext(TabsTableContext)

    return (
        <div className='tabsTableToolbar'>
            <CustomButton
                className='tabsTableToolbar__button'
                theme={CustomButtonThemes.OUTLINE}
                disabled={disabled}
                onClick={addNewRow}
            >
                <FaPlus className='tabsTableToolbar__icon' size={16} />
                Добавить строку
            </CustomButton>
            <CustomButton
                className='tabsTableToolbar__button'
                theme={CustomButtonThemes.OUTLINE}
                disabled={disabled || disabledDelete}
                onClick={deleteRows}
            >
                <FaTrashAlt className='tabsTableToolbar__icon' size={16} />
                Удалить строку
            </CustomButton>
        </div>
    )
}

export default TabsTableToolbar
