import './MobileTabs.scss'
import clsx from 'clsx'
import CheckIcon from '~/components/common/ReusingComponents/CheckIcon/CheckIcon'
import ErrorIcon from '~/components/common/ReusingComponents/ErrorIcon/ErrorIcon'

export interface MobileTab {
    title: string
    isError?: boolean
    isSuccess?: boolean
}

interface MobileTabsProps {
    activeTabIndex: number
    tabs: MobileTab[]
    setActiveTabIndex: (index: number) => void
    hasCheckIcons?: boolean
}

const MobileTabs = (props: MobileTabsProps) => {
    const {
        activeTabIndex,
        tabs,
        setActiveTabIndex,
        hasCheckIcons,
    } = props

    return (
        <div className='mobileTabs'>
            {tabs.map((tab, index) => {
                return (
                    <button
                        key={index}
                        type='button'
                        className={clsx('mobileTabs__item', {
                            'mobileTabs__item--active': activeTabIndex === index,
                        })}
                        onClick={() => setActiveTabIndex(index)}
                    >
                        <p className='mobileTabs__title'>{tab.title}</p>
                        {tab.isError && <ErrorIcon isActive={activeTabIndex === index} />}
                        {hasCheckIcons && !tab.isError &&
                            <CheckIcon
                                isSuccess={tab.isSuccess}
                                active={activeTabIndex === index} />
                        }
                    </button>
                )
            })}
        </div>
    )
}

export default MobileTabs
