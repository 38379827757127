import { useCallback, useMemo, useState } from 'react'
import MobileCustomMultipleSelector
    from '../../../mobile/mobileCommon/mobileInputs/MobileCustomMultipleSelector/MobileCustomMultipleSelector'
import CustomMultipleSelector, {
    CustomMultipleSelectorThemes,
} from '../../inputs/CustomMultipleSelector/CustomMultipleSelector'
import { CustomTabNavbar, CustomTabNavbarData } from '../CustomTabNavbar/CustomTabNavbar'
import FilterItems from './subComponents/FilterItems/FilterItems'
import { isMobile } from '~/shared/util/deviceDetect'
import { IFilter, IFilterProps } from '~/types/types.filter'
import './CustomFilter.scss'

interface ICustomFilterProps {
    filter: IFilter,
    values: IFilterProps,
    onChange: (name: string, values: string[]) => void,
    onChangeTab: (tab: string) => void
}

const CustomFilter = (props: ICustomFilterProps) => {
    const {
        filter,
        values,
        onChange,
        onChangeTab,
    } = props

    const [currentTab, setCurrentTab] = useState<string>('closedSlots')

    const handleNavbarClick = useCallback((value: string | number) => {
        if (typeof value === 'string') {
            setCurrentTab(value)
            onChangeTab(value)
        }
    }, [])

    const getTabs: CustomTabNavbarData[] = useMemo(() => {
        return [
            {
                value: 'closedSlots',
                title: 'Даты',
                isActive: currentTab === 'closedSlots',
            },
            {
                value: 'tasks',
                title: 'Задачи',
                isActive: currentTab === 'tasks',
            },
        ]
    }, [currentTab])

    return (
        <div className='customFilter'>
            {!isMobile && <h5>Фильтр</h5>}
            {filter?.employees && (isMobile ? (
                <MobileCustomMultipleSelector
                    placeholder='Выбрать сотрудника'
                    name='employees'
                    // @ts-ignore
                    items={filter.employees}
                    values={values.employees}
                    theme={CustomMultipleSelectorThemes.GREY}
                    onChange={(value: string[]) => {
                        onChange('employees', value)
                    }}
                />
            ) : (
                <CustomMultipleSelector
                    placeholder='Выбрать сотрудника'
                    name='employees'
                    // @ts-ignore
                    items={filter.employees}
                    values={values.employees}
                    theme={CustomMultipleSelectorThemes.GREY}
                    onChange={(value: string[]) => {
                        onChange('employees', value)
                    }}
                />
            ))}

            {filter?.serviceTypes && (isMobile ? (
                <MobileCustomMultipleSelector
                    placeholder='Выбрать тип уборки'
                    name='serviceTypes'
                    // @ts-ignore
                    items={filter.serviceTypes}
                    values={values.serviceTypes}
                    theme={CustomMultipleSelectorThemes.GREY}
                    onChange={(value: string[]) => {
                        onChange('serviceTypes', value)
                    }}
                />
            ) : (
                <CustomMultipleSelector
                    placeholder='Выбрать тип уборки'
                    name='serviceTypes'
                    // @ts-ignore
                    items={filter.serviceTypes}
                    values={values.serviceTypes}
                    theme={CustomMultipleSelectorThemes.GREY}
                    onChange={(value: string[]) => {
                        onChange('serviceTypes', value)
                    }}
                />
            ))}

            {filter?.counterparty && (
                <CustomMultipleSelector
                    placeholder='Выбрать контрагента'
                    name='counterparty'
                    // @ts-ignore
                    items={filter.counterparty}
                    withSearch={true}
                    values={values.counterparty || []}
                    theme={CustomMultipleSelectorThemes.GREY}
                    onChange={(value: string[]) => {
                        onChange('counterparty', value)
                    }}
                />
            )}

            <CustomTabNavbar data={getTabs} onChange={handleNavbarClick} />

            {filter?.tasks && currentTab === 'tasks' && (
                <FilterItems
                    title='Задачи'
                    items={filter.tasks}
                    values={values?.events.tasks}
                    onClick={(values: string[]) => {
                        onChange('tasks', values)
                    }}
                />
            )}

            {filter?.closedSlots && currentTab === 'closedSlots' && (
                <>
                    <FilterItems
                        title='Закрытая дата'
                        items={filter.closedSlots}
                        values={values.events.closedSlots}
                        onClick={(values: string[]) => {
                            onChange('closedSlots', values)
                        }}
                    />
                    {filter?.emptySlots && (
                        <FilterItems
                            title='Свободная дата'
                            items={filter.emptySlots}
                            values={values.events.emptySlots}
                            onClick={(values: string[]) => {
                                onChange('emptySlots', values)
                            }}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default CustomFilter
