function isObject(obj: any): obj is Record<string, any> {
    return obj && typeof obj === 'object' && !Array.isArray(obj) && !(obj instanceof Map)
}

export default function deepMerge<T>(target: T, ...sources: any[]): T {
    if (!sources.length) return target

    const source = sources.shift()

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} })
                deepMerge(target[key], source[key])
            } else if (Array.isArray(source[key])) {
                // @ts-ignore
                if (!target[key]) target[key] = []
                // @ts-ignore
                target[key] = target[key].concat(source[key])
            } else if (source[key] instanceof Map) {
                // @ts-ignore
                if (!target[key]) target[key] = new Map()
                mergeMaps(target[key], source[key])
            } else {
                Object.assign(target, { [key]: source[key] })
            }
        }
    } else if (target instanceof Map && source instanceof Map) {
        mergeMaps(target, source)
    }

    return deepMerge(target, ...sources)
}

function mergeMaps<K, V>(targetMap: Map<K, V>, sourceMap: Map<K, V>): void {
    for (const [key, value] of sourceMap) {
        if (value instanceof Map) {
            if (!targetMap.has(key)) {
                targetMap.set(key, new Map() as unknown as V)
            }
            mergeMaps(targetMap.get(key) as unknown as Map<any, any>, value)
        } else {
            targetMap.set(key, value)
        }
    }
}
