import { RootState } from '../store'

export const selectSmeta = (state: RootState) => state.smeta
export const selectSmetaPaymentType = (state: RootState) => state.smeta.paymentType
export const selectSmetaLogistics = (state: RootState) => state.smeta.logistics
export const selectSmetaOtherExpenses = (state: RootState) => state.smeta.otherExpenses
export const selectSmetaTmc = (state: RootState) => state.smeta.tmc
export const selectSmetaHandleSubmit = (state: RootState) => state.smeta.handleSubmit
export const selectSmetaWageFundSectionTotal = (state: RootState) => state.smeta.wageFundSectionTotal


