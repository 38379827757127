import { useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Modal from 'react-modal'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import BackButton from '~/components/common/ReusingComponents/BackButton/BackButton'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { TabsTableHeaderSection } from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import MobileTitle from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTitle/MobileTitle'
import MobileTitleButton
    from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTitleButton/MobileTitleButton'
import AdditionalServicesItem
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileAdditionalServices/subComponents/AdditionalServicesItem/AdditionalServicesItem'
import AdditionalServicesTotal
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileAdditionalServices/subComponents/AdditionalServicesTotal/AdditionalServicesTotal'
import './MobileAdditionalServices.scss'
import MobileListValidation
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileListValidation/MobileListValidation'
import { get } from '~/shared/util/get'

interface MobileAdditionalServicesProps {
    name: 'foremanReport.additionalServices';
    title: string;
    headerSections: TabsTableHeaderSection[]
    disabled?: boolean
}

const MobileAdditionalServices = (props: MobileAdditionalServicesProps) => {
    const {
        name,
        title,
        headerSections,
        disabled,
    } = props

    const [listModalOpen, setListModalOpen] = useState<boolean>(false)

    const {
        control,
        formState,
    } = useFormContext<OrderInterface>()

    const {
        fields: services,
        append,
        remove,
    } = useFieldArray({ control, name: name })

    const servicesErrors = useMemo(() => get(formState.errors, name), [formState])

    const inputs = useMemo(() => headerSections[0].inputs.filter((item) => !!item), [headerSections])

    const addNewService = () => {
        append(inputs.reduce((accum, value) => ({ ...accum, [value.name]: value.default || '' }), {}))
    }

    return (
        <>
            <MobileTitleButton
                title={title}
                onClick={() => setListModalOpen(true)}
                isError={!!servicesErrors}
            />
            <MobileListValidation
                baseName={name}
                rows={services}
                inputs={inputs}
            />
            <Modal
                id='MobileAdditionalServices'
                isOpen={listModalOpen}
                onRequestClose={() => setListModalOpen(false)}
                className='mobileServicesVerification__modal'
            >
                <BackButton callback={() => setListModalOpen(false)} />
                <MobileTitle title={title} />
                <div className='mobileAdditionalServices__content'>
                    <div className='mobileAdditionalServices__list'>
                        {services.map((service, rowIndex) => (
                            <AdditionalServicesItem
                                key={service.id}
                                inputs={inputs}
                                disabled={!!disabled}
                                inputName={`${name}.${rowIndex}`}
                                rowIndex={rowIndex}
                                isError={!!servicesErrors?.[rowIndex]}
                                onDelete={remove}
                            />
                        ))}
                    </div>
                    <div className='mobileAdditionalServices__footer'>
                        <AdditionalServicesTotal name={name} />
                        {!disabled && <CustomButton onClick={addNewService}>Добавить услугу</CustomButton>}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default MobileAdditionalServices
