import clsx from 'clsx'
import React, { memo, useEffect, useState } from 'react'
import { useFetchClientsByAddressQuery } from '~/API/clientApi/client'
import { ClientResponse } from '~/API/clientApi/client.types'
import { ClientAddressType } from '~/components/common/inputs/CustomClientInput/CustomClientInput.types'
import { ClientData } from '~/components/common/inputs/CustomClientInput/subComponents/ClientData/ClientData'
import {
    ItemType,
} from '~/components/common/inputs/CustomClientInput/subComponents/ClientOrganization/ClientOrganization.types'
import CustomInput from '~/components/common/inputs/CustomInput/CustomInput'
import './ClientAddress.scss'
import Preloader from '~/components/common/preloaders/Preloader/Preloader'

interface ClientAddressProps {
    className?: string
    clientInfo: ClientResponse<ItemType>
    clientAddress: ItemType
    handleSetClientAddress: (value: ClientAddressType) => void
}

export const ClientAddress = memo((props: ClientAddressProps) => {
    const {
        className,
        clientInfo,
        clientAddress,
        handleSetClientAddress,
    } = props

    const {
        data: clientAddressData,
        isFetching: clientAddressLoading,
    } = useFetchClientsByAddressQuery({
        clientId: clientInfo.id,
    })

    const [addresses, setAddresses] = useState<ItemType[]>([])

    useEffect(() => {
        if (clientAddressData) {
            setAddresses(() => {
                return clientAddressData?.map((address, index) => ({
                    value: `${address}_${index}`,
                    text: address,
                }),
                )
            })
        }
    }, [clientAddressData])

    return (
        <div className={clsx('client-address', className)}>
            <CustomInput
                name='client-address__popup'
                className='client-address__input'
                value={clientInfo?.additionalParams?.text}
                disabled={true}
            />
            {!clientAddressLoading ? (
                <ClientData
                    data={addresses}
                    handleSetData={handleSetClientAddress}
                    currentItem={clientAddress}
                />
            ) : (
                <Preloader />
            )}

        </div>
    )
})
