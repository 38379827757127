import clsx from 'clsx'

const TileContent = (date: Date, events: {[key: string]: number}) => {
    const newDate = new Date(date).setHours(0, 0, 0, 0).toString()
    const count = events[newDate as keyof typeof events] || 0
    return (
        <div className={clsx('tileContent', { 'active': count !== 0 })}>
            <span>{date.getDate()}</span>
            {count !== 0 && <div className='tileContent__eventCount'>{count}</div>}
        </div>

    )
}

export default TileContent
