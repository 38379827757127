import Collapse from '@mui/material/Collapse'
import React, { useEffect, useId, useMemo, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { FaAngleUp } from 'react-icons/fa6'
import TabsTableHeader from './subComponents/TabsTableHeader/TabsTableHeader'
import TabsTablePage from './subComponents/TabsTablePage/TabsTablePage'
import {
    CustomCellType,
    TabsTableHeaderPopover,
    TabsTableHeaderSection,
    TabsTableHeaderSingleSection,
    TabsTablePageType,
    TabsTableTemplate,
} from './tabsTable.types'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import {
    CheckTypes,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableHeader/subComponents/TabsTableCheckIcon/TabsTableCheckIcon'
import { TabsTableContext } from '~/components/common/ReusingComponents/TabsTable/TabsTableContext'
import IphoneScroll from '~/test/IphoneScroll'

import './TabsTable.scss'

export const enum TabsTableMods {
    MANY = 'many',
    SINGLE = 'single'
}

export interface TabsTableProps {
    name: string
    itemsNotNull?: boolean
    withHideButton?: boolean
    withAddButton?: boolean
    withToolbar?: boolean
    alwaysShowHeader?: boolean
    templates?: TabsTableTemplate[]
    onTemplateLoad?: (pageIndex: number) => void
    isStrictTemplateSetting?: boolean
    disabled?: boolean
    disabledHeader?: boolean
    isLoading?: boolean
    headerSections: TabsTableHeaderSection[] | TabsTableHeaderSingleSection[]
    onRowChange?: () => void
    onSubscriptionFieldChange?: (index: number, name: string, value: any) => void
    footer?: React.ReactElement
    customHeader?: React.ReactElement
    customTools?: React.ReactElement
    customButtonNeighbor?: React.ReactElement
    customCell?: CustomCellType[]
    setOutsideActiveIndex?: (index: number) => void
    title?: string
    defaultValues?: {
        name: string,
        title: string,
        items: any[]
    }[]
    checkIcons?: {
        inputName: string
        checkType: CheckTypes
    }
    usingData?: string
    headerPopover?: TabsTableHeaderPopover
    tabsTableMode?: TabsTableMods
    tabIndex?: number
}

const TabsTable = (props: TabsTableProps) => {
    const {
        name,
        itemsNotNull,
        withHideButton,
        withAddButton,
        withToolbar,
        alwaysShowHeader,
        templates,
        onTemplateLoad,
        isStrictTemplateSetting,
        disabled,
        disabledHeader,
        headerSections,
        footer,
        customHeader,
        customTools,
        customButtonNeighbor,
        onRowChange,
        onSubscriptionFieldChange,
        setOutsideActiveIndex,
        title,
        defaultValues,
        isLoading,
        checkIcons,
        usingData,
        headerPopover,
        tabsTableMode = TabsTableMods.MANY,
        customCell,
    } = props

    const [isOpen, setIsOpen] = useState(true)
    const [activeTabIndex, setActiveTabIndex] = useState(0)

    const singleSectionId = useId()

    const {
        control,
        setValue,
    } = useFormContext()

    const {
        fields: pages,
        append: appendPage,
        remove: removePage,
        update: updatePage,
        move: movePage,
    } = useFieldArray({ control, name })

    const inputsMap = useMemo(() => {
        return headerSections.reduce((acc, item) => {
            if ('name' in item) {
                return ({
                    ...acc,
                    [item.name]: item.inputs.filter((input) => !!input),
                })
            } else {
                return ({
                    ...acc,
                    [singleSectionId]: item.inputs.filter((input) => !!input),
                })
            }
        }, {} as Record<string, any>)
    }, [headerSections])

    const TableBody = () => {
        switch (tabsTableMode) {
            case TabsTableMods.MANY: {
                return (
                    <>
                        {(alwaysShowHeader || withAddButton || pages.length > 1) &&
                            <TabsTableHeader customHeader={customHeader} />
                        }
                        {pages.length ?
                                (pages as TabsTablePageType<any>[]).map((page, index) => {
                                    return (
                                        <Controller
                                            name={`${name}[${index}].items`}
                                            control={control}
                                            rules={{
                                                validate: {
                                                    itemsNotNull: (items) => itemsNotNull ? items.length > 0 || index.toString() : true,
                                                },
                                            }}
                                            key={page.id + index}
                                            render={() => (
                                                <TabsTablePage
                                                    name={`${name}.${index}.items`}
                                                    sectionName={page.type}
                                                    isActive={index === activeTabIndex}
                                                    inputs={inputsMap[page.type]}
                                                    pageIndex={index}
                                                />
                                            )}
                                        />
                                    )
                                }) :
                                <p>В таблице нет данных</p>}

                        {footer}
                    </>
                )
            }
            case TabsTableMods.SINGLE: {
                return (
                    <>
                        <Controller
                            name={name}
                            control={control}
                            rules={{
                                validate: {
                                    itemsNotNull: (items) => itemsNotNull ? items.length > 0 : true,
                                },
                            }}
                            key={singleSectionId}
                            render={() => (
                                <TabsTablePage
                                    name={name}
                                    sectionName={singleSectionId}
                                    isActive={true}
                                    inputs={inputsMap[singleSectionId]}
                                    pageIndex={0}
                                />
                            )}
                        />
                        {footer}
                    </>
                )
            }
        }
    }

    useEffect(() => {
        if (setOutsideActiveIndex) {
            setOutsideActiveIndex(activeTabIndex)
        }
    }, [activeTabIndex])

    useEffect(() => {
        if (defaultValues) {
            setValue(name, defaultValues)
        }
    }, [])

    return (
        <IphoneScroll>
            <div className='tabsTable__wrapper'>
                <div className='tabsTable__toggleButtonWrapper'>
                    {withHideButton && <CustomButton
                        className='tabsTable__toggleButton'
                        onClick={() => {
                            setIsOpen((prev) => !prev)
                        }}
                    >
                        {title || (isOpen ? 'Закрыть таблицу' : 'Открыть таблицу')}
                        <FaAngleUp />
                    </CustomButton>}
                    {customButtonNeighbor}
                </div>
                <div className='tabsTable'>
                    <TabsTableContext.Provider
                        value={{
                            baseName: name,
                            pages: pages as TabsTablePageType<any>[],
                            activeTabIndex,
                            setActiveTabIndex,
                            headerSections,
                            appendPage,
                            removePage,
                            movePage,
                            updatePage,
                            onRowChange,
                            onSubscriptionFieldChange,
                            withToolbar,
                            withAddButton,
                            templates,
                            onTemplateLoad,
                            isStrictTemplateSetting,
                            disabled,
                            disabledHeader,
                            isLoading,
                            customTools,
                            checkIcons,
                            usingData,
                            headerPopover,
                            customCell,
                            tabsTableMode,
                        }}>
                        <Collapse in={isOpen}>
                            {TableBody()}
                        </Collapse>
                    </TabsTableContext.Provider>
                </div>
            </div>
        </IphoneScroll>
    )
}

export default TabsTable
