import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

interface InputNZProps {
    caption?: any,
    type: any,
    title: string,
    className?: string,
    error?: FieldError,
    onChange?: (...event: any[]) => void,
    value: any,
}

const InputNZ = (props: InputNZProps) => {
    const {
        caption,
        type,
        title,
        className,
        error,
        onChange,
        value,
    } = props
    const navigate = useNavigate()
    const { id } = useParams()

    return (
        <div className={`inputNZ customField customFileInput ${className || ''}`}>
            <h5>{title}</h5>
            <div className='customFileInput__wrapper'>
                <div
                    className='customFileInput__input'
                    onClick={() => navigate(`/order/${id}/inputnz/`)}
                >
                    <AddIcon className={'customFileInput__add'} />
                    <span>{caption}</span>
                </div>
            </div>
        </div>
    )
}

export default InputNZ
