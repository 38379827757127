import './NotificationWrapper.scss'
import 'react-toastify/dist/ReactToastify.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { SELECT_NOTICE } from '~/redux/selectors/notificationSlice.selectors'
import { NoticeType } from '~/redux/slices/notificationSlice/notificationSlice.types'
import { isMobile } from '~/shared/util/deviceDetect'


const NotificationWrapper = () => {
    const newNotice = useSelector(SELECT_NOTICE)

    const generateCurrentIcon = useCallback((type: NoticeType) => {
        switch (type) {
            case 'error':
                return <ErrorIcon className={'logoIcon'} />
            case 'warning':
                return <ErrorIcon className={'logoIcon'} />
            case 'success':
                return <CheckCircleIcon className={'logoIcon'} />
            default:
                return <CheckCircleIcon className={'logoIcon'} />
        }
    }, [])

    useEffect(() => {
        if (newNotice) {
            toast[newNotice.type](newNotice.message, {
                className: `custom-toast toast-${newNotice.type}`,
                icon: generateCurrentIcon(newNotice.type),
            })
        }
    }, [newNotice])

    return (
        <ToastContainer
            style={{ width: '23em' }}
            position={isMobile ? 'bottom-center' : 'bottom-right'}
            autoClose={4000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            transition={Slide}
            theme='light'
            closeButton={<CloseIcon className={'popupNotification__closeIcon'} />}
        />
    )
}

export default NotificationWrapper
