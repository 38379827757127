import ExpandMore from '@mui/icons-material/ExpandMore'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { CustomDateInputModes } from '~/components/common/inputs/CustomDateInput/CustomDateInput'
import { CustomSelectThemes } from '~/components/common/inputs/CustomSelect/CustomSelect'
import { fetchDate } from '~/shared/util/currentTime'
import '../../../../common/inputs/CustomField.scss'
import MobilePopupDatePicker from '../../../../common/popups/MobilePopupDatePicker/MobilePopupDatePicker'

interface MobileCustomDateInputProps {
    title: string
    value: any
    onChange: (value: any) => void
    disabled: boolean
    singleDate: boolean
    cleaningDate: boolean
    className?: string
    error?: any
    theme?: CustomSelectThemes
    mode?: CustomDateInputModes
}

const MobileCustomDateInput = (props: MobileCustomDateInputProps) => {
    const {
        title,
        value,
        onChange,
        disabled,
        singleDate,
        cleaningDate,
        className,
        error,
        theme = CustomSelectThemes.OUTLINE,
        mode = CustomDateInputModes.DATE_TIME,
    } = props
    const [popup, setPopup] = useState(false)
    const [valueToDisplay, setValueToDisplay] = useState('')

    const handleChange = (values: any) => {
        onChange(values)
    }

    useEffect(() => {
        if (value) {
            switch (mode) {
                case CustomDateInputModes.DATE_TIME: {
                    if (value.startDate) {
                        let newValue = fetchDate(value.startDate).format('D.MM.YYYY • HH:mm')
                        if (value.endDate) {
                            newValue += ' - ' + fetchDate(value.endDate).format('D.MM.YYYY • HH:mm')
                        }
                        setValueToDisplay(newValue)
                        break
                    }
                    if (value.endDate) {
                        setValueToDisplay(fetchDate(value.endDate).format('D.MM.YYYY • HH:mm'))
                        break
                    }
                    if (value) {
                        setValueToDisplay(fetchDate(value.endDate || value).format('D.MM.YYYY • HH:mm'))
                        break
                    }
                    break
                }
                case CustomDateInputModes.TIME: {
                    if (value.startDate) {
                        let newValue = fetchDate(value.startDate).format('HH:mm')
                        if (value.endDate) {
                            newValue += ' - ' + fetchDate(value.endDate).format('HH:mm')
                        }
                        setValueToDisplay(newValue)
                        break
                    }
                    if (value.endDate) {
                        setValueToDisplay(fetchDate(value.endDate).format('HH:mm'))
                        break
                    }
                    if (value) {
                        setValueToDisplay(fetchDate(value.endDate || value).format('HH:mm'))
                        break
                    }
                    break
                }
                case CustomDateInputModes.DATE: {
                    if (value.startDate) {
                        let newValue = fetchDate(value.startDate).format('D.MM.YYYY')
                        if (value.endDate) {
                            newValue += ' - ' + fetchDate(value.endDate).format('D.MM.YYYY')
                        }
                        setValueToDisplay(newValue)
                        break
                    }
                    if (value.endDate) {
                        setValueToDisplay(fetchDate(value.endDate).format('D.MM.YYYY'))
                        break
                    }
                    if (value) {
                        setValueToDisplay(fetchDate(value.endDate || value).format('D.MM.YYYY'))
                        break
                    }
                    break
                }
            }
        } else {
            setValueToDisplay('')
        }
    }, [value])

    return (
        <div className={clsx('customField', 'customDateInput', className, { error, disabled })}>
            <span>{title}</span>
            {disabled ? (
                <div className={'customField__input'}>
                    <input type='text' value={valueToDisplay} disabled={true} />
                </div>
            ) : (
                <div
                    className={clsx('customField__select', theme && `customField__select--${theme}`)}
                    onClick={() => {
                        setPopup(true)
                    }}
                >
                    {valueToDisplay !== '' ? (
                        <span>{valueToDisplay}</span>
                    ) : (
                        <span className={'customField__placeholder'}>Выберете дату</span>
                    )}
                    <ExpandMore className={'customField__expand'} />
                </div>
            )}
            {popup && (
                <MobilePopupDatePicker
                    onClose={() => setPopup(false)}
                    isOpen={popup}
                    onSubmit={handleChange}
                    singleDate={singleDate}
                    defaultValues={value}
                    cleaningDate={cleaningDate}
                />
            )}
        </div>
    )
}

export default MobileCustomDateInput
