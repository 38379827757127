import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useFetchOrderQuery } from '~/API/orderApi/order'
import { ServiceGroup } from '~/API/orderApi/types/foremanReport.type'
import StatusBar from '~/components/common/ReusingComponents/StatusBar/StatusBar'
import {
    calcStatusBarProgress,
} from '~/components/common/ReusingComponents/TabsTable/hocs/withForemanReportChecklist/subComponents/calcStatusBarProgress'
import {
    WORKING_DAY_REPORT_NOT_DISABLED_STAGES,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/oneTimeWork/WORKING_DAY_REPORT'

interface ChecklistStatusBarProps {
    name: string
    title?: string
}

const ChecklistStatusBar = (props: ChecklistStatusBarProps) => {
    const { name, title } = props

    const { id: orderId } = useParams()
    const watchFields: ServiceGroup[] = useWatch({ name })
    const {
        setValue,
        getValues,
    } = useFormContext()

    const { data: order } = useFetchOrderQuery(orderId!)

    const percent = calcStatusBarProgress(watchFields)

    useEffect(() => {
        if (order && WORKING_DAY_REPORT_NOT_DISABLED_STAGES.includes(order.stageName)) {
            if (watchFields.every((watchField) => watchField.items?.every((field) => field.status))) {
                setValue('foremanReport.isAllChecks', true)
            } else if (getValues('foremanReport.isAllChecks')) {
                setValue('foremanReport.isAllChecks', false)
            }
        }
    }, [watchFields])

    return <StatusBar
        className='tabsTable__statusBar'
        percent={percent}
        title={title}
    />
}

export default ChecklistStatusBar
