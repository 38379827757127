import { memo, ReactNode, RefObject } from 'react'
import { Portal } from '~/components/common/ReusingComponents/Portal/Portal'
import './CustomPopupWrapper.scss'

interface CustomPopupWrapperProps {
    onClose: (...args: any) => void
    innerElementRef: RefObject<HTMLDivElement>
    children?: ReactNode
}

export const CustomPopupWrapper = memo((props: CustomPopupWrapperProps) => {
    const {
        onClose,
        innerElementRef,
        children,
    } = props

    const handleClose = (e: any) => {
        if (innerElementRef.current && !innerElementRef.current.contains(e.target) && e.target === e.currentTarget) {
            onClose()
        }
    }

    return (
        <Portal>
            <div
                className='custom-popup-wrapper'
                onClick={handleClose}
            >
                {children}
            </div>
        </Portal>
    )
})
