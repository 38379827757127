import { HTMLAttributes } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import './BackButton.scss'

interface BackButtonProps extends HTMLAttributes<HTMLButtonElement> {
    callback: (value: any) => void,
}

const BackButton = ({ callback, style }: BackButtonProps) => {
    return (
        <button
            className='back-button'
            onClick={callback}
            style={style}
        >
            <FaArrowLeft className='back-button__icon' />
        </button>
    )
}

export default BackButton
