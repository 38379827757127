import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCreateOrderMutation } from '~/API/orderApi/order'
import { createOrderFulfilled, createOrderPending, createOrderRejected } from '~/redux/slices/orderSlice/orderSlice'
import { useAppDispatch } from '~/redux/store'

export const useCreateNewOrder = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [createOrder] = useCreateOrderMutation()

    return useCallback((userId: string) => {
        dispatch(createOrderPending())
        createOrder(userId).unwrap()
            .then((order) => {
                dispatch(createOrderFulfilled(order))
                navigate('/order/' + order.id, { replace: true })
            })
            .catch((e) => {
                dispatch(createOrderRejected(e))
            })
    }, [])
}
