import { chooseSvg } from '~/shared/util/svg'
import './MiniCalendarHint.scss'

const MiniCalendarHint = ({ events }: { events: any[] }) => {
    return (
        <div className='miniCalendarHint'>
            <div className='miniCalendarHint__logo'>{chooseSvg('primary')}</div>
            <div className='miniCalendarHint__body'>
                <h5>Общее количество</h5>
                <p>{events?.length || 0} события</p>
            </div>
        </div>
    )
}

export default MiniCalendarHint
