import { useState } from 'react'
import Modal from 'react-modal'
import './MobileEditModal.scss'
import CustomCheckbox, { CustomCheckboxTheme } from '~/components/common/checkbox/CustomCheckbox/CustomCheckbox'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { OnSubscriptionFieldChange } from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import EditModalItem
    from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileEditModal/subComponents/EditModalItem/EditModalItem'

interface MobileEditModalProps {
    isOpen: boolean
    closeModal: () => void
    inputs: any[]
    inputName: string
    disabled: boolean
    sectionName?: string
    rowIndex: number
    usingData?: string
    sectionTitle?: string
    onSubscriptionFieldChange?: OnSubscriptionFieldChange
    onDelete?: (index: number) => void
    deleteText?: string
}

const MobileEditModal = (props: MobileEditModalProps) => {
    const {
        isOpen,
        closeModal,
        inputs,
        disabled,
        inputName,
        sectionName,
        rowIndex,
        usingData,
        sectionTitle,
        onSubscriptionFieldChange,
        onDelete,
        deleteText = 'Удалить',
    } = props

    const [isDelete, setIsDelete] = useState<boolean>(false)

    const handleDelete = () => {
        onDelete && onDelete(rowIndex)
        closeModal()
    }

    const handleCancel = () => {
        setIsDelete(false)
        closeModal()
    }

    return (
        <Modal
            id={inputName}
            isOpen={isOpen}
            onRequestClose={() => handleCancel()}
            closeTimeoutMS={200}
            className='mobileEditModal'
            overlayClassName={{
                base: 'mobileEditModal__overlay',
                afterOpen: 'mobileEditModal__overlay--afterOpen',
                beforeClose: 'mobileEditModal__overlay--beforeClose',
            }}
        >
            <div className='mobileEditModal__content'>
                {inputs.map((input, index) => (
                    <EditModalItem
                        key={index}
                        inputName={inputName}
                        disabled={disabled}
                        input={input}
                        sectionName={sectionName}
                        rowIndex={rowIndex}
                        usingData={usingData}
                        sectionTitle={sectionTitle}
                        onSubscriptionFieldChange={onSubscriptionFieldChange}
                    />
                ))}
                {onDelete && <div className='mobileEditModal__delete'>
                    <CustomCheckbox
                        className='mobileEditModal__checkbox'
                        name={`${inputName}_isDelete`}
                        id='moneyFlowPopup'
                        value={isDelete}
                        onChange={() => setIsDelete((prev) => !prev)}
                        caption={deleteText}
                        theme={CustomCheckboxTheme.CHECK_MARK}
                        error={isDelete}
                    />
                </div>}
            </div>
            <div className='mobileEditModal__footer'>
                <CustomButton theme={CustomButtonThemes.SMOKE} onClick={handleCancel}>Отмена</CustomButton>
                <CustomButton
                    disabled={disabled}
                    theme={isDelete ? CustomButtonThemes.DANGER : CustomButtonThemes.COLOR}
                    onClick={() => isDelete ? handleDelete() : closeModal()}
                >
                    {isDelete ? 'Удалить' : 'Сохранить'}
                </CustomButton>
            </div>
        </Modal>
    )
}

export default MobileEditModal
