import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { User } from '~/API/userApi/user.types'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { useChat } from '~/hooks/useChat/useChat'
import { useKeyPress } from '~/hooks/useKeyPress'

interface CommentInputProps {
    handleSetScrolled: (value: boolean) => void
    user: User
}

export const CommentInput = (props: CommentInputProps) => {
    const {
        handleSetScrolled,
        user,
    } = props

    const { id = '' } = useParams()

    const [messageText, setMessageText] = useState('')

    const { chatActions: { send } } = useChat({ id })

    const handleSendMessage = useCallback(() => {
        if (messageText && id && user) {
            handleSetScrolled(false)
            send({
                orderId: id,
                message: messageText.trim(),
                userId: user?.id,
                date: new Date(),
                type: 'CLASSIC',
            })
            setMessageText('')
        }
    }, [handleSetScrolled, id, messageText, send, user])

    const isEnterPressed = useKeyPress('Enter')

    useEffect(() => {
        if (isEnterPressed) {
            handleSendMessage()
        }
    }, [handleSendMessage, isEnterPressed])

    return (
        <div className='comments__body__input__container'>
            <input
                type='text'
                value={messageText}
                className='comments__body__input'
                onChange={(e) => setMessageText(e.target.value)}
            />
            <CustomButton
                className='comments__body__input__btn'
                onClick={() => handleSendMessage()}
            >
                <FaArrowRightLong size={20} />
            </CustomButton>
        </div>
    )
}
