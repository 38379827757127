import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'

export const returningDocuments = {
    responsibleManager: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Ответственный менеджер',
        name: 'responsibleManager',
        className: 'input50',
        type: 'user',
    }),
    reportDate: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Дата отчета',
        name: 'reportDate',
        className: 'input33',
        singleDate: true,
        type: 'date',
    }),
    contractPhoto: (disabled: boolean) => ({
        disabled: disabled,
        name: 'contractPhoto',
        title: 'Скан договора',
        type: 'document',
        button: 'Добавить файл',
        parentClassName: 'thin-content',
        required: true,
        allowedFileTypes: ['application', 'image'],
    }),
    actPhoto: (disabled: boolean) => ({
        disabled: disabled,
        name: 'actPhoto',
        title: 'Скан акта',
        type: 'document',
        button: 'Добавить файл',
        parentClassName: 'thin-content',
        required: true,
        allowedFileTypes: ['application', 'image'],
    }),
    updPhoto: (disabled: boolean) => ({
        disabled: disabled,
        name: 'updPhoto',
        title: 'УПД',
        type: 'document',
        button: 'Добавить файл',
        parentClassName: 'thin-content',
        required: true,
        allowedFileTypes: ['application', 'image'],
    }),
    otherDocuments: (disabled: boolean) => ({
        disabled: disabled,
        name: 'otherDocuments',
        title: 'Другие документы',
        max: 150,
        type: 'documentList',
        button: 'Добавить файл',
        multiple: true,
        allowedFileTypes: ['application', 'image'],
    }),
    actualPayment: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Фактическая сумма оплаты',
        name: 'actualPayment',
        className: 'input33',
        type: 'text',
        mask: InputMask.COST,
    }),
    paymentPhoto: (disabled: boolean) => ({
        disabled: disabled,
        name: 'paymentPhoto',
        title: 'Платежное поручение',
        type: 'document',
        button: 'Добавить файл',
        required: true,
        allowedFileTypes: ['application', 'image'],
    }),
}
