import { useMemo, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import Modal from 'react-modal'
import BackButton from '~/components/common/ReusingComponents/BackButton/BackButton'
import { CustomMobileSlider } from '~/components/common/ReusingComponents/CustomSlider/CustomMobileSlider'
import ChecklistStatusBar
    from '~/components/common/ReusingComponents/TabsTable/hocs/withForemanReportChecklist/subComponents/ChecklistStatusBar'
import {
    CheckTypes,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableHeader/subComponents/TabsTableCheckIcon/TabsTableCheckIcon'
import { TabsTablePageType } from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import CheckIconsTabs
    from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTabs/subComponents/CheckIconsTabs/CheckIconsTabs'
import MobileTitle from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTitle/MobileTitle'
import MobileTitleButton
    from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileTitleButton/MobileTitleButton'
import ForemanChecklistItem
    from '~/components/mobile/mobilePages/MobileOrderPage/subComponents/MobileForemanChecklist/subComponents/ForemanChecklistItem/ForemanChecklistItem'
import { TaskItem } from '~/components/pages/KP/kp.types'
import { get } from '~/shared/util/get'
import './MobileForemanChecklist.scss'

interface MobileForemanChecklistProps {
    title: string
    name: string
    disabled?: boolean
    checkIcons?: {
        inputName: string,
        checkType: CheckTypes,
    },
}

const MobileForemanChecklist = (props: MobileForemanChecklistProps) => {
    const {
        title,
        name,
        disabled,
        checkIcons,
    } = props

    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
    const [listModalOpen, setListModalOpen] = useState<boolean>(false)

    const {
        control,
        formState,
    } = useFormContext()

    const { fields: pages } = useFieldArray({ control, name })

    const pagesErrors = useMemo(() => get(formState.errors, name), [formState])

    const tabs = useMemo(() => (pages as TabsTablePageType<TaskItem>[]).map((page, index) => ({
        title: page.title,
        isError: !!pagesErrors?.[index],
    })), [pages, formState])

    const displayNone = useMemo(() => ({
        overlay: {
            display: !listModalOpen ? 'none' : '',
        },
        content: {
            padding: 0,
        },
    }), [listModalOpen])

    return (
        <>
            <div className='mobileForemanChecklist__wrapper'>
                <ChecklistStatusBar name={name} />
                <MobileTitleButton
                    title={title}
                    onClick={() => setListModalOpen(true)}
                    isError={!!pagesErrors}
                />
            </div>
            <Modal
                id={`MobileForemanChecklist__${name}`}
                isOpen={true}
                onRequestClose={() => setListModalOpen(false)}
                style={displayNone}
                className='mobileServicesVerification__modal'
            >
                <BackButton callback={() => setListModalOpen(false)} />
                <MobileTitle title={title} />
                <CheckIconsTabs
                    activeTabIndex={activeTabIndex}
                    tabs={tabs}
                    setActiveTabIndex={setActiveTabIndex}
                    name={name}
                    control={control}
                    checkIcons={checkIcons}
                />
                <CustomMobileSlider
                    index={activeTabIndex}
                    onChangeIndex={setActiveTabIndex}
                    className='mobileCustomService__slider--active'
                >
                    {(pages as TabsTablePageType<TaskItem>[]).map((page, pageIndex) => (
                        <div key={page.id + pageIndex} className='mobileForemanChecklist__list'>
                            {page.items.map((item, rowIndex: number) => (
                                <Controller
                                    key={rowIndex}
                                    name={`${name}.${pageIndex}.items.${rowIndex}.status`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <ForemanChecklistItem
                                            title={item.name}
                                            description={item.description}
                                            onChange={onChange}
                                            status={value}
                                            disabled={disabled}
                                        />
                                    )}
                                />
                            ))}
                        </div>
                    ))}
                </CustomMobileSlider>
            </Modal>
        </>
    )
}

export default MobileForemanChecklist
