import { ServiceGroup } from '~/API/orderApi/types/foremanReport.type'

export const calcStatusBarProgress = (fields: ServiceGroup[]) => {
    if (!fields?.length) {
        return 100
    }

    const wholeProgress =
        100 *
        fields.reduce((accum, section) => {
            return accum + section.items?.filter((e) => e.status).length
        }, 0)
    const doneProgress = fields.reduce((accum, section) => accum + section.items?.length, 0)
    return Math.round(wholeProgress / doneProgress)
}
