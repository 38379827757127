import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import clsx from 'clsx'
import React from 'react'
import { Controller, UseFieldArrayAppend, UseFieldArrayRemove, useFormContext } from 'react-hook-form'
import CustomDateInput from '../../../inputs/CustomDateInput/CustomDateInput'
import CustomInput from '../../../inputs/CustomInput/CustomInput'
import CustomSelect from '../../../inputs/CustomSelect/CustomSelect'
import { InputParams, InputTemplateType } from '~/hooks/useFormConstructor/useFormConstructor.types'

interface CustomFieldArrayBodyProps {
    name: string
    index: number
    item: any
    disableMode?: boolean
    fieldsLength: number
    division?: string
    className?: string
    disabled: boolean
    remove: UseFieldArrayRemove
    append: UseFieldArrayAppend<any>
    items: InputParams[] | InputTemplateType
    isLastIndex: boolean
}

export const CustomFieldArrayBody = (props: CustomFieldArrayBodyProps) => {
    const {
        name,
        item,
        index,
        disableMode,
        fieldsLength,
        division,
        className,
        disabled,
        remove,
        append,
        items,
        isLastIndex,
    } = props
    const {
        getValues,
        control,
    } = useFormContext()

    return (
        <div className='customFieldArray__item'>
            <Controller
                name={`${name}.${index}.${item.name}`}
                control={control}
                rules={{ required: item.required }}
                defaultValue={''}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    switch (item.type) {
                        case 'text':
                            return (
                                <CustomInput
                                    {...item}
                                    className={clsx('formSection__inputText', className)}
                                    error={error}
                                    value={value}
                                    onChange={onChange}
                                    disabled={
                                        item.disabled || (disableMode && index !== fieldsLength - 1) || disabled
                                    }
                                />
                            )
                        case 'select':
                            return (
                                <CustomSelect
                                    {...item}
                                    className={clsx('formSection__inputSelect', className)}
                                    error={error}
                                    onChange={onChange}
                                    value={value}
                                    disabled={
                                        item.disabled || (disableMode && index !== fieldsLength - 1) || disabled
                                    }
                                />
                            )
                        case 'date':
                            return (
                                <CustomDateInput
                                    {...item}
                                    error={error}
                                    onChange={onChange}
                                    value={value}
                                    disabled={
                                        item.disabled || (disableMode && index !== fieldsLength - 1) || disabled
                                    }
                                    division={division || getValues().selling?.division}
                                />
                            )
                        case 'tel':
                            return (
                                <CustomInput
                                    {...item}
                                    className={clsx('formSection__inputText', className)}
                                    error={error}
                                    value={value}
                                    onChange={onChange}
                                    disabled={
                                        item.disabled || (disableMode && index !== fieldsLength - 1) || disabled
                                    }
                                />
                            )
                        default:
                            return <div></div>
                    }
                }}
            />
            {!disableMode && !disabled && isLastIndex && (
                <>
                    {index !== 0 ? (
                        <button
                            className='customFieldArray__button customFieldArray__delete'
                            type='button'
                            onClick={() => remove(index)}
                        >
                            <DeleteIcon />
                            <span>Удалить</span>
                        </button>
                    ) : (
                        <button
                            className='customFieldArray__button'
                            type='button'
                            onClick={() =>
                                append(
                                    Object.values(items).reduce(
                                        (accum, item) => ({
                                            ...accum,
                                            [item.name]: '',
                                        }),
                                        {},
                                    ))
                            }
                        >
                            <AddIcon />
                            <span>Добавить</span>
                        </button>
                    )}
                </>
            )}
        </div>
    )
}
