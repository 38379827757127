import { HiDotsVertical } from '@react-icons/all-files/hi/HiDotsVertical'
import React, { useContext } from 'react'
import { FaExclamation } from 'react-icons/fa'
import TabsTableCheckIcon
    from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableHeader/subComponents/TabsTableCheckIcon/TabsTableCheckIcon'
import { TabsTableContext } from '~/components/common/ReusingComponents/TabsTable/TabsTableContext'

import './TabsTableTab.scss'

interface TabsTableTabProps {
    title: string
    openPopover: (event: React.MouseEvent<SVGElement, MouseEvent>, popoverIndex: number) => void
    index: number
    isError: boolean
}

const TabsTableTab = (props: TabsTableTabProps) => {
    const {
        title,
        openPopover,
        index,
        isError,
    } = props

    const {
        disabledHeader,
        withAddButton,
        headerPopover,
        checkIcons,
    } = useContext(TabsTableContext)

    return (
        <div className='tabsTableTab'>
            {title}
            {((withAddButton && !disabledHeader) || (headerPopover && !disabledHeader)) &&
                <div className='tabsTableTab__iconWrapper'>
                    <HiDotsVertical
                        size={10}
                        onClick={(e) => openPopover(e, index)}
                    />
                </div>}

            {isError && <div className='tabsTableTab__iconWrapper tabsTableTab__iconWrapper--error'>
                <FaExclamation size={10} />
            </div>}

            {checkIcons && !isError && <TabsTableCheckIcon index={index} />}
        </div>
    )
}

export default TabsTableTab
