import React, { useCallback, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchOrderQuery } from '~/API/orderApi/order'
import CustomSelect from '~/components/common/inputs/CustomSelect/CustomSelect'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { CustomPopup } from '~/components/common/ReusingComponents/CustomPopup/CustomPopup'
import { DISABLED_STAGES } from '~/components/pages/KP/constants'
import {
    selectKpGetter,
    selectKpHandleControl,
    selectKpHandleSave,
    selectKpHandleSubmit, selectKpTrigger,
} from '~/redux/selectors/kpSlice.selectors'

const COMPANY_WITHOUT_NDS = [
    'H2O',
    'ORISCLEAN',
] as const

const COMPANY_WITH_NDS = [
    'H2O СЕРВИС ГРУПП',
] as const

const COMPANY_CASH = [
    'H2O',
] as const

const KpSidebarButtons = () => {
    const { id: orderId } = useParams()
    const navigate = useNavigate()

    const handleSubmit = useSelector(selectKpHandleSubmit)
    const handleControl = useSelector(selectKpHandleControl)
    const handleTrigger = useSelector(selectKpTrigger)
    const handleGetter = useSelector(selectKpGetter)
    const handleSave = useSelector(selectKpHandleSave)

    const [isPopup, setPopup] = useState(false)

    const handleSelectCompany = () => {
        const companyName = handleGetter('company')
        if (!companyName) {
            void handleTrigger()
        } else {
            handleSubmit(companyName === 'ORISCLEAN')
            setPopup(false)
        }
    }

    const {
        data: order,
    } = useFetchOrderQuery(orderId!)
    const disabled = !DISABLED_STAGES.filter((stage) => stage !== 'WORKING_PLAN_APPROVING').includes(order?.stageName ?? '') // order?.stageName !== 'COMMERCIAL_OFFER_APPROVING' && order?.stageName !== 'DATE_BOOKING'

    const popupCompanyItems = useMemo(() => {
        if (order) {
            switch (order?.accountingAndSelling?.commercialOffer?.paymentType) {
                case 'р/с (НДС)': {
                    return COMPANY_WITH_NDS.map((item) => ({ value: item, text: item }))
                }
                case 'р/с (без НДС)': {
                    return COMPANY_WITHOUT_NDS.map((item) => ({ value: item, text: item }))
                }
                default: {
                    return COMPANY_CASH.map((item) => ({ value: item, text: item }))
                }
            }
        }
        return COMPANY_WITHOUT_NDS.map((item) => ({ value: item, text: item }))
    }, [order])

    return (
        <>
            <div className='sidebar__buttons'>
                <CustomButton
                    className='sidebar__buttonsItem'
                    onClick={() => setPopup(true)}
                    disabled={disabled}
                >
                    Сформировать КП
                </CustomButton>
                <CustomButton
                    className='sidebar__buttonsItem'
                    onClick={() => handleSave()}
                    disabled={disabled}
                >
                    Сохранить
                </CustomButton>
                <CustomButton
                    className='sidebar__buttonsItem sidebar__buttonsItem--secondary'
                    onClick={() => navigate(`/order/${orderId}`)}
                >
                    Вернуться к заявке
                </CustomButton>
            </div>
            {isPopup && (
                <CustomPopup
                    onClose={() => setPopup(false)}
                    title={{
                        title: 'Выбрать компанию для выставления КП',
                    }}
                    body={{
                        data: [
                            <Controller
                                key='company'
                                name='company'
                                control={handleControl()}
                                rules={{ required: true }}
                                defaultValue={''}
                                render={({ field: { value, onChange, name }, fieldState: { error } }) =>
                                    <CustomSelect
                                        title='Компания'
                                        value={value}
                                        items={popupCompanyItems}
                                        name={name}
                                        error={error}
                                        onChange={(value) => {
                                            onChange(value)
                                        }}
                                    />
                                }
                            />,
                        ],
                    }}
                    footer={[
                        <CustomButton
                            key={'Сформировать КП'}
                            onClick={handleSelectCompany}
                            title='Сформировать КП'
                        />,
                    ]}
                />
            )}
        </>
    )
}

export default KpSidebarButtons
