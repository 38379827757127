import { rules } from '~/shared/order/rules'
import { StageManager } from '~/shared/order/StageManager'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { additionalInputs } from '~/shared/templates/inputTemplates/v2/additionalInputs'

export const DEPARTURE_CONFIRMATION_SECTIONS = {
    cleaningTask: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Задача (ЦУ для выполнения работ)',
        name: 'cleaningTask',
        type: 'text',
        multiline: true,
        version: 'v2',
    }),
}

export const DEPARTURE_CONFIRMATION_ACTIONS = {
    mainActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'SERVICES_VERIFICATION',
                text: 'Выезд подтвержден',
                icon: 'forward',
            },
            {
                value: 'CLIENT_DECLINING',
                text: 'Недозвон до клиента',
                icon: 'repeat',
            },
            {
                value: 'NEED_CHANGES_TO_WORKING_PLAN_APPROVING',
                text: 'Отмена выезда',
                icon: 'back',
            },
            {
                value: 'SEND_TO_MANAGER',
                text: 'Отправить руководителю',
                icon: 'back',
            },
        ],
        version: 'v2',
    }),
}

interface DEPARTURE_CONFIRMATION_PARAMS {
    reportDate: Date,
    additionalServices: any[],
    additionalServicesPhotos: any[],
    stages: string[],
    currentStage: string,
    user: any,
    archived: boolean,
    responsibleUserId: string,
    isLastSection: boolean
}

export const DEPARTURE_CONFIRMATION = (params: DEPARTURE_CONFIRMATION_PARAMS) => {
    const {
        reportDate,
        additionalServices,
        additionalServicesPhotos,
        stages,
        currentStage,
        user,
        archived,
        responsibleUserId,
        isLastSection,
    } = params
    const newInputs = []
    const documentInputs = []
    if (rules[user?.role]?.sections.foremanWorkStarting || responsibleUserId === user.id) {
        const availableAction = StageManager.checkAvailableStageByRole('DEPARTURE_CONFIRMATION', user.role)
        const disabled = stages.includes('SERVICES_VERIFICATION')

        if (reportDate) {
            newInputs.push([
                additionalInputs.division(true),
                additionalInputs.responsibleUserId(true, 'foremanReport.division', 'foreman'),
                additionalInputs.reportDate(true),
                additionalInputs.clientInfo(true),
            ])
        } else {
            newInputs.push([
                additionalInputs.division(true),
                additionalInputs.clientInfo(true),
            ])
        }
        newInputs.push([additionalInputs.address(true, 'primaryInformation.address')])
        newInputs.push([DEPARTURE_CONFIRMATION_SECTIONS.cleaningTask(true)])
        newInputs.push([additionalInputs.nzTask(true, 'documents.starting.nzFront')])

        if (availableAction && isLastSection) {
            const action = fetchFormAction({
                archived,
                section: 'REPORTS',
                user,
                currentStage,
                responsibleUserId,
            })
            if (action) {
                newInputs.push([action])
            }
        }
    }
    return {
        title: 'Подтверждение выезда',
        name: 'foremanReport',
        inputs: [...newInputs],
    }
}
