import { fetchFileModel } from '~/API/fileApi/file'
import { fetchUserById } from '~/API/userApi/user'
import { User } from '~/API/userApi/user.types'
import DocumentManager from '~/components/common/ReusingComponents/DocumentManager/DocumentManager'
import {
    AmortizationItem,
    LogisticsItem,
    OtherExpensesItem,
    TmcItem,
} from '~/components/pages/Smeta/smeta.types'
import { calculateDayDifference } from '~/components/pages/Smeta/utils/calculateDayDifference'
import { WageFundDay } from '~/components/pages/Smeta/utils/parseSmetaValues'
import { NzFrontValues } from '~/shared/templates/mainTemplates/nzNew'
import { fetchDate } from '~/shared/util/currentTime'
import { getLastNumber } from '~/shared/util/getLastNumberFromString'

interface Service {
    name: string
    inventory: {
        type: string,
        title: string,
        items: Omit<TmcItem, 'total'>[]
    }[]
    amortization: {
        type: string,
        title: string,
        items: Omit<AmortizationItem, 'total'>[]
    }[]
    logistic: Omit<LogisticsItem, 'total'>[]
    wageFund: WageFundDay[]
    otherExpanses: Omit<OtherExpensesItem, 'total'>[]
    amortizationTotal: number
    inventoryTotal: number
}

export interface TmcItemWithNumberTotal extends Omit<TmcItem, 'total'> {
    total: number
}

export interface AmortizationItemWithNumberTotal extends Omit<AmortizationItem, 'total'> {
    total: number
}

interface InventoryItem {
    type: string,
    title: string,
    items: TmcItemWithNumberTotal[]
}

interface AmortizationSection {
    type: string,
    title: string,
    items: AmortizationItemWithNumberTotal[]
}

interface BaseItem {
    id: string,
    amount: number | string,
    total: number
}

interface BaseService<T extends BaseItem> {
    type: string,
    title: string,
    items: T[]
}

export const mergeTools = <T extends BaseItem>(services: BaseService<T>[][]): BaseService<T>[] => {
    const merged: Record<string, { type: string, title: string, items: Record<string, T> }> = {}

    services.forEach((inventories) => {
        inventories.forEach((inv) => {
            if (!merged[inv.type]) {
                merged[inv.type] = {
                    type: inv.type,
                    title: inv.title,
                    items: {},
                }
            }

            inv.items.forEach((item) => {
                if (!merged[inv.type].items[item.id]) {
                    merged[inv.type].items[item.id] = { ...item }
                } else {
                    merged[inv.type].items[item.id].amount = Number(merged[inv.type].items[item.id].amount) + Number(item.amount)
                    merged[inv.type].items[item.id].total += item.total
                }
            })
        })
    })

    return Object.values(merged).map((inv) => ({
        type: inv.type,
        title: inv.title,
        items: Object.values(inv.items),
    }))
}

export const getInventoryWithTotal = (services: Service[]): InventoryItem[][] => {
    return services.map((service) =>
        service.inventory.map((inv) => ({
            ...inv,
            items: inv.items.map((tool) => ({
                ...tool,
                total: Math.ceil(+tool.amount * (+tool.unitPrice || 0)),
            })),
        }
        )),
    )
}

export const getAmortizationWithTotal = (services: Service[]): AmortizationSection[][] => {
    return services.map((service) => service.amortization.map((inv) => {
        const daysCount = getLastNumber(service.wageFund.at(-1)!.title!)

        return {
            ...inv,
            items: inv.items.map((tool) => {
                const totalPrice = +tool.amount * (+tool?.unitPrice || 0) * daysCount

                return {
                    ...tool,
                    total: Math.ceil(totalPrice),
                }
            }),
        }
    }))
}

const getTeamArray = (service: Service, manager: string) => {
    return service.wageFund.map((period, index) => {
        const { count, salary } = period.wageFundEmployee.reduce((acc, item) => {
            const dayDifference = calculateDayDifference(period.title!)
            acc.salary += ((+item.amount * +item.salary) || 0) * dayDifference

            if (!item.name.toLowerCase().includes('менеджер')) {
                acc.count += +item.amount
            }

            return acc
        }, {
            count: 0,
            salary: 0,
        })

        return ({
            title: period.title,
            taxiThere: service.logistic[index].taxiThere,
            taxiBack: service.logistic[index].taxiBack,
            petrol: service.logistic[index].petrol,
            parking: service.logistic[index].parking,
            count,
            salary,
            personnel: period.wageFundEmployee,
            manager,
            serviceName: service.name,
        })
    })
}

const getOtherExpanses = (services: Service[]): number => {
    return services.reduce((acc, service) => {
        service.otherExpanses.forEach((item) => {
            acc += +item.amount * +item.priceUnit
        })

        return acc
    }, 0)
}

export const createNZFile = async (order: any, user: User, isReturnValues?: boolean) => {
    const services = order.accountingAndSelling.commercialOffer.services.filter((service: any) => service.isFormed && service.isSelected)
    const manager = await fetchUserById(order.starting.executor)

    const contractId = order.documents.agreement?.contract

    let nzNumber
    if (contractId) {
        const contractModel = await fetchFileModel(contractId)
        nzNumber = contractModel.contractNumber
    } else {
        nzNumber = fetchDate().format('DDMMHm')
    }

    const inventoriesWithTotal = getInventoryWithTotal(services)
    const amortizationWithTotal = getAmortizationWithTotal(services)
    const mergedInventory = mergeTools(inventoriesWithTotal)
    const mergedAmortization = mergeTools(amortizationWithTotal)
    const wageFund = services.map((service: any) => getTeamArray(service, manager ? manager.fullname : '-'))
    const otherExpanses = getOtherExpanses(services)
    const prepayment = Math.floor(order.accountingAndSelling.cost * (order.agreement.prepayment / 100))
    const { amortizationTotal, inventoryTotal } = (services as Service[]).reduce((acc, item) => {
        acc.amortizationTotal += item.amortizationTotal
        acc.inventoryTotal += item.inventoryTotal
        return acc
    }, {
        amortizationTotal: 0,
        inventoryTotal: 0,
    })

    const values: NzFrontValues = {
        cleaningDate: order.starting.cleaningDate,
        responsibleManager: user?.fullname || '-',
        nzNumber,
        serviceType: order.primaryInformation.serviceType,
        buildingType: order.primaryInformation.buildingType,
        buildingClass: order.primaryInformation.buildingClass,
        contactInfo: order.primaryInformation.contactInfo,
        cost: order.accountingAndSelling.cost,
        prepayment,
        prepaymentType: order.agreement.prepaymentType,
        paymentType: order.accountingAndSelling.commercialOffer.paymentType,
        organizationName: order.primaryInformation.organizationRequisites?.name,
        counterparty: order.primaryInformation.counterparty,
        address: order.primaryInformation.address,
        objectArea: order.primaryInformation.objectArea,
        cleaningTask: order.starting.comment,
        tools: [...mergedInventory, ...mergedAmortization],
        wageFund,
        otherExpanses: otherExpanses.toFixed(),
        amortizationTotal,
        inventoryTotal,

    }

    if (isReturnValues) {
        return values
    }

    return await DocumentManager.saveDocument({
        title: 'НЗ',
        creator: '',
        type: 'nzNew',
        values,
        fileName: 'НЗ',
    })
}
