import clsx from 'clsx'
import React from 'react'
import Modal from 'react-modal'
import { CustomMobileSlider } from '~/components/common/ReusingComponents/CustomSlider/CustomMobileSlider'

type MobileModalSwipeProps = {
    index: number;
    onChangeIndex: (index: number) => void;
    tabs: JSX.Element[];
    isOpen: boolean;
    onRequestClose: () => void;
    contentLabel: string;
    modalId?: string;
    navbar?: any;
}

const MobileModalSwipe = (props: MobileModalSwipeProps) => {
    const {
        index,
        onChangeIndex,
        tabs,
        isOpen,
        onRequestClose,
        contentLabel,
        modalId = 'mobileModalSwipe',
        navbar,
    } = props

    return (
        isOpen ?
            <Modal
                id={modalId}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                contentLabel={contentLabel}
                ariaHideApp={false}
            >
                <>
                    {navbar && <div className='modalNavbar'>{navbar}</div>}
                    <CustomMobileSlider
                        index={index}
                        onChangeIndex={onChangeIndex}
                    >
                        {tabs?.map((item, i) => (
                            <div
                                className={clsx('modalTab')}
                                key={'modalTab' + i + index}
                            >
                                {item}
                            </div>
                        ))}
                    </CustomMobileSlider>
                </>
            </Modal> : null
    )
}

export default MobileModalSwipe
