import clsx from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import './CustomTabNavbar.scss'
import { CustomTabNavbarItem } from './subComponents/CustomTabNavbarItem/CustomTabNavbarItem'

export interface CustomTabNavbarData {
    value: string | number,
    title: string | number,
    isActive: boolean,
}

interface CustomTabNavbarProps {
    data: CustomTabNavbarData[]
    onChange: (value: any) => void
    className?: string
    withSearchParams?: boolean
    tabIndex?: number
}

export const CustomTabNavbar = (props: CustomTabNavbarProps) => {
    const {
        data,
        onChange,
        className,
        withSearchParams,
    } = props
    const [searchParams, setSearchParams] = useSearchParams()
    const underlineRef = useRef<HTMLDivElement>(null)

    const [menuParam, setMenuParam] = useState<{ offsetLeft: number, width: number }>({
        offsetLeft: 0,
        width: 0,
    })
    const [currentItem, setCurrentItem] = useState<number>(data.findIndex(({ isActive }) => isActive))

    const handleOnClick = (
        value: string | number,
        index: number,
        offset: number,
        width: number,
    ) => {
        if (withSearchParams) {
            setSearchParams({ tab: value.toString() })
        }

        onChange(value)
        setCurrentItem(index)
        setMenuParam({
            offsetLeft: offset,
            width: width,
        })
    }

    const handleGetUnderline = (offsetLeft: number, width: number) => {
        setMenuParam({ offsetLeft, width })
    }

    const NavbarBody = useMemo(() => {
        return data.map(({ value, title, isActive }, index) => (
            <CustomTabNavbarItem
                key={`${value}_${title}_${index}`}
                value={value}
                title={title}
                index={index}
                onChange={handleOnClick}
                isActive={isActive}
                currentItem={currentItem}
                handleGetUnderline={handleGetUnderline}
            />
        ))
    }, [data, currentItem])

    useEffect(() => {
        if (data) {
            setCurrentItem(data.findIndex(({ isActive }) => isActive))
        }
    }, [data])

    useEffect(() => {
        if (underlineRef.current) {
            underlineRef.current.style.left = `${menuParam.offsetLeft}px`
            underlineRef.current.style.width = `${menuParam.width}px`
        }
    }, [menuParam])

    useEffect(() => {
        if (withSearchParams) {
            const initialTab = searchParams.get('tab')
            const tabIndex = data.findIndex((item) => item.value == initialTab)

            if (tabIndex >= 0) {
                setCurrentItem(tabIndex)
                onChange(initialTab)
            } else {
                setSearchParams({ tab: data[0].value.toString() })
            }
        }
    }, [])

    return (
        <div className={clsx('custom-tab-navbar', className)}>
            <nav>
                <div className='custom-tab-navbar__underline' ref={underlineRef} />
                {NavbarBody}
            </nav>
        </div>
    )
}
