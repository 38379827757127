import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { Control, Controller, FieldValues, useFieldArray, UseFormSetValue } from 'react-hook-form'
import '../../../../fields/CustomFieldArray/CustomFieldArray.scss'
import CustomInput from '../../../../inputs/CustomInput/CustomInput'
import CustomSelect from '../../../../inputs/CustomSelect/CustomSelect'
import Index from '../../../../ReusingComponents/CustomDate/CustomDate'
import { InputParamsItem, InputTemplateType } from '~/hooks/useFormConstructor/useFormConstructor.types'

interface CustomFieldArrayPatentProps {
    items: InputParamsItem<any>[] | Record<string, InputParamsItem<any>[]> | InputTemplateType,
    name: string,
    control: Control,
    setValue: UseFormSetValue<FieldValues>,
    disabled: boolean,
}

const CustomFieldArrayPatent = (props: CustomFieldArrayPatentProps) => {
    const {
        items,
        name,
        control,
        setValue,
        disabled,
    } = props
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
    })

    useEffect(() => {
        if (!fields || fields.length === 0) {
            setValue(name, [Object.values(items).reduce((accum, item) => ({ ...accum, [item.name]: '' }), {})])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields])

    return (
        <div className='customFieldArray'>
            <div className='customFieldArray__body'>
                {fields.map((field, index) => (
                    <div className='customFieldArray__row' key={field.id}>
                        {Object.values(items).map((item) => (
                            <Controller
                                key={`${name}_${index}_${item.name}`}
                                name={`${name}.${index}.${item.name}`}
                                control={control}
                                rules={{ required: item.required }}
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    switch (item.type) {
                                        case 'select':
                                            return (
                                                <CustomSelect
                                                    {...item}
                                                    className={'formSection__inputSelect'}
                                                    error={error}
                                                    onChange={onChange}
                                                    value={value}
                                                    disabled={disabled}
                                                />
                                            )
                                        case 'text':
                                            return (
                                                <CustomInput
                                                    {...item}
                                                    className='formSection__inputText'
                                                    error={error}
                                                    value={value}
                                                    onChange={onChange}
                                                    disabled={disabled}
                                                />
                                            )
                                        case 'muiDate':
                                            return <Index value={value} onChange={onChange} title={item.title} />
                                        default:
                                            return <div></div>
                                    }
                                }}
                            />
                        ))}
                        {!disabled && (
                            <>
                                {index !== 0 ? (
                                    <button
                                        className='customFieldArray__button customFieldArray__delete'
                                        type={'button'}
                                        onClick={() => remove(index)}
                                    >
                                        <DeleteIcon />
                                        <span>Удалить</span>
                                    </button>
                                ) : (
                                    <button
                                        className='customFieldArray__button'
                                        type='button'
                                        onClick={() =>
                                            append(
                                                Object.values(items).reduce(
                                                    (accum, item) => ({
                                                        ...accum,
                                                        [item.name]: '',
                                                    }),
                                                    {},
                                                ))
                                        }
                                    >
                                        <AddIcon />
                                        <span>Добавить</span>
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CustomFieldArrayPatent
