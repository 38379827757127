import clsx from 'clsx'
import { ReactNode, useEffect, useRef } from 'react'

interface IphoneScrollProps {
    className?: string
    children?: ReactNode
}

const IphoneScroll = (props: IphoneScrollProps) => {
    const {
        className,
        children,
    } = props
    const container = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const containerNode = container.current

        if (!containerNode) {
            return
        }

        containerNode.addEventListener('touchstart', isolateTouch, { passive: true })
        containerNode.addEventListener('touchmove', isolateTouch, { passive: true })
        containerNode.addEventListener('touchend', isolateTouch, { passive: true })

        return () => {
            const containerNode = container.current

            if (!containerNode) {
                return
            }

            containerNode.removeEventListener('touchstart', isolateTouch)
            containerNode.removeEventListener('touchmove', isolateTouch)
            containerNode.removeEventListener('touchend', isolateTouch)
        }
    }, [])

    const isolateTouch = (e: any) => {
        e.stopPropagation()
    }

    return (
        <div
            ref={container}
            style={{ overflowX: 'auto', width: '100%' }}
            className={clsx(className)}
        >
            {children}
        </div>
    )
}

export default IphoneScroll
