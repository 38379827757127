import { InputNameEnum } from '../../enum/InputNameEnum'

export const inputsWithDifferentName = (sectionKey: string, inputName: string) => {
    switch (inputName) {
        case InputNameEnum.stageName:
            return inputName
        default:
            return `${sectionKey}.${inputName}`
    }
}
