import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Popover } from '@mui/material'
import clsx from 'clsx'
import Fuse from 'fuse.js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CustomCheckbox from '../../checkbox/CustomCheckbox/CustomCheckbox'
import CustomSearchInput, {
    CustomSearchInputThemes,
} from '~/components/common/inputs/CustomSearchInput/CustomSearchInput'
import { InputParamsItem, InputTemplateType } from '~/hooks/useFormConstructor/useFormConstructor.types'
import './CustomMultipleSelector.scss'

export enum CustomMultipleSelectorThemes {
    COLOR = 'color',
    GREY = 'grey',
}

interface CustomMultipleSelectorProps {
    placeholder?: string,
    name: string,
    items?: InputParamsItem<any>[] | Record<string, InputParamsItem<any>[]> | InputTemplateType,
    values: any[],
    onChange: (value: any[]) => void,
    title?: string,
    className?: string,
    disabled?: boolean,
    error?: any,
    initialValues?: any,
    theme?: CustomMultipleSelectorThemes
    withSearch?: boolean
}

const CustomMultipleSelector = (props: CustomMultipleSelectorProps) => {
    const {
        placeholder,
        name,
        items = [],
        values,
        onChange,
        title,
        className,
        disabled,
        error,
        theme = CustomMultipleSelectorThemes.COLOR,
        withSearch,
    } = props

    const ref = useRef<HTMLDivElement>(null)

    const [active, setActive] = useState(false)
    const [searchText, setSearchText] = useState<string>('')
    const [searchResult, setSearchResult] = useState<any>(items)
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const selectorRefForWidth = useRef<HTMLDivElement>(null)
    const [blockWidth, setBlockWidth] = useState<number | null>(null)

    const fuse = new Fuse(items as any, {
        keys: [
            'text',
            'value',
        ],
    })

    const handleSetSearch = useCallback((value: string) => {
        setSearchText(value)
        ref?.current?.scroll(0, 0)
    }, [])

    const handleCheckbox = (e: any) => {
        const newValues = [...values]
        if (e.target.checked) {
            newValues.push((items as InputParamsItem<any>[]).find((item) => item.value === e.target.name)?.value)
        } else {
            const pos = newValues.findIndex((value) => value === e.target.name)
            if (pos !== -1) {
                newValues.splice(pos, 1)
            }
        }
        onChange(newValues)
    }

    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!disabled) {
            if (!active) {
                setAnchorEl(event.currentTarget)
                setActive(true)
            } else {
                handleClose()
            }
        }
    }

    const handleClose = () => {
        setActive(false)
        setAnchorEl(null)
    }

    useEffect(() => {
        if (selectorRefForWidth.current) {
            setBlockWidth(selectorRefForWidth.current.offsetWidth)
        }
    }, [active])

    useEffect(() => {
        if (searchText === '') {
            setSearchResult(items)
        }
        if (searchText) {
            const res = fuse.search(searchText)
            setSearchResult(res.map((r) => r.item))
        }
    }, [searchText, items])

    return (
        <div className={clsx('customMultipleSelector', className, { disabled, error })}>
            {title && <span>{title}</span>}
            <div
                className={clsx('customMultipleSelector__selector', theme && `customMultipleSelector__selector--${theme}`)}
                onClick={onClick}
                ref={selectorRefForWidth}
            >
                {!values || values.length === 0 ? (
                    <span>{placeholder}</span>
                ) : (
                    <span className='customMultipleSelector__value'>
                        {
                            values
                                .filter((value) => Object.values(items).find((item) => item.value === value))
                                .map((value) => Object.values(items).find((item) => item.value === value)?.text).join(', ')
                        }
                    </span>
                )}
                <ExpandMoreIcon className={clsx('customMultipleSelector__expand', { active })} />
            </div>
            <Popover
                className='customSelect__popover'
                open={active}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className='customMultipleSelector__popupItems'
                    style={{ width: blockWidth ? `${blockWidth}px` : 'auto' }}
                >
                    {withSearch && (
                        <CustomSearchInput
                            onChange={handleSetSearch}
                            placeholder='Поиск контрагента'
                            className='customMultipleSelector__search'
                            theme={CustomSearchInputThemes.GREY}
                        />
                    )}
                    <div
                        className='customMultipleSelector__items'
                        ref={ref}
                    >
                        {Object.values(searchResult).map((item: any) => (
                            <CustomCheckbox
                                key={`${name}_${item.value}`}
                                id={`${name}_${item.value}`}
                                onChange={handleCheckbox}
                                value={values && values.some((value) => value === item.value)}
                                caption={item.text}
                                name={item.value}
                            />
                        ))}
                    </div>
                    <button
                        className='button customMultipleSelector__button'
                        onClick={() => {
                            onChange([])
                        }}
                        type={'button'}
                        disabled={!values || values.length === 0}
                    >
                        Сбросить
                    </button>
                </div>
            </Popover>
        </div>
    )
}

export default CustomMultipleSelector
