import { createContext, ReactElement } from 'react'
import {
    FieldValues,
    UseFieldArrayAppend,
    UseFieldArrayMove,
    UseFieldArrayRemove,
    UseFieldArrayUpdate,
} from 'react-hook-form'
import {
    CustomCellType,
    OnSubscriptionFieldChange,
    TabsTableCustomHeaderPopover,
    TabsTableHeaderPopover,
    TabsTableHeaderSection,
    TabsTableHeaderSingleSection,
    TabsTablePageType,
    TabsTableTemplate,
} from './tabsTable.types'
import {
    CheckTypes,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableHeader/subComponents/TabsTableCheckIcon/TabsTableCheckIcon'
import { TabsTableMods } from '~/components/common/ReusingComponents/TabsTable/TabsTable'

interface TabsTableContextType {
    baseName: string
    activeTabIndex: number
    setActiveTabIndex: (value: (((prevState: number) => number) | number)) => void
    pages: TabsTablePageType<any>[]
    appendPage?: UseFieldArrayAppend<FieldValues, string>
    removePage?: UseFieldArrayRemove
    movePage?: UseFieldArrayMove
    updatePage?: UseFieldArrayUpdate<FieldValues, string>
    updateRow?: UseFieldArrayUpdate<FieldValues, string>
    headerSections: TabsTableHeaderSection[] | TabsTableHeaderSingleSection[]
    onRowChange?: () => void
    onSubscriptionFieldChange?: OnSubscriptionFieldChange
    withToolbar?: boolean
    disabled?: boolean
    disabledHeader?: boolean
    isLoading?: boolean
    customTools?: ReactElement
    customCell?: CustomCellType[]
    withAddButton?: boolean
    templates?: TabsTableTemplate[]
    onTemplateLoad?: (pageIndex: number) => void
    isStrictTemplateSetting?: boolean
    headerPopover?: TabsTableHeaderPopover
    getCustomPopoverOptions?: (popoverIndex: number, closePopover: () => void) => TabsTableCustomHeaderPopover[]
    checkIcons?: {
        inputName: string
        checkType: CheckTypes
    },
    usingData?: string
    tabsTableMode?: TabsTableMods
}

export const TabsTableContext = createContext<TabsTableContextType>({
    baseName: '',
    activeTabIndex: 0,
    pages: [],
    headerSections: [],
    setActiveTabIndex: () => {
    },
    onRowChange: () => {
    },
    onSubscriptionFieldChange: (index: number, name: string, value: any) => {
    },
})
