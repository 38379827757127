import { klona } from 'klona/full'
import { dateForStages } from './orderTimeManager'
import {
    buildEvents,
    buildOrderClosing,
    buildPrimaryInformation,
    buildReportInfo,
    updateLastArrayElement,
} from './orderUtil'
import { diffHours, fetchDate, fetchDateToUTC, fetchLocalDate } from '../util/currentTime'
import eventEmitter from '../util/eventEmmiter'
import { deleteFile } from '~/API/fileApi/file'
import { DocType } from '~/API/fileApi/file.types'
import { useLazyFetchOrderQuery, useUpdateBitrixDealMutation } from '~/API/orderApi/order'
import { StartingSmeta } from '~/API/orderApi/types/starting.type'
import DocumentManager from '~/components/common/ReusingComponents/DocumentManager/DocumentManager'
import { KpService, PaymentType, Tasks } from '~/components/pages/KP/kp.types'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import { NzFullValues } from '~/shared/templates/mainTemplates/nzFull'
import {
    createNZFile,
    getAmortizationWithTotal,
    getInventoryWithTotal,
    mergeTools,
} from '~/shared/templates/utils/createNzFile'

const clearCleaningDates = (order: any, stageName: StageType | string) => {
    const newOrder = klona(order)
    switch (stageName) {
        case 'COMMERCIAL_OFFER_APPROVING': {
            if (newOrder?.agreement?.cleaningDate) newOrder.agreement.cleaningDate = null
            if (newOrder?.starting?.cleaningDate) newOrder.starting.cleaningDate = null
            break
        }
        case 'CONTRACT_SIGNING': {
            if (newOrder?.starting?.cleaningDate) newOrder.starting.cleaningDate = null
            break
        }
    }
    return newOrder
}

export const handleOrderSubmit = async (params: {
    order: any
    id?: string
    user: any
    formAction: any
    updateBitrixDeal: ReturnType<typeof useUpdateBitrixDealMutation>[0]
    fetchOrder: ReturnType<typeof useLazyFetchOrderQuery>[0]
    paymentType?: PaymentType
}) => {
    const {
        order,
        id,
        user,
        formAction,
        updateBitrixDeal,
        fetchOrder,
        paymentType,
    } = params
    if (id) {
        const oldOrder = await fetchOrder(id).unwrap()
        const events = oldOrder.events || []

        // TODO Довести до ума
        // if (availableTransition&&StageManager.availableTransitionStageToStage[switchStageName(oldOrder.stageName)].includes(formAction.stageName)) {
        //     return "TRANSITION_STAGE_ERROR";
        // }
        // if (!valid && !isEqual(oldOrder, activeOrder)) {
        //     return 'EDIT_WARNING_ERROR'
        // }
        if (
            formAction.action === 'INFO_TO_FOREMAN' &&
            oldOrder.stageName !== 'WAITING_CLIENT_CONFIRMATION' &&
            oldOrder.stageName !== 'INFO_TO_FOREMAN'
        ) {
            return 'INFO_TO_FOREMAN_ERROR'
        }
        let editDocument
        let saveDocument
        let deleteDocument
        let newOrder

        if (order.events && oldOrder.events && order.events.length !== oldOrder.events.length) {
            throw new Error('Заявка была изменена другим пользователем, обновите страницу и повторите снова')
        }
        switch (formAction.action as StageType | string) {
            case 'SAVE_FORM':
                newOrder = {
                    ...order,
                    primaryInformation: buildPrimaryInformation(order, user),
                    startDate: order.startDate || fetchDate().toDate(),
                    endDate: order.endDate || fetchDate().add(1, 'hour').toDate(),
                    stageName: order.stageName || 'ORDER_CREATING',
                }

                return newOrder
            case 'INSPECTION_PAYMENT_APPROVING':
            case 'INSPECTION_APPROVING':
                return {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    businessType: order.inspection.division,
                    ...dateForStages(formAction.action, order),
                }
            case 'INSPECTION_STARTING':
                return {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    businessType: order.inspection.division,
                    inspection: {
                        ...order.inspection,
                        inspectionDuration: {
                            startDate: fetchLocalDate().toDate(),
                        }, // add start date
                    },
                    ...dateForStages(formAction.action, order),
                }
            case 'INSPECTION_CONTENT_UPLOADING':
                return {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    businessType: order.inspection.division,
                    inspection: {
                        ...order.inspection,
                        inspectionDuration: {
                            ...order.inspection.inspectionDuration,
                            endDate: fetchLocalDate().toDate(),
                        }, // add end date
                    },
                    ...dateForStages(formAction.action, order),
                }
            case 'INSPECTION_DIVISION_APPROVING':
                return {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    businessType: order.inspection.division,
                    inspection: {
                        ...order.inspection,
                        inspectionCost: order.inspection.inspectionType === 'Бесплатный' ? null : order.inspection.inspectionCost,
                    },
                    ...dateForStages(formAction.action, order),
                }
            case 'COMMERCIAL_OFFER_APPROVING':
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    businessType: order.primaryInformation.division,
                    accountingAndSelling: {
                        ...order?.accountingAndSelling,
                        division: order?.primaryInformation?.division,
                        responsibleUserId: order?.primaryInformation?.orderCreator,
                    },
                    agreement: {
                        ...order?.agreement,
                        division: order?.primaryInformation?.division,
                    },
                    ...dateForStages(formAction.action, order),
                }

                if (oldOrder.stageName === 'ORDER_CREATING' || oldOrder.stageName === null) {
                    newOrder = {
                        ...newOrder,
                        inspection: null,
                    }
                }

                if (newOrder.documents?.inspection?.kpo) {
                    newOrder = {
                        ...newOrder,
                        inspection: {
                            ...order.inspection,
                            reportDate: fetchLocalDate().toDate(), // add close section date
                        },
                    }
                }
                return newOrder
            case 'ORDER_APPROVING':
                newOrder = {
                    ...order,
                    // todo remove on server
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    businessType: order?.starting?.division,
                    accountingAndSelling: {
                        ...order?.accountingAndSelling,
                        division: order?.starting?.division,
                    },
                    agreement: {
                        ...order.agreement,
                        cleaningDate: order.accountingAndSelling?.cleaningDate,
                    },
                    stageName: formAction.action,
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action, order),
                }

                return newOrder
            case 'DATE_BOOKING':
                newOrder = {
                    ...order,
                    // todo remove on server
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    businessType: order?.starting?.division,
                    accountingAndSelling: {
                        ...order?.accountingAndSelling,
                        division: order?.starting?.division,
                        isDateBooked: true,
                    },
                    stageName: formAction.action,
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action, order),
                }

                return newOrder
            case 'ORDER_MANAGER_INFORMING':
                newOrder = {
                    ...order,
                    // todo remove on server
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    stageName: formAction.action,
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action, order),
                }

                return newOrder
            case 'TO_OFFICE_MANAGER_CONTRACT_CREATING':
                newOrder = {
                    ...order,
                    // todo remove on server
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    businessType: order?.agreement?.division || order.businessType,
                    stageName: formAction.action,
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action, order),
                }

                return newOrder
            case 'WORKING_PLAN_APPROVING':
                if (order.stageName === 'MAINTENANCE_FINAL_REPORT') {
                    return {
                        ...order,
                        // todo remove on server
                        selling: {
                            cleaningDate: {
                                startDate: fetchLocalDate().toDate(),
                                endDate: fetchLocalDate().toDate(),
                            },
                        },
                        stageName: formAction.action,
                        events: buildEvents(events, order, user),
                        ...dateForStages(formAction.action, order),
                    }
                }

                newOrder = {
                    ...order,
                    // todo remove on server
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    stageName: formAction.action,
                    businessType: order.starting.division || order.businessType,
                    agreement: {
                        ...order.agreement,
                        responsibleUserId: user?.id,
                        reportDate: fetchLocalDate().toDate(),
                    },
                    starting: {
                        ...order?.starting,
                        comment: order.primaryInformation.comment,
                        cleaningDate: order.agreement.cleaningDate,
                        workerType: order?.starting?.workerType || 'Самостоятельно',
                    },
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action, order),
                }

                if (order.primaryInformation.clientType !== 'Повтор') {
                    newOrder = {
                        ...newOrder,
                        starting: {
                            ...newOrder.starting,
                            division: order?.agreement?.division,
                        },
                    }
                }

                if (!order?.documents?.agreement?.contract) {
                    newOrder = {
                        ...newOrder,
                        accountingAndSelling: {
                            ...newOrder.accountingAndSelling,
                            reportDate: fetchLocalDate().toDate(),
                        },
                    }
                }

                return newOrder
            case 'CLEANING_DATE_CHANGING': {
                newOrder = {
                    ...order,
                    nextStageName: null,
                    stageName: 'WORKING_PLAN_APPROVING',
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action, order),
                    starting: {
                        ...order.starting,
                        cleaningDate: order.temp.cleaningDate,
                    },
                    agreement: {
                        ...order.agreement,
                        cleaningDate: order.temp.cleaningDate,
                    },
                }

                const idsDocuments = await DocumentManager.handleContractStage({
                    creator: user.id,
                    order: {
                        ...newOrder,
                    },
                    paymentType,
                })

                return {
                    ...newOrder,
                    temp: undefined,
                    documents: {
                        ...(newOrder.documents || {}),
                        agreement: {
                            ...(newOrder.documents?.agreement || {}),
                            contract: idsDocuments?.contract,
                            act: idsDocuments?.act,
                        },
                    },
                }
            }
            /** DOCUMENTS GENERATORS */

            /** CONTRACT_SIGNING STAGES */
            case 'CONTRACT_SIGNING':
                newOrder = {
                    ...order,
                    // todo remove on server
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    businessType: order?.starting?.division || order?.accountingAndSelling?.division || order.businessType,
                    accountingAndSelling: {
                        ...order.accountingAndSelling,
                        reportDate: fetchLocalDate().toDate(),
                    },
                    agreement: {
                        ...order.agreement,
                        cleaningDate: order.accountingAndSelling?.cleaningDate,
                        division: order?.starting?.division || order?.accountingAndSelling?.division,
                        responsibleUserId: order.primaryInformation.orderCreator,
                    },
                    stageName: formAction.action,
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action, order),
                }

                return newOrder
            case 'CONTRACT_SIGNING_INDIVIDUAL':
                newOrder = {
                    ...order,
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    accountingAndSelling: {
                        ...order?.accountingAndSelling,
                        reportDate: fetchLocalDate().toDate(),
                    },
                    agreement: {
                        ...order?.agreement,
                        division: order?.starting?.division || order?.accountingAndSelling?.division,
                        responsibleUserId: order?.primaryInformation?.orderCreator,
                        contractType: 'Разовые работы',
                    },
                    events: buildEvents(events, order, user),
                    stageName: 'CONTRACT_SIGNING',
                    nextStageName: null,
                    ...dateForStages(formAction.action, order),
                }

                const idsDocuments = await DocumentManager.handleContractStage({
                    creator: user.id,
                    order: {
                        ...newOrder,
                    },
                    paymentType,
                })

                return {
                    ...newOrder,
                    documents: {
                        ...(newOrder.documents || {}),
                        agreement: {
                            ...(newOrder.documents?.agreement || {}),
                            contract: idsDocuments?.contract,
                            act: idsDocuments?.act,
                        },
                    },
                }
            case 'CONTRACT_SIGNING_BILL': {
                let contract = ''
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: 'CONTRACT_SIGNING',
                    nextStageName: null,
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    agreement: {
                        ...order?.agreement,
                        division: order?.starting?.division || order?.agreement?.division,
                        responsibleUserId: order?.primaryInformation?.orderCreator,
                    },
                    ...dateForStages(formAction.action, order),
                }

                switch (order?.agreement.contractType) {
                    case 'Разовые работы': {
                        const contractObj = await DocumentManager.handleContractStage({
                            creator: user.id,
                            order: newOrder,
                            paymentType,
                        })
                        contract = contractObj?.contract

                        break
                    }
                    case 'Счет договор':
                        contract = await DocumentManager.saveContractBill({
                            creator: user.id,
                            order: {
                                ...newOrder,
                            },
                            paymentType,
                        })
                        break
                    default:
                        break
                }

                return {
                    ...newOrder,
                    documents: {
                        ...(newOrder.documents || {}),
                        agreement: {
                            ...(newOrder.documents?.agreement || {}),
                            contract,
                        },
                    },
                }
            }

            /** OTHER DOCUMENTS */
            case 'GENERATE_NZ': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: 'WORKING_PLAN_APPROVING',
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                }

                newOrder.documents.starting.nzFront = await createNZFile(newOrder, user)
                return newOrder
            }
            case 'GENERATE_NZ_RESULT': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: order.stageName,
                }

                if (order.stageName === 'INVENTORY_ITEMS_RETURNING') {
                    saveDocument = await DocumentManager.saveDocument({
                        title: 'Наряд заказ',
                        creator: user.id,
                        type: order.foremanReport.daysReport.length > 1 ? 'nzBackMultipleDay' : 'nzBack',
                        values: {
                            serviceType: order.primaryInformation.serviceType,
                            ...newOrder.foremanReport,
                            ...newOrder.toolsReport,
                        },
                    })

                    return {
                        ...newOrder,
                        documents: {
                            ...newOrder.documents,
                            toolsReport: {
                                ...newOrder.documents.toolsReport,
                                nzBack: saveDocument,
                            },
                        },
                    }
                }

                if (order.stageName === 'MANAGER_CHECKING') {
                    const nzFrontValues = await createNZFile(newOrder, user, true)

                    saveDocument = await DocumentManager.saveDocument({
                        title: 'Наряд заказ',
                        creator: user.id,
                        type: 'nzFull',
                        values: {
                            nzFrontValues,
                            isMultipleDays: order.foremanReport.daysReport.length > 1,
                            nzBackValues: {
                                serviceType: order.primaryInformation.serviceType,
                                ...newOrder.foremanReport,
                                ...newOrder.toolsReport,
                                orderManager: order.starting.responsibleUserId,
                            },
                        } as NzFullValues,
                    })

                    return {
                        ...newOrder,
                        documents: {
                            ...newOrder.documents,
                            orderManagerReport: {
                                ...newOrder.documents.orderManagerReport,
                                nzFull: saveDocument,
                            },
                        },
                    }
                }
                break
            }
            case 'CHANGE_NZ':
                newOrder = {
                    ...order,
                    starting: {
                        ...order.starting,
                        responsibleManager: user.id,
                    },
                    documents: {
                        ...(order.documents || []),
                        starting: { ...(order.documents?.starting || []), nzFront: null },
                    },
                    events: buildEvents(events, order, user),
                    stageName: 'WORKING_PLAN_APPROVING',
                    nextStageName: null,
                    ...dateForStages(formAction.action),
                }
                if (order.documents?.starting?.nzFront) {
                    // Пока пусть будет, а так оставить на какой-нибудь сборщик
                    deleteDocument = await deleteFile(order.documents.starting.nzFront)
                }
                return newOrder
            case 'GENERATE_CONTRACT':
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: 'TO_OFFICE_MANAGER_CONTRACT_CREATING',
                    nextStageName: null,
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    agreement: {
                        ...order?.agreement,
                        division: order?.starting?.division || order?.accountingAndSelling?.division,
                        responsibleUserId: order?.primaryInformation?.orderCreator,
                    },
                    ...dateForStages(formAction.action, order),
                }

                let contract

                switch (order?.agreement.contractType) {
                    case 'Разовые работы': {
                        contract = await DocumentManager.handleContractStage({
                            creator: user.id,
                            order: newOrder,
                            paymentType,
                            type: 'contractB2B',
                        })
                        break
                    }
                    case 'Обслуживание': {
                        contract = await DocumentManager.handleContractStage({
                            creator: user.id,
                            order: newOrder,
                            paymentType,
                            type: 'contractMaintenance',
                            docType: DocType.DOCX,
                        })
                        break
                    }
                    case 'Счет договор':
                        contract = await DocumentManager.saveContractBill({
                            creator: user.id,
                            order,
                            paymentType,
                        })
                        break
                    default:
                        break
                }

                return {
                    ...newOrder,
                    documents: {
                        ...(newOrder.documents || {}),
                        agreement: {
                            ...(newOrder.documents?.agreement || {}),
                            contract: contract?.contract || contract,
                            act: contract?.act || null,
                        },
                    },
                }
            case 'PREPAYMENT_APPROVING':
                newOrder = {
                    ...order,
                    // todo remove on server
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    businessType: order.agreement.division || order.businessType,
                    stageName: formAction.action,
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action, order),
                }

                return newOrder

            case 'DATE_AND_TIME_CHANGING':
            case 'NEED_CHANGES_TO_CONTRACT_SIGNING':
            case 'NEED_CHANGES_TO_CONTRACT_SIGNING_WITH_DATE_CHANGING':
                newOrder = {
                    ...order,
                    // todo remove on server
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    stageName: 'NEED_CHANGES_TO_CONTRACT_SIGNING',
                    events: buildEvents(events, order, user),
                    // ...dateForStages(formAction.action, order),
                }

                newOrder = clearCleaningDates(newOrder, 'CONTRACT_SIGNING')

                return newOrder
            case 'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING':
                newOrder = {
                    ...order,
                    // todo remove on server
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    stageName: 'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING',
                    events: buildEvents(events, order, user),
                    // ...dateForStages(formAction.action, order),
                }

                newOrder = clearCleaningDates(newOrder, 'COMMERCIAL_OFFER_APPROVING')

                return newOrder
            case 'SEND_TO_MANAGER': {
                newOrder = {
                    ...order,
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    stageName: 'NEED_CHANGES_TO_WORKING_PLAN_APPROVING',
                    events: buildEvents(events, order, user),
                }

                return newOrder
            }
            case 'NEED_CHANGES_TO_CONTRACT_WORK_REPORT_FILLING': {
                newOrder = {
                    ...order,
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    stageName: 'CONTRACT_WORK_REPORT_FILLING',
                    events: buildEvents(events, order, user),
                }

                return newOrder
            }
            case 'NEED_CHANGES_TO_WORKING_PLAN_APPROVING':
                newOrder = {
                    ...order,
                    // todo remove on server
                    selling: {
                        cleaningDate: {
                            startDate: fetchLocalDate().toDate(),
                            endDate: fetchLocalDate().toDate(),
                        },
                    },
                    stageName: formAction.action,
                    events: buildEvents(events, order, user),
                }

                return newOrder
            case 'NEED_CHANGES_TO_INSPECTION_DIVISION_APPROVING': {
                newOrder = {
                    ...order,
                    stageName: formAction.action,
                    events: buildEvents(events, order, user),
                    inspection: {
                        ...order.inspection,
                        reportDate: null,
                    },
                }

                return newOrder
            }
            case 'MAINTENANCE_FINAL_REPORT': {
                if (!order.starting.startingSmeta ||
                    order.starting.startingSmeta.some((item: StartingSmeta) => !item.isConfirmed)) {
                    throw new Error('Заполните и подтвердите смету обслуживания')
                }

                newOrder = {
                    ...order,
                    starting: {
                        ...order.starting,
                        reportDate: fetchLocalDate().toDate(),
                        responsibleManager: user.id,
                        responsibleUserId: user?.id,
                    },
                    stageName: formAction.action,
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action),
                }

                return newOrder
            }
            /** Old constructor stages */
            case 'APPROVE_CLEANING':
                newOrder = {
                    ...order,
                    starting: {
                        ...order.starting,
                        responsibleManager: user.id,
                    },
                    documents: {
                        ...(order.documents || []),
                        starting: { ...(order.documents?.starting || []), nzFront: null },
                    },
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action),
                }
                if (order.documents?.starting?.nzFront) {
                    // Пока пусть будет, а так оставить на какой-нибудь сборщик
                    deleteDocument = await deleteFile(newOrder.documents.starting.nzFront)
                }
                return newOrder
            case 'SERVICES_VERIFICATION': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    foremanReport: {
                        ...order.foremanReport,
                        reportDate: fetchDate().toDate(),
                        foreman: order.foremanReport?.foreman || user.id,
                    },
                    stageName: formAction.action,
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            }
            case 'CONTRACT_WORK_REPORT_FILLING': {
                if (!order.starting.contractor?.value && !order.starting.contractor?.contactInfo[0].clientFullname ||
                    !order.starting.contractor?.contactInfo?.[0].clientPhone) {
                    eventEmitter.emit('CLIENT_INFO_CONTRACTOR_VALIDATION_FAILED')
                    throw new Error('Заполните информацию о подрядчике')
                }

                newOrder = {
                    ...order,
                    starting: {
                        ...order.starting,
                        reportDate: fetchLocalDate().toDate(),
                        responsibleManager: user.id,
                        responsibleUserId: user?.id,
                    },
                    events: buildEvents(events, order, user),
                    contractWorkReport: {
                        ...order.contractWorkReport,
                        division: order.accountingAndSelling.division,
                        contractor: order.starting.contractor.value || order.starting.contractor.contactInfo[0].clientFullname,
                    },
                    orderManagerReport: {
                        division: order.accountingAndSelling.division,
                        responsibleManager: order.starting.responsibleUserId || user?.id,
                    },

                    stageName: formAction.action,
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            }
            case 'APPROVE_NZ':
            case 'DEPARTURE_CONFIRMATION':
                const services = order.accountingAndSelling.commercialOffer.services
                    .filter((service: any) => service.isFormed && service.isSelected)

                const inventoriesWithTotal = getInventoryWithTotal(services)
                const amortizationWithTotal = getAmortizationWithTotal(services)
                const mergedInventory = mergeTools(inventoriesWithTotal)
                const mergedAmortization = mergeTools(amortizationWithTotal)

                newOrder = {
                    ...order,
                    starting: {
                        ...order.starting,
                        reportDate: fetchLocalDate().toDate(),
                        responsibleManager: user.id,
                        responsibleUserId: user?.id,
                    },
                    events: buildEvents(events, order, user),
                    foremanReport: {
                        clientConfirmationInfo: [
                            {
                                date: fetchDateToUTC(order.selling.cleaningDate.startDate)
                                    .subtract(1, 'hour')
                                    .subtract(15, 'minute'),
                                result: '',
                            },
                        ],
                        cleaningTask: order.starting.comment,
                        actualServices: services.flatMap((service: KpService) => service.tasks),
                        division: order.accountingAndSelling.division,
                        foreman: order.starting.executor,
                    },
                    toolsReport: {
                        division: order.accountingAndSelling.division,
                        toolsManager: user.id,
                        actualTools: mergedInventory,
                        actualAmortization: mergedAmortization,
                    },
                    orderManagerReport: {
                        division: order.accountingAndSelling.division,
                        responsibleManager: order.starting.responsibleUserId || user?.id,
                    },

                    stageName: formAction.action,
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            case 'CLIENT_DECLINING': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                }
                return newOrder
            }
            case 'WAITING_CLIENT_CONFIRMATION':
                newOrder = {
                    ...order,
                    foremanReport: {
                        ...order.foremanReport,
                        clientConfirmationInfo: [
                            ...order.foremanReport.clientConfirmationInfo,
                            {
                                date: fetchDate().add(15, 'minute').toDate(),
                                result: '',
                            },
                        ],
                    },
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            case 'INFO_TO_FOREMAN':
                newOrder = {
                    ...order,
                    events: buildEvents(oldOrder.events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            case 'CLIENT_DECLINE':
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: 'WORKING_PLAN_APPROVING',
                    starting: {
                        ...order.starting,
                        cleaningApprovalInfo: [...order.starting.cleaningApprovalInfo, {
                            date: fetchDate().toDate(),
                            result: '',
                        }],
                    },
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            case 'CLIENT_CONFIRMATION':
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action, order),
                }
                return newOrder


            case 'ADD_SERVICES_RESPONSE':
            case 'ADDITIONAL_SERVICES_CREATING': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action, order),
                    documents: {
                        ...order.documents,
                        foremanReport: {
                            ...order.documents?.foremanReport,
                            additionalServicesPhotos: formAction.files,
                        },
                    },
                }
                return newOrder
            }
            case 'RESPONSIBLE_USER_CHANGING': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: 'OBJECT_REPORT_FILLING',
                    foremanReport: {
                        ...order.foremanReport,
                        daysReport: updateLastArrayElement(order.foremanReport.daysReport, { isForemanChanging: true }),
                    },
                }
                return newOrder
            }
            case 'NEED_CHANGES_TO_SERVICE_TABLE_FILLING': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: 'SERVICE_TABLE_FILLING',
                    foremanReport: {
                        ...order.foremanReport,
                        currentDayNumber: order.foremanReport.currentDayNumber - 1,
                        daysReport: updateLastArrayElement(order.foremanReport.daysReport.slice(0, -1), { endWorkingDate: undefined, reportDate: undefined }),
                    },
                    documents: {
                        ...order.documents,
                        foremanReport: {
                            ...order.documents?.foremanReport,
                            daysReport: order.documents?.foremanReport?.daysReport.slice(0, -1),
                        },
                    },
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            }
            case 'OBJECT_REPORT_FILLING':
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    foremanReport: {
                        ...order.foremanReport,
                        startWorkingDate: order.foremanReport?.startWorkingDate || fetchDate().toDate(),
                        currentDayNumber: order.foremanReport?.currentDayNumber ? order.foremanReport.currentDayNumber + 1 : 0,
                        daysReport: [
                            ...(order.foremanReport.daysReport || []),
                            {
                                foreman: order.foremanReport.foreman,
                                workingDate: order.starting.cleaningDate.startDate,
                                // actualObjectArea: order.primaryInformation.objectArea,
                                startWorkingDate: fetchDate().toDate(),
                                foremanCheckList: [
                                    ...order.foremanReport.actualServices.map((s: Tasks[number]) => ({
                                        type: s.type,
                                        title: s.title,
                                        items: s.items
                                            .filter((v: any) => v.status)
                                            .map((v: any) => ({
                                                ...v,
                                                status: false,
                                            })),
                                    })),
                                    order.foremanReport.additionalServices ? {
                                        type: 'Комната',
                                        title: 'Доп. услуги',
                                        items: order.foremanReport.additionalServices
                                            .filter((v: any) => v.status)
                                            .map((v: any) => ({
                                                ...v,
                                                status: false,
                                            })),
                                    } : null,
                                ].filter((e) => e?.items?.length > 0),
                            },
                        ],
                    },
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            case 'UPLOAD_CONTENT': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            }
            case 'SERVICE_TABLE_FILLING': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    foremanReport: {
                        ...order.foremanReport,
                        daysReport: [
                            ...updateLastArrayElement(order.foremanReport.daysReport, {
                                isForemanChanging: false,
                                startWorkingDate: fetchDate().toDate(),
                            }),
                        ],
                    },
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            }
            case 'WORKING_DAY_ENDING': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            }
            case 'WORK_STARTING':
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    foremanReport: {
                        ...order.foremanReport,
                        daysReport: [
                            ...updateLastArrayElement(order.foremanReport.daysReport, { startWorkingDate: fetchDate().toDate() }),
                        ],
                        startWorkingDate: fetchDate().toDate(),
                    },
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            case 'WORK_PROCESS':
                // TODO Такая логика должна быть в одном месте, скорее создать отдельную секцию, отслеживать изменения foremanChecklist и в зависимости от этого выдавать нужные действия
                const defStage =
                    order.foremanReport.lastWorkingDay &&
                    order.foremanReport.daysReport[order.foremanReport.currentDayNumber].foremanCheckList.every(
                        (group: Tasks[number]) => group.items.every((e) => e.status),
                    ) ?
                        'CLEANING_ENDED' :
                        'WORK_PROCESS'
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: defStage,
                    ...dateForStages(defStage),
                }
                return newOrder
            case 'WORK_ENDING':
            case 'TEAM_REPORT_FILLING': {
                // const nzSection = findCurrentNzPeriod(order.starting.nzTeam,
                //     order.foremanReport.daysReport[order.foremanReport.daysReport.length - 1].workingDate)

                newOrder = {
                    ...order,
                    foremanReport: {
                        ...order.foremanReport,
                        daysReport: [
                            ...updateLastArrayElement(order.foremanReport.daysReport, {
                                endWorkingDate: fetchDate().toDate(),
                                actualDurationCleaningTime: diffHours(
                                    fetchDate(order.foremanReport.daysReport.at(-1)?.startWorkingDate),
                                    fetchDate(),
                                ),
                                isForemanChanging: false,
                                cleaningDate: fetchLocalDate().hour(9).minute(0).second(0).toDate(),
                                endDate: fetchLocalDate().hour(18).minute(0).second(0).toDate(),
                            }),
                        ],
                    },
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action),
                }
                return newOrder
            }
            case 'START_NEW_DAY': {
                const newDayNumber = order.foremanReport.currentDayNumber + 1

                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: 'OBJECT_REPORT_FILLING',
                    foremanReport: {
                        ...order.foremanReport,
                        currentDayNumber: newDayNumber,
                        daysReport: [
                            ...updateLastArrayElement(order.foremanReport.daysReport, {
                                reportDate: fetchDate().toDate(),
                            }),
                        ],
                    },
                    ...dateForStages(formAction.action, order),
                }

                newOrder = {
                    ...newOrder,
                    foremanReport: {
                        ...newOrder.foremanReport,
                        daysReport: [
                            ...updateLastArrayElement([...newOrder.foremanReport.daysReport, {}], {
                                foreman: order.foremanReport.daysReport[order.foremanReport.currentDayNumber].foreman,
                                workingDate: fetchDateToUTC(order.starting.cleaningDate.startDate)
                                    .add(order.foremanReport.currentDayNumber, 'd'),
                                foremanCheckList:
                                    order.foremanReport.daysReport[order.foremanReport.currentDayNumber].foremanCheckList
                                        .map((day: Tasks[number]) => ({
                                            ...day,
                                            items: day.items.filter((v) => v.status === false),
                                        }))
                                        .filter((e: Tasks[number]) => e.items.length > 0),
                            }),
                        ],
                    },
                }
                return newOrder
            }
            case 'WAREHOUSE_GOING': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    foremanReport: {
                        ...order.foremanReport,
                        daysReport: [
                            ...updateLastArrayElement(order.foremanReport.daysReport, {
                                reportDate: fetchDate().toDate(),
                            }),
                        ],
                    },
                    ...dateForStages(formAction.action, order),
                }

                return newOrder
            }
            case 'MANAGER_CHECKING': {
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    toolsReport: {
                        ...order.toolsReport,
                        foreman: user.id,
                        reportDate: fetchDate().toDate(),
                    },
                    ...dateForStages(formAction.action, order),
                }

                return newOrder
            }
            case 'TO_ORDER_MANAGER_ORDER_CLOSING': {
                newOrder = {
                    ...order,
                    orderClosing: buildOrderClosing(
                        order.orderClosing,
                        'OPERATOR_ORDER_CLOSING',
                        user,
                        order.foremanReport.division,
                    ),
                    events: buildEvents(events, order, user),
                    stageName: 'DEFECTS_CORRECTING',
                    ...dateForStages(formAction.action),
                }
                return newOrder
            }
            case 'DEFECTS_CORRECTING': {
                const orderClosing = buildOrderClosing(
                    order.orderClosing,
                    'ORDER_MANAGER_ORDER_CLOSING',
                    user,
                    order?.foremanReport?.division || order?.contractWorkReport?.division,
                )

                if (order.orderClosing && !order.orderClosing[order.orderClosing.length - 1].reportDate) {
                    orderClosing[order.orderClosing.length - 1] = {
                        ...orderClosing[order.orderClosing.length - 1],
                        reportDate: fetchDate().toDate(),
                        responsibleManager: user.id,
                    }
                }

                if (order?.foremanReport?.division) {
                    let orderManagerReport = {
                        ...order.orderManagerReport,
                        isDefectCorrecting: true,
                    }
                    if (!order.orderManagerReport?.reportDate) {
                        orderManagerReport = {
                            ...order.orderManagerReport,
                            responsibleManager: user.id,
                            reportDate: fetchDate().toDate(),
                            isDefectCorrecting: true,
                        }
                    }

                    newOrder = {
                        ...order,
                        orderManagerReport,
                        orderClosing,
                        events: buildEvents(events, order, user),
                        stageName: formAction.action,
                        ...dateForStages(formAction.action),
                    }
                } else {
                    newOrder = {
                        ...order,
                        events: buildEvents(events, order, user),
                        orderClosing,
                        stageName: formAction.action,
                        ...dateForStages(formAction.action),
                    }
                }

                return newOrder
            }
            // case 'TO_QUALITY_CONTROL':
            case 'QUALITY_CONTROLLING_FINAL_REPORT':
            case 'QUALITY_CONTROLLING': {
                const orderClosing = buildOrderClosing(
                    order.orderClosing, 'QUALITY_CONTROL',
                    user,
                    order?.foremanReport?.division || order?.contractWorkReport?.division,
                )
                if (order.orderClosing && !order.orderClosing[order.orderClosing.length - 1].reportDate) {
                    orderClosing[order.orderClosing.length - 1] = {
                        ...orderClosing[order.orderClosing.length - 1],
                        reportDate: fetchDate().toDate(),
                        responsibleManager: user.id,
                    }
                }

                if (order?.foremanReport?.division) {
                    let orderManagerReport = order.orderManagerReport
                    if (!order.orderManagerReport?.reportDate) {
                        orderManagerReport = {
                            ...order.orderManagerReport,
                            responsibleManager: user.id,
                            reportDate: fetchDate().toDate(),
                        }
                    }

                    newOrder = {
                        ...order,
                        orderManagerReport,
                        orderClosing,
                        events: buildEvents(events, order, user),
                        stageName: 'QUALITY_CONTROLLING',
                        ...dateForStages(formAction.action),
                    }
                } else {
                    newOrder = {
                        ...order,
                        orderClosing: order.orderClosing && order.stageName === 'CONTRACT_WORK_REPORT_FILLING' ? order.orderClosing : orderClosing,
                        contractWorkReport: {
                            ...order.contractWorkReport,
                            responsibleUserId: order.contractWorkReport.responsibleUserId || user.id,
                            reportDate: order.contractWorkReport.reportDate || fetchDate().toDate(),
                        },
                        events: buildEvents(events, order, user),
                        stageName: 'QUALITY_CONTROLLING',
                        ...dateForStages(formAction.action),
                    }
                }

                return newOrder
            }
            case 'ACTUAL_PAYMENT_RESERVING': {
                newOrder = {
                    ...order,
                    returningDocuments: {
                        ...order.returningDocuments,
                        division: order.foremanReport.division || order.contractWorkReport.division,
                    },
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action),
                }

                return newOrder
            }
            case 'CONTRACTOR_BILLING': {
                const orderClosing = order?.orderClosing
                if (order?.orderClosing && !order?.orderClosing[order.orderClosing?.length - 1]?.reportDate) {
                    orderClosing[order.orderClosing.length - 1] = {
                        ...orderClosing[order.orderClosing.length - 1],
                        reportDate: fetchDate().toDate(),
                        responsibleManager: user.id,
                    }
                }

                newOrder = {
                    ...order,
                    orderClosing,
                    contractorAccounting: {
                        ...order.contractorAccounting,
                        division: order.accountingAndSelling.division,
                    },
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action),
                }

                return newOrder
            }
            case 'DOCUMENTS_RETURNING': {
                if (order?.foremanReport?.division) {
                    const orderClosing = order?.orderClosing
                    if (order?.orderClosing && !order?.orderClosing[order.orderClosing?.length - 1]?.reportDate) {
                        orderClosing[order.orderClosing.length - 1] = {
                            ...orderClosing[order.orderClosing.length - 1],
                            reportDate: fetchDate().toDate(),
                            responsibleManager: user.id,
                        }
                    }

                    newOrder = {
                        ...order,
                        orderManagerReport: {
                            ...order.orderManagerReport,
                            isDocumentReturning: true,
                        },
                        orderClosing,
                        returningDocuments: {
                            ...order.returningDocuments,
                            division: order.foremanReport.division,
                        },
                        events: buildEvents(events, order, user),
                        stageName: formAction.action,
                        ...dateForStages(formAction.action),
                    }
                } else {
                    newOrder = {
                        ...order,
                        contractorAccounting: {
                            ...order.contractorAccounting,
                            division: order.contractWorkReport.division,
                            responsibleManager: user.id,
                            reportDate: fetchDate(),
                        },
                        returningDocuments: {
                            ...order.returningDocuments,
                            division: order.contractWorkReport.division,
                        },
                        events: buildEvents(events, order, user),
                        stageName: formAction.action,
                        ...dateForStages(formAction.action),
                    }
                }

                return newOrder
            }
            case 'CONTRACT_PAYMENT_CONFIRMED': {
                newOrder = {
                    ...order,
                    contractorAccounting: {
                        ...order.contractorAccounting,
                        isPaymentConfirmed: true,
                    },
                    events: buildEvents(events, order, user),
                    stageName: order.stageName,
                    ...dateForStages(formAction.action),
                }
                return newOrder
            }
            case 'CONTRACT_DOCUMENTS_RETURNED': {
                newOrder = {
                    ...order,
                    contractorAccounting: {
                        ...order.contractorAccounting,
                        isDocumentsReturned: true,
                    },
                    events: buildEvents(events, order, user),
                    stageName: order.stageName,
                    ...dateForStages(formAction.action),
                }
                return newOrder
            }
            case 'PAYMENT_CONFIRMED': {
                const orderClosing = order?.orderClosing
                if (order?.orderClosing && !order?.orderClosing[order.orderClosing?.length - 1]?.reportDate) {
                    orderClosing[order.orderClosing.length - 1] = {
                        ...orderClosing[order.orderClosing.length - 1],
                        reportDate: fetchDate().toDate(),
                        responsibleManager: user.id,
                    }
                }

                newOrder = {
                    ...order,
                    returningDocuments: {
                        ...order.returningDocuments,
                        isPaymentConfirmed: true,
                        reportDate: fetchDate().toDate(),
                        responsibleManager: user.id,
                    },
                    orderClosing,
                    events: buildEvents(events, order, user),
                    stageName: 'FINAL_REPORT',
                    ...dateForStages(formAction.action),
                }
                return newOrder
            }
            case 'DOCUMENTS_RETURNED': {
                newOrder = {
                    ...order,
                    returningDocuments: {
                        ...order.returningDocuments,
                        isDocumentsReturned: true,
                    },
                    events: buildEvents(events, order, user),
                    stageName: order.stageName,
                    ...dateForStages(formAction.action),
                }
                return newOrder
            }
            case 'FINAL_REPORT_FROM_QUALITY_CONTROL':
            case 'FINAL_REPORT': {
                const orderClosing = order?.orderClosing
                if (order?.orderClosing && !order?.orderClosing[order.orderClosing?.length - 1]?.reportDate) {
                    orderClosing[order.orderClosing.length - 1] = {
                        ...orderClosing[order.orderClosing.length - 1],
                        reportDate: fetchDate().toDate(),
                        responsibleManager: user.id,
                    }
                }

                newOrder = {
                    ...order,
                    returningDocuments: {
                        ...order.returningDocuments,
                        reportDate: fetchDate().toDate(),
                        responsibleManager: user.id,
                    },
                    orderClosing,
                    events: buildEvents(events, order, user),
                    stageName: 'FINAL_REPORT',
                    ...dateForStages(formAction.action),
                }
                return newOrder
            }
            case 'INVENTORY_ITEMS_RETURNING':
            case 'ON_WAREHOUSE': {
                newOrder = {
                    ...order,
                    toolsReport: {
                        ...order.toolsReport,
                        onWarehouseDate: fetchDate().toDate(),
                    },
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action),
                }
                return newOrder
            }
            case 'REBUILD_NZ_BACK': {
                newOrder = {
                    ...order,
                    documents: {
                        ...order.documents,
                        toolsReport: null,
                    },
                    events: buildEvents(events, order, user),
                    foremanReport: {
                        ...order.foremanReport,
                        isNzRebuild: true,
                    },
                    stageName: 'ON_WAREHOUSE',
                    ...dateForStages(formAction.action),
                }
                deleteDocument = await deleteFile(order.documents.toolsReport.nzBack)
                return newOrder
            }
            case 'TOOLS_REPORT':
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action),
                }

                saveDocument = await DocumentManager.saveDocument({
                    title: 'Наряд заказ',
                    creator: user.id,
                    type: order.foremanReport.daysReport.length > 1 ? 'nzBackMultipleDay' : 'nzBack',
                    values: {
                        serviceType: order.primaryInformation.serviceType,
                        ...newOrder.foremanReport,
                        ...newOrder.toolsReport,
                    },
                })
                return {
                    ...newOrder,
                    documents: {
                        ...newOrder.documents,
                        toolsReport: {
                            ...newOrder.documents.toolsReport,
                            nzBack: saveDocument,
                        },
                    },
                }
            case 'TO_OPERATOR_ORDER_CLOSING':
                newOrder = {
                    ...order,
                    orderClosing: [
                        ...order.orderClosing?.slice(0, -1),
                        {
                            ...buildReportInfo(user, order.foremanReport.division),
                            ...order.orderClosing[order.orderClosing.length - 1],
                        },
                        {
                            sectionName: 'OPERATOR_ORDER_CLOSING',
                            sectionNumber: order.orderClosing.length,
                            conflictResolutionInfo: [
                                {
                                    date: fetchDate().toDate(),
                                    result: '',
                                },
                            ],
                        },
                    ],
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action),
                }
                return newOrder

            case 'ORDER_MANAGER_ORDER_CLOSING_INFO':
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: 'TO_ORDER_MANAGER_ORDER_CLOSING',
                    ...dateForStages(formAction.action),
                }
                return newOrder
            case 'TO_MISFITS_EDITING':
                newOrder = {
                    ...order,
                    orderClosing: buildOrderClosing(order.orderClosing, 'ORDER_MANAGER_ORDER_CLOSING', user, order.foremanReport.division),
                    events: buildEvents(events, order, user),
                    stageName: 'TO_ORDER_MANAGER_ORDER_CLOSING',
                    ...dateForStages(formAction.action),
                }
                return newOrder
            case 'CALLING_DATE_ORDER_CLOSING':
                newOrder = {
                    ...order,
                    orderClosing: [
                        ...order.orderClosing?.slice(0, -1),
                        {
                            ...buildReportInfo(user, order.foremanReport.division),
                            ...order.orderClosing[order.orderClosing.length - 1],
                            conflictResolutionInfo: [
                                ...order.orderClosing[order.orderClosing.length - 1].conflictResolutionInfo,
                                {
                                    date: fetchDate().add(1, 'h').toDate(),
                                    result: '',
                                },
                            ],
                        },
                    ],
                    events: buildEvents(events, order, user),
                    stageName: 'TO_OPERATOR_ORDER_CLOSING',
                    ...dateForStages(formAction.action),
                }
                return newOrder
            case 'TO_CHIEF_DECISION':
                newOrder = {
                    ...order,
                    orderClosing: buildOrderClosing(order.orderClosing, null, user, order.foremanReport.division),
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action),
                }
                return newOrder
            case 'RESERVE_CLEANING':
                newOrder = {
                    ...order,
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            case 'ORDER_CLOSED': {
                await updateBitrixDeal(oldOrder.id).unwrap()
                // TODO: костыль для каждой стадии
                const actualServices = order.foremanReport?.actualServices?.map((section: any) => ({
                    type: section.name || section.type,
                    title: section.title,
                    items: section.values || section.items,
                }))

                const additionalServices = !order.foremanReport?.additionalServices ? null : order.foremanReport.additionalServices.map((section: any) => ({
                    type: section.name || section.type,
                    title: section.title,
                    items: section.values || section.items,
                }))

                newOrder = {
                    ...order,
                    foremanReport: {
                        ...order.foremanReport,
                        actualServices,
                        additionalServices,
                    },
                    events: buildEvents(events, order, user),
                    stageName: formAction.action,
                    ...dateForStages(formAction.action, order),
                }
                return newOrder
            }
            case 'TO_ARCHIVE':
                newOrder = {
                    ...order,
                    archived: true,
                    archivedDate: new Date(),
                    message: formAction.reason,
                    commentType: 'archiveReason',
                }

                return newOrder
            case 'FROM_ARCHIVE':
                return 'FROM_ARCHIVE'
            case 'TO_ARCHIVE_FROM_ACTION':
                newOrder = {
                    ...order,
                    archived: true,
                }

                return newOrder

            default:
                newOrder = {
                    ...order,
                    primaryInformation: buildPrimaryInformation(order, user),
                    stageName: formAction.action,
                    nextStageName: null,
                    events: buildEvents(events, order, user),
                    ...dateForStages(formAction.action, order),
                }

                return newOrder
        }
    }
    return null
}
