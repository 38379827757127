import clsx from 'clsx'
import React from 'react'
import './StatusBar.scss'

export enum StatusBarThemes {
    WIDE = 'wide',
    THIN = 'thin',
}

interface StatusBarProps {
    percent?: number
    stage?: {
        current: number,
        amount: number,
    }
    theme?: StatusBarThemes
    className?: string
    title?: string
}

const StatusBar = (props: StatusBarProps) => {
    const {
        stage,
        percent = Number(stage?.current) / Number(stage?.amount) * 100,
        theme = StatusBarThemes.WIDE,
        className,
        title,
    } = props
    return (
        <div className={clsx('status-bar', theme && `status-bar--${theme}`, className)}>
            {theme === StatusBarThemes.THIN && (
                <div className='status-bar__stage'>
                    <span>{`${stage?.current}/${stage?.amount}`}</span>
                </div>
            )}
            {title && <div className='status-bar__title'>{title}</div>}
            <div className={clsx('status-bar__wrapper', theme && `status-bar__wrapper--${theme}`)}>
                <div
                    className={clsx('status-bar__underline', theme && `status-bar__underline--${theme}`)}
                    style={{ width: `${percent}%` }}
                >
                    {theme === StatusBarThemes.WIDE && (
                        <div className={clsx('status-bar__indicator', percent > 95 && 'status-bar__indicator--full')}>
                            {percent}%
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default StatusBar
