export default function range(start: number, end?: number, step: number = 1): number[] {
    // Если передан только один аргумент, то считаем его как конец, а старт будет 0
    if (end === undefined) {
        end = start
        start = 0
    }

    const result: number[] = []
    const increment = step > 0

    if (increment) {
        for (let i = start; i < end; i += step) {
            result.push(i)
        }
    } else {
        for (let i = start; i > end; i += step) {
            result.push(i)
        }
    }

    return result
}
