import { Dayjs } from 'dayjs'

// todo make generic type
export interface FileResponse {
    fileInfo: {
        fileName: string
        title: string
        inputName: string
        creator: string
        fileType: string
        createdAt?: string | Date | Dayjs
        type?: string
    }
    createdAt: string | Date | Dayjs
    contractNumber?: number
    inputName?: string
    creator?: string
    fileName?: string
    contractCost?: number
    cost?: number
    fileId?: string
}

export interface FileModel {
    fileInfo: {
        fileName: string,
        title: string,
        inputName?: string,
        creator: string,
        fileType: string
    }
    createdAt: string | Date | Dayjs
}

export interface UploadFileParams {
    file: File | Blob
    fileModel: FileModel
}

export interface FetchFileModelParams {
    fileId: string
}

export enum DocType {
    PDF = 'PDF',
    DOCX = 'DOCX',
}
