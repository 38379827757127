import { createContext } from 'react'

export enum themes {
    dark = '',
    light = 'white-content',
}

export const ThemeContext = createContext({
    theme: themes.dark,
    changeTheme: (theme: themes) => {
    },
})
