export const FINAL_REPORT_ACTIONS = {
    chiefDecisionAction: (disabled?: boolean) => ({
        disabled,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите действие',
        items: [
            {
                value: 'ORDER_CLOSED',
                text: 'Завершить заявку',
                icon: 'forward',
            },
            {
                value: 'DEFECTS_CORRECTING', // TO_ORDER_MANAGER_ORDER_CLOSING
                text: 'Действие для руководителя',
                icon: 'back',
            },
            {
                value: 'QUALITY_CONTROLLING_FINAL_REPORT',
                text: 'На контроль качества',
                icon: 'back',
            },
        ],
        type: 'action',
        version: 'v2',
    }),
}
