import React, { ErrorInfo, ReactNode, Suspense } from 'react'
import { connect } from 'react-redux'
import { sendError } from '~/API/notifierApi/notifier'
import { User } from '~/API/userApi/user.types'
import { ErrorPage, ErrorPageThemes } from '~/components/pages/ErrorPage'
import { RootState } from '~/redux/store'

interface ErrorBoundaryProps {
    children: ReactNode
    user: User | null
}

interface ErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        import.meta.env.MODE === 'production' && sendError({ text: `User email: ${this.props.user?.email}\n User fullname: ${this.props.user?.fullname}\n Message: ${error.message}\n File: ${errorInfo.componentStack}` })
        console.log(error)
    }

    render() {
        const { hasError } = this.state
        const { children } = this.props

        if (hasError) {
            // You can render any custom fallback UI
            return (
                <Suspense fallback=''>
                    <ErrorPage theme={ErrorPageThemes.ERROR} />
                </Suspense>
            )
        }

        return children
    }
}

const mapStateToProps = (state: RootState) => ({
    user: state.app.user,
})

export default connect(mapStateToProps)(ErrorBoundary)
