import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Popover } from '@mui/material'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import Fuse from 'fuse.js'
import React, { memo, useEffect, useRef, useState } from 'react'
import { FieldValues, UseFormClearErrors } from 'react-hook-form'
import TriangleIcon from './icons/triangleIcon.svg'
import { selectActionIcon } from '~/components/common/inputs/CustomFormActionInput/CustomFormActionInput'
import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import { InputParamsItem } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { StageData } from '~/redux/slices/ArchiveSlice/ArchiveSlice.types'
import { formatRUB } from '~/shared/util/formatNumberWithIntlOptions'
import './CustomSelect.scss'

export enum CustomSelectPresents {
    DIVISION = 'division'
}

export enum CustomSelectThemes {
    OUTLINE = 'outline',
    GREY = 'grey',
    TABLE = 'table',
    TABLE_FILTER = 'tableFilter',
}

interface ICustomSelect {
    title?: string
    items: InputParamsItem<any>[]
    name?: string
    value?: string | boolean | null
    className?: string
    innerClassName?: string
    onChange: (e: any) => void
    error?: any
    disabled?: boolean
    placeholder?: string
    icon?: any
    search?: boolean
    searchPlaceholder?: string
    inputName?: string
    clearErrors?: UseFormClearErrors<FieldValues>
    theme?: CustomSelectThemes
    preset?: CustomSelectPresents
    mask?: InputMask
}

export const SELECT_PRESETS: Record<string, InputParamsItem<any>[]> = {
    division: [
        {
            text: 'B2C',
            value: 'B2C',
        },
        {
            text: 'B2B',
            value: 'B2B',
        },
        {
            text: 'B2B (ППО)',
            value: 'B2B (ППО)',
        },
        {
            text: 'ORISCLEAN',
            value: 'ORISCLEAN',
        },
    ],
}

const setMask = (value: string | number, mask?: InputMask) => {
    switch (mask) {
        case InputMask.COST: {
            return formatRUB(Number(value || 0), false)
        }
        default: {
            return value
        }
    }
}

const CustomSelect = memo((props: ICustomSelect) => {
    const {
        title,
        items,
        name,
        value,
        className,
        innerClassName,
        onChange,
        error,
        disabled,
        placeholder,
        icon,
        search,
        searchPlaceholder = 'Поиск',
        inputName,
        clearErrors,
        theme = CustomSelectThemes.OUTLINE,
        preset,
        mask,
    } = props

    const ref = useRef<HTMLDivElement>(null)
    const selectorRefForWidth = useRef<HTMLDivElement>(null)

    const currentItems = preset ? SELECT_PRESETS[preset] : items

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const [active, setActive] = useState(false)
    const [searchQueue, setSearchQueue] = useState('')
    const [searchResult, setSearchResult] = useState<Array<StageData>>(currentItems)

    const fuse = new Fuse(currentItems, {
        keys: [
            'text',
            'value',
        ],
    })
    const [blockWidth, setBlockWidth] = useState<number | null>(null)

    useEffect(() => {
        if (selectorRefForWidth.current) {
            setBlockWidth(selectorRefForWidth.current.offsetWidth)
        }
    }, [active])

    useEffect(() => {
        if (searchQueue === '') {
            setSearchResult(currentItems)
        }
        if (searchQueue) {
            const res = fuse.search(searchQueue)
            setSearchResult(res.map((r) => r.item))
        }
    }, [searchQueue, items])

    const handleClick = (value: string | Date | Dayjs | null | boolean) => {
        handleClose()
        inputName && clearErrors && clearErrors(inputName)
        onChange && onChange(value)
    }

    const handleSetSearchQueue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQueue(e.target.value)
        ref?.current?.scroll(0, 0)
    }

    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!disabled) {
            if (!active) {
                setAnchorEl(event.currentTarget)
                setActive(true)
            } else {
                handleClose()
            }
        }
    }

    const handleClose = () => {
        setActive(false)
        setAnchorEl(null)
        setSearchQueue('')
    }

    return (
        <Box className={clsx('customField', className, { error, disabled })}>
            {title && <span>{title}</span>}
            <div
                className={clsx('customField__select', innerClassName, theme && `customField__select--${theme}`)}
                onClick={onClick}
                ref={selectorRefForWidth}
            >
                {icon ? <p className='customField__select__icon'>{icon}</p> : ''}
                {
                    value !== undefined &&
                    value !== null &&
                    value !== '' &&
                    currentItems?.some((item) => item.value == value) ?
                        <span>{setMask(currentItems.find((item) => item.value == value)?.text as string, mask)}</span> :
                        value === undefined || value === null || value === '' ?
                            <span className='customField__placeholder'>
                                {placeholder !== null && placeholder !== undefined ? placeholder : 'Выберите значение'}
                            </span> :
                            <span>{setMask(value as string, mask)}</span>
                }
                {theme === CustomSelectThemes.TABLE ?
                    <TriangleIcon className={clsx({ active }, 'customSelect__tableIcon')} /> :
                    <ExpandMore className={clsx({ active }, 'customField__expand')} />
                }
            </div>
            <Popover
                className={clsx('customSelect__popover', theme && `customSelect__popover--${theme}`)}
                open={active}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div
                    className='customSelect__popover-container'
                    style={{ width: blockWidth ? `${blockWidth}px` : 'auto' }}
                    ref={ref}
                >
                    {search && (
                        <div className='customSelect__popover-searchWrapper'>
                            <div className='customSelect__popover-search'>
                                <FaSearch />
                                <input
                                    className='customSelect__popover-input'
                                    placeholder={searchPlaceholder}
                                    type='text'
                                    onChange={handleSetSearchQueue}
                                />
                            </div>
                        </div>
                    )}
                    <div className='customSelect__popover-list'>
                        {searchResult?.map((option, index) => (
                            <div
                                className={clsx('customSelect__popover-option', {
                                    'customSelect__popover-option--disabled': option.disabled,
                                })}
                                key={name + '_option_' + index}
                                onClick={() => !option.disabled && handleClick(option.value)}
                            >
                                {option.icon ? selectActionIcon(option.icon) : null}
                                {option.text}
                            </div>
                        ),
                        )}
                    </div>
                </div>
            </Popover>
        </Box>
    )
})

export default CustomSelect
