import { memo } from 'react'
import { useWatch } from 'react-hook-form'
import './AdditionalServicesTotal.scss'
import { TaskItem } from '~/components/pages/KP/kp.types'

interface AdditionalServicesTotalProps {
    name: 'foremanReport.additionalServices'
}

const AdditionalServicesTotal = (props: AdditionalServicesTotalProps) => {
    const { name } = props

    const services: TaskItem[] = useWatch({ name })

    const total = services.reduce((acc, service) => {
        if (!service.price) return acc
        return acc += +service.price
    }, 0)


    return (
        <div className='additionalServicesTotal'>
            <p>Подытог: <span>{total} ₽</span></p>
        </div>
    )
}

export default memo(AdditionalServicesTotal)
