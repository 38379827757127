import React, { useEffect, useState } from 'react'
import { DaDataParty, DaDataSuggestion } from 'react-dadata'
import { Controller, FieldValues, useForm, UseFormClearErrors, useFormContext, UseFormSetValue } from 'react-hook-form'
import { BankDataType, InputsTypes } from './PopupOrganization.types'
import { useSaveOrganizationMutation } from '~/API/organizationApi/organization'
import CustomInput from '~/components/common/inputs/CustomInput/CustomInput'
import { CustomButton, CustomButtonThemes } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { CustomPopup } from '~/components/common/ReusingComponents/CustomPopup/CustomPopup'
import { useKeyPress } from '~/hooks/useKeyPress'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import { useAppDispatch } from '~/redux/store'
import { primaryInformation } from '~/shared/templates/inputTemplates/primaryInformation'
import './PopupOrganization.scss'

export const handleSetOrganizationValues = (setValue: UseFormSetValue<FieldValues>, clearErrors: UseFormClearErrors<FieldValues>, response: any) => {
    clearErrors([
        'primaryInformation.organizationRequisites.bankName',
        'primaryInformation.organizationRequisites.bankId',
        'primaryInformation.organizationRequisites.bankAccount',
        'primaryInformation.organizationRequisites.correspondentAccount',
    ])
    setValue(
        'primaryInformation.organizationRequisites.bankName',
        response.bankName,
    )
    setValue(
        'primaryInformation.organizationRequisites.bankId',
        response.bankId,
    )
    setValue(
        'primaryInformation.organizationRequisites.bankAccount',
        response.bankAccount,
    )
    setValue(
        'primaryInformation.organizationRequisites.correspondentAccount',
        response.correspondentAccount,
    )
}

interface PopupOrganizationProps {
    handleSetOrganization: (value: boolean) => void,
    handleSetBankData: (value: BankDataType) => void,
    organizationValues: DaDataSuggestion<DaDataParty>,
    bankData: BankDataType,
    disabled: boolean,
}

export const PopupOrganization = (props: PopupOrganizationProps) => {
    const {
        handleSetOrganization,
        handleSetBankData,
        organizationValues,
        bankData,
        disabled,
    } = props
    const dispatch = useAppDispatch()
    const [saveOrganization] = useSaveOrganizationMutation()
    const isEnterPressed = useKeyPress('Enter')
    const {
        setValue,
        clearErrors,
    } = useFormContext()
    const currentForm = useForm()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isError, setError] = useState<boolean>(false)

    const rules = {
        'organizationRequisites.bankName': true,
        'organizationRequisites.bankId': true,
        'organizationRequisites.bankAccount': true,
        'organizationRequisites.correspondentAccount': true,
    }

    const form: InputsTypes[][] = [
        [
            primaryInformation.organizationNameShort(true),
        ],
        [
            primaryInformation.organizationKpp(true),
            primaryInformation.organizationInn(true),
            primaryInformation.organizationOgrn(true),
            primaryInformation.organizationOkato(true),
        ],
        [
            primaryInformation.organizationManagementName(true),
            primaryInformation.organizationManagementPost(true),
        ],
        [
            primaryInformation.bankName(disabled),
            primaryInformation.bankId(disabled),
            primaryInformation.bankAccount(disabled),
            primaryInformation.correspondentAccount(disabled),
        ],
    ]

    const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        handleSetOrganization(false)
    }

    const onSubmit = currentForm.handleSubmit((data) => {
        const newValues = { ...organizationValues, ...data.primaryInformation.organizationRequisites }
        setLoading(true)
        saveOrganization(newValues).unwrap()
            .then((response) => {
                setError(false)
                handleSetBankData({
                    bankName: response.bankName,
                    bankId: response.bankId,
                    bankAccount: response.bankAccount,
                    correspondentAccount: response.correspondentAccount,
                })
                handleSetOrganizationValues(setValue, clearErrors, response)
                handleSetOrganizationValues(currentForm.setValue, currentForm.clearErrors, response)
                handleSetOrganization(false)
            })
            .catch(() => {
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    })

    const getDefaultValues = (inputValue: InputsTypes) => {
        if (inputValue.name.split('.').length > 2) {
            const mainValue = organizationValues[inputValue.name.split('.')[1] as keyof typeof organizationValues]
            if (mainValue) {
                return mainValue[inputValue.name.split('.')[2] as keyof typeof mainValue]
            }
        }
        if (bankData[inputValue.name.split('.')[1] as keyof typeof bankData]) {
            return bankData[inputValue.name.split('.')[1] as keyof typeof bankData]
        }
        return organizationValues[inputValue.name.split('.')[1] as keyof typeof organizationValues]
    }

    useEffect(() => {
        isError && dispatch(SHOW_NOTICE({ type: 'error', message: 'Произошла ошибка при сохранении организации' }))
    }, [isError])

    useEffect(() => {
        if (isEnterPressed) {
            onSubmit()
        }
    }, [isEnterPressed])

    return (
        <CustomPopup
            onClose={handleClose}
            title={{ title: 'Информация об организации' }}
            className='popup-organization'
            body={{
                data: form.map((inputs, index) => (
                    inputs.map((inputValue) => {
                        return (
                            <Controller
                                key={`controller.popup_${inputValue.name}`}
                                name={`primaryInformation.${inputValue.name}`}
                                rules={{ required: rules[inputValue.name as keyof typeof rules] || false }}
                                control={currentForm.control}
                                defaultValue={getDefaultValues(inputValue) || ''}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <CustomInput
                                        {...inputValue}
                                        error={error}
                                        value={value}
                                        onChange={onChange}
                                        key={`textField_popup_${inputValue.name}`}
                                        className='popup-organization__input'
                                        mask={value.mask}
                                    />
                                )}
                            />
                        )
                    })
                )),
            }}
            footer={!disabled ? [
                (
                    <CustomButton
                        key={'PopupOrganization_onSubmit'}
                        onClick={onSubmit}
                        title='Сохранить'
                        theme={CustomButtonThemes.COLOR}
                        disabled={isLoading}
                    />
                ),
                (
                    <CustomButton
                        key={'PopupOrganization_onCancel'}
                        onClick={() => handleSetOrganization(false)}
                        title='Отмена'
                        theme={CustomButtonThemes.BORDERLESS}
                    />
                ),
            ] : []}
        />
    )
}
