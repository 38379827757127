import { FaCalculator } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchOrderQuery } from '~/API/orderApi/order'
import { DocumentsButton } from '~/components/common/inputs/CustomFileInputGroup/CustomFileInput/CustomFileInput'
import {
    getDocumentRoute,
} from '~/components/common/inputs/CustomFileInputGroup/CustomFileInput/CustomFileInput.helpers'
import './LinkButton.scss'

interface LinkButtonProps {
    editableText: string
    documentType: DocumentsButton
}

const LinkButton = (props: LinkButtonProps) => {
    const {
        editableText,
        documentType,
    } = props

    const { id: orderId } = useParams()
    const navigate = useNavigate()

    const { data: order } = useFetchOrderQuery(orderId!)

    return (
        <button className='linkButton'
            onClick={() => navigate(getDocumentRoute(documentType, orderId!, order), {
                state: {
                    alwaysSave: true,
                    title: 'Сохранить внесенные изменения?',
                },
            })}>
            <FaCalculator />
            {editableText}
        </button>
    )
}

export default LinkButton
