export const fixAddress = (address: string) => {
    if (!address) {
        return ''
    }
    return address
        .replaceAll(' д ', ' д. ')
        .replaceAll(' кв ', ' кв. ')
        .replaceAll(' стр ', ' стр. ')
        .replaceAll(' к ', ' к. ')
        .replaceAll(' г ', ' г. ')
}

export const separateCityInAddress = (address: string) => {
    if (!address) {
        return ''
    }
    return address
        .replace('г Санкт-Петербург, ', '')
        .replace('Ленинградская обл, ', '')
}
