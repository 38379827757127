import { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { useSelector } from 'react-redux'
import MiniCalendarHint from './subComponents/MiniCalendarHint/MiniCalendarHint'
import TileContent from './subComponents/TileContent/TileContent'
import { betweenDates } from '../../../../mobile/mobilePages/MobileHomePage/subComponents/Agenda/AgendaLib'
import { User } from '~/API/userApi/user.types'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { selectFilteredEvents, selectOrderActiveDate } from '~/redux/selectors/orderSlice.selectors'
import { setActiveDate } from '~/redux/slices/staffScheduleSlice/staffScheduleSlice'
import { useAppDispatch } from '~/redux/store'
import { fetchDate } from '~/shared/util/currentTime'
import { FilteredEvent } from '~/types/types.order'
import './MiniCalendar.scss'

interface MiniCalendarProps {
    propsActiveDate?: Date,
    propsFilteredEvents?: any
    activeMiniCalendar?: boolean
}

const MiniCalendar = (props: MiniCalendarProps) => {
    const {
        propsFilteredEvents,
        propsActiveDate,
        activeMiniCalendar = true,
    } = props
    const dispatch = useAppDispatch()
    const user = useSelector(selectUser)
    const selectorActiveDate = useSelector(selectOrderActiveDate).date
    const selectorFilteredEvents = useSelector(selectFilteredEvents)

    const activeDate = propsActiveDate || selectorActiveDate
    const filteredEvents: FilteredEvent[] = propsFilteredEvents || selectorFilteredEvents

    const [activeStartDay, setActiveStartDay] = useState(new Date())
    const [localEvents, setLocalEvents] = useState<{ [key: string]: number }>({})

    const handleActiveChange = (value: any) => {
        setActiveStartDay(value.activeStartDate)
    }

    useEffect(() => {
        dispatch(setActiveDate(fetchDate().toDate()))
    }, [])

    useEffect(() => {
        setActiveStartDay(activeDate)
    }, [activeDate])

    useEffect(() => {
        if (filteredEvents) {
            setLocalEvents(
                filteredEvents.reduce((accumulator, event) => {
                    const days = betweenDates(event.start, event.end)
                    days.forEach((day) => {
                        const eventDate = new Date(day).setHours(0, 0, 0, 0).toString()
                        if (accumulator[eventDate as keyof typeof accumulator]) {
                            accumulator = {
                                ...accumulator,
                                [eventDate]: accumulator[eventDate as keyof typeof accumulator] + 1,
                            }
                        } else {
                            accumulator = {
                                ...accumulator,
                                [eventDate]: 1,
                            }
                        }
                    })
                    return accumulator
                }, {}),
            )
        }
    }, [filteredEvents])

    return (
        <div className='miniCalendar'>
            <Calendar
                locale='ru-RU'
                minDetail='month'
                navigationLabel={({ label }: { label: any }) => <span>{label.substring(0, label.length - 2)}</span>}
                tileContent={({ date }: { date: Date }) => TileContent(date, localEvents)}
                activeStartDate={activeStartDay}
                onActiveStartDateChange={handleActiveChange}
                nextLabel={null}
                next2Label={null}
                prevLabel={null}
                prev2Label={null}
            />
            {(user as User)?.role === 'CHIEF' && activeMiniCalendar && (
                <MiniCalendarHint
                    events={filteredEvents?.filter((e: any) => fetchDate(e.start).isSame(fetchDate(activeStartDay), 'month'))}
                />
            )}
        </div>
    )
}
export default MiniCalendar
