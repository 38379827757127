import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface CustomErrorType {
    data: {
        message: string,
        status: string,
    }
    status: number
}

export const rtkApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('jwtToken') || ''
            if (token) {
                headers.set('Authorization', token)
            }
            return headers
        },
    }) as
        BaseQueryFn<string | FetchArgs, unknown, CustomErrorType, object>,
    tagTypes: [
        'Salary',
        'File',
        'Schedule',
        'User',
        'Order',
        'Norms',
        'Tools',
        'Organization',
        'Personnel',
        'Staff',
        'Util',
        'Client',
        'MoneyFlow',
    ],
    refetchOnReconnect: true,
    endpoints: (builder) => ({}),
})
