import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

type StyledRowDivPropsTypes = {
    $flexDirection: 'column' | 'row'
    $borderColor?: string
    $isHidden?: boolean
}

export const StyledInputWrapper = styled('div')`
  padding: 0;
  margin: 0;
  border: none;
  flex: 1;
  width: 100%;
`

export const StyledRowDiv = styled('div')<StyledRowDivPropsTypes>`
  //padding: 4px 20px;
  margin-top: ${({ $isHidden }) => $isHidden ? '-1rem' : 0}; //1rem;
  //margin: 4px;
  width: 100%;
  display: ${() => isMobile ? 'grid' : 'flex'};
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  justify-content: ${() => isMobile ? '' : 'space-between'};
  align-items: flex-end;
  border: ${({ $borderColor }) => $borderColor ? `1px solid ${$borderColor}` : 'none'};
  row-gap: 1rem;
  column-gap: 2.5rem;
  flex-wrap: wrap;
`
