import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useFetchOrderQuery } from '~/API/orderApi/order'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { KPO_TEST_VALUES } from '~/components/pages/Kpo/constants'
import {
    selectKpoHandleSubmit,
    selectKpoIsLoading, selectKpoResetKpo,
} from '~/redux/selectors/kpoSlice.selector'

const KpoSidebarButtons = () => {
    const { id: orderId } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const isKpoStartingPage = location.pathname.includes('kpo-starting')

    const handleSubmit = useSelector(selectKpoHandleSubmit)
    const isLoading = useSelector(selectKpoIsLoading)
    const resetForm = useSelector(selectKpoResetKpo)

    const { data: order } = useFetchOrderQuery(orderId!)

    const getKpoDisabled = () => {
        if (isKpoStartingPage) {
            return order?.stageName !== 'WORKING_PLAN_APPROVING'
        } else {
            return order?.stageName !== 'INSPECTION_STARTING'
        }
    }

    return (
        <div className='sidebar__buttons'>
            <CustomButton
                className='sidebar__buttonsItem'
                onClick={() => handleSubmit()}
                disabled={isLoading || getKpoDisabled()}
            >
                {order?.stageName === 'WORKING_PLAN_APPROVING' ? 'Сохранить' : 'Сформировать'} КПО
            </CustomButton>
            <CustomButton
                className='sidebar__buttonsItem sidebar__buttonsItem--secondary'
                onClick={(event) => {
                    if (event.ctrlKey && event.altKey) {
                        resetForm(KPO_TEST_VALUES)
                    } else {
                        navigate(`/order/${orderId}`)
                    }
                }}
            >
                {order?.stageName === 'WORKING_PLAN_APPROVING' ? 'Вернуться к Запуск уборки' : 'Вернуться к заявке'}
            </CustomButton>
        </div>
    )
}

export default KpoSidebarButtons
