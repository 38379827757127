import { contract } from '~/shared/templates/mainTemplates/contract'
import { contractMaintenance } from '~/shared/templates/mainTemplates/contractMaintenance/contractMaintenance'
import { kpo, KpoValues } from '~/shared/templates/mainTemplates/kpo'
import { kpoMaintenance } from '~/shared/templates/mainTemplates/kpoMaintenance'
import { kpoSimple } from '~/shared/templates/mainTemplates/kpoSimple'
import { kpWithInspection } from '~/shared/templates/mainTemplates/kpWithInspection'
import { nzFull, NzFullValues } from '~/shared/templates/mainTemplates/nzFull'
import { salaryIssued, SalaryIssuedValues } from '~/shared/templates/mainTemplates/salaryIssued'
import { act } from './mainTemplates/act'
import { contractB2B } from './mainTemplates/contractB2B'
import { contractBill } from './mainTemplates/contractBill'
//@ts-ignore
import { nzBack } from './mainTemplates/nz'
//@ts-ignore
import { nzBackMultipleDay } from './mainTemplates/nzMultipleDay'
import { nzFrontNew, NzFrontValues } from './mainTemplates/nzNew'
import { salaryTable, SalaryTableTemplate } from './mainTemplates/salaryTable'

export const templates = {
    smeta: (values: any) => {
        const html = '<div><img style="width: 840px;height:1056px;" src="/html/bgKP.jpg"/><div>' + Object.values(values) + 'BBBBBBBBBBBBBBBBB CCCCCCCCCCCCCCCCCCCCC</div></div>'
        return html
    },
    nzBack: async (values: any) => {
        const template = await nzBack(values)
        return buildHtml(template)
    },
    nzBackMultipleDay: async (values: any) => {
        const template = await nzBackMultipleDay(values)
        return buildHtml(template)
    },

    salaryTable: async (values: SalaryTableTemplate) => {
        const template = await salaryTable(values)
        return buildHtml(template)
    },
    kpWithInspection: async (values: any) => {
        const template = await kpWithInspection(values)
        return buildHtml(template)
    },
    contract: async (values: any) => {
        const template = await contract(values)
        return buildHtml(template)
    },
    contractB2B: async (values: any) => {
        const template = await contractB2B(values)
        return buildHtml(template)
    },
    contractMaintenance: async (values: any) => {
        const template = await contractMaintenance(values)
        return buildHtml(template)
    },
    contractMaintenanceDocx: async (values: any) => {
        const template = await contractMaintenance({ ...values, format: 'docx' })
        return buildHtml(template)
    },
    contractBill: async (values: any) => {
        const template = await contractBill(values)
        return buildHtml(template)
    },
    act: async (values: any) => {
        const template = await act(values)
        return buildHtml(template)
    },
    salaryIssued: async (values: SalaryIssuedValues) => {
        const template = await salaryIssued(values)
        return buildHtml(template)
    },
    kpo: async (values: KpoValues) => {
        const template = await kpo(values)
        return buildHtml(template)
    },
    kpoSimple: async (values: KpoValues) => {
        const template = await kpoSimple(values)
        return buildHtml(template)
    },
    kpoMaintenance: async (values: KpoValues) => {
        const template = await kpoMaintenance(values)
        return buildHtml(template)
    },
    nzNew: async (values: NzFrontValues) => {
        const template = await nzFrontNew(values)
        return buildHtml(template)
    },
    nzFull: async (values: NzFullValues) => {
        const template = await nzFull(values)
        // return buildHtml({
        //     style: template.nzFront.style,
        //     body: template.nzFront.body + template.nzBack.body,
        // })
        return buildHtml({
            style: template.nzBack.style,
            body: template.nzBack.body,
        })
    },
}

const buildHtml = (template: any) =>
    `<!DOCTYPE html>
        <html xmlns='http://www.w3.org/1999/xhtml' lang='ru'>
            <head> 
                <title>H2O Cleaning document</title>
                <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
                <style>${template.style}</style>
            </head>
            <body>${template.body}</body>
        </html>`
        .replaceAll('<br>', '<br/>')
        .replaceAll('"><label', '"/><label')
