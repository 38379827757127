import clsx from 'clsx'
import React, { memo, ReactNode } from 'react'
import './CustomButton.scss'

export enum CustomButtonThemes {
    COLOR = 'color',
    OUTLINE = 'outline',
    BORDERLESS = 'borderless',
    SMOKE = 'smoke',
    TEXT = 'text',
    DANGER = 'danger'
}

interface CustomButtonProps {
    onClick: (...args: any) => void
    title?: string
    disabled?: boolean
    className?: string
    theme?: CustomButtonThemes
    children?: ReactNode
}

export const CustomButton = memo(function CustomButton(props: CustomButtonProps) {
    const {
        onClick,
        title,
        disabled = false,
        className,
        theme = CustomButtonThemes.COLOR,
        children,
    } = props

    return (
        <button
            className={clsx('custom-button', className, theme && `custom-button--${theme}`)}
            type='button'
            onClick={onClick}
            disabled={disabled}
        >
            {children ? children : (
                <span>{title}</span>
            )}
        </button>
    )
})
