import { Dayjs } from 'dayjs'
import dayjs from '~/shared/util/dayjsConfig'

export function fetchDate(date?: Date | string | Dayjs, format?: string): Dayjs {
    if (date) {
        if (format) {
            return dayjs.utc(date, format).locale('ru').local()
        }
        return dayjs.utc(date).locale('ru').local()
    }
    return dayjs().utc().locale('ru')
    // TODO if it comes with a date, it gives +3 to the hour, without a date,
    // it gives back exactly the hour that was sent
    // make sure it doesn't add hours, but doesn't break anything
}

export function parseStringToDate(dateString: string, format: string): Dayjs {
    return dayjs(dateString, format)
}

export function fetchLocalDate(date?: Date | string | Dayjs): Dayjs {
    if (date) {
        return dayjs(date).locale('ru')
    }
    return dayjs().locale('ru')
}

export function fetchDateToUTC(date: Date | string | Dayjs): Dayjs {
    return dayjs.utc(date).locale('ru')
}

export function diffHours(startDate: Dayjs, endDate: Dayjs): number {
    return Math.abs(Math.round(endDate.diff(startDate, 'hour', true)))
}

export function startOfMonth(date?: Date | Dayjs | null): Dayjs {
    if (date) {
        return dayjs(date).locale('ru').startOf('month')
    }
    return dayjs().locale('ru').startOf('month')
}

export function endOfMonth(date?: Date | Dayjs): Dayjs {
    if (date) {
        return dayjs(date).locale('ru').endOf('month')
    }
    return dayjs().locale('ru').endOf('month')
}

export function getFirstDayOfWeek(date?: Date): Dayjs {
    if (date) {
        return fetchDate(date).locale('ru').startOf('week')
    }
    return fetchLocalDate().locale('ru').startOf('week')
}

export function getLastDayOfWeek(date?: Date): Dayjs {
    if (date) {
        return fetchDate(date).locale('ru').endOf('week')
    }
    return fetchLocalDate().locale('ru').endOf('week')
}

export function getFirstDayOfYear(date?: Date): Dayjs {
    if (date) {
        return fetchDate(date).startOf('year')
    }
    return dayjs().startOf('year')
}

export function getLastDayOfYear(date?: Date): Dayjs {
    if (date) {
        return fetchDate(date).endOf('year')
    }
    return dayjs().endOf('year')
}

interface GetArrayOfDatesParams {
    startOfPeriod: Dayjs;
    endOfPeriod: Dayjs;
}

export const getArrayOfDays = (params: GetArrayOfDatesParams): Date[] => {
    const { startOfPeriod, endOfPeriod } = params

    const days: Date[] = []
    let day = startOfPeriod

    while (day.isBefore(endOfPeriod) || day.isSame(endOfPeriod)) {
        days.push(day.toDate())
        day = day.add(1, 'day')
    }

    return days
}

export function countCalendarDays(day1: Date | Dayjs, day2: Date | Dayjs): number {
    return Math.abs(dayjs(day1).startOf('day').diff(dayjs(day2).endOf('day'), 'day')) + 1
}

export function countMonths(date1: Date | Dayjs, date2: Date | Dayjs): number {
    return Math.abs(dayjs(date1).diff(dayjs(date2), 'month') + 1)
}

export const getWeekdayName = (date: Date): string => {
    const dayIndex = dayjs(date).weekday()
    const weekdays = dayjs.weekdays()
    return weekdays[dayIndex + 1]
}
