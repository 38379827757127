import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import { FaSlidersH } from '@react-icons/all-files/fa/FaSlidersH'
import React, { memo, useCallback, useState } from 'react'
import { PopupFilter } from '~/components/common/popups/PopupFilter/PopupFilter'
import './MobileSearch.scss'

interface MobileSearchProps {
    sections: string[];
    handleSelectSection: (value: string) => void
    handleSetSearch: (value: string) => void
}

export const MobileSearch = memo(function MobileSearch({ sections, handleSelectSection, handleSetSearch }: MobileSearchProps) {
    const [filterPopup, setFilterPopup] = useState<boolean>(false)

    const onClose = useCallback(() => {
        setFilterPopup(false)
    }, [])

    return (
        <div className='search'>
            <div className='search__wrapper'>
                <FaSearch className='search__icon' />
                <input
                    type='text'
                    className='search__input'
                    placeholder='Поиск документа'
                    onChange={(e) => {
                        handleSetSearch(e.target.value.trimStart())
                    }}
                />
            </div>
            <button
                type='button'
                className='filter__button'
                onClick={() => setFilterPopup(true)}
            >
                <FaSlidersH className='filter__icon' />
            </button>
            {filterPopup && (
                <PopupFilter
                    onClose={onClose}
                    sections={sections}
                    handleSelectSection={handleSelectSection}
                />
            )}
        </div>
    )
})
