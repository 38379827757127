import React, { ReactElement, useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { InputTypeEnum } from '../../enum/InputTypeEnum'
import { InputParams, InputParamsItem } from '../../useFormConstructor.types'
import { createInputName } from '../additionalFunctions/createInputName'
import { inputLogicRules } from '../additionalFunctions/inputLogicRules'
import { selectUser } from '~/redux/selectors/appSlice.selectors'

type WithUseFormWatchProps = {
    inputChild: ReactElement<InputParams>
}

const WithUseFormWatch = ({ inputChild }: WithUseFormWatchProps): ReactElement<InputParams> | null => {
    // todo при необходимости вынести выше по компонентам, прокинув пропсами
    // юзера получаем прям тут, потому что выше он не используется
    const user = useSelector(selectUser)

    // забираем пропсы у инпута, который получили ранее с помощью getInput
    const {
        control,
        items,
        options,
        type,
    } = inputChild.props

    const watchingInputsValues: string[] = useWatch({ control, name: options?.watchInputs ? options.watchInputs : [] })

    const [usingValuesForInput, setUsingValuesForInput] = useState<InputParamsItem<any>[]>(items ? (items as Record<string, InputParamsItem<any>[]>)['default'] : [])

    useEffect(() => {
        let isMounted = true
        if (options && options.itemRules && options.itemRules.length !== 0 && user !== null) { // делаем так же проверку на юзера, просто чтоб отсеять ошибки при null
            let conditionMet = false // просто флаг, для прекращения перебора, когда хотяб одно условие из цикла вернуло true

            // перебираем все правила которые указаны для инпута
            options.itemRules.forEach((rule) => {
                // достаем функцию из объекта
                const ruleFunc = inputLogicRules[rule.rule]
                // спасаем код от ошибок, в виде опечаток в названиях функций в json и + если надо останавливаем перебор
                if (ruleFunc === undefined || conditionMet) return
                // составляем массив с отфильтрованными значениями в котором только значения для каждого правила
                const filteredValues = watchingInputsValues.filter((_, index) =>
                    rule.dependencyInputs.includes(options.watchInputs[index]),
                )
                // проверка на удовлетворение функции
                if (ruleFunc(filteredValues, user.role)) {
                    // подставляем нужный массив items по ключу от правила
                    if (isMounted) setUsingValuesForInput((items as Record<string, InputParamsItem<any>[]>)[rule.itemsKey])
                    conditionMet = true // отмечаем на флаге
                } else { // когда вообще никакое условие не подошло, действуем по дефолту
                    // setNewValues()
                    if (items) {
                        const itemString = createInputName(watchingInputsValues) as string
                        const dependentValue = (items as Record<string, InputParamsItem<any>[]>)[itemString]
                        if (isMounted) setUsingValuesForInput(dependentValue === undefined ? (items as Record<string, InputParamsItem<any>[]>)['default'] : dependentValue)
                    }
                }
            })
        } else { // если нет правил - просто сопоставляем значения с ключами, без излишней логики
            // setNewValues()
            if (items) {
                const itemString = createInputName(watchingInputsValues) as string
                let dependentValue = []
                switch (options?.mode) {
                    case 'boolean':
                        dependentValue = (items as Record<string, InputParamsItem<any>[]>)[(!!itemString).toString()]
                        break
                    default:
                        dependentValue = (items as Record<string, InputParamsItem<any>[]>)[itemString]
                        break
                }
                if (isMounted) setUsingValuesForInput(dependentValue === undefined ? (items as Record<string, InputParamsItem<any>[]>)['default'] : dependentValue)
            }
        }
        return () => {
            isMounted = false
        }
    }, [watchingInputsValues])

    if (!options || !items || Array.isArray(items)) {
        return inputChild
    }

    if ((type === InputTypeEnum.action || type === InputTypeEnum.select) &&
        usingValuesForInput?.length === 0) { // проверка на наличие 1 выбора в типах action & select
        return null
    }

    // Заменяем, items у инпута, возвращая при этом все остальные
    return React.cloneElement(inputChild, {
        ...inputChild.props,
        items: usingValuesForInput,
    })
}

export default WithUseFormWatch
