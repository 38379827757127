export const buildStageTransitionInput = (items: any[]) => {
    return {
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        version: 'v2',
        items,
    }
}
