import './CustomTitle.scss'
import clsx from 'clsx'

interface CustomTitleProps {
    title: string
    className?: string
}

export const CustomTitle = ({ title, className }: CustomTitleProps) => {
    return (
        <div className={clsx('custom-title', className)}>
            <h3>
                {title}
            </h3>
        </div>
    )
}
