import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FaPen, FaThumbtack } from 'react-icons/fa'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import rate1 from '~/assets/rate/1.svg'
import rate2 from '~/assets/rate/2.svg'
import rate3 from '~/assets/rate/3.svg'
import rate4 from '~/assets/rate/4.svg'
import rate5 from '~/assets/rate/5.svg'
import noRate from '~/assets/rate/noRate.svg'
import { OnSubscriptionFieldChange } from '~/components/common/ReusingComponents/TabsTable/tabsTable.types'
import MobileEditModal from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileEditModal/MobileEditModal'
import './PersonnelReportItem.scss'

const RATING = [noRate, rate1, rate2, rate3, rate4, rate5]

interface MobilePersonCardProps {
    inputName: string
    inputs: any[]
    rowIndex: number
    disabled: boolean
    isError: boolean
    usingData?: string
    onSubscriptionFieldChange: OnSubscriptionFieldChange
    onDelete: (index: number) => void
}

const PersonnelReportItem = (props: MobilePersonCardProps) => {
    const {
        inputName,
        inputs,
        disabled,
        isError,
        rowIndex,
        usingData,
        onSubscriptionFieldChange,
        onDelete,
    } = props

    const [editModalOpen, setEditModalOpen] = useState<boolean>(false)

    const { getValues } = useFormContext<OrderInterface>()

    const {
        salary,
        addSalary,
        name,
        jobPosition,
        comment,
        rate,
        personnelId,
    } = getValues(inputName as `foremanReport.daysReport.${number}.actualTeam.${number}`)

    const sectionDocuments = getValues(`${usingData}.memo` as `documents.foremanReport.daysReport.${number}.actualTeam.memo`) || []
    const hasFile = !!sectionDocuments.find((item) => item?.index === rowIndex)

    const inputsWithSelfValue = useMemo(() => (inputs.map((input) => {
        if (input.name === 'personnelId' && personnelId) {
            return {
                ...input,
                items: [{ text: name, value: personnelId }, ...input.items],
            }
        }
        return input
    })), [inputs, personnelId, name])

    return (
        <>
            <div className={clsx('mobilePersonCard', isError && 'mobilePersonCard--error')}>
                <div className='mobilePersonCard__title'>
                    <div className='mobilePersonCard__title__content'>
                        <span className='mobilePersonCard__title__content__name'>{name}</span>
                        {!disabled && (
                            <button
                                type='button'
                                className='mobilePersonCard__title__content__edit'
                                disabled={disabled}
                                onClick={() => setEditModalOpen(true)}
                            >
                                <FaPen />
                            </button>
                        )}
                    </div>
                    <div className='mobilePersonCard__title__job'>
                        <span className='mobilePersonCard__title__job__jobPosition'>{jobPosition}</span>
                        {hasFile && <span className='mobilePersonCard__tag'>
                            <FaThumbtack size={8} />
                            ЗАПИСКА
                        </span>}
                    </div>
                </div>
                <div className='mobilePersonCard__salary'>
                    <span className='mobilePersonCard__salary__jobSalary'>
                        Ставка: <span className='salary'>{salary}</span>
                    </span>
                    <span className='mobilePersonCard__salary__addSalary'>
                        Доп. выплата: <span className='salary'>{addSalary ? addSalary : '0'}</span>
                    </span>
                    {
                        RATING.map((Rate, index) => {
                            if (index === rate) {
                                return <Rate key={index} className='mobilePersonCard__slary__rate' />
                            }
                        })
                    }
                </div>
                <div className='mobilePersonCard__note'>
                    <span>Комментарий:</span>
                    <p className='note'>{comment === '' ? 'Комментарий отсутствует' : comment}</p>
                </div>
            </div>
            <MobileEditModal
                inputName={inputName}
                isOpen={editModalOpen}
                closeModal={() => setEditModalOpen(false)}
                inputs={inputsWithSelfValue}
                disabled={disabled}
                sectionName=''
                rowIndex={rowIndex}
                usingData={usingData}
                onSubscriptionFieldChange={onSubscriptionFieldChange}
                onDelete={onDelete}
                deleteText='Удалить карточку сотрудника'
            />
        </>
    )
}

export default PersonnelReportItem
