import React, { useMemo } from 'react'
import { FaRegAddressBook } from 'react-icons/fa'
import ContractorSvg from '~/assets/svg/contractorIcon.svg'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'

interface useChooseIconParams {
    clientType: ClientInfoEnum
}

export const useChooseIcon = ({ clientType }: useChooseIconParams) => {
    return useMemo(() => {
        switch (clientType) {
            case ClientInfoEnum.CONTRACTOR: {
                return (
                    <ContractorSvg className='client-info__button__icon' />
                )
            }
            case ClientInfoEnum.ORGANIZATION: {
                return (
                    <FaRegAddressBook className='client-info__button__icon' />
                )
            }
        }
    }, [])
}
