import clsx from 'clsx'
import './MobileTitle.scss'

interface MobileTitleProps {
    title: string;
    className?: string;
    alignCenter?: boolean;
}

const MobileTitle = (props: MobileTitleProps) => {
    const { title, className, alignCenter } = props

    return (
        <h2 className={clsx('mobileTitle', alignCenter && 'mobileTitle--center', className)}>{title}</h2>
    )
}

export default MobileTitle
