import clsx from 'clsx'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { FaCheck, FaRegCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import Preloader from '../../../../../common/preloaders/Preloader/Preloader'
import BackButton from '../../../../../common/ReusingComponents/BackButton/BackButton'
import DocumentManager from '../../../../../common/ReusingComponents/DocumentManager/DocumentManager'
import { fetchFile, useLazyFetchFileModelQuery } from '~/API/fileApi/file'
import { Portal } from '~/components/common/ReusingComponents/Portal/Portal'
import { checkFilesInputName, isFileImage } from '~/components/pages/Order/subComponents/OrderDocuments/documentLib'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { fetchFileRules } from '~/shared/order/filesRules'
import './MobilePhotoViewer.scss'

interface PhotoViewerProps {
    handleClose: () => void,
    photoId: string,
    files?: {
        id: string,
        inputName: string,
        name: string,
    }[],
    currentStage?: string,
    onDelete?: (photoIds: string[]) => void,
    type: 'image' | 'pdf',
}

interface PhotoType {
    src: string,
    id: string,
    deletable: boolean,
    fileIndex: number,
}

export const MobilePhotoViewer = memo(function MobilePhotoViewer(props: PhotoViewerProps) {
    const {
        handleClose,
        files,
        photoId,
        currentStage,
        onDelete,
        type,
    } = props
    const [fetchFileModel] = useLazyFetchFileModelQuery()
    const user = useSelector(selectUser)
    const [currentImage, setCurrentImage] = useState<PhotoType>({
        src: '',
        id: photoId,
        deletable: false,
        fileIndex: 0,
    })
    const [imagesMap, setImagesMap] = useState<Map<string, PhotoType>>(new Map())
    const [imagesCount, setImagesCount] = useState<number>(0)
    const [body, setBody] = useState(files?.length === 0 ?
        <p className='mobile-viewer__body__img'>Нет изображений</p> :
        <Preloader withoutBackground={true} />,
    )
    const [fileName, setFileName] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(true)
    const [isSwiped, setSwiped] = useState<boolean>(false)
    const [selectedImages, setSelectedImages] = useState<string[]>([])
    const swipeableRef = useRef<HTMLDivElement>(null)
    const [pdfSrc, setPdfSrc] = useState<string>('')

    const handleDelete = () => {
        if (selectedImages.length > 0) {
            if (selectedImages.length === imagesMap.size) {
                setBody(<p className='mobile-viewer__body__img'>Нет изображений</p>)
                setCurrentImage({ src: '', id: photoId, deletable: false, fileIndex: 0 })
            }
            selectedImages.forEach((fileId) => {
                setImagesMap((prev) => {
                    prev.delete(fileId)
                    if (prev.size > 0 && selectedImages.includes(currentImage.id)) {
                        setCurrentImage(prev.values().next().value as PhotoType)
                    }
                    return prev
                })
            })
            onDelete && onDelete(selectedImages)
            setSelectedImages([])
        }
    }

    const handleOpenFile = () => {
        window.open(pdfSrc)
    }

    const swiperContentBody = useMemo(() => {
        let newArray: JSX.Element[] = []
        imagesMap.forEach((photo) => {
            newArray = [...newArray,
                <div className='mobile-viewer__body__content-wrapper'
                    key={`${photo.id}_${photo.src}`}
                >
                    {photo.deletable && (selectedImages.includes(photo.id) ?
                        <FaCheck
                            className='mobile-viewer__select_checked'
                            onClick={() => {
                                setSelectedImages((prev) => {
                                    const newArr = [...prev]
                                    const photoIndex = newArr.findIndex((item) => item === photo.id)
                                    if (photoIndex > -1) {
                                        newArr.splice(photoIndex, 1)
                                    }
                                    return newArr
                                })
                            }}
                        /> :
                        <FaRegCircle
                            className='mobile-viewer__select'
                            onClick={() => {
                                setSelectedImages((prev) => [...prev, photo.id])
                            }}
                        />)}
                    <img
                        className={clsx(
                            'mobile-viewer__swiper__content__image',
                            { 'mobile-viewer__swiper__content__image_selected': photo.fileIndex === currentImage.fileIndex },
                        )}
                        src={photo.src}
                        alt='photo'
                        loading='lazy'
                        onClick={() => setCurrentImage(photo)}
                    />
                </div>,
            ]
        })

        return newArray
    }, [imagesCount, selectedImages, currentImage])

    useEffect(() => {
        /** preventing memory leaks */
        let isMounted = true
        if (type === 'pdf') {
            fetchFileModel({ fileId: photoId }, true).unwrap().then((fileModel: any) => {
                if (isMounted) {
                    setFileName(fileModel.fileInfo.fileName)
                }
                fetchFile(photoId).then((res) => {
                    const fileType = DocumentManager.getDocumentType(fileModel.fileInfo.fileName)
                    const format = DocumentManager.getDocumentFormat(fileModel.fileInfo.fileName)
                    const file = new File([res], fileModel.fileInfo.fileName || 'unnamed', { type: fileModel.fileInfo.fileType || fileType + '/' + format })
                    const url = URL.createObjectURL(file)
                    if (isMounted) {
                        setPdfSrc(url)
                        setLoading(false)
                    }
                })
            })
        } else {
            files?.map((currentFile: any, index: number) => {
                if (!imagesMap.get(currentFile.id)) {
                    fetchFileModel({ fileId: currentFile.id }, true).unwrap().then((fileModel: any) => {
                        const fileId = currentFile.id
                        const fileType = DocumentManager.getDocumentFormat(fileModel.fileInfo.fileName)
                        if (isFileImage(fileType)) {
                            fetchFile(fileId).then((res) => {
                                const fileType = DocumentManager.getDocumentType(fileModel.fileInfo.fileName)
                                const format = DocumentManager.getDocumentFormat(fileModel.fileInfo.fileName)
                                const file = new File([res], fileModel.fileInfo.fileName || 'unnamed', { type: fileModel.fileInfo.fileType || fileType + '/' + format })
                                const url = URL.createObjectURL(file)
                                const fileRules = fetchFileRules({
                                    fileModel,
                                    user,
                                    currentStage,
                                    inputName: currentFile.name,
                                })
                                if (isMounted) {
                                    setImagesMap((prev) => {
                                        prev.set(fileId, {
                                            id: fileId,
                                            src: url,
                                            deletable: fileRules.delete,
                                            fileIndex: index,
                                        })
                                        return new Map([...prev.entries()].sort((a, b) => a[1].fileIndex - b[1].fileIndex))
                                    })
                                    setImagesCount((prev) => prev + 1)
                                }
                            })
                        }
                    })
                }
            })
        }
        return () => {
            /** when the component unmounts, set isMounted to false */
            isMounted = false
        }
    }, [type])

    useEffect(() => {
        if (currentImage.id === '' && imagesMap.size > 0) {
            setCurrentImage(imagesMap.values().next().value as PhotoType)
        } else if (imagesMap.get(currentImage.id)) {
            setCurrentImage(imagesMap.get(currentImage.id) || {} as PhotoType)
        }
    }, [imagesCount])

    useEffect(() => {
        if (files && checkFilesInputName(files)) {
            setBody(<p className='mobile-viewer__body__img_error'>Нет изображений</p>)
        }
    }, [])

    useEffect(() => {
        if (imagesMap.get(currentImage.id)) {
            setBody(
                <img
                    className='mobile-viewer__body__img'
                    src={imagesMap.get(currentImage.id)?.src}
                    alt='photo'
                    loading='lazy'
                    onLoad={() => {
                        setLoading(false)
                    }}
                />,
            )
        }
    }, [currentImage, imagesCount])

    return (
        <Portal>
            {type === 'image' ? (
                <div className='mobile-viewer'>
                    <div className='mobile-viewer__header'>
                        <button
                            type='button'
                            className='mobile-viewer__header__close-btn'
                            onClick={handleClose}
                        >
                            Отмена
                        </button>
                        <p className='mobile-viewer__header__title'>Окно предпросмотра</p>
                        <button
                            type='button'
                            className={clsx(
                                'mobile-viewer__header__delete-btn',
                                { 'mobile-viewer__header__delete-btn_disabled': selectedImages.length === 0 },
                            )}
                            onClick={handleDelete}
                        >
                            Удалить
                        </button>
                    </div>
                    <div className='mobile-viewer__body'>
                        <div className='mobile-viewer__body__content'>
                            {currentImage.deletable && imagesMap.size > 0 && !isLoading && (selectedImages.includes(currentImage.id) ?
                                <FaCheck
                                    className='mobile-viewer__select_checked'
                                    onClick={() => {
                                        setSelectedImages((prev) => {
                                            const newArr = [...prev]
                                            const photoIndex = newArr.findIndex((item) => item === currentImage.id)
                                            if (photoIndex > -1) {
                                                newArr.splice(photoIndex, 1)
                                            }
                                            return newArr
                                        })
                                    }}
                                /> :
                                <FaRegCircle
                                    className='mobile-viewer__select'
                                    onClick={() => {
                                        setSelectedImages((prev) => [...prev, currentImage.id])
                                    }}
                                />)}
                            {body}
                        </div>
                    </div>
                    <div
                        className={clsx(
                            'mobile-viewer__swiper',
                            { 'mobile-viewer__swiper_swiped': isSwiped },
                        )}
                        ref={swipeableRef}
                    >
                        <div className='mobile-viewer__swiper__touch'
                            onClick={() => setSwiped((prev) => !prev)}
                        >
                            <hr />
                        </div>
                        <div className='mobile-viewer__swiper__content' onTouchStart={(event) => {
                        }}>
                            {swiperContentBody}
                        </div>
                    </div>
                </div>
            ) : (
                <div className='mobile-viewer'>
                    <div className='mobile-viewer__header__pdf'>
                        <BackButton callback={handleClose} />
                        <p
                            className='mobile-viewer__header__pdf__title'
                        >
                            {fileName === '' ? 'Загрузка...' : fileName}
                        </p>
                    </div>
                    <div className='mobile-viewer__body__pdf'>
                        <div className='mobile-viewer__body__pdf__content'>
                            {isLoading ? <Preloader withoutBackground={true} /> :
                            <div className='mobile-viewer__pdf-download'>
                                <span>Файл недоступен для</span>
                                <span>просмотра в этом окне.</span>
                                <span>Открыть в новой вкладке?</span>
                                <button
                                    type='button'
                                    className='mobile-viewer__pdf-download__button'
                                    onClick={handleOpenFile}
                                >
                                    Открыть
                                </button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            )}
        </Portal>
    )
})
