import { DocType, FetchFileModelParams, FileResponse, UploadFileParams } from '~/API/fileApi/file.types'
import { instance } from '~/API/lib/axios/axios'
import { fetchPath } from '~/API/lib/path/path'
import { rtkApi } from '~/shared/api/rtkApi'

const path = fetchPath('file')

const fileApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchFileModel: build.query<FileResponse, FetchFileModelParams>({
            query: ({
                fileId,
            }) => ({
                method: 'GET',
                url: path + '/' + fileId + '/model',
            }),
            providesTags: ['File'],
        }),
        uploadFile: build.mutation<FileResponse, UploadFileParams>({
            query: ({
                file,
                fileModel,
            }) => {
                const formData = new FormData()
                formData.append('file', file)
                formData.append('fileModel', JSON.stringify(fileModel))

                return {
                    method: 'POST',
                    url: path + '/upload',
                    body: formData,
                }
            },
            // invalidatesTags: ['Salary']
        }),
    }),
})

export const {
    useFetchFileModelQuery,
    useLazyFetchFileModelQuery,
    useUploadFileMutation,
} = fileApi

// export const uploadFile = async (file: any, fileModel: any) => {
//     try {
//         const formData = new FormData()
//         formData.append('file', file)
//         formData.append('fileModel', JSON.stringify(fileModel))
//
//         return await instance({
//             method: 'post',
//             url: path + 'upload',
//             headers: { 'Content-Type': 'multipart/form-data' },
//             data: formData,
//         }).then((response) => {
//             return response.data
//         })
//     } catch (e) {
//         console.log(e)
//     }
// }

/** blob files is too heavy for cache, don't move it in rtkq */
export const fetchFile = async (id: string) =>
    await instance({
        method: 'get',
        url: path + '/' + id,
        responseType: 'blob',
    }).then((response) => {
        return response.data
    })

/** below func is for class like DocumentManager, maybe it's worth rewriting it in hooks */
export const fetchFileModel = async (fileId: string) =>
    await instance({
        method: 'get',
        url: path + '/' + fileId + '/model',
    }).then((response) => {
        return response.data
    })

export const editFileWithHtml = async (fileId: string, html: any, fileModel: any, docType?: DocType) => {
    const formData = new FormData()
    formData.append('fileId', fileId)
    formData.append('html', html)
    formData.append('fileModel', JSON.stringify(fileModel))
    formData.append('docType', docType || 'PDF')
    return await instance({
        method: 'post',
        url: path + '/' + fileId + '/editWithHtml',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    }).then((response) => {
        return response
    })
}

export const generatePdf = async (html: any) => {
    const formData = new FormData()
    formData.set('html', html)
    return await instance({
        method: 'post',
        url: path + '/generate',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
        responseType: 'blob',
    }).then((response) => {
        return response.data
    })
}

export const fetchDocumentSetting = async ({ documentType, businessType }: {
    documentType: any,
    businessType: any
}) => {
    return await instance({
        method: 'get',
        url: path + '/setting',
        params: { documentType, businessType },
    }).then((response) => {
        return response.data
    })
}

/** use in settings page (don't know for what) and in class DocumentManager */
export const uploadFileWithHtml = async (html: any, fileModel: any, docType?: DocType) => {
    const formData = new FormData()
    formData.append('html', html)
    formData.append('fileModel', JSON.stringify(fileModel))
    formData.append('docType', docType || 'PDF')

    return await instance({
        method: 'post',
        url: path + '/uploadWithHtml',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    }).then((response) => {
        return response.data
    })
}

// export const editFile = async (fileId: string, file: any, fileModel: any) => {
//     const formData = new FormData()
//     formData.append('fileId', fileId)
//     formData.append('file', file)
//     formData.append('fileModel', JSON.stringify(fileModel))
//     return await instance({
//         method: 'post',
//         url: path + fileId + '/edit',
//         headers: { 'Content-Type': 'multipart/form-data' },
//         data: formData,
//     }).then((response) => {
//         return response
//     })
// }

export const deleteFile = async (fileId: string) => {
    const formData = new FormData()
    formData.append('fileId', fileId)

    return await instance({
        method: 'post',
        url: path + '/' + fileId + '/delete',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    }).then((response) => {
        return response
    })
}
