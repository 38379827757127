import { CustomInputIcons, InputMask } from '~/components/common/inputs/CustomInput/CustomInput'

export const foremanWorkStarting = {
    division: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Подразделение',
        name: 'division',
        className: 'input100',
        type: 'select',
        items: [
            { value: 'B2C', text: 'B2C' },
            { value: 'B2B (ПРР)', text: 'B2B (ПРР)' },
        ],
    }),
    foreman: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Менеджер по клинингу',
        name: 'foreman',
        type: 'user',
        role: ['FOREMAN', 'ORDER_MANAGER'],
    }),
    reportDate: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Дата отчета',
        name: 'reportDate',
        className: 'input33',
        singleDate: true,
        type: 'date',
    }),
    addContractPhoto: (disabled: boolean) => ({
        disabled: disabled,
        name: 'addContractPhoto',
        title: 'Фото договора доп.услуг',
        type: 'document',
        button: 'Загрузить фото',
        fileType: 'jpg',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image'],
    }),
    contactInfo: (disabled: boolean) => ({
        disabled: disabled,
        name: 'contactInfo',
        type: 'fieldArray',
        items: [
            {
                disabled: disabled,
                title: 'Имя',
                name: 'clientFullname',
                className: 'input50',
                type: 'text',
                required: true,
            },
            {
                disabled: disabled,
                title: 'Телефон',
                name: 'clientPhone',
                className: 'input50',
                type: 'text',
                mask: InputMask.PHONE_NUMBER,
                required: true,
                maxLength: 18,
                icon: CustomInputIcons.PHONE,
            },
        ],
    }),
    cleaningTask: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Задача (ЦУ для выполнения работ)',
        name: 'cleaningTask',
        className: 'input100',
        type: 'text',
        multiline: true,
    }),
    clientConfirmationInfo: (disabled: boolean, controlled: boolean) => ({
        disabled: !controlled,
        type: 'fieldArray',
        name: 'clientConfirmationInfo',
        title: '',
        disableMode: true,
        items: [
            {
                disabled: true,
                title: 'Дата звонка',
                name: 'date',
                className: 'input50',
                type: 'date',
                singleDate: true,
                required: true,
            },
            {
                disabled: disabled,
                title: 'Результат',
                name: 'result',
                className: 'input50',
                type: 'text',
                required: true,
            },
        ],
    }),
    contractPhoto: (disabled: boolean) => ({
        disabled: disabled,
        name: 'contractPhoto',
        title: 'Фото подписанного договора',
        type: 'document',
        button: 'Загрузить фото',
        fileType: 'jpg',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image'],
    }),
    additionalServicesPhotos: (disabled: boolean) => ({
        disabled: disabled,
        name: 'additionalServicesPhotos',
        title: 'Контент загрязнений',
        max: 150,
        type: 'documentList',
        button: 'Загрузить фото',
        required: true,
        allowedFileTypes: ['image', 'video'],
    }),
}
