import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'

export const additionalInputs = {
    clientInfo: (disabled: boolean, clientType?: ClientInfoEnum) => ({
        disabled: disabled,
        title: '',
        name: 'clientInfo',
        className: 'thin-content',
        clientType,
        required: false,
        type: 'clientInfo',
        version: 'v2',
    }),
    division: (disabled: boolean, usingData?: string) => ({
        disabled: disabled,
        title: 'Подразделение',
        name: 'division',
        type: 'select',
        usingData,
        items: [],
        preset: 'division',
        version: 'v2',
    }),
    reportDate: (disabled: boolean, name?: string) => ({
        disabled: disabled,
        title: 'Дата закрытия этапа',
        name: name || 'reportDate',
        singleDate: true,
        type: 'date',
        version: 'v2',
    }),
    responsibleUserId: (disabled: boolean, usingDivision: string, name: string, usingData?: string) => ({
        disabled: disabled,
        title: 'Ответственный',
        name,
        type: 'user',
        usingDivision,
        usingData,
        role: [
            'CHIEF',
            'ORDER_MANAGER',
        ],
        version: 'v2',
    }),
    address: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        title: 'Адрес',
        name: 'address',
        usingData,
        type: 'address',
        version: 'v2',
    }),
    nzTask: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        name: 'nzTask',
        title: 'Наряд заказ',
        type: 'document',
        usingData,
        caption: 'Заполнить наряд заказ',
        version: 'v2',
    }),
    nzBack: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        name: 'nzBack',
        title: 'Наряд заказ (результат)',
        type: 'document',
        usingData,
        caption: 'Загрузить документ',
        version: 'v2',
    }),
    nzFull: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        name: 'nzFull',
        title: 'Наряд заказ (результат)',
        type: 'document',
        usingData,
        caption: 'Загрузить документ',
        version: 'v2',
    }),
    mistakesFiles: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        name: 'mistakesFiles',
        title: 'Контент доработок',
        max: 15,
        type: 'documentList',
        button: 'Загрузить фото',
        usingData,
        fileType: 'jpg',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image', 'video', 'application'],
        version: 'v2',
    }),
    workInterimProcess: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        name: 'workProcess',
        title: 'Фото промежуточного акта',
        max: 150,
        type: 'documentList',
        usingData,
        button: 'Загрузить фото',
        required: false,
        allowedFileTypes: ['image', 'video', 'application'],
        version: 'v2',
    }),
    actualPayment: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        title: 'Фактическая сумма оплаты',
        name: 'actualPayment',
        type: 'text',
        usingData,
        mask: InputMask.COST,
        version: 'v2',
    }),
    actualPostPayment: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        title: 'Фактическая сумма постоплаты',
        name: 'actualPayment',
        type: 'text',
        usingData,
        mask: InputMask.COST,
        version: 'v2',
    }),
    paymentPhoto: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        name: 'paymentPhoto',
        title: 'Платежное поручение',
        className: 'thin-content',
        type: 'document',
        button: 'Добавить файл',
        usingData,
        required: true,
        allowedFileTypes: ['application', 'image'],
        version: 'v2',
    }),
    additionalExpanses: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Доп. расходы',
        name: 'additionalExpanses',
        type: 'text',
        required: true,
        mask: InputMask.COST,
    }),
    qualityReminder: () => ({
        version: 'v2',
        disabled: true,
        required: false,
        title: '',
        name: 'qualityReminder',
        className: 'thin-content',
        type: 'qualityReminder',
    }),
    nzFrontPhoto: (disabled: boolean, usingData: string) => ({
        version: 'v2',
        disabled,
        name: 'nzFrontPhoto',
        title: 'Наряд заказ(задача)',
        type: 'document',
        usingData,
        button: 'Загрузить фото',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image', 'application'],
    }),
    nzBackPhoto: (disabled: boolean, usingData: string) => ({
        version: 'v2',
        usingData,
        disabled,
        name: 'nzBackPhoto',
        title: 'Наряд заказ(результат)',
        type: 'document',
        button: 'Загрузить фото',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image', 'application'],
    }),
    workBeginning: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        name: 'workBeginning',
        title: 'Начало работ',
        type: 'document',
        button: 'Загрузить фото',
        usingData,
        fileType: 'jpg',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image'],
        version: 'v2',
    }),
    workEnding: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        name: 'workEnding',
        title: 'Окончание работ',
        type: 'document',
        button: 'Загрузить фото',
        usingData,
        fileType: 'jpg',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image'],
        version: 'v2',
    }),
    workProcess: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        name: 'workProcess',
        title: 'Контент с объекта',
        max: 150,
        type: 'documentList',
        button: 'Загрузить фото',
        usingData,
        required: true,
        allowedFileTypes: ['image', 'video'],
        version: 'v2',
    }),
}
