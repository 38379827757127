import { DaDataParty } from 'react-dadata'
import { fetchPath } from '~/API/lib/path/path'
import { DaDataSuggestionValues } from '~/components/common/inputs/CustomOrganizationInput/CustomOrganizationInput'
import { rtkApi } from '~/shared/api/rtkApi'

const path = fetchPath('organization')

const organizationApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAllOrganization: build.query<DaDataSuggestionValues<DaDataParty>[], undefined>({
            query: () => ({
                method: 'GET',
                url: path + '/all',
            }),
            providesTags: ['Organization'],
        }),
        fetchOrganizationByInn: build.query<DaDataSuggestionValues<DaDataParty>, string>({
            query: (inn) => ({
                method: 'GET',
                url: path + '/' + inn,
            }),
            providesTags: ['Organization'],
        }),
        // todo make types
        saveOrganization: build.mutation<any, any>({
            query: (organization) => ({
                method: 'POST',
                url: path + '/create',
                body: organization,
            }),
            invalidatesTags: ['Organization'],
        }),
    }),
})

export const {
    useLazyFetchOrganizationByInnQuery,
    useLazyFetchAllOrganizationQuery,
    useSaveOrganizationMutation,
} = organizationApi


