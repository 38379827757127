import { OrderInterface } from '~/API/orderApi/types/order.types'
import {
    SectionDocument,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTablePage/subComponents/TabsTableCell/subComponents/TabsTableFileInput/TabsTableFileInput'

enum TabsTableValidationType {
    TOOLS_REPORT = 'toolsReport',
    NOT_NULL = 'notNull',
    SOME_SERVICES_STATUS = 'someServicesStatus',
}

type TabsTableValidationMap = Record<TabsTableValidationType, (value: any, formValues: any) => string | boolean>

export const getTabsTableValidation = (validationType: TabsTableValidationType, sectionName: string, rowIndex: number) => {
    const validationMap: TabsTableValidationMap = {
        toolsReport: (value, formValues): boolean => {
            const toolRow = formValues.toolsReport.actualAmortization.find((section: any) => section.type === sectionName).items.find((_: any, index: number) => index === rowIndex)

            if (toolRow) {
                if (toolRow.in >= toolRow.amount) {
                    return true
                } else {
                    return !!formValues.documents.toolsReport?.actualAmortization?.[sectionName]?.find((document: SectionDocument) => document?.index === rowIndex)
                }
            }

            return true
        },
        notNull: (value, formValues): boolean => {
            return value != null
        },
        someServicesStatus: (value, formValues): boolean => {
            const services = (formValues as OrderInterface).foremanReport.actualServices
            return services.some((service) => service.items.some((item) => item.status))
        },
    }

    return validationMap[validationType]
}
