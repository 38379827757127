import { defineNoteFontSize } from './docUtil'
import { fetchDate } from '../../util/currentTime'
import { fetchUserById } from '~/API/userApi/user'
import { formatRUB } from '~/shared/util/formatNumberWithIntlOptions'

export const nzBackMultipleDay = async (values) => {
    const html = await fetch('/html/nz_back_multiple_days.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    const domHtml = parser.parseFromString(htmlText, 'text/html')

    domHtml.querySelector('#actualCostCard').innerHTML = formatRUB((values.daysReport.at(-1).actualCostCard || 0), false)
    domHtml.querySelector('#actualCostCash').innerHTML = formatRUB((values.daysReport.at(-1).actualCostCash || 0), false)
    domHtml.querySelector('#actualPrepaymentCard').innerHTML = formatRUB((values.daysReport[0].actualPrepaymentCard || 0), false)
    domHtml.querySelector('#actualPrepaymentCash').innerHTML = formatRUB((values.daysReport[0].actualPrepaymentCash || 0), false)
    domHtml.querySelector('#additionalExpanses').innerHTML =
        formatRUB(values.daysReport.reduce((accum, day) => accum + Number(day.additionalExpanses), 0), false)
    domHtml.querySelector('#actualObjectArea').innerHTML =
        values.daysReport.reduce((accum, day) => accum + Number(day.actualObjectArea), 0) + ' м<sup>2</sup>'
    domHtml.querySelector('#actualDurationCleaningTime').innerHTML =
        values.daysReport.reduce((accum, day) => accum + Number(day.actualDurationCleaningTime), 0) + ' ч.'
    domHtml.querySelector('#actualEmployeesSalary').innerHTML =
        formatRUB(values.daysReport.reduce((accum, day) => accum + Number(day.actualEmployeesSalary), 0), false)
    domHtml.querySelector('#actualConsumables').innerHTML = formatRUB(values.actualConsumables, false)
    domHtml.querySelector('#actualEquipment').innerHTML = formatRUB(values.actualEquipment, false)
    domHtml.querySelector('#actualExpansesTaxiThere').innerHTML =
        formatRUB(values.daysReport.reduce((accum, day) => accum + Number(day.actualExpansesTaxiThere), 0), false)
    domHtml.querySelector('#actualExpansesPetrol').innerHTML =
        formatRUB(values.daysReport.reduce((accum, day) => accum + Number(day.actualExpansesPetrol), 0), false)
    domHtml.querySelector('#actualParking').innerHTML =
        formatRUB(values.daysReport.reduce((accum, day) => accum + Number(day.actualParking), 0), false)
    domHtml.querySelector('#actualExpansesTaxiBack').innerHTML =
        formatRUB(values.daysReport.reduce((accum, day) => accum + Number(day.actualExpansesTaxiBack), 0), false)
    domHtml.querySelector('#actualRentTMC').innerHTML =
        formatRUB(values.daysReport.reduce((accum, day) => accum + Number(day.actualRentTMC), 0), false)
    domHtml.querySelector('#actualContractWork').innerHTML =
        formatRUB(values.daysReport.reduce((accum, day) => accum + Number(day.actualContractWork), 0), false)
    domHtml.querySelector('#dateTakingOver').innerHTML = fetchDate(values.onWarehouseDate).format('DD.MM.YY HH:mm')
    domHtml.querySelector('#foremanComment').innerHTML = values.daysReport.reduce(
        (accum, day) => accum + fetchDate(day.cleaningDate).format('DD.MM: ') + day.foremanComment + '\n',
        '',
    )
    domHtml.querySelector('#toolsComment').innerHTML = values.toolsComment

    if (values.orderManager) {
        const orderManager = await fetchUserById(values.orderManager)
        domHtml.querySelectorAll('.manager').forEach((e) => e.innerHTML = orderManager.fullname)
    } else {
        domHtml.querySelector('#managerChecking').remove()
    }

    const lastDayForeman = await fetchUserById(values.daysReport.at(-1).foreman)
    const currentDate = fetchDate().format('DD.MM.YY')
    domHtml.querySelectorAll('.currentDate').forEach((e) => e.innerHTML = currentDate)
    domHtml.querySelectorAll('.lastDayForeman').forEach((e) => e.innerHTML = lastDayForeman.fullname)

    const sumTeam = values.daysReport.map((day) => {
        return day?.actualTeam?.reduce(
            (accum, e) => ({
                ...day,
                cleaningDate: day.cleaningDate,
                count: accum.count + (e.jobPosition.includes('Бригадир') || e.status === 'NOT_WORKING' ? 0 : 1),
                salary:
                    accum.salary +
                    (e.status === 'NOT_WORKING' ? 0 : parseInt(e.salary || 0) + parseInt(e.addSalary || 0)),
                addSalary: accum.addSalary + (e.status === 'NOT_WORKING' ? 0 : parseInt(e.addSalary || 0)),
                taxi: parseInt(day.actualExpansesTaxiBack) + parseInt(day.actualExpansesTaxiThere),
                petrol: day.actualExpansesPetrol,
                parking: day.actualParking,
                personnel: day.actualTeam,
            }),
            { count: 0, salary: 0, addSalary: 0 },
        )
    })

    const uniquePersonnel = new Set()
    values.daysReport.forEach((day) => {
        day.actualTeam.forEach((p) => {
            if (p.jobPosition !== 'Бригадир' && p.status === 'WORKING') {
                uniquePersonnel.add(p.personnelId)
            }
        })
    })
    domHtml.querySelector('#actualCleanerNumber').innerHTML = uniquePersonnel.size

    const teamTbody = sumTeam.map(
        (day, index) => `<tr>
            <td>${index + 1}</td>
            <td>${fetchDate(day.cleaningDate).format('DD.MM.YY')}</td>
            <td>${day.count}</td>
            <td>${formatRUB(day.salary, false)}</td>
            <td>${formatRUB(day.addSalary, false)}</td>
            <td>${formatRUB(day.taxi, false)}</td>
            <td>${formatRUB(day.petrol, false)}</td>
            <td>${formatRUB(day.parking, false)}</td>
            <td>${day.personnel.find((e) => e.jobPosition === 'Бригадир')?.name || ''}</td>
    </tr>`,
    )

    domHtml.querySelector('#actualTeam').innerHTML = teamTbody.join('')
    domHtml.querySelector('#actualTeam').style.fontSize = '12px'

    const tableTeamTemplate = domHtml.querySelector('#teamTemplate')
    const teamPages = document.createElement('div')
    const constructTable = (day, index) => {
        const table = tableTeamTemplate.cloneNode(true)
        table.querySelector('#dayDate').innerHTML = `${fetchDate(day?.cleaningDate).format('DD MMMM')}`
        table.querySelector('#teamObjectArea').innerHTML = day.actualObjectArea
        table.querySelector('#teamDurationCleaningTime').innerHTML = day.actualDurationCleaningTime
        table.querySelector('#teamCleanerNumber').innerHTML = day.count
        table.querySelector('#teamEmployeesSalary').innerHTML = day.salary
        table.querySelector('#teamTaxi').innerHTML = day.taxi
        table.querySelector('#teamPetrol').innerHTML = day.petrol
        table.querySelector('#teamParking').innerHTML = day.parking

        const fontSizeTeam = day.actualTeam.length > 8 ? 10 : 12
        const teamTbody = day.actualTeam.map(
            (p, index) => `<tr>
             <td>${index + 1}</td>
             <td style=${p.status === 'NOT_WORKING' ? 'text-decoration:line-through' : ''}>${p.name}</td>
             <td>${p.jobPosition}</td>
             <td>${formatRUB(p.salary, false)}</td>
             <td>${formatRUB(p.addSalary || 0, false)}</td>
             <td>${p.rate}</td>
             <td style='font-size:${Math.min(defineNoteFontSize(p.comment), fontSizeTeam)}px'>${p.comment}</td>
         </tr>`,
        )
        table.querySelector('#dayTeam').innerHTML = teamTbody.join('')
        table.style.fontSize = fontSizeTeam + 'px'
        return table
    }
    const a = sumTeam.reduce(
        (accum, d, i) => {
            const p = i % 4
            if (p === 0) {
                const newPage = document.createElement('div')
                newPage.classList.add('page')
                const leftSide = document.createElement('div')
                leftSide.classList.add('page__leftSide')
                const table = constructTable(d, i)
                leftSide.appendChild(table)
                if (i === sumTeam.length - 1) {
                    newPage.appendChild(leftSide)
                    accum.result.appendChild(newPage)
                }
                return { result: accum.result, newPage, leftSide }
            }
            if (p === 1) {
                const table = constructTable(d, i)
                const { newPage, leftSide } = accum
                leftSide.appendChild(table)
                newPage.appendChild(leftSide)
                if (i === sumTeam.length - 1) {
                    accum.result.appendChild(newPage)
                }
                return { result: accum.result, newPage }
            }
            if (p === 2) {
                const rightSide = document.createElement('div')
                rightSide.classList.add('page__rightSide')
                const table = constructTable(d, i)
                rightSide.appendChild(table)
                if (i === sumTeam.length - 1) {
                    accum.newPage.appendChild(rightSide)
                    accum.result.appendChild(accum.newPage)
                }
                return { result: accum.result, newPage: accum.newPage, rightSide }
            }
            if (p === 3) {
                const table = constructTable(d, i)
                const { newPage, rightSide } = accum
                rightSide.appendChild(table)
                newPage.appendChild(rightSide)
                accum.result.appendChild(newPage)
                return { result: accum.result }
            }
            return accum
        },
        { result: teamPages },
    )

    domHtml.querySelector('#teamTableWrapper').innerHTML = ''
    domHtml.querySelector('#teamTableWrapper').appendChild(a.result)

    let allDoc = domHtml.querySelector('body').innerHTML

    if (values.documentsReturn?.includes('Договор')) {
        allDoc = allDoc.replace('id="documents_contract"', 'id="documents_contract" checked="true"')
    }
    if (values.documentsReturn?.includes('Акт(УПД)')) {
        allDoc = allDoc.replace('id="documents_UPD"', 'id="documents_UPD" checked="true"')
    }
    if (values.documentsReturn?.includes('Опись')) {
        allDoc = allDoc.replace('id="documents_register"', 'id="documents_register" checked="true"')
    }
    if (values.documentsReturn?.includes('ЭДО')) {
        allDoc = allDoc.replace('id="documents_edm"', 'id="documents_edm" checked="true"')
    }
    return { style: domHtml.querySelector('style').innerHTML, body: allDoc }
}
