import { RootState } from '../store'

export const selectUser = (state: RootState) => state.app.user
export const selectUserLessons = (state: RootState) => state.app.user?.lessonStatuses
export const selectToken = (state: RootState) => state.app.token
export const selectButtonAction = (state: RootState) => state.app.buttonAction
export const selectFormAction = (state: RootState) => state.app.formAction
export const selectFormComment = (state: RootState) => state.app.formComment
export const SELECT_APP_LOADER = (state: RootState) => state.app.appLoader
export const SELECT_SIDEBAR_STATUS = (state: RootState) => state.app.sidebarStatus
export const SELECT_BODY_HIDDEN = (state: RootState) => state.app.bodyHidden
export const SELECT_LIGHTBOX = (state: RootState) => state.app.fslightboxController
export const SELECT_SCHEDULE_PERSONNEL = (state: RootState) => state.app.schedulePersonnel
export const selectHeaderToolbar = (state: RootState) => state.app.headerToolbar
export const SELECT_UNIQUE_SCHEDULE_VALUES = (state: RootState) => state.app.uniqueScheduleValues
export const SELECT_SKIP_PAGINATION = (state: RootState) => state.app.skipPagination
export const selectAgendaLoading = (state: RootState) => state.app.agendaLoading
export const selectIsPageError = (state: RootState) => state.app.isPageError
