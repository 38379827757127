import ExpandMore from '@mui/icons-material/ExpandMore'
import clsx from 'clsx'
import React, { memo, useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { FaCalendarDays } from 'react-icons/fa6'
import PopupDatePicker from '../../popups/PopupDatePicker/PopupDatePicker'
import { CustomSelectThemes } from '~/components/common/inputs/CustomSelect/CustomSelect'
import { fetchDate } from '~/shared/util/currentTime'
import '../CustomField.scss'
import './CustomDateInput.scss'

export enum CustomDateInputModes {
    DATE_TIME = 'date-time',
    TIME = 'time',
    DATE = 'date',
}

interface CustomDateInputProps {
    title?: string,
    value: any,
    onChange: (values: any) => void,
    disabled?: boolean,
    singleDate: boolean,
    cleaningDate: boolean,
    className?: string,
    error?: FieldError,
    division?: string,
    mode?: CustomDateInputModes
    theme?: CustomSelectThemes
    withTooltip?: boolean
    displayFormat?: string
}

const CustomDateInput = memo((props: CustomDateInputProps) => {
    const {
        title,
        value,
        onChange,
        disabled,
        singleDate,
        cleaningDate,
        className,
        error,
        division,
        mode = CustomDateInputModes.DATE_TIME,
        theme = CustomSelectThemes.OUTLINE,
        withTooltip,
        displayFormat,
    } = props
    const [popup, setPopup] = useState(false)
    const [valueToDisplay, setValueToDisplay] = useState('')

    const handleChange = (values: any) => {
        onChange(values)
    }

    useEffect(() => {
        if (value) {
            switch (mode) {
                case CustomDateInputModes.DATE_TIME: {
                    if (value.startDate) {
                        let newValue = fetchDate(value.startDate).format('D.MM.YYYY • HH:mm')
                        if (value.endDate) {
                            newValue += ' - ' + fetchDate(value.endDate).format('D.MM.YYYY • HH:mm')
                        }
                        setValueToDisplay(newValue)
                        break
                    }
                    if (value.endDate) {
                        setValueToDisplay(fetchDate(value.endDate).format('D.MM.YYYY • HH:mm'))
                        break
                    }
                    if (value) {
                        setValueToDisplay(fetchDate(value.endDate || value).format('D.MM.YYYY • HH:mm'))
                        break
                    }
                    break
                }
                case CustomDateInputModes.TIME: {
                    if (value.startDate) {
                        let newValue = fetchDate(value.startDate).format('HH:mm')
                        if (value.endDate) {
                            newValue += ' - ' + fetchDate(value.endDate).format('HH:mm')
                        }
                        setValueToDisplay(newValue)
                        break
                    }
                    if (value.endDate) {
                        setValueToDisplay(fetchDate(value.endDate).format('HH:mm'))
                        break
                    }
                    if (value) {
                        setValueToDisplay(fetchDate(value.endDate || value).format('HH:mm'))
                        break
                    }
                    break
                }
                case CustomDateInputModes.DATE: {
                    if (value.startDate) {
                        let newValue = fetchDate(value.startDate).format(displayFormat || 'D.MM.YYYY')
                        if (value.endDate) {
                            newValue += ' - ' + fetchDate(value.endDate).format(displayFormat || 'D.MM.YYYY')
                        }
                        setValueToDisplay(newValue)
                        break
                    }
                    if (value.endDate) {
                        setValueToDisplay(fetchDate(value.endDate).format(displayFormat || 'D.MM.YYYY'))
                        break
                    }
                    if (value) {
                        setValueToDisplay(fetchDate(value.endDate || value).format(displayFormat || 'D.MM.YYYY'))
                        break
                    }
                    break
                }
            }
        } else {
            setValueToDisplay('')
        }
    }, [value])

    return (
        <div className={clsx('customField', 'customDateInput', className, { error, disabled })}>
            {title && <span>{title}</span>}
            {disabled ? (
                <div className='customField__input'>
                    <input type='text' value={valueToDisplay} disabled={true} />
                </div>
            ) : (
                <div
                    className={clsx('customField__select', theme && `customField__select--${theme}`)}
                    onClick={() => {
                        setPopup(true)
                    }}
                >
                    <FaCalendarDays className='customDateInput__icon' />
                    {valueToDisplay !== '' ? (
                        <span>{valueToDisplay}</span>
                    ) : (
                        <span className='customField__placeholder'>Выберите дату</span>
                    )}
                    <ExpandMore className='customField__expand' />
                </div>
            )}
            {popup && (
                <PopupDatePicker
                    onClose={() => setPopup(false)}
                    onSubmit={handleChange}
                    singleDate={singleDate}
                    defaultValues={value}
                    cleaningDate={cleaningDate}
                    division={division}
                    withTooltip={withTooltip}
                />
            )}
        </div>
    )
})

export default CustomDateInput
