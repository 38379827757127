import { Dayjs } from 'dayjs'
import { ForemanReportType } from '~/API/orderApi/types/foremanReport.type'
import { CustomCheckboxTheme } from '~/components/common/checkbox/CustomCheckbox/CustomCheckbox'
import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import {
    CheckTypes,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableHeader/subComponents/TabsTableCheckIcon/TabsTableCheckIcon'
import { MobileComponentKeys } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { rules } from '~/shared/order/rules'
import { StageManager } from '~/shared/order/StageManager'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { additionalInputs } from '~/shared/templates/inputTemplates/v2/additionalInputs'
import { buildStageTransitionInput } from '~/shared/templates/inputTemplates/v2/lib/helpers'
import { fetchDate } from '~/shared/util/currentTime'

export const WORKING_DAY_REPORT_ACTIONS = {
    mainActions: (notFirstDay: boolean) => {
        const items = [
            {
                value: 'SERVICE_TABLE_FILLING',
                text: 'Начало работ',
                icon: 'forward',
            },
        ]
        if (notFirstDay) {
            items.push({
                value: 'NEED_CHANGES_SERVICE_TABLE_FILLING',
                text: 'Вернуться к заполнению объекта',
                icon: 'back',
            })
        }
        return buildStageTransitionInput(items)
    },
    managerActions: (notFirstDay: boolean) => {
        const items = [
            {
                value: 'SERVICE_TABLE_FILLING',
                text: 'Начало работ',
                icon: 'forward',
            },
            {
                value: 'RESPONSIBLE_USER_CHANGING',
                text: 'Изменить ответственного',
                icon: 'repeat',
            },
        ]
        if (notFirstDay) {
            items.push({
                value: 'NEED_CHANGES_TO_SERVICE_TABLE_FILLING',
                text: 'Вернуться к заполнению объекта',
                icon: 'back',
            })
        }
        return buildStageTransitionInput(items)
    },
    serviceFillingActions: () => {
        return buildStageTransitionInput([
            {
                value: 'UPLOAD_CONTENT',
                text: 'Загрузить контент и выполненные задачи',
                icon: 'repeat',
            },
            {
                value: 'WORKING_DAY_ENDING',
                text: 'Перейти к завершению рабочего дня',
                icon: 'forward',
            },
        ])
    },
    dayEndedActions: () => {
        return buildStageTransitionInput([
            {
                value: 'TEAM_REPORT_FILLING',
                text: 'Перейти к заполнению бригады',
                icon: 'forward',
            },
        ])
    },
}

export const WORKING_DAY_REPORT_SECTIONS = {
    division: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Подразделение',
        name: 'division',
        className: 'input100',
        type: 'select',
        items: [
            { value: 'B2C', text: 'B2C' },
            { value: 'B2B (ПРР)', text: 'B2B (ПРР)' },
        ],
    }),
    foreman: (disabled: boolean, division?: string) => ({
        disabled: disabled,
        title: 'Менеджер по клинингу',
        name: 'foreman',
        type: 'user',
        role: ['FOREMAN', 'ORDER_MANAGER'],
        division: division,
    }),
    reportDate: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Дата отчета',
        name: 'reportDate',
        className: 'input33',
        singleDate: true,
        type: 'date',
    }),
    actualPrepaymentCash: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Выплачено предоплата нал.',
        name: 'actualPrepaymentCash',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
    }),
    actualPrepaymentCard: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Выплачено предоплата безнал.',
        name: 'actualPrepaymentCard',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
    }),
    prepaymentCheck: (disabled: boolean) => ({
        disabled: disabled,
        name: 'prepaymentCheck',
        title: 'Подтверждение предоплаты',
        type: 'document',
        button: 'Загрузить фото',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image', 'application'],
    }),
    workBeginning: (disabled: boolean) => ({
        disabled: disabled,
        name: 'workBeginning',
        title: 'Начало работ',
        type: 'document',
        button: 'Загрузить фото',
        fileType: 'jpg',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image'],
    }),
    foremanCheckList: (disabled: boolean) => ({
        version: 'v2',
        disabled: disabled,
        name: 'foremanCheckList',
        type: 'table',
        mobileComponent: MobileComponentKeys.FOREMAN_CHECK_LIST,
        title: 'Выполняемые услуги',
        hocType: 'foremanCheckList',
        withHideButton: true,
        alwaysShowHeader: true,
        checkIcons: {
            inputName: 'status',
            checkType: CheckTypes.REQUIRED,
        },
        headerSections: [
            {
                'name': 'Комната',
                'title': 'Комната',
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование работ',
                        'default': '',
                        'required': true,
                        'disabled': true,
                        'className': 'td50',
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                        'disabled': true,
                    },
                    {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'checkbox',
                        'caption': 'Выполнено',
                        'theme': CustomCheckboxTheme.TABLE,
                    },
                ],
            },
            {
                'name': 'Кухня',
                'title': 'Кухня',
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование работ',
                        'default': '',
                        'required': true,
                        'className': 'td50',
                        'disabled': true,
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                        'disabled': true,
                    },
                    {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'checkbox',
                        'caption': 'Выполнено',
                        'theme': CustomCheckboxTheme.TABLE,
                    },
                ],
            },
            {
                'name': 'Окна',
                'title': 'Окна',
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование работ',
                        'default': '',
                        'required': true,
                        'className': 'td50',
                        'disabled': true,
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                        'disabled': true,
                    },
                    {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'checkbox',
                        'caption': 'Выполнено',
                        'theme': CustomCheckboxTheme.TABLE,
                    },
                ],
            },
            {
                'name': 'Балкон',
                'title': 'Балкон',
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование работ',
                        'default': '',
                        'required': true,
                        'className': 'td50',
                        'disabled': true,
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                        'disabled': true,
                    },
                    {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'checkbox',
                        'caption': 'Выполнено',
                        'theme': CustomCheckboxTheme.TABLE,
                    },
                ],
            },
            {
                'name': 'Санузел',
                'title': 'Санузел',
                'inputs': [
                    {
                        'name': 'name',
                        'title': 'Наименование работ',
                        'default': '',
                        'required': true,
                        'className': 'td50',
                        'disabled': true,
                    },
                    {
                        'name': 'description',
                        'title': 'Примечание',
                        'default': '',
                        'className': '',
                        'disabled': true,
                    },
                    {
                        'name': 'status',
                        'title': 'Статус сверки',
                        'default': '',
                        'className': '',
                        'type': 'checkbox',
                        'caption': 'Выполнено',
                        'theme': CustomCheckboxTheme.TABLE,
                    },
                ],
            },
        ],
    }),
    workProcess: (disabled: boolean, required: boolean = true) => ({
        disabled: disabled,
        name: 'workProcess',
        title: 'Контент с объекта',
        max: 150,
        type: 'documentList',
        button: 'Загрузить фото',
        required,
        allowedFileTypes: ['image', 'video'],
    }),
    actualCostCash: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Выплачено наличными',
        name: 'actualCostCash',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
    }),
    actualCostCard: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Выплачено безнал.',
        name: 'actualCostCard',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
    }),
    workEnding: (disabled: boolean) => ({
        disabled: disabled,
        name: 'workEnding',
        title: 'Окончание работ',
        type: 'document',
        button: 'Загрузить фото',
        fileType: 'jpg',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image'],
    }),
    startWorkingDate: () => ({
        disabled: true,
        title: 'Дата начала работы',
        name: 'startWorkingDate',
        className: 'input33',
        type: 'date',
        cleaningDate: false,
        singleDate: true,
    }),
    endWorkingDate: () => ({
        disabled: true,
        title: 'Дата окончания работы',
        name: 'startWorkingDate',
        className: 'input33',
        type: 'date',
        singleDate: true,
    }),
    paymentCheck: (disabled: boolean) => ({
        disabled: disabled,
        name: 'paymentCheck',
        title: 'Подтверждение оплаты',
        type: 'document',
        button: 'Загрузить фото',
        accept: 'image/*;capture=camera',
        allowedFileTypes: ['image', 'application'],
    }),
    objectComment: (disabled: boolean) => ({
        disabled: disabled,
        name: 'objectComment',
        title: 'Комментарий по объекту',
        type: 'text',
        multiline: true,
    }),
    additionalObjectInformation: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Доп. информация по объекту',
        name: 'additionalObjectInformation',
        className: 'input100',
        type: 'checkboxGroup',
        required: false,
        items: [
            {
                name: 'children',
                caption: 'Есть дети',
            },
            {
                name: 'pets',
                caption: 'Есть животные',
            },
        ],
    }),
}

interface WORKING_DAY_REPORT_PARAMS {
    businessType: string,
    stages: string[],
    currentStage: string,
    user: any,
    archived: boolean,
    responsibleUserId: string,
    cleaningDate: {
        startDate: Date | string | Dayjs,
        endDate: Date | string | Dayjs,
    },
    foremanReport: ForemanReportType,
    prepaymentType: string,
    days: number,
    order?: any,
    isAllChecks?: boolean
    lastDayReportDate: Date
}

export const WORKING_DAY_REPORT_NOT_DISABLED_STAGES = ['OBJECT_REPORT_FILLING', 'SERVICE_TABLE_FILLING', 'WORKING_DAY_ENDING']

export const WORKING_DAY_REPORT = (params: WORKING_DAY_REPORT_PARAMS) => {
    const {
        businessType,
        stages,
        currentStage,
        user,
        archived,
        responsibleUserId,
        cleaningDate,
        foremanReport,
        prepaymentType,
        days,
        order,
        isAllChecks,
        lastDayReportDate,
    } = params
    if (rules[user?.role]?.sections.workingDayReport || responsibleUserId === user.id) {
        const documentInputs: any[] = []
        const availableAction = StageManager.checkAvailableStageByRole('OBJECT_REPORT_FILLING', user.role) || responsibleUserId === user.id
        const temporaryDisable = !WORKING_DAY_REPORT_NOT_DISABLED_STAGES.includes(currentStage) // && user.role === 'ORDER_MANAGER'
        const isManagerChecking = stages.includes('DEFECTS_CORRECTING') || stages.includes('QUALITY_CONTROLLING')
        const isDisabled = (flag: boolean) => {
            if (isManagerChecking) {
                return true
            }
            if (user.role === 'ORDER_MANAGER' && user.division.includes(businessType)) {
                return false
            }
            return !availableAction || flag || temporaryDisable
        }

        const tabs = new Array(days)
            .fill('pashtet_lover_1337')
            .map((_, index) => {
                if (index > foremanReport.currentDayNumber) {
                    return {
                        index,
                        name: 'foremanReport.daysReport.' + index,
                        inputs: [],
                        label: 'День ' + (index + 1),
                        disabled: true,
                    }
                }

                const newInputs = []

                const isCurrentDay: boolean = foremanReport.currentDayNumber === index && !lastDayReportDate
                const isFirstDay = index === 0

                const workingDay = fetchDate(cleaningDate.startDate).add(index, 'd')
                const reportDate = foremanReport.daysReport[index].endWorkingDate

                if (reportDate) {
                    newInputs.push([
                        additionalInputs.division(true, 'foremanReport.division'),
                        WORKING_DAY_REPORT_SECTIONS.foreman(!(order.foremanReport.daysReport[index]?.isForemanChanging && (user.role === 'ORDER_MANAGER' || user.role === 'CHIEF')), order.foremanReport.division),
                        additionalInputs.reportDate(true, 'endWorkingDate'),
                    ])
                } else {
                    newInputs.push([
                        additionalInputs.division(true, 'foremanReport.division'),
                        WORKING_DAY_REPORT_SECTIONS.foreman(!(order.foremanReport.daysReport[index]?.isForemanChanging && (user.role === 'ORDER_MANAGER' || user.role === 'CHIEF')), order.foremanReport.division),
                    ])
                }

                if (stages.includes('OBJECT_REPORT_FILLING') || !isCurrentDay) {
                    if (isFirstDay) {
                        if (prepaymentType === 'На объекте') {
                            newInputs.push([
                                WORKING_DAY_REPORT_SECTIONS.actualPrepaymentCash(isDisabled(!isCurrentDay)),
                                WORKING_DAY_REPORT_SECTIONS.actualPrepaymentCard(isDisabled(!isCurrentDay)),
                            ])

                            if (foremanReport.daysReport[0].actualPrepaymentCard > 0) {
                                newInputs.push([WORKING_DAY_REPORT_SECTIONS.prepaymentCheck(isDisabled(!isCurrentDay))])
                            }
                        }
                    }
                    newInputs.push([WORKING_DAY_REPORT_SECTIONS.workBeginning(isDisabled(!isCurrentDay))])
                    documentInputs.push(WORKING_DAY_REPORT_SECTIONS.workBeginning(isDisabled(!isCurrentDay)))
                }

                if (stages.includes('SERVICE_TABLE_FILLING') || !isCurrentDay) {
                    newInputs.push([WORKING_DAY_REPORT_SECTIONS.foremanCheckList(isDisabled(!isCurrentDay || currentStage === 'WORKING_DAY_ENDING'))])

                    if (availableAction) {
                        newInputs.push([WORKING_DAY_REPORT_SECTIONS.workProcess(isDisabled(!isCurrentDay || currentStage === 'WORKING_DAY_ENDING'), order.nextStageName !== 'UPLOAD_CONTENT')])
                        documentInputs.push(WORKING_DAY_REPORT_SECTIONS.workProcess(isDisabled(!isCurrentDay || currentStage === 'WORKING_DAY_ENDING'), order.nextStageName !== 'UPLOAD_CONTENT'))
                    }
                }

                if (stages.includes('WORKING_DAY_ENDING') || !isCurrentDay) {
                    newInputs.push([additionalInputs.workEnding(isDisabled(!isCurrentDay), `documents.foremanReport.daysReport.${index}.workEnding`)])
                    if (isAllChecks && index === foremanReport.daysReport.length - 1 && order.primaryInformation.counterparty !== 'Юр. лицо') {
                        newInputs.push([
                            WORKING_DAY_REPORT_SECTIONS.actualCostCash(isDisabled(!isCurrentDay)),
                            WORKING_DAY_REPORT_SECTIONS.actualCostCard(isDisabled(!isCurrentDay)),
                        ])
                        if (foremanReport.daysReport[foremanReport.daysReport.length - 1].actualCostCard > 0 && index === foremanReport.currentDayNumber) {
                            newInputs.push([WORKING_DAY_REPORT_SECTIONS.paymentCheck(isDisabled(!isCurrentDay))])
                            documentInputs.push(WORKING_DAY_REPORT_SECTIONS.paymentCheck(isDisabled(!isCurrentDay)))
                        }
                        newInputs.push([WORKING_DAY_REPORT_SECTIONS.objectComment(isDisabled(!isCurrentDay))])
                        newInputs.push([WORKING_DAY_REPORT_SECTIONS.additionalObjectInformation(isDisabled(!isCurrentDay))])
                    }
                }

                if (availableAction && isCurrentDay) {
                    const action = fetchFormAction({
                        archived,
                        section: 'WORKING_DAY_REPORT',
                        user,
                        currentStage,
                        responsibleUserId,
                        division: foremanReport.division,
                        lastWorkingDay: foremanReport.lastWorkingDay,
                        order,
                    })
                    if (action) {
                        newInputs.push([action])
                    }
                }
                return {
                    index,
                    workingDay,
                    name: 'foremanReport.daysReport.' + index,
                    documentInputs,
                    inputs: newInputs,
                    label: 'День ' + (index + 1),
                }
            })
            .filter((_, index) => {
                return foremanReport.daysReport[index].foreman === user.id || user.role === 'CHIEF' || user.role === 'ORDER_MANAGER'
            })
            .map((item, index) => {
                return { ...item, index }
            })

        return {
            title: 'Отчет Менеджера по клинингу (объект)',
            tabs,
            defaultTab: tabs.length - 1,
            multiple: true,
        }
    }
    return {
        title: 'Отчет Менеджера по клинингу (объект)',
        name: 'foremanReport.daysReport.0',
        inputs: [],
    }
}
