import './AdditionalServicesItem.scss'
import clsx from 'clsx'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FaPen } from 'react-icons/fa'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import MobileEditModal from '~/components/mobile/mobileCommon/mobileReusingComponents/MobileEditModal/MobileEditModal'

interface AdditionalServicesItem {
    inputs: any[]
    disabled: boolean
    inputName: string
    rowIndex: number
    isError: boolean
    onDelete: (index: number) => void
}

const AdditionalServicesItem = (props: AdditionalServicesItem) => {
    const {
        inputs,
        disabled,
        inputName,
        rowIndex,
        isError,
        onDelete,
    } = props

    const [editModalOpen, setEditModalOpen] = useState<boolean>(false)

    const { getValues } = useFormContext<OrderInterface>()

    const {
        name,
        description,
        price,
    } = getValues(inputName as `foremanReport.additionalServices.${number}`)

    return (
        <>
            <div className={clsx('additionalServicesItem', isError && 'additionalServicesItem--error')}>
                <h4 className='additionalServicesItem__title'>{name}</h4>
                {description && <p className='additionalServicesItem__description'>{description}</p>}
                <div className='additionalServicesItem__content'>
                    {price && <p className='additionalServicesItem__price'>{price} ₽</p>}
                    <button type='button' className='toolsReportItem__icon' onClick={() => setEditModalOpen(true)}>
                        <FaPen size={16} />
                    </button>
                </div>
            </div>
            <MobileEditModal
                inputName={inputName}
                isOpen={editModalOpen}
                closeModal={() => setEditModalOpen(false)}
                inputs={inputs}
                disabled={disabled}
                rowIndex={rowIndex}
                onDelete={onDelete}
                deleteText='Удалить услугу'
            />
        </>
    )
}

export default AdditionalServicesItem
