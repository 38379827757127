import { CSSProperties } from 'react'
import { getFirstNumber } from '~/shared/util/getFirstNumberFromString'

export const calculateCellStyles = (index: number, columnsToHide: [number, number], withCheckboxes: boolean, inputs: any[]): CSSProperties | undefined => {
    if (index < columnsToHide[0]) {
        const leftSum = inputs.reduce((acc, input, currentIndex) => {
            if (currentIndex < index) {
                acc += getFirstNumber(input.className)
            }
            return acc
        }, 0)

        return {
            width: getFirstNumber(inputs[index].className) + 'rem',
            left: `${withCheckboxes ? 2.25 + leftSum : leftSum}rem`,
        }
    }

    if (index > columnsToHide[1]) {
        const rightSum = inputs.reduce((acc, input, currentIndex) => {
            if (currentIndex > index) {
                acc += getFirstNumber(input.className)
            }
            return acc
        }, 0)

        return {
            width: getFirstNumber(inputs[index].className) + 'rem',
            right: `${rightSum}rem`,
        }
    }

    return undefined
}
