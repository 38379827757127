import { FaCheck } from '@react-icons/all-files/fa/FaCheck'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import './MobileCheckbox.scss'

interface MobileCheckboxProps {
    value?: boolean
    onChange: (value: boolean) => void
    disabled?: boolean
}

const MobileCheckbox = (props: MobileCheckboxProps) => {
    const {
        value,
        onChange,
        disabled,
    } = props

    const [checked, setChecked] = useState<boolean>(!!value)

    const onClick = () => {
        if (disabled) return
        setChecked(!checked)
        onChange && onChange(!checked)
    }

    useEffect(() => {
        setChecked(!!value)
    }, [value])

    return (
        <div
            className={clsx('mobileCheckboxNew', { 'mobileCheckboxNew--active': checked })}
            onClick={onClick}
        >
            <FaCheck size={18} />
        </div>
    )
}

export default MobileCheckbox
