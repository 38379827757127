const servicesDescriptionTable = (contractType: string) => `
<table class='servicesTable' style='margin-top: -1px;'>
                <tbody>
                    <tr id='servicesDescDiv'>
                        <td colspan='2' class='tarPr10' style='width:27%; height: 40px;'>План выполнения работ:</td>
                        <td colspan='4' class='paddingL10' id='servicesDesc'></td>
                    </tr>
                    <tr id='operSchedDescDiv'>
                        <td colspan='2' class='tarPr10' style='width:27%; height: 40px;'>График работ:</td>
                        <td colspan='4' class='paddingL10'  id='operSched'></td>
                    </tr>
                    <tr id='numOfPersDescDiv'>
                        <td colspan='2' class='tarPr10' style='width:27%; height: 40px;'>Количество персонала:</td>
                        <td colspan='4' class='paddingL10'  id='numOfPers'></td>
                    </tr>
                    <tr>
                        <th colspan='6' style='background-color: #54D3C2; text-align: center; height: 25px;'>${contractType !== 'Обслуживание' ? 'Стоимость:' : 'Расчет стоимости'}</th>
                    </tr>
                    <tr id='paymentTypeWithoutNds'>
                        <th colspan='2' class='tarPr10' style='width: 40%; height: 40px; hyphens: auto;'>
                            Итого (НДС не облагается):
                            ${contractType === 'Обслуживание' ? '<br/>НДС не предусмотрен в связи с применением п. 2 ст. 346.11 НК РФ, организации, применяющей упрощенную систему налогообложения.' : ''}
                        </th>
                        <td colspan='4' class='paddingL10' id='b2bNoNds'></td>
                    </tr>
                    <tr id='paymentTypeCash'>
                        <th colspan='2' class='tarPr10' style='width: 27%; height: 40px;'>Сумма:</th>
                        <td colspan='4' class='paddingL10'  id='b2bCash'></td>
                    </tr>
                    <tr id='paymentTypeWithNds1'>
                        <th colspan='2' class='tarPr10' style='width: 27%; height: 40px;'>Всего к оплате с учетом НДС 20%:</th>
                        <td colspan='4' class='paddingL10'  id='b2bSum'></td>
                    </tr>
                    <tr id='paymentTypeWithNds2'>
                        <th colspan='2' class='tarPr10' style='width: 27%; height: 40px;'>В том числе НДС 20%:</th>
                        <td colspan='4' class='paddingL10'  id='b2bNds'></td>
                    </tr>
                </tbody>
            </table>
`

const servicesDescriptionTableDocx = (contractType: string) => `
<table class='servicesTable' style='width: 100%; border: 1px solid #54D3C2; font-size: 12px; border-collapse: collapse;;'>
                <tbody>
                    <tr id='servicesDescDiv'>
                        <td colspan='2' class='tarPr10' style='width:27%; height: 40px; text-align: right; padding-right: 10px; border: 1px solid #54D3C2;'>План выполнения работ:</td>
                        <td colspan='5' class='paddingL10' id='servicesDesc' style='padding-left: 10px; border: 1px solid #54D3C2;'></td>
                    </tr>
                    <tr id='operSchedDescDiv'>
                        <td colspan='2' class='tarPr10' style='width:27%; height: 40px; text-align: right; padding-right: 10px; border: 1px solid #54D3C2;'>График работ:</td>
                        <td colspan='5' class='paddingL10' id='operSched' style='padding-left: 10px; border: 1px solid #54D3C2;'></td>
                    </tr>
                    <tr id='numOfPersDescDiv'>
                        <td colspan='2' class='tarPr10' style='width:27%; height: 40px; text-align: right; padding-right: 10px; border: 1px solid #54D3C2;'>Количество персонала:</td>
                        <td colspan='5' class='paddingL10' id='numOfPers' style='padding-left: 10px; border: 1px solid #54D3C2;'></td>
                    </tr>
                    <tr>
                        <th colspan='7' style='background-color: #54D3C2; text-align:center; height: 25px; border: 1px solid #54D3C2;'>${contractType !== 'Обслуживание' ? 'Стоимость:' : 'Расчет стоимости'}</th>
                    </tr>
                    <tr id='paymentTypeWithoutNds'>
                        <th colspan='2' class='tarPr10' style='width:27%; height: 40px; text-align: right; padding-right: 10px; border: 1px solid #54D3C2; hyphens: auto;'>
                            Итого (НДС не облагается):
                            ${contractType === 'Обслуживание' ? '<br/>НДС не предусмотрен в связи с применением п. 2 ст. 346.11 НК РФ, организации, применяющей упрощенную систему налогообложения.' : ''}
                        </th>
                        <td colspan='5' class='paddingL10' id='b2bNoNds' style='padding-left: 10px; border: 1px solid #54D3C2;'></td>
                    </tr>
                    <tr id='paymentTypeCash'>
                        <th colspan='2' class='tarPr10' style='width:27%; height: 40px; text-align: right; padding-right: 10px; border: 1px solid #54D3C2;'>Сумма:</th>
                        <td colspan='5' class='paddingL10' id='b2bCash' style='padding-left: 10px; border: 1px solid #54D3C2;'></td>
                    </tr>
                    <tr id='paymentTypeWithNds1'>
                        <th colspan='2' class='tarPr10' style='width:27%; height: 40px; text-align: right; padding-right: 10px; border: 1px solid #54D3C2;'>Всего к оплате с учетом НДС 20%:</th>
                        <td colspan='5' class='paddingL10' id='b2bSum' style='padding-left: 10px; border: 1px solid #54D3C2;'></td>
                    </tr>
                    <tr id='paymentTypeWithNds2'>
                        <th colspan='2' class='tarPr10' style='width:27%; height: 40px; text-align: right; padding-right: 10px; border: 1px solid #54D3C2;'>В том числе НДС 20%:</th>
                        <td colspan='5' class='paddingL10' id='b2bNds' style='padding-left: 10px; border: 1px solid #54D3C2;'></td>
                    </tr>
                </tbody>
            </table>
`
const unitedServicesDescriptionTableDocxAndHead = (contractType: string, format: PageTemplateOptions['format']) => `
${contractType === 'Обслуживание' ? '<span style="font-size: 10px"><b>1.1.</b> Объект уборки:</span>' : ''}
    <table class='servicesTable'
    style='${format === 'docx' ? 'width: 100%; border: 1px solid #54D3C2; font-size: 12px; border-collapse: collapse;' : ''}'
>
                <tbody>
                    <tr>
                        <th colspan='7' style='text-align:center; height: 40px; ${format === 'docx' ? 'border: 1px solid #54D3C2; background-color: #54D3C2; padding: 5px; margin: 5px 0; font-weight: bold;' : ''}'>Технологическая карта уборки</th>
                    </tr>
                    <tr>
                        <th colspan='2' style='text-align:right; width: 27%; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Дата начала работ:</th>
                        <td colspan='5' class='paddingL10' id='startDate' style='${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}'>07.06.2022</td>
                    </tr>
                    ${contractType !== 'Обслуживание' ? `<tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Дата окончания работ:</th>
                        <td colspan='5' class='paddingL10' id='endDate' style='${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}'>16.06.2022</td>
                    </tr>
                    <tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Тип объекта:</th>
                        <td colspan='5' class='paddingL10' id='objectType' style='${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}'>Коммерческий объект</td>
                    </tr>` : ''}
                    <tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>${contractType === 'Обслуживание' ? 'Классификация объекта по назначению:' : 'Объект:'}</th>
                        <td colspan='5' class='paddingL10' id='object' style='${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}'>Производстводственные помещения/Завод/Фабрика/Цех</td>
                    </tr>
                    ${contractType !== 'Обслуживание' ? `<tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Контрагент:</th>
                        <td colspan='5' class='paddingL10' id='counterparty' style='${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}'>Юр. лицо</td>
                    </tr>` : ''}
                    <tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Название организации:</th>
                        <td colspan='5' class='paddingL10 organizationName' style='${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}'>ООО «МЕБЕЛЬНАЯ МЕЛОДИЯ»</td>
                    </tr>
                    <tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Адрес объекта:</th>
                        <td colspan='5' class='paddingL10 objectAddress' style='${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}'>Всеволожский р-н, поселок Щеглово, ул. Инженерная, д. 14</td>
                    </tr>
                    ${contractType === 'Обслуживание' ? `
                        <tr>
                            <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Объем:</th>
                            <td colspan='5' class='paddingL10 objectArea' style='${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}'>10 м2</td>
                        </tr>
                    ` : ''}
                    <tr>
                        <th colspan='1' style='text-align:center; height: 40px; border: 1px solid #54D3C2;'>№</th>
                        <th colspan='2' style='text-align:center; border: 1px solid #54D3C2;'>Наименование работ/услуг</th>
                        <th colspan='1' style='text-align:center; border: 1px solid #54D3C2;'>${contractType === 'Обслуживание' ? 'Объем' : 'Кол-во'}</th>
                        <th colspan='1' style='text-align:center; border: 1px solid #54D3C2;'>Ед.</th>
                        <th colspan='1' style='text-align:center; border: 1px solid #54D3C2;'>Цена за ед.</th>
                        <th colspan='1' style='text-align:center; border: 1px solid #54D3C2;'>Сумма</th>
                    </tr>
                    </tbody>
                <tbody id='services'></tbody>
            </table>
`
const servicesMainTable = (contractType: string) => `
<table class='servicesTable' style='margin-top: -1px;'>
                <tbody>
                    <tr>
                        <th colspan='1' style='width: 3%; text-align:center; height: 40px;'>№</th>
                        <th colspan='6' style='width: 54%; text-align:center;'>Наименование работ/услуг</th>
                        <th colspan='2' style='text-align:center;'>${contractType === 'Обслуживание' ? 'Объем' : 'Кол-во'}</th>
                        <th colspan='2' style='text-align:center;'>Ед.</th>
                        <th colspan='2' style='text-align:center;'>Цена за ед.</th>
                        <th colspan='2' style='text-align:center;'>Сумма</th>
                    </tr>
                </tbody>
                <tbody id='services'></tbody>
            </table>
`
const servicesMainTableDocx = (contractType: string) => `
            <table style='width: 100%; border: 1px solid #54D3C2; font-size: 12px; border-collapse: collapse;'>
                <tbody>
                    <tr>
                        <th colspan='1' style='text-align:center; height: 40px; border: 1px solid #54D3C2;'>№</th>
                        <th colspan='2' style='text-align:center; border: 1px solid #54D3C2;'>Наименование работ/услуг</th>
                        <th colspan='1' style='text-align:center; border: 1px solid #54D3C2;'>${contractType === 'Обслуживание' ? 'Объем' : 'Кол-во'}</th>
                        <th colspan='1' style='text-align:center; border: 1px solid #54D3C2;'>Ед.</th>
                        <th colspan='1' style='text-align:center; border: 1px solid #54D3C2;'>Цена за ед.</th>
                        <th colspan='1' style='text-align:center; border: 1px solid #54D3C2;'>Сумма</th>
                    </tr>
                <tbody id='services'></tbody>
            </table>
`

const servicesHeadTable = (contractType: string, format: PageTemplateOptions['format']) => `
${contractType === 'Обслуживание' ? '<span style="font-size: 10px"><b>1.1.</b> Объект уборки:</span>' : ''}
<table class='servicesTable'
    style="${format === 'docx' ? 'width: 100%; border: 1px solid #54D3C2; font-size: 12px; border-collapse: collapse;' : ''}"
>
                <tbody>
                    <tr>
                        <th colspan='7' style='text-align:center; height: 40px; ${format === 'docx' ? 'border: 1px solid #54D3C2; background-color: #54D3C2; padding: 5px; margin: 5px 0; font-weight: bold;' : ''}'>Технологическая карта уборки</th>
                    </tr>
                    <tr>
                        <th colspan='2' style='text-align:right; width: 27%; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Дата начала работ:</th>
                        <td colspan='5' class='paddingL10' id='startDate' style="${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}">07.06.2022</td>
                    </tr>
                    ${contractType !== 'Обслуживание' ? `<tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Дата окончания работ:</th>
                        <td colspan='5' class='paddingL10' id='endDate' style="${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}">16.06.2022</td>
                    </tr>
                    <tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Тип объекта:</th>
                        <td colspan='5' class='paddingL10' id='objectType' style="${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}">Коммерческий объект</td>
                    </tr>` : ''}
                    <tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>${contractType === 'Обслуживание' ? 'Классификация объекта по назначению:' : 'Объект:'}</th>
                        <td colspan='5' class='paddingL10' id='object' style="${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}">Производстводственные помещения/Завод/Фабрика/Цех</td>
                    </tr>
                    ${contractType !== 'Обслуживание' ? `<tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Контрагент:</th>
                        <td colspan='5' class='paddingL10' id='counterparty' style="${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}">Юр. лицо</td>
                    </tr>` : ''}
                    <tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Название организации:</th>
                        <td colspan='5' class='paddingL10 organizationName' style="${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}">ООО «МЕБЕЛЬНАЯ МЕЛОДИЯ»</td>
                    </tr>
                    <tr>
                        <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Адрес объекта:</th>
                        <td colspan='5' class='paddingL10 objectAddress' style="${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}">Всеволожский р-н, поселок Щеглово, ул. Инженерная, д. 14</td>
                    </tr>
                    ${contractType === 'Обслуживание' ? `
                        <tr>
                            <th colspan='2' style='text-align:right; padding-right: 10px; ${format === 'docx' ? 'border: 1px solid #54D3C2;' : ''}'>Объем:</th>
                            <td colspan='5' class='paddingL10 objectArea' style="${format === 'docx' ? 'padding-left: 10px; border: 1px solid #54D3C2;' : ''}">10 м2</td>
                        </tr>
                    ` : ''}
                </tbody>
            </table>
`

const signing = `
<div class='signing'>
                <div style='text-align: center'><b>ПОДПИСИ СТОРОН</b></div>
                <div class='signing__footer'>
                    <div class='signing__left'>
                        Заказчик:
                    </div>
                    <div class='signing__right'>
                        Исполнитель:
                    </div>
                </div>
                <table class='borderNoneTable' style='width: 100%; margin-bottom: 20px;'>
                    <tbody>
                        <tr>
                            <td><b class='responsibleFaceName'>Генеральный директор</b> <br>
                                <b class='organizationNameShort'></b>
                            </td>
                            <td class='director__right'>
                                <b>Генеральный директор<br><span class='ourOrganizationName'>ООО 'H2O'</span></b>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class='signing__footer'>
                    <div class='signing__left'>
                        __________________/<b class='responsibleFaceShortName'>_______________</b>/
                    </div>
                    <div class='signing__right'>
                        __________________/<b class="directorShortName">Исаков А.Л.</b>/
                    </div>
                </div>
            </div>
`

const signingDocx = `
<div align='center'>
            <table class='a1' border='0' cellspacing='0' cellpadding='0' width='669'
                   style='border-collapse:collapse'>
                <tr>
                    <td width='707' colspan='4' style=';;height:auto'>
                        <p align='center' style='text-align:center;padding:12px 12px'><span
                            style='font-size:10.0pt;font-family:"Times New Roman",serif'> </span><span
                            style='font-size:10.0pt;font-family:"Times New Roman",serif'>ПОДПИСИ СТОРОН</span>
                            </p>
                    </td>
                </tr>
                <tr>
                    <td width='314' valign='top' style=';'>
                        <p align='center' style='margin-right:-22.05pt;text-align:start'><span
                            style='font-size:11.0pt;font-family:"Times New Roman",serif'>Заказчик:</span></p>
                    </td>
                    <td width='355' valign='top' style=';'>
                        <p align='center' style='margin-right:-22.05pt;text-align:end'><span
                            style='font-size:11.0pt;font-family:"Times New Roman",serif'>Исполнитель:</span></p>
                    </td>
                </tr>
                <tr style='height:auto;text-align:left'>
                    <td width='314' valign='top' style=';;height:auto'>
                        <p style='background:white'>
                            <b><span style='font-family:"Times New Roman",serif;color:black' class='responsibleFaceName'>Генеральный директор/представитель</span></br></b>
                            <b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;color:black' class='organizationNameShort'>НАИМЕНОВАНИЕ ООО</span></b>
                        </p>
                    </td>
                    <td width='355' style=';;height:auto'>
                        <p align='right' style='text-align:right;background:white'>
                            <b>
                                <span style='font-family:"Times New Roman",serif;color:black;'>Генеральный директор<br>
                                    <span class='ourOrganizationName'>ООО «Н2О»</span>
                                </span>
                            </b>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td width='314' valign='top' style=';;height:auto'>
                        <p style='margin-right:-22.05pt'>
                            <b><span style='font-family:"Times New Roman",serif'>&nbsp;</span></b>
                        </p>
                        <p style='background:white'>
                            <b><span style='font-family:"Times New Roman",serif;color:black'>________________ /<b class='responsibleFaceShortName'>Ф.И.О.</b>/</span></b>
                        </p>
                    </td>
                    <td width='355' style=';;height:auto'>
                        <p align='right' style='text-align:right'>
                            <b><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></b>
                        </p>
                        <p align='right' style='text-align:right;background:white'>
                            <b><span style='font-family:"Times New Roman",serif;color:black;'>________________/
                                <span class='directorShortName'>Исаков А.Л.</span>/
                            </span></b>
                        </p>
                    </td>
                </tr>
            </table>
        </div>
`
const cleaningPriceNote = '<span style=\'font-size: 12px\'><strong>В стоимость Клининговых Услуг включено:</strong> уборка помещений; использование и замена моющих средств, уборочного инвентаря и оборудования, обеспечение уборочного персонала спецодеждой.</span>'

const ndsFooter = `
    <span style='font-size: 12px'><strong>1.2. Расчетная стоимость выполняемых работ включает в себя:</strong></span>
    <ul style='font-size: 12px'>
        <li>Заработную плату персонала, включая налоговые отчисления.</li>
        <li>Затраты на оформление и ведение персонала.</li>
        <li>Профессиональные средства для уборки и инвентарь.</li>
        <li>Стоимость спецодежды, СИЗ.</li>
        <li>Налоги, накладные расходы, нормативную прибыль.</li>
        <li>Сервисное обслуживание и текущий ремонт используемого на объекте оборудования.</li>
        <li>Обучение и аттестация линейного персонала.</li>
    </ul>
    <span style='font-size: 12px'>В своей работе мы используем химические средства ведущих российских и зарубежных производителей:
     «Питхим», «Pro-brite», их использование позволяет достигнуть высокого качества оказываемых услуг и безопасно для окружающей среды.</span><br/>
    ${cleaningPriceNote}
`

const servicesFooterTable = ({ contractType, paymentType }: PageTemplateOptions) => `
<table style='margin-top: -1px; width: 100%; border-collapse: collapse; border: 1px solid #54D3C2; font-size: 12px;'>
    <tbody>
        <tr>
            <th colspan='6' style='background-color: #54D3C2; text-align: center; height: 25px; border: 1px solid #54D3C2;'>${contractType !== 'Обслуживание' ? 'Ответственное лицо от Исполнителя:' : 'Контактные данные от Исполнителя:'}</th>
        </tr>
        <tr>
            <th colspan='2' style='padding-left: 10px; width: 27%; border: 1px solid #54D3C2;'>${contractType !== 'Обслуживание' ? 'ФИО:' : ''}</th>
            <td colspan='4' style='padding-left: 10px; border: 1px solid #54D3C2;' id='execName'></td>
        </tr>
        ${contractType !== 'Обслуживание' ?
        `<tr>
            <th colspan='2' style='padding-left: 10px; border: 1px solid #54D3C2;'>Должность:</th>
            <td colspan='4' style='padding-left: 10px; border: 1px solid #54D3C2;' id='execJob'></td>
        </tr>` : ''}
        <tr>
            <th colspan='2' style='padding-left: 10px; border: 1px solid #54D3C2;'>${contractType === 'Обслуживание' ? 'Контактные данные' : 'Телефон/e-mail:'}</th>
            <td colspan='4' style='padding-left: 10px; border: 1px solid #54D3C2;' id='execPhone'></td>
        </tr>
    </tbody>
</table>

<table style='margin-top: -1px; width: 100%; border-collapse: collapse; border: 1px solid #54D3C2; font-size: 12px;'>
    <tbody>
        <tr>
            <th colspan='6' style='background-color: #54D3C2; text-align: center; height: 25px; border: 1px solid #54D3C2;'>Ответственное лицо от Заказчика:</th>
        </tr>
        <tr>
            <th colspan='2' style='padding-left: 10px; width: 27%; border: 1px solid #54D3C2;'>ФИО:</th>
            <td colspan='4' style='padding-left: 10px; border: 1px solid #54D3C2;' class='custName'></td>
        </tr>
        <tr id='customerJobPositionRow'>
            <th colspan='2' style='padding-left: 10px; border: 1px solid #54D3C2;'>Должность:</th>
            <td colspan='4' style='padding-left: 10px; border: 1px solid #54D3C2;' class='custJob'></td>
        </tr>
        <tr>
            <th colspan='2' style='padding-left: 10px; border: 1px solid #54D3C2;'>${contractType === 'Обслуживание' ? 'Контактные данные' : 'Телефон/e-mail:'}</th>
            <td colspan='4' style='padding-left: 10px; border: 1px solid #54D3C2;' id='custPhone'></td>
        </tr>
    </tbody>
</table>

        ${contractType === 'Обслуживание' && paymentType === 'р/с (без НДС)' ? cleaningPriceNote : ''}
        ${contractType === 'Обслуживание' && paymentType === 'р/с (НДС)' ? ndsFooter : ''}
`

const additionalHeader = (contractType: string) => `
<p style='text-align:right; margin: 0;'>Приложение №1 <br> к Договору №<span class='contractNumber'>___</span><br> <span class='contractDate'>«___»
                            ______20__г.</span></p>
           ${contractType === 'Обслуживание' ? '<h1 style=\'padding: 1px 0; font-size: 12px; text-align:center\'>Перечень выполняемых услуг, стоимость и график их проведения</h1>' :
        '<h1 style=\'padding: 20px 0\'>Перечень выполняемых услуг, стоимость и график их проведения</h1>'}
`

const getHeadAndMainTable = (options: PageTemplateOptions) => {
    if (options.format === 'docx') {
        return unitedServicesDescriptionTableDocxAndHead(options.contractType, options.format)
    }
    return `
            ${servicesHeadTable(options.contractType, options.format)}
            ${servicesMainTable(options.contractType)}
    `
}

export interface PageTemplateOptions {
    contractType: string
    paymentType: string
    format?: 'docx' | 'default'
}

export const firstPageTemplate = (options: PageTemplateOptions) => `
<div class='pageWrapper pageWrapper_6'
    style="${options.format === 'docx' ? 'position: relative; height: 1122px; padding: 40px 35px; line-height: 1.1; font-size: 14px; font-family: \'Times New Roman\', Times, serif; text-align: justify;' : ''}"
>
            ${additionalHeader(options.contractType)}
            ${getHeadAndMainTable(options)}
            ${options.format === 'docx' ? signingDocx : signing}
        </div>
`

export const lastPageTemplate = (options: PageTemplateOptions) => `
<div class='pageWrapper pageWrapper_6'
    style="${options.format === 'docx' ? 'position: relative; height: 1122px; padding: 40px 35px; line-height: 1.1; font-size: 14px; font-family: \'Times New Roman\', Times, serif; text-align: justify;' : ''}"
>
            ${additionalHeader(options.contractType)}
            ${options.format === 'docx' ? servicesMainTableDocx(options.contractType) : servicesMainTable(options.contractType)}
            ${options.format === 'docx' ? servicesDescriptionTableDocx(options.contractType) : servicesDescriptionTable(options.contractType)}
            ${servicesFooterTable(options)}
            ${options.format === 'docx' ? signingDocx : signing}
        </div>
`

export const singlePageTemplate = (options: PageTemplateOptions) => `
<div class='pageWrapper pageWrapper_6'
    style="${options.format === 'docx' ? 'position: relative; height: 1122px; padding: 40px 35px; line-height: 1.1; font-size: 14px; font-family: \'Times New Roman\', Times, serif; text-align: justify;' : ''}"
>
            ${additionalHeader(options.contractType)}
            ${getHeadAndMainTable(options)}
            ${options.format === 'docx' ? servicesDescriptionTableDocx(options.contractType) : servicesDescriptionTable(options.contractType)}
            ${servicesFooterTable(options)}
            ${options.format === 'docx' ? signingDocx : signing}
        </div>
`

export const pageTemplate = (options: PageTemplateOptions) => `
<div class='pageWrapper pageWrapper_6'
    style=${options.format === 'docx' ? 'position: relative; height: 1122px; padding: 40px 35px; line-height: 1.1; font-size: 14px; font-family: \'Times New Roman\', Times, serif; text-align: justify;' : ''}
>
            ${additionalHeader(options.contractType)}
            ${options.format === 'docx' ? servicesMainTableDocx(options.contractType) : servicesMainTable(options.contractType)}
            ${options.format === 'docx' ? signingDocx : signing}
        </div>
`
