import Avatar from '@mui/material/Avatar'
import { FaBell } from '@react-icons/all-files/fa/FaBell'
import { FaCalendarAlt } from '@react-icons/all-files/fa/FaCalendarAlt'
import { FaCalendarDay } from '@react-icons/all-files/fa/FaCalendarDay'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from './logoM.svg'
import BackButton from '../../common/ReusingComponents/BackButton/BackButton'
import Comments from '../../common/ReusingComponents/Comments/Comments'
import MobileDateToolbar from '../mobileCommon/mobileReusingComponents/MobileDateToolbar/MobileDateToolbar'
import { logoutUser } from '~/API/authApi/auth'
import { Division } from '~/API/userApi/user.types'
import { useUpdateCalendar } from '~/hooks/useUpdateCalendar/useUpdateCalendar'
import { SELECT_SIDEBAR_STATUS, selectHeaderToolbar, selectUser } from '~/redux/selectors/appSlice.selectors'
import { selectActiveOrder, selectOrderActiveDate, selectOrderLoader } from '~/redux/selectors/orderSlice.selectors'
import { CHANGE_SIDEBAR, logout } from '~/redux/slices/appSlice/appSlice'
import {
    CHANGE_DIVISION,
    changeOrderActiveDate,
    changeOrderActiveDateTarget,
} from '~/redux/slices/orderSlice/orderSlice'
import { fetchDate } from '~/shared/util/currentTime'
import { getGlobalDivision } from '~/shared/util/localStorage'
import './MobileHeader.scss'

const MobileHeader = () => {
    const dispatch = useDispatch()
    const updateCalendar = useUpdateCalendar()
    const user = useSelector(selectUser)
    const sidebarStatus = useSelector(SELECT_SIDEBAR_STATUS)
    const headerToolbar = useSelector(selectHeaderToolbar)
    const activeDate = useSelector(selectOrderActiveDate)
    const location = useLocation()
    const order = useSelector(selectActiveOrder)
    const loader = useSelector(selectOrderLoader)
    const navigate = useNavigate()

    const [isModal, setModal] = useState<boolean>(false)
    const [isOpenComments, setOpenComments] = useState<boolean>(false)

    const openModal = useCallback(() => {
        setModal(true)
    }, [])

    const closeModal = useCallback(() => {
        setModal(false)
    }, [])

    const handleCloseComments = useCallback(() => {
        setOpenComments(false)
    }, [])

    const showToggle = user?.division?.length && user?.division?.length > 1
    const division = user?.division.includes(getGlobalDivision()) ? getGlobalDivision() : user?.division[0]
    const handleLogout = useCallback(() => {
        setModal(false)
        logoutUser().then(() => {
            dispatch(logout())
        })
    }, [])

    const handleChangeDivision = useCallback((division: Division) => {
        dispatch(CHANGE_DIVISION(division))
        setModal(false)
        user && updateCalendar({ newDivision: division })
        // user && dispatch(updateOrdersThunk({ user, clearOld: true }))
    }, [user])

    const handleNavigate = useCallback((value: 'PREV' | 'NEXT') => {
        dispatch(changeOrderActiveDateTarget('toolbar'))
        switch (value) {
            case 'PREV':
                dispatch(
                    changeOrderActiveDate({
                        date: fetchDate(activeDate.date).subtract(1, 'month').toDate(),
                    }),
                )

                return
            case 'NEXT':
                dispatch(
                    changeOrderActiveDate({
                        date: fetchDate(activeDate.date).add(1, 'month').toDate(),
                    }),
                )
                return
            default:
                return
        }
    }, [activeDate])

    const DivisionsBody = useMemo(() => {
        return user?.division.filter((item) => item !== division) || []
    }, [user?.division, division])

    return (
        <div className='mobileHeader'>
            <div className='mobileHeader__leftside'>
                <Logo
                    className='mobileHeader__logo'
                    onClick={() => navigate('/')}
                />
            </div>
            {location.pathname === '/' && (
                <MobileDateToolbar
                    className={clsx({ active: headerToolbar || sidebarStatus })}
                    date={activeDate.date}
                    onNavigateNext={() => {
                        handleNavigate('NEXT')
                    }}
                    onNavigatePrev={() => {
                        handleNavigate('PREV')
                    }}
                />
            )}

            <div className='mobileHeader__rightside'>
                {location.pathname === '/' &&
                    (!sidebarStatus ? (
                        <FaCalendarDay
                            onClick={() => {
                                dispatch(CHANGE_SIDEBAR())
                            }}
                        />
                    ) : (
                        <FaCalendarAlt
                            onClick={() => {
                                dispatch(CHANGE_SIDEBAR())
                            }}
                        />
                    ))}
                {(location.pathname.slice(0, 7) === '/order/' || location.pathname.slice(0, 8) === '/report/') && (
                    <>
                        <FaBell
                            onClick={() => {
                                setOpenComments(true)
                            }}
                            className='orderBell'
                        />
                        <Modal
                            id='commentsModal'
                            isOpen={isOpenComments}
                            onRequestClose={() => {
                                setOpenComments(false)
                            }}
                        >
                            <BackButton callback={handleCloseComments} />
                            <Comments user={user} />
                        </Modal>
                    </>
                )}
                <Avatar
                    className={'mobileHeader__avatar'}
                    src={`${user?.photo || ''}`}
                    alt={user?.fullname}
                    onClick={openModal}
                />
            </div>
            {isModal && (
                <Modal
                    id='avatarModal'
                    isOpen={isModal}
                    onRequestClose={closeModal}
                    style={{
                        overlay: {
                            zIndex: 2,
                        },
                    }}
                >
                    <div className='avatarModal'>
                        {!!showToggle && (
                            DivisionsBody.map((item) => (
                                <div
                                    key={item}
                                    className='avatarModal__button'
                                    onClick={() => {
                                        handleChangeDivision(item)
                                    }}
                                >
                                    Перейти на {item}
                                </div>
                            ))
                        )}

                        <div
                            className='avatarModal__button'
                            onClick={handleLogout}
                        >
                            Выход
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default MobileHeader
