import { FaUser } from '@react-icons/all-files/fa/FaUser'
import clsx from 'clsx'
import { memo } from 'react'
import './ClientType.scss'
import { FaUserTie } from 'react-icons/fa6'

export enum ClientTypes {
    ENTITY = 'Юр. лицо',
    INDIVIDUAL = 'Физ. лицо',
    NONE = 'none'
}

interface ClientTypeProps {
    className?: string
    clientType: ClientTypes
    onClick: (value: ClientTypes) => void
}

export const ClientType = memo((props: ClientTypeProps) => {
    const {
        className,
        clientType,
        onClick,
    } = props


    const handleClick = (clientType: ClientTypes) => {
        onClick(clientType)
    }

    return (
        <div className={clsx('client-type', className)}>
            <button
                className={clsx('client-type__button', { 'client-type__button--checked': clientType === ClientTypes.ENTITY })}
                onClick={() => handleClick(ClientTypes.ENTITY)}
            >
                <div
                    className={clsx(
                        'client-type__button__icon__wrapper',
                        {
                            'client-type__button__icon__wrapper--checked': clientType === ClientTypes.ENTITY,
                        },
                    )}
                >
                    <FaUserTie className='client-type__button__icon' />
                </div>
                <span className='client-type__button__title'>Юр. лицо</span>
            </button>
            <button
                className={clsx('client-type__button', { 'client-type__button--checked': clientType === ClientTypes.INDIVIDUAL })}
                onClick={() => handleClick(ClientTypes.INDIVIDUAL)}
            >
                <div
                    className={clsx(
                        'client-type__button__icon__wrapper',
                        {
                            'client-type__button__icon__wrapper--checked': clientType === ClientTypes.INDIVIDUAL,
                        },
                    )}
                >
                    <FaUser className='client-type__button__icon' />
                </div>
                <span className='client-type__button__title'>Физ. лицо</span>
            </button>
        </div>
    )
})
