import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { selectKpHandleSubmit } from '~/redux/selectors/kpSlice.selectors'

const FtzSidebarButtons = () => {
    const { id: orderId } = useParams()
    const navigate = useNavigate()
    const handleSubmit = useSelector(selectKpHandleSubmit)

    return (
        <div className='sidebar__buttons'>
            <CustomButton
                className='sidebar__buttonsItem'
                onClick={() => handleSubmit()}
            >
                Сохранить ФТЗ
            </CustomButton>
            <CustomButton
                className='sidebar__buttonsItem sidebar__buttonsItem--secondary'
                onClick={() => navigate(`/order/${orderId}`)}
            >
                Вернуться к Запуск уборки
            </CustomButton>
        </div>
    )
}

export default FtzSidebarButtons
