import React, { useCallback, useState } from 'react'
import { FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import MobileDocumentCard from '../MobileDocumentCard/MobileDocumentCard'
import { MobilePhotoViewer } from '../MobilePhotoViewer/MobilePhotoViewer'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import { isFileImage } from '~/components/pages/Order/subComponents/OrderDocuments/documentLib'
import { OPEN_LIGHTBOX } from '~/redux/slices/appSlice/appSlice'
import { useAppDispatch } from '~/redux/store'
import { orderTypes } from '~/shared/util/types'

interface DocumentGroupProps {
    name: string
    files: any
    setValue: UseFormSetValue<OrderInterface>
    getValues: UseFormGetValues<OrderInterface>
    currentStage: any
    searchText: string
}

export const MobileDocumentGroup = (props: DocumentGroupProps) => {
    const {
        name,
        files,
        setValue,
        getValues,
        currentStage,
        searchText,
    } = props
    const dispatch = useAppDispatch()
    const [viewer, setViewer] = useState<boolean>(false)
    const [currentImage, setCurrentImage] = useState<string>('')
    const [fileType, setFileType] = useState<'image' | 'pdf'>('image')

    const handleClick = useCallback((fileId: string, fileType: string) => {
        if (isFileImage(fileType)) {
            const index = files.findIndex((value: any) => value.id === fileId)
            if (index !== -1) {
                dispatch(OPEN_LIGHTBOX({ sourceIndex: index, sources: files.map((e: any) => e.id) }))
            }
        } else {
            setCurrentImage(fileId)
            setFileType('pdf')
            setViewer(true)
        }
    }, [files])

    const handleClose = useCallback(() => {
        setCurrentImage('')
        setViewer(false)
    }, [])

    const handleDelete = useCallback((file: any) => {
        const files = getValues(file.name)
        if (Array.isArray(files)) {
            const newArray = [...files]
            const index = files.findIndex((e) => e === file.id)
            if (index !== -1) {
                newArray.splice(index, 1)
                setValue(file.name, newArray)
            }
        } else {
            setValue(file.name, null)
        }
    }, [])

    const handlePhotoDelete = useCallback((photoIds: string[]) => {
        const filesToDelete: any[] = []
        photoIds.forEach((fileId) => {
            files.forEach((item: any) => {
                if (item.id === fileId) {
                    filesToDelete.push(item)
                }
            })
        })
        filesToDelete.forEach((file) => {
            const files = getValues(file.name)
            if (Array.isArray(files)) {
                const newArray = [...files]
                const index = files.findIndex((e) => e === file.id)
                if (index !== -1) {
                    newArray.splice(index, 1)
                    setValue(file.name, newArray)
                }
            } else {
                setValue(file.name, null)
            }
        })
    }, [files])

    return (
        <div className='mobile-order-documents__stage'>
            <div className='header'>
                <h4 className='header__title'>{orderTypes[name as keyof typeof orderTypes]}</h4>
                <button
                    className='header__delete-btn'
                    onClick={() => {
                        setViewer(true)
                        setFileType('image')
                    }}
                >
                    Удалить
                </button>
            </div>
            <div className='mobile-order-documents__docGroup'>
                {files.map((file: any) => (
                    <MobileDocumentCard
                        searchText={searchText}
                        currentStage={currentStage}
                        file={file}
                        onClick={handleClick}
                        onDelete={handleDelete}
                        key={'DC_' + file.id}
                    />
                ))}
            </div>
            {viewer && (
                <MobilePhotoViewer
                    currentStage={currentStage}
                    handleClose={handleClose}
                    photoId={currentImage}
                    files={files}
                    onDelete={handlePhotoDelete}
                    type={fileType}
                />
            )}
        </div>
    )
}
