import { User } from '~/API/userApi/user.types'
import Comment from '~/components/common/ReusingComponents/Comments/subComponents/Comment/Comment'
import { Message } from '~/hooks/useChat/useChat.types'

interface CommentListCommonProps {
    title: string
    commentGroup: Message[]
    groupIndex: number
    usersInfo: Record<string, User>
    user: User
}

export const CommentListCommon = (props: CommentListCommonProps) => {
    const {
        title,
        commentGroup,
        groupIndex,
        usersInfo,
        user,
    } = props

    return (
        <div className='comments__group'>
            {title && (
                <>
                    <h5>{title}</h5>
                    <hr className='comments__group__hr' />
                </>
            )}
            {commentGroup.map((comment, index) => {
                return (
                    <Comment
                        message={comment.message}
                        date={comment.date}
                        key={'comment' + groupIndex + '_' + index}
                        userInfo={usersInfo[comment.userId as keyof typeof usersInfo]}
                        direction={user?.id === comment?.userId ? 'right' : 'left'}
                    />
                )
            })}
        </div>
    )
}
