import {
    PageTemplateOptions,
} from '~/shared/templates/mainTemplates/additionalFunctions/collectCommercialOfferServices/collectCommercialOfferServices.templates'
import { ServicesType } from './collectServices.types'

interface CollectServicesParams {
    services: ServicesType[]
    pageLimit?: number
}

export interface ServicesWithId extends ServicesType {
    id?: string
}

export const collectServices = (params: CollectServicesParams) => {
    const {
        services,
        pageLimit = 25,
    } = params
    const servicesAdditionalPages: string[][] = [[]]
    let servicePageCounter = 0

    let newServices: ServicesWithId[] = [...services.map((item, index) => ({ ...item, id: item.title + index }))]

    services.forEach(() => {
        //Проверяем лимит для текущей дополнительной страницы
        let currentLimit = pageLimit - servicePageCounter
        const findServices = findNeededServices(currentLimit, newServices)

        //Если нашлась группа услуг для добавления на текущую страницу
        if (findServices) {
            const html = buildServiceTable(findServices)
            servicesAdditionalPages[servicesAdditionalPages.length - 1].push(html)
            newServices = newServices.filter((s) => s.id !== findServices.id)
            servicePageCounter += findServices.items.length
            return
        }

        //Если остались сервисы, но не нашлось места на текущей странице
        servicePageCounter = 0
        currentLimit = pageLimit - servicePageCounter
        servicesAdditionalPages.push([])

        const newFindServices = findNeededServices(currentLimit, newServices)
        if (newFindServices) {
            const html = buildServiceTable(newFindServices)
            servicesAdditionalPages[servicesAdditionalPages.length - 1].push(html)
            newServices = newServices.filter(s => s.id !== newFindServices.id)
            servicePageCounter += newFindServices.items.length
            return servicesAdditionalPages
        }
        throw new Error('Too many services')
    })

    return servicesAdditionalPages
}

const findNeededServices = (limit: number, services: ServicesWithId[]) => {
    if (services.length === 0) {
        return null
    }

    if (services[0].items.length > limit) {
        return null
    }
    return services[0]
}

const buildServiceTable = (service: ServicesType) => {
    let html = `<tr>
                    <th colspan="5" style="text-align: center; height: 30px; font-family: 'Times New Roman', Times, serif;">${service.title}</th>
                </tr>
                <tr class="services__header">
                    <th colspan="1" class="center" style="width: 20px !important; height: 32px; text-align: center; border: 1px solid black; font-family: 'Times New Roman', Times, serif;">№</th>
                    <th colspan="2" class="center" style="width: 50%; text-align: center; border: 1px solid black; font-family: 'Times New Roman', Times, serif;">Наименование работ</th>
                    <th colspan="2" class="center" style="width: 45%; text-align: center; border: 1px solid black; font-family: 'Times New Roman', Times, serif;">Примечание</th>
                </tr>
                `
    html += service.items
        .map((item, index) => {
            return `<tr>
                        <td colspan="1" style="width: 20px !important; height: 32px; text-align: center; font-family: 'Times New Roman', Times, serif;border: 1px solid black;">${index + 1 + '.'}</td>
                        <td colspan="2" style="text-align: center; font-family: 'Times New Roman', Times, serif;border: 1px solid black;">${item.name}</td>
                        <td colspan="2" style="text-align: center; font-family: 'Times New Roman', Times, serif;border: 1px solid black;">${item.description || ''}</td>
                    </tr>
                    `
        })
        .join('')

    return html
}

export const generateNewKpPageWithBodyContract = (body: string) => {
    const div = document.createElement('div')
    div.innerHTML = pageTemplate.trim()
    div.querySelector('#additionalPageBody')!.innerHTML = body.trim()
    return div.innerHTML
}

const pageTemplate = `
    <div class='pageWrapper pageWrapper_2 page-break' style='margin-top: 0; position: relative; height: 1122px; padding: 40px 35px; line-height: 1.1; width: 100%'>
        <table style='border-collapse: collapse; border: none; width: 100%; table-layout: fixed; height: 100%;'>
            <tbody>
                <tr>
                    <td style='border: none; vertical-align: baseline; width: 100%;'>
                        <div id='additionalPageBody' style='font-family: "Times New Roman", Times, serif; text-align: justify;'>
                            your code here
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

`

export const buildPageTable = (body: string, format?: PageTemplateOptions['format']) => {
    if (format === 'docx') {
        return `
            <p style="text-align: right; font-family: 'Times New Roman', Times, serif; text-align: justify;">Приложение №1 <br> к Договору №<span class='contractNumber'>___</span><br> <span class='contractDate'>«___» ______20__г.</span></p>
            <table class='services' style="width: 100%; border: 1px solid black; border-collapse: collapse; font-family: 'Times New Roman', Times, serif; text-align: justify;">
                <tr>
                    <th colspan='5' class='center' style='text-align: center; border: 1px solid black;'>
                        Перечень работ
                    </th>
                </tr>
                <tbody id='services'>
                    ${body}
                </tbody>
            </table>
        `
    }
    return `
        <p style='text-align:right;'>Приложение №1 <br> к Договору №<span class='contractNumber'>___</span><br> <span class='contractDate'>«___»
                            ______20__г.</span></p>
        <table class='services' style='width: 100%'>
            <tr>
                <th colspan='5' class='center'>
                    Перечень работ
                </th>
            </tr>
            <tbody id='services'>
                ${body}
            </tbody>
        </table>
    `
}

export const buildLastPageTable = (body: string, format?: PageTemplateOptions['format']) => {
    if (format === 'docx') {
        return `
            <p style='text-align:right; margin: 0;'>Приложение №1 <br> к Договору №<span class='contractNumber'>___</span><br> <span class='contractDate'>«___»
                            ______20__г.</span></p>
            <table class='services' style="width: 100%; border: 1px solid black; border-collapse: collapse; font-family: 'Times New Roman', Times, serif; text-align: justify;">
                <tr>
                    <th colspan='5' class='center' style='text-align: center; border: 1px solid black;'>
                        Перечень работ
                    </th>
                </tr>
                <tbody id='services'>
                    ${body}
                </tbody>
            </table>
            <div align='center'>
                <table class='a1' border='0' cellspacing='0' cellpadding='0' width='669'
                       style='border-collapse:collapse'>
                    <tr>
                        <td width='707' colspan='4' style=';;height:auto'>
                            <p align='center' style='text-align:center;padding:20px'><span
                                style='font-size:10.0pt;font-family:"Times New Roman",serif'> </span><span
                                style='font-size:10.0pt;font-family:"Times New Roman",serif'>ПОДПИСИ СТОРОН</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td width='314' valign='top' style=';'>
                            <p align='center' style='margin-right:-22.05pt;text-align:center'><b><span
                                style='font-size:11.0pt;font-family:"Times New Roman",serif'>Заказчик:</span></b></p>
                        </td>
                        <td width='355' valign='top' style=';'>
                            <p align='center' style='margin-right:-22.05pt;text-align:center'><b><span
                                style='font-size:11.0pt;font-family:"Times New Roman",serif'>Исполнитель:</span></b></p>
                        </td>
                    </tr>
                    <tr style='height:auto;text-align:left'>
                        <td width='314' valign='top' style=';;height:auto'>
                            <p style='background:white'>
                                <b><span style='font-family:"Times New Roman",serif;color:black' class='responsibleFaceName'>Генеральный директор/представитель</span></br></b>
                                <b><span style='font-size:10.0pt;font-family:"Times New Roman",serif;color:black' class='organizationNameShort'>НАИМЕНОВАНИЕ ООО</span></b>
                            </p>
                        </td>
                        <td width='355' style=';;height:auto'>
                            <p align='right' style='text-align:right;background:white'>
                                <b>
                                        <span style='font-family:"Times New Roman",serif;color:black;'>Генеральный директор<br>
                                            <span class='ourOrganizationName'>ООО «Н2О»</span>
                                        </span>
                                </b>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width='314' valign='top' style=';;height:auto'>
                            <p style='margin-right:-22.05pt'>
                                <b><span style='font-family:"Times New Roman",serif'>&nbsp;</span></b>
                            </p>
                            <p style='background:white'>
                                <b><span style='font-family:"Times New Roman",serif;color:black'>________________/<b class='responsibleFaceShortName'>Ф.И.О.</b>/</span></b>
                            </p>
                        </td>
                        <td width='355' style=';;height:auto'>
                            <p align='right' style='text-align:right'>
                                <b><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></b>
                            </p>
                            <p align='right' style='text-align:right;background:white'>
                                <b><span style='font-family:"Times New Roman",serif;color:black;'>________________/<span class='directorShortName'>Исаков А.Л.</span>/
                                    </span></b>
                            </p>
                        </td>
                    </tr>
                </table>
            </div>
        `
    }
    return `
    <p style='text-align:right;'>Приложение №1 <br> к Договору №<span class='contractNumber'>___</span><br> <span class='contractDate'>«___»
                        ______20__г.</span></p>                     
    <table class='services' style='width: 100%'>
        <tr>
            <th colspan='5' class='center'>
                Перечень работ
            </th>
        </tr>
        <tbody id='services'>
            ${body}
        </tbody>
    </table>
    <div class='signing'>
        <div style='text-align: center'><b>ПОДПИСИ СТОРОН</b></div>
        <div class='signing__footer'>
            <div class='signing__left'>
                Заказчик:
            </div>
            <div class='signing__right'>
                Исполнитель:
            </div>
        </div>
        <table class='borderNoneTable' style='width: 100%; margin-bottom: 20px;'>
            <tbody>
                <tr>
                    <td><b class='responsibleFaceName'>Генеральный директор</b> <br>
                        <b class='organizationNameShort'></b>
                    </td>
                    <td class='director__right'>
                        <b>Генеральный директор<br><span class='ourOrganizationName'>ООО 'H2O'</span></b>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class='signing__footer'>
            <div class='signing__left'>
                __________________/<b class='responsibleFaceShortName'>_______________</b>/
            </div>
            <div class='signing__right'>
                __________________/<b class='directorShortName'>Исаков А.Л.</b>/
            </div>
        </div>
    </div>`
}
