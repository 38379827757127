import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './OrderDocuments.scss'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { DocumentGroup } from './DocumentGroup/DocumentGroup'
import { PhotoViewer } from './PhotoViewer/PhotoViewer'
import { DocumentTool } from '~/API/orderApi/types/documents.type'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'

interface OrderDocumentsProps {
    documents: any,
    currentStage: StageType,
    setValue: UseFormSetValue<any>,
    getValues: UseFormGetValues<any>,
    viewer: boolean,
    handleCloseViewer: () => void,
}

interface SortedDocumentsType {
    mainBlock: any,
    orderClosingBlock: any[],
}

const OrderDocuments = (props: OrderDocumentsProps) => {
    const {
        documents,
        currentStage,
        setValue,
        getValues,
        viewer,
        handleCloseViewer,
    } = props
    const [sortedDocuments, setSortedDocuments] = useState<SortedDocumentsType>({
        mainBlock: {},
        orderClosingBlock: [],
    })
    const [currentImage, setCurrentImage] = useState<string>('')

    const handleSetCurrentImage = useCallback((id: string) => {
        setCurrentImage(id)
    }, [])

    const getAllFiles = useMemo((): {
        id: string,
        inputName: string,
        name: string,
    }[] => {
        if (sortedDocuments) {
            const allDocuments = { ...sortedDocuments.mainBlock, orderClosingBlock: sortedDocuments?.orderClosingBlock }
            return Object.entries(allDocuments).reduce((accum: {
                id: string,
                inputName: string,
                name: string,
            }[], [_, files]: [_: any, files: any]) => {
                return [...accum, ...files]
            }, [])
        }
        return []
    }, [sortedDocuments])

    const handlePhotoDelete = useCallback((photoIds: string[]) => {
        photoIds.forEach((fileId) => {
            getAllFiles.forEach((file: any) => {
                if (file.id === fileId) {
                    const files = getValues(file.name)
                    if (Array.isArray(files)) {
                        const newArray = [...files]
                        const index = files.findIndex((e) => e === file.id || e?.fileId === file.id)
                        if (index !== -1) {
                            newArray.splice(index, 1)
                            setValue(file.name, newArray)
                        }
                    } else {
                        setValue(file.name, null)
                    }
                }
            })
        })
    }, [getAllFiles])

    const buildDocsForArraySection = (sectionName: string, files: any[]) => {
        if (!files) return []
        return files.flatMap((file, i) =>
            file && Object.entries(file).reduce((accum: any[], [name, ids]: [name: any, ids: any]) => {
                if (!ids) return accum

                if (Array.isArray(ids)) {
                    if (ids.length === 0) return accum
                    return [
                        ...accum,
                        ...ids.map((e) => ({
                            id: e,
                            name: 'documents.' + sectionName + '.' + i + '.' + name,
                            inputName: name,
                        })),
                    ]
                } else if (typeof ids === 'string') {
                    return [
                        ...accum,
                        {
                            name: 'documents.' + sectionName + '.' + i + '.' + name,
                            id: ids,
                            inputName: name,
                        },
                    ]
                } else {
                        // ids is object
                    const inputs = Object.entries(ids).flatMap(([inputName, files]) => {
                        return (files as DocumentTool[]).map((file) => ({
                            id: file.fileId,
                            inputName,
                            name: 'documents.' + sectionName + '.' + i + '.' + name + '.' + inputName,
                        }))
                    })

                    return [
                        ...accum,
                        ...inputs,
                    ]
                }
            }, []),
        )
    }

    const buildDocsForSimpleSection = (sectionName: string, files: any): { id: string, inputName: string, name: string }[] => {
        if (!files) return []
        return Object.entries(files).reduce((sectionFiles: any[], [name, ids]: [name: any, ids: any]) => {
            if (!ids) return sectionFiles

            if (Array.isArray(ids)) {
                if (ids.length === 0) return sectionFiles
                return [
                    ...sectionFiles,
                    ...ids.map((e) => ({
                        id: e,
                        name: 'documents.' + sectionName + '.' + name,
                        inputName: name,
                    })),
                ]
            } else {
                return [
                    ...sectionFiles,
                    {
                        name: 'documents.' + sectionName + '.' + name,
                        id: ids,
                        inputName: name,
                    },
                ]
            }
        }, [])
    }

    const buildDocsForActualAmortization = (sectionName: string, files: any) => {
        const result: any[] = []

        for (const key in files) {
            if (Array.isArray(files[key])) {
                files[key].forEach((item: any, index: number) => {
                    if (item) {
                        result.push({
                            name: `documents.${sectionName}.${key}`,
                            id: item.fileId,
                            inputName: 'actualAmortization',
                        })
                    }
                })
            }
        }

        return result
    }

    useEffect(() => {
        if (documents) {
            let mainBlock: any = {}
            let orderClosingBlock: any[] = []
            Object.entries(documents).forEach(([sectionName, files]: [sectionName: string, files: any]) => {
                if (!files || !sectionName) return

                switch (sectionName) {
                    case 'personnelInfo': {
                        const newIds = buildDocsForSimpleSection(sectionName, files)
                        if (newIds.length !== 0) {
                            newIds.forEach((file) => {
                                if (!mainBlock[file.inputName]) {
                                    mainBlock[file.inputName] = [file]
                                } else {
                                    mainBlock[file.inputName].push(file)
                                }
                            })
                        }
                        return
                    }
                    case 'foremanReport': {
                        mainBlock = {
                            ...mainBlock,
                            foremanReport: [
                                ...buildDocsForSimpleSection('foremanReport', { ...files, daysReport: null }),
                                ...buildDocsForArraySection('foremanReport.daysReport', files.daysReport),
                            ],
                        }
                        return
                    }
                    case 'toolsReport': {
                        const { actualAmortization, ...restFiles } = files
                        mainBlock = {
                            ...mainBlock,
                            toolsReport: [
                                ...buildDocsForSimpleSection('toolsReport', { ...restFiles, daysReport: null }),
                                ...buildDocsForActualAmortization('toolsReport.actualAmortization', actualAmortization),
                            ],
                        }
                        return
                    }
                    case 'orderClosing': {
                        orderClosingBlock = buildDocsForArraySection(sectionName, files)
                            .filter((element) => element !== null)
                        // ATTENTION!!! this is a solution to work around a bug with the generation
                        // of a null object in the orderClosing section
                        return
                    }
                    default: {
                        const newIds = buildDocsForSimpleSection(sectionName, files)
                        if (newIds.length !== 0) {
                            mainBlock = {
                                ...mainBlock,
                                [sectionName]: newIds,
                            }
                        }
                    }
                }
            })

            setSortedDocuments({ mainBlock, orderClosingBlock })
        }
    }, [documents])

    return (
        <div className='orderDocuments'>
            {sortedDocuments &&
                Object.entries(sortedDocuments.mainBlock).map(([section, files]) => (
                    <DocumentGroup
                        name={section}
                        files={files}
                        key={'dg' + section}
                        currentStage={currentStage}
                        setValue={setValue}
                        getValues={getValues}
                    />
                ))}
            {sortedDocuments && sortedDocuments.orderClosingBlock.length > 0 && (
                <DocumentGroup
                    name='orderClosing'
                    files={sortedDocuments.orderClosingBlock}
                    key='dgOC'
                    currentStage={currentStage}
                    setValue={setValue}
                    getValues={getValues}
                />
            )}
            {viewer && handleCloseViewer && (
                <PhotoViewer
                    currentStage={currentStage}
                    handleCloseViewer={handleCloseViewer}
                    photoId={currentImage}
                    files={getAllFiles}
                    onDelete={handlePhotoDelete}
                />
            )}
        </div>
    )
}

export default OrderDocuments
