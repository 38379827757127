import { useCallback } from 'react'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import { closeArchivePopup } from '~/redux/slices/popupSlice/popupSlice'
import { useAppDispatch } from '~/redux/store'

export const useOrderToArchive = () => {
    const dispatch = useAppDispatch()

    return useCallback((reason: string) => {
        dispatch(changeFormAction({ action: 'TO_ARCHIVE', reason }))
        dispatch(closeArchivePopup())
    }, [dispatch])
}
