import clsx from 'clsx'
import { FaAngleRight } from 'react-icons/fa6'
import './MobileTitleButton.scss'

interface MobileTitleButtonProps {
    title: string
    onClick: () => void
    isError?: boolean
}

const MobileTitleButton = (props: MobileTitleButtonProps) => {
    const {
        title,
        onClick,
        isError,
    } = props

    return (
        <button
            type='button'
            className={clsx('mobileTitleButton', { 'mobileTitleButton--error': isError })}
            onClick={onClick}
        >
            <span>{title}</span>
            <FaAngleRight size={16} />
        </button>
    )
}

export default MobileTitleButton
