import '../../StaffScheduleFilter.scss'
import { FaCheck } from '@react-icons/all-files/fa/FaCheck'
import clsx from 'clsx'
import React from 'react'
import { useSelector } from 'react-redux'
import { User } from '~/API/userApi/user.types'
import { getScheduleSelector } from '~/redux/selectors/staffSchedule.selector'

type Props = {
    personnelDB: User[]
    propsRole: string
}

const StaffScheduleFilterPersonalGroup = ({ propsRole, personnelDB }: Props) => {
    const schedule = useSelector(getScheduleSelector)

    const active = (id: string) => {
        return schedule.some((s) => s.personnel.id === id)
    }

    return (
        <>
            {
                personnelDB
                    .filter((user) => user.role === propsRole)
                    .sort((a, b) => a.fullname.localeCompare(b.fullname))
                    .map((pers: any) => {
                        return (
                            <div className='scheduleFilter__personnelList__item'
                                key={'scheduleFilter__personnelList__item' + pers.id}>
                                <div
                                    className={clsx(
                                        'scheduleFilter__personnelList__item__check',
                                        active(pers.id) && 'personSchedule__active',
                                    )}
                                >
                                    <FaCheck />
                                </div>
                                <p>{pers.fullname}</p>
                            </div>
                        )
                    })
            }
        </>
    )
}

export default StaffScheduleFilterPersonalGroup
