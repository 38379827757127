import './ErrorPage.scss'
import { useEffect, useMemo } from 'react'
import { FaRegSmile } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { setPageError } from '~/redux/slices/appSlice/appSlice'
import { useAppDispatch } from '~/redux/store'

export enum ErrorPageThemes {
    NOT_FOUND = 'not_found',
    ERROR = 'error',
}

interface ErrorPageProps {
    theme: ErrorPageThemes
    errorCode?: number
    title?: string
}

const ErrorPage = (props: ErrorPageProps) => {
    const {
        theme,
        errorCode = 500,
        title= 'Но это не повод расстраиваться, это повод написать в любимый отдел разработки🥰',
    } = props
    const dispatch = useAppDispatch()
    const reloadPage = () => {
        location.reload()
    }

    useEffect(() => {
        dispatch(setPageError(true))

        return () => {
            dispatch(setPageError(false))
        }
    }, [dispatch])

    const ErrorBody = useMemo(() => {
        switch (theme) {
            case ErrorPageThemes.NOT_FOUND: {
                return (
                    <div className='not-found-page__container'>
                        <span className='not-found-page__title'>
                            404
                        </span>
                        <span className='not-found-page__body'>
                            Страница не найдена
                        </span>
                        <span className='not-found-page__description'>
                            Извините, но страница, которую вы ищете, не существует
                        </span>
                        <Link className='not-found-page__link' to={'/'}>
                            Вернуться домой
                        </Link>
                    </div>
                )
            }
            default: {
                return (
                    <div className='not-found-page__container'>
                        <span className='not-found-page__title'>
                            {errorCode}
                        </span>
                        <span className='not-found-page__body'>
                            Произошла непредвиденная ошибка
                        </span>
                        <span className='not-found-page__description'>
                            {title}
                        </span>
                        <button
                            type='button'
                            className='not-found-page__link'
                            onClick={reloadPage}
                        >
                            Обновить страницу
                        </button>
                    </div>
                )
            }
        }
    }, [errorCode, theme, title])

    return (
        <div className='not-found-page'>
            <div className='not-found-page__container'>
                <div className='not-found-page__icon'>
                    <FaRegSmile />
                </div>
            </div>
            {ErrorBody}
        </div>
    )
}

export default ErrorPage
