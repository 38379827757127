import { useSaveOrderMutation, useUpdateOrderMutation } from '~/API/orderApi/order'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import { SHOW_NOTICE } from '~/redux/slices/notificationSlice/notificationSlice'
import {
    CHANGE_ACTIVE_ORDER,
    UPDATE_ORDER,
    UPDATE_ORDER_FAILED,
    UPDATE_ORDER_REQUESTED,
    UPDATE_ORDER_SUCCESS,
} from '~/redux/slices/orderSlice/orderSlice'
import { useAppDispatch } from '~/redux/store'

export const useUpdateOrder = () => {
    const dispatch = useAppDispatch()

    const [updateOrder] = useUpdateOrderMutation()
    const [saveOrder] = useSaveOrderMutation()

    return async (order: any) => {
        dispatch(UPDATE_ORDER(order))
        try {
            dispatch(UPDATE_ORDER_REQUESTED())
            let newOrder
            if (order.save) {
                newOrder = await saveOrder(order).unwrap()
            } else {
                newOrder = await updateOrder(order).unwrap()
            }
            dispatch(CHANGE_ACTIVE_ORDER(newOrder))
            dispatch(UPDATE_ORDER_SUCCESS())
            dispatch(SHOW_NOTICE({ type: 'success', message: 'Форма успешно сохранена!' }))
        } catch (e) {
            dispatch(UPDATE_ORDER_FAILED())
            dispatch(SHOW_NOTICE({ type: 'error', message: 'Ошибка при сохранении!' }))
        } finally {
            dispatch(changeFormAction({ action: 'RESET_FORM' }))
        }
    }
}
