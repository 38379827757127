import { Dayjs } from 'dayjs'
import { ForemanReportType } from '~/API/orderApi/types/foremanReport.type'
import { Division } from '~/API/userApi/user.types'
import { User } from '~/API/userApi/user.types'
import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'
import {
    CheckTypes,
} from '~/components/common/ReusingComponents/TabsTable/subComponents/TabsTableHeader/subComponents/TabsTableCheckIcon/TabsTableCheckIcon'
import { TabsTableMods } from '~/components/common/ReusingComponents/TabsTable/TabsTable'
import { MobileComponentKeys } from '~/hooks/useFormConstructor/useFormConstructor.types'
import { rules } from '~/shared/order/rules'
import { StageManager, VERSIONS } from '~/shared/order/StageManager'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { additionalInputs } from '~/shared/templates/inputTemplates/v2/additionalInputs'

export const FOREMAN_REPORT_ACTIONS = {
    mainActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'START_NEW_DAY',
                text: 'Завершить рабочий день',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
    lastDayActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'WAREHOUSE_GOING',
                text: 'Перейти к возврату ТМЦ',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
    warehouseActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'INVENTORY_ITEMS_RETURNING',
                text: 'На складе',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
}

export const FOREMAN_REPORT_TEAM_SECTIONS = {
    division: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Подразделение',
        name: 'division',
        className: 'input100',
        type: 'select',
        items: [
            { value: 'B2C', text: 'B2C' },
            { value: 'B2B (ПРР)', text: 'B2B (ПРР)' },
        ],
    }),
    foreman: (disabled: boolean, division?: string) => ({
        disabled: disabled,
        title: 'Менеджер по клинингу',
        name: 'foreman',
        type: 'user',
        role: ['FOREMAN', 'ORDER_MANAGER'],
        division: division,
    }),
    reportDate: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Дата отчета',
        name: 'reportDate',
        className: 'input33',
        singleDate: true,
        type: 'date',
    }),
    actualTitle: () => ({
        type: 'title',
        title: 'Фактические данные',
    }),
    cleaningDate: (disabled: boolean, required?: boolean) => ({
        disabled: disabled,
        title: 'Дата рабочего дня',
        name: 'cleaningDate',
        className: 'input50',
        type: 'date',
        singleDate: true,
        required: required,
    }),
    actualObjectArea: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Квадратура(м2)',
        name: 'actualObjectArea',
        className: 'input20',
        type: 'text',
        mask: InputMask.NUMBER,
    }),
    actualDurationCleaningTime: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Время(ч)',
        name: 'actualDurationCleaningTime',
        className: 'input20',
        type: 'text',
        mask: InputMask.NUMBER,
    }),
    actualCleanerNumber: (disabled: boolean) => ({
        required: false,
        disabled: disabled,
        title: 'Кол-во клинеров',
        name: 'actualCleanerNumber',
        className: 'input20',
        type: 'text',
    }),
    actualEmployeesSalary: (disabled: boolean) => ({
        required: false,
        disabled: disabled,
        title: 'Работники(ФОТ)',
        name: 'actualEmployeesSalary',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
    }),
    actualExpansesTaxiThere: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Расходы на такси туда',
        name: 'actualExpansesTaxiThere',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
        required: true,
    }),
    actualExpansesTaxiBack: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Расходы на такси обратно',
        name: 'actualExpansesTaxiBack',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
        required: true,
    }),
    actualExpansesPetrol: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Расходы на бензин',
        name: 'actualExpansesPetrol',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
        required: true,
    }),
    actualParking: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Расходы на паркинг',
        name: 'actualParking',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
        required: true,
    }),
    actualRentTMC: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Аренда ТМЦ',
        name: 'actualRentTMC',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
    }),
    actualContractWork: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Подрядные раб.',
        name: 'actualContractWork',
        className: 'input20',
        type: 'text',
        mask: InputMask.COST,
    }),
    actualTeam: (disabled: boolean, index: number, props?: any) => {
        const inputs = [
            {
                className: 'td25',
                name: 'personnelId',
                title: 'Сотрудник',
                placeholder: 'Укажите сотрудника',
                type: 'select',
                required: true,
                search: true,
                isSubscription: true,
            },
            {
                name: 'jobPosition',
                title: 'Должность',
                multiline: true,
                placeholder: 'Введите должность',
                required: true,
            },
            {
                className: 'td7',
                name: 'salary',
                title: 'Ставка',
                defaultValue: 0,
                mask: InputMask.NUMBER_WITH_SEPARATOR,
                placeholder: 'Введите ставку',
                required: true,
            },
            {
                className: 'td7',
                name: 'addSalary',
                title: 'Доп. выплата',
                defaultValue: 0,
                mask: InputMask.NUMBER_WITH_SEPARATOR,
                placeholder: '0',
                required: false,
            },
            {
                name: 'status',
                className: 'td15',
                title: 'Статус работы',
                defaultValue: 'WORKING',
                placeholder: 'Выберите статус',
                disabled: true,
                type: 'select',
                items: [
                    {
                        value: 'WORKING',
                        text: 'Работает',
                    },
                    {
                        value: 'NOT_WORKING',
                        text: 'Не работает',
                    },
                ],
            },
            {
                className: 'td7',
                name: 'rate',
                title: 'Оценка',
                type: 'select',
                items: [
                    {
                        value: 0,
                        text: '-',
                    },
                    {
                        value: 1,
                        text: '1',
                    },
                    {
                        value: 2,
                        text: '2',
                    },
                    {
                        value: 3,
                        text: '3',
                    },
                    {
                        value: 4,
                        text: '4',
                    },
                    {
                        value: 5,
                        text: '5',
                    },
                ],
                required: true,
                placeholder: '1-5',
            },
            {
                name: 'comment',
                title: 'Комментарий',
                multiline: true,
                placeholder: 'Напишите комментарий',
                required: true,
            },
            {
                name: 'memo',
                title: 'Объяснительная записка',
                type: 'file',
            },
        ]
        return ({
            version: VERSIONS.V2,
            required: false,
            disabled: disabled,
            name: 'actualTeam',
            usingData: `documents.foremanReport.daysReport.${index}.actualTeam`,
            mobileComponent: MobileComponentKeys.PERSONNEL,
            type: 'table',
            title: 'Бригада',
            withHideButton: true,
            itemsNotNull: true,
            alwaysShowHeader: true,
            tabsTableMode: TabsTableMods.SINGLE,
            hocType: 'personnel',
            withToolbar: true,
            checkIcons: {
                inputName: 'status',
                checkType: CheckTypes.NOT_NULL,
            },
            tabIndex: index,
            inputs,
            headerSections: [
                {
                    inputs: [
                        {
                            name: 'personnelId',
                            title: 'Сотрудник',
                            placeholder: 'Укажите сотрудника',
                            type: 'personnel',
                            required: true,
                            search: true,
                            isSubscription: true,
                        },
                        {
                            name: 'jobPosition',
                            title: 'Должность',
                            multiline: true,
                            placeholder: 'Введите должность',
                            required: true,
                        },
                        {
                            name: 'salary',
                            title: 'Ставка',
                            defaultValue: 0,
                            mask: InputMask.NUMBER_WITH_SEPARATOR,
                            placeholder: 'Введите ставку',
                            required: true,
                            rules: {
                                min: 1,
                            },
                        },
                        {
                            name: 'addSalary',
                            title: 'Доп. выплата',
                            defaultValue: 0,
                            mask: InputMask.NUMBER_WITH_SEPARATOR,
                            placeholder: '0',
                            required: false,
                        },
                        {
                            name: 'status',
                            title: 'Статус работы',
                            defaultValue: 'WORKING',
                            placeholder: 'Выберите статус',
                            type: 'select',
                            disabled: true,
                            items: [
                                {
                                    value: 'WORKING',
                                    text: 'Работает',
                                },
                                {
                                    value: 'NOT_WORKING',
                                    text: 'Не работает',
                                },
                            ],
                        },
                        {
                            name: 'rate',
                            title: 'Оценка',
                            type: 'select',
                            items: [
                                {
                                    value: 0,
                                    text: '-',
                                },
                                {
                                    value: 1,
                                    text: '1',
                                },
                                {
                                    value: 2,
                                    text: '2',
                                },
                                {
                                    value: 3,
                                    text: '3',
                                },
                                {
                                    value: 4,
                                    text: '4',
                                },
                                {
                                    value: 5,
                                    text: '5',
                                },
                            ],
                            required: true,
                            placeholder: '1-5',
                        },
                        {
                            name: 'comment',
                            title: 'Комментарий',
                            defaultValue: '',
                            multiline: true,
                            placeholder: 'Напишите комментарий',
                            required: true,
                        },
                        {
                            name: 'memo',
                            title: 'Служебная записка',
                            type: 'file',
                        },
                    ],
                },
            ],
        })
    },
    foremanComment: (disabled: boolean) => ({
        disabled: disabled,
        name: 'foremanComment',
        title: 'Комментарий по бригаде',
        type: 'text',
        multiline: true,
    }),
}

interface FOREMAN_REPORT_TEAM_PARAMS {
    businessType: Division,
    stages: string[],
    currentStage: string,
    user: User,
    archived: boolean,
    responsibleUserId: string,
    cleaningDate: {
        startDate: Date | string | Dayjs,
        endDate: Date | string | Dayjs,
    },
    foremanReport: ForemanReportType,
    division: Division,
    days: number,
    isAllChecks?: boolean
    lastDayReportDate?: Date
}

export const FOREMAN_REPORT_TEAM = (params: FOREMAN_REPORT_TEAM_PARAMS) => {
    const {
        businessType,
        stages,
        currentStage,
        user,
        archived,
        responsibleUserId,
        foremanReport,
        division,
        days,
        isAllChecks,
        lastDayReportDate,
    } = params
    if (rules[user?.role]?.sections.foremanReportWarehouse || responsibleUserId === user.id) {
        const availableAction = StageManager.checkAvailableStageByRole('TEAM_REPORT_FILLING', user.role) || responsibleUserId === user.id || (user.role === 'FOREMAN' && user.division.includes(division))
        const isManagerChecking = stages.includes('DEFECTS_CORRECTING') || stages.includes('QUALITY_CONTROLLING')
        const tabs = new Array(days)
            .fill('pashtet_lover_1337')
            .map((_, index) => {
                if (index > foremanReport.currentDayNumber) {
                    return {
                        index,
                        name: 'foremanReport.daysReport.' + index,
                        inputs: [],
                        label: 'День ' + (index + 1),
                        disabled: true,
                    }
                }
                const newInputs = []
                const isCurrentDay: boolean = foremanReport.currentDayNumber === index && !lastDayReportDate
                const isFirstDay = index === 0
                const reportDate = foremanReport.daysReport[index].reportDate

                if (reportDate) {
                    newInputs.push([
                        additionalInputs.division(true, 'foremanReport.division'),
                        additionalInputs.responsibleUserId(true, 'foremanReport.division', 'foreman'),
                        additionalInputs.reportDate(true),
                    ])
                } else {
                    newInputs.push([
                        additionalInputs.division(true, 'foremanReport.division'),
                        additionalInputs.responsibleUserId(true, 'foremanReport.division', 'foreman'),
                    ])
                }
                let resultDisable = !isCurrentDay && !!foremanReport.daysReport[index].reportDate
                if (user.role === 'ORDER_MANAGER' && user.division.includes(businessType)) {
                    resultDisable = false
                }
                if (isManagerChecking) {
                    resultDisable = true
                }
                if (foremanReport.daysReport[index].endWorkingDate && availableAction) {
                    newInputs.push([
                        FOREMAN_REPORT_TEAM_SECTIONS.cleaningDate(resultDisable),
                        FOREMAN_REPORT_TEAM_SECTIONS.actualDurationCleaningTime(true),
                        FOREMAN_REPORT_TEAM_SECTIONS.actualObjectArea(resultDisable),
                    ])
                    newInputs.push([
                        FOREMAN_REPORT_TEAM_SECTIONS.actualRentTMC(resultDisable),
                        FOREMAN_REPORT_TEAM_SECTIONS.actualContractWork(resultDisable),
                        additionalInputs.additionalExpanses(resultDisable),
                    ])
                    newInputs.push([
                        FOREMAN_REPORT_TEAM_SECTIONS.actualExpansesTaxiThere(resultDisable),
                        FOREMAN_REPORT_TEAM_SECTIONS.actualExpansesTaxiBack(resultDisable),
                    ])
                    newInputs.push([
                        FOREMAN_REPORT_TEAM_SECTIONS.actualExpansesPetrol(resultDisable),
                        FOREMAN_REPORT_TEAM_SECTIONS.actualParking(resultDisable),
                    ])
                    newInputs.push([
                        FOREMAN_REPORT_TEAM_SECTIONS.actualTeam(resultDisable, index),
                    ])
                    newInputs.push([FOREMAN_REPORT_TEAM_SECTIONS.foremanComment(resultDisable)])
                    newInputs.push([additionalInputs.workInterimProcess(resultDisable, `documents.foremanReport.daysReport.${index}.workInterimProcess`)])
                }
                if (availableAction && isCurrentDay) {
                    const action = fetchFormAction({
                        archived,
                        section: 'FOREMAN_REPORT_TEAM',
                        user,
                        currentStage,
                        responsibleUserId,
                        isAllChecks,
                        lastWorkingDay: foremanReport.lastWorkingDay,
                    })
                    if (action) {
                        newInputs.push([action])
                    }
                } else if (currentStage === 'WAREHOUSE_GOING') {
                    const action = fetchFormAction({
                        archived,
                        section: 'FOREMAN_REPORT_TEAM',
                        user,
                        currentStage,
                        responsibleUserId,
                        division: foremanReport.division,
                        lastWorkingDay: foremanReport.lastWorkingDay,
                    })
                    if (action) {
                        newInputs.push([action])
                    }
                }
                return {
                    index,
                    name: 'foremanReport.daysReport.' + index,
                    inputs: newInputs,
                    label: 'День ' + (index + 1),
                }
            })
            .filter((_, index) => {
                return foremanReport.daysReport[index].foreman === user.id || user.role === 'CHIEF' || user.role === 'ORDER_MANAGER'
            })
            .map((item, index) => {
                return { ...item, index }
            })

        return {
            title: 'Отчет Менеджера по клинингу (бригада)',
            tabs,
            defaultTab: tabs.length - 1,
            multiple: true,
        }
    }
    return {
        title: 'Отчет Менеджера по клинингу (склад)',
        name: 'foremanReport.daysReport.0',
        inputs: [],
    }
}
