import { Division } from '~/API/userApi/user.types'

export const getGlobalDivision = () => {
    const division = localStorage.getItem('division') as Division
    return division || 'B2C'
}

export const setGlobalDivision = (division: Division) => {
    localStorage.setItem('division', division)
}
