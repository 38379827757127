import {
    ClientAddressResponse,
    ClientResponse,
    ClientServiceResponse,
    FetchAllClientsParams,
    FetchClientsByAddressParams,
    FetchClientServicesParams,
} from '~/API/clientApi/client.types'
import { fetchPath } from '~/API/lib/path/path'
import { rtkApi } from '~/shared/api/rtkApi'

const path = fetchPath('client')

const clientApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAllClients: build.query<ClientResponse<never>[], FetchAllClientsParams>({
            query: ({ counterparty, query }) => {
                return ({
                    method: 'get',
                    url: path,
                    params: { counterparty, query },
                })
            },
            providesTags: ['Client'],
        }),
        fetchClientsByAddress: build.query<string[], FetchClientsByAddressParams>({
            query: ({ clientId }) => {
                return ({
                    method: 'get',
                    url: path + '/address',
                    params: { clientId },
                })
            },
            providesTags: ['Client'],
        }),
        fetchClientServices: build.query<ClientServiceResponse[], FetchClientServicesParams>({
            query: ({ clientId, address }) => {
                return ({
                    method: 'get',
                    url: path + '/services',
                    params: { clientId, address },
                })
            },
            providesTags: ['Client'],
        }),
    }),
})

export const {
    useFetchAllClientsQuery,
    useFetchClientsByAddressQuery,
    useFetchClientServicesQuery,
    useLazyFetchAllClientsQuery,
} = clientApi
