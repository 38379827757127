import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomSelect from '../../../../common/inputs/CustomSelect/CustomSelect'
import { useLazyFetchUsersByRoleQuery } from '~/API/userApi/user'
import { SELECT_SCHEDULE_PERSONNEL } from '~/redux/selectors/appSlice.selectors'
import { CHANGE_SCHEDULE_PERSONNEL, changeFormAction } from '~/redux/slices/appSlice/appSlice'
import { useAppDispatch } from '~/redux/store'
import './ScheduleFilter.scss'

const ScheduleFilter = () => {
    const dispatch = useAppDispatch()
    const [fetchUsersByRole] = useLazyFetchUsersByRoleQuery()
    const personnel = useSelector(SELECT_SCHEDULE_PERSONNEL)
    const [personnelDB, setPersonnelDB] = useState<any[]>([])

    const onSubmit = () => {
        dispatch(
            changeFormAction({
                action: 'SAVE_SCHEDULE',
                personnelId: personnel,
                fullname: personnelDB.find((pers) => pers.id === personnel).fullname,
            }),
        )
    }

    const onDelete = () => {
        dispatch(
            changeFormAction({
                action: 'DELETE_SCHEDULE',
                personnelId: personnel,
                fullname: personnelDB.find((pers) => pers.id === personnel).fullname,
            }),
        )
    }

    const onCancel = () => {
        dispatch(CHANGE_SCHEDULE_PERSONNEL(null))
    }

    useEffect(() => {
        fetchUsersByRole({ role: 'FOREMAN' }, true).unwrap().then((personnel) => setPersonnelDB(personnel))
        return () => {
            dispatch(CHANGE_SCHEDULE_PERSONNEL(null))
        }
    }, [])

    return (
        <div className='scheduleFilter'>
            <h5>Выбор сотрудника</h5>
            <CustomSelect
                value={personnel}
                onChange={(value) => dispatch(CHANGE_SCHEDULE_PERSONNEL(value))}
                title='Сотрудник'
                name='scheduleFilter.personnel'
                disabled={personnelDB.length === 0}
                items={personnelDB.map((pers) => ({ value: pers.id, text: pers.fullname }))}
            />
            {personnel && (
                <p>Выбран сотрудник <span>{personnelDB.find((pers) => pers.id === personnel).fullname}</span></p>
            )}
            {personnel && (
                <div className='scheduleFilter__buttons'>
                    <button
                        className='button button_border-none'
                        onClick={onSubmit}
                    >
                        Применить
                    </button>
                    <button
                        className='button button_border-none delete'
                        onClick={onDelete}
                    >
                        Удалить расписание
                    </button>
                    <button
                        className='button button_bc-grey'
                        onClick={onCancel}
                    >
                        Отмена
                    </button>
                </div>
            )}
        </div>
    )
}

export default ScheduleFilter
