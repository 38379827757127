import { useCallback } from 'react'
import { useLazyFetchOrderQuery } from '~/API/orderApi/order'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import {
    CHANGE_ACTIVE_ORDER,
    CHANGE_ORDER_LOADER,
    FETCH_ORDER_FAILED,
    FETCH_ORDER_REQUESTED,
    FETCH_ORDER_SUCCESS,
} from '~/redux/slices/orderSlice/orderSlice'
import { useAppDispatch } from '~/redux/store'

export const useFetchExistOrder = () => {
    const dispatch = useAppDispatch()
    const [fetchOrder] = useLazyFetchOrderQuery({
        refetchOnFocus: false,
    })

    return useCallback((id: string) => {
        dispatch(FETCH_ORDER_REQUESTED())
        dispatch(CHANGE_ORDER_LOADER(true))
        fetchOrder(id, true).unwrap()
            .then((order) => {
                dispatch(CHANGE_ACTIVE_ORDER(order))
                dispatch(changeFormAction({ action: 'RESET_FORM' }))
                dispatch(FETCH_ORDER_SUCCESS())
            })
            .catch((e) => {
                dispatch(FETCH_ORDER_FAILED(e))
            })
            .finally(() => {
                dispatch(CHANGE_ORDER_LOADER(false))
            })
    }, [])
}
