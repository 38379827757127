import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import { memo, useCallback, useEffect, useRef } from 'react'
import { CustomPopupWrapper } from '~/components/common/ReusingComponents/CustomPopupWrapper/CustomPopupWrapper'
import './CustomPopup.scss'
import { useKeyPress } from '~/hooks/useKeyPress'

interface CustomPopupProps {
    onClose: (...args: any) => void
    title: {
        title: string,
        className?: string,
    }
    body?: {
        className?: { title: string, index: number },
        data: JSX.Element[][] | JSX.Element[],
    }
    footer: JSX.Element[]
    className?: string
    widthAuto?: boolean
}

export const CustomPopup = memo(function CustomPopup(props: CustomPopupProps) {
    const {
        onClose,
        title,
        body,
        footer,
        className,
        widthAuto,
    } = props

    const isEscapePressed = useKeyPress('Escape')
    const ref = useRef<HTMLDivElement>(null)

    const handleClose = useCallback(() => {
        onClose()
    }, [])

    useEffect(() => {
        if (isEscapePressed) {
            onClose()
        }
    }, [isEscapePressed])

    return (
        <CustomPopupWrapper
            onClose={handleClose}
            innerElementRef={ref}
        >
            <div
                className={clsx('custom-popup', className, {
                    'custom-popup--widthAuto': widthAuto,
                })}
                ref={ref}
            >
                <div className={clsx('custom-popup__header', title.className)}>
                    <h3>{title.title}</h3>
                    <CloseIcon
                        className='custom-popup__icon-close'
                        onClick={onClose}
                    />
                </div>
                {body && (
                    <div
                        className={clsx('custom-popup__body', {
                            'custom-popup__body__constructor': !Array.isArray(body.data[0]),
                            'custom-popup__body--no-footer': !footer.length,
                        })}>
                        {!Array.isArray(body.data[0]) ? body.data :
                            body.data.map((row, index) => {
                                if (Array.isArray(row) && !!row.length) {
                                    return (
                                        <div
                                            key={`custom-popup__body__${index}`}
                                            className={clsx('custom-popup__body__row',
                                                            { [(body?.className?.title || '')]:
                                                                index === body?.className?.index ||
                                                                body?.className?.index === -1 },
                                            )}>
                                            {row}
                                        </div>
                                    )
                                }
                                return null
                            },
                            )}
                    </div>
                )}
                <div className='custom-popup__footer'>
                    {footer}
                </div>
            </div>
        </CustomPopupWrapper>
    )
})
