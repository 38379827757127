import clsx from 'clsx'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import Carousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { FaCaretLeft, FaCaretRight, FaCheck, FaRegCircle, FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import Preloader from '../../../../../common/preloaders/Preloader/Preloader'
import DocumentManager from '../../../../../common/ReusingComponents/DocumentManager/DocumentManager'
import { checkFilesInputName, isFileImage } from '../documentLib'
import { fetchFile, useLazyFetchFileModelQuery } from '~/API/fileApi/file'
import { Portal } from '~/components/common/ReusingComponents/Portal/Portal'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { fetchFileRules } from '~/shared/order/filesRules'
import './PhotoViewer.scss'

interface DesktopPhotoViewerProps {
    handleCloseViewer: () => void
    photoId: string
    files: {
        id: string,
        inputName: string,
        name: string,
    }[]
    currentStage?: string
    onDelete: (photoIds: string[]) => void
}

interface PhotoType {
    src: string,
    id: string,
    deletable: boolean,
    fileNumber: number,
    fileIndex: number,
}

const responsive = {
    0: {
        items: 1,
    },
    568: {
        items: 2,
        itemsFit: 'contain',
    },
    900: {
        items: 3,
        itemsFit: 'contain',
    },
    1150: {
        items: 4,
        itemsFit: 'contain',
    },
    1300: {
        items: 5,
        itemsFit: 'contain',
    },
    1500: {
        items: 6,
        itemsFit: 'contain',
    },
    1723: {
        items: 7,
        itemsFit: 'contain',
    },
    1980: {
        items: 8,
        itemsFit: 'contain',
    },
    2500: {
        items: 11,
        itemsFit: 'contain',
    },
}

export const PhotoViewer = memo(function PhotoViewer(props: DesktopPhotoViewerProps) {
    const {
        handleCloseViewer,
        files,
        photoId,
        currentStage,
        onDelete,
    } = props
    const [fetchFileModel] = useLazyFetchFileModelQuery()
    const user = useSelector(selectUser)
    const [currentImage, setCurrentImage] = useState<PhotoType>({
        src: '',
        id: photoId,
        deletable: false,
        fileNumber: 0,
        fileIndex: 0,
    })
    const [imagesMap, setImagesMap] = useState<Map<string, PhotoType>>(new Map())
    const [imagesCount, setImagesCount] = useState<number>(0)
    const [body, setBody] = useState<JSX.Element>(files.length === 0 ?
        <p className='viewer__body__img_error'>Нет изображений</p> :
        <Preloader withoutBackground={true} />,
    )
    const [isLoading, setLoading] = useState<boolean>(true)
    const [selectedImages, setSelectedImages] = useState<Map<string, number>>(new Map())
    const ref = useRef<HTMLDivElement>(null)
    const carouselRef = useRef<Carousel>(null)

    const handleWrapperClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
            handleCloseViewer()
        }
    }

    const handleDelete = () => {
        if (selectedImages.size > 0) {
            if (selectedImages.size === imagesMap.size) {
                setBody(<p className='viewer__body__img'>Нет изображений</p>)
                setCurrentImage({ src: '', id: photoId, deletable: false, fileNumber: 0, fileIndex: 0 })
                carouselRef?.current?.slideTo(0)
            }
            [...selectedImages.keys()].forEach((fileId) => {
                setImagesMap((prev) => {
                    prev.delete(fileId)
                    const newArray = new Map([...prev.entries()]
                        .map((item, index) => [item[0], { ...item[1], fileIndex: index }]))
                    if (newArray.size > 0) {
                        if (selectedImages.has(currentImage.id)) {
                            setCurrentImage(newArray.values().next().value as PhotoType)
                            carouselRef?.current?.slideTo(newArray.values().next().value?.fileIndex)
                        }
                    }
                    return newArray
                })
            })
            setCurrentImage((prev) => {
                if (prev.fileIndex > Math.max(...[...selectedImages.values()])) {
                    carouselRef?.current?.slideTo(prev.fileIndex - selectedImages.size)
                    return { ...prev, fileIndex: prev.fileIndex - selectedImages.size }
                }
                if (prev.fileIndex < Math.max(...[...selectedImages.values()]) && prev.fileIndex > Math.min(...[...selectedImages.values()])) {
                    const lessCount = [...selectedImages.values()].reduce((accum, value) => {
                        if (value < prev.fileIndex) {
                            accum = accum + 1
                        }
                        return accum
                    }, 0)
                    return { ...prev, fileIndex: prev.fileIndex - lessCount }
                }
                return prev
            })
            onDelete([...selectedImages.keys()])
            setSelectedImages(new Map())
        }
    }

    const handleMoveImage = (direction: 'left' | 'right') => {
        if (direction === 'right' && currentImage.fileIndex < imagesMap.size - 1) {
            const key = Array.from(imagesMap.keys())[currentImage.fileIndex + 1]
            if (key && imagesMap.get(key)) {
                setCurrentImage(imagesMap.get(key)!)
                carouselRef?.current?.slideNext()
            }
        } else if (direction === 'left' && currentImage.fileIndex !== 0) {
            const key = Array.from(imagesMap.keys())[currentImage.fileIndex - 1]
            if (key && imagesMap.get(key)) {
                setCurrentImage(imagesMap.get(key)!)
                carouselRef?.current?.slidePrev()
            }
        }
    }

    const swiperContentBody = useMemo(() => {
        let newArray: JSX.Element[] = []
        imagesMap.forEach((photo) => {
            newArray = [...newArray,
                <div
                    key={`${photo.id}_${photo.src}`}
                    className='viewer__swiper__content__item'
                >
                    {(photo.deletable && imagesMap.size > 0) && (selectedImages.has(photo.id) ? (
                        <FaCheck
                            className='viewer__select_checked'
                            onClick={() => {
                                setSelectedImages((prev) => {
                                    const newArr = [...prev]
                                    const photoIndex = newArr.findIndex((item) => item[0] === photo.id)
                                    if (photoIndex > -1) {
                                        newArr.splice(photoIndex, 1)
                                    }
                                    return new Map(newArr)
                                })
                            }}
                        />
                    ) : (
                        <FaRegCircle
                            className='viewer__select'
                            onClick={() => {
                                setSelectedImages((prev) => {
                                    prev.set(photo.id, photo.fileIndex)
                                    return new Map(prev)
                                })
                            }}
                        />
                    ))}
                    <img
                        className={clsx(
                            'viewer__swiper__content__image',
                            { 'viewer__swiper__content__image--selected': photo.fileIndex === currentImage.fileIndex },
                        )}
                        src={photo.src}
                        alt='photo'
                        loading='lazy'
                        onClick={() => {
                            setCurrentImage(photo)
                            carouselRef?.current?.slideTo(photo.fileIndex)
                        }}
                    />
                </div>,
            ]
        })

        return newArray
    }, [imagesCount, selectedImages.values(), currentImage])

    useEffect(() => {
        /** preventing memory leaks */
        let isMounted = true
        files.map((currentFile: any, index: number) => {
            if (!imagesMap.get(currentFile.id)) {
                fetchFileModel({ fileId: currentFile.id }, true).unwrap().then((fileModel) => {
                    if (fileModel?.inputName === 'avatar') {
                        fileModel = {
                            fileInfo: {
                                fileName: fileModel.fileName || '',
                                title: 'Аватар',
                                inputName: fileModel.inputName,
                                creator: fileModel.creator || '',
                                fileType: 'image/jpeg',
                            },
                            createdAt: new Date(),
                        }
                    }
                    const fileId = currentFile.id
                    const fileType = DocumentManager.getDocumentFormat(fileModel.fileInfo.fileName)
                    if (isFileImage(fileType)) {
                        fetchFile(fileId).then((res) => {
                            const type = DocumentManager.getDocumentType(fileModel.fileInfo.fileName)
                            const format = DocumentManager.getDocumentFormat(fileModel.fileInfo.fileName)
                            const file = new File([res], fileModel.fileInfo.fileName || 'unnamed', { type: fileModel.fileInfo.fileType || type + '/' + format })
                            const url = URL.createObjectURL(file)
                            const fileRules = fetchFileRules({
                                fileModel,
                                user,
                                currentStage,
                                inputName: currentFile.name,
                            })

                            if (isMounted) {
                                setImagesMap((prev) => {
                                    prev.set(fileId, {
                                        id: fileId,
                                        src: url,
                                        deletable: fileRules.delete,
                                        fileIndex: 0,
                                        fileNumber: index,
                                    })
                                    return new Map([...prev.entries()]
                                        .sort((a, b) => a[1].fileNumber - b[1].fileNumber)
                                        .map(([key, image], i) => {
                                            return [
                                                key,
                                                {
                                                    id: image.id,
                                                    src: image.src,
                                                    deletable: image.deletable,
                                                    fileIndex: i,
                                                    fileNumber: image.fileNumber,
                                                },
                                            ]
                                        }),
                                    )
                                })
                                setImagesCount((prev) => prev + 1)
                            }
                        })
                    }
                })
            }
        })
        return () => {
            /** when the component unmounts, set isMounted to false */
            isMounted = false
        }
    }, [])

    useEffect(() => {
        if (currentImage.id === '' && imagesMap.size > 0) {
            setCurrentImage(imagesMap.values().next().value as PhotoType)
            carouselRef?.current?.slideTo(imagesMap.values().next().value?.fileIndex)
        } else if (imagesMap.get(currentImage.id)) {
            setCurrentImage(imagesMap.get(currentImage.id) || {} as PhotoType)
            carouselRef?.current?.slideTo(imagesMap.get(currentImage.id)?.fileIndex || 0)
        }
    }, [imagesCount])

    useEffect(() => {
        if (checkFilesInputName(files)) {
            setBody(<p className='viewer__body__img_error'>Нет изображений</p>)
        }
    }, [])

    useEffect(() => {
        if (imagesMap.get(currentImage.id)) {
            setBody(
                <img
                    className='viewer__body__img'
                    src={imagesMap.get(currentImage.id)?.src}
                    alt='photo'
                    loading='lazy'
                    onLoad={() => {
                        setLoading(false)
                    }}
                />,
            )
        }
    }, [currentImage, imagesCount])

    return (
        <Portal>
            <div className='popupWrapper' onClick={handleWrapperClose}>
                <div className='viewer' ref={ref}>
                    <div className='viewer__container'>
                        <div className='viewer__header'>
                            <p className='viewer__header__title'>Окно предпросмотра</p>
                            <FaTimes className='viewer__header__close-btn' onClick={handleCloseViewer} />
                        </div>
                        <div className='viewer__body'>
                            <div className='viewer__body__content'>
                                {currentImage.deletable && imagesMap.size > 0 && !isLoading && (selectedImages.has(currentImage.id) ?
                                    <FaCheck
                                        className='viewer__select_checked'
                                        onClick={() => {
                                            setSelectedImages((prev) => {
                                                const newArr = [...prev]
                                                const photoIndex = newArr.findIndex((item) => item[0] === currentImage.id)
                                                if (photoIndex > -1) {
                                                    newArr.splice(photoIndex, 1)
                                                }
                                                return new Map(newArr)
                                            })
                                        }}
                                    /> :
                                    <FaRegCircle
                                        className='viewer__select'
                                        onClick={() => {
                                            setSelectedImages((prev) => {
                                                const newArr = [...prev, ([currentImage.id, currentImage.fileIndex]) as [string, number]]
                                                return new Map(newArr)
                                            })
                                        }}
                                    />)}
                                {body}
                            </div>
                        </div>
                        <div className='viewer__swiper'>
                            <FaCaretLeft
                                className={clsx('viewer__swiper__caret', 'left', {
                                    'disabled': currentImage.fileIndex === 0,
                                })}
                                onClick={() => handleMoveImage('left')}
                            />
                            <div className='viewer__swiper__window'>
                                <Carousel
                                    key='carousel'
                                    ref={carouselRef}
                                    items={swiperContentBody}
                                    activeIndex={currentImage.fileIndex}
                                    responsive={responsive}
                                    touchTracking={false}
                                    disableButtonsControls
                                    syncStateOnPropsUpdate={false}
                                />
                            </div>
                            <FaCaretRight
                                className={clsx('viewer__swiper__caret', 'right', {
                                    'disabled': currentImage.fileIndex === imagesMap.size - 1 || imagesMap.size === 0,
                                })}
                                onClick={() => handleMoveImage('right')}
                            />
                        </div>
                        <div className='viewer__buttons'>
                            <button
                                className={clsx(
                                    'viewer__buttons__btn-delete',
                                    { 'viewer__buttons__btn-delete_disabled': selectedImages.size === 0 },
                                )}
                                onClick={handleDelete}
                            >
                                Удалить
                            </button>
                            <button
                                className='viewer__buttons__btn-cancel'
                                onClick={handleCloseViewer}
                            >
                                Отмена
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    )
})
