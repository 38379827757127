import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import BitrixButton from '~/components/common/ReusingComponents/BitrixButton/BitrixButton'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { selectActiveOrder, selectOrderLoader } from '~/redux/selectors/orderSlice.selectors'
import { changeFormAction } from '~/redux/slices/appSlice/appSlice'
import { setOrderToUpdateFromArchive, showArchivePopup, showUnzipPopup } from '~/redux/slices/popupSlice/popupSlice'
import '../ComingEvents/subComponents/SidebarEventCard.scss'
import Comments from '../../../../common/ReusingComponents/Comments/Comments'
import './OrderSidebarBody.scss'

const OrderSidebarBody = () => {
    const dispatch = useDispatch()
    const { id: orderId = '' } = useParams()

    const order = useSelector(selectActiveOrder)
    const user = useSelector(selectUser)
    const loader = useSelector(selectOrderLoader)

    useEffect(() => {
        return () => {
            dispatch(changeFormAction({ action: 'NONE' }))
        }
    }, [])

    const returnOrderFromArchive = () => {
        dispatch(showUnzipPopup())
        dispatch(setOrderToUpdateFromArchive(orderId))
    }

    return (
        <div className='orderSidebarBody'>
            <div className='orderSidebarBody__buttonGroup'>
                <button
                    className='button'
                    onClick={() => {
                        dispatch(changeFormAction({
                            action: 'SAVE_FORM',
                            isSaveButton: true,
                        }))
                    }}
                    disabled={loader}
                >
                    Сохранить
                </button>
                {order?.primaryInformation?.crmLink &&
                    <BitrixButton bitrixUrl={order?.primaryInformation?.crmLink} />}
                {order?.stageName &&
                    !(order?.stageName === 'ORDER_CLOSED' && order.archived) &&
                    (user?.role === 'OPERATOR' || user?.role === 'CHIEF') &&
                    (
                        <button
                            className='button button_border-none'
                            onClick={() => order.archived ? returnOrderFromArchive() : dispatch(showArchivePopup())}
                            disabled={loader}
                        >
                            {order.archived ? 'Вернуть из архива' : 'Переместить в архив'}
                        </button>
                    )}
                <button
                    className={'button button_bc-grey'}
                    onClick={(event) => {
                        if (event.ctrlKey && event.altKey) {
                            dispatch(changeFormAction({ action: 'ADMIN_RESET_FORM' }))
                        } else {
                            dispatch(changeFormAction({ action: 'RESET_FORM' }))
                        }
                    }}
                    disabled={loader}
                >
                    Сбросить
                </button>
                {user?.role === 'OPERATOR' && (
                    <button
                        className='button button_bc-grey'
                        onClick={() => {
                            dispatch(changeFormAction({ action: 'COPY_TEXT' }))
                        }}
                        disabled={loader}
                    >
                        Скопировать заявку
                    </button>
                )}
            </div>
            {!loader && <Comments
                user={user}
            />}
        </div>
    )
}

export default OrderSidebarBody
