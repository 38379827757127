import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterSliceInitialState } from './FilterSlice.types'
import { User } from '~/API/userApi/user.types'
import { IFilterProps } from '~/types/types.filter'

const filterSliceInitialState: FilterSliceInitialState = {
    calendarFilter: {
        employees: [],
        serviceTypes: [],
        counterparty: [],
        emptySlots: [],
        closedSlots: [],
        tasks: [],
    },
    calendarFilterTab: 'closedSlots',
    employees: [],
    isLoading: false,
    values: {
        employees: [],
        serviceTypes: [],
        counterparty: [],
        events: {
            emptySlots: [],
            closedSlots: [],
            tasks: [],
        },
        userId: '',
    },
}

export const filterSlice = createSlice({
    name: 'filter',
    initialState: filterSliceInitialState,
    reducers: {
        setFilterIsLoading: (state: FilterSliceInitialState, action) => {
            state.isLoading = action.payload
        },
        setCalendarFilter: (state: FilterSliceInitialState, action) => {
            state.calendarFilter = action.payload
        },
        setFilterValues: (state: FilterSliceInitialState, action: PayloadAction<IFilterProps>) => {
            state.values = action.payload
        },
        setEmployees: (state: FilterSliceInitialState, action) => {
            state.employees = action.payload
        },
        setCalendarFilterTab: (state, action: PayloadAction<string>) => {
            state.calendarFilterTab = action.payload
        },
        fetchUsersByRolesFulfilled: (state, action: PayloadAction<User[]>) => {
            if (action.payload) {
                state.employees = action.payload
            }
            state.isLoading = false
        },
    },
})

export const {
    setFilterIsLoading,
    setCalendarFilter,
    setFilterValues,
    setEmployees,
    setCalendarFilterTab,
    fetchUsersByRolesFulfilled,
} = filterSlice.actions

export default filterSlice.reducer
