import { Dayjs } from 'dayjs'
import React, { useCallback, useEffect, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { isAvailableFilter } from './util'
import CustomFilter from '../../../../common/ReusingComponents/CustomFilter/CustomFilter'
import { useFetchUsersByRolesMutation } from '~/API/userApi/user'
import { User } from '~/API/userApi/user.types'
import { checkHiddenStages } from '~/components/pages/Home/subComponents/OrderCalendar/OrderCalendarFilterFunctions'
import useLocalStorage from '~/hooks/useLocalStorage'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { selectCalendarFilter, selectEmployees, selectFilterValues } from '~/redux/selectors/FilterSlice.selectors'
import { selectDivision, selectEvents, selectOrderActiveDate } from '~/redux/selectors/orderSlice.selectors'
import {
    fetchUsersByRolesFulfilled,
    setCalendarFilter,
    setCalendarFilterTab,
    setFilterIsLoading,
    setFilterValues,
} from '~/redux/slices/FilterSlice/FilterSlice'
import { fRolesData } from '~/redux/slices/FilterSlice/FilterSliceMoreData'
import { CleaningSlot } from '~/redux/slices/orderSlice/types/orderSlice.type'
import { rules } from '~/shared/order/rules'
import './CalendarFilter.scss'
import { fetchDate } from '~/shared/util/currentTime'

const isBetweenTwoDates = (date: Dayjs, start: Dayjs, end: Dayjs) => {
    return (fetchDate(start).isSameOrBefore(fetchDate(date).endOf('month')) && fetchDate(start).isSameOrAfter(fetchDate(date).startOf('month'))) ||
        (fetchDate(end).isSameOrAfter(fetchDate(date).startOf('month')) && fetchDate(end).isSameOrBefore(fetchDate(date).endOf('month')))
}

const isShowEvent = (event: CleaningSlot, activeDate: Date) => {
    return isBetweenTwoDates(fetchDate(activeDate), fetchDate(event.start), fetchDate(event.end)) &&
        !checkHiddenStages(event.stageName, !!event.isDateBooked, !!event.isInspectionInPast)
}

const checkAdditionalCleaningType = (serviceType: string) => {
    return serviceType !== 'Поддерживающая уборка' && serviceType !== 'Остекление' && serviceType !== 'Ежедневная уборка (обслуживание)'
}

const CalendarFilter = () => {
    const dispatch = useDispatch()
    const [fetchUsersByRoles] = useFetchUsersByRolesMutation()

    const user = useSelector(selectUser)
    const { closedSlots: events, tasks, emptySlotsUniq } = useSelector(selectEvents)
    const calendarFilter = useSelector(selectCalendarFilter)
    const activeDate = useSelector(selectOrderActiveDate)
    const filterValues = useSelector(selectFilterValues)
    const employees = useSelector(selectEmployees)
    const division = useSelector(selectDivision)

    const [storage] = useLocalStorage({ key: 'storage', initialValue: '' })

    const onChangeFilter = useCallback((name: string, values: string[]) => {
        let events = filterValues.events
        switch (name) {
            case 'tasks': {
                events = {
                    closedSlots: [],
                    emptySlots: [],
                    tasks: values,
                }
                dispatch(setFilterValues({ ...filterValues, events, division: division }))
                return
            }
            case 'closedSlots':
            case 'emptySlots': {
                events = {
                    ...events,
                    [name]: values,
                    tasks: [],
                }
                dispatch(setFilterValues({ ...filterValues, events, division: division, userId: user!.id }))
                return
            }
            default: {
                dispatch(setFilterValues({ ...filterValues, [name]: values, division: division, userId: user!.id }))
            }
        }
    }, [filterValues])

    const onChangeTab = useCallback((tab: string) => {
        dispatch(setCalendarFilterTab(tab))
    }, [])

    const newCalendarFilter = useMemo(() => {
        if (user && employees && events && tasks) {
            if (isMobile) {
                const uniqMobileServiceTypes = [...(new Set(events.flatMap((event) => event.serviceType)))]
                const uniqMobileEmployersId = [...(new Set(events.flatMap((event) => event.responsibleUsers)))]

                const closedSlots = rules[user.role]?.filter.events.closedSlots.filter((filter) => {
                    return events.some((item) => {
                        if (filter === 'inspection' && !!item.inspectionDate) return true

                        return item.type === filter || item.stageGroup === filter
                    })
                })
                const tasks = rules[user.role]?.filter.events.tasks.filter((filter) => {
                    return events.some((item) => item.section === filter)
                })

                return {
                    employees: employees
                        .filter((employer) => uniqMobileEmployersId.includes(employer.id))
                        .map((e: User) => ({ value: e.id, text: e.fullname })),
                    serviceTypes: uniqMobileServiceTypes.map((s) => ({ value: s, text: s })),
                    counterparty: [...(new Set(events.filter((item) => !!item.organization).map((item) => item.organization)))]
                        .map((organization) => ({ value: organization, text: organization })),
                    emptySlots: rules[user.role]?.filter.events.emptySlots,
                    closedSlots,
                    tasks,
                }
            } else {
                const monthEvents = events.filter((event) => isShowEvent(event, activeDate.date))
                const monthFreeSlots = emptySlotsUniq.filter((slot) => isBetweenTwoDates(fetchDate(activeDate.date), fetchDate(slot.start), fetchDate(slot.end)))
                const monthTasks = tasks.filter((task) => isBetweenTwoDates(fetchDate(activeDate.date), fetchDate(task.start), fetchDate(task.end)))

                const uniqFreeSlots = [...(new Set(monthFreeSlots.flatMap((event) => event.stageGroup)))]
                const uniqServiceTypes = [...(new Set(monthEvents.flatMap((event) => event.serviceType)))]
                const uniqEmployersId = [...(new Set(monthEvents.flatMap((event) => event.responsibleUsers)))]

                const closedSlots = rules[user.role]?.filter.events.closedSlots
                    .filter((filter) => {
                        return monthEvents.some((item) => {
                            if (filter === 'inspection' &&
                                !!item.inspectionDate &&
                                isBetweenTwoDates(fetchDate(item.inspectionDate), fetchDate(item.start), fetchDate(item.end))) {
                                return true
                            }
                            return (item.type === filter || (item.stageGroup === filter && checkAdditionalCleaningType(item.serviceType))) && !item.isInspectionInPast && item.stageGroup !== 'inspection'
                        })
                    })
                const tasksFilters = rules[user.role]?.filter.events.tasks
                    .filter((filter) => {
                        return monthTasks.some((item) => item.section === filter)
                    })
                return {
                    employees: employees
                        .filter((employer) => uniqEmployersId.includes(employer.id))
                        .map((e: User) => ({ value: e.id, text: e.fullname })),
                    serviceTypes: uniqServiceTypes.map((s) => ({ value: s, text: s })),
                    counterparty: [...(new Set(monthEvents.filter((item) => !!item.organization).map((item) => item.organization)))]
                        .map((organization) => ({ value: organization, text: organization })),
                    emptySlots: uniqFreeSlots.filter((slot) => rules[user.role]?.filter.events.emptySlots.includes(slot)),
                    closedSlots,
                    tasks: tasksFilters,
                }
            }
        }
        return {}
    }, [user, employees, events, activeDate.date, tasks])

    useEffect(() => {
        const localStorageSavedFilters: Array<string[]> = Object.values(storage?.filter?.values?.events || {})
        const areEmptyAllLocalStorageSavedFilters = localStorageSavedFilters.every((filterArr) => filterArr.length === 0)

        if (areEmptyAllLocalStorageSavedFilters && user) {
            const defaultFilter = rules[user.role]?.defaultFilter
            dispatch(setFilterValues({ ...defaultFilter, division: division, userId: user.id }))
        }

        dispatch(setFilterIsLoading(true))
        fetchUsersByRoles({ roles: fRolesData }).unwrap()
            .then((res) => {
                dispatch(fetchUsersByRolesFulfilled(res))
            })
    }, [])

    useEffect(() => {
        dispatch(setCalendarFilter(newCalendarFilter))
    }, [newCalendarFilter])

    useEffect(() => {
        if (filterValues && user && !isAvailableFilter(filterValues, rules[user.role]?.filter)) {
            dispatch(setFilterValues({ ...rules[user.role].defaultFilter, userId: user.id }))
        }
    }, [user, filterValues])

    useEffect(() => {
        if (filterValues.division !== division) {
            onChangeFilter('employees', [])
        }
    }, [division])

    return (
        <div className='calendar-filter'>
            {calendarFilter && (
                <CustomFilter
                    filter={calendarFilter}
                    onChange={onChangeFilter}
                    values={filterValues}
                    onChangeTab={onChangeTab}
                />)
            }
        </div>
    )
}

export default CalendarFilter
