import React, { useEffect, useState } from 'react'
import { useStompClient, useSubscription } from 'react-stomp-hooks'
import { useFetchMessagesQuery } from '~/API/orderApi/order'
import {sendError} from "~/API/notifierApi/notifier";


const Test2 = () => {
    const [messages, setMessages] = useState<any[]>([])
    const { data: newMessages } = useFetchMessagesQuery('1')

    // const onError = (err: any) => {
    //     console.log(err)
    // }

    const onMessageReceived = (msg: any) => {
        setMessages((prevState: any) => ([...prevState, JSON.parse(msg.body)]))
    }

    const stompClient = useStompClient()
    useSubscription('/queue/1', onMessageReceived)


    useEffect(() => {
        if (newMessages) {
            setMessages(newMessages)
        }
    }, [newMessages])


    const sendMessage = () => {
        if (stompClient) {
            const text = ({
                orderId: 1,
                comment: 'HI',
                userId: 111,
                date: new Date(),
                type: 'CLASSIC',
                commentId: 123,
            })
            stompClient.publish({ destination: '/api/cleanproc/chat', body: JSON.stringify(text) })
        }
    }

    const sendErr = () => {
        sendError({
            text: "zdarova muzhiki"
        })
    }

    return (
        <div>
            {messages.map((m: any) => <p key={m.comment}>{m.comment}</p>)}
            <button onClick={sendMessage}>SEND</button>
            <button onClick={sendErr}>SEND ERROR</button>
        </div>
    )
}

export default Test2
