import { countCalendarDays, fetchDate } from '../util/currentTime'
import { useLazyFetchUserByIdQuery } from '~/API/userApi/user'

export const convertOrderToText = (formValues: any, user: any, fetchUserById: ReturnType<typeof useLazyFetchUserByIdQuery>[0]) => {
    const text = []

    let cleaningDate
    if (formValues.accountingAndSelling?.cleaningDate) {
        cleaningDate = formValues.accountingAndSelling.cleaningDate
    }
    if (formValues.agreement?.cleaningDate) {
        cleaningDate = formValues.agreement.cleaningDate
    }
    if (formValues.starting?.cleaningDate) {
        cleaningDate = formValues.starting.cleaningDate
    }

    formValues.primaryInformation.contactInfo && text.push('Имя клиента: ' + formValues.primaryInformation.contactInfo.map((info: any) => info.clientFullname + ' ' + info.clientPhone).join(',\n'))
    cleaningDate && text.push('Дата уборки: ' + fetchDate(cleaningDate.startDate).format('DD.MM') + (countCalendarDays(cleaningDate.startDate, cleaningDate.endDate) > 1 ? fetchDate(cleaningDate.endDate).format(' - DD.MM') : ''))
    cleaningDate && text.push('Время уборки: ' + fetchDate(cleaningDate.startDate).format('HH:mm'))
    formValues.primaryInformation.address && text.push('Адрес: ' + formValues.primaryInformation.address)

    const tasks: any[] = []
    formValues.accountingAndSelling?.commercialOffer?.services.forEach((s: any) => {
        if (s.tasks) {
            const finded = s.tasks.find((e: any) => (e.name || e.type)?.toLowerCase().includes('окн'))
            if (finded) {
                tasks.push(finded)
            }
        }
    })
    if (formValues.primaryInformation.serviceType) {
        let serviceType = formValues.primaryInformation.serviceType
        if (serviceType === 'Послестроительная уборка(комплексная)') {
            serviceType = 'Послестроительная уборка'
        }

        if (serviceType === 'Генеральная уборка Luxe') {
            if (tasks.some((t: any) => t.items?.some((service: any) => service.name?.includes('с двух сторон'))) ||
                tasks.some((t: any) => t.values?.some((service: any) => service.name?.includes('с двух сторон')))) {
                serviceType = 'Люкс (окна с двух сторон)'
            } else {
                serviceType = 'Люкс (окна с внутренней стороны)'
            }
        }
        text.push('Вид уборки: ' + serviceType)
    }

    formValues.primaryInformation.buildingType && text.push('Вид объекта: ' + formValues.primaryInformation.buildingType + ' ' + formValues.primaryInformation.buildingClass)
    formValues.primaryInformation.windows && formValues.primaryInformation.windows.length > 0 && text.push('Окна: ' + formValues.primaryInformation.windows.map((window: any) => window.windowNumber + 'шт. ' + window.windowType + ' ' + window.windowMaterial).join(',\n'))
    formValues.primaryInformation.balconies && formValues.primaryInformation.balconies.length > 0 && text.push('Балкон: ' + formValues.primaryInformation.balconies.map((balcony: any) => balcony.balconyArea + 'м.кв. ' + balcony.balconyType + ' ' + balcony.balconyMaterial).join(',\n'))
    formValues.primaryInformation.cleaningDuration && text.push('Продолжительность уборки: ' + formValues.primaryInformation.cleaningDuration)
    formValues.accountingAndSelling?.cost && text.push('Стоимость: ' + formValues.accountingAndSelling?.cost.toLocaleString() + ' руб.')
    formValues.accountingAndSelling?.paymentType && text.push('Способ оплаты: ' + formValues.accountingAndSelling?.paymentType)
    text.push('(5469 5500 4967 4077 Михаил Андреевич С.)')
    text.push('По всем вопросам: 8(812)504-88-15')
    formValues.primaryInformation.comment && text.push('Комментарий: ' + formValues.primaryInformation.comment)
    text.push('_______')
    text.push('Примечания:')
    text.push('*Перед началом работ Менеджер по клинингу оценивает возможность удаления следующих загрязнений:*\n' +
        '• Силикон/монтажная пена на деревянных и пластиковых поверхностях;\n' +
        '• «Прикипевшая»/присохшая/старая (от полугода) плёнка на оконных рамах;\n' +
        '• Наклейки, карандаши/фломастеры и скотч на мебели (зависит от покрытия);\n' +
        '• Трудновыводимые пятна (кровь, моча, напитки) при химчистке мягкой мебели и ковров;\n' +
        '• Высолы на стеклянных поверхностях – белый налёт в виде пятен или разводов.\n' +
        '*Удаляем без гарантии полного устранения следующие загрязнения:*\n' +
        '• Эпоксидная затирка;\n' +
        '• Послестроительные пятна с натуральных незащищённых деревянных поверхностей;\n' +
        '*Важно понимать, что:*\n' +
        '• Есть загрязнённые поверхности, а есть испорченные (плесень в силиконе, въевшаяся ржавчина, пожелтевшие оконные рамы и пр.)\n' +
        '• Время полного высыхания мебели и ковров после химчистки около 12ч.;\n' +
        '• Мы не проводим демонтаж (откручивание и/или разбор духовки, радиаторов, москитных сеток и пр.)\n' +
        '• Мы не отодвигаем крупногабаритную мебель (кровать, диван, холодильник, стиральная машинка и пр.)\n' +
        '• Мы оказываем качественные профессиональные клининговые услуги!')
    text.push('_______')
    formValues.primaryInformation.counterparty && text.push('Контрагент: ' + formValues.primaryInformation.counterparty)
    if (formValues.primaryInformation.counterparty === 'Физ. лицо') {
        text.push('Пол: ' + formValues.primaryInformation.sex)
    } else {
        text.push('Организация: ' + formValues.primaryInformation.organization)
    }
    formValues.primaryInformation.attractionSource && text.push('Источник: ' + formValues.primaryInformation.attractionSource + (formValues.primaryInformation.sourceType === '-' ? '' : ' ' + formValues.primaryInformation.sourceType))
    text.push('Дата оформления: ' + new Date().toLocaleDateString())
    let orderCreator = user?.fullname
    if (formValues.primaryInformation.orderCreator) {
        fetchUserById(formValues.primaryInformation.orderCreator, true).unwrap().then((userInfo) => {
            orderCreator = userInfo?.fullname
        })
    }
    text.push('Кто оформил заявку: ' + orderCreator)
    text.push('Кто закрыл заявку: ')
    text.push('Кто исполнил заявку: ')
    text.push('Позвонить за день до уборки!')
    text.push('___')
    text.push('Назначенные Клинеры:')
    text.push('1.')
    text.push('2.')
    text.push('3.')
    text.push('4.')
    text.push('5.')
    return text
}
