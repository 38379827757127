import { User } from '~/API/userApi/user.types'
import { IRulesComments } from '~/types/types.order'

export type AppSliceInitialState = {
    user: User | null
    token: string | null
    buttonAction: {
        message: string
    }
    formAction: FormActionType
    formComment?: keyof IRulesComments
    appLoader: boolean
    sidebarStatus: boolean
    bodyHidden: string
    fslightboxController: FslightboxController
    schedulePersonnel: null
    headerToolbar: boolean
    uniqueScheduleValues: any[]
    skipPagination: boolean
    agendaLoading: boolean
    isPageError: boolean
}

export interface FormActionType {
    action: FormActionEnum
    comment?: string
    isSaveButton?: boolean
}

export type FslightboxController = {
    toggler: null
    sourceIndex: number
    key: number
    sources: any[]
    local: boolean
}

export enum FormActionEnum {
    ADMIN_RESET_FORM = 'ADMIN_RESET_FORM',
    SAVE_WITHOUT_CHECK = 'SAVE_WITHOUT_CHECK',
    BLACK_LIST = 'BLACK_LIST',
    BUFFER = 'BUFFER',
    COPY_TEXT = 'COPY_TEXT',
    CREATE_DOCUMENT = 'CREATE_DOCUMENT',
    CREATE_KP = 'CREATE_KP',
    DELETE_ORDER = 'DELETE_ORDER',
    DELETE_SCHEDULE = 'DELETE_SCHEDULE',
    EDIT_DOCUMENT = 'EDIT_DOCUMENT',
    EDIT_ORDER = 'EDIT_ORDER',
    FILL_NZ_WITH_TASK = 'FILL_NZ_WITH_TASK',
    FIRED = 'FIRED',
    FROM_ARCHIVE = 'FROM_ARCHIVE',
    HANDLE_SUBMIT = 'HANDLE_SUBMIT',
    INTERNSHIP = 'INTERNSHIP',
    NONE = 'NONE',
    NOT_WORKING = 'NOT_WORKING',
    ORDER_CREATION = 'ORDER_CREATION',
    RESET_FORM = 'RESET_FORM',
    SAVE_FORM = 'SAVE_FORM',
    SAVE_ORDER = 'SAVE_ORDER',
    SAVE_PERSONNEL = 'SAVE_PERSONNEL',
    SAVE_SCHEDULE = 'SAVE_SCHEDULE',
    TO_ARCHIVE = 'TO_ARCHIVE',
    UPDATE_ORDER = 'UPDATE_ORDER',
    UPLOAD_MONTH_STATISTIC = 'UPLOAD_MONTH_STATISTIC',
    WORKING = 'WORKING',
    NEED_CHANGES_TO_WORKING_PLAN_APPROVING = 'NEED_CHANGES_TO_WORKING_PLAN_APPROVING',
    WORKING_PLAN_APPROVING = 'WORKING_PLAN_APPROVING',
    RESPONSIBLE_USER_CHANGING = 'RESPONSIBLE_USER_CHANGING',
    PAYMENT_CONFIRMED = 'PAYMENT_CONFIRMED',
    NEED_CHANGES_TO_CONTRACT_SIGNING = 'NEED_CHANGES_TO_CONTRACT_SIGNING',
    NEED_CHANGES_TO_CONTRACT_SIGNING_WITH_DATE_CHANGING = 'NEED_CHANGES_TO_CONTRACT_SIGNING_WITH_DATE_CHANGING',
    NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING = 'NEED_CHANGES_TO_COMMERCIAL_OFFER_APPROVING',
    SEND_TO_MANAGER = 'SEND_TO_MANAGER',
    FINAL_REPORT = 'FINAL_REPORT',
    TO_ORDER_MANAGER_ORDER_CLOSING = 'TO_ORDER_MANAGER_ORDER_CLOSING',
    ORDER_CLOSED = 'ORDER_CLOSED',
    QUALITY_CONTROLLING_FINAL_REPORT = 'QUALITY_CONTROLLING_FINAL_REPORT',
    NEED_CHANGES_TO_CONTRACT_WORK_REPORT_FILLING = 'NEED_CHANGES_TO_CONTRACT_WORK_REPORT_FILLING',
    NEED_CHANGES_TO_SERVICE_TABLE_FILLING = 'NEED_CHANGES_TO_SERVICE_TABLE_FILLING',
}
