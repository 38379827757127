import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { ERROR_CODES, OrderInterface } from '~/API/orderApi/types/order.types'
import { Division, User } from '~/API/userApi/user.types'
import {
    StageType,
} from '~/components/pages/Order/subComponents/OrderCreator/additionalFunctions/filterStageItemsByRole'
import {
    buildCleaningSlots,
    buildEmptySlots,
    defaultDateRange,
    formEventsForUser,
} from '~/redux/slices/orderSlice/lib/orderSliceAdditionalFunc'
import { ActiveDateTarget, DateRange, OrderSliceInitialState } from '~/redux/slices/orderSlice/types/orderSlice.type'
import { fetchLocalDate } from '~/shared/util/currentTime'
import { setGlobalDivision } from '~/shared/util/localStorage'

const orderSliceInitialState: OrderSliceInitialState = {
    events: {
        tasks: [],
        emptySlots: [],
        emptySlotsUniq: [],
        closedSlots: [],
    },
    currentCleanings: [],
    emptySlotsObj: {},
    emptySlotsUniqObj: {},
    removedCleaningsObj: {},
    filter: {
        cleaningTypes: [],
        divisions: [],
        events: {
            task: [],
            cleaning: [],
        },
        searchString: '',
        serviceTypes: [],
        counterparty: [],
        employees: [],
    },
    filteredEvents: [],
    activeEvent: null,
    activeDate: {
        date: new Date(),
        view: 'month',
    },
    activeDateTarget: 'scroll',
    activeMonthDate: new Date(),
    order: undefined,
    activeOrder: undefined,
    formState: false,
    orderLoader: true,
    eventLoader: true,
    prices: null,
    division: localStorage.getItem('division') !== undefined ?
        localStorage.getItem('division') as Division :
        'B2C',
    menuType: 'MENU_MAIN',
    dateRange: {
        start: fetchLocalDate().subtract(2, 'month').startOf('month'),
        end: fetchLocalDate().add(2, 'month').startOf('month'),
    },
    eventsWithCustomFilter: [],
    eventsWithCustomFilterUniq: [],
    staffFormMenu: 'FORM',
    error: null,
    sectionName: '',
    stageName: '',
    isServicesSet: false,
    isServiceDescriptionsSet: false,
    eventEmitters: {
        CLIENT_INFO_CONTRACTOR_VALIDATION_FAILED: false,
    },
}

export const orderSlice = createSlice({
    name: 'order',
    initialState: orderSliceInitialState,
    reducers: {
        setEventEmitter: (state: OrderSliceInitialState, action: PayloadAction<{
            code: typeof ERROR_CODES[number],
            condition: boolean
        }>) => {
            state.eventEmitters[action.payload.code] = action.payload.condition
        },
        setActiveMonthDate: (state: OrderSliceInitialState, action: PayloadAction<Date>) => {
            state.activeMonthDate = action.payload
        },
        setIsServices: (state: OrderSliceInitialState, action: PayloadAction<boolean>) => {
            state.isServicesSet = action.payload
        },
        setIsServiceDescriptions: (state: OrderSliceInitialState, action: PayloadAction<boolean>) => {
            state.isServiceDescriptionsSet = action.payload
        },
        setDateRange: (state: OrderSliceInitialState, action: PayloadAction<DateRange>) => {
            state.dateRange = action.payload
        },
        setSectionName: (state: OrderSliceInitialState, action: PayloadAction<string>) => {
            state.sectionName = action.payload
        },
        setStageName: (state: OrderSliceInitialState, action: PayloadAction<StageType>) => {
            state.stageName = action.payload
        },
        resetDateRange: (state) => {
            state.dateRange = defaultDateRange
        },
        CHANGE_MENU_TYPE: (state, action) => {
            state.menuType = action.payload
        },
        changeOrderActiveDate: (state, action: PayloadAction<{ date: Date, view?: string }>) => {
            state.activeDate = action.payload
        },
        changeOrderActiveDateTarget: (state, action: PayloadAction<ActiveDateTarget>) => {
            state.activeDateTarget = action.payload
        },
        changeActiveEvent: (state, action) => {
            state.activeEvent = action.payload
        },
        CHANGE_FILTER: (state: OrderSliceInitialState, action) => {
            state.filter = action.payload
        },
        CHANGE_DIVISION: (state, action) => {
            setGlobalDivision(action.payload)
            state.division = action.payload
        },
        UPDATE_EVENTS: () => {
            // todo скорее всего срабатывает сага, НУЖНО переписать на санки
        },
        UPDATE_ORDER: (state, action) => {
            state.order = action.payload
            state.activeOrder = action.payload
        },
        FETCH_ORDER: (state, action) => {
        },
        ORDER_TO_ARCHIVE: (state: OrderSliceInitialState, payload: { type: string; payload: string }) => {
            // todo скорее всего срабатывает сага, НУЖНО переписать на санки
        },
        ORDER_FROM_ARCHIVE: () => {
            // todo скорее всего срабатывает сага, НУЖНО переписать на санки
        },
        CHANGE_ACTIVE_ORDER: (state, action) => {
            // when order without stage set it to ORDER_CREATING
            state.stageName = !action.payload?.stageName ? 'ORDER_CREATING' : action.payload?.stageName
            if (action.payload) {
                state.activeOrder = {
                    ...action.payload,
                    primaryInformation: {
                        ...action.payload.primaryInformation,
                        orderCreatedAt: !action.payload?.stageName ? new Date() : action.payload.primaryInformation.orderCreatedAt,
                        division: action.payload.primaryInformation.division || action.payload.businessType,
                        // crmLink: 'https://h2ocleaning.bitrix24.ru/crm/deal/details/43836/',
                    },
                }
            } else {
                state.activeOrder = undefined
            }
        },
        CHANGE_ACTIVE_ORDER_DOCUMENTS: (state, action: PayloadAction<OrderInterface['documents']>) => {
            if (state.activeOrder) {
                state.activeOrder = { ...state.activeOrder, documents: action.payload }
            }
        },
        UPLOAD_FILE: (state, action) => {
            // todo скорее всего срабатывает сага, НУЖНО переписать на санки
        },
        FETCH_EVENTS_REQUESTED: (state) => {
            state.eventLoader = true
        },
        FETCH_EVENTS_SUCCESS: (state) => {
            state.eventLoader = false
        },
        FETCH_EVENTS_FAILED: (state) => {
            state.eventLoader = false
        },
        FETCH_ORDER_REQUESTED: (state) => {
            state.error = ''
            state.orderLoader = true
        },
        FETCH_ORDER_SUCCESS: (state) => {
            state.error = ''
            state.orderLoader = false
        },
        FETCH_ORDER_FAILED: (state, action) => {
            if (action.payload.status === 404) {
                state.error = 'Заявка, которую Вы пытаетесь открыть, не существует'
            } else {
                state.error = 'Произошла ошибка при получении заявки'
            }
            state.orderLoader = false
        },
        UPDATE_ORDER_REQUESTED: (state) => {
            state.error = ''
            state.orderLoader = true
        },
        UPDATE_ORDER_SUCCESS: (state) => {
            state.error = ''
            state.orderLoader = false
        },
        UPDATE_ORDER_FAILED: (state) => {
            state.error = 'Произошла ошибка при получении заявки'
            state.orderLoader = false
        },
        CREATE_CONTRACT_REQUESTED: (state) => {
            state.orderLoader = true
        },
        CREATE_NZ_REQUESTED: (state) => {
            state.orderLoader = true
        },
        CREATE_NZ_SUCCESS: (state) => {
            state.orderLoader = false
        },
        CREATE_NZ_FAILED: (state) => {
            state.orderLoader = false
        },
        CHANGE_ORDER_LOADER: (state, action) => {
            state.orderLoader = action.payload
        },
        SET_PRICES: (state, action) => {
            state.prices = action.payload
        },
        setFilteredEvents: (state: OrderSliceInitialState, action) => {
            state.filteredEvents = action.payload
        },
        setEventsWithCustomFilter: (state, action: PayloadAction<any[]>) => {
            state.eventsWithCustomFilter = action.payload
        },
        setEventsWithCustomFilterUniq: (state, action: PayloadAction<any[]>) => {
            state.eventsWithCustomFilterUniq = action.payload
        },
        changeStaffFormMenu: (state, action: PayloadAction<'FORM' | 'DOCS'>) => {
            state.staffFormMenu = action.payload
        },
        clearOrderError: (state) => {
            state.error = ''
        },
        setEventLoader: (state, action: PayloadAction<boolean>) => {
            state.eventLoader = action.payload
        },
        clearOrder: (state) => {
            state.order = undefined
            state.activeOrder = undefined
            // state.sectionName = ''
            state.stageName = ''
        },
        updateOrderFulfilled: (state, action) => {
            const {
                cleanings,
                user,
                actions,
                schedule,
                personnelBD,
                clearOld,
            } = action.payload

            const cleaningsForCalendar = buildCleaningSlots(cleanings, user) // just cleanings work events
            const eventsForCalendar = formEventsForUser(actions, user) // all work events
            const emptySlots = buildEmptySlots({
                schedule,
                cleanings,
                personnelBD: personnelBD as User[],
                oldAvailableSlotsBySchedule: clearOld ? {} : current(state.emptySlotsObj),
                oldRemovedCleanings: clearOld ? {} : current(state.removedCleaningsObj),
                oldCleanings: clearOld ? [] : current(state.currentCleanings),
            }) // empty slots for free personal

            state.emptySlotsObj = emptySlots.availableSlots
            state.emptySlotsUniqObj = emptySlots.availableSlotsUniq
            state.removedCleaningsObj = emptySlots.removedCleanings

            if (clearOld) {
                state.events = {
                    tasks: eventsForCalendar,
                    emptySlots: [...Object.values(emptySlots.availableSlots).map((item) => [...item.values()]).flat()],
                    emptySlotsUniq: [...Object.values(emptySlots.availableSlotsUniq)],
                    closedSlots: cleaningsForCalendar,
                }
                state.currentCleanings = cleanings
            } else {
                state.events = {
                    tasks: [...state.events.tasks, ...eventsForCalendar],
                    emptySlots: [...Object.values(emptySlots.availableSlots).map((item) => [...item.values()]).flat()],
                    emptySlotsUniq: [...Object.values(emptySlots.availableSlotsUniq)],
                    closedSlots: [...state.events.closedSlots, ...cleaningsForCalendar],
                }
                state.currentCleanings = [...state.currentCleanings, ...cleanings]
            }
            state.eventLoader = false
        },
        createOrderPending: (state) => {
            state.orderLoader = true
            state.error = null
        },
        createOrderRejected: (state, action) => {
            state.orderLoader = false
            state.error = action.payload
        },
        createOrderFulfilled: (state, action) => {
            state.orderLoader = false
            state.error = null
            state.stageName = 'ORDER_CREATING'
            state.activeOrder = {
                ...action.payload,
                primaryInformation: {
                    ...action.payload.primaryInformation,
                    orderCreatedAt: new Date(),
                    division: action.payload.businessType,
                    // crmLink: 'https://h2ocleaning.bitrix24.ru/crm/deal/details/43836/',
                },
            }
        },
        setOrderLoader: (state, action: PayloadAction<boolean>) => {
            state.orderLoader = action.payload
        },
        setActiveOrder: (state, action: PayloadAction<any>) => {
            state.activeOrder = action.payload
        },
    },
})

export const {
    setEventEmitter,
    setActiveMonthDate,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_REQUESTED,
    UPDATE_ORDER_FAILED,
    setActiveOrder,
    setOrderLoader,
    setEventLoader,
    updateOrderFulfilled,
    CHANGE_MENU_TYPE,
    changeOrderActiveDate,
    changeOrderActiveDateTarget,
    UPDATE_ORDER,
    CHANGE_FILTER,
    CHANGE_DIVISION,
    ORDER_TO_ARCHIVE,
    CHANGE_ACTIVE_ORDER,
    FETCH_ORDER,
    UPDATE_EVENTS,
    CHANGE_ORDER_LOADER,
    SET_PRICES,
    setFilteredEvents,
    setDateRange,
    resetDateRange,
    FETCH_ORDER_REQUESTED,
    setEventsWithCustomFilter,
    setEventsWithCustomFilterUniq,
    changeStaffFormMenu,
    clearOrderError,
    FETCH_ORDER_SUCCESS,
    FETCH_ORDER_FAILED,
    createOrderFulfilled,
    createOrderPending,
    createOrderRejected,
    clearOrder,
    setSectionName,
    setStageName,
    setIsServices,
    setIsServiceDescriptions,
} = orderSlice.actions

export default orderSlice.reducer
