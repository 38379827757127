import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { AddressSuggestions, DaDataAddress } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'
import { FieldError } from 'react-hook-form'
import CustomInput from '../CustomInput/CustomInput'
import { useLazyFetchTaxiPriceQuery } from '~/API/utilApi/util'
import CustomCheckbox, { CustomCheckboxTheme } from '~/components/common/checkbox/CustomCheckbox/CustomCheckbox'
import { fixAddress } from '~/shared/util/strings'
import './CustomAddressInput.scss'

interface ICustomAddressInput {
    value: string
    onChange: (fixAddress: any) => void
    title: string
    name: string
    error?: FieldError,
    disabled: boolean
    className: string
    placeholder?: string
}

const CustomAddressInput = (props: ICustomAddressInput) => {
    const {
        value,
        onChange,
        title,
        name,
        error,
        disabled,
        className,
        placeholder,
    } = props
    const [fetchTaxiPrice] = useLazyFetchTaxiPriceQuery()

    const [taxiCost, setTaxiCost] = useState(null)
    const [checkbox, setCheckbox] = useState(false)
    const [currentValue, setCurrentValue] = useState(value)

    useEffect(() => {
        if (value) {
            onChange(fixAddress(value))
        }
    }, [])

    useEffect(() => {
        if (value) {
            setCurrentValue(value)
        }
    }, [value])

    const handleChange = (value: any) => {
        const longitude = value?.data?.geo_lon
        const latitude = value?.data?.geo_lat
        if (longitude && latitude) {
            fetchTaxiPrice({ longitude, latitude }, true).unwrap()
                .then((response: any) => {
                    let result: any = `${response.distance} - ${response.time}\n`
                    result += `${response.service_levels[1].name} - ${response.service_levels[1].price}\n`
                    result += `${response.service_levels[10].name} - ${response.service_levels[10].price}`
                    setTaxiCost(result)
                })
                .catch((error: any) => console.log('error: ', error))
        }
        setCurrentValue(fixAddress(value.value))
        onChange(fixAddress(value.value))
    }

    const handleSetValue = (e: any) => {
        setCurrentValue(e.target.value)
        onChange(e.target.value)
    }

    return (
        <div className={clsx('customField', 'customAddressInput', className, { error, disabled })}>
            <div className='customAddressInput__title'>
                <span
                    data-toolip='taxiCost'
                    className={clsx('customField__title', { error })}
                >
                    {title}
                </span>
            </div>
            <div className='customAddressInput__wrapper'>
                {!checkbox ? (
                    <div
                        className={clsx('customField__input', 'customAddressInput__customInput--address')}
                        data-tooltip={taxiCost}
                    >
                        <AddressSuggestions
                            token={import.meta.env.VITE_DADATA_TOKEN}
                            value={{
                                value: currentValue || '',
                                unrestricted_value: currentValue || '',
                                data: {} as DaDataAddress,
                            }}
                            defaultQuery={currentValue || ''}
                            onChange={handleChange}
                            minChars={1}
                            inputProps={{
                                name,
                                className: '',
                                disabled,
                                placeholder,
                                onChange: handleSetValue,
                            }}
                            delay={300}
                            containerClassName='customAddressInput__body'
                            filterLocationsBoost={[{ kladr_id: '78' }, { kladr_id: '47' }]}
                        />
                    </div>
                ) : (
                    <CustomInput
                        value={currentValue}
                        name='address'
                        onChange={handleSetValue}
                        className={clsx('customAddressInput__customInput', 'customAddressInput__customInput--address')}
                        disabled={disabled}
                        placeholder={placeholder}
                    />
                )}
                <CustomCheckbox
                    id={name + 'checkbox'}
                    value={checkbox}
                    onChange={() => setCheckbox((prev) => !prev)}
                    disabled={disabled}
                    caption='Нет адреса в базе'
                    theme={CustomCheckboxTheme.ROUND}
                />
            </div>
        </div>
    )
}
export default CustomAddressInput
