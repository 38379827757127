import { InputMask } from '~/components/common/inputs/CustomInput/CustomInput'

export const INVENTORY_ITEMS_RETURNING_ACTIONS = {
    mainActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'GENERATE_NZ_RESULT',
                text: 'Сформировать наряд-заказ (результат)',
                icon: 'repeat',
            },
        ],
        version: 'v2',
    }),
    withNzActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'GENERATE_NZ_RESULT',
                text: 'Сформировать наряд-заказ (результат)',
                icon: 'repeat',
            },
            {
                value: 'MANAGER_CHECKING',
                text: 'Отправить на проверку руководителю',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
}

export const INVENTORY_ITEMS_RETURNING_SECTIONS = {
    tmc: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Затраты на ТМЦ',
        name: 'actualConsumables',
        type: 'text',
        mask: InputMask.NUMBER,
        version: 'v2',
    }),
    equipment: (disabled: boolean) => ({
        disabled: disabled,
        title: 'Амортизация',
        name: 'actualEquipment',
        type: 'text',
        mask: InputMask.NUMBER,
        required: false,
        version: 'v2',
    }),

}
