import { KpoValues } from '~/shared/templates/mainTemplates/kpo'
import { fetchLocalDate } from '~/shared/util/currentTime'


export const kpoMaintenance = async (values: KpoValues) => {
    const {
        kpoData: {
            propertyInformation,
            technicalIndicator,
            staffIndicator,
            inventoryEquipment,
            additionalWork,
        },
        responsiblePerson,
        hasOuterArea,
    } = values

    const html = await fetch('/html/kpoMaintenance.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    const domHtml = parser.parseFromString(htmlText, 'text/html')

    domHtml.getElementById('inspectionDate')!.innerHTML = fetchLocalDate().format('DD.MM.YYYY')
    domHtml.getElementById('responsiblePerson')!.innerHTML = responsiblePerson

    domHtml.getElementById('objectType')!.innerHTML = propertyInformation.objectType
    domHtml.getElementById('objectClass')!.innerHTML = propertyInformation.objectClass
    domHtml.getElementById('serviceType')!.innerHTML = propertyInformation.serviceType
    domHtml.getElementById('address')!.innerHTML = propertyInformation.address
    domHtml.getElementById('objectSchedule')!.innerHTML = propertyInformation.objectSchedule
    domHtml.getElementById('isPassNeeded')!.innerHTML = propertyInformation.isPassNeeded! ? 'Да' : 'Нет'
    domHtml.getElementById('documentRequirements')!.innerHTML = propertyInformation.documentRequirements
    domHtml.getElementById('personnelRequirements')!.innerHTML = propertyInformation.personnelRequirements
    domHtml.getElementById('cleaningStartDate')!.innerHTML = propertyInformation.cleaningStartDate
    domHtml.getElementById('paymentType')!.innerHTML = propertyInformation.paymentType
    domHtml.getElementById('hasTender')!.innerHTML = propertyInformation.hasTender! ? 'Да' : 'Нет'
    domHtml.getElementById('hasTechnicalTask')!.innerHTML = propertyInformation.hasTechnicalTask! ? 'Да' : 'Нет'
    // @ts-ignore
    domHtml.getElementById('counterparty')!.innerHTML = propertyInformation.counterparty ? propertyInformation.counterparty : '-'
    domHtml.getElementById('contactPerson')!.innerHTML = propertyInformation.contactPerson
    domHtml.getElementById('comment')!.innerHTML = propertyInformation.comment

    domHtml.getElementById('hasPlans')!.innerHTML = technicalIndicator.hasPlans! ? 'Да' : 'Нет'
    domHtml.getElementById('innerArea')!.innerHTML = technicalIndicator.innerArea + ' м2'
    domHtml.getElementById('bathroomNumber')!.innerHTML = technicalIndicator.bathroomNumber
    domHtml.getElementById('draining')!.innerHTML = technicalIndicator.draining
    domHtml.getElementById('flooring')!.innerHTML = technicalIndicator.flooring
    domHtml.getElementById('personnelNumber')!.innerHTML = technicalIndicator.personnelNumber
    domHtml.getElementById('cluttering')!.innerHTML = technicalIndicator.cluttering
    domHtml.getElementById('pollution')!.innerHTML = technicalIndicator.pollution
    domHtml.getElementById('personnelRoom')!.innerHTML = technicalIndicator.personnelRoom
    domHtml.getElementById('outerArea')!.innerHTML = hasOuterArea ? technicalIndicator.outerArea + ' м2' : '-'
    domHtml.getElementById('covering')!.innerHTML = hasOuterArea ? technicalIndicator.covering : '-'
    domHtml.getElementById('hasGreenArea')!.innerHTML = hasOuterArea ? technicalIndicator.hasGreenArea! ? 'Да' : 'Нет' : '-'
    domHtml.getElementById('hasMowing')!.innerHTML = hasOuterArea ? technicalIndicator.hasMowing! ? 'Да' : 'Нет' : '-'
    domHtml.getElementById('innerGlazing')!.innerHTML = technicalIndicator.innerGlazing
    domHtml.getElementById('facadeArea')!.innerHTML = technicalIndicator.facadeArea + ' м2'
    domHtml.getElementById('roofArea')!.innerHTML = technicalIndicator.roofArea + ' м2'

    domHtml.getElementById('maintenancePersonnelNumber')!.innerHTML = staffIndicator.maintenancePersonnelNumber
    domHtml.getElementById('schedule')!.innerHTML = staffIndicator.schedule
    domHtml.getElementById('wageFund')!.innerHTML = staffIndicator.wageFund
    domHtml.getElementById('isRegistrationNeeded')!.innerHTML = staffIndicator.isRegistrationNeeded

    domHtml.getElementById('sanitaryConsumables')!.innerHTML = inventoryEquipment.sanitaryConsumables
    domHtml.getElementById('inventoryRequirements')!.innerHTML = inventoryEquipment.inventoryRequirements
    domHtml.getElementById('internalEquipment')!.innerHTML = inventoryEquipment.internalEquipment
    domHtml.getElementById('externalEquipment')!.innerHTML = inventoryEquipment.externalEquipment

    domHtml.getElementById('isWashingFacades')!.innerHTML = additionalWork.isWashingFacades! ? 'Да' : 'Нет'
    domHtml.getElementById('isAlpinism')!.innerHTML = additionalWork.isAlpinism! ? 'Да' : 'Нет'
    domHtml.getElementById('isRotaryCleaning')!.innerHTML = additionalWork.isRotaryCleaning! ? 'Да' : 'Нет'
    domHtml.getElementById('isDryCleaning')!.innerHTML = additionalWork.isDryCleaning! ? 'Да' : 'Нет'
    domHtml.getElementById('isCarpetService')!.innerHTML = additionalWork.isCarpetService! ? 'Да' : 'Нет'
    domHtml.getElementById('isMechanizedCleaning')!.innerHTML = hasOuterArea ? additionalWork.isMechanizedCleaning! ? 'Да' : 'Нет' : '-'
    domHtml.getElementById('isSnowRemoval')!.innerHTML = hasOuterArea ? additionalWork.isSnowRemoval! ? 'Да' : 'Нет' : '-'
    domHtml.getElementById('isLandscaping')!.innerHTML = hasOuterArea ? additionalWork.isLandscaping! ? 'Да' : 'Нет' : '-'
    domHtml.getElementById('otherServices')!.innerHTML = additionalWork.otherServices


    return { style: domHtml.querySelector('style')!.innerHTML, body: domHtml.querySelector('body')!.innerHTML }
}
