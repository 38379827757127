import { EmblaCarouselType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { useCallback, useEffect } from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'
import ServicesVerificationItem from '../ServicesVerificationItem/ServicesVerificationItem'
import './ServicesVerificationSwiper.scss'

interface ServicesVerificationSwiperProps {
    title: string;
    description?: string;
    price?: number;
    onChange: (value: boolean) => void
    value?: boolean
    error?: boolean
    disabled?: boolean
}

const ServicesVerificationSwiper = (props: ServicesVerificationSwiperProps) => {
    const {
        title,
        description,
        price,
        onChange,
        value,
        error,
        disabled,
    } = props

    const [emblaRef, emblaApi] = useEmblaCarousel({
        startIndex: 1,
        watchDrag: !disabled,
    })

    const onSelectSlide = useCallback((emblaApi: EmblaCarouselType) => {
        const selectedScrollSnap = emblaApi.selectedScrollSnap()

        if (selectedScrollSnap === 0 || selectedScrollSnap === 2) {
            onChange(selectedScrollSnap === 0)
            emblaApi.scrollTo(1)
        }
    }, [])

    useEffect(() => {
        if (emblaApi) {
            emblaApi.on('select', onSelectSlide)
        }
    }, [emblaApi, onSelectSlide])

    return (
        <div className='servicesVerificationSwiper' ref={emblaRef}>
            <div className='servicesVerificationSwiper__container'>
                <div className='servicesVerificationSwiper__slide servicesVerificationSwiper__slide--green'>
                    <div className='servicesVerificationSwiper__action'>
                        <div className='servicesVerificationSwiper__icon'>
                            <FaCheck size={16} />
                        </div>
                        <p>Подтверждено</p>
                    </div>
                </div>
                <div className='servicesVerificationSwiper__slide'>
                    <ServicesVerificationItem
                        title={title}
                        description={description}
                        price={price}
                        status={value}
                        error={error}
                    />
                </div>
                <div className='servicesVerificationSwiper__slide servicesVerificationSwiper__slide--red'>
                    <div className='servicesVerificationSwiper__action'>
                        <div className='servicesVerificationSwiper__icon'>
                            <FaTimes size={16} />
                        </div>
                        <p>Не требуется</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesVerificationSwiper
