import { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'
import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'

interface useUpdateOrderParams {
    clientType: ClientInfoEnum
    setValue: UseFormSetValue<any>
    contextMethods: UseFormReturn<any>
}

export const useClientEffect = ({ clientType, setValue, contextMethods }: useUpdateOrderParams) => {
    switch (clientType) {
        case ClientInfoEnum.ORGANIZATION: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useEffect(() => {
                setValue('primaryInformation.organizationRequisites', contextMethods.getValues('primaryInformation.organizationRequisites'))
            }, [contextMethods.getValues('primaryInformation.organizationRequisites')])
        }
        case ClientInfoEnum.CONTRACTOR: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useEffect(() => {
                setValue('starting.contractor', contextMethods.getValues('starting.contractor'))
            }, [contextMethods.getValues('starting.contractor')])
        }
    }
}
