import clsx from 'clsx'
import React from 'react'
import { StageGroup } from '~/API/orderApi/types/order.types'
import { chooseSvg } from '~/shared/util/svg'
import { orderTypes } from '~/shared/util/types'
import './OrderStatusBar.scss'

interface OrderStatusBarProps {
    className?: string,
    type: StageGroup,
}

const OrderStatusBar = ({ className, type }: OrderStatusBarProps) => {
    return (
        <div className={clsx('orderStatusBar', className, type?.toLowerCase())}>
            {chooseSvg(type ?? '')}{orderTypes[type?.toLowerCase() as keyof typeof orderTypes]}
        </div>
    )
}

export default OrderStatusBar
