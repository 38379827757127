import { fetchDate } from '../../util/currentTime'

export const act = async (values: any) => {
    const html = await fetch('/html/act.html')
    const htmlText = await html.text()
    const parser = new DOMParser()
    const domHtml = parser.parseFromString(htmlText, 'text/html')

    if (values.clientRequisites?.name) {
        domHtml.querySelector('#clientName')!.innerHTML = `<b>${values.clientRequisites.name}</b>`
    }
    const contractDate = fetchDate(values.contractDate).format('LL')
    domHtml.querySelector('#contractNumber')!.innerHTML = values.contractNumber
    domHtml.querySelector('#contractDate')!.innerHTML = contractDate
    domHtml.querySelector('#address')!.innerHTML = values.address

    return { style: domHtml.querySelector('style')!.innerHTML, body: domHtml.querySelector('body')!.innerHTML }
}
