import clsx from 'clsx'
import { FaExclamation } from 'react-icons/fa'
import './ErrorIcon.scss'

interface ErrorIconProps {
    isActive?: boolean
}

const ErrorIcon = (props: ErrorIconProps) => {
    const { isActive } = props

    return (
        <div className={clsx('errorIcon', {
            'errorIcon--white': isActive,
        })}>
            <FaExclamation size={10} />
        </div>
    )
}

export default ErrorIcon
