export const actions = {
    // SELLING

    reserveOrderAction: (disabled?: boolean) => ({
        disabled,
        name: 'orderToDivisionAction',
        title: 'Выберите действие',
        items: [{
            value: 'RESERVE_ORDER',
            text: 'Передать информацию',
        }],
        type: 'action',
    }),
    orderToDivisionAction: (disabled?: boolean) => ({
        disabled,
        name: 'orderToDivisionAction',
        title: 'Выберите действие',
        items: [{
            value: 'ORDER_TO_DIVISION',
            text: 'Передать заявку на подтверждение',
        }],
        type: 'action',
    }),
    orderToDivisionCleaningAction: (disabled?: boolean) => ({
        disabled,
        name: 'orderToDivisionCleaningAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'ORDER_TO_DIVISION',
                text: 'Передать заявку на подтверждение',
            },
            {
                value: 'RESERVE_CLEANING',
                text: 'Передать информацию подразделению',
            },
            {
                value: 'RESERVE_ORDER',
                text: 'Передать информацию без подтверждения',
            },
        ],
        type: 'action',
    }),
    orderManagerTakeOrderAction: () => ({
        name: 'takeOrderAction',
        title: 'Выберите действие',
        items:
            [
                {
                    value: 'TAKE_ORDER',
                    text: 'Подтвердить заявку',
                }],
        type: 'action',
    }),
    takeOrderAction: (disabled?: boolean) => ({
        disabled,
        name: 'takeOrderAction',
        title: 'Выберите действие',
        items:
            [
                {
                    value: 'TAKE_ORDER',
                    text: 'Принять заявку',
                },
                {
                    value: 'CHANGE_COST',
                    text: 'Передать информацию отделу продаж',
                }],
        type: 'action',
    }),

    // STARTING

    changeRequestAction: (disabled?: boolean) => ({
        disabled,
        name: 'changeRequestAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'CHANGE_REQUEST',
                text: 'Запрос на изменение заявки',
            },
        ],
        type: 'action',
    }),
    successRequestAction: (disabled?: boolean) => ({
        disabled,
        name: 'successRequestAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'EDIT_ORDER',
                text: 'Подтвердить запрос',
            },
        ],
        type: 'action',
    }),

    selectNewCleaningDateAction: (disabled?: boolean) => ({
        disabled,
        name: 'selectNewCleaningDateAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'SELECT_NEW_CLEANING_DATE',
                text: 'Выбрать новую дату уборки',
            },

        ],
        type: 'action',
    }),
    cleaningApprovalAction: (disabled?: boolean) => ({
        disabled,
        name: 'cleaningApprovalAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'APPROVE_CLEANING',
                text: 'Подтвердить дату уборки',
            },
            {
                value: 'NEW_CLEANING_DATE',
                text: 'Перенести дату уборки',
            },
            {
                value: 'NEW_CLEANING_APPROVAL_DATE',
                text: 'Перенести дату звонка',
            },
            {
                value: 'TO_ARCHIVE_FROM_ACTION',
                text: 'Перенести заявку в архив',
            },
        ],
        type: 'action',
    }),
    approveNZAction: (disabled?: boolean) => ({
        disabled,
        name: 'approveNZAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'APPROVE_NZ',
                text: 'Подтвердить наряд заказ',
            },
            {
                value: 'APPROVE_CLEANING',
                text: 'Изменить наряд заказ',
            },
        ],
        type: 'action',
    }),

    // NZ
    fillNZWithToolsAction: (disabled?: boolean) => ({
        disabled,
        title: 'Выбрать действие',
        items:
            [
                {
                    value: 'FILL_NZ_WITH_TOOLS',
                    text: 'Сформировать инвентарь',
                },
            ],
        type: 'action',
    }),
    fillNZWithTeamAction: (disabled?: boolean) => ({
        disabled,
        title: 'Выбрать действие',
        items:
            [
                {
                    value: 'FILL_NZ_WITH_TEAM',
                    text: 'Сформировать бригаду',
                },
            ],
        type: 'action',
    }),
    fillNZWithTaskAction: (disabled?: boolean) => ({
        disabled,
        name: 'fillNZWithTaskAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'FILL_NZ_WITH_TASK',
                text: 'Сформировать задание',
            },
        ],
        type: 'action',
    }),


    // FOREMAN REPORT
    clientConfirmationAction: (disabled?: boolean) => ({
        disabled,
        name: 'clientConfirmationAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'CLIENT_CONFIRMATION',
                text: 'Подтвердить выезд на уборку',
            },
            {
                value: 'WAITING_CLIENT_CONFIRMATION',
                text: 'Недозвон до клиента',
            },
            {
                value: 'CLIENT_DECLINE', // Перенос на стадию TAKE_ORDER но с другими данными
                text: 'Отказ клиента',
            },
        ],
        type: 'action',
    }),
    infoToForemanAction: (disabled?: boolean) => ({
        disabled,
        name: 'infoToForemanAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'INFO_TO_FOREMAN',
                text: 'Передать информацию бригаде',
            },
        ],
        type: 'action',
    }),
    cleaningStartedAction: (disabled?: boolean) => ({
        disabled,
        name: 'cleaningStartedAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'CLEANING_STARTED',
                text: 'Подтвердить услуги',
            },
            {
                value: 'ADD_SERVICES_RESPONSE',
                text: 'Требуются дополнительные услуги',
            },
        ],
        type: 'action',
    }),
    addServicesToConfirmationAction: (disabled?: boolean) => ({
        disabled,
        name: 'addServicesToConfirmationAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'ADD_SERVICES_TO_CONFIRMATION',
                text: 'Отправить на подтверждение услуг',
            },
        ],
        type: 'action',
    }),
    confirmAddServiceAction: (disabled?: boolean) => ({
        disabled,
        name: 'addServicesToConfirmationAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'CLEANING_STARTED',
                text: 'Подтвердить услуги',
            },
        ],
        type: 'action',
    }),
    workStartingAction: (disabled?: boolean) => ({
        disabled,
        name: 'workStartingAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'WORK_STARTING',
                text: 'Начать работы',
            },
        ],
        type: 'action',
    }),
    workProcessAction: (lastWorkingDay: boolean, disabled?: boolean) => ({
        disabled,
        name: 'workProcessAction',
        title: 'Выберите действие',
        items: lastWorkingDay ?
                [{
                    value: 'WORK_PROCESS',
                    text: 'Загрузить контент и выполненные задачи',
                }] :
                [
                    {
                        value: 'WORK_PROCESS',
                        text: 'Загрузить контент и выполненные задачи',
                    },
                    {
                        value: 'WORKING_DAY_ENDED',
                        text: 'Перейти к завершению рабочего дня',
                    },
                ],
        type: 'action',
    }),

    workEndingAction: (disabled?: boolean) => ({
        disabled,
        name: 'workEndingAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'LAST_DAY_ENDED',
                text: 'Завершить работы',
            },
        ],
        type: 'action',
    }),

    lastDayEndedAction: (disabled?: boolean) => ({
        disabled,
        name: 'lastDayEndedAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'WORK_ENDING',
                text: 'Завершить отчет по объекту',
            },
        ],
        type: 'action',
    }),
    foremanReportAction: (lastWorkingDay: boolean, disabled?: boolean) => ({
        disabled,
        name: 'foremanReportAction',
        title: 'Выберите действие',
        items: [lastWorkingDay ?
                {
                    value: 'FOREMAN_REPORT',
                    text: 'Закончить заполнение бригады',
                } :
                {
                    value: 'START_NEW_DAY',
                    text: 'Закончить рабочий день',
                },
        ],
        type: 'action',
    }),


    // TOOLS REPORT
    onWarehouseAction: (disabled?: boolean) => ({
        disabled,
        name: 'onWarehouseAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'ON_WAREHOUSE',
                text: 'На складе',
            },
        ],
        type: 'action',
    }),
    toolsReportAction: (disabled?: boolean) => ({
        disabled,
        name: 'toolsReportAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'TOOLS_REPORT',
                text: 'Сформировать наряд заказ',
            },
        ],
        type: 'action',
    }),
    approveNZBackAction: (disabled?: boolean) => ({
        disabled,
        name: 'approveNZBackAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'APPROVE_NZ_BACK',
                text: 'Подтвердить наряд заказ',
            },
            {
                value: 'REBUILD_NZ_BACK',
                text: 'Исправить наряд заказ',
            },
        ],
        type: 'action',
    }),


    // ORDER MANAGER REPORT
    orderManagerReportAction: (disabled?: boolean) => ({
        disabled,
        name: 'orderManagerReportAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'TO_QUALITY_CONTROL',
                text: 'Отправить на контроль качества',
            },
            {
                value: 'TO_MISFITS_EDITING',
                text: 'Перейти к исправлению ошибок',
            },
        ],
        type: 'action',
    }),

    paymentReceivedAction: (disabled?: boolean) => ({
        disabled: disabled,
        name: 'returningDocumentsAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'TO_QUALITY_CONTROL',
                text: 'Отправить на контроль качества',
            },
        ],
        type: 'action',
    }),


    // QUALITY CONTROL
    qualityControlAction: (disabled?: boolean) => ({
        disabled,
        name: 'qualityControlAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'TO_CHIEF_DECISION',
                text: 'Завершить контроль качества',
            },
        ],
        type: 'action',
    }),

    // ORDER MANAGER ORDER CLOSING
    orderManagerOrderClosing: (disabled?: boolean) => ({
        disabled,
        name: 'orderManagerReportAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'ORDER_MANAGER_ORDER_CLOSING_INFO',
                text: 'Передать информацию по исправлениям',
            },
            {
                value: 'TO_QUALITY_CONTROL',
                text: 'Отправить на контроль качества',
            },
            {
                value: 'TO_CHIEF_DECISION',
                text: 'На переделку',
            },
        ],
        type: 'action',
    }),

    // ORDER MANAGER ORDER CLOSING
    operatorOrderClosing: (disabled?: boolean) => ({
        disabled,
        name: 'orderManagerReportAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'TO_CHIEF_DECISION',
                text: 'Отправить на завершение',
            },
            {
                value: 'CALLING_DATE_ORDER_CLOSING',
                text: 'Недозвон до клиента',
            },
        ],
        type: 'action',
    }),

    chiefReportAction: (disabled?: boolean) => ({
        disabled,
        name: 'chiefReportAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'ORDER_CLOSED',
                text: 'Завершить заявку',
            },
        ],
        type: 'action',
    }),


    // Закрытие заявки
    orderClosingAction: (disabled?: boolean) => ({
        disabled,
        name: 'orderClosingAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'PAYMENT_WAITING',
                text: 'Ожидает оплаты',
            },
            {
                value: 'FINISH_ORDER',
                text: 'Закрыть заявку',
            },
            {
                value: 'RECLEANING',
                text: 'Отправить на переделку',
            },
        ],
        type: 'action',
    }),


    // KPO
    creatingKPOAction: (disabled?: boolean) => ({
        disabled,
        name: 'creatingKPOAction',
        title: 'Выберите действие',
        items: [{
            value: 'CREATE_KPO',
            text: 'Сформировать КПО',
        }],
        type: 'action',
    }),


    // KP
    selectCleaningDateAction: (disabled?: boolean) => ({
        disabled,
        name: 'selectCleaningDateAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'SELECT_CLEANING_DATE',
                text: 'Назначить дату уборки',
            },
        ],
        type: 'action',
    }),
    selectInspectionDateAction: (disabled?: boolean) => ({
        disabled,
        name: 'selectInspectionDateAction',
        title: 'Выберите действие',
        items: [{
            value: 'SELECT_INSPECTION_DATE',
            text: 'Назначить дату осмотра',
        }],
        type: 'action',
    }),
    toSellingAction: (disabled?: boolean) => ({
        disabled,
        name: 'toSellingAction',
        title: 'Выберите действие',
        items: [{
            value: 'TO_SELLING',
            text: 'Перейти к согласованию КП',
        }],
        type: 'action',
    }),
    selectKPDateAction: (disabled?: boolean) => ({
        disabled,
        name: 'selectKPDateAction',
        title: 'Выберите действие',
        items: [{
            value: 'SELECT_KP_DATE',
            text: 'Назначить дату согласования КП',
        }],
        type: 'action',
    }),
    signingKPDateAction: (disabled?: boolean) => ({
        disabled,
        name: 'signingKPDateAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'NEW_KP_DATE',
                text: 'Назначить новую дату согласования КП',
            },
            {
                value: 'NEW_CONTRACT_DATE',
                text: 'Перейти к согласованию договора',
            }],
        type: 'action',
    }),
    signingContractAction: (disabled?: boolean) => ({
        disabled,
        name: 'signingContractAction',
        title: 'Выберите действие',
        items: [
            {
                value: 'SIGNING_CONTRACT',
                text: 'Согласовать договор',
            },
            {
                value: 'NEW_CONTRACT_DATE',
                text: 'Назначить новую дату согласования договора',
            },
        ],
        type: 'action',
    }),
    selectContractDateAction: (disabled?: boolean) => ({
        disabled,
        name: 'selectContractDateAction',
        title: 'Выберите действие',
        items: [{
            value: 'SELECT_CONTRACT_DATE',
            text: 'Назначить дату согласования договора',
        }],
        type: 'action',
    }),
    fillContractAction: (disabled?: boolean) => ({
        disabled,
        name: 'fillContractAction',
        title: 'Выберите действие',
        items: [{
            value: 'SIGNING_CONTRACT',
            text: 'Закончить заполнение договора',
        }],
        type: 'action',
    }),
}
