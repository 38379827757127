export const clientTypeItems = [
    {
        'value': 'Юр. лицо',
        'text': 'Юр. лицо',
    },
    {
        'value': 'Физ. лицо',
        'text': 'Физ. лицо',
    },
]

export const clientGenderItems = [
    {
        'value': 'Мужской',
        'text': 'Мужской',
    },
    {
        'value': 'Женский',
        'text': 'Женский',
    },
]

export const clientProxyItems = [
    {
        'value': true,
        'text': 'Да',
    },
    {
        'value': false,
        'text': 'Нет',
    },
]

export const clientMainInfo = [
    {
        'disabled': false,
        'title': 'Имя',
        'name': 'clientFullname',
        'className': 'client-info__info',
        'type': 'text',
        'required': true,
    },
    {
        'disabled': false,
        'title': 'Должность',
        'name': 'clientPost',
        'className': 'client-info__info',
        'type': 'text',
    },
    {
        'disabled': false,
        'title': 'Телефон',
        'name': 'clientPhone',
        'className': 'client-info__info',
        'type': 'text',
        'required': true,
        'maxLength': 18,
        'icon': 'phone',
        'mask': '+7 (###) ###-##-##',
    },
    {
        'disabled': false,
        'title': 'Email',
        'name': 'clientEmail',
        'className': 'client-info__info',
        'type': 'text',
        'icon': 'email',
    },
]

export type ClientType = 'Юр. лицо' | 'Физ. лицо'
