export const inputsWithOwnController = new Set([
    'clientInfo',
    'fieldArray',
    'fieldArrayPatent',
    'personnel',
    'rating',
    'reportTable',
    'table',
    'title',
    'tools',
])
