import {
    ClientInfoEnum,
} from '~/components/common/ReusingComponents/ClientInfo/hooks/useSaveClient/useSaveClient.types'
import { rules } from '~/shared/order/rules'
import { StageManager, VERSIONS } from '~/shared/order/StageManager'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { additionalInputs } from '~/shared/templates/inputTemplates/v2/additionalInputs'

export const CONTRACT_WORK_REPORT_FILLING_SECTIONS = {
    foremanComment: (disabled: boolean) => ({
        disabled: disabled,
        name: 'foremanComment',
        title: 'Комментарий от руководителя',
        type: 'text',
        multiline: true,
        version: VERSIONS.V2,
    }),
    contractor: () => ({
        disabled: true,
        name: 'contractor',
        title: 'Подрядчик',
        type: 'text',
        version: VERSIONS.V2,
    }),
    startWorkingDate: (disabled: boolean) => ({
        disabled,
        title: 'Дата начала работ',
        name: 'startWorkingDate',
        mode: 'date',
        type: 'date',
        singleDate: true,
    }),
    endWorkingDate: (disabled: boolean) => ({
        disabled,
        title: 'Дата окончания работ',
        name: 'endWorkingDate',
        mode: 'date',
        type: 'date',
        singleDate: true,
    }),
    workProcess: (disabled: boolean, usingData: string) => ({
        disabled: disabled,
        name: 'workProcess',
        title: 'Контент от подрядчика',
        max: 150,
        type: 'documentList',
        button: 'Загрузить фото',
        usingData,
        required: true,
        allowedFileTypes: ['image', 'video'],
        version: 'v2',
    }),
}

export const CONTRACT_WORK_REPORT_FILLING_ACTIONS = {
    mainActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'QUALITY_CONTROLLING',
                text: 'Отправить на контроль качества',
                icon: 'forward',
            },
            {
                value: 'NEED_CHANGES_TO_WORKING_PLAN_APPROVING',
                text: 'Отправить на запуск работ',
                icon: 'back',
            },
        ],
        version: 'v2',
    }),
}

interface CONTRACT_WORK_REPORT_FILLING_PARAMS {
    reportDate: Date,
    stages: string[],
    currentStage: string,
    user: any,
    archived: boolean,
    isLastSection: boolean
}

export const CONTRACT_WORK_REPORT_FILLING = (params: CONTRACT_WORK_REPORT_FILLING_PARAMS) => {
    const {
        reportDate,
        stages,
        currentStage,
        user,
        archived,
        isLastSection,
    } = params
    const newInputs = []

    if (rules[user?.role]?.sections.contractWorkReport) {
        const availableAction = StageManager.checkAvailableStageByRole('CONTRACT_WORK_REPORT_FILLING', user.role)
        const disabled = stages.includes('DEFECTS_CORRECTING')

        if (reportDate) {
            newInputs.push([
                additionalInputs.division(true),
                additionalInputs.responsibleUserId(true, 'contractWorkReport.division', 'responsibleUserId'),
                additionalInputs.reportDate(true),
                additionalInputs.clientInfo(true),
            ])
        } else {
            newInputs.push([
                additionalInputs.division(true),
                additionalInputs.clientInfo(true),
            ])
        }
        newInputs.push([
            CONTRACT_WORK_REPORT_FILLING_SECTIONS.contractor(),
            CONTRACT_WORK_REPORT_FILLING_SECTIONS.startWorkingDate(disabled),
            CONTRACT_WORK_REPORT_FILLING_SECTIONS.endWorkingDate(disabled),
            additionalInputs.clientInfo(true, ClientInfoEnum.CONTRACTOR),
        ])

        newInputs.push([CONTRACT_WORK_REPORT_FILLING_SECTIONS.foremanComment(disabled)])
        newInputs.push([CONTRACT_WORK_REPORT_FILLING_SECTIONS.workProcess(disabled, 'documents.contractWork.workProcess')])

        if (availableAction && isLastSection) {
            const action = fetchFormAction({
                archived,
                section: 'REPORTS',
                user,
                currentStage,
            })
            if (action) {
                newInputs.push([action])
            }
        }
    }
    return {
        title: 'Отчет подрядных работ',
        name: 'contractWorkReport',
        inputs: [...newInputs],
    }
}
