import clsx from 'clsx'
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FaEye, FaThumbtack } from 'react-icons/fa'
import { FaRepeat } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import { useUploadFileMutation } from '~/API/fileApi/file'
import { useCustomDisabled } from '~/components/common/ReusingComponents/TabsTable/hooks/useCustomDisabled'
import { useFileTitle } from '~/components/common/ReusingComponents/TabsTable/hooks/useFileTitle'
import { TabsTableMods } from '~/components/common/ReusingComponents/TabsTable/TabsTable'
import { TabsTableContext } from '~/components/common/ReusingComponents/TabsTable/TabsTableContext'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { OPEN_LIGHTBOX } from '~/redux/slices/appSlice/appSlice'
import { useAppDispatch } from '~/redux/store'
import { fetchDate } from '~/shared/util/currentTime'

import './TabsTableFileInput.scss'

interface TabsTableFileInputProps {
    name: string
    sectionName: string
    pageIndex: number
    rowIndex: number
    onChange: () => void
    disabled?: boolean
    customDisabledType?: string
}

export interface SectionDocument {
    index: number
    fileId: string
}

const TabsTableFileInput = (props: TabsTableFileInputProps) => {
    const {
        name,
        sectionName,
        pageIndex,
        rowIndex,
        onChange,
        disabled,
        customDisabledType,
    } = props

    const dispatch = useAppDispatch()
    const { baseName } = useContext(TabsTableContext)

    const [fileId, setFileId] = useState<string | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const fileTitle = useFileTitle({
        sectionName,
        rowIndex,
    })
    const customDisabled = useCustomDisabled({
        customDisabledType,
        rowName: `${baseName}.${pageIndex}.items.${rowIndex}`,
    })

    const user = useSelector(selectUser)

    const [uploadFile] = useUploadFileMutation()

    const {
        setValue,
        getValues,
    } = useFormContext()

    const { usingData, tabsTableMode } = useContext(TabsTableContext)
    const valuesName = tabsTableMode === TabsTableMods.MANY ? `${usingData}.${sectionName}` : `${usingData}.${name}`

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!usingData) return

        const file = event.target.files?.[0]
        if (file) {
            try {
                const fileModel = {
                    fileInfo: {
                        fileName: file.name || '',
                        title: fileTitle,
                        inputName: name,
                        creator: user?.id || '',
                        fileType: file.type,
                    },
                    createdAt: fetchDate().toDate(),
                }

                const response = await uploadFile({
                    file,
                    fileModel,
                }).unwrap()

                const newFileId = response.fileId

                if (newFileId) {
                    const sectionDocuments: SectionDocument[] = getValues(valuesName) || []
                    let updatedSectionDocuments: SectionDocument[]

                    if (fileId) {
                        updatedSectionDocuments = sectionDocuments.map((item) => {
                            if (item.index === rowIndex) {
                                return {
                                    ...item,
                                    fileId: newFileId,
                                }
                            }
                            return item
                        })
                    } else {
                        updatedSectionDocuments = [...sectionDocuments, { index: rowIndex, fileId: newFileId }]
                    }
                    setValue(valuesName, updatedSectionDocuments)
                    setFileId(newFileId)
                    onChange()
                }
            } catch (error) {
                console.error('Ошибка загрузки файла:', error)
            }
        }
    }

    const handleIconClick = () => {
        if (fileId) {
            dispatch(
                OPEN_LIGHTBOX({
                    sources: [
                        fileId,
                    ],
                    sourceIndex: 0,
                }),
            )
        } else {
            fileInputRef.current?.click()
        }
    }

    const handleReset = () => {
        const files = getValues(valuesName)
        if (Array.isArray(files)) {
            const newArray = [...files]
            const index = files.findIndex((e) => e === fileId || e?.fileId === fileId)
            if (index !== -1) {
                newArray.splice(index, 1)
                setValue(valuesName, newArray)
            }
            setFileId(null)
        }
    }

    useEffect(() => {
        const sectionDocuments: SectionDocument[] = getValues(valuesName) || []
        const existingFile = sectionDocuments.find((item) => item?.index === rowIndex)

        if (existingFile) {
            setFileId(existingFile.fileId)
        }
    }, [])

    return (
        <div className='tabsTableFileInput'>
            <button
                type='button'
                className={clsx('tabsTableFileInput__button', {
                    'tabsTableFileInput__button--disabled': !fileId ? (disabled || customDisabled) : false,
                })}
                onClick={handleIconClick}
                disabled={!fileId ? (disabled || customDisabled) : false}
            >
                {fileId ? <FaEye className='tabsTableFileInput__icon' size={12} /> :
                <FaThumbtack className='tabsTableFileInput__icon' size={10} />}
            </button>
            {fileId && <button
                type='button'
                className={clsx('tabsTableFileInput__button', {
                    'tabsTableFileInput__button--disabled': disabled || customDisabled,
                })}
                onClick={handleReset}
                disabled={disabled || customDisabled}
            >
                <FaRepeat className='tabsTableFileInput__icon' />
            </button>}
            <input
                type='file'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </div>
    )
}

export default TabsTableFileInput
