import { rules } from '~/shared/order/rules'
import { fetchFormAction } from '~/shared/templates/actionTemplates/formActionService'
import { kpPrimary } from '~/shared/templates/inputTemplates/kpPrimary'
import { qualityControl } from '~/shared/templates/inputTemplates/qualityControl'
import { additionalInputs } from '~/shared/templates/inputTemplates/v2/additionalInputs'

export const QUALITY_CONTROL_ACTIONS = {
    mainActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'DOCUMENTS_RETURNING',
                text: 'Отправить на возврат документов',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
    individualActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'FINAL_REPORT_FROM_QUALITY_CONTROL',
                text: 'Отправить на финальный отчет',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
    contractActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'CONTRACTOR_BILLING',
                text: 'Отправить на расчет с подрядчиком',
                icon: 'forward',
            },
            {
                value: 'DEFECTS_CORRECTING',
                text: 'Отправить на исправление недочетов',
                icon: 'forward',
            },
            {
                value: 'NEED_CHANGES_TO_CONTRACT_WORK_REPORT_FILLING',
                text: 'Отправить на отчет подрядных работ',
                icon: 'back',
            },
        ],
        version: 'v2',
    }),
    paymentActions: () => ({
        disabled: false,
        name: 'nextStageName',
        usingData: 'nextStageName',
        title: 'Выберите следующий шаг',
        type: 'action',
        required: true,
        items: [
            {
                value: 'ACTUAL_PAYMENT_RESERVING',
                text: 'Отправить на указание суммы оплаты',
                icon: 'forward',
            },
        ],
        version: 'v2',
    }),
}

interface QUALITY_CONTROL_PARAMS {
    businessType: string,
    isLastSection: boolean,
    sectionNumber: number,
    reportDate: Date,
    stages: string[],
    currentStage: string,
    user: any,
    archived: boolean,
    foremanList: string[],
    order: any
}

export const QUALITY_CONTROL = (params: QUALITY_CONTROL_PARAMS) => {
    const {
        businessType,
        isLastSection,
        sectionNumber,
        reportDate,
        stages,
        currentStage,
        user,
        archived,
        foremanList,
        order,
    } = params
    const newInputs = []
    const documentInputs = []
    // ТУТ логика available action теряется, нужно будет переписать
    if (rules[user?.role]?.sections.qualityControl && (user.role !== 'FOREMAN' || foremanList.includes(user.id))) {
        // const availableAction = StageManager.checkAvailableStageByRole(currentStage, user.role)
        const disabled = !isLastSection || stages.includes('TO_CHIEF_DECISION')

        if (reportDate) {
            newInputs.push([
                additionalInputs.division(true),
                additionalInputs.responsibleUserId(true, `orderClosing.${sectionNumber}.division`, 'responsibleManager'),
                additionalInputs.reportDate(true),
                additionalInputs.qualityReminder(),
            ])
        } else {
            newInputs.push([
                additionalInputs.division(true, `orderClosing.${sectionNumber}.division`),
                additionalInputs.qualityReminder(),
            ])
        }
        if (
            user.role === 'OPERATOR' ||
            user.role === 'CHIEF' ||
            ((user.role === 'ORDER_MANAGER' || user.role === 'FOREMAN') && !isLastSection) ||
            stages.includes('TO_CHIEF_DECISION')
        ) {
            newInputs.push([
                qualityControl.qualityRating(disabled),
                qualityControl.serviceRating(disabled),
            ])
            newInputs.push([
                qualityControl.discountNextCleaning(disabled),
                kpPrimary.attractionSource(disabled),
            ])
            newInputs.push([qualityControl.clientComment(disabled)])
            newInputs.push([qualityControl.qualityControlComment(disabled)])
            newInputs.push([qualityControl.qualityControlFiles(disabled)])
            documentInputs.push(qualityControl.qualityControlFiles(disabled))
        }

        if ((user.role === 'OPERATOR' || user.role === 'CHIEF') && isLastSection) {
            const action = fetchFormAction({
                archived,
                section: 'QUALITY_CONTROL',
                user,
                currentStage,
                order,
            })
            if (action) {
                newInputs.push([action])
            }
        }

        return {
            title: 'Контроль качества',
            name: 'orderClosing.' + sectionNumber,
            documentInputs,
            inputs: newInputs,
        }
    }
    return {
        title: 'Контроль качества',
        name: 'orderClosing.' + sectionNumber,
        inputs: [],
    }
}
