import { actions } from './actions'
import { rules } from '../../order/rules'
import { OrderInterface } from '~/API/orderApi/types/order.types'
import { Division } from '~/API/userApi/user.types'
import { actionsB2B } from '~/shared/templates/actionTemplates/actionsB2B'
import {
    DEFECTS_CORRECTING_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/common/DEFECTS_CORRECTING'
import {
    DOCUMENTS_RETURNING_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/common/DOCUMENTS_RETURNING'
import { QUALITY_CONTROL_ACTIONS } from '~/shared/templates/inputTemplates/v2/stagesInputs/common/QUALITY_CONTROL'
import {
    CONTRACT_WORK_REPORT_FILLING_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/contractWork/CONTRACT_WORK_REPORT_FILLING'
import {
    CONTRACTOR_BILLING,
    CONTRACTOR_BILLING_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/contractWork/CONTRACTOR_BILLING'
import {
    CHIEF_REPORT_SECTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/maintenance/MAINTENANCE_FINAL_REPORT'
import {
    ACTUAL_PAYMENT_RESERVING_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/oneTimeWork/ACTUAL_PAYMENT_RESERVING'
import {
    DEPARTURE_CONFIRMATION_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/oneTimeWork/DEPARTURE_CONFIRMATION'
import { FINAL_REPORT_ACTIONS } from '~/shared/templates/inputTemplates/v2/stagesInputs/oneTimeWork/FINAL_REPORT'
import {
    FOREMAN_REPORT_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/oneTimeWork/FOREMAN_REPORT_TEAM'
import {
    INVENTORY_ITEMS_RETURNING_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/oneTimeWork/INVENTORY_ITEMS_RETURNING'

import {
    MANAGER_CHECKING_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/oneTimeWork/MANAGER_CHECKING'
import {
    SERVICES_VERIFICATION_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/oneTimeWork/SERVICES_VERIFICATION'
import {
    WORKING_DAY_REPORT_ACTIONS,
} from '~/shared/templates/inputTemplates/v2/stagesInputs/oneTimeWork/WORKING_DAY_REPORT'

interface fetchFormActionParams {
    order?: OrderInterface,
    archived: boolean,
    user: any,
    section: string,
    currentStage?: string,
    responsibleUserId?: string,
    lastWorkingDay?: boolean,
    isAllChecks?: boolean
    division?: Division
}

export const fetchFormAction = (params: fetchFormActionParams) => {
    const {
        archived,
        user,
        section,
        currentStage,
        responsibleUserId,
        lastWorkingDay = false,
        order,
        isAllChecks,
        division,
    } = params
    if (!archived && user) {
        switch (section) {
            case 'RETURNING_DOCUMENTS': {
                switch (currentStage) {
                    case 'ACTUAL_PAYMENT_RESERVING': {
                        return ACTUAL_PAYMENT_RESERVING_ACTIONS.mainActions()
                    }
                    case 'DOCUMENTS_RETURNING':
                    case 'FINAL_REPORT':
                    case 'ORDER_CLOSED':
                        if (order) {
                            const isOrderClosed = order.stageName === 'FINAL_REPORT' || order.stageName === 'ORDER_CLOSED'
                            if (order?.returningDocuments?.isDocumentsReturned) {
                                if (order?.returningDocuments?.isPaymentConfirmed || (order?.agreement?.prepayment === 100 && order?.agreement?.prepaymentType === 'Внесена')) {
                                    if (!isOrderClosed) {
                                        return DOCUMENTS_RETURNING_ACTIONS.withPaymentDocumentsActions()
                                    } else {
                                        return null
                                    }
                                } else {
                                    if (!isOrderClosed) {
                                        return DOCUMENTS_RETURNING_ACTIONS.withDocumentsActions()
                                    } else {
                                        return null
                                    }
                                }
                            } else {
                                if (order?.returningDocuments?.isPaymentConfirmed || (order?.agreement?.prepayment === 100 && order?.agreement?.prepaymentType === 'Внесена')) {
                                    if (!isOrderClosed) {
                                        return DOCUMENTS_RETURNING_ACTIONS.withPaymentActions()
                                    } else {
                                        return DOCUMENTS_RETURNING_ACTIONS.withClosedPaymentActions()
                                    }
                                } else {
                                    return DOCUMENTS_RETURNING_ACTIONS.mainActions()
                                }
                            }
                        }
                        return null
                    case 'CONTRACTOR_BILLING': {
                        return CONTRACTOR_BILLING_ACTIONS.mainActions()
                    }
                    case 'RETURNING_DOCUMENTS':
                        return actionsB2B.returningDocumentsAction(order?.agreement?.prepayment === 100 && order?.agreement?.prepaymentType === 'Внесена')
                    case 'PAYMENT_RECEIVING':
                        return actionsB2B.paymentReceivedAction()
                    default:
                        return null
                }
            }
            case 'CONTRACTOR_BILLING': {
                switch (currentStage) {
                    case 'CONTRACTOR_BILLING': {
                        return CONTRACTOR_BILLING_ACTIONS.mainActions()
                    }
                    default:
                        return null
                }
            }
            case 'REPORTS': {
                switch (currentStage) {
                    case 'CONTRACT_WORK_REPORT_FILLING': {
                        return CONTRACT_WORK_REPORT_FILLING_ACTIONS.mainActions()
                    }
                    case 'CLIENT_DECLINING':
                    case 'DEPARTURE_CONFIRMATION': {
                        return DEPARTURE_CONFIRMATION_ACTIONS.mainActions()
                    }
                    case 'SERVICES_VERIFICATION': {
                        switch (user.role) {
                            case 'FOREMAN': {
                                return SERVICES_VERIFICATION_ACTIONS.foremanActions()
                            }
                            default: {
                                return SERVICES_VERIFICATION_ACTIONS.managerActions()
                            }
                        }
                    }
                    case 'ADDITIONAL_SERVICES_CREATING': {
                        return SERVICES_VERIFICATION_ACTIONS.additionalServicesAction()
                    }

                    default: {
                        return null
                    }
                }
            }
            case 'SELLING': {
                switch (currentStage) {
                    case undefined:
                    case null:
                    case 'ORDER_CREATION':
                    case 'EDIT_ORDER':
                    case 'APPROVE_KP':
                        if (rules[user.role]?.actions.orderManagerTakeOrder) {
                            return actions.orderManagerTakeOrderAction()
                        } else {
                            return actions.orderToDivisionCleaningAction()
                        }
                    case 'CHANGE_COST':
                    case 'RESERVE_ORDER':
                        if (rules[user.role]?.actions.orderToDivisionCleaning) {
                            return actions.orderToDivisionCleaningAction()
                        }
                        return null
                    case 'RESERVE_CLEANING':
                        if (rules[user.role]?.actions.orderToDivisionCleaning) {
                            return actions.orderToDivisionCleaningAction()
                        } else {
                            return actions.reserveOrderAction()
                        }
                    case 'ORDER_TO_DIVISION':
                        return actions.takeOrderAction()
                    default:
                        return null
                }
            }
            case 'STARTING': {
                if (user.role === 'OPERATOR') {
                    if (currentStage === 'TAKE_ORDER') {
                        return actions.changeRequestAction()
                    }
                    return null
                }
                switch (currentStage) {
                    case 'CHANGE_REQUEST':
                        return actions.successRequestAction()
                    case 'CLIENT_DECLINE':
                    case 'TAKE_ORDER':
                    case 'NEW_CLEANING_DATE':
                    case 'NEW_CLEANING_APPROVAL_DATE':
                        return actions.cleaningApprovalAction()
                    case 'FILL_NZ_WITH_TASK':
                        return actions.approveNZAction()
                    default:
                        return null
                }
            }
            case 'FOREMAN_WORK_STARTING': {
                switch (currentStage) {
                    case 'APPROVE_NZ':
                        return actions.clientConfirmationAction()
                    case 'WAITING_CLIENT_CONFIRMATION':
                    case 'INFO_TO_FOREMAN':
                        return user.role === 'FOREMAN' ? actions.clientConfirmationAction() : actions.infoToForemanAction()
                    case 'CLIENT_CONFIRMATION':
                        return actions.cleaningStartedAction()
                    case 'ADD_SERVICES_RESPONSE':
                        return user.role !== 'FOREMAN' && actions.addServicesToConfirmationAction()
                    case 'ADD_SERVICES_TO_CONFIRMATION':
                        return actions.confirmAddServiceAction()
                    default:
                        return null
                }
            }
            case 'WORKING_DAY_REPORT': {
                switch (currentStage) {
                    case 'OBJECT_REPORT_FILLING':
                        if (order) {
                            const notFirstDay = order.foremanReport.daysReport.length > 0
                            if ((user.role === 'ORDER_MANAGER' || user.role === 'CHIEF') && user.division.includes(division)) {
                                return WORKING_DAY_REPORT_ACTIONS.managerActions(notFirstDay)
                            }
                            return WORKING_DAY_REPORT_ACTIONS.mainActions(notFirstDay)
                        }
                        return null
                    case 'SERVICE_TABLE_FILLING':
                        return WORKING_DAY_REPORT_ACTIONS.serviceFillingActions()
                    case 'WORKING_DAY_ENDING':
                        return WORKING_DAY_REPORT_ACTIONS.dayEndedActions()
                    case 'CLEANING_STARTED':
                        return actions.workStartingAction()
                    case 'WORK_STARTING':
                        return actions.workProcessAction(lastWorkingDay)
                    case 'WORK_PROCESS':
                        return actions.workProcessAction(lastWorkingDay)
                    case 'CLEANING_ENDED':
                    case 'WORKING_DAY_ENDED':
                        return actions.workEndingAction()
                    case 'LAST_DAY_ENDED':
                        return actions.lastDayEndedAction()
                    default:
                        return null
                }
            }
            case 'FOREMAN_REPORT_TEAM': {
                switch (currentStage) {
                    case 'TEAM_REPORT_FILLING':
                        if (isAllChecks) {
                            return FOREMAN_REPORT_ACTIONS.lastDayActions()
                        }
                        return FOREMAN_REPORT_ACTIONS.mainActions()
                    case 'WAREHOUSE_GOING':
                        return FOREMAN_REPORT_ACTIONS.warehouseActions()
                    case 'WORK_ENDING':
                        return actions.foremanReportAction(lastWorkingDay)
                    case 'LAST_WORK_ENDED':
                        return actions.lastDayEndedAction()
                    default:
                        return null
                }
            }
            case 'TOOLS_REPORT': {
                switch (currentStage) {
                    case 'INVENTORY_ITEMS_RETURNING':
                        if (order?.documents?.toolsReport?.nzBack) {
                            return INVENTORY_ITEMS_RETURNING_ACTIONS.withNzActions()
                        }
                        return INVENTORY_ITEMS_RETURNING_ACTIONS.mainActions()
                    case 'FOREMAN_REPORT':
                        return actions.onWarehouseAction()
                    case 'ON_WAREHOUSE':
                        return actions.toolsReportAction()
                    case 'TOOLS_REPORT':
                        return actions.approveNZBackAction()
                    default:
                        return null
                }
            }
            case 'ORDER_MANAGER_REPORT': {
                switch (currentStage) {
                    case 'MANAGER_CHECKING':
                        if (!order?.documents?.orderManagerReport?.nzFull) {
                            return MANAGER_CHECKING_ACTIONS.withoutNzActions()
                        }
                        return MANAGER_CHECKING_ACTIONS.mainActions()
                    case 'APPROVE_NZ_BACK':
                        if (order?.agreement?.contractType === 'Без договора') {
                            return actions.paymentReceivedAction()
                        }
                        return actions.orderManagerReportAction()
                    default:
                        return null
                }
            }
            case 'ORDER_MANAGER_ORDER_CLOSING': {
                switch (currentStage) {
                    case 'DEFECTS_CORRECTING':
                    case 'TO_ORDER_MANAGER_ORDER_CLOSING':
                        return DEFECTS_CORRECTING_ACTIONS.mainActions()
                    // case 'TO_ORDER_MANAGER_ORDER_CLOSING':
                    //     return actions.orderManagerOrderClosing()
                    default:
                        return null
                }
            }
            case 'OPERATOR_ORDER_CLOSING': {
                switch (currentStage) {
                    case 'TO_OPERATOR_ORDER_CLOSING':
                        return actions.operatorOrderClosing()
                    default:
                        return null
                }
            }
            case 'QUALITY_CONTROL': {
                switch (currentStage) {
                    case 'QUALITY_CONTROLLING':
                        if (order?.starting?.workerType === 'Подрядчик') {
                            return QUALITY_CONTROL_ACTIONS.contractActions()
                        }
                        if (!(order?.agreement?.prepayment === 100 && order?.agreement?.prepaymentType === 'Внесена')) {
                            return QUALITY_CONTROL_ACTIONS.paymentActions()
                        }
                        if (order?.primaryInformation?.counterparty === 'Физ. лицо' || order?.agreement?.contractType === 'Без договора') {
                            return QUALITY_CONTROL_ACTIONS.individualActions()
                        }
                        return QUALITY_CONTROL_ACTIONS.mainActions()
                    case 'TO_QUALITY_CONTROL':
                        return actions.qualityControlAction()
                    default:
                        return null
                }
            }
            case 'CHIEF_DECISION': {
                return FINAL_REPORT_ACTIONS.chiefDecisionAction()
            }
            case 'CHIEF_REPORT': {
                switch (currentStage) {
                    case 'FINAL_REPORT':
                    case 'TO_CHIEF_DECISION':
                        return FINAL_REPORT_ACTIONS.chiefDecisionAction()
                    case 'MAINTENANCE_FINAL_REPORT':
                        if ((user.role === 'CHIEF')) {
                            return CHIEF_REPORT_SECTIONS.chiefDecisionAction()
                        }
                        return null
                    case 'APPROVE_QUALITY_CONTROL':
                        return actions.chiefReportAction()
                    default:
                        return null
                }
            }
            default: {
                return null
            }
        }
    }
}
