import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchOrderQuery } from '~/API/orderApi/order'
import { CustomButton } from '~/components/common/ReusingComponents/CustomButton/CustomButton'
import { DISABLED_STAGES } from '~/components/pages/KP/constants'
import { selectSmetaHandleSubmit } from '~/redux/selectors/smetaSlise.selectors'

const SmetaSidebarButtons = () => {
    const { id: orderId } = useParams()
    const navigate = useNavigate()
    const handleSubmit = useSelector(selectSmetaHandleSubmit)

    const {
        data: order,
    } = useFetchOrderQuery(orderId!)

    const saveSmeta = async () => {
        try {
            await handleSubmit()
        } catch (e) {
        }
    }

    return (
        <div className='sidebar__buttons'>
            <CustomButton
                className='sidebar__buttonsItem'
                onClick={saveSmeta}
                disabled={!order || !DISABLED_STAGES.includes(order.stageName)}
            >
                Сформировать Смету
            </CustomButton>
            <CustomButton
                className='sidebar__buttonsItem sidebar__buttonsItem--secondary'
                onClick={() => navigate(`/order/${orderId}/kp`)}
            >
                Вернуться к КП
            </CustomButton>
        </div>
    )
}

export default SmetaSidebarButtons
