import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import clsx from 'clsx'
import React from 'react'
import { fetchDate } from '~/shared/util/currentTime'
import './CustomDate.scss'
import { MuiLocalizationProvider } from '../../../app/providers/MuiLocalizationProvider/MuiLocalizationProvider'

interface CustomDateProps {
    value: Date,
    onChange: (value: any) => void,
    title: string,
    disabled?: boolean,
    className?: string
}

const CustomDate = (props: CustomDateProps) => {
    const {
        value,
        onChange,
        title,
        disabled,
        className,
    } = props

    return (
        <FormControl className={clsx('customDate', className, { disabled })}>
            <InputLabel className='customDate__label'>{title}</InputLabel>
            <MuiLocalizationProvider>
                <DatePicker
                    onChange={onChange}
                    value={fetchDate(value)}
                    views={['year', 'month', 'day']}
                    disabled={disabled}
                />
            </MuiLocalizationProvider>
        </FormControl>
    )
}

export default CustomDate
