import { useSelector } from 'react-redux'
import { useLazyFetchAllOrderByActionDateQuery, useLazyFetchAllOrderByCleaningDateQuery } from '~/API/orderApi/order'
import { useLazyFetchScheduleQuery } from '~/API/scheduleApi/schedule'
import { useFetchUsersByRolesMutation } from '~/API/userApi/user'
import { Division } from '~/API/userApi/user.types'
import { UseUpdateCalendarParams } from '~/hooks/useUpdateCalendar/useUpdateCalendar.types'
import { selectUser } from '~/redux/selectors/appSlice.selectors'
import { getDateRangeSelector, selectDivision } from '~/redux/selectors/orderSlice.selectors'
import { selectWatchingRoles } from '~/redux/selectors/staffSchedule.selector'
import { setEventLoader, updateOrderFulfilled } from '~/redux/slices/orderSlice/orderSlice'
import { useAppDispatch } from '~/redux/store'
import { fetchDate } from '~/shared/util/currentTime'

export const useUpdateCalendar = () => {
    const dispatch = useAppDispatch()

    const [fetchAllOrderByCleaningDate] = useLazyFetchAllOrderByCleaningDateQuery()
    const [fetchAllOrderByActionDate] = useLazyFetchAllOrderByActionDateQuery()
    const [fetchSchedule] = useLazyFetchScheduleQuery()
    const [fetchUsersByRoles] = useFetchUsersByRolesMutation()

    const dateRange = useSelector(getDateRangeSelector)
    const watchingRoles = useSelector(selectWatchingRoles)
    const division = useSelector(selectDivision)
    const user = useSelector(selectUser)

    return (params: UseUpdateCalendarParams) => {
        const {
            clearOld = true,
            newDivision = division as Division,
            actualDateRange = dateRange,
        } = params
        dispatch(setEventLoader(true))

        const startDate = actualDateRange.start.toISOString()
        const endDate = fetchDate(actualDateRange.end).subtract(1, 'second').toISOString()

        const cleaningsPromise = fetchAllOrderByCleaningDate({
            startDate,
            endDate,
            division: newDivision,
        }).unwrap()

        const actionsPromise = fetchAllOrderByActionDate({
            startDate,
            endDate,
            division: newDivision,
        }).unwrap() // all actions also with cleanings

        // TODO ATTENTION! manually subtract 1 second from end date, figure out how to make the correct date initially
        const schedulePromise = fetchSchedule({
            startDate: actualDateRange.start.toDate(),
            endDate: fetchDate(actualDateRange.end).subtract(1, 'second').toDate(),
            division: newDivision,
        }).unwrap()

        const personnelBDPromise = fetchUsersByRoles({
            roles: watchingRoles,
            division: newDivision,
        }).unwrap()

        Promise.all([cleaningsPromise, actionsPromise, schedulePromise, personnelBDPromise])
            .then((res) => {
                const [cleanings, actions, schedule, personnelBD] = res
                dispatch(updateOrderFulfilled({
                    cleanings,
                    actions,
                    schedule,
                    personnelBD,
                    user,
                    clearOld,
                }))
            })
            .catch(() => {

            })
            .finally(() => {
                dispatch(setEventLoader(false))
            })
    }
}
